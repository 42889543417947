import { GridColDef } from '@mui/x-data-grid';

export const productSalesCountColumns: GridColDef[] = [
  {
    field: 'salesCount',
    headerName: 'Sales',
    width: 100,
    type: 'number',
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'prevSixMonthSalesCount',
    headerName: 'Prev 6mo Sales',
    width: 150,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'salesPerEventDate',
    headerName: 'Sales Per Event Date',
    align: 'center',
    valueFormatter: ({ value }) => {
      const roundedValue = Math.round(value * 100) / 100;

      return roundedValue;
    },
    width: 150,
  },
];