import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { addStaffToEventDate } from 'api/actions';
import { EventDateStaffStatusEnum } from 'api/resources';
import { ButtonGroup, ButtonGroupType } from 'components';

export type AddStaffActionButtonGroupProps = {
  userId: string;
  eventDateId: string;
  onMutation?: () => void;
  invalidateQueriesHandler: (queryClient: QueryClient) => Promise<void>;
} & Omit<ButtonGroupType, 'options'>;

export const AddStaffActionButtonGroup: React.FC<AddStaffActionButtonGroupProps> = ({ eventDateId, userId, onMutation, invalidateQueriesHandler, ...props }) => {
  const queryClient = useQueryClient();
  const addStaffMutation = useMutation({
    mutationFn: async (status: EventDateStaffStatusEnum) => {
      return addStaffToEventDate(eventDateId, { user: userId, status });
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      onMutation?.();
    }
  });

  const loading = addStaffMutation.isLoading;

  const options: ButtonGroupType['options'] = [
    {
      label: 'Confirm',
      onClick: () => addStaffMutation.mutate(EventDateStaffStatusEnum.confirmed),
    },
    {
      label: 'Request',
      onClick: () => addStaffMutation.mutate(EventDateStaffStatusEnum.requested),
    },
    {
      label: 'Initial',
      onClick: () => addStaffMutation.mutate(EventDateStaffStatusEnum.initial),
    }
  ];

  return <ButtonGroup {...props} options={options} loading={loading} />;
};