import { GetProductsStockUpdateLogsResponse, GetEventsSalesResponse, GetSalesResponse } from 'api/actions';
import { DateRange } from 'components';
import { useProductsStockUpdateLogs, useEventsSales, useSales } from 'queries';
import React from 'react';
import { DateService } from 'services';

type AnalyticsPageContextType = {
  dateRange: NonNullable<DateRange>;
  compareDateRange: DateRange;
  sales: GetSalesResponse['data'];
  salesLoading: boolean;
  eventsSales: GetEventsSalesResponse['data'];
  eventsSalesLoading: boolean;
  updateLogs: GetProductsStockUpdateLogsResponse['data'];
  updateLogsLoading: boolean;
  compareSales: GetSalesResponse['data'];
  compareEventsSales: GetEventsSalesResponse['data'];
  compareUpdateLogs: GetProductsStockUpdateLogsResponse['data'];
};

export const AnalyticsPageContext = React.createContext<AnalyticsPageContextType>({
  dateRange: { start: DateService.dayjs().subtract(1, 'day'), end: DateService.dayjs().subtract(1, 'day') },
  compareDateRange: null,
  sales: [],
  salesLoading: false,
  eventsSales: [],
  eventsSalesLoading: false,
  updateLogs: [],
  updateLogsLoading: false,
  compareSales: [],
  compareEventsSales: [],
  compareUpdateLogs: [],
});

export type AnalyticsPageContextProviderProps = {
  dateRange: NonNullable<DateRange>;
  compareDateRange: DateRange;
  children: React.ReactNode | ((context: AnalyticsPageContextType) => React.ReactNode);
};

export const AnalyticsPageContextProvider: React.FC<AnalyticsPageContextProviderProps> = props => {
  const startDate = props.dateRange.start.format('YYYY-MM-DD');
  const endDate = props.dateRange.end.format('YYYY-MM-DD');
  const { data: sales = [], isInitialLoading: salesLoading } = useSales({ startDate, endDate });
  const { data: eventsSales = [], isInitialLoading: eventsSalesLoading } = useEventsSales({ startDate, endDate });
  const { data: updateLogs = [], isInitialLoading: updateLogsLoading } = useProductsStockUpdateLogs({ startDate, endDate });

  const compareStartDate = props.compareDateRange?.start.format('YYYY-MM-DD');
  const compareEndDate = props.compareDateRange?.end.format('YYYY-MM-DD');
  const { data: compareSales = [], isInitialLoading: compareSalesLoading } = useSales({ startDate: compareStartDate, endDate: compareEndDate }, Boolean(compareStartDate && compareEndDate));
  const { data: compareEventsSales = [], isInitialLoading: compareEventsSalesLoading } = useEventsSales({ startDate: compareStartDate, endDate: compareEndDate }, Boolean(compareStartDate && compareEndDate));
  const { data: compareUpdateLogs = [], isInitialLoading: compareUpdateLogsLoading } = useProductsStockUpdateLogs({ startDate: compareStartDate, endDate: compareEndDate }, Boolean(compareStartDate && compareEndDate));

  const contextValue: AnalyticsPageContextType = {
    dateRange: props.dateRange,
    compareDateRange: props.compareDateRange,
    sales,
    salesLoading: salesLoading || (!!props.compareDateRange && compareSalesLoading),
    eventsSales,
    eventsSalesLoading: eventsSalesLoading || (!!props.compareDateRange && compareEventsSalesLoading),
    updateLogs,
    updateLogsLoading: updateLogsLoading || (!!props.compareDateRange && compareUpdateLogsLoading),
    compareSales,
    compareEventsSales,
    compareUpdateLogs,
  };

  const children = typeof props.children === 'function' ? props.children(contextValue) : props.children;

  return (
    <AnalyticsPageContext.Provider value={contextValue}>
      {children}
    </AnalyticsPageContext.Provider>
  );
};


export const useAnalyticsPageContext = () => React.useContext(AnalyticsPageContext);