import { JumpNav, PageContentContainer, PageContentResourceHeader, SectionCardsEditContextProvider } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useMemo, useState } from 'react';
import { EditEventOrganizerModal } from './components/EditEventOrganizerModal.component';
import { useEventOrganizerOutletContext } from './EventOrganizer.base';
import { DeleteEventOrganizerModal } from './components/DeleteEventOrganizerModal.component';
import { EventsSection, OverviewSection } from './sections';
import { Box } from '@mui/material';
import { EventOrganizerPageSection } from './types';

type ViewType = 'basicInfo' | null | 'deleteEventOrganizer';

export const EventOrganizer = () => {
  const { eventOrganizer } = useEventOrganizerOutletContext();
  const [ view, setView ] = useState<ViewType>(null);

  const modalView = useMemo(() => {
    if (view === 'deleteEventOrganizer') {
      return <DeleteEventOrganizerModal onClose={() => setView(null)} />;
    }

    if (view === 'basicInfo') {
      return <EditEventOrganizerModal onClose={() => setView(null)} />;
    }

    return null;
  }, [ view ]);

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.eventOrganizers, text: 'Event Organizers' } ],
        current: eventOrganizer.name
      }}
      header={(
        <PageContentResourceHeader
          title={eventOrganizer.name}
          pageTitle="Event Organizer Page"
          options={[
            {
              label: 'Delete Event Organizer',
              menuItemProps: { onClick: () => setView('deleteEventOrganizer'), },
              labelTypographyProps: { sx: { color: (theme) => theme.palette.error.dark } },
            },
          ]}
          stickyTabs
          tabs={(
            <JumpNav
              initialValue={EventOrganizerPageSection.OVERVIEW}
              offsetTop={64}
              tabs={[
                {
                  label: 'Overview',
                  sectionId: EventOrganizerPageSection.OVERVIEW,
                },
                {
                  label: 'Events',
                  sectionId: EventOrganizerPageSection.EVENTS,
                }
              ]}
            />
          )}
        />
      )}
    >
      {modalView}

      <Box display="flex" flexDirection="column" gap={5} pt={5} pb={10}>
        <SectionCardsEditContextProvider>
          <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
            <OverviewSection />
            <EventsSection />
          </Box>
        </SectionCardsEditContextProvider>
      </Box>

    </PageContentContainer>
  );
};
