import { AccessTime, People } from '@mui/icons-material';
import { Box, Typography, Chip } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { EventDate, EventDateStaffStatusEnum, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { eventDateEnumHelpers, userEnumHelpers } from 'helpers';
import { getWorkdayTotal } from 'helpers/event-date-times';
import React from 'react';
import { DateService } from 'services';

export type EventDateContentCondensedProps = {
  actions: React.ReactNode;
  event: GetEventsResponse['data'][number];
  eventDate: ResourceWithPopulated<EventDate, {
    staff: ResourceWithPopulated<EventDate['staff'][number], {
      user: PickFromResource<User, 'name'>;
    }>[];
  }>;
};

export const EventDateContentCondensed: React.FC<EventDateContentCondensedProps> = ({ event, eventDate, actions }) => {
  const TypeIcon = eventDateEnumHelpers.type.getIcon(eventDate.type);
  const { workdayTotalLabel, workdayTimeRangeLabel } = getWorkdayTotal({ event, eventDate });

  return (
    <Box>
      <Box bgcolor="primary.background"  display="flex" alignItems="center" py={1} px={2} gap={1}>
        <TypeIcon fontSize="small" color="disabled" />
        <Typography variant="body2" fontWeight={500}>{DateService.dayjsTz(eventDate.dateAsUtc).format('ddd, MMM Do')}</Typography>
        <Typography color="text.secondary">|</Typography>
        <AccessTime fontSize="small" color="disabled" />
        <Typography variant="body2">{workdayTimeRangeLabel} <b>({workdayTotalLabel})</b></Typography>
        <Typography color="text.secondary">|</Typography>
        <People fontSize="small" color="disabled" />
        <Typography variant="body2" component="span" whiteSpace="nowrap" fontSize="small"><Typography component="span" fontWeight={500}>{eventDate.staffNeeded}</Typography> Needed, </Typography>
        <Typography variant="body2" component="span" whiteSpace="nowrap" fontSize="small"><Typography component="span" fontWeight={500}>{eventDate.staff.filter(({ status }) => status === EventDateStaffStatusEnum.confirmed).length}</Typography> Confirmed</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="5px">
        <Box display="flex" alignItems="center">
          <Typography variant="body2">Scheduled Staff:&nbsp;</Typography>
          <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center">
            {eventDate.staff.map((staff) => {
              return (
                <Box key={staff._id} display="flex">
                  <Chip
                    size="small"
                    color={userEnumHelpers.staffStatus.getColor(staff.status)}
                    variant="outlined"
                    label={staff.user.name}
                    sx={{ fontWeight: 500, borderRadius: '4px' }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        {actions}
      </Box>
    </Box>
  );
};