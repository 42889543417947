import { Grid } from '@mui/material';
import { GetEventDatesResponse, GetUsersAvailabilitySetHashResponse } from 'api/actions';
import { User } from 'api/resources';
import { EventManagementLogisticsStaffScheduleUserCard } from './EventManagementLogisticsStaffScheduleUserCard.component';

export type EventsManagementLogisticsStaffScheduleUserCardsProps = {
  users: User[];
  eventDates: GetEventDatesResponse['data'];
  eventDatesLoading: boolean;
  usersAvailabilitySetHash: GetUsersAvailabilitySetHashResponse['data'];
  usersAvailabilitySetHashLoading?: boolean;
};

export const EventsManagementLogisticsStaffScheduleUserCards: React.FC<EventsManagementLogisticsStaffScheduleUserCardsProps> = props => {
  const sortedUsers = props.users.sort((a, b) => a.name < b.name ? -1 : 1);

  return (
    <Grid container spacing={2}>
      {sortedUsers.map(user => (
        <Grid item xs={12} md={6} lg={4} key={user._id}>
          <EventManagementLogisticsStaffScheduleUserCard user={user} {...props} key={user._id} />
        </Grid>
      ))}
    </Grid>
  );
};