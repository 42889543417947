import { Container, Typography } from '@mui/material';

export const Storefronts = () => {
  return (
    <Container maxWidth="lg">
      <Typography mt={3}>
        Storefronts page is in progress...
      </Typography>
    </Container>
  );
};
