import { Badge, Box, Card, Checkbox, Container, FormControlLabel, FormHelperText, InputAdornment, List, Typography } from '@mui/material';
import { CardContentContainer, Loading, TextInput, useAlertSnackbar } from 'components';
import { getFrameEnumFromValue, saleEnumHelpers } from 'helpers';
import { DiscountEnum, LogSaleStepEnum, listDividersSx, useLogSaleContext } from '../utils';
import { CheckOutlined, EmailOutlined, PersonOutline } from '@mui/icons-material';
import { SaleProductListItem } from '../components/SaleProductListItem.component';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { currencyFormatter } from 'services';
import { DiscountChip } from '../components/DiscountChip.component';
import { TotalsOverview } from '../components/TotalsOverview.component';
import { formSx } from 'styles';
import { Formik } from 'formik';
import { SalePaymentEnum } from 'api/resources';
import { SchemaOf, boolean, mixed, object, string } from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLoggingPortalContext } from 'pages/LoggingPortal/LoggingPortal.base';
import { QUERY_KEY } from 'queries/query-keys';
import { CreateLiveSaleBaseInput, LoggingPortalResourceTypeEnum, createLiveSaleForEventDate, createLiveSaleForStorefront } from 'api/actions';

export type PaymentStepProps = {
  userId: string;
};

type PaymentStepForm = {
  payment: SalePaymentEnum | null;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

type PaymentStepFormValidated = {
  payment: SalePaymentEnum;

  customerName: string;
  customerEmail: string;
  sendReceipt: boolean;
};

const paymentStepFormValidationSchema: SchemaOf<PaymentStepForm> = object().shape({
  payment: mixed<SalePaymentEnum>().oneOf(saleEnumHelpers.salePayment.enumValues).required('Please select a payment type'),

  customerName: string().default('').when('customerEmail', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer name'),
  }),
  customerEmail: string().email('Please enter a valid email').default('').when('customerName', {
    is: (val: string) => !!val,
    then: schema => schema.required('Please enter a customer email'),
  }),
  sendReceipt: boolean().default(true),
}, [ [ 'customerName', 'customerEmail' ], [ 'customerEmail', 'customerName' ] ]);

const initialValues: PaymentStepForm = {
  payment: null,

  customerName: '',
  customerEmail: '',
  sendReceipt: true,
};

export const PaymentStep: React.FC<PaymentStepProps> = props => {
  const { resourceId, resourceType, ...data } = useLoggingPortalContext();
  const { state, onSetStep, saleProductsTransformed, onClearAndExit } = useLogSaleContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const confirmSaleMutation = useMutation({
    mutationFn: async (form: PaymentStepFormValidated) => {
      const otherDiscountsTotal = state.discounts.reduce((acc, discount) => {
        switch (discount) {
          case DiscountEnum.fiveOff:
            return acc - 5;
          case DiscountEnum.tenOff:
            return acc - 10;
          case DiscountEnum.tenPercentOff:
            return acc - (saleProductsTransformed.reduce((acc, { total }) => acc + total, 0) * 0.1);
          default:
            return acc;
        }
      }, 0);

      const discountPer = +((otherDiscountsTotal / saleProductsTransformed.length).toFixed(2));
      let filler = 0;

      if (discountPer * saleProductsTransformed.length !== otherDiscountsTotal) {
        filler = +((otherDiscountsTotal - (discountPer * saleProductsTransformed.length)).toFixed(2));
      }

      const sales = saleProductsTransformed.map(({ product, price, totalDiscount, frame }, idx, { length }): CreateLiveSaleBaseInput['sales'][number] => {
        let salePrice = price + totalDiscount + discountPer;

        if (filler && idx === length - 1) {
          salePrice = +((salePrice + filler).toFixed(2));
        }

        return {
          product: product._id,
          salePrice,
          frame,
        };
      });

      if ('eventDate' in data) {
        return await createLiveSaleForEventDate({
          eventDate: data.eventDate._id,
          sales,
          payment: form.payment,
          user: props.userId,
        });
      }

      return await createLiveSaleForStorefront({
        storefront: data.storefront._id,
        sales,
        payment: form.payment,
        user: props.userId,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        resourceType ===  LoggingPortalResourceTypeEnum.eventDate
          ? QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(resourceId)
          : QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(resourceId)
      );
      snackbar.success('Sale logged');
      onClearAndExit();
    },
    onError: () => {
      snackbar.error('Unable to log sale. Please try again or contact management.');
    }
  });

  return (
    <Formik
      onSubmit={(values: PaymentStepFormValidated) => confirmSaleMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={paymentStepFormValidationSchema}
    >
      {formik => {
        const paymentError = formik.touched.payment && formik.errors.payment;

        if (formik.isSubmitting) {
          return <Loading />;
        }

        return (
          <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="md">
            <NavigateButtons
              onNext={() => formik.handleSubmit()}
              onBack={() => onSetStep(LogSaleStepEnum.discount)}
            />
            <Box display="flex" gap={2} mx={{ xs: -2, sm: 0 }} flexDirection={{ xs: 'column', sm: 'row' }} flexWrap="wrap">

              <Box flex={1} display="flex" flexDirection="column" gap={1} minWidth={{ xs: 'unset', sm: '400px' }}>
                <Card variant="outlined">
                  <List disablePadding sx={listDividersSx()}>
                    {saleProductsTransformed.map(({ product, id, frame, total, price }) => {
                      const frameLabel = saleEnumHelpers.frame.getLabel(getFrameEnumFromValue(frame));

                      return (
                        <SaleProductListItem
                          key={id}
                          name={product.name}
                          imageUrl={product.imageUrl}
                          secondary={frameLabel}
                          actions={(
                            <Box display="flex" alignItems="center" gap={1}>
                              {price !== total && (
                                <Typography sx={{ textDecoration: 'line-through', textDecorationColor: theme => theme.palette.error.light }}>
                                  {currencyFormatter.format(price)}
                                </Typography>
                              )}
                              <Typography fontWeight={500}>
                                {currencyFormatter.format(total)}
                              </Typography>
                            </Box>
                          )}
                        />
                      );
                    })}
                  </List>
                </Card>
                <Card variant="outlined">
                  <Box p={2} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={state.discounts.length ? 2 : 0}>
                    <Box flex={1} display="flex" flexDirection="column" gap={1}>
                      {state.discounts.map((discount) => <DiscountChip key={discount} discount={discount} />)}
                    </Box>
                    <TotalsOverview />
                  </Box>
                </Card>
              </Box>

              <Box width={{ xs: '100%', sm: '396px' }} display="flex" flexDirection="column" gap={2}>
                <Card
                  variant="outlined"
                  sx={{
                    borderColor: theme => paymentError ? theme.palette.error.main : undefined,
                    background: theme => paymentError ? theme.palette.error.background : undefined,
                  }}
                >
                  <Box display="flex" flexDirection="column" gap={2} p={2}>
                    {saleEnumHelpers.salePayment.enumValues.map(paymentType => {
                      const selected = formik.values.payment === paymentType;
                      const label = saleEnumHelpers.salePayment.getLabel(paymentType);

                      return (
                        <Badge
                          color="primary"
                          badgeContent={selected ? <CheckOutlined fontSize="small" /> : 0}
                          key={paymentType}
                        >
                          <Card
                            variant="outlined"
                            sx={{
                              width: '100%',
                              cursor: 'pointer',
                              borderRadius: theme => theme.shape.borderRadius,
                              border: theme => selected ? `1px solid ${theme.palette.primary.main}` : undefined,
                              background: theme => selected ? theme.palette.primary.background : undefined,
                              color: theme => selected ? theme.palette.primary.dark : undefined,
                              userSelect: 'none',
                            }}
                            onClick={() => formik.setFieldValue('payment', paymentType)}
                          >
                            <Box p={1} display="flex" alignItems="center" justifyContent="center">
                              <Typography fontWeight={500}>{label}</Typography>
                            </Box>
                          </Card>
                        </Badge>
                      );
                    })}
                    {Boolean(paymentError) && (
                      <FormHelperText error sx={{ textAlign: 'center', fontSize: theme => theme.typography.body2.fontSize }}>
                        {paymentError}
                      </FormHelperText>
                    )}
                  </Box>
                </Card>
                <CardContentContainer title="Customer Information" cardContentSx={{ pb: theme => `${theme.spacing(2)} !important` }}>
                  <Box sx={formSx.formGroup}>
                    <TextInput
                      fullWidth
                      name="customerName"
                      label="Customer Name"
                      InputLabelProps={{ shrink: true }}
                      startAdornment={(
                        <InputAdornment position="start">
                          <PersonOutline />
                        </InputAdornment>
                      )}
                      autoComplete="off"
                    />
                    <TextInput
                      fullWidth
                      name="customerEmail"
                      label="Customer Email"
                      InputLabelProps={{ shrink: true }}
                      startAdornment={(
                        <InputAdornment position="start">
                          <EmailOutlined />
                        </InputAdornment>
                      )}
                      autoComplete="off"
                    />
                  </Box>
                  <Box px={1} pt={2}>
                    <FormControlLabel
                      control={<Checkbox checked={formik.values.sendReceipt} onChange={(_e, checked) => formik.setFieldValue('sendReceipt', checked)} />}
                      label="Send Receipt"
                      disabled={!formik.values.customerEmail}
                    />
                  </Box>
                </CardContentContainer>
              </Box>

            </Box>

          </Container>

        );
      }}

    </Formik>
  );
};