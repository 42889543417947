import { BedOutlined, PlaceOutlined, WarningAmberOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Typography, Stack, Alert, Collapse, ListItem, ListItemButton, Chip } from '@mui/material';
import { EventLodging, Place, PlaceDefaultEnum, PlaceMatrix } from 'api/resources';
import { ResourceWithPopulated } from 'api/resources/resources.types';
import { CardContentGrid, CardContentGridProps, CardContentMarkdown } from 'components/CardContent';
import { BookingStatusChip } from 'components/Chips';
import { PlaceDistanceAndDurationContent } from 'components/PlaceDistanceAndDurationContent';
import React, { useMemo, useState } from 'react';
import { useEventContext } from '../event.context';
import { getCurrentEventLodgingPlace } from 'helpers';
import { DateService, currencyFormatter } from 'services';

export type EventLodgingPlaceCardProps = {
  lodgingPlace: ResourceWithPopulated<EventLodging['places'][number], {
    place: (Place & {
      fromStudioMatrix: PlaceMatrix;
      toEventMatrix: PlaceMatrix;
    });
  }>;
};

export const EventLodgingPlaceCard: React.FC<EventLodgingPlaceCardProps> = ({ lodgingPlace }) => {
  const { event } = useEventContext();
  const [ viewingMore, setViewingMore ] = useState(false);

  const isCurrent = getCurrentEventLodgingPlace(event)?._id === lodgingPlace._id;
  const cardContentGridItems = useMemo(() => {
    const items: CardContentGridProps['items'] = [
      {
        title: 'Estimated cost',
        value: currencyFormatter.format(lodgingPlace.cost ?? 0),
        isUnassigned: !lodgingPlace.cost,
      },
      {
        title: 'Final cost',
        value: currencyFormatter.format(lodgingPlace.finalCost ?? 0),
        isUnassigned: !lodgingPlace.finalCost,
      },
      {
        title: 'Booking Confirmation number',
        value: lodgingPlace.confirmationNumber,
        type: 'full',
      },
      {
        title: 'Website',
        value: lodgingPlace.website,
      },
      {
        title: 'Phone',
        value: lodgingPlace.phone,
      },
    ];

    if(lodgingPlace.notes) {
      items.push({
        title: 'Notes',
        value: <CardContentMarkdown markdown={lodgingPlace.notes} />,
        type: 'full',
      });
    }

    return items;
  }, [ lodgingPlace.confirmationNumber, lodgingPlace.cost, lodgingPlace.finalCost, lodgingPlace.notes, lodgingPlace.phone, lodgingPlace.website ]);

  return (
    <Box width={{ xs: 200, sm: 400, md: 500 }} bgcolor={theme => isCurrent ? theme.palette.primary.background : undefined} p={1}>
      <Box display="flex" alignItems="flex-end" gap={0.5} mb={0.5}>
        <BedOutlined color="primary" />
        <Typography fontWeight={500}>{lodgingPlace.place.name}</Typography>
        {isCurrent && <Chip color="primary" label="Current" size="small" sx={{ fontWeight: 500 }} />}
      </Box>
      <Typography variant="body2" display="flex">
        (<Typography variant="body2" fontWeight={500}>{DateService.getFormattedDateAndTime(lodgingPlace.checkIn)}</Typography>
        &nbsp;to&nbsp;
        <Typography variant="body2" fontWeight={500}>{DateService.getFormattedDateAndTime(lodgingPlace.checkOut)}</Typography>)
      </Typography>
      <Typography display="flex" alignItems="center" gap={0.5}><PlaceOutlined color="error" fontSize="small" />{lodgingPlace.place.address}</Typography>
      <Stack gap={1} my={0.5}>
        <BookingStatusChip value={lodgingPlace.bookingStatus} />
      </Stack>
      <Stack gap={1} my={1}>
        <PlaceDistanceAndDurationContent
          fromLabel="Studio"
          toLabel="Lodging"
          placeMatrix={lodgingPlace.place.fromStudioMatrix}
          routeDetails={{
            fromAddress: PlaceDefaultEnum.studio,
            toAddress: lodgingPlace.place.address,
          }}
        />
        <PlaceDistanceAndDurationContent
          fromLabel="Lodging"
          toLabel="Event"
          placeMatrix={lodgingPlace.place.toEventMatrix}
          routeDetails={{
            fromAddress: lodgingPlace.place.address,
            toAddress: event.place.address,
          }}
        />
      </Stack>
      {(lodgingPlace.needToSendCreditAuthorization && !lodgingPlace.creditAuthorizationSent) && (
        <Box my={1}>
          <Alert severity="warning" icon={<WarningAmberOutlined />}>Need to send credit authorization!</Alert>
        </Box>
      )}
      <Collapse in={viewingMore} timeout="auto" unmountOnExit>
        <ListItem divider sx={{ position: 'static' }} dense>
          <CardContentGrid items={cardContentGridItems} />
        </ListItem>
      </Collapse>
      <ListItemButton dense sx={{ position: 'static', display: 'flex', justifyContent: 'center' }} onClick={() => setViewingMore(p => !p)} disableRipple divider>
        {viewingMore ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        <Typography fontSize="small">View {viewingMore ? 'Less' : 'More'} Info</Typography>
      </ListItemButton>
    </Box>
  );
};
