import { EventDatePageSectionRowId, EventDateSectionRowForm, SectionCardRowEditable, SectionCardRowEditableList, SectionCardRowEditableListProps, SectionCardRowEditableProps, useEventContext } from 'components';
import { QUERY_KEY } from 'queries/query-keys';
import { useContext, useMemo } from 'react';
import { EventDatePageRowWarningsContext } from '../EventEventDate.page';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';

export type EventSectionCardRowEditableProps<RowId extends EventDatePageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<EventDateSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const EventDateSectionCardRowEditable = <RowId extends EventDatePageSectionRowId>(props: EventSectionCardRowEditableProps<RowId>) => {
  const { event } = useEventContext();
  const { isAuthorizedEmployee } = useCurrentUser();
  const { getRowWarningDescription } = useContext(EventDatePageRowWarningsContext);
  const description = getRowWarningDescription(props.rowId);

  const disableEditable = useMemo(() => {
    return !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  }, [ isAuthorizedEmployee ]);

  return (
    <SectionCardRowEditable
      {...props}
      invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) })}
      warningDescription={description}
      disableEditable={disableEditable}
    />
  );
};

export type EventSectionCardRowEditableListProps<RowId extends EventDatePageSectionRowId, ListItem extends {_id: string}> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableListProps<EventDateSectionRowForm[RowId], ListItem>, 'rowId' | 'invalidateQueryKeys'>;

export const EventDateSectionCardRowEditableList = <RowId extends EventDatePageSectionRowId, ListItem extends {_id: string}>(props: EventSectionCardRowEditableListProps<RowId, ListItem>) => {
  const { event } = useEventContext();
  const { isAuthorizedEmployee } = useCurrentUser();
  const { getRowWarningDescription } = useContext(EventDatePageRowWarningsContext);
  const description = getRowWarningDescription(props.rowId);

  const disableEditable = useMemo(() => {
    return !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  }, [ isAuthorizedEmployee ]);

  return (
    <SectionCardRowEditableList
      {...props}
      invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) })}
      warningDescription={description}
      disableEditable={disableEditable}
    />
  );
};