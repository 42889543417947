import { GetEventsResponse } from 'api/actions';
import { DateObjectType, DateService } from 'services';

export const getEventLodgingPlaceForDate = (event: Pick<GetEventsResponse['data'][number], 'lodging'>, date: DateObjectType) => {
  return event.lodging?.places?.find(lodgingPlace => {
    if (!lodgingPlace.checkIn && !lodgingPlace.checkOut) {
      return false;
    }

    return !DateService.dayjsTz(lodgingPlace.checkIn).isAfter(date) && !DateService.dayjsTz(lodgingPlace.checkOut).isBefore(DateService.dayjsTz(date));
  });
};

export const getCurrentEventLodgingPlace = (event: GetEventsResponse['data'][number]) => {
  return event.lodging?.places.find(p => {
    return DateService.dayjs().isAfter(p.checkIn) && DateService.dayjs().isBefore(p.checkOut);
  });
};