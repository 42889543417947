import { CancelOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowEditStopReasons, GridRowModes, GridRowModesModel } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateGenreInput, deleteGenre, updateGenre } from 'api/actions/genre';
import { Genre } from 'api/resources/genre';
import { DeleteResourceModal, Table, useAlertSnackbar } from 'components';
import { useCurrentUser } from 'contexts';
import { getSelectOptionsFromEnumHelper } from 'helpers';
import { genreEnumHelpers } from 'helpers/enums/genre-enums.helpers';
import { useGenres } from 'queries/genre';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useCallback, useMemo, useState } from 'react';

const createGenreTableColumns = (setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>, onDelete: (id: string) => void, isAdmin: boolean): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    flex: 1,
    editable: true,
    type: 'string',
  },
  {
    field: 'group',
    headerName: 'Group',
    width: 200,
    flex: 1,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(genreEnumHelpers.group),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    hideable: isAdmin,
    width: 100,
    renderCell: ({ id , api }) => {
      const rowModeObj = api.getRowMode(id);

      if (rowModeObj === GridRowModes.Edit) {
        return (
          <Box display="flex">
            <GridActionsCellItem
              icon={<SaveOutlined />}
              label="Save"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))}
              sx={{
                color: 'primary.main',
              }}
            />
            <GridActionsCellItem
              icon={<CancelOutlined />}
              label="Cancel"
              className="textPrimary"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }))}
              color="inherit"
            />
          </Box>
        );
      } else {
        return (
          <Box display="flex">
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit"
              className="textPrimary"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<DeleteOutlined />}
              label="Edit"
              className="textPrimary"
              onClick={() => onDelete(id.toString())}
              color="inherit"
            />
          </Box>
        );
      }
    }
  }
];

export const GenresTable = () => {
  const { isAdmin } = useCurrentUser();
  const [ genreToDelete, setGenreToDelete ] = useState<string | null>(null);
  const [ rowModesModel, setRowModesModel ] = useState<GridRowModesModel>({});
  const { data: genres = [], isInitialLoading: loading } = useGenres();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const apiRef = useGridApiRef();
  const updateMutation = useMutation({
    mutationFn: ({ id, updates }: {id: string; updates: UpdateGenreInput}) => updateGenre(id, updates),
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.GENRES);

      snackbar.success('Genre updated');
    }
  });
  const deleteMutation = useMutation({
    mutationFn: deleteGenre,
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.GENRES);

      snackbar.success('Genre deleted');
    }
  });

  const columns = createGenreTableColumns(setRowModesModel, (id) => setGenreToDelete(id), isAdmin);
  const processRowUpdate = useCallback(async (newRow: Genre) => {
    const updates = {
      title: newRow.title,
      group: newRow.group,
    };

    await updateMutation.mutateAsync({ id: newRow._id, updates });

    return newRow;
  }, [ updateMutation ]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  }, []);

  const deleteGenreModal = useMemo(() => {
    if (genreToDelete) {
      return (
        <DeleteResourceModal
          onDelete={() => {
            deleteMutation.mutate(genreToDelete);
            setGenreToDelete(null);
          }}
          onClose={() => setGenreToDelete(null)}
          description="Deleting this genre will remove it from all products."
        />
      );
    }

    return null;
  }, [ deleteMutation, genreToDelete ]);

  return (
    <>
      {deleteGenreModal}
      <Table
        apiRef={apiRef}
        rows={genres}
        columns={columns}
        loading={updateMutation.isLoading || loading}
        getRowId={(x) => x._id}
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        pinnedColumns={{ right: [ 'actions' ] }}
        editMode="row"
        onRowModesModelChange={setRowModesModel}
        onRowEditStop={handleRowEditStop}
        initialState={{
          columns: {
            columnVisibilityModel: {
              actions: isAdmin,
            }
          }
        }}
      />
    </>
  );
};
