import { Typography, TypographyProps } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DateService } from 'services';

export type StandardDateCellProps = Pick<GridRenderCellParams<any, string | undefined | null>, 'value'> & {
  fontSize?: TypographyProps['fontSize'];
};

export const StandardDateCell: React.FC<StandardDateCellProps> = ({ value }) => {
  if (!value) return <Typography component="i" sx={{ fontSize: '14px' }}>Not Specified</Typography>;

  const date = DateService.dayjs(value);

  return (
    <Typography fontSize="14px">
      {date.format('MM/DD/YY')}&nbsp;
      <Typography component="span" fontWeight={500} fontSize="inherit">{date.format('(ddd)')}</Typography>
    </Typography>
  );
};

export const LoggedAtDateCell: React.FC<StandardDateCellProps> = ({ value, fontSize = '14px' }) => { // won't be undefined
  if (!value) {
    return null;
  }

  const date = DateService.dayjs(value);

  const isToday = date.isToday();
  const isYesterday = date.isYesterday();

  const formattedTime = DateService.getFormattedTimeFromDate(date);

  let weekday = '';

  if (isToday) {
    weekday = 'Today';
  } else if (isYesterday) {
    weekday = 'Yesterday';
  } else {
    weekday = date.format('ddd');
  }

  const showYear = date.isBefore(DateService.dayjs().subtract(1, 'year'));

  return (
    <Typography fontSize={fontSize}>
      <Typography component="span" fontWeight={500} fontSize="inherit">{weekday}</Typography>
      {!(isToday || isYesterday) && date.format(` MMM DD${showYear ? ', YYYY' : ''}`)}
      &nbsp;– {formattedTime}
    </Typography>
  );
};
