import {
  GridColDef, GridColumnVisibilityModel, GridFilterModel, GridSortModel,
} from '@mui/x-data-grid';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMemo } from 'react';

export type UseTableLocalStorage = {
  id: string;
  columns: GridColDef[];
  initialVisible: string[];
  actionNotInitialVisible?: boolean;
  initialSortModel?: GridSortModel;
};

type StorageState = {
  visibilityModel: GridColumnVisibilityModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
};

export const useTableLocalStorage = ({
  id,
  initialVisible,
  actionNotInitialVisible,
  initialSortModel,
  columns,
}: UseTableLocalStorage) => {
  const localStorage = useLocalStorage<StorageState>(id);
  const localStorageValue = useMemo(() => {
    return localStorage.getResource({
      filterModel: { items: [] },
      sortModel: initialSortModel ?? [],
      visibilityModel: {
        ...columns.reduce((r, v) => ({ ...r, [v.field]: false }), {}),
        action: actionNotInitialVisible ? false : true,
        ...initialVisible.reduce((r, v) => ({ ...r, [v]: true }), {}),
      }
    });
  }, [ actionNotInitialVisible, columns, initialSortModel, initialVisible, localStorage ]);

  const initialFilter = localStorageValue?.filterModel;
  const initialSort = localStorageValue?.sortModel || initialSortModel;
  const initialColumnVisibilityModel = localStorageValue?.visibilityModel || {
    ...columns.reduce((r, v) => ({ ...r, [v.field]: false }), {}),
    action: actionNotInitialVisible ? undefined : true,
    ...initialVisible.reduce((r, v) => ({ ...r, [v]: true }), {}),
  };

  const onColumnVisibilityModelChange = (visibilityModel: GridColumnVisibilityModel) => localStorage.setResource({ ...localStorageValue,  visibilityModel });
  const onFilterModelChange = (filterModel: GridFilterModel) => localStorage.setResource({ ...localStorageValue,  filterModel });
  const onSortModelChange = (sortModel: GridSortModel) => localStorage.setResource({ ...localStorageValue,  sortModel });

  return {
    initialTableState: {
      order: [ 'action', ...initialVisible ],
      filter: initialFilter,
      sort: initialSort,
      columnVisibility: initialColumnVisibilityModel,
    },
    tableStateChange: {
      onColumnVisibilityModelChange,
      onFilterModelChange,
      onSortModelChange,
    },
  };
};
