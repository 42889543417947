import { EventContextProvider, Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useEvent } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import { Navigate, Outlet, useParams } from 'react-router-dom';

const EventBaseRaw: React.FC<{eventId: string}> = ({ eventId }) => {
  const { data: event, isInitialLoading: loading } = useEvent(eventId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!event) {
    return <Navigate to={`/${ROUTING_CONFIG.events}`} replace />;
  }

  return (
    <EventContextProvider event={event} loading={loading} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) })}>
      <Outlet />
    </EventContextProvider>
  );
};

export const EventBase = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={`/${ROUTING_CONFIG.events}`} replace />;
  }

  return <EventBaseRaw eventId={id} />;
};
