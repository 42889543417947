import { Checkbox, FormControlLabel } from '@mui/material';

export type ShowNotAtEventsInputProps = {
  showProductsNotAvailableAtEvents: boolean;
  setShowProductsNotAvailableAtEvents: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

export const ShowNotAtEventsInput: React.FC<ShowNotAtEventsInputProps> = props => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(_e, checked: boolean) => props.setShowProductsNotAvailableAtEvents(checked)}
          checked={props.showProductsNotAvailableAtEvents}
        />
      }
      label="Show products that are not offered at shows"
      disabled={props.disabled}
    />
  );
};

