import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { StockMetaKey } from '../StockMetaKey.component';

export type InventoryTableShowStockMetaInputProps = {
  showStockMeta: boolean;
  setShowStockMeta: (showStockMeta: boolean) => void;
};

export const InventoryTableShowStockMetaInput: React.FC<InventoryTableShowStockMetaInputProps> = props => {

  return (
    <FormControlLabel
      control={<Checkbox checked={props.showStockMeta} onClick={() => props.setShowStockMeta(!props.showStockMeta)} />}
      label={(
        <Box display="flex" alignItems="center" columnGap={2} rowGap={0.5} flexWrap="wrap">
          <Typography>Display stock information</Typography>
          <StockMetaKey />
        </Box>
      )}
    />
  );
};