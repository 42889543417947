import { Button } from '@mui/material';
import { PageContentContainer, PageContentHeader } from 'components';
import { EventOrganizersTable } from './components/EventOrganizersTable.component';
import { useNavigate } from 'react-router-dom';
import { useEventOrganizers } from 'queries/eventOrganizer';

export const EventOrganizers = () => {
  const navigate = useNavigate();
  const { data: eventOrganizers = [], isInitialLoading: loading } = useEventOrganizers();

  const clickHandler = () => {
    navigate('new');
  };

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Event Organizers"
          rightActions={<Button variant="contained" onClick={clickHandler} size="small">New Event Organizer</Button>}
        />
      )}
    >
      <EventOrganizersTable eventOrganizers={eventOrganizers} loading={loading} />
    </PageContentContainer>
  );
};
