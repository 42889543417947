import {
  Alert,
  AlertTitle,
  Box,
  Card, CardContent, Fade, Grid, Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { EventSalesDatesList } from './EventSalesDatesList.component';
import { EventSalesLogProductList } from './EventSalesLogProductList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateSaleInput, createSale } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { SaleTypeEnum } from 'api/resources';
import { SalesTabComponentProps } from '../../EventSales.page';
import { EventSalesTable } from '../EventSalesDisplay';
import { useEventContext } from 'components';
import { DateService } from 'services';

export type OnAddProductHandler = (values: Pick<CreateSaleInput, 'product' | 'frame' | 'payment' | 'salePrice'>) => Promise<void>;

export const EventSalesLog: React.FC<SalesTabComponentProps> = ({ sales, loading }) => {
  const queryClient = useQueryClient();
  const { event } = useEventContext();
  const saleMutation = useMutation({
    mutationFn: createSale,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY.SALES_FOR_EVENT(event._id) });
    }
  });
  const [ selectedDateId, setSelectedDateId ] = useState<string | null>(null);

  const onDateChange = (id: string) => setSelectedDateId((p) => (p === id ? null : id));

  const onAddProductHandler: OnAddProductHandler = async (values) => {
    if (!selectedDateId) {
      return;
    }

    await saleMutation.mutateAsync({ ...values, type: SaleTypeEnum.manual, eventDate: selectedDateId });
  };

  const filteredSales = useMemo(() => {
    if (!selectedDateId) {
      return [];
    }

    return sales.filter((sale) => sale.eventDate._id === selectedDateId);
  }, [ selectedDateId, sales ]);

  const formattedDate = useMemo(() => {
    if (!selectedDateId) {
      return null;
    }

    const date = event.dates.find((date) => date._id === selectedDateId);

    if (!date) {
      return null;
    }
    return DateService.getFormattedDate(DateService.dayjsTz(date?.dateAsUtc), 'standardFull');
  }, [ selectedDateId, event.dates ]);

  if (!event.team) {
    return (
      <Alert
        severity="error"
        variant="outlined"
      >
        <AlertTitle>Select a team for this event before logging sales</AlertTitle>
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h6">1. Select a Date:</Typography>
      <Typography mb={1}>To log a sale for this event, first select the date of the sale</Typography>
      <Box mb={4}>
        <EventSalesDatesList
          datesFilter={[ 'past', 'today' ]}
          onEventDateClick={onDateChange}
          selectedDateId={selectedDateId}
          emptyMessage="As of now, there are no event dates to log sales for (you can only log sales for event dates that have passed)"
        />
      </Box>

      <Fade in={Boolean(selectedDateId)} unmountOnExit exit={false}>
        <div>
          <Typography variant="h6">2. Add Products to the Log:</Typography>
          <Typography mb={1}>Find the product that was sold, click the arrow, and make the appropriate selections before clicking &apos;ADD&apos;</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" mb={2}>Products</Typography>
                  <Box ml={-2} mr={-2}>
                    <EventSalesLogProductList onAddProduct={onAddProductHandler} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="baseline" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'initial' }}>
                    <Typography variant="h6" mb={2}>Logged Sales</Typography>
                    {formattedDate && (
                      <Typography variant="body2">
                        {formattedDate}:&nbsp;{filteredSales.length}&nbsp;Sale{filteredSales.length === 1 ? '' : 's'}
                      </Typography>
                    )}
                  </Box>
                  <Box ml={-2} mr={-2}>
                    <EventSalesTable sales={filteredSales} loading={loading} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </>
  );
};
