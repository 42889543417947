import { DateSelectArg } from '@fullcalendar/react';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserAvailability } from 'api/actions';
import { User, UserAvailabilityEnum } from 'api/resources';
import { UpdateResourceModal, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import { userEnumHelpers } from 'helpers';
import { QUERY_KEY } from 'queries/query-keys';
import React from 'react';
import { DateService } from 'services';
import { formSx } from 'styles';
import { ResourceForm } from 'types';

export type UpdateAvailabilityModalProps = {
  info: DateSelectArg;
  user: User;
  onClose: () => void;
};

export const UpdateAvailabilityModal: React.FC<UpdateAvailabilityModalProps> = ({ info, user, onClose }) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateUserAvailabilityMutation = useMutation({
    mutationKey: [ 'update-user-availability' ],
    mutationFn: async (values: ResourceForm['userAvailability']) => {
      const data = {
        start: DateService.dayjs(info.start).format('YYYY-MM-DD'),
        end: DateService.dayjs(info.end).subtract(1, 'day').format('YYYY-MM-DD'),
        availability: values.availability === 'clear' ? null : values.availability,
      };

      await updateUserAvailability(user._id, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.USER_AVAILABILTY(user._id));
      snackbar.success('Availability updated');

      onClose();
    },
    onError: async () => {
      snackbar.error('Unable to update availability');
    }
  });
  const onSubmit = async (values: ResourceForm['userAvailability']) => {
    await updateUserAvailabilityMutation.mutateAsync(values);
  };

  const startStr = DateService.dayjs(info.start).format('MM/DD/YYYY');
  const endStr = DateService.dayjs(info.end).subtract(1, 'day').format('MM/DD/YYYY');
  const initialValues: ResourceForm['userAvailability'] = {
    availability: UserAvailabilityEnum.available,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open
            onClose={onClose}
            title="Set Availability"
            onSave={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
            autoHeight
          >
            <Box sx={formSx.formGroup}>
              <Typography>Select your availability from <b>{startStr}</b> to <b>{endStr}</b>:</Typography>
              <ToggleButtonGroup
                orientation="vertical"
                value={formik.values.availability}
                exclusive
                onChange={(_, value) => value && formik.setFieldValue('availability', value)}
                sx={{
                  width: 'fit-content',
                  '& .MuiToggleButton-root': {
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'flex-start'
                  },
                }}
              >
                {userEnumHelpers.availability.enumValues.map(value => {
                  const Icon = userEnumHelpers.availability.getIcon(value);

                  return (
                    <ToggleButton
                      key={value}
                      value={value}
                      color={userEnumHelpers.availability.getColor(value) as any}
                    >
                      <Icon /> {userEnumHelpers.availability.getLabel(value)}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Box>
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};
