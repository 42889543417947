import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState }from 'react';
import { TextInput, TextInputProps } from './TextInput.component';

export const PasswordTextInput: React.FC<TextInputProps> = props => {
  const [ passwordVisible, setPasswordVisible ] = useState(false);

  const passwordEndAdornment = (
    <IconButton onClick={() => setPasswordVisible((p) => !p)}>
      {passwordVisible ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  );

  return (
    <TextInput
      {...props}
      endAdornment={passwordEndAdornment}
      type={passwordVisible ? 'text' : 'password'}
    />
  );
};