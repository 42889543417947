import { Box, Chip, Typography } from '@mui/material';

type StockMetaChip = {
  label: string;
  color: 'info' | 'success' | 'warning';
};

const stockMetaChips: StockMetaChip[] = [
  { label: 'Baseline', color: 'info' },
  { label: 'Need', color: 'warning' },
  { label: 'Overstock', color: 'success' }
];

export const StockMetaKey: React.FC = () => {

  return (
    <Box display="flex" gap={0.5}>
      {stockMetaChips.map(({ label, color }) => {

        const chipLabel = (
          <Typography fontSize="15px" color={theme => theme.palette[color].main} lineHeight={1}>
            <Typography fontSize="inherit" component="span" fontWeight={500} lineHeight={1}>{label[0]}</Typography>
            {label.slice(1)}
          </Typography>
        );

        return (
          <Chip
            key={label}
            label={chipLabel}
            color={color}
            size="small"
            variant="outlined"
            sx={{
              bgcolor: theme => theme.palette[color].background,
              borderRadius: theme => theme.spacing(0.5),
            }}
          />
        );
      })}
    </Box>
  );
};