import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { GetProductsStockUpdateLogsInput, getProductsStockUpdateLogs } from 'api/actions';

export const useProductsStockUpdateLogs = (input?: GetProductsStockUpdateLogsInput, enabled = true) => {
  return useQuery(QUERY_KEY.PRODUCTS_STOCK_UPDATE_LOGS(input), async () => {
    const response = await getProductsStockUpdateLogs(input);

    return response.data;
  }, { enabled });
};