import { YesNoUnknownIconSwitchWithLabel } from 'components';
import React from 'react';

export type ProductPageSectionCardRowYesNoUnknownContentProps = {
  value: boolean | undefined;
  text: string;
};

export const ProductPageSectionCardRowYesNoUnknownContent: React.FC<ProductPageSectionCardRowYesNoUnknownContentProps> = ({ value, text }) => {
  if (value === undefined) {
    return null;
  }

  return <YesNoUnknownIconSwitchWithLabel value={value}>{value ? text : `Not ${text}`}</YesNoUnknownIconSwitchWithLabel>;
};
