import { Box, Typography } from '@mui/material';
import { FormTimePicker } from 'components';
import React from 'react';
import { formSx } from 'styles';

export const EventDateStartEndTimesForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <Box display="flex" gap={2} alignItems="center">
        <FormTimePicker
          name="startTime"
          label="Start"
        />
        <Typography color="text.secondary">to</Typography>
        <FormTimePicker
          name="endTime"
          label="End"
        />
      </Box>
    </Box>
  );
};
