import { useFormikContext } from 'formik';
import { dateRangeShortcutEnumHelpers } from './helpers';
import { Box, Button, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { ArrowRightAlt, CheckOutlined } from '@mui/icons-material';
import { DateRangeFormInput } from './DateRangePickerPopover.component';

export type DateRangeFormProps = {
  onApply: () => void;
  onCancel: () => void;
};

export const DateRangeForm: React.FC<DateRangeFormProps> = ({ onApply, onCancel }) => {
  const { values, setValues, isValid } = useFormikContext<DateRangeFormInput>();
  const { dateRange } = values;
  const selectedShortcut = dateRangeShortcutEnumHelpers.getValueForDateRange(dateRange);

  return (
    <Box>

      <Box>
        <Box display="flex" height="100%">
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            width="200px"
            borderRight={theme => `1px solid ${theme.palette.divider}`}
            boxShadow={theme => `inset 0 -16px 16px -16px ${theme.palette.divider}`}
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Box flex={1} overflow="auto" display="flex" flexDirection="column" gap={1}>
                <List>
                  {dateRangeShortcutEnumHelpers.enumValues.map(dateRangeShortcut => {
                    const dateRange = dateRangeShortcutEnumHelpers.getDateRange(dateRangeShortcut);
                    const isSelected = selectedShortcut === dateRangeShortcut;

                    return (
                      <ListItemButton
                        selected={isSelected}
                        key={dateRangeShortcut}
                        onClick={() => dateRange && setValues({ dateRange })}
                        sx={{ position: 'relative' }}
                      >
                        <ListItemText
                          primary={dateRangeShortcutEnumHelpers.getLabel(dateRangeShortcut)}
                          primaryTypographyProps={{ fontWeight: isSelected ? 500 : 400, fontSize: 'small' }}
                        />
                        {isSelected && (
                          <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                            <CheckOutlined fontSize="small" color="primary" />
                          </Box>
                        )}
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box py={1.5} pt={2} display="flex" alignItems="center">
              <Box flex={1} px={2} textAlign="center">
                <Typography fontWeight={500} fontSize="large">{dateRange.start.format('MMMM Do, YYYY') ?? '___'}</Typography>
              </Box>
              <Box display="flex" color="text.secondary">
                <ArrowRightAlt fontSize="large" color="inherit" />
              </Box>
              <Box flex={1} px={2} textAlign="center">
                <Typography fontWeight={500} fontSize="large">{dateRange.end.format('MMMM Do, YYYY') ?? '___'}</Typography>
              </Box>
            </Box>
            <Divider />
            <StaticDateRangePicker
              value={[ dateRange.start, dateRange.end ]}
              onChange={(value) => value[0] && value[1] && setValues({ dateRange: { start: value[0], end: value[1] } })}
              slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
              calendars={2}
            />
          </Box>
        </Box>

      </Box>

      <Divider />
      <Box display="flex" justifyContent="flex-end" py={2} gap={2} px={2}>
        <Button onClick={onCancel} size="small" color="inherit">Cancel</Button>
        <Button onClick={onApply} disabled={!isValid} size="small" variant="contained">Apply</Button>
      </Box>
    </Box>
  );
};