import axios, { AxiosRequestConfig } from 'axios';
import { setupInterceptorsTo } from './interceptors';
import { REACT_APP_ENVIRONMENT, ReactAppEnvironment } from 'config/environment.config';

const urlHash: Record<ReactAppEnvironment, string> = {
  development: 'http://localhost:5000',
  staging: 'https://api.staging.astrologisticsapp.com',
  production: 'https://api.astrologisticsapp.com'
};

const baseUrl = urlHash[REACT_APP_ENVIRONMENT];

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export const authAxiosInstance = setupInterceptorsTo(axios.create({
  baseURL: baseUrl,
  withCredentials: true,
}));

export const authAxiosRequest = async <Response>(url: string, config: AxiosRequestConfig = {}) => {
  const response = await authAxiosInstance.request<Response>({
    url,
    method: 'GET',
    ...config
  });

  return response.data;
};