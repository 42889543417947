import { Box } from '@mui/material';
import { ReactNode } from 'react';

export type FormFooterType = {
  children: ReactNode;
  fixedOnMobileOnly?: boolean;
};

export const FormFooter: React.FC<FormFooterType> = ({ children }) => {

  return (
    <>
      <Box sx={(theme) => {
        return {
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: theme.zIndex.drawer - 1,
          background: theme.palette.background.default,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          padding: 2,
          boxShadow: `inset 0 1px 0 ${theme.palette.divider}`,
        };
      }}
      >
        {children}
      </Box>
      <Box height="74px" />
    </>
  );
};
