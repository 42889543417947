import { Box, Button, Card } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { AddStaffToEventDateInput, GetEventDateAddStaffUserOptions, addStaffToEventDate } from 'api/actions';
import { EventDateStaffStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useEventContext, useAlertSnackbar, Select, EmployeeForm } from 'components';
import { FORMAT_DATE } from 'constants/format-date';
import { Formik } from 'formik';
import { QUERY_KEY } from 'queries/query-keys';
import { DateService } from 'services';
import { userEnumHelpers, getSelectOptionsFromEnumHelper } from 'helpers';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useCurrentUser } from 'contexts';
import { STAFF_CARD_DIMENSIONS } from './StaffCard.component';

export type AddStaffCardProps = {
  eventDateId: string;
  workdayTotalInMinutes: number | null;
  onExit: () => void;
  userOptionsData: GetEventDateAddStaffUserOptions['data'];
};

export const AddStaffCard: React.FC<AddStaffCardProps> = ({ eventDateId, workdayTotalInMinutes, onExit, userOptionsData }) => {
  const currentUser = useCurrentUser();
  const { event } = useEventContext();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const eventDate = event.dates.find(e => e._id === eventDateId);
  const fullFormattedDate = eventDate ? DateService.dayjsTz(eventDate.dateAsUtc).format(FORMAT_DATE.standardFull) : 'Event Date';
  const addStaffMutation = useMutation({
    mutationFn: (input: AddStaffToEventDateInput) => addStaffToEventDate(eventDateId, input),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_DATE_ADD_STAFF_USER_OPTIONS(eventDateId) });
      snackbar.success(`Staff added to ${fullFormattedDate}`);
      onExit();
    },
    onError: () => {
      snackbar.error(`Unable to add staff to ${fullFormattedDate}`);
    }
  });
  const canEditOthers = event.teamManager?._id === currentUser._id || currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  const canSelfConfirm = currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ]);
  const staffStatusOptions = useMemo(() => {
    if (canEditOthers) {
      return getSelectOptionsFromEnumHelper(userEnumHelpers.staffStatus);
    }

    const options = [ { value: EventDateStaffStatusEnum.selfRequested, label: 'Request' } ];

    if (canSelfConfirm) {
      options.push({ value: EventDateStaffStatusEnum.confirmed, label: 'Confirm' });
    }

    return options;
  }, [ canEditOthers, canSelfConfirm ]);

  const initialValues: AddStaffToEventDateInput = {
    user: canEditOthers ? '' : currentUser._id,
    status: '',
  };

  return (
    <Formik
      onSubmit={(values) => addStaffMutation.mutateAsync(values)}
      initialValues={initialValues}
    >
      {(formik) => {
        return (
          <Card variant="outlined" sx={{ ...STAFF_CARD_DIMENSIONS, borderRadius: 0 }}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" gap={1.7} py={1.5} px={2}>
              <Box display="flex" flexDirection="column" gap={1}>
                <EmployeeForm userOptionsData={userOptionsData} disabled={!canEditOthers} workdayTotalInMinutes={workdayTotalInMinutes} />
                <Select
                  label="Status"
                  name="status"
                  options={staffStatusOptions}
                  size="small"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" gap={1}>
                <Button onClick={onExit} size="small" sx={{ height: 32 }}>Cancel</Button>
                <LoadingButton variant="contained" size="small" sx={{ height: 32 }} onClick={() => formik.handleSubmit()}>Save</LoadingButton>
              </Box>
            </Box>
          </Card>
        );
      }}
    </Formik>
  );
};