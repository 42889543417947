import { Box, CssBaseline, Toolbar } from '@mui/material';
import React from 'react';
import { LightAppbar, LightAppbarProps } from './LightAppbar.component';

export type LightLayoutProps = {
  children: React.ReactNode;
  appbarProps?: LightAppbarProps;
};

export const LightLayout: React.FC<LightLayoutProps> = props => {
  return (
    <Box display="flex" height="100%">
      <CssBaseline />
      <LightAppbar {...props.appbarProps} />
      <Box
        component="main"
        sx={() => {
          return {
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'hidden',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
          };
        }}
      >
        <Toolbar />
        <Box flexGrow={1} display="flex" flexDirection="column">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};
