import { Autocomplete } from 'components';
import { useEventOrganizers } from 'queries';

export const EventOrganizerForm: React.FC = () => {
  const { data: eventOrganizers = [], isInitialLoading: eventOrganizersLoading } = useEventOrganizers();

  return (
    <Autocomplete
      label="Event Organizer"
      name="eventOrganizer"
      options={eventOrganizers}
      getOptionLabel={(opt) => opt?.name}
      transformValue={(opt) => opt?._id}
      loading={eventOrganizersLoading}
      autoFocus
      openOnFocus
    />
  );
};