import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetEventOrganizerResponse, getEventOrganizer, getEventOrganizers } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useEventOrganizers = () => {
  return useQuery(QUERY_KEY.EVENT_ORGANIZERS, async () => {
    const response = await getEventOrganizers();

    return response.data;
  });
};

export const useEventOrganizer = (id: string, opts?: UseQueryOptions<GetEventOrganizerResponse['data']>) => {
  return useQuery(QUERY_KEY.EVENT_ORGANIZER(id), async () => {
    const response = await getEventOrganizer(id);

    return response.data;
  }, opts);
};