import { Box } from '@mui/material';
import { PasswordTextInput } from 'components';
import { formSx } from 'styles';

export const ChangePasswordForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <PasswordTextInput
        name="currentPassword"
        label="Password"
        helperText="Enter your current password"
      />
      <PasswordTextInput
        name="newPassword"
        label="New Password"
        helperText="Enter a new password"
      />
    </Box>
  );
};