import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetEventResponse, getUpcomingEventsForTeamsHash, getEvent, getEventTeamChangeOptions, getEvents, getEventsForEventOrganizer, GetUpcomingEventsForTeamsHashInput, GetUpcomingEventsForVehiclesHashInput, getUpcomingEventsForVehiclesHash, getEventVehicleChangeOptions, GetEventsForEventOrganizerInput, GetEventsResponse, getEventsForDashboard, getEventsCommunicationLog, GetEventsCommunicationLogInput } from 'api/actions';
import { QUERY_KEY } from './query-keys';
import { DateService } from 'services';

export const useEvents = (opts?: UseQueryOptions<GetEventsResponse['data']>) => {
  return useQuery(QUERY_KEY.EVENTS, async () => {
    const response = await getEvents();

    return response.data;
  }, opts);
};

export const useTodaysEvents = () => {
  const today = DateService.dayjs().format('YYYY-MM-DD');

  return useQuery(QUERY_KEY.EVENTS_TODAY(today), async () => {
    const response = await getEvents(today);

    return response.data.map(event => {
      const todaysEventDate = event.dates.filter(({ dateAsUtc }) => DateService.dayjsTz(dateAsUtc).format('YYYY-MM-DD') === today)[0];

      return {
        ...event,
        todaysEventDate
      };
    });
  });
};

export const useUpcomingEventsForTeamsHash = (input?: GetUpcomingEventsForTeamsHashInput) => {
  return useQuery(QUERY_KEY.TEAMS_EVENTS(input), async () => {
    const response = await getUpcomingEventsForTeamsHash(input);

    return response.data;
  });
};

export const useUpcomingEventsForTeam = (input: GetUpcomingEventsForTeamsHashInput & { teamId: string }) => {
  return useQuery(QUERY_KEY.TEAM_EVENTS(input), async () => {
    const response = await getUpcomingEventsForTeamsHash(input);

    return response.data[input.teamId];
  });
};

export const useUpcomingEventsForVehiclesHash = (input?: GetUpcomingEventsForVehiclesHashInput) => {
  return useQuery(QUERY_KEY.VEHCILES_EVENTS(input), async () => {
    const response = await getUpcomingEventsForVehiclesHash(input);

    return response.data;
  });
};

export const useUpcomingEventsForVehicle = (input: GetUpcomingEventsForVehiclesHashInput & { vehicleId: string }) => {
  return useQuery(QUERY_KEY.VEHCILE_EVENTS(input), async () => {
    const response = await getUpcomingEventsForVehiclesHash(input);

    return response.data[input.vehicleId];
  });
};

export const useEvent = (id: string, opts?: UseQueryOptions<GetEventResponse['data']>) => {
  return useQuery(QUERY_KEY.EVENT(id), async () => {
    const response = await getEvent(id);

    return response.data;
  }, opts);
};

export const useEventsForEventOrganizer = (eventOrganizerId: string, input?: GetEventsForEventOrganizerInput) => {
  return useQuery(QUERY_KEY.EVENTS_FOR_EVENT_ORGANIZER(eventOrganizerId, input), async () => {
    const response = await getEventsForEventOrganizer(eventOrganizerId, input);

    return response.data;
  });
};

export const useEventTeamChangeOptions = (eventId: string) => {
  return useQuery(QUERY_KEY.EVENT_TEAM_CHANGE_OPTIONS(eventId), async () => {
    const response = await getEventTeamChangeOptions(eventId);

    return response.data;
  });
};

export const useEventVehicleChangeOptions = (eventId: string) => {
  return useQuery(QUERY_KEY.EVENT_VEHICLE_CHANGE_OPTIONS(eventId), async () => {
    const response = await getEventVehicleChangeOptions(eventId);

    return response.data;
  });
};

export const useEventsForDashboard = () => {
  return useQuery(QUERY_KEY.EVENTS_FOR_DASHBOARD, async () => {
    const response = await getEventsForDashboard();

    return response.data;
  });
};

export const useEventsCommunocationLog = (input?: GetEventsCommunicationLogInput) => {
  return useQuery(QUERY_KEY.EVENTS_COMMUNICATION_LOG(input), async () => {
    const response = await getEventsCommunicationLog(input);

    return response.data;
  });
};