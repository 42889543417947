import { useQuery } from '@tanstack/react-query';
import { getUser, getUserAvailability, getUsers, getUsersAsResourceOptions, getUsersAsResourceOverview, getUsersAvailabilityHashForEvent, getUsersAvailabilityHashForEventDate, getUsersAvailabilitySetHash } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useUsers = () => {
  return useQuery(QUERY_KEY.USERS, async () => {
    const response = await getUsers();

    return response.data;
  });
};

export const useUsersAsResourceOptions = () => {
  return useQuery(QUERY_KEY.USERS_AS_RESOURCE_OPTIONS, async () => {
    const response = await getUsersAsResourceOptions();

    return response.data;
  });
};

export const useUsersAsResourceOverview = () => {
  return useQuery(QUERY_KEY.USERS_AS_RESOURCE_OVERVIEW, async () => {
    const response = await getUsersAsResourceOverview();

    return response.data;
  });
};

export const useUser = (id: string) => {
  return useQuery(QUERY_KEY.USER(id), async () => {
    const response = await getUser(id);

    return response.data;
  });
};

export const useUserAvailability = (id: string, enabled: boolean = true) => {
  return useQuery(QUERY_KEY.USER_AVAILABILTY(id), async () => {
    const response = await getUserAvailability(id);

    return response.data;
  }, { enabled });
};

export const useUsersAvailabilityForEventDate = (eventDateId: string) => {
  return useQuery(QUERY_KEY.USERS_AVAILABILTY_FOR_EVENT_DATE_HASH(eventDateId), async () => {
    const response = await getUsersAvailabilityHashForEventDate(eventDateId);

    return response.data;
  });
};

export const useUsersAvailabilityForEvent = (eventId: string) => {
  return useQuery(QUERY_KEY.USERS_AVAILABILTY_FOR_EVENT_HASH(eventId), async () => {
    const response = await getUsersAvailabilityHashForEvent(eventId);

    return response.data;
  });
};

export const useUsersAvailabilitySetHash = () => {
  return useQuery(QUERY_KEY.USERS_AVAILABILTY_SET_HASH, async () => {
    const response = await getUsersAvailabilitySetHash();

    return response.data;
  });
};

export const useUserAvailabilitySet = (userId: string) => {
  return useQuery(QUERY_KEY.USER_AVAILABILTY_SET(userId), async () => {
    const response = await getUsersAvailabilitySetHash();

    return response.data[userId];
  });
};
