import React, { UIEvent, useEffect, useMemo, useState } from 'react';
import { StickyStackProvider } from '../StickyStack';
import { Box, BoxProps } from '@mui/material';

export type ScrollableViewContextValue = {
  scrollTo: (scrollToOptions: ScrollToOptions) => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
};

export const ScrollableViewContext = React.createContext<ScrollableViewContextValue | null>(null);


const fallbackScrollableView: ScrollableViewContextValue = {
  scrollTo: (opts?: ScrollToOptions) => window.scrollTo(opts),
};


export const useScrollableView = () => {
  const context = React.useContext(ScrollableViewContext);

  return context ?? fallbackScrollableView;
};

export type ScrollableViewProps = {
  enableScrollShadow?: boolean;
} & BoxProps;

export const ScrollableView: React.FC<ScrollableViewProps> = ({ children, enableScrollShadow, ...props }) => {
  const [ scrollState, setScrollState ] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const value = React.useMemo(() => ({
    scrollTo: (scrollToOptions: ScrollToOptions) => {
      scrollContainer.current?.scrollTo(scrollToOptions);
    },
    scrollContainerRef: scrollContainer
  }), []);

  useEffect(() => {
    if (enableScrollShadow) {
      setScrollState({
        scrollTop: scrollContainer.current?.scrollTop ?? 0,
        scrollHeight: scrollContainer.current?.scrollHeight ?? 0,
        clientHeight: scrollContainer.current?.clientHeight ?? 0,
      });
    }
  }, [ children, enableScrollShadow ]);

  const boxShadow = useMemo(() => {
    const { clientHeight, scrollHeight, scrollTop } = scrollState;

    let boxShadow = 'none';

    if (!enableScrollShadow || scrollHeight <= clientHeight) {
      return boxShadow;
    }

    const scrollAmount = Math.floor(scrollHeight - scrollTop);

    const isBottom = clientHeight === scrollAmount;
    const isTop = scrollTop === 0;
    const isBetween = !isBottom && !isTop;

    const top = 'inset 0 8px 5px -5px rgba(0, 0, 0, 0.3)';
    const bottom = 'inset 0 -8px 5px -5px rgba(0, 0, 0, 0.3)';

    if (isTop) {
      boxShadow = bottom;
    } else if (isBetween) {
      boxShadow = `${top}, ${bottom}`;
    } else if (isBottom) {
      boxShadow = top;
    }

    return boxShadow;
  }, [ enableScrollShadow, scrollState ]);

  return (
    <ScrollableViewContext.Provider value={value}>
      <StickyStackProvider>
        <Box {...props} flex={1} position="relative">
          <Box position="absolute" top={0} bottom={0} width="100%" height="100%" boxShadow={boxShadow} zIndex={1} sx={{ pointerEvents: 'none' }} />
          <Box
            component="div"
            ref={scrollContainer}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              width: '100%',
              height: '100%',
              overflow: 'hidden auto',
            }}
            onScroll={(e: UIEvent<HTMLDivElement>) =>
              enableScrollShadow && setScrollState({
                scrollTop: e.currentTarget.scrollTop,
                scrollHeight: e.currentTarget.scrollHeight,
                clientHeight: e.currentTarget.clientHeight,
              })
            }
          >
            {children}
          </Box>
        </Box>
      </StickyStackProvider>
    </ScrollableViewContext.Provider>
  );
};