import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getProductShopifyInfo } from 'api/actions';

export const useProductShopifyInfo = (shopifyProductId: number, enabled: boolean = true) => {
  return useQuery(QUERY_KEY.PRODUCT_SHOPIFY_INFO(shopifyProductId), async () => {
    const response = await getProductShopifyInfo(shopifyProductId);

    return response.data;
  }, { enabled });
};