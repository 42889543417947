import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getStorefronts } from 'api/actions';

export const useStorefronts = () => {
  return useQuery(QUERY_KEY.STOREFRONTS, async () => {
    const response = await getStorefronts();

    return response.data;
  }, {});
};