import { Box, Card, Typography } from '@mui/material';

export type SectionCardProps = {
  title: string;
  children: React.ReactNode;
  id?: string;
  actions?: React.ReactNode;
};

export const SectionCard: React.FC<SectionCardProps> = props => {

  return (
    <Box component="div" id={props.id}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6" fontWeight={400}>{props.title}</Typography>
        {props.actions}
      </Box>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 0,
          '& > [role="row"]:not(:last-of-type)': { borderBottom: theme => `1px solid ${theme.palette.divider}` },
          marginX: { xs: -2, sm: 0 }
        }}
      >
        {props.children}
      </Card>
    </Box>
  );
};