import { Box, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CardContentContainer, EventDatesList, useEventContext } from 'components';

export type EventDatesCardProps = {
  showButton?: boolean;
  isCalendar?: boolean;
};

export const EventDatesCard: React.FC<EventDatesCardProps> = ({ showButton = true, isCalendar = false }) => {
  const { event } = useEventContext();
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate('dates');
  };

  const eventDateClickHandler = (eventDateId: string) => {
    if (isCalendar) {
      navigate(eventDateId);
    } else {
      navigate(`dates/${eventDateId}`);
    }
  };

  return (
    <CardContentContainer title="Dates" action={showButton && <Button size="small" onClick={clickHandler} variant="outlined">Add / Edit</Button>}>
      <Box mx={-2}>
        <EventDatesList
          event={event}
          isCalendar={isCalendar}
          onEventDateClick={eventDateClickHandler}
        />
      </Box>
    </CardContentContainer>
  );
};
