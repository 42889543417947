import { FilterListOutlined } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';

export const FilterButton: React.FC<ButtonProps> = props => {

  return (
    <Button variant="outlined" startIcon={<FilterListOutlined />} {...props}>
        Filters
    </Button>
  );
};