import { GridCellParams } from '@mui/x-data-grid';
import { EventDateStaffStatusEnum } from 'api/resources';
import { UserStaffStatusChip } from 'components/Chips/custom/UserStaffStatusChip.component';

export type UserStaffStatusCellType = GridCellParams<any, EventDateStaffStatusEnum>;

export const UserStaffStatusCell: React.FC<UserStaffStatusCellType> = ({ value }) => {
  if (!value) return null;
  return <UserStaffStatusChip size="small" value={value} />;
};
