import {
  Alert,
  Box,
  Button, DialogActions, DialogContent, DialogTitle, Theme, useMediaQuery,
} from '@mui/material';
import { Modal, ModalType } from './Modal.component';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { ResourceForm } from 'types';
import { useMutation } from '@tanstack/react-query';
import { UserCredentialsForm, UserCredentialsFormProps, validateUserCredentialsSchema } from 'components/UserCredentialsModal';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useCurrentUser } from 'contexts';
import { validateCredentials } from 'api/actions';

type UpdateResourceModalBaseProps = {
  open: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
  loading?: boolean;
  saveDisabled?: boolean;
  saveButtonText?: string;
};
type UpdateResourceModalPropsWithCredentials = UpdateResourceModalBaseProps & {
  requireCredentials: true;
  onSave: (userId: string) => void;
  userCredentialsFormProps?: UserCredentialsFormProps;
};
type UpdateResourceModalPropsWithoutCredentials = UpdateResourceModalBaseProps & {
  requireCredentials?: false;
  onSave: () => void;
  userCredentialsFormProps?: never;
};

export type UpdateResourceModalProps = ModalType & (UpdateResourceModalPropsWithCredentials | UpdateResourceModalPropsWithoutCredentials);

export const UpdateResourceModal: React.FC<UpdateResourceModalProps> = ({
  open = false,
  title,
  onClose,
  children,
  onSave,
  loading,
  saveDisabled,
  saveButtonText = 'Save',
  requireCredentials,
  userCredentialsFormProps,
  ...props
}) => {
  const formik = useFormikContext();
  const userCredentialsFormikRef = useRef<FormikProps<ResourceForm['validateCredentials']>>(null);
  const { _id: meId } = useCurrentUser();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const snackbar = useAlertSnackbar();
  const { values, dirty } = useFormikContext();
  const [ exitWarning, setExitWarning ] = useState(false);
  const [ credentialsStep, setCredentialsStep ] = useState(false);
  const validateCredentialsMutation = useMutation({
    mutationFn: (form: ResourceForm['validateCredentials']) => {
      if (form.credentialType === 'password') {
        return validateCredentials({ userId: form.userId, password: form.password });
      } else {
        return validateCredentials({ userId: form.userId, pin: form.pin });
      }
    },
    onSuccess: async (_, form) => {
      await onSave(form.userId);
    },
    onError: () => {
      snackbar.error('Invalid Credentials. Please, try again.');
    }
  });

  useEffect(() => {
    setExitWarning(false);
  }, [ values ]);

  const onConfirmExit = () => {
    setExitWarning(false);
    onClose();
  };

  // go back to form step when modal is re-opened
  useEffect(() => {
    if(open) {
      setCredentialsStep(false);
    }
  }, [ open ]);

  const onCloseHandler = (isIcon: boolean) => {
    if (!loading) {
      if (!exitWarning && dirty) {
        setExitWarning(true);
      } else if (isIcon) {
        onConfirmExit();
      }
    }
  };


  const confirmButtonClickHandler = async () => {
    if (credentialsStep) {
      if (!userCredentialsFormikRef.current) {
        return;
      }
      const res = await userCredentialsFormikRef.current.validateForm();

      if (Object.keys(res).length === 0) {
        validateCredentialsMutation.mutate(userCredentialsFormikRef.current.values);
      }
    } else {
      if (requireCredentials) {
        const res = await formik.validateForm();

        if (Object.keys(res).length === 0) {
          setCredentialsStep(true);
        }
      } else {
        onSave();
      }
    }
  };
  const cancelButtonClickHandler = () => {
    if (credentialsStep) {
      setCredentialsStep(false);
    } else {
      onClose();
    }
  };

  const cancelButtonText = (requireCredentials && credentialsStep) ? 'Back' : 'Cancel';
  const confirmButtonText = useMemo(() => {
    if (requireCredentials && !credentialsStep) return 'Confirm';

    return loading ? 'Saving...' : saveButtonText;
  }, [ credentialsStep, loading, requireCredentials, saveButtonText ]);

  const content = useMemo(() => {
    const initialUserCredentialsState: ResourceForm['validateCredentials'] = {
      userId: meId,
      password: '',
      pin: '',
      credentialType: 'pin',
    };

    if (credentialsStep) {
      return (
        <Formik
          innerRef={userCredentialsFormikRef}
          initialValues={initialUserCredentialsState}
          onSubmit={(value) => validateCredentialsMutation.mutateAsync(value)}
          validationSchema={validateUserCredentialsSchema}
        >
          <UserCredentialsForm {...userCredentialsFormProps} />
        </Formik>
      );
    }

    return children;
  }, [ children, credentialsStep, meId, userCredentialsFormProps, validateCredentialsMutation ]);

  return (
    <Modal
      open={open}
      onClose={() => onCloseHandler(false)}
      onCloseIcon={() => onCloseHandler(true)}
      fullScreen={isSmall}
      slotProps={{ backdrop: { style: { backgroundColor: exitWarning ? 'rgba(105, 69, 69, 0.67)' : undefined } } }}
      topLeftIcon="close"
      {...props}
    >
      <>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent dividers sx={{ pt: 3, pb: 5 }}>{content}</DialogContent>
        {!exitWarning && (
          <DialogActions sx={(theme) => {
            return {
              padding: theme.spacing(2),
              display: 'flex',
              '& > button': {
                flex: 1,
              },
            };
          }}
          >
            <Button
              onClick={cancelButtonClickHandler}
              disabled={loading}
              color="inherit"
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              {cancelButtonText}
            </Button>
            <Button
              onClick={confirmButtonClickHandler}
              disabled={loading || saveDisabled}
              color="primary"
              variant="contained"
              type="button"
            >
              {confirmButtonText}
            </Button>
          </DialogActions>)}
        {exitWarning &&
          <Alert
            severity="error"
            action={(
              <Box display="flex" alignItems="center" gap={1}>
                <Button size="small" color="inherit" onClick={onConfirmExit}>Yes</Button>
                <Button size="small" color="inherit" onClick={() => setExitWarning(false)}>No</Button>
              </Box>
            )}
          >
            Wait! Are you sure you want to exit without saving?
          </Alert>
        }
      </>
    </Modal>
  );
};
