import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

export type UnaryOperationCellType = Record<'initialValue' | 'newValue', number> & {
  restrictNegative?: boolean;
  noColor?: boolean;
};

export const UnaryOperationCell: React.FC<UnaryOperationCellType> = ({ initialValue, newValue, restrictNegative = false, noColor = false }) => {
  const theme = useTheme();
  const difference = newValue;

  const value = restrictNegative ? Math.max(initialValue + difference, 0) : initialValue + difference;

  const operand = difference > 0 ? '+' : '-';

  const differenceAbsolute = Math.abs(difference);

  const backgroundColor = useMemo(() => {
    if (noColor) {
      return undefined;
    }
    return operand === '+' ? theme.palette.success.background : theme.palette.error.background;
  }, [ noColor, operand, theme.palette.error.background, theme.palette.success.background ]);

  const color = useMemo(() => {
    if (noColor) {
      return undefined;
    }
    return operand === '+' ? theme.palette.success.main : theme.palette.error.main;
  }, [ noColor, operand, theme.palette.error.main, theme.palette.success.main ]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        background: backgroundColor,
        px: 1,
      }}
    >
      {initialValue}
        &nbsp;
      <Typography
        variant="body2"
        sx={{ color }}
      >
        {operand}
        &nbsp;
        {differenceAbsolute}
      </Typography>
        &nbsp;=&nbsp;
      {value}
    </Box>
  );
};