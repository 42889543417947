import { Box, Stack, Typography } from '@mui/material';
import { getUntilApplicationOpenColorCodeConfig, getUntilApplicationDeadlineColorCodeConfig, getApplicationHasBeenOpenColorCodeConfig, getApplicationDateRangeLabel } from 'helpers';
import React from 'react';
import { DateService } from 'services';
import { ThemePalette } from 'types';

export type EventApplicationDateRangeContentProps = {
  applicationOpenDate?: string;
  applicationIsOpen?: boolean;
  applicationDeadlineDate?: string;
  applicationOpenUntilFull?: boolean;
};

export const EventApplicationDateRangeContent: React.FC<EventApplicationDateRangeContentProps> = ({ applicationOpenDate, applicationIsOpen, applicationDeadlineDate, applicationOpenUntilFull }) => {
  const untilApplicationOpenConfig = getUntilApplicationOpenColorCodeConfig(applicationOpenDate, applicationIsOpen);
  const applicationHasBeenOpenConfig = getApplicationHasBeenOpenColorCodeConfig(applicationOpenDate, applicationIsOpen);
  const untilApplicationDeadlineConfig = getUntilApplicationDeadlineColorCodeConfig(applicationDeadlineDate, applicationOpenUntilFull);
  const applicationDateRangeLabel = getApplicationDateRangeLabel(applicationOpenDate, applicationIsOpen, applicationDeadlineDate, applicationOpenUntilFull);

  if(!applicationOpenDate && !applicationIsOpen && !applicationDeadlineDate && !applicationOpenUntilFull) {
    return <span>{applicationDateRangeLabel}</span>;
  }

  if (!applicationIsOpen && applicationOpenDate && DateService.dayjs().isBefore(applicationOpenDate, 'day')) {
    return (
      <Stack gap={0.5}>
        <Box display="flex" gap={1}>
          {applicationDateRangeLabel}
          <ColorChip color="success">
            Upcoming
          </ColorChip>
        </Box>
        <Box display="flex" gap={1}>
          Until application open:
          <ColorChip solid={untilApplicationOpenConfig.solid} color={untilApplicationOpenConfig.color}>
            {untilApplicationOpenConfig.label}
          </ColorChip>
        </Box>
      </Stack>
    );
  }

  if ((applicationOpenDate ? !DateService.dayjs().isBefore(applicationOpenDate, 'day') : applicationIsOpen) && (applicationDeadlineDate ? !DateService.dayjs().isAfter(applicationDeadlineDate, 'day') : applicationOpenUntilFull)) {
    return (
      <Stack gap={0.5}>
        <Box display="flex" gap={1}>
          {applicationDateRangeLabel}
          <ColorChip color="warning">
            Open
          </ColorChip>
        </Box>
        <Box display="flex" gap={1}>
          Application has been open for:&nbsp;
          <ColorChip color={applicationHasBeenOpenConfig.color} solid={applicationHasBeenOpenConfig.solid}>
            {applicationHasBeenOpenConfig.label}
          </ColorChip>
        </Box>
        <Box display="flex" gap={1}>
          Until application deadline:&nbsp;
          <ColorChip color={untilApplicationDeadlineConfig.color} solid={untilApplicationDeadlineConfig.solid}>
            {untilApplicationDeadlineConfig.label}
          </ColorChip>
        </Box>
      </Stack>
    );
  }

  return (
    <Box display="flex" gap={1}>
      {applicationDateRangeLabel}
      <ColorChip color="error">
        Closed
      </ColorChip>
    </Box>
  );
};

type ColorChipProps = {
  color: ThemePalette;
  solid?: boolean;
  children: React.ReactNode;
};

const ColorChip: React.FC<ColorChipProps> = ({ color, solid, children }) => {
  return (
    <Typography
      variant="body2"
      component="span"
      px={1}
      bgcolor={theme => theme.palette[color][solid ? 'main' : 'background']}
      color={theme => solid ? theme.palette[color].contrastText : theme.palette[color].main}
      border={theme => `1px solid ${theme.palette[color].main}`}
    >
      {children}
    </Typography>
  );
};