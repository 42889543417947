import { Card, CardContent, Dialog, DialogContent, Skeleton } from '@mui/material';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';

export type AnalyticsChartWrapperProps = {
  loading?: boolean;
  children: React.ReactNode;
  fullScreen?: boolean;
};

export const CHART_HEIGHT = 320;
export const CHART_CARD_HEIGHT = CHART_HEIGHT + 175;

export const AnalyticsChartWrapper: React.FC<AnalyticsChartWrapperProps> = ({ loading, children, fullScreen }) => {
  if(loading) {
    return <Skeleton variant="rectangular" width="100%" height={CHART_CARD_HEIGHT} sx={{ borderRadius: 1 }} />;
  }

  if (fullScreen) {
    return (
      <Dialog fullScreen fullWidth open sx={{ zIndex: FULL_SCREEN_Z_INDEX, bgcolor: theme => theme.palette.grey[50] }} keepMounted>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};