import { CollectionsOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { EventDate } from 'api/resources';
import { eventDateEnumHelpers } from 'helpers';

export type EventDateImagesListProps = {
  onClick: (index: number) => void;
  images: EventDate['images'];
  size: number;
};

export const EventDateImagesList: React.FC<EventDateImagesListProps> = props => {
  if (!props.images.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={props.size ? { height: props.size } : { my: 4 }}>
        <Stack alignItems="center" gap={1}>
          <CollectionsOutlined color="disabled" fontSize="large"/>
          <Typography color="text.secondary" fontStyle="italic">No photos uploaded yet.</Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        overflow: 'auto',
        maxWidth: '100%',
      }}
    >
      {props.images.map((image, index) => {
        return (
          <Box
            key={index}
            component="div"
            sx={{
              width: props.size,
              minWidth: props.size,
              height: props.size,
              mb: 2,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
              '&:hover img': {
                transform: 'scale(1.05)',
              },
              '&:hover::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.3)',
              },
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease',
              },
              '&::after': {
                transition: 'background-color 0.3s ease',
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0)',
              },
            }}
            onClick={() => props.onClick(index)}
          >
            <img src={image.url} alt="Descriptive Alt Text" />
            <Typography
              component="span"
              variant="caption"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                zIndex: 100,
                width: '100%',
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              {eventDateEnumHelpers.imageType.getLabel(image.type)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};