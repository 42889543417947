import { OpenInNewOutlined, Square } from '@mui/icons-material';
import { Box, Button, Grid, Typography,
} from '@mui/material';
import { CardContentContainer, CardContentGrid, CardContentLink, PageContentContainer, PageContentHeader } from 'components';
import { useTeams, useUpcomingEventsForTeamsHash } from 'queries';
import { useNavigate } from 'react-router-dom';

export const Teams = () => {
  const navigate = useNavigate();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: upcomingEventsForTeamsHash = {}, isInitialLoading: upcomingEventsForTeamsHashLoading } = useUpcomingEventsForTeamsHash({ limit: 1 });

  return (
    <PageContentContainer
      loading={teamsLoading}
      header={(
        <PageContentHeader
          title="Teams"
          rightActions={<Button variant="contained" disabled size="small">New Team</Button>}
        />
      )}
    >
      <Grid container spacing={2}>
        {teams.map(team => {
          const currentEvent = upcomingEventsForTeamsHash[team._id]?.[0];

          return (
            <Grid key={team._id} item xs={12} sm={6} md={4} minHeight={200}>
              <CardContentContainer
                showEditOption
                editOptionIcon={<OpenInNewOutlined />}
                onEdit={() => navigate(team._id)}
                disableAutoHeight
                clickable
                title={(
                  <Box display="flex" alignItems="center">
                    {team.color && <><Square htmlColor={team.color} />&nbsp;</>}
                    <Typography variant="h6" display="flex" alignItems="center">
                      {team.name}
                      {team.teamLead && (
                        <Typography display="flex" alignItems="center" color="text.secondary">
                        &nbsp;(managed by&nbsp;<CardContentLink href={`/events/${team.teamLead._id}`} title={team.teamLead.name} onClick={e => e.stopPropagation()} />)
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                )}
              >
                <CardContentGrid
                  items={[
                    {
                      title: `${currentEvent?.type === 'current' ? 'Current' : 'Upcoming'} Event`,
                      type: 'full',
                      loading: upcomingEventsForTeamsHashLoading,
                      value: currentEvent ? <CardContentLink href={`/events/${currentEvent.event._id}`} title={currentEvent.event.name} onClick={e => e.stopPropagation()} /> : <Box>––</Box>
                    },
                  ]}
                />
              </CardContentContainer>
            </Grid>
          );
        })}
      </Grid>
    </PageContentContainer>
  );
};
