import { SectionCardRowEditable, SectionCardRowEditableProps } from 'components';
import { ProductPageSectionRowId, ProductSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useProductOutletContext } from '../Product.base';

export type ProductSectionCardRowEditableProps<RowId extends ProductPageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<ProductSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const ProductSectionCardRowEditable = <RowId extends ProductPageSectionRowId>(props: ProductSectionCardRowEditableProps<RowId>) => {
  const { product } = useProductOutletContext();

  return <SectionCardRowEditable {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCT(product._id) })} />;
};