import { DateRange } from 'components';
import { useFormikContext } from 'formik';
import { analyticsCompareToEnumHelpers } from '../helpers';
import { Box, Button, List, ListItemButton, ListItemText } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';

export type AnalyticsCompareToFormProps = {
  inputDateRange: NonNullable<DateRange>;
  onApply: () => void;
  onCancel: () => void;
};

export const AnalyticsCompareToForm: React.FC<AnalyticsCompareToFormProps> = ({ inputDateRange, onApply, onCancel }) => {
  const { values, setValues } = useFormikContext<{dateRange: DateRange}>();
  const selectedShortcut = values.dateRange && analyticsCompareToEnumHelpers.getValueForDateRange(inputDateRange, values.dateRange);

  return (
    <>
      <List>
        <ListItemButton
          selected={!selectedShortcut}
          onClick={() => setValues({ dateRange: null })}
          sx={{ position: 'relative' }}
        >
          <ListItemText
            primary="No comparison"
            primaryTypographyProps={{ fontWeight: !selectedShortcut ? 500 : 400, fontSize: 'small' }}
          />
          {!selectedShortcut && (
            <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
              <CheckOutlined fontSize="small" color="primary" />
            </Box>
          )}
        </ListItemButton>
        {analyticsCompareToEnumHelpers.enumValues.map(compareTo => {
          const dateRange = analyticsCompareToEnumHelpers.getDateRange(inputDateRange, compareTo);
          const isSelected = selectedShortcut === compareTo;

          return (
            <ListItemButton
              selected={isSelected}
              key={compareTo}
              onClick={() => dateRange && setValues({ dateRange })}
              sx={{ position: 'relative' }}
            >
              <ListItemText
                primary={analyticsCompareToEnumHelpers.getLabel(compareTo)}
                primaryTypographyProps={{ fontWeight: isSelected ? 500 : 400, fontSize: 'small' }}
              />
              {isSelected && (
                <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                  <CheckOutlined fontSize="small" color="primary" />
                </Box>
              )}
            </ListItemButton>
          );
        })}
      </List>
      <Box display="flex" justifyContent="flex-end" py={2} gap={2} px={2}>
        <Button onClick={onCancel} size="small" color="inherit">Cancel</Button>
        <Button onClick={onApply} size="small" variant="contained">Apply</Button>
      </Box>
    </>
  );
};