import Chart, { Plugin } from 'chart.js/auto/auto.mjs';

// draws 8 px long vertical lines from x-axis to tick label
export const tickMarkPlugin: Plugin = {
  id: 'tickMarkPlugin',
  beforeDatasetDraw: (chart) => {
    const { ctx, scales } = chart;

    ctx.save();
    ctx.strokeStyle = '#B8B8B8';
    ctx.lineWidth = 1;

    scales.x.ticks.forEach((_, index) => {
      const x = scales.x.getPixelForTick(index);

      ctx.beginPath();
      ctx.moveTo(x, scales.x.top);
      ctx.lineTo(x, scales.x.top + 8);
      ctx.stroke();
    });

    ctx.restore();
  }
};

// draws vertical line through the tooltip origin
const verticalLinePlugin: Plugin = {
  id: 'verticalLineOnHover',
  beforeTooltipDraw: (chart: Chart) => {
    // have to ts-ignore because typescript type doesn't include _config
    // while it's actually present on the object
    // @ts-ignore
    if (chart.tooltip && [ 'bar', 'line' ].includes(chart.config._config?.type)) {
      const x = chart.tooltip.caretX;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      const ctx = chart.ctx;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.setLineDash([ 5, 5 ]);
      ctx.strokeStyle = '#B8B8B8';
      ctx.stroke();
      ctx.restore();
    }
  },
};

// applies border dashes on bar chart
const dashedBorderPlugin: Plugin = {
  id: 'dashedBorderPlugin',
  beforeDatasetDraw: function(chart, args) {
    // borderDash is a custom field
    // @ts-ignore
    if(chart.data.datasets[args.index].borderDash && chart.config._config?.type === 'bar') {
      const dataset = chart.data.datasets[args.index];

      args.meta.data.forEach((element, index) => {
        const borderWidth = 1;
        const ctx = chart.ctx;
        // Set border width to 0 to avoid drawing the default border

        // eslint-disable-next-line no-param-reassign
        element.options.borderWidth = 0;

        // Calculate dimensions
        // @ts-ignore
        const halfWidth = element.width / 2;
        const left = element.x - halfWidth;
        const right = element.x + halfWidth;
        let top, height;
        const value = dataset.data[index] as number;

        if (value >= 0) { // For positive values
          top = element.y;
          height = chart.scales.y.getPixelForValue(0) - top;
        } else { // For negative values
          top = chart.scales.y.getPixelForValue(0);
          height = element.y - top;
        }

        // Drawing dashed lines
        ctx.beginPath();
        ctx.lineWidth = borderWidth;
        ctx.strokeStyle = element.options.borderColor as string; // Default to black if borderColor is not set
        ctx.setLineDash([ 5, 5 ]);
        // Left vertical line
        ctx.moveTo(left, top);
        ctx.lineTo(left, top + height);

        if (value >= 0) {
          // Draw top horizontal line for positive values
          ctx.moveTo(left, top);
          ctx.lineTo(right, top);
        } else {
          // Draw bottom horizontal line for negative values
          ctx.moveTo(left, top + height);
          ctx.lineTo(right, top + height);
        }

        // Right vertical line
        ctx.moveTo(right, top);
        ctx.lineTo(right, top + height);
        ctx.stroke();
        ctx.setLineDash([]); // Reset line dash to ensure it does not affect other elements
        ctx.restore();
      });
    }
  }
};

export const registerChartPlugins = () => {
  Chart.register(tickMarkPlugin);
  Chart.register(dashedBorderPlugin);
  Chart.register(verticalLinePlugin);
};
