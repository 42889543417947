import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetMeErrorResponse, LoginUserErrorResponse, LoginUserInput, getMe, loginUser, logoutUser } from 'api/actions';
import { User } from 'api/resources';
import { initialLoadingAndErrorState, InitialLoadingAndErrorState } from 'app/loading-state';

export type AuthSliceState = {
  accessToken: string | null;
  sessionId: string | null;
  me: User | null;
} & InitialLoadingAndErrorState;

export const logoutUserThunk = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await logoutUser();
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const loginUserThunk = createAsyncThunk(
  'auth/login',
  async (input: LoginUserInput) => {
    try {
      const { data } = await loginUser(input);

      return data;
    } catch (err: unknown) {
      const error = err as LoginUserErrorResponse;
      let message = 'Invalid Credentials';

      if (error.response?.data?.errorKey === 'deactivated') {
        message = 'Account has been deactivated';
      }

      throw new Error(message);
    }
  },
);

export const getMeThunk = createAsyncThunk(
  'auth/getMe',
  async () => {
    try {
      const { data } = await getMe();

      return data;
    } catch (err: unknown) {
      const error = err as GetMeErrorResponse;
      let message = 'User not found';

      if (error.response?.data?.errorKey === 'deactivated') {
        message = 'Account has been deactivated';
      }

      throw new Error(message);
    }
  },
);

const initialState: AuthSliceState = {
  accessToken: null,
  sessionId: null,
  me: null,
  ...initialLoadingAndErrorState,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    replaceAccessToken(state, action: { payload: string | null }) {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUserThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.accessToken = action.payload.accessToken;
      state.me = action.payload.me;
      state.sessionId = action.payload.sessionId;
      state.error = null;
    }).addCase(loginUserThunk.pending, (state) => {
      state.loading = 'posting';
      state.accessToken = null;
      state.sessionId = null;
      state.me = null;
      state.error = null;
    }).addCase(loginUserThunk.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.error.message ?? 'There was an error';
    }).addCase(getMeThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.me = action.payload.me;
      state.sessionId = action.payload.sessionId;
    })
      .addCase(getMeThunk.pending, (state) => {
        state.loading = !state.me ? 'pending' : 'refetching';
        state.error = null;
      })
      .addCase(getMeThunk.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.error.message ?? 'There was an error';
      })
      .addCase(logoutUserThunk.fulfilled, (state, _action) => {
        state.loading = 'idle';
        state.accessToken = null;
        state.sessionId = null;
        state.me = null;
      })
      .addCase(logoutUserThunk.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(logoutUserThunk.rejected, (state) => {
        state.loading = 'idle';
        state.accessToken = null;
        state.sessionId = null;
        state.me = null;
      });
  },
});

export const authActions = authSlice.actions;
