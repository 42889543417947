import { ArrowRightAltSharp } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

export type StockQtyCellContentProps = {
  quantity: number;
  updateQuantity: number | undefined;
  isSetOperation?: boolean;
};

export const StockQtyCellContent: React.FC<StockQtyCellContentProps> = props => {
  const theme = useTheme();
  const { quantity, updateQuantity, isSetOperation } = props;

  if (updateQuantity === undefined) {
    return <Typography className="MuiDataGrid-cellContent" fontWeight={500}>{quantity}</Typography>;
  }

  if (isSetOperation) {
    const isLess = updateQuantity < quantity;
    const isMore = updateQuantity > quantity;
    let color = theme.palette.text.secondary;
    let sign = '';

    if (isLess) {
      color = theme.palette.error.main;
      sign = '-';
    }
    if (isMore) {
      color = theme.palette.success.main;
      sign = '+';
    }

    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography component="span" className="MuiDataGrid-cellContent" color="text.disabled">{quantity}</Typography>
        <Box color={color} display="flex" position="relative">
          <Box position="absolute" bottom="100%" textAlign="center" width="100%">
            <Typography fontSize="small" lineHeight={1}>{sign}{Math.abs(updateQuantity - quantity)}</Typography>
          </Box>
          <ArrowRightAltSharp sx={{ mx: 0.75 }} fontSize="small" color="inherit" />
          {isLess && (
            <Box position="absolute" top="100%" textAlign="center" left={0} right={0}>
              <Typography fontSize="small" lineHeight={1}>Missing</Typography>
            </Box>
          )}
        </Box>
        <Typography component="span" className="MuiDataGrid-cellContent" fontWeight={500}>{updateQuantity}</Typography>
      </Box>
    );
  }

  const updateQuantityAbs = Math.abs(updateQuantity);
  const isNegative = updateQuantity < 0;
  const sign = isNegative ? '-' : '+';
  const color = isNegative ? theme.palette.error.main : theme.palette.success.main;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
      <span>
        <Box component="span" className="MuiDataGrid-cellContent" color="text.disabled">{quantity}</Box>
        <Box component="span" sx={{ borderBottom: `2px solid ${color}` }} ml={0.75} color={color}>
          <Box component="span" mr={0.75}>{sign}</Box>
          <Box component="span" className="MuiDataGrid-cellContent">{updateQuantityAbs}</Box>
        </Box>
      </span>
      <span>
        <Box component="span" mx={0.75}>=</Box>
        <Typography component="span" className="MuiDataGrid-cellContent" fontWeight={500}>{quantity + updateQuantity}</Typography>
      </span>
    </Box>
  );
};