import { Box, FormControlLabel } from '@mui/material';
import { Select, Checkbox } from 'components';
import { useFormikContext } from 'formik';
import { getSelectOptionsFromEnumHelper, userEnumHelpers } from 'helpers';
import { useEventDateAddStaffUserOptions } from 'queries/eventDate';
import { formSx } from 'styles';
import { EventDateSectionRowForm } from '../types';
import { useMemo } from 'react';
import { EmployeeForm } from './Employee.form';

export type EventDateStaffFormProps = {
  eventDateId: string;
  workdayTotalInMinutes: number | null;
  hideTeamLeadField?: boolean;
  hideUserField?: boolean;
};

export const EventDateStaffForm: React.FC<EventDateStaffFormProps> = ({ eventDateId, workdayTotalInMinutes, hideTeamLeadField, hideUserField }) => {
  const { initialValues } = useFormikContext<EventDateSectionRowForm['staff']>();
  const { data: userOptionsData = [] } = useEventDateAddStaffUserOptions(eventDateId);
  const staffStatusOptions = useMemo(() => getSelectOptionsFromEnumHelper(userEnumHelpers.staffStatus), []);
  const showTeamLead = initialValues.isTeamLead || !hideTeamLeadField;

  return (
    <Box sx={formSx.formGroup}>
      {!hideUserField && <EmployeeForm userOptionsData={userOptionsData} workdayTotalInMinutes={workdayTotalInMinutes} />}
      <Select
        label="Status"
        name="status"
        options={staffStatusOptions}
      />
      {showTeamLead && (
        <FormControlLabel control={<Checkbox name="isTeamLead" />} label="This is a Team Lead" />
      )}
    </Box>
  );
};