import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { GetTeamsResponse } from 'api/actions';
import React from 'react';

export type TeamSelectionInputProps = {
  teams: GetTeamsResponse['data'];
  setSelectedTeams: React.Dispatch<React.SetStateAction<GetTeamsResponse['data']>>;
  selectedTeams: GetTeamsResponse['data'];
  disabled?: boolean;
};

export const TeamSelectionInput: React.FC<TeamSelectionInputProps> = props => {
  const onSelectTeam = (team: GetTeamsResponse['data'][number]) => {
    props.setSelectedTeams(prev => {
      const teamExists = prev.some(({ _id }) => _id === team._id);

      if (teamExists) {
        return prev.filter(({ _id }) => _id !== team._id);
      }

      return [ ...prev, team ].sort((a, b) => a.order - b.order);
    });
  };

  return (
    <Box display="flex" gap={1} flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start">
      {props.teams.map((team) => {
        const isSelected = props.selectedTeams.some(({ _id }) => _id === team._id);

        const label = <Typography>{team.name}</Typography>;

        return (
          <FormControlLabel
            disabled={props.disabled}
            key={team._id}
            control={<Switch checked={isSelected} onClick={() => onSelectTeam(team)} />}
            label={label}
            sx={{ userSelect: 'none', flex: '0 1 30%', whiteSpace: 'nowrap' }}
          />
        );
      })}
    </Box>
  );
};