import { ChangeEvent } from 'react';
import { Area } from 'react-easy-crop';

export const downloadBlob = async (blobUrl: string) => fetch(blobUrl, { cache: 'no-cache' }).then(res => res.blob());

export const getBlobFromEvent = (e: ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files;
  const file = files?.[0];

  if (file) {
    return fileToUrl(file);
  }
  return undefined;
};

export const fileToUrl = (file: File) => {
  return URL.createObjectURL(file);
};

export const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
  downloadBlob(url)
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const image = new Image();

      image.addEventListener('load', ()=> resolve(image));
      image.addEventListener('error', error => reject(error));
      image.src = blobUrl;
    })
    .catch(err => {
      reject(err);
    });
});

export async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise<string>(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file as Blob));
    }, 'image/jpeg');
  });
}