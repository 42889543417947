import { MenuItem, Avatar, Typography, ListSubheader, lighten, Select } from '@mui/material';
import { GetEventDateAddStaffUserOptions } from 'api/actions';
import { UserAvailabilityEnum } from 'api/resources';
import { WrapWithStatusIndicator } from 'components';
import { useFormikContext } from 'formik';
import { SelectOptionType, userEnumHelpers } from 'helpers';
import { useMemo } from 'react';
import { currencyFormatter, getUserInitialsForAvatar } from 'services';
import { UnknownEnum } from 'types';

const mapOptions = (options: SelectOptionType[]) => {
  return options.map(option => (
    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
      {option.label}
    </MenuItem>
  ));
};

const initialGroupedByAvailability: Record<UserAvailabilityEnum | 'unknown', SelectOptionType[]>  = {
  available: [],
  undetermined: [],
  notAvailable: [],
  unknown: [],
};

export const getUserOptionsDataGroupedByAvailability = (userOptionsData: GetEventDateAddStaffUserOptions['data'], workdayTotalInMinutes: number | null) => {
  const transformedUserOptionsData = userOptionsData.map((option) => {
    const status = userEnumHelpers.availability.getColor(option.availability);
    const key: UserAvailabilityEnum | UnknownEnum.unknown = option.availability || UnknownEnum.unknown;

    return {
      value: option.userId,
      label: (
        <WrapWithStatusIndicator status={status}>
          <Avatar src={option.userProfileImageUrl} sx={{ width: 25, height: 25 }}><Typography variant="body2">{getUserInitialsForAvatar(option.userName)}</Typography></Avatar>
          {option.userName}
          {option.payRate && workdayTotalInMinutes && ` - ${currencyFormatter.format(option.payRate)}/h, ~${currencyFormatter.format(option.payRate * workdayTotalInMinutes / 60)} total `}
          {option.conflictEvent && ` (working at ${option.conflictEvent})`}
        </WrapWithStatusIndicator>
      ),
      disabled: !!option.conflictEvent,
      key,
    };
  });

  return transformedUserOptionsData.reduce((acc, option) => {
    return { ...acc, [option.key]: [ ...acc[option.key], option ] };
  }, initialGroupedByAvailability);
};

export type EmployeeFormProps = {
  userOptionsData: GetEventDateAddStaffUserOptions['data'];
  workdayTotalInMinutes: number | null;
  disabled?: boolean;
};

export const EmployeeForm: React.FC<EmployeeFormProps> = ({ userOptionsData, workdayTotalInMinutes, disabled }) => {
  const groupedByAvailability = useMemo(() => getUserOptionsDataGroupedByAvailability(userOptionsData, workdayTotalInMinutes), [ userOptionsData, workdayTotalInMinutes ]);
  const { setFieldValue, values } = useFormikContext<{user: string}>();

  return (
    <Select
      size="small"
      disabled={disabled}
      value={values.user}
      renderValue={p => {
        const option = userOptionsData.find(({ userId }) => userId === p);

        if (!option) {
          return null;
        }

        const status = userEnumHelpers.availability.getColor(option.availability);

        return (
          <WrapWithStatusIndicator status={status}>
            <Avatar src={option.userProfileImageUrl} sx={{ width: 25, height: 25 }}><Typography variant="body2">{getUserInitialsForAvatar(option.userName)}</Typography></Avatar>
            {option.userName}
          </WrapWithStatusIndicator>
        );
      }}
      onChange={(e) => setFieldValue('user', e.target.value)}
    >
      <UserListSubheader availability={UserAvailabilityEnum.available} />
      {mapOptions(groupedByAvailability.available)}
      <UserListSubheader availability={UserAvailabilityEnum.undetermined} />
      {mapOptions(groupedByAvailability.undetermined)}
      <UserListSubheader availability={UserAvailabilityEnum.notAvailable} />
      {mapOptions(groupedByAvailability.notAvailable)}
      <UserListSubheader />
      {mapOptions(groupedByAvailability.unknown)}
    </Select>
  );
};

type UserListSubheaderProps = {
  availability?: UserAvailabilityEnum;
};

const UserListSubheader: React.FC<UserListSubheaderProps> = (props) => {
  const color = userEnumHelpers.availability.getColor(props.availability);

  return (
    <ListSubheader
      sx={{
        background: theme => lighten(theme.palette[color].light, 0.7),
        color: theme => theme.palette[color].dark,
        userSelect: 'none',
      }}
    >
      {userEnumHelpers.availability.getLabel(props.availability)}
    </ListSubheader>
  );
};