import { GridCellParams } from '@mui/x-data-grid';
import { DatesStatusChip } from 'components/Chips/custom/DatesStatusChip.component';
import { EventDatesStatusEnum } from 'types';

export type DatesStatusCellType = GridCellParams<any, EventDatesStatusEnum>;

export const DatesStatusCell: React.FC<DatesStatusCellType> = ({ value }) => {
  if (!value) return null;

  return <DatesStatusChip size="small" value={value} />;
};
