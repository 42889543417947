import { object, string, mixed } from 'yup';

export const validateUserCredentialsSchema = object({
  userId: string().required('User ID is required'),
  credentialType: mixed<'password' | 'pin'>().oneOf([ 'password', 'pin' ]).required('Credential type is required'),
  pin: string().default('').when('credentialType', {
    is: 'pin',
    then: string().required('Pin is required'),
  }),
  password: string().default('').when('credentialType', {
    is: 'password',
    then: string().required('Password is required'),
  }),
});