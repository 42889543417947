import { GetTeamsResponse } from 'api/actions';

export const getWarehouseTeamIds = (teams: GetTeamsResponse['data']) => {
  return getWarehouseTeams(teams).map((team) => team._id);
};

export const getWarehouseTeams = (teams: GetTeamsResponse['data']) => {
  return teams.filter((team) => team.isWarehouse);
};

export const getNonWarehouseTeams = (teams: GetTeamsResponse['data']) => {
  return teams.filter((team) => !team.isWarehouse);
};

export const getTeamShortLabel = (teamName: string) => {
  return teamName.split(' ').map(s => s[0]).join('').toUpperCase();
};