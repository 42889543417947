import { Box, Typography } from '@mui/material';
import { CheckboxGroup, CheckboxItemValueType, UpdateResourceModal } from 'components';
import { Formik } from 'formik';
import { CALENDAR_FULL_SCREEN_MODAL_ZINDEX } from 'constants/full-screen';
import { useMemo } from 'react';
import { ShowTheseFieldsConfig } from './types';
import { EnumHelpers } from 'helpers/enums/types';

export type ShowTheseFieldsModalProps<Field extends string, Group extends string, Resource, Extra = undefined> = {
  config: ShowTheseFieldsConfig<Field, Group, Resource, Extra>[];
  showTheseFields: Field[];
  groupEnumHelpers?: EnumHelpers<Group>;
  onClose: () => void;
  onShowTheseFieldsChange: (showTheseFields: Field[]) => void;
};

type ShowTheseFieldsFormType<Field> = {
  showTheseFields: Field[];
};

const getShowTheseFieldsOptions = <Field extends string, Group extends string, Resource extends unknown, Extra extends unknown = undefined>(showTheseFieldsConfig: ShowTheseFieldsConfig<Field, Group, Resource, Extra>[]): CheckboxItemValueType[] => {
  return showTheseFieldsConfig.map((fieldConfig) => ({
    label: fieldConfig.label,
    value: fieldConfig.field,
    disableTypography: true,
    sx: { mr: 0 },
  }));
};

export const ShowTheseFieldsModal = <Field extends string, Group extends string, Resource extends unknown, Extra extends unknown = undefined>({ config, onClose, showTheseFields, onShowTheseFieldsChange, groupEnumHelpers }: ShowTheseFieldsModalProps<Field, Group, Resource, Extra>) => {
  const onSubmit = ({ showTheseFields }: ShowTheseFieldsFormType<Field>) => {
    onShowTheseFieldsChange(showTheseFields);
    onClose();
  };

  const initialValues: ShowTheseFieldsFormType<Field> = {
    showTheseFields,
  };

  const showTheseFieldsGroups = useMemo(() => {
    const groups = [ ...(groupEnumHelpers?.enumValues ?? []), undefined ];

    return groups.map(group => {
      return { group, values: getShowTheseFieldsOptions(config.filter(c => c.group === group)) };
    }).filter(({ values }) => values.length);
  }, [ config, groupEnumHelpers?.enumValues ]);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formik) => (
        <UpdateResourceModal
          open
          onClose={onClose}
          onSave={formik.handleSubmit}
          title="Show These Fields"
          sx={{ zIndex: CALENDAR_FULL_SCREEN_MODAL_ZINDEX }}
        >
          <Box>
            {showTheseFieldsGroups.map(({ group, values }, index) => {
              return (
                <>
                  {groupEnumHelpers && <Typography variant="subtitle1" fontWeight={500} mt={index ? 2 : -2} mb={-0.5}>{groupEnumHelpers.getLabel(group)}</Typography>}
                  <Box ml={!!groupEnumHelpers ? 2 : 0}>
                    <CheckboxGroup
                      key={group}
                      fullWidth
                      name="showTheseFields"
                      values={values}
                    />
                  </Box>
                </>
              );
            })}
          </Box>
        </UpdateResourceModal>
      )}
    </Formik>
  );
};
