import { EventDateImageTypeEnum, EventDateSetupBreakdownTypeEnum, EventDateTypeEnum } from 'api/resources';
import { EnumHelpers, EnumHelpersWithIcon } from './types';
import { DirectionsCarOutlined, HardwareOutlined, HelpOutlined, Inventory2Outlined, KeyOutlined, StorefrontOutlined } from '@mui/icons-material';

const type: EnumHelpersWithIcon<EventDateTypeEnum> & { getShortLabel: (value: EventDateTypeEnum) => string } = {
  enumValues: [
    EventDateTypeEnum.regular,
    EventDateTypeEnum.travel,
    EventDateTypeEnum.setup,
    EventDateTypeEnum.breakdown,
  ],
  getShortLabel: value => {
    switch (value) {
      case EventDateTypeEnum.regular:
        return 'Sales';
      case EventDateTypeEnum.travel:
        return 'Travel';
      case EventDateTypeEnum.setup:
        return 'Setup';
      case EventDateTypeEnum.breakdown:
        return 'Breakdown';
      default:
        return 'Unknown';
    }
  },
  getLabel: value => {
    switch (value) {
      case EventDateTypeEnum.regular:
        return 'Sales Day';
      case EventDateTypeEnum.travel:
        return 'Travel Day';
      case EventDateTypeEnum.setup:
        return 'Setup Day';
      case EventDateTypeEnum.breakdown:
        return 'Breakdown Day';
      default:
        return 'Unknown';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case EventDateTypeEnum.travel:
        return DirectionsCarOutlined;
      case EventDateTypeEnum.regular:
        return StorefrontOutlined;
      case EventDateTypeEnum.setup:
        return HardwareOutlined;
      case EventDateTypeEnum.breakdown:
        return Inventory2Outlined;
      default:
        return HelpOutlined;
    }
  },
  getColor: () => 'unknown',
};

const imageType: EnumHelpers<EventDateImageTypeEnum> = {
  enumValues: [
    EventDateImageTypeEnum.arrival,
    EventDateImageTypeEnum.setupComplete,
    EventDateImageTypeEnum.boothStraightOut,
    EventDateImageTypeEnum.boothStreetLeft,
    EventDateImageTypeEnum.boothStreetRight,
    EventDateImageTypeEnum.insideVehicle,
    EventDateImageTypeEnum.other,
  ],
  getLabel: value => {
    switch (value) {
      case EventDateImageTypeEnum.arrival:
        return 'Arrival';
      case EventDateImageTypeEnum.setupComplete:
        return 'Complete setup';
      case EventDateImageTypeEnum.boothStraightOut:
        return 'Looking out of booth';
      case EventDateImageTypeEnum.boothStreetLeft:
        return 'Booth Street View - Left';
      case EventDateImageTypeEnum.boothStreetRight:
        return 'Booth Street View - Right';
      case EventDateImageTypeEnum.insideVehicle:
        return 'Vehicle inside';
      case EventDateImageTypeEnum.other:
      default:
        return 'Other';
    }
  },
  getColor: () => 'unknown'
};

const setupBreakdownType: EnumHelpersWithIcon<EventDateSetupBreakdownTypeEnum> = {
  enumValues: [
    EventDateSetupBreakdownTypeEnum.soft,
    EventDateSetupBreakdownTypeEnum.hard,
  ],
  getLabel: value => {
    switch (value) {
      case EventDateSetupBreakdownTypeEnum.soft:
        return 'Soft';
      case EventDateSetupBreakdownTypeEnum.hard:
      default:
        return 'Hard';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case EventDateSetupBreakdownTypeEnum.soft:
        return KeyOutlined;
      case EventDateSetupBreakdownTypeEnum.hard:
      default:
        return HardwareOutlined;
    }
  },
  getColor: () => 'unknown',
};

export const eventDateEnumHelpers = {
  setupBreakdownType,
  type,
  imageType
};