import { Box, Slider } from '@mui/material';
import { useState } from 'react';
import Cropper, { CropperProps, Point } from 'react-easy-crop';

export type ImageCropProps = {
  image: CropperProps['image'];
  onCropComplete: CropperProps['onCropComplete'];
};

export const ImageCrop: React.FC<ImageCropProps> = props => {
  const [ crop, setCrop ] = useState<Point>({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);

  return (
    <Box height="100%" display="flex">
      <Box flex={1} display="flex" position="relative">
        <Cropper
          image={props.image}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={props.onCropComplete}
        />
      </Box>
      <Box width="64px" display="flex" position="relative">
        <Box position="absolute" height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" py={4}>
          <Slider
            orientation="vertical"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(_e, zoom) => setZoom(Number(zoom))}
          />
        </Box>
      </Box>
    </Box>
  );
};