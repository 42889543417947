import { Table } from 'components';
import { salesPerEventColumns } from './columns';
import { transformSalesForTablePerType } from './helpers';
import { SalesTableProps } from './types';

export const SalesPerEventTable: React.FC<SalesTableProps> = ({ eventsSales, loading }) => {
  const rows = transformSalesForTablePerType(eventsSales, (sale) => ({ name: sale.event.name, id: sale.event._id }));

  return (
    <Table
      getRowId={(x) => x._id}
      rows={rows}
      columns={salesPerEventColumns}
      loading={loading}
      disableRowSelectionOnClick
    />
  );
};
