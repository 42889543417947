import { authAxiosRequest } from 'api/axios';
import { Event, EventDate, Product, Sale, Storefront, Team, User } from 'api/resources';
import { Order } from 'api/resources/order';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetEventsSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    event: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
      team: PickFromResource<Team, 'name' | 'color'>;
    }>;
    eventDate: PickFromResource<EventDate, 'dateAsUtc'>;
    order?: PickFromResource<Order, 'name'>;
    storefront: never;
    user?: PickFromResource<User, 'name'>;
    saleDate: string;
  }>[];
};

export type GetSalesInput = {
  startDate?: string;
  endDate?: string;
};

export const getEventsSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetEventsSalesResponse>('/api/sales', { params: { ...params, kind: 'events' } });
};

export type GetStorefrontsSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    event: never;
    eventDate: never;
    order?: PickFromResource<Order, 'name'>;
    storefront: Storefront;
    user?: PickFromResource<User, 'name'>;
    saleDate: string;
  }>[];
};

export const getStorefrontsSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetStorefrontsSalesResponse>('/api/sales', { params: { ...params, kind: 'storefronts' } });
};

export type GetSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    event?: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
      team: PickFromResource<Team, 'name' | 'color'>;
    }>;
    eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
    order?: PickFromResource<Order, 'name'>;
    storefront?: Storefront;
    user?: PickFromResource<User, 'name'>;
    saleDate: string;
  }>[];
};

export const getSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetSalesResponse>('/api/sales', { params: params });
};

export type GetSalesAsResourceAnalyticsDataInput = {
  userId?: string;
  productId?: string;
  teamId?: string;
  eventId?: string;
  eventDateId?: string;
};
export type GetSalesAsResourceAnalyticsDataResponse = {
  data: ResourceWithPopulated<Sale, {
    event?: PickFromResource<Event, 'timezone'>;
    eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
    saleDate: string;
  }>[];
};

export const getSalesAsResourceAnalyticsData = (input: GetSalesAsResourceAnalyticsDataInput) => {
  const { productId, teamId, eventId, eventDateId, userId } = input;
  const params = { asResource: 'analyticsData' };

  if(productId) {
    return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>(`/api/products/${productId}/sales`, { params });
  }
  if(teamId) {
    return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>(`/api/teams/${teamId}/sales`, { params });
  }
  if(eventId) {
    return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>(`/api/events/${eventId}/sales`, { params });
  }
  if(eventDateId) {
    return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>(`/api/event-dates/${eventDateId}/sales`, { params });
  }
  if(userId) {
    return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>(`/api/users/${userId}/sales`, { params });
  }

  return authAxiosRequest<GetSalesAsResourceAnalyticsDataResponse>('/api/sales', { params });
};

export type GetSalesForEventResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    event: PickFromResource<Event, 'timezone'>;
    eventDate: PickFromResource<EventDate, 'dateAsUtc'>;
    order?: PickFromResource<Order, 'name'>;
    saleDate: string;
  }>[];
};

export const getSalesForEvent = (eventId: string) => {
  return authAxiosRequest<GetSalesForEventResponse>(`/api/events/${eventId}/sales`);
};

export type GetSaleResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name' | 'imageUrl'>;
    event?: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
      team: PickFromResource<Team, 'name'>;
    }>;
    eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
    storefront?: Storefront;
    order?: PickFromResource<Order, 'name'>;
    user?: PickFromResource<User, 'name'>;
    saleDate: string;
  }>;
};

export const getSale = (id: string) => {
  return authAxiosRequest<GetSaleResponse>(`/api/sales/${id}`);
};

export type CreateSaleInput = {
  product: string;
  eventDate: string;
} & Pick<Sale, 'type' | 'payment' | 'frame' | 'salePrice'>;

export const createSale = (data: CreateSaleInput) => {
  return authAxiosRequest<GetSaleResponse>('/api/sales', { method: 'POST', data });
};

export type UpdateSaleInput = Partial<Omit<CreateSaleInput, 'eventDate' | 'type'>>;

export const updateSale = (id: string, data: UpdateSaleInput) => {
  return authAxiosRequest<GetSaleResponse>(`/api/sales/${id}`, { method: 'PUT', data });
};

export const deleteSale = (id: string) => {
  return authAxiosRequest(`/api/sales/${id}`, { method: 'DELETE' });
};