import { UpdateProductStocksQuantityInput } from 'api/actions';
import { InventoryRow, SaveInventoryEditsParams } from './useInventoryTable.hook';
import { ProductStockUpdateLogTransferTypeEnum, ProductStockUpdateLogTypeEnum } from 'api/resources';

type UpdateStock = UpdateProductStocksQuantityInput['stocks'][number];

export const prepareInventoryTableEditsToSave = (params: SaveInventoryEditsParams) => {
  const productsHash = params.products.reduce((hash: Record<string, InventoryRow>, product) => ({ [product._id]: product, ...hash }), {});
  const getProduct = (productId: string) => productsHash[productId];
  const getProductStock = (productId: string, teamId: string) => getProduct(productId).stocks[teamId];
  const transferConfig = params.transferConfig;

  return Object.entries(params.edits).reduce((allUpdates: UpdateStock[], [ productId, teamUpdates ]) => {
    return Object.entries(teamUpdates).reduce((productUpdates, [ teamId, { value: updateAmount } ]) => {
      const stock = getProductStock(productId, teamId);

      if (params.isTransferOperation) {
        const inStock = stock;
        const outStock = getProductStock(productId, transferConfig.out);

        const inUpdate: UpdateStock = {
          _id: inStock._id,
          updateAmount,
          updatedQuantity: inStock.quantity + updateAmount,
          type: ProductStockUpdateLogTypeEnum.transfer,
          transferType: ProductStockUpdateLogTransferTypeEnum.to,
          transferStock: outStock._id,
        };
        const outUpdate: UpdateStock = {
          _id: outStock._id,
          updateAmount: -updateAmount,
          updatedQuantity: outStock.quantity - updateAmount,
          type: ProductStockUpdateLogTypeEnum.transfer,
          transferType: ProductStockUpdateLogTransferTypeEnum.from,
          transferStock: inStock._id,
        };

        return [ ...productUpdates, inUpdate, outUpdate ];
      }

      if (params.isSetOperation) {

        // skip if the stock is already set to the update amount
        if (updateAmount === stock.quantity) {
          return productUpdates;
        }

        const update: UpdateStock = {
          _id: stock._id,
          updateAmount: updateAmount - stock.quantity,
          updatedQuantity: updateAmount,
          type: ProductStockUpdateLogTypeEnum.set,
        };

        return [ ...productUpdates, update ];
      }

      const update: UpdateStock = {
        _id: stock._id,
        updateAmount,
        updatedQuantity: stock.quantity + updateAmount,
        type: ProductStockUpdateLogTypeEnum.unary,
      };

      return [ ...productUpdates, update ];
    }, allUpdates);
  }, []);
};