import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import { GetSortedDataProps, getSortedData, maxCountOptions } from 'pages/Analytics/helpers';
import { useMemo, useState } from 'react';
import { GetEventsSalesResponse, GetSalesResponse } from 'api/actions';
import { AnalyticsChart } from 'components';
import { UseAnalyticsChartDataWithCompareProps, useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';
import { MenuItem, Select } from '@mui/material';

type BestSellersChartPropsBase = {
  header: string;
  getNavigateTo?: (id: string) => string;
};
type EventsBestSellersChartProps = BestSellersChartPropsBase & {
  type: 'events';
  getNameAndId: GetSortedDataProps<GetEventsSalesResponse['data'][number]>['getNameAndId'];
};
type StandardBestSellersChartProps = BestSellersChartPropsBase & {
  type?: 'standard';
  getNameAndId: GetSortedDataProps<GetSalesResponse['data'][number]>['getNameAndId'];
};

export type BestSellersChartProps = StandardBestSellersChartProps | EventsBestSellersChartProps;

export const BestSellersChart: React.FC<BestSellersChartProps> = ({ type, header, getNameAndId, getNavigateTo }) => {
  const { sales, salesLoading, eventsSales, eventsSalesLoading } = useAnalyticsPageContext();
  const [ maxCount, setMaxCount ] = useState(5);
  const orderedUsersData = useMemo(() => getSortedData({ data: type === 'events' ? eventsSales : sales, getNameAndId }), [ eventsSales, getNameAndId, sales, type ]);
  const bestSellers = useMemo(() => {
    return orderedUsersData.slice(0, Math.min(orderedUsersData.length, maxCount));
  }, [ maxCount, orderedUsersData ]);

  const useAnalyticsChartDataWithCompareConfig = useMemo(() => {
    if (type === 'events') {
      return {
        type: 'eventsSales',
        baseConfig: { getValue: () => 1, getLabel: (sale) => getNameAndId(sale)?.name, getId: (sale) => getNameAndId(sale)?.id },
        datasetsConfigs: [
          {
            filter: (sale) => {
              const nameAndId = getNameAndId(sale);

              if (nameAndId === null) {
                return false;
              }

              return bestSellers.some(bestSeller => nameAndId.id === bestSeller._id);
            }
          }
        ]
      } as UseAnalyticsChartDataWithCompareProps<'eventsSales'>;
    }

    return {
      type: 'sales',
      baseConfig: { getValue: () => 1, getLabel: (sale) => getNameAndId(sale)?.name, getId: (sale) => getNameAndId(sale)?.id },
      datasetsConfigs: [
        {
          filter: (sale) => {
            const nameAndId = getNameAndId(sale);

            if (nameAndId === null) {
              return false;
            }

            return bestSellers.some(bestSeller => nameAndId.id === bestSeller._id);
          }
        }
      ]
    } as UseAnalyticsChartDataWithCompareProps<'sales'>;
  }, [ bestSellers, getNameAndId, type ]);

  const { config } = useAnalyticsChartDataWithCompare(useAnalyticsChartDataWithCompareConfig);

  return (
    <AnalyticsChart
      header={header}
      config={config}
      loading={type === 'events' ? eventsSalesLoading : salesLoading}
      actions={(
        <Select
          size="small"
          value={maxCount}
          onChange={(e) => setMaxCount(e.target.value as number)}
          fullWidth
        >
          {maxCountOptions.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
        </Select>
      )}
      getNavigateTo={getNavigateTo}
    />
  );
};