import { GetEventsResponse, GetTeamsResponse } from 'api/actions';
import { useCurrentUser } from 'contexts';
import { useShowTheseFields } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/useShowTheseFields.hook';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { EditModeToggler, FilterFieldConfiguration, FullCalendar, FullCalendarEventContentContainer, ShowTheseFieldsEventContent, eventsCalendarEventStaffStatusFilterConfiguration, eventsCalendarEventTeamFilterConfiguration, eventsCalendarEventTeamManagerFilterConfiguration, eventsShowTheseFieldsGroupEnumHelpers, useEventPreviewDrawerContext, useFilter, useSpanEvents, userStaffStatusColorCodeConfig } from 'components';
import { User } from 'api/resources';
import { eventShowTheseFieldsSpaceSizeConfig, eventShowTheseFieldsTeamConfig, eventShowTheseFieldsTeamManagerConfig, eventShowTheseFieldsStaffStatusConfig, eventShowTheseFieldsStaffListConfig, eventShowTheseFieldsDatesStatusConfig, eventShowTheseFieldsDistanceFromStudioConfig, eventShowTheseFieldsWorkDayLengthConfig, eventShowTheseFieldsVehicleConfig, eventShowTheseFieldsLodgingRequiredConfig } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/custom/show-these-fields-presets';
import { convertEventsForEventsCalendar, convertUserAvailabilityForCalendarBackground, EventsCalendarEventExtendedProps } from 'components/FullCalendar/custom/helpers';
import { useMemo, useCallback, useState } from 'react';
import { EventContentArg } from 'types';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventInput } from '@fullcalendar/react';
import { useTeams } from 'queries';
import { useUserAvailability, useUsers } from 'queries/user';
import { DashboardPageEventsOverviewSection } from '../types';
import { UpdateAvailabilityModal } from 'pages/Profile/components/ProfileSchedule/UpdateAvailabilityModal.modal';
import { useFullCalendarWithEditModeDateLocalStorage } from 'hooks';
import { ResetAllActionContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/ResetAllActionContent.component';

const showTheseFieldsConfig = [
  eventShowTheseFieldsSpaceSizeConfig,
  eventShowTheseFieldsTeamConfig,
  eventShowTheseFieldsTeamManagerConfig,
  eventShowTheseFieldsStaffStatusConfig,
  eventShowTheseFieldsStaffListConfig,
  eventShowTheseFieldsDatesStatusConfig,
  eventShowTheseFieldsVehicleConfig,
  eventShowTheseFieldsLodgingRequiredConfig,
  eventShowTheseFieldsDistanceFromStudioConfig,
  eventShowTheseFieldsWorkDayLengthConfig
];

export const getFilterConfigurations = ({ users, usersLoading, teams, teamsLoading }: {
  users: User[];
  usersLoading?: boolean;
  teams: GetTeamsResponse['data'];
  teamsLoading: boolean;
}): FilterFieldConfiguration<EventInput>[] => {
  return [
    eventsCalendarEventStaffStatusFilterConfiguration,
    eventsCalendarEventTeamFilterConfiguration(teams, teamsLoading),
    eventsCalendarEventTeamManagerFilterConfiguration(users, usersLoading),
  ];
};

export type EventsOverviewCalendarProps = {
  events: GetEventsResponse['data'];
  loading: boolean;
};

const CALENDAR_ID = 'dashboard_events_calendar';
const CALENDAR_EDITABLE_ID = 'dashboard_events_calendar_editable';

export const EventsOverviewCalendar: React.FC<EventsOverviewCalendarProps> = ({ events, loading }) => {
  const currentUser = useCurrentUser();
  const theme = useTheme();
  const { setOpenEvent } = useEventPreviewDrawerContext();
  const { data: users = [], isInitialLoading: usersLoading } = useUsers();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const [ isEditMode, setIsEditMode ] = useState(false);

  const initialDate = useFullCalendarWithEditModeDateLocalStorage(CALENDAR_ID, CALENDAR_EDITABLE_ID, isEditMode);

  const {
    spanEvents,
    setSpanEvents,
    sidebarActionConfig: spanEventsSidebarActionConfig
  } = useSpanEvents({ id: CALENDAR_ID });

  const { data: availabilitySlots = [] } = useUserAvailability(currentUser._id);
  const availabilityEvents = convertUserAvailabilityForCalendarBackground(availabilitySlots, theme);
  const transformedEvents = useMemo(() => convertEventsForEventsCalendar(events, spanEvents), [ events, spanEvents ]);

  const { modal: showTheseFieldsModal, sidebarActionConfig: showTheseFieldsSidebarActionConfig, showTheseFields, onResetShowTheseFields } = useShowTheseFields({
    id: CALENDAR_ID,
    config: showTheseFieldsConfig,
    defaultShowTheseFields: [ 'team' ],
    groupEnumHelpers: eventsShowTheseFieldsGroupEnumHelpers,
  });

  const filterFieldConfigurations = useMemo(() => getFilterConfigurations({ users, usersLoading, teams, teamsLoading }), [ teams, teamsLoading, users, usersLoading ]);
  const { modal: filterModal, filteredRows: filteredEvents, onResetFilters, sidebarActionConfig: filterSidebarActionConfig } = useFilter({
    rows: transformedEvents,
    localStorageId: CALENDAR_ID,
    filterConfigurations: filterFieldConfigurations,
  });

  const getEventContent = useCallback((arg: EventContentArg<EventsCalendarEventExtendedProps>) => {
    if (arg.event.display === 'background') {
      return undefined;
    }

    const { event, eventDate, group } = arg.event.extendedProps;
    const backgroundFunc = userStaffStatusColorCodeConfig.getColorKeyConfig({ event, eventDate, user: currentUser }).background;

    return (
      <Box sx={{ cursor: isEditMode ? 'default' : 'pointer' }}>
        <FullCalendarEventContentContainer
          isStart={arg.isStart}
          isEnd={arg.isEnd}
          backgroundFunc={backgroundFunc ?? (theme => theme.palette.primary.main)}
        >
          <ShowTheseFieldsEventContent
            showTheseFieldsConfig={showTheseFieldsConfig}
            showTheseFields={showTheseFields}
            title={event.name}
            extra={{ event, user: currentUser }}
            eventDate={eventDate}
            group={group}
          />
        </FullCalendarEventContentContainer>
      </Box>
    );
  }, [ currentUser, isEditMode, showTheseFields ]);

  const calendarDisplay = useMemo(() => {
    if (loading) {
      return <Skeleton variant="rectangular" height="1050px" width="100%" />;
    }

    const onResetAll = () => {
      setSpanEvents(false);
      onResetFilters();
      onResetShowTheseFields();
    };

    return (
      <Box>
        <FullCalendar
          calendarsConfig={[
            {
              id: CALENDAR_ID,
              initialDate,
              plugins: [ dayGridPlugin ],
              height: '800px',
              events: [ ...filteredEvents, ...availabilityEvents ],
              initialView: 'dayGridMonth',
              headerToolbar: false,
              eventClick: (clickInfo) => setOpenEvent( clickInfo.event.extendedProps.event._id, 'main'),
              eventContent: getEventContent,
              eventOrder: 'title'
            }
          ]}
          sidebarActionsConfig={[
            { content: <ResetAllActionContent onResetAll={onResetAll} /> },
            spanEventsSidebarActionConfig,
            filterSidebarActionConfig,
            showTheseFieldsSidebarActionConfig,
          ]}
        />
      </Box>
    );
  }, [ loading, initialDate, filteredEvents, availabilityEvents, getEventContent, spanEventsSidebarActionConfig, filterSidebarActionConfig, showTheseFieldsSidebarActionConfig, setSpanEvents, onResetFilters, onResetShowTheseFields, setOpenEvent ]);

  // Edit Mode Calendar Display

  const [ selectInfo, setSelectInfo ] = useState<DateSelectArg | null>(null);

  const availabilityModal = useMemo(() => {
    if (!selectInfo) {
      return null;
    }

    return (
      <UpdateAvailabilityModal
        info={selectInfo}
        user={currentUser}
        onClose={() => setSelectInfo(null)}
      />
    );
  }, [ selectInfo, currentUser ]);

  const editModeCalendarDisplay = useMemo(() => {
    if (loading) {
      return <Skeleton variant="rectangular" height="1050px" width="100%" />;
    }

    return (
      <Box>
        <FullCalendar
          calendarsConfig={[
            {
              id: `${CALENDAR_ID}_editable`,
              initialDate,
              plugins: [ dayGridPlugin, interactionPlugin ],
              height: '800px',
              events: [ ...filteredEvents, ...availabilityEvents ],
              initialView: 'dayGridMonth',
              headerToolbar: false,
              eventContent: getEventContent,
              eventOrder: 'title',
              selectable: true,
              select: setSelectInfo,
            }
          ]}
        />
      </Box>
    );
  }, [ loading, initialDate, filteredEvents, availabilityEvents, getEventContent ]);

  return (
    <Box title="Calendar" id={DashboardPageEventsOverviewSection.CALENDAR}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" fontWeight={400} mb={1}>Calendar</Typography>      {showTheseFieldsModal}
        <EditModeToggler isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
      </Box>
      {filterModal}
      {availabilityModal}
      <Box>
        {isEditMode ? editModeCalendarDisplay : calendarDisplay}
      </Box>
    </Box>
  );
};