import { Box, BoxProps, Typography } from '@mui/material';

export type LatestUpdateWrapperProps = {
  latestUpdate: string | undefined;
  latestUpdatePosition?: 'left' | 'right';
  children: React.ReactNode;
} & BoxProps;

export const LatestUpdateWrapper: React.FC<LatestUpdateWrapperProps> = ({ latestUpdate, latestUpdatePosition = 'left', children,  ...props }) => {

  return (
    <Box {...props} position="relative">
      {latestUpdate && (
        <Box position="absolute" bottom={0} {...latestUpdatePosition === 'left' ? { left: 0 } : { right: 0 }}>
          <Typography fontSize="11px">
                Upd: {latestUpdate}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};