import { Chip, useTheme } from '@mui/material';
import { currencyFormatter } from 'services';
import { StatusChipProps } from '../types';

export type OwedChipType = StatusChipProps<number>;

export const OwedChip: React.FC<OwedChipType> = ({ value, ...props }) => {
  const theme = useTheme();

  if (!value) {
    return (
      <Chip
        label="Unknown"
        variant="outlined"
        size="small"
        color="default"
        sx={{ bgcolor: theme.palette.unknown.background }}
        {...props}
      />
    );
  }

  return (
    <Chip
      label={currencyFormatter.format(value)}
      variant="outlined"
      size="small"
      color="error"
      sx={{ bgcolor: theme.palette.error.background }}
      {...props}
    />
  );
};
