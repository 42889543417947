import { EventInsuranceSharedEnum } from 'api/resources';
import { InsuranceSharedChip } from 'components/Chips/custom/InsuranceSharedChip.component';

export type InsuranceSharedCellType = {
  value?: EventInsuranceSharedEnum;
};

export const InsuranceSharedCell: React.FC<InsuranceSharedCellType> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <InsuranceSharedChip size="small" value={value} />;
};
