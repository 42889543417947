import { Box, Grid, Rating, Typography } from '@mui/material';
import { getEventFeedbackIcon, getSummativeEventFeedback } from '../helpers';
import { GetEventFeedbackResponse } from 'api/actions/eventFeedback';

type SummativeEventFeedbackItemProps = {
  value?: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const SummativeEventFeedbackItem: React.FC<SummativeEventFeedbackItemProps> = ({ value, icon: Icon }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Icon fontSize="large" color="disabled" />
      <Rating value={value ?? 0} size="large" precision={0.1} readOnly />
      <Typography variant="h6" color={(theme) => theme.palette.grey[500]} ml={0.5}>
        {value && value.toFixed(1)}
      </Typography>
    </Box>
  );
};

export type SummativeEventFeedbackProps = {
  feedback: GetEventFeedbackResponse['data'];
};

export const SummativeEventFeedback: React.FC<SummativeEventFeedbackProps> = ({ feedback }) => {
  const summativeFeedback = getSummativeEventFeedback(feedback);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.overall} icon={getEventFeedbackIcon('overall')} />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.busyness} icon={getEventFeedbackIcon('busyness')} />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.sales} icon={getEventFeedbackIcon('sales')} />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.food} icon={getEventFeedbackIcon('food')} />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.artists} icon={getEventFeedbackIcon('artists')} />
      </Grid>
      <Grid item lg={2} md={4} sm={6} xs={12}>
        <SummativeEventFeedbackItem value={summativeFeedback.parking} icon={getEventFeedbackIcon('parking')} />
      </Grid>
    </Grid>
  );
};