import { ApiResource } from './resources.types';

export type ProductStockUpdateLog = ApiResource<{
  stock: string;
  type: ProductStockUpdateLogTypeEnum;
  previousQuantity: number;
  newQuantity: number;

  transferType?: ProductStockUpdateLogTransferTypeEnum;
  transferStock?: string;

  sale?: string;
  event?: string;

  user: string;
}>;

export const enum ProductStockUpdateLogTypeEnum {
  set = 'set',
  unary = 'unary',
  damaged = 'damaged',
  missing = 'missing',
  transfer = 'transfer',
  sale = 'sale',
  deleteSale = 'deleteSale',
  saleProductChangeTransfer = 'saleProductChangeTransfer',
  eventTeamChangeTransfer = 'eventTeamChangeTransfer',
}

export const enum ProductStockUpdateLogTransferTypeEnum {
  to = 'to',
  from = 'from',
}

