import { Box } from '@mui/material';
import { missingImg } from 'assets';
import { PageContentContainer, PageContentResourceHeader, SectionCardsEditContextProvider } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useSaleOutletContext } from './Sale.base';
import { OverviewSection } from './sections';
import { useMemo } from 'react';
import { DateService } from 'services';

export const Sale: React.FC = () => {
  const { sale } = useSaleOutletContext();

  const subHeaderContent = useMemo(() => {
    if (sale.event && sale.eventDate) {
      const eventDate = DateService.getFormattedDate(DateService.dayjsTz(sale.eventDate.dateAsUtc), 'ddd, MMM Do, YYYY');

      return `${eventDate} • ${sale.event.name}`;
    }

    if(sale.storefront) {
      const saleDate = DateService.getFormattedDate(DateService.dayjsTz(sale.saleDate), 'ddd, MMM Do, YYYY');

      return `${saleDate} • ${sale.storefront.name}`;
    }

    return null;
  }, [ sale.event, sale.eventDate, sale.saleDate, sale.storefront ]);

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.salesLog, text: 'Sales' } ],
        current: 'Sale Page',
      }}
      header={(
        <PageContentResourceHeader
          title={sale.product.name}
          subHeaderContent={subHeaderContent}
          pageTitle="Sale Page"
          imageSrc={sale.product.imageUrl || missingImg}
        />
      )}
    >
      <SectionCardsEditContextProvider>
        <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
          <OverviewSection />
        </Box>
      </SectionCardsEditContextProvider>
    </PageContentContainer>
  );
};
