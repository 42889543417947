import { Box, Button, Card, Chip, Divider, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EventWithEventDateContent, PageContentContainer, PageContentHeader } from 'components';
import { useStorefronts, useTodaysEvents } from 'queries';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { PlaceOutlined } from '@mui/icons-material';
import { useCurrentUser } from 'contexts';
import { useMemo } from 'react';
import { EventPreperationAcceptanceStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { getEventLodgingPlaceForDate } from 'helpers';
import { DateService } from 'services';

export const LogLiveSale = () => {
  const navigate = useNavigate();
  const { getIsMe, isAdmin, isAuthorizedEmployee } = useCurrentUser();
  const { data: events = [], isInitialLoading: eventsLoading } = useTodaysEvents();
  const { data: storefronts = [], isInitialLoading: storefrontsLoading } = useStorefronts();

  const myEvents = events.filter(event => event.dates.some(eventDate => eventDate.staff.some(staff => getIsMe(staff.user._id))));
  const acceptedEvents = useMemo(() => {
    const acceptedEvents = events
      .filter(event => event.preperation.acceptanceStatus === EventPreperationAcceptanceStatusEnum.accepted)
      .sort((a, b) => {
        if(myEvents.some(event => event._id === a._id)) {
          return -1;
        }
        if(myEvents.some(event => event._id === b._id)) {
          return 1;
        }

        return 0;
      });

    if(isAdmin || isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ])) {
      return acceptedEvents;
    }

    return acceptedEvents.filter(event => myEvents.some(e => e._id === event._id));
  }, [ events, isAdmin, isAuthorizedEmployee, myEvents ]);

  return (
    <PageContentContainer
      loading={eventsLoading || storefrontsLoading}
      header={(
        <PageContentHeader
          title="Point of Sale"
          rightActions={<Button variant="contained" onClick={() => navigate(`/${ROUTING_CONFIG.salesLog}`)} size="small">Go To Sales</Button>}
        />
      )}
    >
      <Grid container spacing={5}>
        {!!acceptedEvents.length && (
          <Grid item xs={12} md={12} lg={6}>
            <Typography variant="h6" fontWeight={400} mb={1}>Todays Events</Typography>
            <Box display="flex" flexDirection="column" gap={2} maxWidth="768px">
              {acceptedEvents.map((event) => {
                const eventDate = event.todaysEventDate;
                const isMyEvent = myEvents.some(_event => event._id === _event._id);

                return (
                  <Card
                    key={event._id}
                    variant="outlined"
                    sx={{ position: 'relative', boxShadow: isMyEvent ? theme => `0 6px 10px ${theme.palette.primary.main}` : 'none' }}
                  >
                    {isMyEvent && (
                      <Chip
                        label="Your Event"
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{
                          position: 'absolute',
                          top: 20,
                          right: 20,
                          backgroundColor: theme => theme.palette.primary.background,
                          borderRadius: 0,
                          fontWeight: 400,
                        }}
                      />
                    )}
                    <EventWithEventDateContent
                      key={event._id}
                      data={{
                        event: event,
                        eventDate: eventDate,
                        eventDates: event.dates,
                        startDate: event.startDate,
                        endDate: event.endDate,
                        travelData: {
                          studioToEventMatrix: event.place.fromStudioMatrix,
                          lodgingToEventMatrix: getEventLodgingPlaceForDate(event, DateService.dayjsTz(eventDate.dateAsUtc))?.place?.toEventMatrix
                        }
                      }}
                      actions={(
                        <Box display="flex" justifyContent="flex-end" py={1.5} px={2} gap={1} flexDirection={{ xs: 'column', md: 'row' }}>
                          <Box display="flex" gap={1} flex={2}>
                            <Button variant="outlined" onClick={() => navigate(`/${ROUTING_CONFIG.events}/${event._id}`)} sx={{ flex: 1 }}>Go To Event</Button>
                            <Button variant="outlined" onClick={() => navigate(`/${ROUTING_CONFIG.events}/${event._id}/dates/${eventDate._id}`)} sx={{ flex: 1 }}>Go To Event Date</Button>
                          </Box>
                          <Button onClick={() => navigate(`/${ROUTING_CONFIG.loggingPortal}/event-date/${eventDate._id}`)} variant="contained" sx={{ flex: 1 }}>Go To Logging Portal</Button>
                        </Box>
                      )}
                    />
                  </Card>
                );
              })}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6" fontWeight={400} mb={1}>Store Fronts</Typography>
          <Box display="flex" flexDirection="column" gap={2} maxWidth="768px">
            {storefronts.map(storefront => (
              <Card key={storefront._id} variant="outlined">
                <Box mx={2} my={1}>
                  <Typography component="span" fontSize="1.15rem" lineHeight={1} fontWeight={500}>{storefront.name}</Typography>
                  <Typography mt={0.5} display="flex" alignItems="center"><PlaceOutlined color="error" fontSize="small" />{storefront.place.address}</Typography>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="flex-end" py={1.5} px={2} gap={1} flexDirection={{ xs: 'column', md: 'row' }}>
                  <Button disabled variant="outlined" fullWidth sx={{ flex: 1 }}>Go To Store Front</Button>
                  <Button onClick={() => navigate(`/${ROUTING_CONFIG.loggingPortal}/storefront/${storefront._id}`)} variant="contained" fullWidth sx={{ flex: 1 }}>Go To Logging Portal</Button>
                </Box>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};
