import { StaffStatusChip } from 'components/Chips/custom/StaffStatusChip.component';
import { EventStaffStatusEnum } from 'types';

export type StaffStatusCellType = {
  value?: EventStaffStatusEnum;
};

export const StaffStatusCell: React.FC<StaffStatusCellType> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <StaffStatusChip size="small" value={value} />;
};
