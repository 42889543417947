import { authAxiosRequest } from 'api/axios';
import { Place, Storefront } from 'api/resources';
import { ResourceWithPopulated } from 'api/resources/resources.types';

export type GetStorefrontsResponse = {
  data: ResourceWithPopulated<Storefront, {
    place: Place;
  }>[];
};

export const getStorefronts = () => {
  return authAxiosRequest<GetStorefrontsResponse>('/api/storefronts');
};