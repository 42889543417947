import { useState, useEffect } from 'react';

export const enum Device {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

const getDetectedDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

  if (isMobile) {
    return Device.mobile;
  } else if (isTablet) {
    return Device.tablet;
  } else {
    return Device.desktop;
  }
};

export const useDeviceDetection = () => {
  const [ device, setDevice ] = useState(getDetectedDevice);

  useEffect(() => {
    const handleDeviceDetection = () => setDevice(getDetectedDevice());

    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);

  return device;
};