import { Box, Divider, List, ListItemButton, Typography } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { useNavigate } from 'react-router-dom';
import { EventContent } from '../EventContent.component';
import { PlaceDistanceAndDurationContent } from 'components/PlaceDistanceAndDurationContent';

export type EventListProps = {
  title?: string;
  events: GetEventsResponse['data'];
  showPlaceDistanceAndDurationContent?: boolean;
  renderRightSideContent?: (event: GetEventsResponse['data'][number]) => React.ReactNode;
};

export const EventList: React.FC<EventListProps> = props => {
  const navigate = useNavigate();

  if (!props.events.length) {
    return null;
  }

  return (
    <Box>
      <Typography color="text.secondary" mb={1} pl={1} fontSize="large">{props.title}</Typography>
      <Divider />
      <List disablePadding>
        {props.events.map((event) => {

          return (
            <ListItemButton key={event._id} divider disableGutters onClick={() => navigate(`/events/${event._id}`)}>
              <Box px={{ xs: 0, sm: 2 }} display="flex" alignItems={{ xs: 'unset', sm: 'center' }} py={1} width="100%" flexDirection={{ xs: 'column', sm: 'row' }}>
                <Box maxWidth={{ xs: 'unset', sm: '400px' }} width="100%">
                  <EventContent data={{ event, startDate: event.startDate, endDate: event.endDate }} disablePadding>
                    {props.showPlaceDistanceAndDurationContent && <Box>
                      <PlaceDistanceAndDurationContent
                        fromLabel="Studio"
                        toLabel="Event"
                        placeMatrix={event.place.fromStudioMatrix}
                      />
                    </Box>}
                  </EventContent>
                </Box>
                <Box flex={1}>
                  {props.renderRightSideContent?.(event)}
                </Box>
              </Box>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};