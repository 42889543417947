import { Components, Theme } from '@mui/material';

export const makeComponents = (theme: Theme): Components => {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset`,
            '-webkit-text-fill-color': 'currentColor',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& a': {
            color: theme.palette.text.primary,
            textDecorationColor: theme.palette.text.primary,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
        //   borderRadius: 0,
          boxShadow: 'none',
          textTransform: 'none'
        },
        sizeLarge: {
        //   fontSize: '0.9375rem',
        //   lineHeight: '1.625rem',
        },
        sizeSmall: {
        //   fontSize: '0.8125rem',
        //   lineHeight: '1.375rem',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeLarge: {
          fontSize: '0.9375rem',
          lineHeight: '1.625rem',
        },
        sizeMedium: {
          fontSize: '0.875rem',
          lineHeight: 1.7,
          padding: '5px 15px',
        },
        sizeSmall: {
          fontSize: '0.8125rem',
          lineHeight: '1.375rem',
          padding: '3px 9px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
        //   textTransform: 'none'
        }
      }
    }
  };
};
