import { Box, Typography } from '@mui/material';
import { FilterFieldConfiguration, FilterValidFilterObject } from 'components/Filter';
import React from 'react';

export type FiltersActionContentProps = {
  filterFieldConfigurations: FilterFieldConfiguration[];
  filter: FilterValidFilterObject;
};

export const FiltersActionContent: React.FC<FiltersActionContentProps> = props => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
    >
      {props.filterFieldConfigurations.map((fieldConfiguration) => {
        switch (fieldConfiguration.type) {
          case 'year':
            return null;
          case 'checkbox':
            return null;
          case 'enum':
            return (
              <Box key={fieldConfiguration.field}>
                <Typography variant="body2">{fieldConfiguration.label}</Typography>
                <Typography fontSize="small" fontWeight={500}>{props.filter[fieldConfiguration.field].map(fieldConfiguration.getOptionLabel).join(', ')}</Typography>
              </Box>
            );
          case 'resource':
          default:
            return (
              <Box key={fieldConfiguration.field}>
                <Typography variant="body2">{fieldConfiguration.label}</Typography>
                <Typography fontSize="small" fontWeight={500}>
                  {props.filter[fieldConfiguration.field] === 'any'
                    ? <span style={{ fontStyle: 'italic' }}>Any</span>
                    : props.filter[fieldConfiguration.field]
                      .map((value: string) => {
                        if (value === 'notSelected') {
                          return 'Not Selected';
                        }
                        return fieldConfiguration.options.find(option => option._id === value)?.label;
                      })
                      .sort((a: string, b: string) => a < b ? -1 : 1)
                      .join(', ')}
                </Typography>
              </Box>
            );
        }
      })}
    </Box>
  );
};
