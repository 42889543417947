import { FormikProps } from 'formik';
import { GetProductsResponse } from 'api/actions';
import { Box, Button, Divider, FormControl, FormLabel, IconButton, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';
import { Autocomplete, Select, UnaryOperationCell } from 'components';
import { useTeams } from 'queries';

export const enum DamagedOrMissingEnum {
  damaged = 'damaged',
  missing = 'missing',
}

export type InventoryDamagedStateType = {
  productId: string | null;
  teamId: string | null;
  amount: number;
  damagedOrMissing: DamagedOrMissingEnum | null;
};

export type InventoryDamagedFormProps = FormikProps<InventoryDamagedStateType> & {
  products: GetProductsResponse['data'];
};

export const InventoryDamagedForm: React.FC<InventoryDamagedFormProps> = ({ products, ...formik }) => {
  const { data: teams = [] } = useTeams();
  const { productId, teamId, amount } = formik.values;

  const onTeamSelectHandler = (teamId: string) => {
    formik.setFieldValue('teamId', teamId);
  };

  const addHandler = () => {
    if (amount >= -1) return;
    formik.setFieldValue('amount', amount + 1);
  };

  const subtractHandler = () => {
    formik.setFieldValue('amount', amount - 1);
  };

  const product = productId ? products.find((product) => product._id === productId) || null : null;
  const stock = teamId && product ? product.stocks?.[teamId] : null;

  return (
    <Box display="flex" flexDirection="column" gap={3} pb={6}>
      <Autocomplete
        label="Product"
        name="productId"
        options={products}
        getOptionLabel={(opt) => opt?.name}
        transformValue={(opt) => opt?._id}
        setValue={() => formik.setFieldValue('teamId', null)}
        disablePortal
      />
      {product && (
        <>
          <Divider />
          <FormControl>
            <FormLabel sx={{ pb: 1 }}>Select Team</FormLabel>
            <Box display="grid" gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' }} gap={1}>

              {teams.map((team) => {
                const stock = product?.stocks?.[team._id] ?? null;
                const isSelected = teamId === team._id;
                const notAtEvents = !product?.availability?.events && !team.isWarehouse;
                const quantity = stock?.quantity !== undefined ? `Quantity: ${stock.quantity}` : null;
                const displayQuantity = notAtEvents ? 'Not at events' : quantity;

                return (
                  <Button
                    key={team._id}
                    variant={isSelected ? 'outlined' : 'text'}
                    color={isSelected ? 'primary' : 'inherit'}
                    sx={(theme) => ({
                      p: 1,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: isSelected ? undefined : theme.palette.divider,
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: isSelected ? theme.palette.primary.background : 'transparent',
                    })}
                    disabled={!!notAtEvents || !product}
                    onClick={() => onTeamSelectHandler(team._id)}
                  >
                    <Typography textTransform="none">{team.name}</Typography>
                    <Typography variant="body2" textTransform="none">{displayQuantity}</Typography>
                  </Button>
                );
              })}
            </Box>
          </FormControl>
        </>
      )}
      {product && teamId && (
        <>
          <Divider />
          <Box display="flex" gap={2} alignItems={{ xs: 'initial', sm: 'flex-end' }} flexDirection={{ xs: 'column', sm: 'row' }} mt={1}>
            <Box flex={1}>
              <FormControl>
                <FormLabel>Select Amount To Remove</FormLabel>
                <Box display="flex" alignItems="center" mr={-1}>
                  <IconButton onClick={subtractHandler}>
                    <RemoveCircleOutline />
                  </IconButton>
                  <Box width="46px" textAlign="center">
                    <Typography
                      variant="body2"
                      fontWeight={450}
                      fontSize="large"
                      lineHeight={1}
                      color="error"
                    >
                      {'-'}{Math.abs(amount)}
                    </Typography>
                  </Box>
                  {Math.abs(amount) > 1 && (
                    <IconButton onClick={addHandler}>
                      <AddCircleOutline />
                    </IconButton>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Box flex={1}>
              <Select
                label="Damaged or Missing"
                options={[ { value: DamagedOrMissingEnum.damaged, label: 'Damaged' }, { value: DamagedOrMissingEnum.missing, label: 'Missing' } ]}
                name="damagedOrMissing"
                MenuProps={{
                  style: {
                    zIndex: FULL_SCREEN_Z_INDEX + 1
                  }
                }}
              />
            </Box>
          </Box>
        </>
      )}
      {stock && (
        <>
          <Divider />
          <Box height="50px">
            <Typography mb={1}>Review:</Typography>
            <UnaryOperationCell initialValue={stock.quantity} newValue={amount} />
          </Box>
        </>
      )}
    </Box>
  );
};