import { DateRange, PopoverButton } from 'components';
import { Formik } from 'formik';
import { AnalyticsCompareToForm } from '../forms/AnalyticsCompareTo.form';
import { useMemo } from 'react';
import { analyticsCompareToEnumHelpers } from '../helpers';

export type AnalyticsCompareToPopoverProps = {
  dateRange: DateRange;
  inputDateRange: NonNullable<DateRange>;
  onChange: (dateRange: DateRange) => void;
};

export const AnalyticsCompareToPopover: React.FC<AnalyticsCompareToPopoverProps> = props => {
  const label = useMemo(() => {
    const dateRangeShortcut = analyticsCompareToEnumHelpers.getValueForDateRange(props.inputDateRange, props.dateRange);

    if (dateRangeShortcut) {
      return `Compare: ${analyticsCompareToEnumHelpers.getLabel(dateRangeShortcut)}`;
    }

    return 'No comparison';
  }, [ props.dateRange, props.inputDateRange ]);

  return (
    <PopoverButton label={label} id="daterange-com">
      {({ handleClose }) => {
        return (
          <Formik
            initialValues={{ dateRange: props.dateRange }}
            onSubmit={(values) => props.onChange(values.dateRange)}
          >
            {formik => (
              <AnalyticsCompareToForm
                inputDateRange={props.inputDateRange}
                onCancel={handleClose}
                onApply={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
              />
            )}
          </Formik>
        );
      }}
    </PopoverButton>
  );
};