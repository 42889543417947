import { authAxiosRequest } from 'api/axios';
import { Genre, GenreGroupEnum } from 'api/resources/genre';
import { DeepPartial } from 'types';

export type GetGenresResponse = {
  data: Genre[];
};

export const getGenres = () => {
  return authAxiosRequest<GetGenresResponse>('/api/genres');
};

export type CreateGenreInput = {
  title: string;
  group: GenreGroupEnum;
};

export const createGenre = (data: CreateGenreInput) => {
  return authAxiosRequest('/api/genres', { method: 'POST', data });
};

export type UpdateGenreInput = DeepPartial<CreateGenreInput>;

export const updateGenre = (genreId: string, data: UpdateGenreInput) => {
  return authAxiosRequest(`/api/genres/${genreId}`, { method: 'PUT', data });
};

export const deleteGenre = (genreId: string) => {
  return authAxiosRequest(`/api/genres/${genreId}`, { method: 'DELETE' });
};