import { SectionCardRowEditable, SectionCardRowEditableProps } from 'components';
import { TeamPageSectionRowId, TeamSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useTeamOutletContext } from '../Team.base';

export type TeamSectionCardRowEditableProps<RowId extends TeamPageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<TeamSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const TeamSectionCardRowEditable = <RowId extends TeamPageSectionRowId>(props: TeamSectionCardRowEditableProps<RowId>) => {
  const { team } = useTeamOutletContext();

  return <SectionCardRowEditable {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.TEAM(team._id) })} />;
};