import { MarkdownInput, UpdateResourceModal } from 'components';
import { Formik, useFormikContext } from 'formik';
import { DateObjectType, DateService } from 'services';
import { ResourceForm, YesNoEnum } from 'types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventDateSetupBreakdownTypeEnum, EventDateTypeEnum } from 'api/resources';
import { GetEventResponse, createEventDate } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { eventDateValidationSchema } from '../../../EventDate';
import { getEventDateAutoSetupTime, processFormValueUpdate } from 'helpers';
import { Box, Divider, Typography } from '@mui/material';
import { formSx } from 'styles';
import { EventDateTravelForm } from 'pages/Event/pages/EventDate/forms/EventDateTravel.form';
import { EventDateSetupForm } from 'pages/Event/pages/EventDate/forms/EventDateSetup.form';
import { useEffect, useMemo } from 'react';
import { EventDateStartEndTimesForm } from 'pages/Event/pages/EventDate/forms/EventDateMarket.form';
import { EventDateCloseProcedureForm } from 'pages/Event/pages/EventDate/forms/EventDateCloseProcedure.form';
import { EventDateStaffForm } from 'pages/Event/pages/EventDate/forms/EventDateStaff.form';
import { EventDateGeneralForm } from 'pages/Event/pages/EventDate/forms/EventDateGeneral.form';

export type AddEventDateModalType = {
  onClose: () => void;
  fullDate: DateObjectType;
  event: GetEventResponse['data'];
};

const getMostRecentEventDate = (event: GetEventResponse['data']) => {
  if (!event.dates.length) {
    return null;
  }

  const mostRecentEventDate = event.dates.reduce((acc, date) => {
    if (date.createdAt > acc.createdAt) {
      return date;
    }

    return acc;
  }, event.dates[0]);

  return mostRecentEventDate;
};

export const AddEventDateModal: React.FC<AddEventDateModalType> = ({
  onClose,
  fullDate,
  event,
}) => {
  const queryClient = useQueryClient();
  const postEventDateMutation = useMutation({
    mutationFn: async (form: ResourceForm['eventDate']) => {
      const dateISO = fullDate.format('YYYY-MM-DD');

      return await createEventDate(event._id, {
        date: dateISO,
        type: form.type,

        confirmedTimes: form.confirmedTimes,
        startTime: form.startTime,
        endTime: form.endTime,

        setupType: form.setupType,
        breakdownType: form.breakdownType,

        setupEndTime: processFormValueUpdate.string(form.setupEndTime),
        arriveAsEarlyAs: processFormValueUpdate.string(form.arriveAsEarlyAs),
        noVehiclesAllowedAfter: processFormValueUpdate.string(form.noVehiclesAllowedAfter),
        allVehiclesMustBeRemovedBy: processFormValueUpdate.string(form.allVehiclesMustBeRemovedBy),

        bufferTimeInMinutes: form.bufferTimeInMinutes,
        displaySetupInMinutes: form.displaySetupInMinutes,
        breakdownInMinutes: form.breakdownInMinutes,

        notes: form.notes.trim() || undefined,
        staffNeeded: form.staffNeeded || undefined,
        teamLeadNeeded: processFormValueUpdate.yesNo(form.teamLeadNeeded) ?? true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEY.EVENT(event._id),
      });
      onClose();
    }
  });

  const mostRecentEventDate = getMostRecentEventDate(event);
  const setupType = useMemo(() => {
    if (DateService.dayjsTz(mostRecentEventDate?.dateAsUtc).isAfter(fullDate, 'day')) {
      return EventDateSetupBreakdownTypeEnum.hard;
    }

    if (mostRecentEventDate?.breakdownType === EventDateSetupBreakdownTypeEnum.soft) {
      return EventDateSetupBreakdownTypeEnum.soft;
    }

    return EventDateSetupBreakdownTypeEnum.hard;
  }, [ fullDate, mostRecentEventDate?.breakdownType, mostRecentEventDate?.dateAsUtc ]);

  const initialValues: ResourceForm['eventDate'] = {
    type: EventDateTypeEnum.regular,
    setupType: setupType,
    breakdownType: mostRecentEventDate?.breakdownType ?? EventDateSetupBreakdownTypeEnum.hard,
    confirmedTimes: mostRecentEventDate?.confirmedTimes ?? false,
    bufferTimeInMinutes: mostRecentEventDate?.bufferTimeInMinutes ?? 20,
    displaySetupInMinutes: getEventDateAutoSetupTime(setupType, 0),
    breakdownInMinutes: mostRecentEventDate?.bufferTimeInMinutes ?? 90,
    startTime: mostRecentEventDate?.startTime ?? '09:00',
    endTime: mostRecentEventDate?.endTime ?? '17:00',
    arriveAsEarlyAs: mostRecentEventDate?.arriveAsEarlyAs ?? '',
    noVehiclesAllowedAfter: mostRecentEventDate?.noVehiclesAllowedAfter ?? '',
    allVehiclesMustBeRemovedBy: mostRecentEventDate?.allVehiclesMustBeRemovedBy ?? '',
    setupEndTime: mostRecentEventDate?.setupEndTime ?? '',
    notes: '',
    staffNeeded: 0,
    teamLeadNeeded: YesNoEnum.yes,
  };

  return (
    <Formik
      onSubmit={(values) => postEventDateMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={eventDateValidationSchema}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open
            onClose={onClose}
            onSave={formik.handleSubmit}
            title={`New Event Date - ${DateService.getFormattedDate(fullDate)}`}
            loading={formik.isSubmitting}
            autoHeight
          >
            <EventDateForm fullDate={fullDate} />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};

type EventDateFormProps = Pick<AddEventDateModalType, 'fullDate'>;

const EventDateForm: React.FC<EventDateFormProps> = ({ fullDate }) => {
  const { values, setFieldValue } = useFormikContext<ResourceForm['eventDate']>();

  const startEndTimesFormSectionTitle = useMemo(() => {
    switch (values.type) {
      case EventDateTypeEnum.regular:
        return 'Market';
      case EventDateTypeEnum.setup:
        return 'Setup';
      case EventDateTypeEnum.breakdown:
        return 'Breakdown';
      default:
        return '';
    }
  }, [ values.type ]);

  useEffect (() => {
    if (values.type !== EventDateTypeEnum.regular) {
      setFieldValue('arriveAsEarlyAs', '');
      setFieldValue('noVehiclesAllowedAfter', '');
      setFieldValue('allVehiclesMustBeRemovedBy', '');
      setFieldValue('setupEndTime', '');
    }
  }, [ values.type, setFieldValue ]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <EventDateFormSection title="General">
        <EventDateGeneralForm />
      </EventDateFormSection>
      <EventDateFormSection title="Travel">
        <EventDateTravelForm date={DateService.dayjs(fullDate)}/>
      </EventDateFormSection>
      {values.type === EventDateTypeEnum.regular && (
        <EventDateFormSection title="Setup">
          <EventDateSetupForm staffNeeded={values.staffNeeded} />
        </EventDateFormSection>
      )}
      {values.type !== EventDateTypeEnum.travel && (
        <EventDateFormSection title={startEndTimesFormSectionTitle}>
          <EventDateStartEndTimesForm />
        </EventDateFormSection>
      )}
      {values.type === EventDateTypeEnum.regular && (
        <EventDateFormSection title="Close procedure">
          <EventDateCloseProcedureForm />
        </EventDateFormSection>
      )}
      <EventDateFormSection title="Staff">
        <EventDateStaffForm />
      </EventDateFormSection>
      <EventDateFormSection title="Notes">
        <MarkdownInput name="notes" label="Notes" />
      </EventDateFormSection>
    </Box>
  );
};

type EventDateFormSectionProps = {
  title: string;
  children: React.ReactNode;
};

const EventDateFormSection: React.FC<EventDateFormSectionProps> = ({ title, children }) => {
  return (
    <Box>
      <Typography
        width="fit-content"
        mb={2}
        variant="subtitle1"
        fontWeight={500}
      >
        {title}
      </Typography>
      <Box mx={-3} mt={-2} mb={2}><Divider /></Box>
      <Box sx={formSx.formGroup}>
        {children}
      </Box>
    </Box>
  );
};