import { InventoryBatchStatusEnum } from 'api/resources';
import { StatusChipProps } from '../types';
import { inventoryBatchEnumHelpers } from 'helpers';
import { StandardChip } from '../StandardChip.component';

export type InventoryBatchStatusChipProps = StatusChipProps<InventoryBatchStatusEnum>;

export const InventoryBatchStatusChip: React.FC<InventoryBatchStatusChipProps> = props => {
  return <StandardChip {...inventoryBatchEnumHelpers.status} {...props} />;
};
