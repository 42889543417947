import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetVehicleResponse, updateVehicle } from 'api/actions';
import { EditableImage, JumpNav, Loading, PageContentContainer, PageContentResourceHeader, SectionCardsEditContextProvider, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useVehicle } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { FirebaseService } from 'services';
import { OverviewSection, EventsSection } from './sections';
import { VehiclePageSection } from './types';
import { FilesSection } from './sections/Files.section';
import { MaintenanceSection } from './sections/Maintenance.section';

type VehicleRawProps = {
  vehicle: GetVehicleResponse['data'];
};

const VehicleRaw: React.FC<VehicleRawProps> = ({ vehicle }) => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const uploadImageMutation = useMutation({
    mutationFn: async (imageBlobUrl: Blob) => {
      if (vehicle.imageUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(vehicle.imageUrl);
        const path = `${vehicle._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      const imageUrl = await FirebaseService.uploadFile(imageBlobUrl, `${vehicle._id}/${FirebaseService.convertFileName('_vehicle_image')}`);

      return updateVehicle(vehicle._id, { imageUrl });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.VEHICLE(vehicle._id));
      snackbar.success('Image uploaded');
    },
    onError: () => {
      snackbar.error('Image could not be uploaded');
    }
  });
  const deleteImageMutation = useMutation({
    mutationFn: async () => {
      if (vehicle.imageUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(vehicle.imageUrl);
        const path = `${vehicle._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      return updateVehicle(vehicle._id, { imageUrl: null });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.VEHICLE(vehicle._id));
      snackbar.success('Image deleted');
    },
    onError: () => {
      snackbar.error('Image could not be deleted');
    }
  });

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.vehicles, text: 'Vehicles' } ],
        current: vehicle.name,
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="Vehicle Page"
          title={vehicle.name}
          renderEditableImage={(props) => (
            <EditableImage
              {...props}
              imageUrl={vehicle.imageUrl}
              onUpload={uploadImageMutation.mutate}
              deleteConfig={{ onDelete: deleteImageMutation.mutate }}
              loading={uploadImageMutation.isLoading || deleteImageMutation.isLoading}
              modalTitle="Update Vehicle Image"
            />
          )}
          stickyTabs
          tabs={(
            <JumpNav
              initialValue={VehiclePageSection.OVERVIEW}
              offsetTop={64}
              tabs={[
                {
                  label: 'Overview',
                  sectionId: VehiclePageSection.OVERVIEW,
                },
                {
                  label: 'Maintenance',
                  sectionId: VehiclePageSection.MAINTENANCE,
                },
                {
                  label: 'Files',
                  sectionId: VehiclePageSection.FILES,
                },
                {
                  label: 'Events',
                  sectionId: VehiclePageSection.EVENTS,
                },
              ]}
            />
          )}
        />
      )}
    >
      <SectionCardsEditContextProvider>
        <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
          <OverviewSection vehicle={vehicle} />
          <MaintenanceSection vehicle={vehicle} />
          <FilesSection vehicle={vehicle} />
        </Box>
        <Box mt={5}>
          <EventsSection vehicle={vehicle} />
        </Box>
      </SectionCardsEditContextProvider>
    </PageContentContainer>
  );
};

export const Vehicle = () => {
  const { id } = useParams();
  const { data: vehicle, isInitialLoading: loading } = useVehicle(id as string);

  if (loading) {
    return <Loading />;
  }

  if (!vehicle) {
    return <Navigate to={`/${ROUTING_CONFIG.vehicles}`} replace />;
  }

  return <VehicleRaw vehicle={vehicle} />;
};
