import { PaletteMode, ThemeProvider } from '@mui/material';
import { getTheme } from './themes';
import { createContext, useContext, useMemo, useState } from 'react';

export type ThemeModeContextType = {
  mode: PaletteMode;
  nextMode: PaletteMode;
  onToggleThemeMode: () => void;
};

export const ThemeModeContext = createContext<ThemeModeContextType>({
  mode: 'light',
  nextMode: 'dark',
  onToggleThemeMode: () => {},
});
export const useThemeModeContext = () => useContext(ThemeModeContext);

const LOCAL_STORAGE_KEY = 'theme-palette-mode-v2';

export const MainThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ mode, setMode ] = useState(() => {
    const localStorageMode = window.localStorage.getItem(LOCAL_STORAGE_KEY) as PaletteMode | null;

    return localStorageMode && [ 'light', 'dark' ].includes(localStorageMode) ? localStorageMode : 'light';
  });

  const nextMode = mode === 'light' ? 'dark' : 'light';
  const onToggleThemeMode = () => {
    setMode(nextMode);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, nextMode);
  };
  const theme = useMemo(() => getTheme(mode), [ mode ]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeModeContext.Provider value={{ mode, nextMode, onToggleThemeMode  }}>
        {children}
      </ThemeModeContext.Provider>
    </ThemeProvider>
  );
};
