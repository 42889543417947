import { useState } from 'react';
import { SelectionChipGroup, SelectionChipGroupProps } from './SelectionChipGroup.component';

export type UseSelectionChipGroupProps = {
  initialValue: string;
};

export const useSelectionChipGroup = (props: UseSelectionChipGroupProps) => {
  const [ value, setValue ] = useState<string>(props.initialValue);

  const SelectionChipGroupComponent: React.FC<Pick<SelectionChipGroupProps, 'options' | 'disabled'>> = (props) => {

    return <SelectionChipGroup {...props} value={value} onChange={(newValue) => setValue(newValue)} />;
  };

  return {
    SelectionChipGroup: SelectionChipGroupComponent,
    value,
  };
};