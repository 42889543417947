import { ListItemButton, ListItemButtonProps } from '@mui/material';
import { EventDateContent, EventDateContentProps } from './EventDateContent.component';

export type EventDateListItemProps = {
  onClick: (eventDateId: string) => void;
  divider?: boolean;
  listItemButtonSx?: ListItemButtonProps['sx'];
} & EventDateContentProps;

export const EventDateListItem: React.FC<EventDateListItemProps> = ({ onClick, divider = false, listItemButtonSx, ...props }) => {

  return (
    <ListItemButton
      divider={divider}
      onClick={() => onClick(props.data.eventDate._id)}
      sx={{ p: 0, pb: 1, ...listItemButtonSx }}
      disableTouchRipple
    >
      <EventDateContent {...props} />
    </ListItemButton>
  );
};