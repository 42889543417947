import { UseBaseQueryOptions, useQuery } from '@tanstack/react-query';
import { getEventDateAddStaffUserOptions, getEventDates, getEventDatesForUser } from 'api/actions';
import { QUERY_KEY } from './query-keys';
import { DateService } from 'services';

export const useEventDates = (opts?: UseBaseQueryOptions) => {
  return useQuery(QUERY_KEY.EVENT_DATES, async () => {
    const response = await getEventDates();

    return response.data;
  }, opts);
};

export const useUpcomingEventDates = () => {
  const today = DateService.dayjs().format('YYYY-MM-DD');

  return useQuery(QUERY_KEY.EVENT_DATES_UPCOMING(today), async () => {
    const response = await getEventDates({ startDate: today });

    return response.data;
  });
};

export const useTodaysEventDates = () => {
  const today = DateService.dayjs().format('YYYY-MM-DD');

  return useQuery(QUERY_KEY.EVENT_DATES_TODAY(today), async () => {
    const response = await getEventDates({ date_eq: today });

    return response.data;
  });
};

// todo: make query for conflicting for event

export const useEventDatesForUser = (userId: string, enabled = true) => {
  return useQuery(QUERY_KEY.EVENT_DATES_FOR_USER(userId), async () => {
    const response = await getEventDatesForUser(userId);

    return response.data;
  }, { enabled });
};

export const useEventDateAddStaffUserOptions = (eventDateId: string) => {
  return useQuery(QUERY_KEY.EVENT_DATE_ADD_STAFF_USER_OPTIONS(eventDateId), async () => {
    const response = await getEventDateAddStaffUserOptions(eventDateId);

    return response.data;
  });
};