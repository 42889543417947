import { ResourceForm, yesNoUnknownSchema } from 'types';
import { SchemaOf, array, number, object, string } from 'yup';

export const productNewValidationSchema = (): SchemaOf<ResourceForm['product']> => {
  return object({
    name: string().required('Product Name Required'),
    sku: string().required('Sku Required'),
    price: number().required('Price Required').min(0, 'Price must be greater than 0'),
    category: string().required('Category Required'),
    notes: string().default(''),
    availability: object({
      events: yesNoUnknownSchema,
      website: yesNoUnknownSchema,
      uploaded: yesNoUnknownSchema,
    }),
    preperation: object({
      rasterLayer: yesNoUnknownSchema,
      centerLabel: yesNoUnknownSchema,
      png: yesNoUnknownSchema,
      dmc: yesNoUnknownSchema,
      dwg: yesNoUnknownSchema,
      divider: yesNoUnknownSchema,
      websitePngs: yesNoUnknownSchema,
    }),
    genres: array(string().default('')).required(),
  });
};
