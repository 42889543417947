import { EventPreperationApplicationStatusEnum } from 'api/resources';
import { ApplicationStatusChip } from 'components';

export type ApplicationStatusCellType = {
  value?: EventPreperationApplicationStatusEnum;
};

export const ApplicationStatusCell: React.FC<ApplicationStatusCellType> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <ApplicationStatusChip size="small" value={value} />;
};
