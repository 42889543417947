import { Box, Typography } from '@mui/material';
import { InventoryRow } from './useInventoryTable.hook';
import { useMemo } from 'react';

type StockMetaConfigValue = 'primary' | 'secondary';
export type StockMetaConfig = {
  baseline?: StockMetaConfigValue;
  needed?: StockMetaConfigValue;
  overstocked?: StockMetaConfigValue;
};
export type StockMetaCellContentProps = {
  stock: InventoryRow['stocks'][string];
  updateQuantity: number | undefined;
  isSetOperation: boolean;
  baseline: number;
  config?: StockMetaConfig;
};

export const StockMetaCellContent: React.FC<StockMetaCellContentProps> = props => {
  const { updateQuantity, stock: { quantity }, baseline } = props;
  const need = Math.max(baseline - quantity, 0);
  const overstocked = Math.max(quantity - baseline, 0);

  let needUpdated: number | undefined;
  let overstockedUpdated: number | undefined;

  if (updateQuantity !== undefined) {
    needUpdated = Math.max(baseline - (props.isSetOperation ? updateQuantity : quantity + updateQuantity), 0);
    overstockedUpdated = Math.max((props.isSetOperation ? updateQuantity : quantity + updateQuantity) - baseline, 0);
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" py={0.75} minWidth="64px">
      <Box flex={1} display="flex" flexDirection="column" bgcolor="background.paper" overflow="hidden" gap={0.25}>
        <StockMetaRow
          label="B"
          value={baseline}
          color={props.config?.baseline === 'secondary' ? 'unknown' : 'info'}
        />
        <StockMetaRow
          label="N"
          value={need}
          newValue={needUpdated}
          color={props.config?.needed === 'secondary' ? 'unknown' : 'warning'}
        />
        <StockMetaRow
          label="O"
          value={overstocked}
          newValue={overstockedUpdated}
          color={props.config?.overstocked === 'secondary' ? 'unknown' : 'success'}
        />
      </Box>
    </Box>
  );
};

export type StockMetaRowProps = {
  label: string;
  value: number;
  newValue?: number | undefined;
  color: 'info' | 'success' | 'threat' | 'warning' | 'unknown';
};

export const StockMetaRow: React.FC<StockMetaRowProps> = props => {
  const { label, value, newValue, color } = props;

  const content = useMemo(() => {
    if (newValue === undefined || newValue === value) {
      return <Typography fontSize="15px" lineHeight={1} color={props.color === 'unknown' ? 'text.secondary' : 'text.primary'}>{value}</Typography>;
    }

    return (
      <Box display="flex" alignItems="flex-end" gap={0.25}>
        <Typography fontSize="13px" lineHeight={1} color="text.disabled">{value}</Typography>
        {newValue !== undefined && <Typography fontSize="15px" lineHeight={1} color={props.color === 'unknown' ? 'text.secondary' : 'text.primary'}>{newValue}</Typography>}
      </Box>
    );
  }, [ newValue, props.color, value ]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={0.5} flex={1} bgcolor={`${color}.background`} px={0.5} pl={0.5} border={theme => `1px solid ${theme.palette[color].main}`} borderRadius={theme => theme.spacing(0.5)}>
      <Typography fontWeight={500} fontSize="15px" lineHeight={1} color={theme => theme.palette[color].main} width="12px">{label}:</Typography>
      {content}
    </Box>
  );
};