import { EventDate } from 'api/resources';
import { DateObjectType, DateService } from 'services';
import { EventDateTimePeriodFilterOption, EventDateTimePeriodType } from 'types';

export const getEventDateTimePeriod = (date: DateObjectType | string): EventDateTimePeriodType => {
  const currentDate = typeof date === 'string' ? DateService.dayjsTz(date).startOf('D') : date.startOf('D');
  const today = DateService.dayjs().startOf('D');

  const isAfterToday = currentDate.isAfter(today);
  const isToday = currentDate.isSame(today);
  let period: EventDateTimePeriodType;

  if (isToday) {
    period = 'today';
  } else if (isAfterToday) {
    period = 'upcoming';
  } else {
    period = 'past';
  }

  return period;
};

const todayFilter = (tp: EventDateTimePeriodFilterOption) => tp === 'today';
const upcomingFilter = (tp: EventDateTimePeriodFilterOption) => tp === 'upcoming' || tp === 'today';
const pastFilter = (tp: EventDateTimePeriodFilterOption) => tp === 'past';

const filterHash: { [Property in EventDateTimePeriodType]: (tp: EventDateTimePeriodFilterOption) => boolean } = {
  today: todayFilter,
  upcoming: upcomingFilter,
  past: pastFilter,
};

export const getEventDatesFilteredByTimePeriod = <T extends Pick<EventDate, 'dateAsUtc'>>(eventDates: T[], timePeriodFilterOption: EventDateTimePeriodFilterOption | EventDateTimePeriodType[]) => {
  if (timePeriodFilterOption === 'all') {
    return eventDates;
  }

  return eventDates.filter((d) => {
    const timePeriod = getEventDateTimePeriod(d.dateAsUtc);

    return Array.isArray(timePeriodFilterOption) ? timePeriodFilterOption.some((option) => filterHash[option](timePeriod)) : filterHash[timePeriodFilterOption](timePeriod);
  });

};