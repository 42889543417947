import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean, Select, TextInput } from 'components';
import { useFormikContext } from 'formik';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper } from 'helpers';
import { useMemo } from 'react';
import { formSx } from 'styles';
import { EventResourceForm, YesNoUnknownEnum } from 'types';

export const ElectricityFeeForm: React.FC = () => {
  const electricityPaymentTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.electricityPaymentType), []);
  const { values } = useFormikContext<EventResourceForm['electricityFee']>();


  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="electricityFeeRequired"
        label="Is There an Electricity Fee? *"
        includeUnknown
        row
      />
      {values.electricityFeeRequired === YesNoUnknownEnum.yes && (
        <>
          <TextInput
            name="electricityFeeAmount"
            label="Electricity Fee Amount *"
            type="number"
          />
          <RadioGroupYesNoBoolean
            name="electricityFeePaid"
            label="Is Electricity Fee Paid? *"
            includeUnknown
            row
          />
          <Select
            options={electricityPaymentTypeOptions}
            name="electricityFeePaymentType"
            label="Electricity Fee Payment Type *"
            disabled={values.electricityFeePaid !== YesNoUnknownEnum.yes}
          />
        </>
      )}
    </Box>
  );
};