import { Box, Breadcrumbs, Divider, Typography } from '@mui/material';
import { Link } from 'components/Link';
import { useEffect, useMemo, useRef } from 'react';

export type PageContentPrevBreadcrumb = {
  link: string;
  text: string;
};

export type PageContentBreadcrumbsProps = {
  breadcrumbs: {
    prev: PageContentPrevBreadcrumb[];
    current: string;
  };
};

export const PageContentBreadcrumbs: React.FC<PageContentBreadcrumbsProps> = props => {
  const breadcrumpContainerRef = useRef<HTMLDivElement>(null);

  const breadcrumbs = useMemo(() => {
    if (props.breadcrumbs) {
      return props.breadcrumbs.prev.map(({ link, text }, index) => (
        <Link
          key={index}
          to={`/${link}`}
          getColor={theme => theme.palette.primary.dark}
          fontSize="15px"
          lineHeight={1}
          underline="hover"
        >
          {text}
        </Link>
      )).concat(
        <Typography
          key={props.breadcrumbs.current}
          color="text.primary"
          fontSize="15px"
          lineHeight={1}
          mt={0.25}
        >
          {props.breadcrumbs.current}
        </Typography>
      );
    }

    return null;
  }, [ props.breadcrumbs ]);

  useEffect(() => {
    if (breadcrumpContainerRef.current) {
      breadcrumpContainerRef.current.scrollLeft = breadcrumpContainerRef.current.scrollWidth;
    }
  }, []);

  return (
    <Box>
      <Box
        height="28px"
        whiteSpace="nowrap"
        px={2}
        ref={breadcrumpContainerRef}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap'
          },
          '::-webkit-scrollbar': {
            height: '2px',
          },
        }}
        display="flex"
        alignItems="center"
        bgcolor={theme => theme.palette.background.defaultDarker}
      >
        <Breadcrumbs separator=">">{breadcrumbs}</Breadcrumbs>
      </Box>
      <Divider />
    </Box>
  );
};