import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const LogisticsLodging = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticsLodging}
      localStorageArgs={{
        initialVisible: [ 'name', 'eventTimeframe', 'daysCount', 'acceptanceStatus', 'studioToEvent', 'lodgingRequired' ],
      }}
    />
  );
};
