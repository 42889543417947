
import { AreYouSureModal, AreYouSureModalProps } from './AreYouSureModal.component';

export type DeleteResourceModalType = {
  deleteButtonText?: string;
  onDelete?: () => void;
} & Omit<AreYouSureModalProps, 'confirmButtonText' | 'confirmButtonColor' | 'onDelete'>;

export const DeleteResourceModal: React.FC<DeleteResourceModalType> = ({
  deleteButtonText = 'Delete',
  onDelete,
  ...props
}) => {

  return (
    <AreYouSureModal
      {...props}
      confirmButtonText={deleteButtonText}
      confirmButtonColor="error"
      onConfirm={onDelete}
    />
  );
};
