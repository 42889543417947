import { DeleteResourceModal, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { useProductOutletContext } from '../Product.base';
import { useMutation } from '@tanstack/react-query';
import { deleteProduct } from 'api/actions';

export type ProductDeleteModalProps = {
  onClose: () => void;
};

export const ProductDeleteModal: React.FC<ProductDeleteModalProps> = props => {
  const { product } = useProductOutletContext();
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const deleteMutation = useMutation({
    mutationFn: () => deleteProduct(product._id),
    onSuccess: async () => {
      props.onClose();
      snackbar.success('Product deleted');
      navigate(`/${ROUTING_CONFIG.productList}`);
    }
  });

  return (
    <DeleteResourceModal
      onClose={props.onClose}
      onDelete={() => deleteMutation.mutate()}
      deleteButtonText="Delete Product"
      description="Delete product"
      loading={deleteMutation.isLoading}
    />
  );
};
