import { useContext, useMemo } from 'react';
import { EventsManagementPageContext, eventsManagementTreeViewConfig } from '../EventsManagement.page';
import { EventsManagementTreeItemConfig, EventsManagementTreeViewNodeIdEnum, EventsManagementTreeViewTerminalNodeId, eventsManagementTreeViewNoBadgeNodeIds, eventsManagementTreeViewTerminalNodeIds } from '../helpers';
import { GetEventsResponse } from 'api/actions';
import { getFilteredRowsFromFilterConfigurations } from 'components';
import { useEventsManagementFilterConfigurations } from './useEventsManagementFilterConfigurations.hook';

export type UseEventManagementBadgeTitleInput = {
  nodeId: EventsManagementTreeViewNodeIdEnum;
};

const getEventManagementNodeIdsBadgeNumbersHash = (config: EventsManagementTreeItemConfig[], events: GetEventsResponse['data'], nodeIdToFilteredRowsCount: Record<EventsManagementTreeViewTerminalNodeId, number>): Record<EventsManagementTreeViewNodeIdEnum, number> => {
  return config.reduce((r, node) => {
    if(!node.children) {
      const filteredRowsCount = nodeIdToFilteredRowsCount[node.nodeId as EventsManagementTreeViewTerminalNodeId];

      return { ...r, [node.nodeId]: filteredRowsCount };
    }

    const badgeNumsHash = getEventManagementNodeIdsBadgeNumbersHash(node.children, events, nodeIdToFilteredRowsCount);
    const nodeBadgeNumber = node.children.reduce((r, node) => badgeNumsHash[node.nodeId] + r, 0);

    return { ...r, ...badgeNumsHash, [node.nodeId as EventsManagementTreeViewNodeIdEnum]: nodeBadgeNumber };
  }, {} as Record<EventsManagementTreeViewNodeIdEnum, number>);
};

export type UseEventManagementNodeIdsBadgeNumbersHashProps = GetEventsResponse['data'] | undefined;

export const useEventManagementNodeIdsBadgeNumbersHash = (events: UseEventManagementNodeIdsBadgeNumbersHashProps = undefined) => {
  const { events: contextEvents } = useContext(EventsManagementPageContext);
  const filterConfigurations = useEventsManagementFilterConfigurations();
  const nodeIdToFilteredRowsCount = useMemo(() => {
    return eventsManagementTreeViewTerminalNodeIds.reduce((r, nodeId) => {
      if (eventsManagementTreeViewNoBadgeNodeIds.includes(nodeId)) {
        return { ...r, [nodeId]: 0 };
      }
      const filterConfiguration = filterConfigurations[nodeId] ?? [];
      const filteredRows = getFilteredRowsFromFilterConfigurations(events ?? contextEvents, filterConfiguration);

      return { ...r, [nodeId]: filteredRows.length };
    }, {} as Record<EventsManagementTreeViewTerminalNodeId, number>);
  }, [ contextEvents, events, filterConfigurations ]);

  return getEventManagementNodeIdsBadgeNumbersHash(eventsManagementTreeViewConfig, events ?? contextEvents, nodeIdToFilteredRowsCount);
};
