import { GetEventResponse } from 'api/actions';
import React, { useMemo } from 'react';
import { eventDateEnumHelpers } from 'helpers';
import { EventDateSetupBreakdownTypeEnum, EventDateTypeEnum } from 'api/resources';
import { DateService } from 'services';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

export type EventDateTimesListProps = {
  eventDate: GetEventResponse['data']['dates'][number];
};

export const EventDateTimesList: React.FC<EventDateTimesListProps> = ({ eventDate }) => {
  const startEndTimesLabel = useMemo(() => {
    switch (eventDate.type) {
      case EventDateTypeEnum.regular:
        return 'Show';
      case EventDateTypeEnum.setup:
        return 'Setup';
      case EventDateTypeEnum.breakdown:
        return 'Breakdown';
      default:
        return '';
    }
  }, [ eventDate.type ]);

  const timelineItems = useMemo(() => {
    const items: {title: string; value: string; isUnassigned?: boolean}[] = [];

    if (eventDate.type === EventDateTypeEnum.regular) {
      if (eventDate.setupType === EventDateSetupBreakdownTypeEnum.hard) {
        items.push(
          {
            title: 'Arrive as early as',
            value: DateService.dayjs(eventDate.arriveAsEarlyAs, 'HH:mm').format('h:mma'),
            isUnassigned: !eventDate.arriveAsEarlyAs,
          },
          {
            title: 'No vehicles allowed after',
            value: DateService.dayjs(eventDate.noVehiclesAllowedAfter, 'HH:mm').format('h:mma'),
            isUnassigned: !eventDate.noVehiclesAllowedAfter,
          },
          {
            title: 'Vehicles must be removed by',
            value: DateService.dayjs(eventDate.allVehiclesMustBeRemovedBy, 'HH:mm').format('h:mma'),
            isUnassigned: !eventDate.allVehiclesMustBeRemovedBy,
          },
        );
      }

      items.push(
        {
          title: 'Display setup',
          value: `${eventDate.displaySetupInMinutes} minutes (${eventDateEnumHelpers.setupBreakdownType.getLabel(eventDate.setupType)})`,
        },
        {
          title: 'Complete setup by',
          value: DateService.dayjs(eventDate.setupEndTime, 'HH:mm').format('h:mma'),
          isUnassigned: !eventDate.setupEndTime,
        },
      );
    }

    items.push(
      {
        title: `${startEndTimesLabel} start`,
        value: DateService.dayjs(eventDate.startTime, 'HH:mm').format('h:mma'),
      },
      {
        title: `${startEndTimesLabel} end`,
        value: DateService.dayjs(eventDate.endTime, 'HH:mm').format('h:mma'),
      }
    );

    if (eventDate.type === EventDateTypeEnum.regular) {
      items.push(
        {
          title: 'Display breakdown',
          value: `${eventDate.breakdownInMinutes} minutes (${eventDateEnumHelpers.setupBreakdownType.getLabel(eventDate.breakdownType)})`,
        }
      );
    }

    return items;
  }, [ eventDate.allVehiclesMustBeRemovedBy, eventDate.arriveAsEarlyAs, eventDate.breakdownInMinutes, eventDate.breakdownType, eventDate.displaySetupInMinutes, eventDate.endTime, eventDate.noVehiclesAllowedAfter, eventDate.setupEndTime, eventDate.setupType, eventDate.startTime, eventDate.type, startEndTimesLabel ]);

  if (eventDate.type === EventDateTypeEnum.travel) {
    return null;
  }

  return (
    <Timeline position="left">
      {timelineItems.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent color={item.isUnassigned ? 'warning.dark' : 'text.secondary'} fontWeight={item.isUnassigned ? 500 : undefined}>
            {item.isUnassigned ? 'Unassigned': item.value}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            {index < timelineItems.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>{item.title}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
