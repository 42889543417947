import { ErrorPageContent } from 'components';

export const NoRoute404: React.FC = () => {

  return (
    <ErrorPageContent
      errorCode={404}
      message="Uh oh. We can't seem to find the page you're looking for. Try going back to the previous page or reach out to management."
    />
  );
};