import { ProductsAutocomplete, SectionCard, SectionCardRow, TextInput, ToggleButtonGroup, YesNoUnknownIconSwitch } from 'components';
import React from 'react';
import { SaleSectionCardRowEditable } from '../components/SaleSectionCardRowEditable.component';
import { useSaleOutletContext } from '../Sale.base';
import { SalePageSectionRowId } from '../types';
import { updateSale } from 'api/actions';
import { mixed, number, object, string } from 'yup';
import { frameEnumToValueHash, getFrameEnumFromValue, getSelectOptionsFromEnumHelper, saleEnumHelpers } from 'helpers';
import { FrameEnum } from 'types';
import { Box, ToggleButton } from '@mui/material';
import { formSx } from 'styles';
import { SalePaymentEnum } from 'api/resources';
import { DateService, currencyFormatter } from 'services';

export const OverviewSection = () => {
  const { sale, warehouseTeam } = useSaleOutletContext();

  return (
    <SectionCard title="Overview">
      <SaleSectionCardRowEditable
        title="Product"
        rowId={SalePageSectionRowId.PRODUCT}
        formikProps={{
          initialValues: { product: sale.product._id },
          onSubmit: (values) => updateSale(sale._id, { product: values.product }),
          validationSchema: object({ product: string().required('Product Required') })
        }}
        form={<ProductsAutocomplete name="product" label="Product" teamId={sale.event ? sale.event.team._id : warehouseTeam._id} />}
      >
        {sale.product.name}
      </SaleSectionCardRowEditable>
      <SectionCardRow title="Log">Logged {sale.user ? <>by <b>{sale.user?.name}</b></> : null} on {DateService.getFormattedDateAndTime(sale.createdAt, 'ddd, MMM Do, YYYY', sale.event?.timezone)} <b>{sale.type}</b></SectionCardRow>
      <SaleSectionCardRowEditable
        title="Price"
        rowId={SalePageSectionRowId.SALE_PRICE}
        formikProps={{
          initialValues: { salePrice: sale.salePrice },
          onSubmit: (values) => updateSale(sale._id, { salePrice: values.salePrice }),
          validationSchema: object({ salePrice: number().required('Price Required') })
        }}
        form={<TextInput name="salePrice" type="number" label="Sale Price" fullWidth />}
      >
        {currencyFormatter.format(sale.salePrice)}
      </SaleSectionCardRowEditable>
      <SaleSectionCardRowEditable
        title="Frame"
        rowId={SalePageSectionRowId.FRAME}
        formikProps={{
          initialValues: { frame: getFrameEnumFromValue(sale.frame) },
          onSubmit: (values) => updateSale(sale._id, { frame: frameEnumToValueHash[values.frame], }),
          validationSchema: object({ frame: mixed<FrameEnum>().oneOf([ FrameEnum.frame, FrameEnum.noFrame ]).required() })
        }}
        form={(
          <Box sx={formSx.formGroup}>
            <ToggleButtonGroup label="Frame" name="frame" color="primary" exclusive fullWidth>
              {getSelectOptionsFromEnumHelper(saleEnumHelpers.frame).map(({ value, label }) => (
                <ToggleButton value={value} key={value}>{label}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
      >
        <YesNoUnknownIconSwitch value={sale.frame} />
      </SaleSectionCardRowEditable>
      <SaleSectionCardRowEditable
        title="Payment"
        rowId={SalePageSectionRowId.PAYMENT}
        formikProps={{
          initialValues: { payment: sale.payment },
          onSubmit: (values) => updateSale(sale._id, { payment: values.payment }),
          validationSchema: object({ payment: mixed<SalePaymentEnum>().oneOf(saleEnumHelpers.salePayment.enumValues).required() })
        }}
        form={(
          <Box sx={formSx.formGroup}>
            <ToggleButtonGroup label="Payment" name="payment" color="primary" exclusive fullWidth>
              {getSelectOptionsFromEnumHelper(saleEnumHelpers.salePayment).map(({ value, label }) => (
                <ToggleButton value={value} key={value}>{label}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
        )}
      >
        {sale.payment}
      </SaleSectionCardRowEditable>
      {(sale.event && sale.eventDate) && <SectionCardRow title="Event Date">{sale.event.name} - {DateService.getFormattedDate(DateService.dayjsTz(sale.eventDate.dateAsUtc), 'ddd, MMM Do, YYYY')}</SectionCardRow>}
      {sale.storefront && <SectionCardRow title="Store Front">{sale.storefront.name}</SectionCardRow>}
      <SectionCardRow title="Order">{sale.order?.name}</SectionCardRow>
      <SectionCardRow title="Sale ID">{sale._id}</SectionCardRow>
    </SectionCard>
  );
};
