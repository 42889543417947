import { StatusIndicatorProps, WrapWithStatusIndicator } from 'components';

export type QuantityWithStatusCellType = {
  quantity: number;
  baseline: number;
};

export const QuantityWithStatusCell: React.FC<QuantityWithStatusCellType> = ({ quantity, baseline }) => {
  let status: StatusIndicatorProps['status'] = 'error';

  if (quantity > baseline) {
    status = 'success';
  }

  if (quantity === baseline) {
    status = 'warning';
  }

  return <WrapWithStatusIndicator status={status}>{quantity}</WrapWithStatusIndicator>;
};
