import { Box } from '@mui/material';
import { MarkdownInput, TextInput } from 'components';
import { formSx } from 'styles';

export const EventOrganizerBasicInfoForm: React.FC = () => {
  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        trim
        fullWidth
        id="name"
        name="name"
        label="Event Organizer Name *"
      />
      <TextInput
        trim
        fullWidth
        id="website"
        name="website"
        label="Website *"
      />
      <TextInput
        trim
        fullWidth
        id="mailingAddress"
        name="mailingAddress"
        label="Mailing Address *"
        multiline
        minRows={2}
      />
      <MarkdownInput name="notes" label="Notes" />
    </Box>
  );
};
