import { GetSalesReportDataResponse, GetSalesResponse } from 'api/actions';
import { useMemo } from 'react';
import { DateService, currencyFormatter } from 'services';
import { AnalyticsTimelineChart, AnalyticsTimelineChartProps } from '../AnalyticsTimelineChart.component';

type TSale = Pick<GetSalesResponse['data'][number], 'saleDate' | 'salePrice' | 'payment'>;

export type SalesAnalyticsTimelineChartProps = Omit<GetSalesReportDataResponse['data'], 'timePeriod'> & Pick<AnalyticsTimelineChartProps<TSale>, 'tooltipContent' | 'setTimePeriod' | 'timePeriod'> & {
  header?: string;
  endToday?: boolean;
  loading?: boolean;
};

export const SalesAnalyticsTimelineChart: React.FC<SalesAnalyticsTimelineChartProps> = ({
  endToday = true,
  header = 'Sales',
  totalRevenue,
  dateRange: _dateRange,
  loading,
  data,
  labels,
  ...props
}) => {
  const dateRange =  useMemo(() => {
    return { start: DateService.dayjsTz(_dateRange.start), end: endToday ? DateService.dayjsTz() : DateService.dayjsTz(_dateRange.end) };
  }, [ _dateRange.end, _dateRange.start, endToday ]);

  return (
    <AnalyticsTimelineChart
      header={header}
      subheader={currencyFormatter.format(totalRevenue)}
      dateRange={dateRange}
      data={{ labels,  datasets: [ { data, dateRange } ] }}
      loading={loading}
      {...props}
    />
  );
};
