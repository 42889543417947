import { SxProps, Theme } from '@mui/material';
import { EnumHelpers } from './enums/types';

export type SelectOptionType = {
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const getSelectOptionsFromEnumHelper = <T extends string>(enumHelper: EnumHelpers<T>): Pick<SelectOptionType, 'value' | 'label'>[] => {
  return enumHelper.enumValues.map(value => ({ value, label: enumHelper.getLabel(value) }));
};