import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

export type FullCalendarSidebarActionProps = {
  label?: string;
  content: React.ReactNode;
  onClickMore?: () => void;
};

export const FullCalendarSidebarAction: React.FC<FullCalendarSidebarActionProps> = props => {
  return (
    <Box py={2}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box flex={1}>
          <Typography variant="subtitle2" color="primary.main" mb={0.5}>
            {props.label}
          </Typography>
          {props.content}
        </Box>
        {props.onClickMore &&
        <IconButton onClick={props.onClickMore}>
          <MoreVert />
        </IconButton>}
      </Box>
    </Box>
  );
};
