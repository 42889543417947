import { EnumHelpers } from 'helpers/enums/types';
import React from 'react';

export type ShowTheseFieldsConfig<Field extends string, Group extends string, Resource, Extra = undefined> = {
  field: Field;
  group?: Group;
  label: string;
  getContent: (resource: Resource, group: Resource[], extra: Extra) => React.ReactNode;
  disableTypography?: boolean;
};

// Event

export const enum EventsShowTheseFieldsGroup {
  event = 'event',
  eventDate = 'eventDate',
  team = 'team',
  logistics = 'logistics',
}

export const eventsShowTheseFieldsGroupEnumHelpers: EnumHelpers<EventsShowTheseFieldsGroup> = {
  enumValues: [
    EventsShowTheseFieldsGroup.event,
    EventsShowTheseFieldsGroup.eventDate,
    EventsShowTheseFieldsGroup.team,
    EventsShowTheseFieldsGroup.logistics,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventsShowTheseFieldsGroup.event:
        return 'Event overview';
      case EventsShowTheseFieldsGroup.eventDate:
        return 'Event Date overview';
      case EventsShowTheseFieldsGroup.team:
        return 'Team and Staff';
      case EventsShowTheseFieldsGroup.logistics:
        return 'Logistics';
      default:
        return 'Other';
    }
  },
  getColor: () => 'unknown',
};