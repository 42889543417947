import { DeleteResourceModal, DeleteResourceModalType, useAlertSnackbar } from 'components';
import { useEventOrganizerOutletContext } from '../EventOrganizer.base';
import { useMutation } from '@tanstack/react-query';
import { deleteEventOrganizer } from 'api/actions';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';

export type DeleteEventOrganizerModalProps = Pick<DeleteResourceModalType, 'onClose'>;

export const DeleteEventOrganizerModal: React.FC<DeleteEventOrganizerModalProps> = props => {
  const snackbar = useAlertSnackbar();
  const navigate = useNavigate();
  const { eventOrganizer } = useEventOrganizerOutletContext();
  const deleteMutation = useMutation({
    mutationFn: () => deleteEventOrganizer(eventOrganizer._id),
    onSuccess: async () => {
      snackbar.success('Event organizer deleted');
      navigate(`/${ROUTING_CONFIG.eventOrganizers}`, { replace: true });
    }
  });

  return (
    <DeleteResourceModal
      onClose={props.onClose}
      onDelete={deleteMutation.mutate}
      description="Are you sure you want to delete this event organizer? The associated contacts will be deleted and the events will be unlinked."
      deleteButtonText="Delete Event Organizer"
      loading={deleteMutation.isLoading}
    />
  );
};