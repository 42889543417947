import { ChipProps } from '@mui/material';
import { EventPreperationParticipationStatusEnum } from 'api/resources';
import { StandardChip } from '../StandardChip.component';
import { eventEnumHelpers } from 'helpers';

export type ParticipationStatusChipProps = {
  value: EventPreperationParticipationStatusEnum | undefined;
} & ChipProps;

export const ParticipationStatusChip: React.FC<ParticipationStatusChipProps> = ({ value, ...props }) => {
  return <StandardChip value={value} {...eventEnumHelpers.participationStatus} {...props} />;
};