import React, { useMemo } from 'react';
import { AreYouSureModal, EventTeamChangeObject, useAlertSnackbar } from 'components';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEvent } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';

export type TeamChangeModalProps = {
  teamChangeObj: EventTeamChangeObject;
  onClose: () => void;
};

export const ConfirmTeamChangeModal: React.FC<TeamChangeModalProps> = ({ teamChangeObj, onClose }) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateMutation = useMutation({
    mutationFn: () => updateEvent(teamChangeObj.event._id, { team: teamChangeObj.newTeam?._id || null }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);
      snackbar.success('Teams updated');

      onClose();
    }
  });

  const onCancel = () => {
    teamChangeObj.revert();
    onClose();
  };


  const confirmationText = useMemo(() => {
    if (teamChangeObj.newTeam) {

      if (teamChangeObj.event.team) {
        return <Typography>You are about to change a team for <b>{teamChangeObj.event.name}</b> from <b>{teamChangeObj.event.team.name}</b> to <b>{teamChangeObj.newTeam.name}</b></Typography>;
      } else {
        return <Typography>You are about to <b>set</b> a team for <b>{teamChangeObj.event.name}</b> to <b>{teamChangeObj.newTeam.name}</b></Typography>;
      }

    } else {
      return <Typography>You are about to <b>remove</b> a team for <b>{teamChangeObj.event.name}</b></Typography>;
    }
  }, [ teamChangeObj ]);

  return (
    <AreYouSureModal
      description={confirmationText}
      onClose={onCancel}
      onConfirm={updateMutation.mutate}
      loading={updateMutation.isLoading}
    />
  );
};
