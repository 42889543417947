import { InventoryBatchStatusChip, PageContentContainer, PageContentResourceHeader, PageContentResourceHeaderRenderTitleParams } from 'components';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useCurrentUser } from 'contexts';

export type InventoryBatchLayoutProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
  preventReducedMarginTop?: boolean;
};

export const InventoryBatchLayout: React.FC<InventoryBatchLayoutProps> = props => {
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const { getIsMe, isAuthorizedEmployee } = useCurrentUser();
  const isOpenStep = useMemo(() => getIsMe(inventoryBatch.user._id) && inventoryBatch.status === InventoryBatchStatusEnum.open, [ getIsMe, inventoryBatch.status, inventoryBatch.user._id ]);
  const isReviewStep = useMemo(() => (!getIsMe(inventoryBatch.user._id) || isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ])) && inventoryBatch.status === InventoryBatchStatusEnum.review, [ getIsMe, inventoryBatch.status, inventoryBatch.user._id ]);

  const description = useMemo(() => {
    if (isOpenStep) {
      let action = '';

      if (inventoryBatch.type === InventoryBatchTypeEnum.picking) {
        action = 'Picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
        action = 'Picking out overstocked';
      } else {
        action = 'Logging production';
      }

      return `${action} ${inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking ? 'from' : 'for'} ${inventoryBatch.team.name}`;
    }
    if(isReviewStep) {
      let action = '';

      if (inventoryBatch.type === InventoryBatchTypeEnum.picking) {
        action = 'picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
        action = 'overstock picking';
      } else {
        action = 'production';
      }

      return `Review ${action} batch for ${inventoryBatch.team.name}`;
    }

    return null;
  }, [ inventoryBatch.team.name, inventoryBatch.type, isOpenStep, isReviewStep ]);

  const renderTitle = useCallback(({ title }: PageContentResourceHeaderRenderTitleParams) => {
    if (!description) {
      return title;
    }

    return (
      <Box display="flex" alignItems="center" gap={1}>
        {(isOpenStep || isReviewStep) && <InventoryBatchStatusChip value={inventoryBatch.status} />}
        <Box>
          {title}
          <Typography variant="h5" fontWeight={500} component="span">:</Typography>
        </Box>
        <Typography variant="h6" fontWeight={400}>{description}</Typography>
      </Box>
    );
  }, [ description, inventoryBatch.status, isOpenStep, isReviewStep ]);

  return (
    <PageContentContainer
      maxWidth="xl"
      breadcrumbs={{
        prev: [
          { text: 'Inventory Batches', link: `${ROUTING_CONFIG.inventoryBatches}` },
        ],
        current: inventoryBatch.name,
      }}
      header={(
        <PageContentResourceHeader
          title={inventoryBatch.name}
          renderTitle={renderTitle}
          pageTitle="Inventory Batch Page"
          actions={props.actions}
        />
      )}
      contentSx={props.preventReducedMarginTop ? {} : { mt: 1 }}
    >
      {props.children}
    </PageContentContainer>
  );
};