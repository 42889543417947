import { useEffect, useMemo, useState } from 'react';
import { ColorCodeAction } from './ColorCodeAction.component';
import { ColorCodeConfig } from './colorCode';
import { useLocalStorage } from 'hooks';
import { ColorCodeModal } from './ColorCodeModal.modal';

export type UseColorCodeProps<Option extends string, Arg extends unknown> = {
  id: string;
  defaultValue: Option;
  colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>;
  disabledColorCodeOptions?: Option[];
};

export const useColorCode = <Option extends string, Arg extends unknown>({ id, defaultValue, colorCodeConfigHash, disabledColorCodeOptions }: UseColorCodeProps<Option, Arg>) => {
  const colorCodeOptionLocalStorageService = useLocalStorage<Option | null>(`${id}ColorCodeOption`);
  const [ colorCodeOption, setColorCodeOption ] = useState<Option | null>(colorCodeOptionLocalStorageService.getResource(defaultValue));
  const [ showModal, setShowModal ] = useState<boolean>(false);

  const modal = useMemo(() => {
    if (!showModal) {
      return null;
    }

    return (
      <ColorCodeModal
        colorCodeOption={colorCodeOption}
        onColorCodeOptionChange={(option) => setColorCodeOption(option)}
        disabledColorCodeOptions={disabledColorCodeOptions}
        colorCodeConfigHash={colorCodeConfigHash}
        onClose={() => setShowModal(false)}
      />
    );
  }, [ colorCodeConfigHash, colorCodeOption, disabledColorCodeOptions, showModal ]);

  const sidebarActionConfig = useMemo(() => ({
    label: 'Color Code',
    content: <ColorCodeAction colorCodeOption={colorCodeOption} colorCodeConfigHash={colorCodeConfigHash} />,
    onClickMore: () => setShowModal(true)
  }), [ colorCodeConfigHash, colorCodeOption ]);

  useEffect(() => {
    return () =>  colorCodeOptionLocalStorageService.setResource(colorCodeOption);
  }, [ colorCodeOption, colorCodeOptionLocalStorageService ]);

  return {
    colorCodeOption,
    setColorCodeOption,
    modal,
    sidebarActionConfig,
  };
};