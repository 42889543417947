import { BarChartOutlined, CloseFullscreen, OpenInFull, TimelineOutlined, TransitEnterexit } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { AnalyticsChartWrapper } from './AnalyticsChartWrapper.component';
import { AnalyticsTimelineChartTimePeriodEnum, GetAnalyticsTimelineChartDataProps, analyticsTimelineChartTimePeriodEnumHelpers, getAnalyticsTimelineChartData, getInitialAnalyticsTimelineChartTimePeriodForDateRange } from './helpers';
import { DateRange } from 'components';
import { AnalyticsChartDateRangeChip } from './AnalyticsChartDateRangeChip.component';
import { AnalyticsChartData, AnalyticsChartTypeEnum, UseAnalyticsChartProps, useAnalyticsChart } from './useAnalyticsChart.hook';
import { DefaultAnalyticsTimelineChartTooltipContent } from './DefaultAnalyticsTimelineChartTooltipContent.component';

export type AnalyticsTimelineChartConfig<Data> = Omit<GetAnalyticsTimelineChartDataProps<Data>, 'baseConfig'> & {baseConfig: Omit<GetAnalyticsTimelineChartDataProps<Data>['baseConfig'], 'timePeriod'>};
export type TooltipContentTimelineChartTooltipArg = {
  data: AnalyticsChartData;
  dataIndex: number;
  timePeriod: AnalyticsTimelineChartTimePeriodEnum;
};

export type OnAnalyticsTimelineChartTimePeriodChangeArg = {
  setChartType: React.Dispatch<React.SetStateAction<AnalyticsChartTypeEnum>>;
};

export type AnalyticsTimelineChartBaseProps = Omit<UseAnalyticsChartProps, 'tooltipContent' | 'data' | 'allowedViewTypes'> & {
  header: string;
  subheader: string;
  dateRange: DateRange;
  desiredTrend?: 'asc' | 'desc';
  loading?: boolean;
  // if these props are present, it means timePeriod is handled outside the component
  timePeriod?: AnalyticsTimelineChartTimePeriodEnum;
  setTimePeriod?: (timePeriod: AnalyticsTimelineChartTimePeriodEnum) => void;
  tooltipContent?: (arg: TooltipContentTimelineChartTooltipArg) => React.ReactNode;
  onTimePeriodChange?: (timePeriod: AnalyticsTimelineChartTimePeriodEnum, arg: OnAnalyticsTimelineChartTimePeriodChangeArg) => void;
};
export type AnalyticsTimelineChartWithConfigProps<Data> = AnalyticsTimelineChartBaseProps & {
  config: AnalyticsTimelineChartConfig<Data>;
};
export type AnalyticsTimelineChartWithDataProps = AnalyticsTimelineChartBaseProps & {
  data: AnalyticsChartData;
};
export type AnalyticsTimelineChartProps<Data> = AnalyticsTimelineChartWithConfigProps<Data> | AnalyticsTimelineChartWithDataProps;

const defaultDesiredTrend = 'asc';

export const AnalyticsTimelineChart = <Data extends unknown>({
  header,
  subheader,
  dateRange,
  loading,
  desiredTrend = defaultDesiredTrend,
  timePeriod: propsTimePeriod,
  setTimePeriod: setPropsTimePeriod,
  tooltipContent = DefaultAnalyticsTimelineChartTooltipContent,
  onTimePeriodChange,
  ...props
}: AnalyticsTimelineChartProps<Data>) => {
  const [ innerTimePeriod, setInnerTimePeriod ] = useState(AnalyticsTimelineChartTimePeriodEnum.week);
  const timePeriod = propsTimePeriod ?? innerTimePeriod;
  const data = useMemo(() => {
    if ('config' in props) {
      return getAnalyticsTimelineChartData({ ...props.config, baseConfig: { ...props.config.baseConfig, timePeriod } });
    }

    return props.data;
  }, [ props, timePeriod ]);
  const {
    chartType,
    setChartType,
    chartDisplay,
    tooltipDisplay,
    colors,
    dataWithStyles,
    fullScreen,
    onFullScreenToggle,
  } = useAnalyticsChart({
    data: data,
    tooltipContent: (arg) => tooltipContent({ ...arg, timePeriod }),
    getDisplayLegend: (params) => params.datasets.length > 1 && params.datasets.every(dataset => dataset.label),
    ...props,
  });

  useEffect(() => {
    const timePeriod = getInitialAnalyticsTimelineChartTimePeriodForDateRange(dateRange);

    setInnerTimePeriod(timePeriod);
  }, [ dateRange ]);

  useEffect(() => {
    onTimePeriodChange?.(timePeriod, { setChartType });
  }, [ onTimePeriodChange, setChartType, timePeriod ]);

  // is undefined when there was no secondary datasets
  // otherwise, is an increase/decrease percentage of sum of primary datasets
  // in comparison with sum of secondary datasets
  const percentageDiff = useMemo(() => {
    const { baseDatasets, referenceDatasets } = data.datasets.reduce((r, dataset: AnalyticsChartData['datasets'][number]) => {
      if(dataset.referenceIndex === undefined) {
        return { ...r, baseDatasets: [ ...r.baseDatasets ?? [], dataset ] };
      }
      return { ...r, referenceDatasets: [ ...r.referenceDatasets ?? [], dataset ] };
    }, { baseDatasets: [], referenceDatasets: [] } as {baseDatasets: AnalyticsChartData['datasets']; referenceDatasets: AnalyticsChartData['datasets']});

    const totalData = baseDatasets.reduce((r1, dataset: AnalyticsChartData['datasets'][number]) => r1 + dataset.data.reduce((r2, data) => r2 + data, 0), 0);

    if(!totalData || !referenceDatasets.length) {
      return undefined;
    }

    const referenceTotalData = referenceDatasets.reduce((r1, dataset) => r1 + dataset.data.reduce((r2, data) => r2 + data, 0), 0);
    const diff = totalData - referenceTotalData;

    return Number(((diff / totalData) * 100).toFixed(2));
  }, [ data.datasets, props ]);

  const dateRangeChipDisplay = useMemo(() => {
    if(dataWithStyles.datasets.length) {
      return (
        <>
          {dataWithStyles.datasets.map((dataset: AnalyticsChartData['datasets'][number], index) => {
            return (
              <AnalyticsChartDateRangeChip
                key={index}
                dateRange={dataset.dateRange}
                indicatorColor={dataset.borderColor?.toString() ?? colors[0].main}
                indicatorDashed={!!dataset.borderDash}
              />
            );
          })}
        </>
      );
    }
    return <AnalyticsChartDateRangeChip dateRange={dateRange} />;
  }, [ colors, dataWithStyles.datasets, dateRange ]);

  return (
    <AnalyticsChartWrapper loading={loading} fullScreen={fullScreen}>
      {tooltipDisplay}
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Typography variant="subtitle1" sx={{ pb: 0, borderBottomColor: (theme) => theme.palette.grey[500], borderBottomStyle: 'dotted', borderBottomWidth: 3 }} >{header}</Typography>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <Typography variant="h6" fontWeight={600} >{subheader}</Typography>
              {percentageDiff !== undefined &&
                <Typography
                  variant="caption"
                  color={(desiredTrend === 'asc' ? percentageDiff >= 0 : percentageDiff < 0) ? 'success.main' : 'error.main'}
                  fontWeight={500}
                  display="flex"
                  alignItems="center"
                >
                  <TransitEnterexit fontSize="small" sx={{ transform: `scaleX(-1) ${percentageDiff > 0 ? 'scaleY(-1)' : ''}`, fontSize: 14 }}/>
                  {Math.abs(percentageDiff)}%
                </Typography>}
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <ToggleButtonGroup
              value={propsTimePeriod ?? innerTimePeriod}
              onChange={(_, value) => value && (propsTimePeriod ? setPropsTimePeriod?.(value) : setInnerTimePeriod(value))}
              exclusive
              size="small"
            >
              {analyticsTimelineChartTimePeriodEnumHelpers.enumValues.map((value) => {
                const disabled = !dateRange || analyticsTimelineChartTimePeriodEnumHelpers.getDisabled(value, dateRange);

                if (disabled) {
                  return null;
                }

                return <ToggleButton key={value} value={value} disabled={disabled}>{analyticsTimelineChartTimePeriodEnumHelpers.getLabel(value)}</ToggleButton>;
              })}
            </ToggleButtonGroup>
            <ToggleButtonGroup value={chartType} onChange={(_, value) => value && setChartType(value)} exclusive size="small">
              <ToggleButton value={AnalyticsChartTypeEnum.bar}><BarChartOutlined fontSize="small" /></ToggleButton>
              <ToggleButton value={AnalyticsChartTypeEnum.line}><TimelineOutlined fontSize="small" /></ToggleButton>
            </ToggleButtonGroup>
            <ToggleButton onClick={onFullScreenToggle} value="" size="small">
              {fullScreen ? <CloseFullscreen titleAccess="Exit Full Screen" fontSize="small" /> : <OpenInFull titleAccess="Enter Full Screen" fontSize="small" />}
            </ToggleButton>
          </Box>
        </Box>
        {chartDisplay}
        <Box display="flex" justifyContent="flex-end" gap={1} mt={1} overflow="auto" flexWrap="wrap">
          {dateRangeChipDisplay}
        </Box>
      </Box>
    </AnalyticsChartWrapper>
  );
};