import { SearchOutlined } from '@mui/icons-material';
import { Box, Card, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { GetLoggingPortalDataResponse } from 'api/actions';
import { useLoggingPortalContext } from 'pages/LoggingPortal/LoggingPortal.base';
import { useMemo, useRef, useState } from 'react';

type TProduct = GetLoggingPortalDataResponse['data']['products'][number];
export type ProductFinderProps = {
  onProductSelect: (product: TProduct) => void;
};

const sortProductsAlphabetically = (products: TProduct[]) => {
  return [ ...products ].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });
};

const alphabet = [ '0-9', 'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z' ];
const alphabetHash = alphabet.reduce((acc, letter) => ({ ...acc, [letter]: [] }), {} as Record<string, TProduct[]>);

const getProductsGroupedByLetter = (products: TProduct[]) => {
  return products.reduce((acc, product) => {
    const firstLetter = product.name[0].toUpperCase();

    if (firstLetter.match(/[0-9]/)) {
      return { ...acc, '0-9': [ ...acc['0-9'], product ] };
    }

    return { ...acc, [firstLetter]: [ ...acc[firstLetter], product ] };
  }, alphabetHash);
};

const getProductsGroupedByLetterWithThePrefix = (products: TProduct[]) => {
  return products.reduce((acc, product) => {
    const lowerCaseName = product.name.toLowerCase().trim();

    if (!lowerCaseName.startsWith('the ')) {
      return acc;
    };

    const theRemoved = lowerCaseName.replace('the ', '');
    const firstLetter = theRemoved[0].toUpperCase();

    if (firstLetter === 'T') {
      return acc;
    }

    if (firstLetter.match(/[0-9]/)) {
      return { ...acc, '0-9': [ ...(acc?.['0-9'] ?? []), product ] };
    }

    return { ...acc, [firstLetter]: [ ...(acc?.[firstLetter] ?? []), product ] };
  }, {} as Record<string, TProduct[]>);
};

export const ProductFinder: React.FC<ProductFinderProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const [ search, setSearch ] = useState('');
  const [ clicked, setClicked ] = useState<string | null>(null);
  const { products } = useLoggingPortalContext();

  const onProductSelect: ProductFinderProps['onProductSelect'] = (product) => {
    setSearch('');
    if (ref.current) {
      ref.current.scrollTo({ top: 0 });
    }
    props.onProductSelect(product);
  };

  const filteredProducts = search
    ? products.filter((product) => {
      const lowerCaseName = product.name.toLowerCase().trim();

      let nameWithoutThe = null;

      if (lowerCaseName.startsWith('the ')) {
        nameWithoutThe = lowerCaseName.replace('the ', '');
      };

      return lowerCaseName.includes(search.toLowerCase()) || (nameWithoutThe && nameWithoutThe.includes(search.toLowerCase()));
    })
    : products;

  const sortedProducts = useMemo(() => sortProductsAlphabetically(filteredProducts), [ filteredProducts ]);

  const productsAvailablility = sortedProducts.reduce((acc, product) => {
    if (product.availability?.events) {
      return { ...acc, available: [ ...acc.available, product ] };
    }

    return { ...acc, unavailable: [ ...acc.unavailable, product ] };
  }, { available: [], unavailable: [] });

  const productsGroupedByLetter = getProductsGroupedByLetter(productsAvailablility.available);
  const productsGroupedByLetterWithThePrefix = getProductsGroupedByLetterWithThePrefix(productsAvailablility.available);

  const onSearch = (value: string) => {

    if (value && !value.match(/^[a-zA-Z0-9][a-zA-Z0-9- ]*$/)) {
      return;
    }

    if (value && search.length === 0) {
      const firstLetter = value[0].toUpperCase();

      if (firstLetter.match(/[0-9]/)) {
        onScrollToLetter('0-9', true);
      } else {
        onScrollToLetter(firstLetter, true);
      }
    }

    setSearch(value);
  };

  const onScrollToLetter = (letter: string, top = false) => {
    const element = document.getElementById(letter);

    if (element) {
      element.scrollIntoView({ block: top ? 'start' : 'center' });
    }
  };

  const mapProducts = (products: TProduct[]) => {
    return products.map((product) => {
      const disabled = !product.availability?.events;

      return (
        <Box key={product._id}>
          <Card
            sx={{
              p: 1.5,
              px: 2.5,
              cursor: disabled ? undefined : 'pointer',
              userSelect: 'none',
              background: theme => disabled ? theme.palette.action.disabledBackground : undefined,
              color: theme => disabled ? theme.palette.text.disabled : undefined,
              '&:hover': {
                background: theme => disabled ? undefined : theme.palette.primary.background
              }
            }}
            variant="outlined"
            onClick={() => !disabled && onProductSelect(product)}
          >
            <Typography>{product.name}</Typography>
          </Card>
        </Box>
      );
    });
  };

  return (
    <Box display="flex" flex={1}>
      <Box flex={1} position="relative">
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Box bgcolor={theme => theme.palette.background.paper}>
            <Box px={2} pt={2} pb={1} display="flex" alignItems="center" gap={1}>
              <Box flex={1} maxWidth={{ lg: '350px' }}>
                <TextField
                  autoFocus
                  label="Search"
                  size="medium"
                  onChange={(e) => onSearch(e.target.value)}
                  value={search}
                  autoComplete="off"
                  fullWidth
                  placeholder="Product name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box display="flex" flexGrow={1} flexDirection="column" overflow="auto" component="div" ref={ref}>
            <Box
              py={2}
              sx={{
                '@keyframes fade-in': {
                  from: { background: theme => theme.palette.primary.main },
                  to: { background: 'unset' }
                },
                '& .fade-in': {
                  animation: 'fade-in 1.5s'
                }
              }}
            >
              {!filteredProducts.length && (
                <Box px={2}>
                  <Typography variant="h6">No Results</Typography>
                  <Typography maxWidth="400px">We couldn't find a match for "{search}". Please try another search.</Typography>
                </Box>
              )}
              {alphabet.map((letter) => {
                const products = productsGroupedByLetter[letter];
                const productsWithThePrefix = productsGroupedByLetterWithThePrefix[letter];

                if (!products.length && !productsWithThePrefix?.length) {
                  return null;
                }

                return (
                  <Box
                    key={letter}
                    component="div"
                    id={letter}
                    pb={1}
                    mb={1}
                    className={clicked === letter ? 'fade-in' : ''}
                    onAnimationEnd={() => setClicked(null)}
                    px={2}
                  >
                    <Typography variant="h6" position="sticky" top={0}>{letter}</Typography>
                    <Box display="flex" flexDirection="column" gap={{ xs: 2, md: 1 }}>
                      <Box display="flex" gap={1} rowGap={{ xs: 2, sm: 1 }} flexWrap="wrap">
                        {mapProducts(products)}
                      </Box>
                      <Box display="flex" gap={1} rowGap={{ xs: 2, sm: 1 }} flexWrap="wrap" alignItems="center">
                        {productsWithThePrefix && (
                          <>
                            <Typography variant="body2">Prefixed with "The":</Typography>
                            {mapProducts(productsWithThePrefix)}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              {!!productsAvailablility.unavailable.length && (
                <Box px={2}>
                  <Typography variant="h6" position="sticky" top={0}>Not Available At Events</Typography>
                  <Box display="flex" gap={1} rowGap={{ xs: 2, md: 1 }} flexWrap="wrap">
                    {mapProducts(productsAvailablility.unavailable)}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};