import { Box, Tab, Tabs } from '@mui/material';
import { useJumpNavConfig } from './useJumpNavConfig.hook';
import { useScrollableView } from '../MainLayout';

export type JumpNavProps = {
  initialValue: string;
  tabs: {
    label: string;
    sectionId: string;
    badgeContent?: number;
    hidden?: boolean;
  }[];
  offsetTop?: number;
};

export const JumpNav: React.FC<JumpNavProps> = ({ initialValue, tabs, offsetTop = 0 }) => {
  const scrollableView = useScrollableView();
  const { navValue } = useJumpNavConfig({ initialValue, sectionIds: tabs.map(tab => tab.sectionId) });

  const handleTabClick = (sectionId: string) => {
    const element = document.getElementById(sectionId);

    if (element) {
      scrollableView.scrollTo({
        top: element.offsetTop - offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={navValue}
      allowScrollButtonsMobile
    >
      {tabs.map(({ label, sectionId, hidden, badgeContent }) => {

        if (hidden) {
          return null;
        }

        return (
          <Tab
            key={sectionId}
            label={label}
            value={sectionId}
            onClick={() => handleTabClick(sectionId)}
            sx={{ whiteSpace: 'nowrap', textTransform: 'unset', minHeight: 50 }}
            icon={
              !!badgeContent ? (
                <Box
                  bgcolor="primary.main"
                  color="common.white"
                  borderRadius="50%"
                  height={19}
                  width={19}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {badgeContent}
                </Box>
              ) : undefined
            }
            iconPosition="end"
          />
        );
      }
      )}
    </Tabs>
  );
};