import { CheckboxGroup, FormDatePicker, SectionCard, SectionCardRow, TextInput } from 'components';
import React, { useMemo } from 'react';
import { ProfilePageSection, ProfilePageSectionProps, UserPageSectionRowId } from '../types';
import { UserSectionCardRowEditable } from '../components/UserSectionCardRowEditable.component';
import { updateUser } from 'api/actions';
import { array, mixed, number, object, string } from 'yup';
import { getSelectOptionsFromEnumHelper, processFormValueUpdate, userEnumHelpers } from 'helpers';
import { useCurrentUser } from 'contexts';
import { DateService, currencyFormatter } from 'services';
import { UserEmployeeRoleEnum } from 'api/resources';
import { Box, Stack, Typography } from '@mui/material';

export const OverviewSection: React.FC<ProfilePageSectionProps> = ({ user }) => {
  const { getIsMe, isAdmin } = useCurrentUser();
  const canEdit = getIsMe(user) || isAdmin;

  const content = useMemo(() => {
    const employeeRoleValues = getSelectOptionsFromEnumHelper(userEnumHelpers.employeeRole).map(value => ({ ...value, caption: userEnumHelpers.employeeRole.getDescription(value.value as UserEmployeeRoleEnum) }));
    const employeeRolesDisplay = (
      <Stack gap={1}>
        {user.employeeRoles.map(role => (
          <Box key={role}>
            <Typography>{userEnumHelpers.employeeRole.getLabel(role)}</Typography>
            <Typography variant="body2" color="text.secondary">{userEnumHelpers.employeeRole.getDescription(role)}</Typography>
          </Box>
        ))}
      </Stack>
    );

    if(canEdit) {
      return (
        <>
          <UserSectionCardRowEditable
            title="Name"
            rowId={UserPageSectionRowId.NAME}
            formikProps={{
              initialValues: { name: user.name },
              onSubmit: (values) => updateUser(user._id, { name: values.name }),
              validationSchema: object({ name: string().required('Name Required') })
            }}
            form={<TextInput name="name" label="Name" trim autoFocus />}
            user={user}
          >
            {user.name}
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Email"
            rowId={UserPageSectionRowId.EMAIL}
            formikProps={{
              initialValues: { email: user.email },
              onSubmit: (values) => updateUser(user._id, { email: values.email }),
              validationSchema: object({ email: string().email('Invalid email').required('Email required') })
            }}
            form={<TextInput name="email" label="Email" trim autoFocus />}
            user={user}
          >
            {user.email}
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Phone Number"
            rowId={UserPageSectionRowId.PHONE_NUMBER}
            formikProps={{
              initialValues: { phoneNumber: user.phoneNumber ?? '' },
              onSubmit: (values) => updateUser(user._id, { phoneNumber: processFormValueUpdate.string(values.phoneNumber) }),
              validationSchema: object({
                phoneNumber: string().default('').test({
                  test: (value: string) => {
                    if (!value) return true;

                    // US phone number https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
                    return new RegExp(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/).test(value);
                  },
                  message: 'Invalid phone number',
                }),
              })
            }}
            form={<TextInput name="phoneNumber" label="Phone Number" trim autoFocus />}
            user={user}
          >
            {user.phoneNumber}
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Birthday"
            rowId={UserPageSectionRowId.BIRTHDAY}
            formikProps={{
              initialValues: { birthday: user.birthday ?? null },
              onSubmit: (values) => updateUser(user._id, { birthday: values.birthday }),
              validationSchema: object({ birthday: string().nullable().default(null) })
            }}
            form={<FormDatePicker name="birthday" />}
            user={user}
          >
            {user.birthday ? DateService.getFormattedDate(user.birthday, 'MM-D-YY') : undefined}
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Pay Rate"
            rowId={UserPageSectionRowId.PAY_RATE}
            formikProps={{
              initialValues: { payRate: user.payRate ?? 0 },
              onSubmit: (values) => updateUser(user._id, { payRate: processFormValueUpdate.number(values.payRate) }),
              validationSchema: object({ payRate: number().default(0) })
            }}
            form={<TextInput name="payRate" label="Pay Rate" type="number" />}
            user={user}
          >
            {!!user.payRate && currencyFormatter.format(user.payRate)}
          </UserSectionCardRowEditable>
          <SectionCardRow title="Role">{user.role}</SectionCardRow>
          {isAdmin ? (
            <UserSectionCardRowEditable
              title="Employee Roles"
              rowId={UserPageSectionRowId.EMPLOYEE_ROLES}
              formikProps={{
                initialValues: { employeeRoles: user.employeeRoles },
                onSubmit: (values) => updateUser(user._id, { employeeRoles: values.employeeRoles }),
                validationSchema: array(mixed().oneOf<UserEmployeeRoleEnum>(userEnumHelpers.employeeRole.enumValues, 'Invalid employee role')).required('Employee roles required')
              }}
              form={<CheckboxGroup label="Employee Roles" name="employeeRoles" values={employeeRoleValues} />}
              user={user}
            >
              {employeeRolesDisplay}
            </UserSectionCardRowEditable>
          ) : (
            <SectionCardRow title="Employee Roles">{user.employeeRoles.map(role => userEnumHelpers.employeeRole.getLabel(role)).join(', ')}</SectionCardRow>
          )}
          <SectionCardRow title="Account Created">{DateService.getFormattedDate(user.createdAt, 'standardShort')}</SectionCardRow>
          <SectionCardRow title="Account Info Last Updated">{DateService.getFormattedDate(user.updatedAt, 'standardShort')}</SectionCardRow>
        </>
      );
    }

    return (
      <>
        <SectionCardRow title="Name">{user.name}</SectionCardRow>
        <SectionCardRow title="Email">{user.email}</SectionCardRow>
        <SectionCardRow title="Phone Number">{user.phoneNumber}</SectionCardRow>
        <SectionCardRow title="Birthday">{user.birthday ? DateService.getFormattedDate(user.birthday, 'MM-D-YY') : undefined}</SectionCardRow>
        <SectionCardRow title="Role">{user.role}</SectionCardRow>
        <SectionCardRow title="Employee Roles">{employeeRolesDisplay}</SectionCardRow>
        <SectionCardRow title="Account Created">{DateService.getFormattedDate(user.createdAt, 'standardShort')}</SectionCardRow>
        <SectionCardRow title="Account Info Last Updated">{DateService.getFormattedDate(user.updatedAt, 'standardShort')}</SectionCardRow>
      </>
    );
  }, [ canEdit, isAdmin, user ]);

  return (
    <SectionCard title="Overview" id={ProfilePageSection.OVERVIEW}>
      {content}
    </SectionCard>
  );
};
