import { Box, Typography } from '@mui/material';
import { EventForResourceTypeEnum } from 'api/resources';
import { SectionCard, VehicleEventList } from 'components';
import { useUpcomingEventsForTeam } from 'queries';
import React from 'react';
import { TeamPageSection } from '../types';
import { useTeamOutletContext } from '../Team.base';

export const EventsSection = () => {
  const { team } = useTeamOutletContext();
  const { data: upcomingEventsForTeam = [], isInitialLoading: upcomingEventsForTeamLoading } = useUpcomingEventsForTeam({ teamId: team._id });

  return (
    <SectionCard title="Events" id={TeamPageSection.EVENTS}>
      <Box my={3} mx={2}>
        {!!upcomingEventsForTeam.length && (
          <>
            <VehicleEventList
              title="Upcoming:"
              events={upcomingEventsForTeam.filter(e => e.type === EventForResourceTypeEnum.upcoming)}
              vehicle={team.vehicle}
            />
            <VehicleEventList
              title="Current:"
              events={upcomingEventsForTeam.filter(e => e.type === EventForResourceTypeEnum.current)}
              vehicle={team.vehicle}
            />
          </>
        )}
        {!upcomingEventsForTeam.length && !team.isWarehouse && (
          <Typography component="i" color="text.secondary">{upcomingEventsForTeamLoading ? 'Loading...' : 'No current or upcoming events'}</Typography>
        )}
      </Box>
    </SectionCard>
  );
};
