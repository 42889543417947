import { CheckCircleOutline, HelpOutline, UnpublishedOutlined } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';
import { KeyToLabelHash, YesNoEnum, YesNoUnknownEnum, YesNoUnknownValueType, YesNoValueType } from 'types';

export const yesNoUnknownToValueHash: { [key in YesNoUnknownEnum]: YesNoUnknownValueType } = {
  yes: true,
  no: false,
  unknown: null,
};

export const yesNoToValueHash: { [key in YesNoEnum]: YesNoValueType } = {
  yes: true,
  no: false,
};

export const getYesNoUnknownLabelFromValue = (value: YesNoUnknownValueType | undefined) => {
  if (value === null || value === undefined) {
    return 'Unknown';
  }

  return value ? 'Yes' : 'No';
};

export const getYesNoUnknownFromValue = (value: YesNoUnknownValueType | undefined) => {
  if (value === null || value === undefined) {
    return YesNoUnknownEnum.unknown;
  }

  return value ? YesNoUnknownEnum.yes : YesNoUnknownEnum.no;
};

export const getYesNoUnknownIconFromValue = (value: YesNoUnknownValueType | undefined) => {
  if (value === null || value === undefined) {
    return HelpOutline;
  }

  return value ? CheckCircleOutline : UnpublishedOutlined;
};
export const getYesNoUnknownIconSxFromValue = (value: YesNoUnknownValueType | undefined): SxProps<Theme> => {
  if (value === null || value === undefined) {
    return (theme) => ({ color: theme.palette.text.secondary });
  }

  return value ? (theme) => ({ color: theme.palette.success.main }) : (theme) => ({ color: theme.palette.error.main });
};

export const getYesNoFromValue = (value: YesNoValueType | undefined) => {
  return value ? YesNoEnum.yes : YesNoEnum.no;
};

export const yesNoUnknownValueToLabelHash: KeyToLabelHash<YesNoUnknownEnum> = {
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown'
};