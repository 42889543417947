import { Button, ButtonProps, Popover } from '@mui/material';
import React, { useState } from 'react';

export type PopoverButtonProps = {
  children: (props: {handleClose: () => void}) => React.ReactNode;
  label: string;
  id: string;
} & Omit<ButtonProps, 'onClick' | 'children' | 'id' | 'label'>;

export const PopoverButton: React.FC<PopoverButtonProps> = ({ children, label, id, ...props }) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? id : undefined;


  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="inherit"
        {...props}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
      >
        {typeof children === 'function' ? children({ handleClose }) : children}
      </Popover>
    </>
  );
};