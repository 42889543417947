import { Typography } from '@mui/material';
import { EventsManagementTreeViewNodeIdEnum, eventsManagementTreeViewNodeIdEnumHelpers } from '../helpers';

export type EventsManagementPageTitleProps = {
  nodeId: EventsManagementTreeViewNodeIdEnum;
};

export const EventsManagementPageTitle: React.FC<EventsManagementPageTitleProps> = ({ nodeId }) => {
  const Icon = eventsManagementTreeViewNodeIdEnumHelpers.getIcon(nodeId);

  return (
    <Typography
      variant="h6"
      fontWeight={400}
      borderBottom={theme => `1px solid ${theme.palette.grey[300]}`}
      width="fit-content" mb={1}
      display="flex"
      alignItems="center"
      gap={1}
    >
      <Icon />
      {eventsManagementTreeViewNodeIdEnumHelpers.getFullLabel(nodeId)}
    </Typography>
  );
};