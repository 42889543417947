export type LoadingStateType = {
  loading: 'idle' | 'pending' | 'posting' | 'refetching';
};

export const initialLoadingState: LoadingStateType = {
  loading: 'pending',
};

export type ErrorStateType = {
  error: string | null;
};

export const initialErrorState: ErrorStateType = {
  error: null,
};

export type InitialLoadingAndErrorState = LoadingStateType & ErrorStateType;

export const initialLoadingAndErrorState: InitialLoadingAndErrorState = {
  ...initialLoadingState,
  ...initialErrorState,
};
