import { authAxiosRequest } from 'api/axios';
import { Session, User, UserAvailabilityEnum, UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { CreateResourceResponse, DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { DeepPartial } from 'redux';

export type GetUsersResponse = {
  data: User[];
};

export const getUsers = () => {
  return authAxiosRequest<GetUsersResponse>('/api/users');
};

export type GetUsersAsResourceResponse = {
  data: PickFromResource<User, 'name' | 'email' | 'employeeRoles' | 'profileImageUrl' | 'role'>[];
};

export const getUsersAsResourceOptions = () => {
  return authAxiosRequest<GetUsersAsResourceResponse>('/api/users', { params: { asResource: 'options' } });
};

export type GetUsersAsResourceOverview = {
  data: ResourceWithPopulated<User, {
    lastSession?: PickFromResource<Session, 'createdAt'>;
    eventDatesCount: number;
    upcomingEventDatesCount: number;
    eventsCount: number;
    eventDatesSalesCount: number;
    eventDatesSalesPrice: number;
    userLoggedSalesCount: number;
    userLoggedSalesPrice: number;
  }>[];
};

export const getUsersAsResourceOverview = () => {
  return authAxiosRequest<GetUsersAsResourceOverview>('/api/users', { params: { asResource: 'overview' } });
};

export type GetUserResponse = {
  data: User;
};

export const getUser = (id: string) => {
  return authAxiosRequest<GetUserResponse>(`/api/users/${id}`);
};

export type CreateUserInput = DeepOptionalToNull<{
  name: string;
  email: string;
  phoneNumber?: string;
  birthday?: string;
  payRate?: number;
  role: UserRoleEnum;
  employeeRoles: UserEmployeeRoleEnum[];
  password: string;
  pin: string;
  driverLicence?: boolean;
  personalVehicle?: boolean;
  coveredByBussinessInsuranceAsDriver?: boolean;
  canDriveBoxTruck?: boolean;
  canPullTrailer?: boolean;
  drivingNotes?: string;
}>;

export const createUser = (data: CreateUserInput) => {
  return authAxiosRequest<CreateResourceResponse>('/api/users', { method: 'POST', data });
};

export type UpdateUserInput = DeepPartial<Omit<CreateUserInput, 'role' | 'password' | 'pin'> & { profileImageUrl: string | null; active: boolean }>;

export const updateUser = (id: string, data: UpdateUserInput) => {
  return authAxiosRequest(`/api/users/${id}`, { method: 'PUT', data });
};

export type UpdateUserPasswordInput = {
  currentPassword: string;
  newPassword: string;
};

export const updateUserPassword = (data: UpdateUserPasswordInput) => {
  return authAxiosRequest('/api/users/me/pass', { method: 'PUT', data });
};

export type UpdateUserPinInput = {
  password: string;
  newPin: string;
};

export const updateUserPin = (data: UpdateUserPinInput) => {
  return authAxiosRequest('/api/users/me/pin', { method: 'PUT', data });
};

export type GetUserAvailabilityResponse = {
  data: {
    startDateAsUtc: string;
    endDateAsUtc: string;
    availability: UserAvailabilityEnum;
  }[];
};

export const getUserAvailability = (id: string) => {
  return authAxiosRequest<GetUserAvailabilityResponse>(`/api/users/${id}/availability`, { method: 'GET' });
};

export type UpdateUserAvailabilityInput = { start: string; end: string; availability: UserAvailabilityEnum | null };

export const updateUserAvailability = (id: string, data: UpdateUserAvailabilityInput) => {
  return authAxiosRequest(`/api/users/${id}/availability`, { method: 'PUT', data });
};

export type GetUsersAvailabilityHashForEventDateResponse = {
  data: {
    [userId: string]: UserAvailabilityEnum | null;
  };
};
export type GetUsersAvailabilityHashForEventResponse = {
  data: {
    [eventDateId: string]: {
      [userId: string]: UserAvailabilityEnum | null;
    };
  };
};

export const getUsersAvailabilityHashForEventDate = (eventDateId: string) => {
  return authAxiosRequest<GetUsersAvailabilityHashForEventDateResponse>(`/api/users/availability/eventDate/${eventDateId}`);
};

export const getUsersAvailabilityHashForEvent = (eventId: string) => {
  return authAxiosRequest<GetUsersAvailabilityHashForEventResponse>(`/api/users/availability/event/${eventId}`);
};