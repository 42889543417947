import { Alert, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { EventDateImagesList, EventDateImageUploadModal, EventDateImageWarnings, EventDateImagesCarousel, useEventContext, EventDatePageSection, EventDateSectionProps } from 'components';

export const PhotosSection: React.FC<EventDateSectionProps> = ({ eventDate }) => {
  const { event } = useEventContext();
  const [ uploadModalOpen, setUploadModalOpen ] = useState(false);
  const [ displayCarouselAtIndex, setDisplayCarouselAtIndex ] = useState<number | null>(null);

  return (
    <Box component="div" id={EventDatePageSection.PHOTOS}>
      {uploadModalOpen && <EventDateImageUploadModal eventDate={eventDate} onClose={() => setUploadModalOpen(false)} />}
      {displayCarouselAtIndex !== null && <EventDateImagesCarousel eventDateId={eventDate._id} images={eventDate.images} initialIndex={displayCarouselAtIndex}  onClose={() => setDisplayCarouselAtIndex(null)}/>}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6" fontWeight={400}>Photos</Typography>
        <Button size="small" variant="outlined" onClick={() => setUploadModalOpen(true)}>+ Add Photo</Button>
      </Box>
      <EventDateImagesList images={eventDate.images} onClick={setDisplayCarouselAtIndex} size={300} />
      <Alert severity="warning">
        <EventDateImageWarnings event={event} eventDate={eventDate} />
      </Alert>
    </Box>
  );
};