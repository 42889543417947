import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { ProductActiveBaselineTypeEnum, Team } from 'api/resources';

export const getProductTeamBaseline = (
  product: Pick<GetProductsResponse['data'][number], 'activeBaseline' | 'activeWarehouseBaseline' | 'autoBaseline' | 'autoWarehouseBaseline' | 'manualBaseline' | 'manualWarehouseBaseline'>,
  team: GetTeamsResponse['data'][number] | Team,
  type?: ProductActiveBaselineTypeEnum
) => {
  if (team.isWarehouse) {
    if(type === ProductActiveBaselineTypeEnum.auto) {
      return product.autoWarehouseBaseline;
    }
    if(type === ProductActiveBaselineTypeEnum.manual) {
      return (product.manualWarehouseBaseline ?? 0);
    }

    return product.activeWarehouseBaseline;
  }

  if(type === ProductActiveBaselineTypeEnum.auto) {
    return product.autoBaseline;
  }
  if(type === ProductActiveBaselineTypeEnum.manual) {
    return product.manualBaseline ?? 0;
  }

  return product.activeBaseline;
};

export const getProductProduceAmount = (baseline: number, quantity: number) => Math.max(baseline - quantity, 0); // if negative, will return 0

export const getProductTotalProduceAmount = (selectedTeams: GetTeamsResponse['data'], product: GetProductsResponse['data'][number]) => {
  return selectedTeams.reduce((total, team) => {
    const stock = product.stocks[team._id];
    const baseline = getProductTeamBaseline(product, team);

    return total + getProductProduceAmount(baseline, stock.quantity);
  }, 0);
};