import { Autocomplete, CardContentLink, HexColorPicker, SectionCard, SectionCardRow } from 'components';
import React from 'react';
import { TeamPageSection, TeamPageSectionRowId } from '../types';
import { TeamSectionCardRowEditable } from '../components';
import { updateTeam } from 'api/actions';
import { useTeamOutletContext } from '../Team.base';
import { object, string } from 'yup';
import { Box } from '@mui/material';
import { useTeams, useVehicles } from 'queries';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useUsersAsResourceOptions } from 'queries/user';
import { UserEmployeeRoleEnum } from 'api/resources';

export const OverviewSection = () => {
  const { data: vehicles = [], isInitialLoading: vehiclesLoading } = useVehicles();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const { team } = useTeamOutletContext();

  const teamLeadOptions = users
    .filter(user => user.employeeRoles.includes(UserEmployeeRoleEnum.teamLead))
    .map(user => {
      const userTeam = teams.find(team => team.teamLead?._id === user._id);

      return {
        id: user._id,
        label: userTeam ? `${user.name} (assigned to ${userTeam.name})` : user.name,
        disabled: !!userTeam
      };
    });

  return (
    <SectionCard title="Overview" id={TeamPageSection.OVERVIEW}>
      <SectionCardRow title="Name">{team.name}</SectionCardRow>
      <TeamSectionCardRowEditable
        title="Color"
        rowId={TeamPageSectionRowId.COLOR}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { color: values.color }),
          initialValues: { color: team.color ?? '' },
          validationSchema: object({ color: string().default('') })
        }}
        form={<HexColorPicker name="color" />}
      >
        <Box height={30} width={30} sx={{ backgroundColor: team.color, borderRadius: 1 }} />
      </TeamSectionCardRowEditable>
      <TeamSectionCardRowEditable
        title="Designated Vehicle"
        rowId={TeamPageSectionRowId.VEHICLE}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { vehicle: values.vehicle }),
          initialValues: { vehicle: team.vehicle?._id ?? '' },
          validationSchema: object({ vehicle: string().nullable().default(null) })
        }}
        form={(
          <Autocomplete
            name="vehicle"
            label="Vehicle"
            options={vehicles}
            getOptionLabel={opt => opt.name}
            transformValue={opt => opt?._id}
            loading={vehiclesLoading}
          />
        )}
      >
        {team.vehicle && <CardContentLink title={team.vehicle.name} href={`/${ROUTING_CONFIG.vehicles}/${team.vehicle._id}`} />}
      </TeamSectionCardRowEditable>
      <TeamSectionCardRowEditable
        title="Team Manager"
        rowId={TeamPageSectionRowId.TEAM_LEAD}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { teamLead: values.teamLead }),
          initialValues: { teamLead: team.teamLead?._id ?? '' },
          validationSchema: object({ teamLead: string().nullable().default(null) })
        }}
        form={(
          <Autocomplete
            name="teamLead"
            label="Team Manager"
            options={teamLeadOptions}
            getOptionLabel={opt => opt.label}
            transformValue={opt => opt?.id}
            getOptionDisabled={opt => opt.disabled}
            loading={teamsLoading || usersLoading}
          />
        )}
      >
        {team.teamLead && <CardContentLink title={team.teamLead.name} href={`/${ROUTING_CONFIG.users}/${team.teamLead._id}`} />}
      </TeamSectionCardRowEditable>
    </SectionCard>
  );
};
