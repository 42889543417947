import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { TextInput, TextInputProps } from './TextInput.component';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

export type TextInputWithAutoProps = TextInputProps & {
  autoValue: number;
};

export const TextInputWithAuto: React.FC<TextInputWithAutoProps> = ({ autoValue, ...props }) => {
  const name = props.name;
  const { values, setFieldValue } = useFormikContext<{ [key: typeof name]: any }>();
  const [ isAuto, setIsAuto ] = useState(autoValue === values[name]);

  useEffect(() => {
    if (isAuto) {
      setFieldValue(name, autoValue);
    }
  }, [ autoValue, isAuto, name, setFieldValue ]);

  return (
    <Box>
      <TextInput {...props} disabled={props.disabled ?? isAuto} />
      <Box ml={1}>
        <FormControlLabel
          control={<Switch checked={isAuto} onChange={(_e, checked: boolean) => setIsAuto(checked)} size="small" />}
          label={<Typography variant="body2" color={theme => theme.palette.grey[700]}>Use auto time</Typography>}
        />
      </Box>
    </Box>
  );
};