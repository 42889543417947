import { Typography, Box } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { EventDateTypeEnum } from 'api/resources';
import { EventsShowTheseFieldsGroup, ShowTheseFieldsConfig, StaffList, YesNoUnknownIconSwitch, getMatrixDisplayText } from 'components';
import { StatusChipDisplay } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/custom';
import { eventEnumHelpers, getEventDatesStaffList, getEventDatesStatus, getEventStaffStatus } from 'helpers';
import { getDurationLabel, getWorkdayTotal } from 'helpers/event-date-times';

type Field =
  | 'team'
  | 'vehicle'
  | 'spaceSize'
  | 'participationStatus'
  | 'applicationStatus'
  | 'acceptanceStatus'
  | 'applicationPlatform'
  | 'staffStatus'
  | 'datesStatus'
  | 'staff'
  | 'lodgingRequired'
  | 'distanceFromStudio'
  | 'averageRegularWorkDay';

export const showTheseFieldsConfig: ShowTheseFieldsConfig<Field, EventsShowTheseFieldsGroup, GetEventsResponse['data'][number]['dates'][number], { event: GetEventsResponse['data'][number] }>[] = [
  {
    field: 'team',
    group: EventsShowTheseFieldsGroup.team,
    label: 'Team',
    getContent: (_, __, { event }) => event.team?.name ?? <Typography variant="body2" fontSize="small" component="i" color="warning.main">Team Unassigned</Typography>,
  },
  {
    field: 'staffStatus',
    group: EventsShowTheseFieldsGroup.team,
    label: 'Staff Status',
    getContent: (_, __, { event }) => <StatusChipDisplay title="Staff st." helpers={eventEnumHelpers.staffStatus} value={getEventStaffStatus(event.dates)} />,
    disableTypography: true,
  },
  {
    field: 'staff',
    group: EventsShowTheseFieldsGroup.team,
    label: 'Staff List',
    getContent: (eventDate, group) => {
      const staffList = group ? getEventDatesStaffList(group) : eventDate.staff;

      return <StaffList staffList={staffList} />;
    },
    disableTypography: true,
  },
  {
    field: 'vehicle',
    group: EventsShowTheseFieldsGroup.logistics,
    label: 'Vehicle',
    getContent: (_, __, { event }) => event.vehicle?.name ?? <Typography variant="body2" fontSize="small" component="i" color="warning.main">Vehicle Unassigned</Typography>,
  },
  {
    field: 'spaceSize',
    group: EventsShowTheseFieldsGroup.event,
    label: 'Space Size',
    getContent: (_, __, { event }) => event.spaceSize,
  },
  {
    field: 'participationStatus',
    group: EventsShowTheseFieldsGroup.event,
    label: 'Participation Status',
    getContent: (_, __, { event }) => <StatusChipDisplay title="Part. st." helpers={eventEnumHelpers.participationStatus} value={event.preperation.participationStatus} />,
    disableTypography: true,
  },
  {
    field: 'applicationStatus',
    group: EventsShowTheseFieldsGroup.event,
    label: 'App. Status',
    getContent: (_, __, { event }) => <StatusChipDisplay title="App. st." helpers={eventEnumHelpers.applicationStatus} value={event.preperation.applicationStatus} />,
    disableTypography: true,
  },
  {
    field: 'acceptanceStatus',
    group: EventsShowTheseFieldsGroup.event,
    label: 'Acceptance Status',
    getContent: (_, __, { event }) => <StatusChipDisplay title="Acc. st." helpers={eventEnumHelpers.acceptanceStatus} value={event.preperation.acceptanceStatus} />,
    disableTypography: true,
  },
  {
    field: 'applicationPlatform',
    group: EventsShowTheseFieldsGroup.event,
    label: 'Application Platform',
    getContent: (_, __, { event }) => <StatusChipDisplay title="Acc. st." helpers={eventEnumHelpers.applicationPlatform} value={event.preperation.applicationPlatform} />,
    disableTypography: true,
  },
  {
    field: 'datesStatus',
    group: EventsShowTheseFieldsGroup.logistics,
    label: 'Dates Status',
    getContent: (_, __, { event }) => <StatusChipDisplay title="Dates st." helpers={eventEnumHelpers.datesStatus} value={getEventDatesStatus(event.dates)} />,
    disableTypography: true,
  },
  {
    field: 'lodgingRequired',
    group: EventsShowTheseFieldsGroup.logistics,
    label: 'Lodging required',
    getContent: (_, __, { event }) => <Box display="flex" alignItems="center" gap={1}><YesNoUnknownIconSwitch value={event.lodging?.isRequired ?? null} fontSize="small" />Lodging</Box>,
  },
  {
    field: 'distanceFromStudio',
    group: EventsShowTheseFieldsGroup.logistics,
    label: 'From studio',
    getContent: (_, __, { event }) => getMatrixDisplayText(event.place.fromStudioMatrix),
  },
  {
    field: 'averageRegularWorkDay',
    group: EventsShowTheseFieldsGroup.logistics,
    label: 'Average workday',
    getContent: (_, __, { event }) => {
      const regularEventDates = event.dates.filter(date => date.type === EventDateTypeEnum.regular);
      const totals = regularEventDates.reduce((r, date) => {
        const { workdayTotalInMinutes } = getWorkdayTotal({ event, eventDate: date });

        return {
          totalInMinutes: r.totalInMinutes + (workdayTotalInMinutes ?? 0),
          datesWithUnknownWorkdayTotalCount: workdayTotalInMinutes === null ? r.datesWithUnknownWorkdayTotalCount + 1 : r.datesWithUnknownWorkdayTotalCount,
        };
      }, { totalInMinutes: 0, datesWithUnknownWorkdayTotalCount: 0 });
      const averageRegularWorkdayInMinutes = Math.ceil(totals.totalInMinutes / (regularEventDates.length - totals.datesWithUnknownWorkdayTotalCount));

      return `Avg. workday: ${getDurationLabel(averageRegularWorkdayInMinutes)}`;
    },
  },
];