import { Button } from '@mui/material';
import { PageContentContainer, PageContentHeader } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { SalesTable } from './components/SalesTable.main';
import { useEventsSales, useSales, useStorefrontsSales } from 'queries/sale';

export const SalesLog = () => {
  const navigate = useNavigate();
  const { data: eventsSales = [], isInitialLoading: eventsSalesLoading } = useEventsSales();
  const { data: storefrontsSales = [], isInitialLoading: storefrontsSalesLoading } = useStorefrontsSales();
  const { data: sales = [], isInitialLoading: salesLoading } = useSales();

  const clickHandler = () => navigate(`/${ROUTING_CONFIG.logLiveSale}`);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Sales Log"
          rightActions={<Button variant="contained" onClick={clickHandler} size="small">Point of Sale</Button>}
        />
      )}
    >
      <SalesTable
        sales={sales}
        eventsSales={eventsSales}
        storefrontsSales={storefrontsSales}
        loading={salesLoading || eventsSalesLoading || storefrontsSalesLoading}
      />
    </PageContentContainer>
  );
};
