import {
  Box,
} from '@mui/material';
import React from 'react';
import { ThemePalette, themePalette } from 'types';

export type StatusIndicatorProps = {
  status?: ThemePalette | string;
};

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  if (!status) {
    return null;
  }

  return (
    <Box
      sx={{
        width: theme => theme.spacing(0.65),
        height: theme => theme.spacing(0.65),
        borderRadius: '50%',
        background: theme => themePalette.includes(status) ? theme.palette[status as ThemePalette].main : status,
      }}
    />
  );
};

export type WrapWithStatusIndicatorProps = {
  children: React.ReactNode;
} & StatusIndicatorProps;

export const WrapWithStatusIndicator: React.FC<WrapWithStatusIndicatorProps> = ({ children, ...props }) => {
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <StatusIndicator {...props} />
      {children}
    </Box>
  );
};
