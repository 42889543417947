import {
  Alert,
  AppBar, Box, Button, Card, CardContent, Container, Typography,
} from '@mui/material';
import { LoginUserInput } from 'api/actions';
import { loginUserThunk } from 'app/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logoImg } from 'assets';

import { PasswordTextInput, TextInput } from 'components';
import { Form, Formik } from 'formik';
import { formSx } from 'styles';
import { object, SchemaOf, string } from 'yup';

const initialValues: LoginUserInput = {
  email: '',
  password: '',
};

const validationSchema = (): SchemaOf<LoginUserInput> => {
  return object({
    email: string().email('Must be valid email').required('Email is required'),
    password: string().min(6, 'Too short').required('Password is required'),
  });
};

export const Login = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const handleLogin = async (values: LoginUserInput) => {
    dispatch(loginUserThunk(values));
  };

  return (
    <>
      <AppBar sx={{ height: '64px' }}>
        <Box display="flex" alignItems="center" justifyContent="center" my="auto">
          <Box
            component="img"
            sx={{ height: (theme) => {
              return theme.spacing(5.5);
            } }}
            src={logoImg}
          />
        </Box>
      </AppBar>
      <Box sx={{ height: '64px' }} />
      <Container maxWidth="sm">
        <Box maxWidth="375px" mx="auto" mt={6}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={validationSchema}
          >
            {(formik) => {
              const hasError = formik.submitCount >= 1 && auth.error;

              return (
                <Form>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography fontSize="large" fontWeight={500} variant="h5" mb={1}>Login</Typography>
                      {hasError && <Alert severity="error">{hasError}</Alert>}
                      <Box sx={formSx.formGroup} mt={hasError ? 2 : 3}>
                        <TextInput name="email" label="Email" />
                        <PasswordTextInput name="password" label="Password" />
                        <Button variant="contained" size="large" type="submit" disabled={auth.loading === 'posting'}>Continue</Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Container>
    </>
  );
};
