import { Avatar, Box, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { EventFeedbackRating } from './EventFeedbackRating.component';
import { PopperWrap, TPopperWrapOption } from 'components/PopperWrap';
import { MoreVert } from '@mui/icons-material';
import { GetEventFeedbackResponse } from 'api/actions/eventFeedback';
import { useCurrentUser } from 'contexts';
import { EditEventFeedbackModal } from './EditEventFeedbackModal';
import { DeleteEventFeedbackModal } from './DeleteEventFeedback.modal';
import { DateService, getUserInitialsForAvatar } from 'services';
import { getEventFeedbackIcon } from '../helpers';
import { CardContentContainer, CardContentGrid, CardContentMarkdown } from 'components';
import { ThemePalette } from 'types';

type YesNoQuestionsDisplayItemProps = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color: ThemePalette;
  text: string;
};

const YesNoQuestionDisplayItem: React.FC<YesNoQuestionsDisplayItemProps> = props => {
  return <Box display="flex" alignItems="center" gap={1}><props.Icon color={props.color} />&nbsp;{props.text}</Box>;
};

export type EventFeedbackCardProps = {
  eventFeedback: GetEventFeedbackResponse['data'][number];
};

export const EventFeedbackCard: React.FC<EventFeedbackCardProps> = ({ eventFeedback }) => {
  const { getIsMe } = useCurrentUser();
  const [ editFeedbackOpen, setEditFeedbackOpen ] = useState(false);
  const [ deleteFeedbackOpen, setDeleteFeedbackOpen ] = useState(false);
  const isMe = getIsMe(eventFeedback.user._id);

  const renderPopperAnchor = (onToggle: () => void) => {
    return (
      <IconButton onClick={onToggle}>
        <MoreVert />
      </IconButton>
    );
  };

  const options: TPopperWrapOption[] = [
    {
      label: 'Edit feedback form',
      menuItemProps: {
        onClick: () => setEditFeedbackOpen(true),
      },
    },
    {
      label: 'Delete feedback',
      menuItemProps: {
        onClick: () => setDeleteFeedbackOpen(true),
      },
      labelTypographyProps: {
        sx: {
          color: (theme) => theme.palette.error.dark,
        },
      },
    },
  ];

  const pullUpColor = eventFeedback.unloadPullUp && eventFeedback.packUpPullUp ? 'success' : 'error';
  const weatherAffectedSalesColor = !eventFeedback.weatherAffectedSales? 'success' : 'error';
  const moreHelpNeededColor = !eventFeedback.needMoreStaff ? 'success' : 'error';
  const doAgainColor = eventFeedback.doAgain ? 'success' : 'error';
  const twoSpacesColor = eventFeedback.twoSpaces ? 'success' : 'error';

  const pullUpText = useMemo(() => {
    const { unloadPullUp, packUpPullUp } = eventFeedback;
    const isSame = unloadPullUp === packUpPullUp;

    return `We could ${!unloadPullUp ? 'not' : ''} pull up to unload${isSame ? ' and' : ', but' } could ${!packUpPullUp ? 'not' : ''} pull up to pack up`;
  }, [ eventFeedback ]);

  const weatherAffectedSalesText = `Sales were ${!eventFeedback.weatherAffectedSales ? 'not' :''} affected by the weather`;
  const moreHelpNeededText = `I ${!eventFeedback.needMoreStaff ? "don't" :''} think we needed more help`;

  const doAgainText =`I think the event is ${!eventFeedback.doAgain ? 'not' : ''} worth doing again`;
  const twoSpacesText = `I ${!eventFeedback.twoSpaces ? "don't" : ''} think the event was big enough that we could have 2 spaces`;


  return (
    <>
      {editFeedbackOpen && <EditEventFeedbackModal eventFeedback={eventFeedback} onClose={() => setEditFeedbackOpen(false)}/>}
      {deleteFeedbackOpen && <DeleteEventFeedbackModal eventFeedback={eventFeedback} onClose={() => setDeleteFeedbackOpen(false)}/>}
      <CardContentContainer disableAutoHeight>
        <CardHeader
          avatar={<Avatar>{getUserInitialsForAvatar(eventFeedback.user.name)}</Avatar>}
          title={eventFeedback.user.name}
          subheader={`Added feedback on ${DateService.getFormattedDate(eventFeedback.createdAt)}`}
          action={isMe ?
            <PopperWrap
              anchor={renderPopperAnchor}
              options={options}
            /> : null
          }
        />
        <CardContent>
          <Grid container mb={2} spacing={1}>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.overall} title="Overall Event" Icon={getEventFeedbackIcon('overall')} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.busyness} title="Busyness" Icon={getEventFeedbackIcon('busyness')} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.sales} title="Sales" Icon={getEventFeedbackIcon('sales')} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.food} title="Food" Icon={getEventFeedbackIcon('food')} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.artists} title="Artists" Icon={getEventFeedbackIcon('artists')} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <EventFeedbackRating rating={eventFeedback.parking} title="Parking" Icon={getEventFeedbackIcon('parking')} />
            </Grid>
          </Grid>
          <Stack gap={1} mb={1}>
            <YesNoQuestionDisplayItem color={pullUpColor} text={pullUpText} Icon={getEventFeedbackIcon('unloadPullUp')} />
            <YesNoQuestionDisplayItem color={weatherAffectedSalesColor} text={weatherAffectedSalesText} Icon={getEventFeedbackIcon('weatherAffectedSales')} />
            <YesNoQuestionDisplayItem color={moreHelpNeededColor} text={moreHelpNeededText} Icon={getEventFeedbackIcon('needMoreStaff')} />
            <YesNoQuestionDisplayItem color={doAgainColor} text={doAgainText} Icon={getEventFeedbackIcon('doAgain')} />
            <YesNoQuestionDisplayItem color={twoSpacesColor} text={twoSpacesText} Icon={getEventFeedbackIcon('twoSpaces')} />
          </Stack>
          <CardContentGrid
            items={[
              {
                title: 'Next year recommendations',
                value: <CardContentMarkdown markdown={eventFeedback.nextYearRecommendations} />,
                isUnassigned: !eventFeedback.nextYearRecommendations,
                dividerTop: true,
                type: 'full'
              },
              {
                title: 'Note',
                value: <CardContentMarkdown markdown={eventFeedback.note} />,
                type: 'full',
                isUnassigned: !eventFeedback.note,
              }
            ]}
          />
        </CardContent>
      </CardContentContainer>
    </>
  );
};