import { Box } from '@mui/material';
import { Autocomplete, Select, TextInput, getFormattedPaymentDue, useEventContext } from 'components';
import { getSelectOptionsFromEnumHelper, eventEnumHelpers } from 'helpers';
import React, { useMemo } from 'react';
import { currencyFormatter } from 'services';
import { formSx } from 'styles';

export const EventApplicationPaymentForm = () => {
  const { event } = useEventContext();
  const paymentTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.paymentType), []);

  return (
    <Box sx={formSx.formGroup}>
      <Select name="type" options={paymentTypeOptions} label="Payment Type *"/>
      <TextInput name="receiptNumber" label="Receipt Number" fullWidth trim  />
      <TextInput name="amount" type="number" label="Amount Paid *" fullWidth startAdornment="$" />
      <Autocomplete
        name="paymentDue"
        label="Payment Due"
        options={event.preperation.paymentsDue}
        getOptionLabel={(opt) => `${currencyFormatter.format(opt.amountDue)} (${getFormattedPaymentDue(opt)})`}
        transformValue={(opt) => opt?._id}
      />
    </Box>
  );
};
