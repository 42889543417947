import { EventsManagementTreeViewNodeIdEnum, EventsManagementTreeViewTerminalNodeId } from './events-management-tree-view.helpers';

type EventsManagementInfoConfiguration = {
  description: React.ReactNode;
};
type EventsManagementInfoConfigurations = { [key in EventsManagementTreeViewTerminalNodeId]?: EventsManagementInfoConfiguration };

export const eventsManagementInfoConfigurations: EventsManagementInfoConfigurations = {
  [EventsManagementTreeViewNodeIdEnum.eventsCalendar]: {
    description: (
      <>
        This page is displaying events, past or future, in calendar format. All filter statuses are shown besides events marked as <strong>canceled</strong>, <strong>unable</strong>, and <strong>not interested</strong>. This calendar is color coded by acceptance status.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.eventsProSearch]: {
    description: (
      <>
        This page is displaying all <strong>upcoming </strong>events in list format. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.eventsDuplicate]: {
    description: (
      <>
        This page is for <strong>duplicating</strong> events from the past 365 days for a current year. Only events with <strong>unknown</strong> future interest status are shown. Event is automatically duplicated when future interest status is changed to <strong>yes</strong> or <strong>yes, ask again next year</strong>.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsMissingData]: {
    description: (
      <>
        This page is displaying all <strong>upcoming</strong> events for which either the participation status, the application open date, or the application deadline date is missing. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong> are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsComingSoon]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming </strong>events with applications that may be <strong>opening </strong>in the future. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, and <strong>not applied </strong>to are shown. Events with <strong>no specified open date</strong> or <strong>deadline date </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsOpen]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming </strong>events with applications that are currently <strong>open</strong>. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, and <strong>not applied </strong>to are shown. Events with <strong>no specified open date </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsClosed]: {
    description: (
      <>
        This page is displaying all <strong>upcoming </strong>events with applications that are currently <strong>closed</strong>. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>,<strong> </strong>and <strong>not applied </strong>to are shown. Events with application deadlines that have <strong>passed </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsPending]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming </strong>events for which applications have been completed, but notifications of acceptance/denial are <strong>pending</strong>. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied</strong> <strong>to</strong>, <strong>follow up</strong>, and <strong>unknown </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsWaitListed]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming </strong>events for which applications have been completed, but a spot on the <strong>waitlist </strong>has been assigned. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied</strong> <strong>to</strong>, and <strong>waitlist pending </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.applicationsDenied]: {
    description: (
      <>
        This page is displaying all <strong>upcoming</strong> events for which applications have been completed, but the application has been <strong>denied</strong>. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, and <strong>applied</strong> <strong>to</strong>, <strong>waitlist denied</strong>, and <strong>denied </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsMissingData]: {
    description: (
      <>
        This page is displaying all <strong>upcoming</strong> events that have warnings. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsInsurance]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming</strong> events for which applications have been <strong>accepted </strong>and a certificate of <strong>insurance </strong>is required by the event. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown. Events for which <strong>insurance is  required</strong> and all <strong>unknown insurance</strong> statuses are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue]: {
    description: (
      <>
        This page is for monitoring all<strong> upcoming </strong>events for which applications have been <strong>accepted</strong> and <strong>payments are due</strong>. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown. Events with payment due dates that are <strong>specified </strong>and <strong>not specified </strong>are shown. Events with payment statuses that are marked as <strong>balance due</strong> and <strong>unassigned </strong>are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsEventDates]: {
    description: (
      <>
        This page is for monitoring all<strong> upcoming </strong>events for which date information needs attention. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming</strong> events for which staff need to be scheduled, displayed in list format. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar]: {
    description: (
      <>
        This page is for monitoring user&apos;s schedules and availability in calendar format. It shows events for which they may be <strong>confirmed</strong>, <strong>requested</strong>, or <strong>not confirmed</strong>, as well as days they are <strong>available </strong>and <strong>not available</strong>.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards]: {
    description: (
      <>
        This page is displaying users&apos; information, sorted by <strong>team leads </strong>and <strong>members</strong>. It shows the <strong>next upcoming event </strong>for which members are scheduled for as well as how many dates they are <strong>confirmed </strong>for and <strong>requested </strong>for. Click on a user card to see more information.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming</strong> events for which <strong>teams </strong>may or may not need to be assigned, displayed in list format. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown. Click the teams dropdown menu to select one or any.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar]: {
    description: (
      <>
        This page is for assigning <strong>teams </strong>in calendar format. Click and drag an event to assign it to a team. Events with <strong>no team assigned</strong> are displayed at the bottom of the page.
      </>
    )
  },
  [EventsManagementTreeViewNodeIdEnum.logisticsLodging]: {
    description: (
      <>
        This page is for monitoring all <strong>upcoming </strong>events for which lodging may be required. Events marked as <strong>interested</strong>, <strong>participating</strong>, <strong>unknown</strong>, <strong>applied to</strong>, and <strong>accepted</strong> are shown. Events with lodging requirements marked as <strong>yes </strong>and <strong>unknown </strong>are shown. Events with lodging booking statuses marked as <strong>in progress</strong>, <strong>to book</strong>, and <strong>unknown</strong> are shown.
      </>
    )
  },
};