import { useField } from 'formik';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import React from 'react';
import { DateObjectType, DateService } from 'services';

export type FormDatePickerPropsType = {
  name: string;
} & DatePickerProps<DateObjectType>;

export const FormDatePicker: React.FC<FormDatePickerPropsType> = ({
  name,
  ...props
}) => {
  const [ field, meta, helpers ] = useField(name);

  return (
    <DatePicker
      {...props}
      value={field.value ? DateService.dayjs(field.value) : null}
      onChange={(date) => {
        helpers.setValue(date?.isValid() ? date.toISOString() : null, true);
      }}
      slotProps={{
        textField: {
          error: !!meta.error,
          helperText: meta.error,
        },
      }}
    />
  );
};
