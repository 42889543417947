import { ProductActiveBaselineTypeEnum } from 'api/resources';
import { EnumHelpers } from './types';

const activeBaselineType: EnumHelpers<ProductActiveBaselineTypeEnum> = {
  enumValues: [
    ProductActiveBaselineTypeEnum.auto,
    ProductActiveBaselineTypeEnum.manual,
  ],
  getLabel: (value) => {
    switch (value) {
      case ProductActiveBaselineTypeEnum.manual:
        return 'Manual';
      case ProductActiveBaselineTypeEnum.auto:
      default:
        return 'Automatic';
    }
  },
  getColor: () => 'unknown'
};

export const productEnumHelpers = {
  activeBaselineType,
};