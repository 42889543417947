import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

export type AnalyticsSectionProps = {
  title?: string;
  id: string;
  children: React.ReactNode;
};

export const AnalyticsSection: React.FC<AnalyticsSectionProps> = props => {

  return (
    <Box id={props.id}>
      <Box mb={2}>
        <Typography variant="h5" fontWeight={500} ml={0.5} mb={0.5}>{props.title}</Typography>
        <Divider />
      </Box>
      {props.children}
    </Box>
  );
};