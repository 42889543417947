import { Box, Tab, Tabs } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PageContentContainer, useEventContext } from 'components';
import { useState } from 'react';
import { useSalesForEvent } from 'queries/sale';
import { GetSalesForEventResponse } from 'api/actions';
import { EventPageHeader } from 'pages/Event/components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { EventSalesTable, EventSalesLog } from './components';
import { EventSalesCharts } from './components/EventSalesCharts/EventSalesCharts.page';
import { useCurrentUser } from 'contexts';

type EventSalesTabType = 'table' | 'log' | 'charts';

export type SalesTabComponentProps = {
  sales: GetSalesForEventResponse['data'];
  loading: boolean;
};

export const EventSales: React.FC = () => {
  const { event } = useEventContext();
  const { data: sales = [], isInitialLoading: loading } = useSalesForEvent(event._id);
  const [ tab, setTab ] = useState<EventSalesTabType>('table');
  const { isAdmin } = useCurrentUser();

  const onTabChangeHandler = (tab: EventSalesTabType) => {
    setTab(tab);
  };

  const props: SalesTabComponentProps = {
    sales,
    loading,
  };

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [
          { link: ROUTING_CONFIG.events, text: 'Events' },
          { link: `${ROUTING_CONFIG.events}/${event._id}`, text: event.name },
        ],
        current: 'Sales'
      }}
      header={<EventPageHeader pageTitle="Event Sales Page" />}
      contentSx={{ pt: 0 }}
    >
      <Box mb={2}>
        <Tabs
          value={tab}
          onChange={(_e, value: EventSalesTabType) => onTabChangeHandler(value)}
          sx={{ boxShadow: `inset 0 -1px 0 ${grey[300]}` }}
        >
          <Tab label="Sales" value="table" sx={{ flex: { xs: 1, sm: 'unset' } }} />
          <Tab label="Log Sheet" value="log" sx={{ flex: { xs: 1, sm: 'unset' } }} />
          {isAdmin && <Tab label="Charts" value="charts" sx={{ flex: { xs: 1, sm: 'unset' } }} />}
        </Tabs>
      </Box>
      <>
        {tab === 'table' && <EventSalesTable {...props} />}
        {tab === 'log' && <EventSalesLog {...props} />}
        {tab === 'charts' && <EventSalesCharts />}
      </>
    </PageContentContainer>
  );
};
