import { Box } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { GetEventsForEventOrganizerResponse } from 'api/actions';
import { SectionCard, Table, YesNoUnknownIconSwitch, eventSalesColumns } from 'components';
import { useTableActionColumn } from 'hooks';
import { useEventOrganizerOutletContext } from '../EventOrganizer.base';
import { useEventsForEventOrganizer } from 'queries';
import React from 'react';
import { DateService } from 'services';
import { EventOrganizerPageSection } from '../types';
import { eventEnumHelpers } from 'helpers';
import { EventFutureInterestStatusEnum } from 'api/resources';

const eventOrganizerEventsTableColumns: GridColDef<GetEventsForEventOrganizerResponse['data'][number]>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: 'yearsStr',
    headerName: 'Years',
    width: 150,
  },
  {
    field: 'futureInterestStatus',
    headerName: 'Future Interest',
    width: 150,
    valueFormatter: ({ value }: GridValueFormatterParams<EventFutureInterestStatusEnum>) => eventEnumHelpers.futureInterestStatus.getLabel(value),
  },
  {
    field: 'thisYear',
    headerName: 'Duplicated',
    align: 'center',
    width: 90,
    valueGetter: ({ row }) => row.year >= DateService.dayjs().year(),
    renderCell: ({ value }) => <YesNoUnknownIconSwitch value={!!value} />,
  },
  ...eventSalesColumns
];

export const EventsSection: React.FC = () => {
  const { eventOrganizer } = useEventOrganizerOutletContext();

  const { data: events = [], isLoading: eventsLoading } = useEventsForEventOrganizer(eventOrganizer._id);
  const { withActionColumn } = useTableActionColumn({ routeTo: 'events' });
  const columns = withActionColumn(eventOrganizerEventsTableColumns);

  return (
    <SectionCard title="Linked Events" id={EventOrganizerPageSection.EVENTS}>
      <Box height="400px">
        <Table
          getRowId={(x) => x._id}
          columns={columns}
          rows={events}
          loading={eventsLoading}
          sx={{ border: 'none' }}
          disableRowSelectionOnClick
        />
      </Box>
    </SectionCard>
  );
};
