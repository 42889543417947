import { FrameEnum } from 'types';

export const getFrameEnumFromValue = (value: boolean) => {
  if (value === true) {
    return FrameEnum.frame;
  }
  return FrameEnum.noFrame;
};

export const frameEnumToPriceHash: { [key in FrameEnum]: number } = {
  [FrameEnum.frame]: 55,
  [FrameEnum.noFrame]: 50,
};

export const frameEnumToValueHash: { [key in FrameEnum]: boolean } = {
  [FrameEnum.frame]: true,
  [FrameEnum.noFrame]: false,
};
