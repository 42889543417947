import { LoadingButton } from '@mui/lab';
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Box } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { GetEventsResponse, removeStaffFromEventDate, updateEventDateStaff } from 'api/actions';
import { EventDateStaffStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useEventContext } from 'components/Event/event.context';
import { QUERY_KEY } from 'queries/query-keys';
import { useState, useRef, useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCurrentUser } from 'contexts';

export type EventDateStaffStatusChangeButtonProps = {
  staff: GetEventsResponse['data'][number]['dates'][number]['staff'][number];
  eventDateId: string;
  disabled?: boolean;
};

type EventDateStaffStatusChangeButtonOption = {
  title: string;
  toStatus: EventDateStaffStatusEnum | null;
};

export const EventDateStaffStatusChangeButton: React.FC<EventDateStaffStatusChangeButtonProps> = ({ staff, eventDateId, disabled }) => {
  const currentUser = useCurrentUser();
  const[ open, setOpen ] = useState(false);
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { event } = useEventContext();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateMutation = useMutation({
    mutationFn: (status: EventDateStaffStatusEnum) => updateEventDateStaff(eventDateId, staff._id, { status }),
    onSuccess: async () => {
      snackbar.success('Staff status updated');

      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      await queryClient.invalidateQueries(QUERY_KEY.EVENT_DATE_ADD_STAFF_USER_OPTIONS(eventDateId));
    },
    onError: () => {
      snackbar.error('Staff status could not be updated');
    }
  });
  const deleteMutation = useMutation({
    mutationFn: () => removeStaffFromEventDate(eventDateId, staff._id),
    onSuccess: async () => {
      snackbar.success('Staff deleted');

      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      await queryClient.invalidateQueries(QUERY_KEY.EVENT_DATE_ADD_STAFF_USER_OPTIONS(eventDateId));
    },
    onError: () => {
      snackbar.error('Staff could not be deleted');
    }
  });

  const staffActionOptions = useMemo(() => {
    const isMe = currentUser._id === staff.user._id;
    const canSelfConfirm = currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ]);
    const canEditOthers = event.teamManager?._id === currentUser._id || currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);

    switch (staff.status) {
      case EventDateStaffStatusEnum.confirmed:
        if (isMe || canEditOthers) {
          return [ { title: 'Remove', toStatus: null } ];
        }
        return [];
      case EventDateStaffStatusEnum.initial:
        if (canEditOthers || isMe) {
          const options: EventDateStaffStatusChangeButtonOption[]  = [ { title: 'Remove', toStatus: null } ];

          if (isMe) {
            options.push({ title: 'Self Request', toStatus: EventDateStaffStatusEnum.selfRequested });
          } else {
            options.push({ title: 'Send Request', toStatus: EventDateStaffStatusEnum.requested });
          }

          if (isMe ? canSelfConfirm : canEditOthers) {
            options.push({ title: 'Confirm', toStatus: EventDateStaffStatusEnum.confirmed });
          }

          return options;
        }
        return [];
      case EventDateStaffStatusEnum.requested:
        if (canEditOthers || isMe) {
          const options: EventDateStaffStatusChangeButtonOption[]  = [ { title: 'Confirm', toStatus: EventDateStaffStatusEnum.confirmed } ];

          if (isMe) {
            options.push({ title: 'Deny Request', toStatus: EventDateStaffStatusEnum.deniedRequest });
          }

          if (canEditOthers) {
            options.push({ title: 'Remove', toStatus: null });
          }

          return options;
        }
        return [];
      case EventDateStaffStatusEnum.selfRequested:
        if (canEditOthers) {
          return [
            { title: 'Confirm', toStatus: EventDateStaffStatusEnum.confirmed },
            { title: 'Deny', toStatus: EventDateStaffStatusEnum.selfRequestDenied },
            { title: 'Remove', toStatus: null },
          ];
        }

        if (isMe) {
          return [ { title: 'Remove', toStatus: null } ];
        }

        return [];
      case EventDateStaffStatusEnum.deniedRequest:
        if (canEditOthers) {
          return [ { title: 'Remove', toStatus: null } ];
        }
        return [];
      case EventDateStaffStatusEnum.selfRequestDenied:
        if (canEditOthers) {
          return [ { title: 'Remove', toStatus: null } ];
        }
        return [];
      default:
        return [];
    }

  }, [ currentUser, event.teamManager?._id, staff.status, staff.user._id ]);

  const handleActionClick = () => {
    const toStatus = staffActionOptions[selectedIndex].toStatus;

    if (toStatus) {
      updateMutation.mutate(toStatus);
    } else {
      deleteMutation.mutate();
    }
  };
  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  if (!staffActionOptions.length) {
    return <Box height={32} />;
  }

  return (
    <>
      <ButtonGroup
        variant="outlined"
        size="small"
        fullWidth
        ref={anchorRef}
        sx={{
          display: 'flex',
          color: theme => theme.palette.unknown.main
        }}
        disabled={disabled}
      >
        <LoadingButton
          loadingPosition="start"
          loading={updateMutation.isLoading || deleteMutation.isLoading}
          onClick={handleActionClick}
          sx={{ flex: 1 }}
        >
          {staffActionOptions[selectedIndex].title}
        </LoadingButton>
        <Button size="small" onClick={handleToggle} sx={{ flex: 0 }}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom"
        modifiers={[
          {
            name: 'matchWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: [ 'computeStyles' ],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            }
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {staffActionOptions.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};