import { GetSaleResponse, GetTeamsResponse } from 'api/actions';
import { Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useTeams } from 'queries';
import { useSale } from 'queries/sale';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

type UseSaleOutletContext = {
  warehouseTeam: GetTeamsResponse['data'][number];
  sale: GetSaleResponse['data'];
  loading: boolean;
};

export const useSaleOutletContext = () => useOutletContext<UseSaleOutletContext>();

const SaleBaseRaw: React.FC<{saleId: string}> = ({ saleId }) => {
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: sale, isInitialLoading: saleLoading } = useSale(saleId, { retry: false });

  const warehouseTeam = teams.find(team => team.isWarehouse);

  if (saleLoading || teamsLoading) {
    return <Loading />;
  }

  if (!sale || !warehouseTeam) {
    return <Navigate to={`/${ROUTING_CONFIG.salesLog}`} replace />;
  }

  return <Outlet context={{ sale, loading: saleLoading, warehouseTeam }} />;
};

export const SaleBase = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={`/${ROUTING_CONFIG.salesLog}`} replace />;
  }

  return <SaleBaseRaw saleId={id} />;
};
