import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';

export const ApplicationsComingSoon = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.applicationsComingSoon}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'daysUntilEvent', 'juryFee', 'juryFeeAmount', 'cost', 'applicationOpenDate', 'untilApplicationOpen', 'applicationStatus', 'participationStatus' ],
        initialSortModel: [ { field: 'untilApplicationOpen', sort: 'asc' }, { field: 'daysUntilEvent', sort: 'asc' }, { field: 'year', sort: 'asc' } ]
      }}
      editable={{ participationStatus: true }}
      groupByWeek
    />
  );
};
