import { PickingBaselineEnum } from 'types';
import { EnumHelpers } from './types';

const baseline: EnumHelpers<PickingBaselineEnum> = {
  enumValues: [
    PickingBaselineEnum.below,
    PickingBaselineEnum.met,
  ],
  getLabel: (value) => {
    switch (value) {
      case PickingBaselineEnum.met:
        return 'Baseline met';
      default:
        return 'Below baseline';
    }
  },
  getColor: (value) => {
    switch (value) {
      case PickingBaselineEnum.met:
        return 'success';
      default:
        return 'error';
    }
  },
};

export const pickingEnumHelpers = {
  baseline,
};