import { Grid } from '@mui/material';
import React from 'react';
import { TotalSalesChart } from './TotalSalesChart.component';
import { SalesPaymentMethodChart } from './SalesPaymentMethodChart.component';
import { SalesFrameChart } from './SalesFrameChart.component';
import { TeamsSalesChart } from './TeamsSalesChart.component';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { BestSellersChart } from './BestSellersChart.component';

export const SalesAnalytics = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TotalSalesChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TeamsSalesChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          type="events"
          header="Event Sales"
          getNameAndId={sale => ({ name: sale.event.name, id: sale.event._id })}
          getNavigateTo={(id) => `/${ROUTING_CONFIG.events}/${id}`}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          header="Product Sales"
          getNameAndId={sale => ({ name: sale.product.name, id: sale.product._id })}
          getNavigateTo={(id) => `/${ROUTING_CONFIG.productList}/${id}`}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <BestSellersChart
          header="User Logged Sales"
          getNameAndId={sale => sale.user ? { name: sale.user.name, id: sale.user._id } : null}
          getNavigateTo={(id) => `/${ROUTING_CONFIG.users}/${id}`}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SalesPaymentMethodChart />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SalesFrameChart />
      </Grid>
    </Grid>
  );
};
