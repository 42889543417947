import { Box, Stack, Typography } from '@mui/material';
import { TooltipContentTimelineChartTooltipArg } from './AnalyticsTimelineChart.component';
import { HorizontalRule } from '@mui/icons-material';
import { DateService } from 'services';
import { analyticsTimelineChartTimePeriodEnumHelpers } from './helpers';

export type DefaultAnalyticsTimelineChartTooltipContentProps = TooltipContentTimelineChartTooltipArg;

export const DefaultAnalyticsTimelineChartTooltipContent: React.FC<DefaultAnalyticsTimelineChartTooltipContentProps> = props => {
  return (
    <>
      {props.data.datasets.map((dataset, index) => {
        const timePeriodStart = DateService.dayjs(dataset.dateRange.start).add(props.dataIndex, props.timePeriod).startOf(props.timePeriod);
        const timePeriodEnd = timePeriodStart.endOf(props.timePeriod);
        const timePeriodDateRange =  { start: timePeriodStart, end: timePeriodEnd };
        const color = Array.isArray(dataset.borderColor) ? dataset.borderColor[props.dataIndex] : dataset.borderColor;

        return (
          <Stack key={index}>
            <Box display="flex" alignItems="center">
              {dataset.borderDash
                ? <Typography variant="subtitle2" sx={{ color, fontWeight: 900, px: 0.3 }}>---</Typography>
                : <HorizontalRule style={{ color }} />}
              <Typography variant="body2">{analyticsTimelineChartTimePeriodEnumHelpers.getTimePeriodDateRangeTooltipLabel(props.timePeriod, timePeriodDateRange)}</Typography>
              <Typography variant="body2" fontWeight={700} ml={1}>{dataset.data[props.dataIndex] ?? 0}</Typography>
            </Box>
          </Stack>
        );
      })}
    </>
  );
};