import { Typography } from '@mui/material';
import { Team } from 'api/resources';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';

export type TeamCellProps = {
  team: Pick<Team, 'color' | 'name'> | null;
  label?: string;
};

export const TeamCell: React.FC<TeamCellProps> = ({ team, label }) => {
  if (!team) {
    return <Typography component="i" variant="body2" color="text.secondary">Unassigned</Typography>;
  }

  return (
    <WrapWithStatusIndicator status={team.color}>
      {label ?? team.name}
    </WrapWithStatusIndicator>
  );
};