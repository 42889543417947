/* eslint-disable no-restricted-imports */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
import isYesterday from 'dayjs/plugin/isYesterday';
import { FORMAT_DATE } from 'constants/format-date';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(timezone);

export type DateObjectType = dayjs.Dayjs;
export type ManipulateType = dayjs.ManipulateType;

const dayjsTz = (date?: string | number | DateObjectType | Date | null) => {
  const tz = DateService.dayjs.tz.guess();

  return dayjs.tz(date, tz);
};

const combineDateAsUtcAndTime = (isoDateString: string, timeString: string) => {
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(isoDateString)) {
    throw new Error('Invalid ISO date string format');
  }

  if (!/^\d{2}:\d{2}$/.test(timeString)) {
    throw new Error('Invalid time format');
  }

  const date = dayjsTz(isoDateString);
  const [ hours, minutes ] = timeString.split(':').map(Number);
  const dateWithTimes = date.set('hour', hours).set('minute', minutes);

  return dateWithTimes;
};

const getFormattedTimeAmount = (time: string) => {
  if (time.length !== 5) throw new Error('Time format incorrect - getFormattedTimeAmount()');
  return dayjs().hour(Number(time.slice(0, 2))).minute(Number(time.slice(3))).format('HH:mm');
};

const getTimeAmountValue = (time: string) => {
  if (time.length !== 5) throw new Error('Time format incorrect - getTimeAmountValue()');
  return (Number(time.slice(0, 2)) * 3600 + Number(time.slice(3)) * 60);
};

const getFormattedTime = (time: string) => {
  if (time.length !== 5) throw new Error('Time format incorrect - getFormattedTime()');
  return dayjs(time, 'HH:mm').format('h:mma');
};

const getFormattedTimeSubstracted = (time: string, sub: number) => {
  if (time.length !== 5) throw new Error('Time format incorrect - getFormattedTimeSubstracted()');
  if (!sub) return null;
  const diff = (dayjs().hour(Number(time.slice(0, 2))).minute(Number(time.slice(3))).valueOf() -
  dayjs().hour(Math.trunc(sub / 3600)).minute(Math.round((sub % 3600) / 60)).valueOf()) / 1000;

  return dayjs().hour(Math.trunc(diff / 3600)).minute(Math.round((diff % 3600) / 60)).format('h:mma');
};

const checkIfDateIsInPast = (date: DateObjectType | string): boolean => {
  return dayjs(date).startOf('D').isBefore(dayjs().startOf('D'));
};

const getFormattedTimeFromDate = (date: string | DateObjectType) => {
  const dateObj = typeof date === 'string' ? dayjs(date) : date;

  return dateObj.format('h:mma');
};

const getFormattedDate = (
  date: DateObjectType | string,
  format?: keyof typeof FORMAT_DATE,
) => {
  const dateObj = typeof date === 'string' ? dayjs(date) : date;

  return dateObj.format(format ? FORMAT_DATE[format] : FORMAT_DATE.standard);
};

const getFormattedDateRange = (
  startDate: DateObjectType | string | undefined | null,
  endDate: DateObjectType | string | undefined | null,
  weekday: boolean = true,
) => {
  const startDateObj = typeof startDate === 'string' ? dayjs(startDate) : startDate;
  const endDateObj = typeof endDate === 'string' ? dayjs(endDate) : endDate;

  let formattedStartDate = '';
  let formattedEndDate = '';

  if (startDateObj) {
    if (weekday) {
      formattedStartDate = startDateObj.format('dddd') + ', ';
    }
    formattedStartDate += startDateObj.format('MM/DD/YYYY');
  } else {
    formattedStartDate = 'Unassigned';
  }

  if (endDateObj) {
    if (weekday) {
      formattedEndDate = endDateObj.format('dddd') + ', ';
    }
    formattedEndDate += endDateObj.format('MM/DD/YYYY');
  } else {
    formattedEndDate = 'Unassigned';
  }

  return `${formattedStartDate} — ${formattedEndDate}`;
};

const dateComparator = (_a: DateObjectType | string | undefined, _b: DateObjectType | string | undefined) => {
  let a = _a, b = _b;

  if(typeof a === 'string') a = dayjs(a);
  if(typeof b === 'string') b = dayjs(b);

  if(!a) return 1;
  if(!b) return -1;
  if(!a.isSame(b)) return a.isBefore(b) ? -1 : 1;

  return 0;
};

const dateRangeComparer = (_a: [DateObjectType | string | undefined, DateObjectType | string | undefined], _b: [DateObjectType | string | undefined, DateObjectType | string | undefined]) => {
  const a = _a, b = _b;

  if(typeof a[0] === 'string') a[0] = dayjs(a[0]);
  if(typeof a[1] === 'string') a[1] = dayjs(a[1]);
  if(typeof b[0] === 'string') b[0] = dayjs(b[0]);
  if(typeof b[1] === 'string') b[1] = dayjs(b[1]);

  if(!a[0]) return 1;
  if(!b[0]) return -1;
  if(!a[0].isSame(b[0])) return a[0].isBefore(b[0]) ? -1 : 1;
  if(!a[1]) return 1;
  if(!b[1]) return -1;
  return a[1].isBefore(b[1]) ? -1 : 1;
};

const getFormattedDateAndTime = (date: DateObjectType | string, format?: keyof typeof FORMAT_DATE, timezone?: string) => {
  if (timezone) {
    const currentTimezone = DateService.dayjs.tz.guess();

    if (timezone !== currentTimezone) {
      const dateInTimezone = dayjs(date).tz(timezone);

      const dateFormatted = getFormattedDate(dateInTimezone, format);
      const timeFormatted = getFormattedTimeFromDate(dateInTimezone);

      const timezoneName = timezones.find((tz) => tz.iana === timezone)?.shortName;

      return `${dateFormatted} - ${timeFormatted} ${timezoneName}`;
    }
  }

  const dateFormatted = getFormattedDate(date, format);
  const timeFormatted = getFormattedTimeFromDate(date);

  return `${dateFormatted} - ${timeFormatted}`;
};

const getFormattedDateForSaleLog = (date: DateObjectType | string) => {
  const dateObj = typeof date === 'string' ? dayjs(date) : date;

  if (dateObj.isToday()) {
    return `Today at ${getFormattedTimeFromDate(dateObj)}`;
  }
  if (dateObj.isYesterday()) {
    return `Yesterday at ${getFormattedTimeFromDate(dateObj)}`;
  }

  const daysDifference = DateService.dayjs().diff(dateObj, 'days');

  if (daysDifference < 7) {
    return `${dateObj.format('dddd')} at ${getFormattedTimeFromDate(dateObj)}`;
  }
  return `${dateObj.format('MMM Do')} at ${getFormattedTimeFromDate(dateObj)}`;
};

const timezones = [
  {
    iana: 'America/New_York',
    shortName: 'EST',
  },
  {
    iana: 'America/Chicago',
    shortName: 'CST',
  },
  {
    iana: 'America/Denver',
    shortName: 'MST',
  },
  {
    iana: 'America/Los_Angeles',
    shortName: 'PST',
  },
];

export const DateService = {
  dayjs,
  dayjsTz,
  combineDateAsUtcAndTime,
  getFormattedTimeFromDate,
  getFormattedDate,
  getFormattedTimeAmount,
  getTimeAmountValue,
  getFormattedTime,
  getFormattedTimeSubstracted,
  checkIfDateIsInPast,
  getFormattedDateRange,
  dateComparator,
  dateRangeComparer,
  getFormattedDateAndTime,
  getFormattedDateForSaleLog,
  timezones: timezones,
};
