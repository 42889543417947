import { ApiResource } from './resources.types';

export type Sale = ApiResource<{
  product: string;
  storefront?: string;
  eventDate?: string;
  event?: string;
  user?: string;
  salePrice: number;
  frame: boolean;
  payment: SalePaymentEnum;
  type: SaleTypeEnum;
}>;

export const enum SaleTypeEnum {
  manual = 'manual',
  live = 'live',
  online = 'online',
}

export const enum SalePaymentEnum {
  cash = 'cash',
  card = 'card',
  online = 'online',
  venmo = 'venmo',
  other = 'other',
}