import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createGenre } from 'api/actions/genre';
import { GenreGroupEnum } from 'api/resources/genre';
import { Select, TextInput, UpdateResourceModal, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import { getSelectOptionsFromEnumHelper } from 'helpers';
import { genreEnumHelpers } from 'helpers/enums/genre-enums.helpers';
import { QUERY_KEY } from 'queries/query-keys';
import { useMemo } from 'react';
import { formSx } from 'styles';
import { SchemaOf, mixed, object, string } from 'yup';

export type AddGenreModalProps = {
  onClose: () => void;
};

type GenreForm = {
  title: string;
  group: GenreGroupEnum;
};

const genreValidationSchema = (): SchemaOf<GenreForm> => {
  return object({
    title: string().required(),
    group: mixed<GenreGroupEnum>().oneOf(genreEnumHelpers.group.enumValues).required(),
  });
};

export const AddGenreModal: React.FC<AddGenreModalProps> = props => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: createGenre,
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.GENRES);

      snackbar.success('Genre created');
    }
  });
  const genreGroupOptions = useMemo(() => getSelectOptionsFromEnumHelper(genreEnumHelpers.group), []);
  const initialValues: GenreForm = {
    title: '',
    group: GenreGroupEnum.other,
  };

  const onSubmit = (values: GenreForm) => {
    createMutation.mutateAsync(values);
    props.onClose();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={genreValidationSchema}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open
            onClose={props.onClose}
            title="Create Genre"
            onSave={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
          >
            <Box sx={formSx.formGroup}>
              <TextInput trim fullWidth name="title" label="Genre Title *" />
              <Select name="group" label="Genre Group *" options={genreGroupOptions} />
            </Box>
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};