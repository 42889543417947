import { DateSelectArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Typography, useTheme } from '@mui/material';
import { GetEventDatesForUserResponse } from 'api/actions';
import { User } from 'api/resources';
import { ShowTheseFieldsConfig, FullCalendarSidebarActionProps } from 'components/FullCalendar/CalendarSidebarAction';
import { ShowTheseFieldsEventContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction';
import { FullCalendarEventContentContainer } from 'components/FullCalendar/EventContent';
import { FullCalendar } from 'components/FullCalendar/FullCalendar.component';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { userEnumHelpers } from 'helpers';
import { useUserAvailability } from 'queries/user';
import { useNavigate } from 'react-router-dom';
import { EventClickArg, EventContentArg } from 'types';
import { convertUserAvailabilityForCalendarBackground } from '../helpers';
import { DateService } from 'services';

type UserScheduleFullCalendarBaseProps<Field extends string, Group extends string> = {
  id: string;
  eventDates: GetEventDatesForUserResponse['data'];
  user?: User | null;
  showTheseFields?: {
    value: Field[];
    config: ShowTheseFieldsConfig<Field, Group, GetEventDatesForUserResponse['data'][number], {event: GetEventDatesForUserResponse['data'][number]['event']}>[];
  };
  sidebarActionsConfig?: FullCalendarSidebarActionProps[];
  disableAutoHeight?: boolean;
};
type UserScheduleFullCalendarSelectableProps = {
  selectable: true;
  setSelectInfo: React.Dispatch<React.SetStateAction<DateSelectArg | null>>;
};
type UserScheduleFullCalendarNotSelectableProps = {
  selectable?: false;
  setSelectInfo?: never;
};

export type UserScheduleFullCalendarProps<Field extends string, Group extends string> = UserScheduleFullCalendarBaseProps<Field, Group> & (UserScheduleFullCalendarSelectableProps | UserScheduleFullCalendarNotSelectableProps);

type ExtendedProps = {
  eventDate: GetEventDatesForUserResponse['data'][number];
  event: GetEventDatesForUserResponse['data'][number]['event'];
};

const convertEventDatesToCalendarEvents = (eventDates: GetEventDatesForUserResponse['data']) => {
  return eventDates.map((eventDate): EventInput => {
    const extendedProps: ExtendedProps = {
      eventDate,
      event: eventDate.event,
    };

    return ({
      title: eventDate.event.name,
      start: DateService.dayjsTz(eventDate.dateAsUtc).toISOString(),
      end: DateService.dayjsTz(eventDate.dateAsUtc).toISOString(),
      id: eventDate._id,
      extendedProps: extendedProps,
    });
  });
};

export const UserScheduleFullCalendar = <Field extends string, Group extends string>(props: UserScheduleFullCalendarProps<Field, Group>) => {
  const navigate = useNavigate();
  const userId = props.user ? props.user._id : '';
  const { getIsMe, isAdmin } = useCurrentUser();
  const { data: availabilitySlots = [] } = useUserAvailability(userId, !!userId);
  const isMe = getIsMe(userId);
  const theme = useTheme();

  const availabilityEvents = convertUserAvailabilityForCalendarBackground(availabilitySlots, theme);
  const isMeOrAdmin = isMe || isAdmin;

  const onEventClick = ({ event: { extendedProps } }: EventClickArg<ExtendedProps>) => {
    const eventDate = extendedProps.eventDate;
    const event = extendedProps.event;

    navigate(`/${ROUTING_CONFIG.events}/${event._id}/dates/${eventDate._id}`);
  };

  const getEventContent = (arg: EventContentArg<ExtendedProps>) => {
    if (arg.event.display === 'background') {
      return undefined;
    }
    const { event, eventDate } = arg.event.extendedProps;

    const staff = eventDate.staff.find((staff) => staff.user._id === userId);
    const statusLabel = userEnumHelpers.staffStatus.getLabel(staff?.status);
    const statusColor = userEnumHelpers.staffStatus.getColor(staff?.status);

    return (
      <FullCalendarEventContentContainer
        isStart={arg.isStart}
        isEnd={arg.isEnd}
        backgroundFunc={theme => theme.palette[statusColor].main}
      >
        <Typography fontWeight={500} fontSize="small" color="text.primary">{event.name}</Typography>
        <Typography fontSize="small" color="text.secondary">{statusLabel}</Typography>
        {props.showTheseFields && (
          <ShowTheseFieldsEventContent
            showTheseFields={props.showTheseFields.value}
            showTheseFieldsConfig={props.showTheseFields.config}
            extra={{ event }}
            eventDate={eventDate}
            group={[ eventDate ]}
          />
        )}
      </FullCalendarEventContentContainer>
    );
  };

  return (
    <FullCalendar
      calendarsConfig={[
        {
          id: props.id,
          plugins: [ dayGridPlugin, interactionPlugin ],
          height: props.disableAutoHeight ? '100%' : 'auto',
          events: [ ...convertEventDatesToCalendarEvents(props.eventDates), ...availabilityEvents ],
          initialView: 'dayGridMonth',
          headerToolbar: false,
          selectable: props.selectable && isMeOrAdmin,
          select: props.setSelectInfo,
          eventClick: onEventClick,
          eventContent: getEventContent,
          eventOrder: 'title'
        }
      ]}
      sidebarActionsConfig={props.sidebarActionsConfig}
    />
  );
};