import { Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { ProfileContent } from 'pages/Profile';
import { useUser } from 'queries/user';
import { Navigate, useParams } from 'react-router-dom';

export const User: React.FC = () => {
  const { getIsMe } = useCurrentUser();
  const { id } = useParams();
  const { data: user, isInitialLoading } = useUser(id as string);

  if (isInitialLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to={`/${ROUTING_CONFIG.users}`} replace />;
  }

  if (getIsMe(user)) {
    return <Navigate to={`/${ROUTING_CONFIG.profile}`} replace />;
  }

  return <ProfileContent user={user} />;
};