import { Box, Rating, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/extensions
import React from 'react';

export type EventFeedbackRatingProps = {
  title?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rating: number;
};

export const EventFeedbackRating: React.FC<EventFeedbackRatingProps> = ({ title, Icon, rating }) => {
  return (
    <Stack alignItems={{ xs: 'center', sm: 'flex-start' }}>
      <Typography variant="subtitle2" display="flex" alignItems="center" gap={1}>
        {Icon && <Icon fontSize="small" />}{title}
      </Typography>
      <Box display="flex" alignItems="center" gap={0.5}>
        <Rating precision={0.5} value={rating} readOnly />
        <Typography variant="subtitle2">{rating.toFixed(1)}</Typography>
      </Box>
    </Stack>
  );
};