import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetProductResponse, getProduct, getProducts, getProductsAsResourceOptions } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useProducts = () => {
  return useQuery({
    queryKey: QUERY_KEY.PRODUCTS,
    queryFn: async () => {
      const response = await getProducts();

      return response.data;
    }
  });
};

export const useProductsAsResourceOptions = () => {
  return useQuery(QUERY_KEY.PRODUCTS_AS_RESOURCE_OPTIONS, async () => {
    const response = await getProductsAsResourceOptions();

    return response.data;
  });
};

export const useProduct = (id: string, opts?: UseQueryOptions<GetProductResponse['data']>) => {
  return useQuery(QUERY_KEY.PRODUCT(id), async () => {
    const response = await getProduct(id);

    return response.data;
  }, opts);
};