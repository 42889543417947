import { Autocomplete } from 'components';
import { useUsersAsResourceOptions } from 'queries/user';

export const EventManagerForm: React.FC = () => {
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();

  return (
    <Autocomplete
      name="eventManager"
      label="Event Manager"
      options={users}
      getOptionLabel={(opt) => opt?.name}
      transformValue={(opt) => opt?._id}
      loading={usersLoading}
      autoFocus
      openOnFocus
    />
  );
};