import { Box } from '@mui/material';
import { GetUpcomingEventsHashResponse } from 'api/actions';
import { Vehicle } from 'api/resources';
import { CardContentGrid, EventList } from 'components';
import { getVehicleDistanceCost } from 'helpers';
import { currencyFormatter } from 'services';

export type VehicleEventListProps = {
  title?: string;
  events: GetUpcomingEventsHashResponse['data'][string];
  vehicle?: Pick<Vehicle, 'mpg'>;
};

export const VehicleEventList: React.FC<VehicleEventListProps> = props => {
  return (
    <EventList
      title={props.title}
      events={props.events.map(({ event }) => event)}
      showPlaceDistanceAndDurationContent={!!props.vehicle}
      renderRightSideContent={(event) => props.vehicle ? (
        <Box display="flex" justifyContent={{ xs: 'flex-start', sm: 'center' }}>
          <Box px={{ xs: 0, sm: 2 }} pt={1}>
            <CardContentGrid
              items={[
                {
                  title: 'Estimated Cost (one way)',
                  value: props.vehicle.mpg ? currencyFormatter.format(getVehicleDistanceCost(props.vehicle.mpg, event.place.fromStudioMatrix.distance)) : undefined,
                  type: 'full',
                  unassignedText: 'Unknown MPG'
                }
              ]}
            />
          </Box>
        </Box>
      ) : null}
    />
  );
};