import { ReportProblemOutlined } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Modal } from 'components/Modal';
import { useState } from 'react';
import { AttentionWarningContentStack, AttentionWarningContentStackProps } from './AttentionWarningContentStack';

export type AttentionWarningIconProps<SectionEnum extends string, RowEnum extends string> = AttentionWarningContentStackProps<SectionEnum, RowEnum>;

export const AttentionWarningIcon = <SectionEnum extends string, RowEnum extends string>(props: AttentionWarningIconProps<SectionEnum, RowEnum>) => {
  const [ showWarningContent, setShowWarningContent ] = useState(false);

  if (!props.warningItems.length) {
    return null;
  }

  return (
    <>
      <Modal topLeftIcon="close" open={showWarningContent} onClose={() => setShowWarningContent(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={2}>
            <ReportProblemOutlined color="warning" />
            Attention Needed
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <AttentionWarningContentStack {...props} />
        </DialogContent>
      </Modal>

      <IconButton color="warning" onClick={() => setShowWarningContent(true)}>
        <ReportProblemOutlined />
      </IconButton>
    </>
  );
};