import { Box, Typography } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { userEnumHelpers } from 'helpers';
import React from 'react';

export type StaffListProps = {
  staffList: GetEventsResponse['data'][number]['dates'][number]['staff'];
};

export const StaffList: React.FC<StaffListProps> = ({ staffList }) => {
  if (!staffList.length) {
    return (
      <Typography variant="body2" fontSize="small" component="i">
        No Staff
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      overflow="auto" sx={{
        '::-webkit-scrollbar': {
          height: '2px',
        }
      }}
    >
      {
        staffList.map((staff, index) => (
          <React.Fragment key={staff.user._id} >
            <Box component="span" color={userEnumHelpers.staffStatus.getColor(staff.status)}>
              {staff.user.name}
            </Box>
            {index < staffList.length - 1 && ', '
            }
          </React.Fragment>
        ))
      }
    </Typography>
  );
};
