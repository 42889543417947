import { CancelOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowModes, GridRowModesModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { DateRange } from '@mui/x-date-pickers-pro';
import { getSelectOptionsFromEnumHelper, eventEnumHelpers } from 'helpers';
import { DateService } from 'services';
import { TEventsTableEditableRow } from 'types';
import {
  ParticipationStatusCell,
  ApplicationStatusCell,
  AcceptanceStatusCell,
  ApplicationPlatformCell,
  AutocompleteCell,
  DateRangePickerCell
} from '../Cells';


const spaceSizeOptions = [ '10x10', '10x15', '10x20', '20x20', '8x8', '8x16', 'Unknown' ];
const { dayjs } = DateService;

export const getEventsTableEditableColumns = (setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>): GridColDef<TEventsTableEditableRow>[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'eventTimeframe',
    headerName: 'Event Daterange',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'attendance',
    headerName: 'Attendance',
    flex: 1,
    editable: true,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
  },
  {
    field: 'spaceSize',
    headerName: 'Space Size',
    flex: 1,
    editable: true,
    type: 'string',
    minWidth: 200,
    renderEditCell: (params) => <AutocompleteCell options={spaceSizeOptions} {...params} />
  },
  {
    field: 'participationStatus',
    headerName: 'Participation Status',
    width: 220,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.participationStatus),
    renderCell: ParticipationStatusCell,
  },
  {
    field: 'applicationStatus',
    headerName: 'Application Status',
    width: 220,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationStatus),
    renderCell: ApplicationStatusCell,
  },
  {
    field: 'acceptanceStatus',
    headerName: 'Acceptance Status',
    width: 220,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.acceptanceStatus),
    renderCell: AcceptanceStatusCell,
  },
  {
    field: 'applicationPlatform',
    headerName: 'Application Platform',
    width: 220,
    editable: true,
    type: 'singleSelect',
    valueOptions: getSelectOptionsFromEnumHelper(eventEnumHelpers.applicationPlatform),
    renderCell: ApplicationPlatformCell,
  },
  {
    field: 'applicationOpenTimeframe',
    headerName: 'App. Open Timeframe',
    flex: 1,
    minWidth: 270,
    resizable: false,
    editable: true,
    valueFormatter: ({ value }: GridValueFormatterParams<DateRange<string>>) => `${value[0] ? dayjs(value[0]).format('MM/DD/YYYY') : 'Unknown'} - ${value[1] ? dayjs(value[1]).format('MM/DD/YYYY') : 'Unknown'}`,
    renderEditCell: (params) => <DateRangePickerCell {...params} />,
  },
  //   {
  //     field: 'juryFee',
  //     headerName: 'Jury Fee',
  //     flex: 1,
  //     minWidth: 100,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'juryFeeAmount',
  //     headerName: 'Jury Fee $',
  //     type: 'number',
  //     editable: true,
  //     flex: 1,
  //     minWidth: 100,
  //   },
  //   {
  //     field: 'juryFeePaid',
  //     headerName: 'Is Jury fee paid?',
  //     flex: 1,
  //     editable: true,
  //     minWidth: 130,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'juryFeePaymentType',
  //     headerName: 'Jury fee payment',
  //     flex: 1,
  //     minWidth: 150,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: paymentTypeValues,
  //     valueFormatter: ({ value }) => paymentTypeValueToLabelHash[value as PaymentTypeEnum],
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(paymentTypeValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'lodgingRequired',
  //     headerName: 'Lodging Required',
  //     flex: 1,
  //     minWidth: 130,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'bookingStatus',
  //     headerName: 'Booking Status',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: lodgingStatusValues,
  //     renderCell: LodgingBookingStatusCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(bookingStatusValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceRequired',
  //     headerName: 'Insurance Required',
  //     flex: 1,
  //     minWidth: 150,
  //     editable: true,
  //     renderCell: ({ value }) => (
  //       <Box width="100%" display="flex" justifyContent="center">
  //         <YesNoUnknownIconSwitch value={yesNoUnknownToValueHash[value as YesNoUnknownEnum]} />
  //       </Box>
  //     ),
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(yesNoUnknownValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceStatus',
  //     headerName: 'Insurance Status',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: insuranceStatusValues,
  //     renderCell: InsuranceStatusCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(insuranceStatusValueToLabelHash)} {...params} />)
  //   },
  //   {
  //     field: 'insuranceShared',
  //     headerName: 'Insurance Shared',
  //     flex: 1,
  //     minWidth: 200,
  //     editable: true,
  //     type: 'singleSelect',
  //     valueOptions: insuranceSharedValues,
  //     renderCell: InsuranceSharedCell,
  //     renderEditCell: (params) => (<SelectCell options={hashToSelectOptions(insuranceSharedValueToLabelHash)} {...params} />)
  //   },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 100,
    cellClassName: 'actions',
    renderCell: ({ id , api }) => {
      const rowModeObj = api.getRowMode(id);

      if (rowModeObj === GridRowModes.Edit) {
        return (
          <Box display="flex">
            <GridActionsCellItem
              icon={<SaveOutlined />}
              label="Save"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }))}
              sx={{
                color: 'primary.main',
              }}
            />
            <GridActionsCellItem
              icon={<CancelOutlined />}
              label="Cancel"
              className="textPrimary"
              onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }))}
              color="inherit"
            />
          </Box>
        );
      } else {
        return (
          <GridActionsCellItem
            icon={<EditOutlined />}
            label="Edit"
            className="textPrimary"
            onClick={() => setRowModesModel((rowModesModel) => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }))}
            color="inherit"
          />
        );
      }
    }
  }
];