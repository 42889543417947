import React from 'react';
import { Box } from '@mui/material';
import { CardContentContainer, DatesStatusChip, StaffStatusChip, useEventContext } from 'components';
import { getEventStaffStatus, getEventDatesStatus } from 'helpers/event-dates/event-dates.helpers';
import { LabeledChipBox } from 'components/Chips/LabeledChipBox.component';

export const EventSchedulingCard: React.FC = () => {
  const { event } = useEventContext();

  return (
    <CardContentContainer title="Scheduling" disableAutoHeight>
      <Box display="flex" flexDirection="column" gap={2}>
        <LabeledChipBox title="Staff Status" value={getEventStaffStatus(event.dates)} ChipComponent={StaffStatusChip} />
        <LabeledChipBox title="Dates Status" value={getEventDatesStatus(event.dates)} ChipComponent={DatesStatusChip} />
      </Box>
    </CardContentContainer>
  );
};
