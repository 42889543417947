import { getDurationLabel, getTotalDuration } from './get-duration';
import { GetEventDateTimelineConfigsProps } from './get-event-date-timeline-configs';
import { getEventDateTimes } from './get-event-date-times';

export const getWorkdayTotal = (props: GetEventDateTimelineConfigsProps) => {
  const times = getEventDateTimes(props);

  if (!times.length) {
    return {
      workdayTotalInMinutes: null,
      workdayTotalLabel: '--',
      workdayTimeRangeLabel: '--',
    };
  }

  const workdayTotalInMinutes = getTotalDuration(times);
  const workdayTimeRangeLabel = `${times[0].time} - ${times[times.length - 1].time}`;

  return { workdayTotalInMinutes, workdayTotalLabel: getDurationLabel(workdayTotalInMinutes), workdayTimeRangeLabel };
};