import { RestartAltOutlined } from '@mui/icons-material';
import { Box, Button, DialogContent, DialogTitle } from '@mui/material';
import { Modal } from 'components';
import React from 'react';

export type FiltersModalProps = {
  onResetFilters: () => void;
  onClose: () => void;
  open?: boolean;
  children?: React.ReactNode;
  resetDisabled?: boolean;
};

export const FiltersModal: React.FC<FiltersModalProps> = ({
  onResetFilters,
  onClose,
  open = false,
  children,
  resetDisabled
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1500 }}
      autoHeight
      topLeftIcon="close"
    >
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Button
            onClick={() => onResetFilters()}
            color="primary"
            variant="outlined"
            startIcon={<RestartAltOutlined />}
            disabled={resetDisabled}
            size="small"
          >
            Reset Filters to Default
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          {children}
        </Box>
      </DialogContent>
    </Modal>
  );
};
