
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { LoggedAtDateCell, TeamCell } from 'components/Table/Cells';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Table } from '../../Table.component';
import { GetProductsStockUpdateLogsResponse } from 'api/actions';
import { getStockUpdateLogDetails } from './helpers';
import React from 'react';

export type TStockUpdateLogTableRow = GetProductsStockUpdateLogsResponse['data'][number];

export type StockUpdateLogTableProps = {
  rows: TStockUpdateLogTableRow[];
  loading?: boolean;
  sx?: SxProps<Theme>;
};

const columns: GridColDef<TStockUpdateLogTableRow>[] = [
  {
    field: 'createdAt',
    headerName: 'Date',
    valueGetter: ({ value }) => new Date(value),
    type: 'date',
    renderCell: LoggedAtDateCell,
    width: 200
  },
  {
    field: 'user',
    headerName: 'User',
    renderCell: ({ row }) => row.user ?? <Box color="text.secondary" fontStyle="italic">Not applicable</Box>,
    width: 115,
  },
  {
    field: 'product',
    headerName: 'Product',
    valueGetter: ({ row }) => row.stock.product.name,
    width: 200
  },
  {
    field: 'team',
    headerName: 'Team',
    valueGetter: ({ row }) => row.stock.team.name,
    renderCell: ({ row }) => <TeamCell team={row.stock.team} />,
  },
  {
    field: 'previousQuantity',
    headerName: 'Previous Quantity',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'newQuantity',
    headerName: 'New Quantity',
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'updateAmount',
    headerName: 'Update Amount',
    valueGetter: (params) => params.row.newQuantity - params.row.previousQuantity,
    renderCell: (params) => {
      const isPositive = params.value > 0;

      return (
        <Typography
          sx={{
            color: (theme) => theme.palette[isPositive ? 'success' : 'error'].main
          }}
        >
          {isPositive ? '+' : '-'}{Math.abs(params.value)}
        </Typography>
      );
    },
    align: 'center',
    headerAlign: 'center',
    flex: 1
  },
  {
    field: 'type',
    headerName: 'Update Type',
    width: 150
  },
];

export const StockUpdateLogTable: React.FC<StockUpdateLogTableProps> = ({ rows, loading, sx }) => {

  return (
    <Table
      getDetailPanelContent={({ row }: GridRowParams<TStockUpdateLogTableRow>) => {

        return (
          <Box
            sx={(theme) => ({ background: theme.palette.grey[50] })}
            p={2}
            pl="58px"
          >
            <Typography variant="body2">{getStockUpdateLogDetails(row)}</Typography>
          </Box>
        );
      }}
      getDetailPanelHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'createdAt', sort: 'desc' }
          ],
        },
      }}
      getRowId={(x) => x._id}
      columns={columns}
      rows={rows}
      loading={loading}
      emptyMessage="No Update Logs Were Found"
      disableRowSelectionOnClick
      sx={sx}
    />
  );
};