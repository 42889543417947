import { authAxiosRequest } from 'api/axios';
import { Event, EventDate, EventDateImageTypeEnum, EventDateSetupBreakdownTypeEnum, EventDateStaffStatusEnum, PlaceMatrix, Team, User, UserAvailabilityEnum, Vehicle } from 'api/resources';
import { DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { DeepPartial } from 'types';

// dateString format is YYYY-MM-DD
export type GetEventDatesInput = {
  date_eq?: string;
  startDate?: string;
  endDate?: string;
};

export type GetEventDatesResponse = {
  data: ResourceWithPopulated<EventDate, {
    event: ResourceWithPopulated<Event, {
      team: PickFromResource<Team, 'name'>;
      vehicle: PickFromResource<Vehicle, 'name'>;
      eventManager: User;
      place: { travelMatrix: PlaceMatrix };
    }>;
    staff: ResourceWithPopulated<EventDate['staff'][number], { user: User }>[];
    createdBy?: PickFromResource<User, 'name'>;
  }>[];
};

export const getEventDates = (params?: GetEventDatesInput) => {
  return authAxiosRequest<GetEventDatesResponse>('/api/event-dates', { params });
};

export type GetEventDateResponse = {
  data: GetEventDatesResponse['data'][number];
};

export const getEventDate = (id: string) => {
  return authAxiosRequest<GetEventDateResponse>(`/api/event-dates/${id}`);
};

export type GetEventDatesForUserResponse = {
  data: GetEventDatesResponse['data'];
};

export const getEventDatesForUser = (id: string) => {
  return authAxiosRequest<GetEventDatesForUserResponse>(`/api/users/${id}/event-dates`);
};

export type CreateEventDateInput = DeepOptionalToNull<{
  date: string;
  type: EventDate['type'];
  confirmedTimes: boolean;

  startTime: string;
  endTime: string;

  setupType: EventDateSetupBreakdownTypeEnum;
  breakdownType: EventDateSetupBreakdownTypeEnum;

  setupEndTime?: string;
  arriveAsEarlyAs?: string;
  noVehiclesAllowedAfter?: string;
  allVehiclesMustBeRemovedBy?: string;

  bufferTimeInMinutes: number;
  displaySetupInMinutes: number;
  breakdownInMinutes: number;

  notes?: string;
  staffNeeded?: number;
  teamLeadNeeded: boolean;
}>;

export const createEventDate = (eventId: string, data: CreateEventDateInput) => {
  return authAxiosRequest<GetEventDateResponse>(`/api/events/${eventId}/event-dates`, { method: 'POST', data });
};

export type UpdateEventDateInput = DeepPartial<Omit<CreateEventDateInput, 'date'>>;

export const updateEventDate = (id: string, data: UpdateEventDateInput) => {
  return authAxiosRequest<GetEventDateResponse>(`/api/event-dates/${id}`, { method: 'PUT', data });
};

export const deleteEventDate = (id: string) => {
  return authAxiosRequest(`/api/event-dates/${id}`, { method: 'DELETE' });
};


export type ResizeEventDateInput = {
  interval: number;
  reverse?: boolean;
};

export const resizeEventDate = (id: string, data: ResizeEventDateInput) => {
  return authAxiosRequest<GetEventDateResponse>(`/api/event-dates/${id}/resize`, { method: 'POST', data });
};

// Staff

export type GetEventDateAddStaffUserOptions = {
  data: {
    userId: string;
    userName: string;
    payRate?: number;
    userProfileImageUrl?: string;
    isTeamLead: boolean;
    conflictEvent?: string;
    availability: UserAvailabilityEnum | null;
  }[];
};

export const getEventDateAddStaffUserOptions = (id: string) => {
  return authAxiosRequest<GetEventDateAddStaffUserOptions>(`/api/event-dates/${id}/getAddStaffUserOptions`);
};

export type AddStaffToEventDateInput = {
  user: string;
  status: EventDateStaffStatusEnum | '';
  isTeamLead?: boolean;
};

export const addStaffToEventDate = (eventDateId: string, data: AddStaffToEventDateInput) => {
  return authAxiosRequest(`/api/event-dates/${eventDateId}/staff`, { method: 'POST', data });
};

export const updateEventDateStaff = (eventDateId: string, staffId: string, data: Partial<Pick<AddStaffToEventDateInput, 'status' | 'isTeamLead'>>) => {
  return authAxiosRequest(`/api/event-dates/${eventDateId}/staff/${staffId}`, { method: 'PUT', data });
};

export const removeStaffFromEventDate = (eventDateId: string, staffId: string) => {
  return authAxiosRequest(`/api/event-dates/${eventDateId}/staff/${staffId}`, { method: 'DELETE' });
};

// Images

export type CreateEventDateImageInput = {
  url: string;
  type: EventDateImageTypeEnum;
};

export const createEventDateImage = (eventDateId: string, data: CreateEventDateImageInput) => {
  return authAxiosRequest(`/api/event-dates/${eventDateId}/images`, { method: 'POST', data });
};

export const deleteEventDateImage = (eventDateId: string, imageId: string) => {
  return authAxiosRequest(`/api/event-dates/${eventDateId}/images/${imageId}`, { method: 'DELETE' });
};