import { GetEventsResponse } from 'api/actions';
import { FullCalendar, FullCalendarProps } from 'components/FullCalendar/FullCalendar.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { EventClickArg, EventContentArg } from 'types';
import { Box, Typography } from '@mui/material';
import { FullCalendarEventContentContainer } from 'components/FullCalendar/EventContent';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { EventsCalendarEventExtendedProps, convertEventsForEventsCalendar, eventOrder } from '../helpers';
import { useMemo } from 'react';
import { EventInput } from '@fullcalendar/react';

export type EventsFullCalendarProps = {
  id: string;
  events: GetEventsResponse['data'];
  filter: (event: EventInput) => boolean;
  getEventContent?: (arg: EventContentArg<EventsCalendarEventExtendedProps>) => React.ReactNode;
  onEventClick?: (eventId: string) => void;
  spanEvents: boolean;
} & Pick<FullCalendarProps, 'sidebarActionsConfig'>;

export const EventsFullCalendar: React.FC<EventsFullCalendarProps> = props => {
  const navigate = useNavigate();

  const onEventClick = (clickInfo: EventClickArg<EventsCalendarEventExtendedProps>) => {
    const event = clickInfo.event.extendedProps.event;
    const { detail } = clickInfo.jsEvent;

    if (detail === 1) {
      props.onEventClick?.(event._id);
    } else {
      navigate(`/${ROUTING_CONFIG.events}/${event._id}`);
    }
  };

  const getEventContent = (arg: EventContentArg<EventsCalendarEventExtendedProps>) => {
    const { event } = arg.event.extendedProps;

    return (
      <FullCalendarEventContentContainer
        isStart={arg.isStart}
        isEnd={arg.isEnd}
        backgroundFunc={theme => theme.palette.primary.main}
      >
        <Typography fontWeight={500} fontSize="small" whiteSpace="nowrap" color="text.primary">{arg.event.title}</Typography>
        <Typography fontSize="small" color="text.secondary">{event.team?.name}</Typography>
      </FullCalendarEventContentContainer>
    );
  };

  const convertedEvents = useMemo(() => convertEventsForEventsCalendar(props.events, props.spanEvents), [ props.events, props.spanEvents ]);
  const filteredEvents =  convertedEvents.filter(event => props.filter ? props.filter(event) : true);

  return (
    <Box>
      <FullCalendar
        calendarsConfig={[
          {
            id: 'eventsCalendar',
            height: 'auto',
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
            initialView: 'dayGridMonth',
            headerToolbar: false,
            eventContent: props.getEventContent ?? getEventContent,
            eventClick: onEventClick,
            eventOrder: (a: EventContentArg<EventsCalendarEventExtendedProps>['event'], b: EventContentArg<EventsCalendarEventExtendedProps>['event']) => eventOrder(a, b),
            events: filteredEvents,
          }
        ]}
        sidebarActionsConfig={props.sidebarActionsConfig}
      />
    </Box>
  );
};