import { initializeApp } from 'firebase/app';
import { deleteObject, getBlob, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { REACT_APP_ENVIRONMENT } from 'config/environment.config';
import { DateService } from 'services/date-service';

// Firebase connection

const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyBS6VtFBRMdaElq-2sQI49GaRV06J76eg4',
  authDomain: 'astro-vinyl-art-d9797.firebaseapp.com',
  projectId: 'astro-vinyl-art-d9797',
  storageBucket: 'astro-vinyl-art-d9797.appspot.com',
  messagingSenderId: '408259971950',
  appId: '1:408259971950:web:69fd0540b962c098058f7f',
  measurementId: 'G-V4Q110H5N3'
};

const firebaseConfigProduction = {
  apiKey: 'AIzaSyDNxZU1tb85olvPxrk04Gtt2KCe3kRC2vI',
  authDomain: 'astro-vinyl-art-production.firebaseapp.com',
  projectId: 'astro-vinyl-art-production',
  storageBucket: 'astro-vinyl-art-production.appspot.com',
  messagingSenderId: '461311933056',
  appId: '1:461311933056:web:9e39bb507147e28490c465',
  measurementId: 'G-NJ5LX39TGD'
};

const config = (REACT_APP_ENVIRONMENT === 'development' || REACT_APP_ENVIRONMENT === 'staging')
  ? firebaseConfigDevelopment
  : firebaseConfigProduction;

const app = initializeApp(config);
const firebaseStorage = getStorage(app);

// Service functions

const convertFileName = (fileName: string) => {
  return DateService.dayjs().unix() + fileName.replaceAll(' ', '_');
};

const deleteFile = async (path: string) => {
  const storage = getStorage();
  const fileRef = ref(storage, path);

  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.log('File delete error');
  }
};

const downloadFile = async (path: string) => {
  const fileName = FirebaseService.getFileNameFromUrl(path).substring(13);
  const storageRef = ref(firebaseStorage, path);

  const blob = await getBlob(storageRef);
  const file = new File([ blob ], fileName);

  return file;
};

const uploadFile = async (file: any, path: string) => {
  return new Promise<string>((resolve, reject) => {
    const storageRef = ref(firebaseStorage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log('Firebase Upload Error: ', error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => resolve(downloadURL));
      });
  });
};

const getFileNameFromUrl = (url: string) => {
  return url.split('?')[0].split('/').slice(-1)[0].split('%2F').slice(-1)[0];
};

export const FirebaseService = {
  convertFileName,
  getFileNameFromUrl,
  uploadFile,
  downloadFile,
  deleteFile,
};