import { CancelPresentation } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

export type TableOverlayType = {
  message?: string;
  icon?: React.ReactNode;
};

export const TableOverlay: React.FC<TableOverlayType> = ({
  icon = <CancelPresentation />,
  message = 'No results',
}) => {
  return (
    <GridOverlay>
      <Box
        p={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          mb={3}
          sx={{
            color: (theme) => {
              return theme.palette.text.secondary;
            },
            '& > svg': {
              fontSize: '64px',
            },
          }}
        >
          {icon}
        </Box>
        <Typography variant="h6" color="text.secondary">{message}</Typography>
      </Box>
    </GridOverlay>
  );
};
