import { EventDateTimePeriodType, StrictForm, UnknownEnum, YesNoUnknownEnum } from 'types';
import { EventCommunicationLog, EventFutureInterestStatusEnum, EventIndoorOurdoorEnum, EventInsurance, EventLodgingBookingStatusEnum, EventPreperation, EventPreperationAcceptanceStatusEnum, EventPreperationApplicationPlatformEnum, EventPreperationApplicationStatusEnum, EventPreperationParticipationStatusEnum, EventPreperationPayment } from 'api/resources';
import { FileFormType } from 'components';

export type EventDateTimePeriodOptionType = EventDateTimePeriodType | 'all';

export const enum EventPageSection {
  OVERVIEW = 'overview',
  TO_DOS = 'to_dos',
  DATES = 'dates',
  PHOTOS = 'photos',
  SALES = 'sales',
  APPLICATION = 'application',
  LOCATION = 'location',
  LODGING = 'lodging',
  PAYMENTS = 'payments',
  INSURANCE = 'insurance',
  LINKS = 'links',
  FILES = 'files',
}

export const enum EventPageSectionRowId {
  NAME = 'name',
  EVENT_ORGANIZER = 'event_organizer',
  TEAM = 'team',
  EVENT_MANAGER = 'event_manager',
  ATTENDANCE = 'attendance',
  SPACE_SIZE = 'space_size',
  SPACE_NUMBER = 'space_number',
  VENDORS = 'vendors',
  INDOOR_OUTDOOR = 'indoor_outdoor',
  ON_WEBSITE = 'on_website',
  RAIN_OR_SHINE = 'rain_or_shine',

  ELECTRICITY = 'electricity',
  NOTES = 'notes',
  DESCRIPTION = 'description',

  TO_DO = 'to_do',

  PARTICIPATION_STATUS = 'participation_status',
  APPLICATION_DATE_RANGE = 'application_date_range',
  APPLICATION_STATUS = 'application_status',
  APPLICATION_PLATFORM = 'application_platform',
  ACCEPTANCE_STATUS = 'acceptance_status',
  APPLICATION_DENIED_REASON = 'application_denied_reason',
  FUTURE_INTEREST_STATUS = 'future_interest_status',
  COMMUNICATION_LOG = 'communication_log',

  ADDRESS = 'address',
  VEHICLE = 'vehicle',
  LODGING_REQUIRED = 'lodging_required',
  LODGING_PLACE = 'lodging_place',
  LODGING_NOTES = 'lodging_notes',

  PAYMENTS_DUE_DATE = 'payments_due_date',
  COST = 'cost',
  PAYMENTS = 'payments',
  PAYMENTS_DUE = 'payments_due',
  PAYMENT_NOTE = 'payment_note',
  JURY_FEE = 'jury_fee',
  ELECTRICITY_FEE = 'electricity_fee',

  INSURANCE = 'insurance',

  APPLICATION_FILES = 'application_files',
  LODGING_FILES = 'lodging_files',
  INSURANCE_FILES = 'insurance_files',
  OTHER_FILES = 'other_files',

  EXTERNAL_LINKS='external_links'
}

export type EventSectionRowForm = {
  [EventPageSectionRowId.NAME]: StrictForm<{
    name: string;
  }>;
  [EventPageSectionRowId.EVENT_ORGANIZER]: StrictForm<{
    eventOrganizer: string | null;
  }>;
  [EventPageSectionRowId.TEAM]: StrictForm<{
    team: string | null;
    teamManager: string | null;
  }>;
  [EventPageSectionRowId.EVENT_MANAGER]: StrictForm<{
    eventManager: string | null;
  }>;
  [EventPageSectionRowId.ATTENDANCE]: StrictForm<{
    attendance: number;
  }>;
  [EventPageSectionRowId.SPACE_SIZE]: StrictForm<{
    spaceSize: string;
  }>;
  [EventPageSectionRowId.SPACE_NUMBER]: StrictForm<{
    spaceNumber: string;
  }>;
  [EventPageSectionRowId.VENDORS]: StrictForm<{
    vendors: number;
  }>;
  [EventPageSectionRowId.INDOOR_OUTDOOR]: StrictForm<{
    indoorOutdoor: EventIndoorOurdoorEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.RAIN_OR_SHINE]: StrictForm<{
    rainOrShine: YesNoUnknownEnum;
  }>;
  [EventPageSectionRowId.ON_WEBSITE]: StrictForm<{
    onWebsite: YesNoUnknownEnum;
  }>;
  [EventPageSectionRowId.ELECTRICITY]: StrictForm<{
    electricityWanted: YesNoUnknownEnum;
    electricityAvailable: YesNoUnknownEnum;
    electricGeneratorAllowed: YesNoUnknownEnum;
  }>;
  [EventPageSectionRowId.NOTES]: StrictForm<{
    notes: string;
  }>;
  [EventPageSectionRowId.DESCRIPTION]: StrictForm<{
    description: string;
  }>;
  [EventPageSectionRowId.TO_DO]: StrictForm<{
    title: string;
    description: string;
    date: string | null;
    assignees: string[];
  }>;
  [EventPageSectionRowId.PARTICIPATION_STATUS]: StrictForm<{
    participationStatus: EventPreperationParticipationStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_DATE_RANGE]: StrictForm<{
    applicationOpenDate: string | null;
    applicationIsOpen: boolean;
    applicationDeadlineDate: string | null;
    applicationOpenUntilFull: boolean;
  }>;
  [EventPageSectionRowId.APPLICATION_STATUS]: StrictForm<{
    applicationStatus: EventPreperationApplicationStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_PLATFORM]: StrictForm<{
    applicationPlatform: EventPreperationApplicationPlatformEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.ACCEPTANCE_STATUS]: StrictForm<{
    acceptanceStatus: EventPreperationAcceptanceStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_DENIED_REASON]: StrictForm<{
    applicationDeniedReason: string;
  }>;
  [EventPageSectionRowId.FUTURE_INTEREST_STATUS]: StrictForm<{
    futureInterestStatus: EventFutureInterestStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.COMMUNICATION_LOG]: StrictForm<{
    date: string;
    type: EventCommunicationLog['type'];
    note: string;
  }>;
  [EventPageSectionRowId.ADDRESS]: StrictForm<{
    location: string;
    placeId: string;
  }>;
  [EventPageSectionRowId.VEHICLE]: StrictForm<{
    vehicle: string | null;
  }>;
  [EventPageSectionRowId.LODGING_REQUIRED]: StrictForm<{
    isRequired: YesNoUnknownEnum;
  }>;
  [EventPageSectionRowId.LODGING_NOTES]: StrictForm<{
    notes: string;
  }>;
  [EventPageSectionRowId.LODGING_PLACE]: StrictForm<{
    bookingStatus: EventLodgingBookingStatusEnum | UnknownEnum;
    cost: number;
    finalCost: number;

    placeId: string;
    placeName: string;
    placeAddress: string;

    notes: string;
    checkIn: string | null;
    checkOut: string | null;
    website: string;
    phone: string;
    confirmationNumber: string;
    needToSendCreditAuthorization: YesNoUnknownEnum;
    creditAuthorizationSent: YesNoUnknownEnum;
  }>;
  [EventPageSectionRowId.PAYMENTS_DUE_DATE]: StrictForm<{
    paymentsDueDate: string | null;
  }>;
  [EventPageSectionRowId.COST]: StrictForm<{
    cost: number;
  }>;
  [EventPageSectionRowId.PAYMENTS]: StrictForm<{
    receiptNumber: string;
    amount: string;
    type: EventPreperationPayment['type'];
    paymentDue: string;
  }>;
  [EventPageSectionRowId.PAYMENTS_DUE]: StrictForm<{
    amountDue: number;
    dueWithApplication?: boolean;
    dueAsSoonAsPossible?: boolean;
    dueDate?: string;
  }>;
  [EventPageSectionRowId.PAYMENT_NOTE]: StrictForm<{
    paymentNote: string;
  }>;
  [EventPageSectionRowId.JURY_FEE]: StrictForm<{
    juryFeeRequired: YesNoUnknownEnum;
    juryFeeAmount: number;
    juryFeePaid: YesNoUnknownEnum;
    juryFeePaymentType: EventPreperation['juryFeePaymentType'] | UnknownEnum;
  }>;
  [EventPageSectionRowId.ELECTRICITY_FEE]: StrictForm<{
    electricityFeeRequired: YesNoUnknownEnum;
    electricityFeeAmount: number;
    electricityFeePaid: YesNoUnknownEnum;
    electricityFeePaymentType: EventPreperation['electricityFeePaymentType'] | UnknownEnum;
  }>;
  [EventPageSectionRowId.INSURANCE]: StrictForm<{
    isRequired: YesNoUnknownEnum;
    status: EventInsurance['status'] | UnknownEnum;
    shared: EventInsurance['shared'] | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_FILES]: FileFormType;
  [EventPageSectionRowId.LODGING_FILES]: FileFormType;
  [EventPageSectionRowId.INSURANCE_FILES]: FileFormType;
  [EventPageSectionRowId.OTHER_FILES]: FileFormType;
  [EventPageSectionRowId.EXTERNAL_LINKS]: StrictForm<{
    title: string;
    url: string;
  }>;
};