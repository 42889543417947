import { Box } from '@mui/material';
import { Select, TextInputWithAuto } from 'components';
import { useFormikContext } from 'formik';
import { eventDateEnumHelpers, getEventDateAutoBreakdownTime, getSelectOptionsFromEnumHelper } from 'helpers';
import React, { useMemo } from 'react';
import { formSx } from 'styles';
import { ResourceForm } from 'types';

export const EventDateCloseProcedureForm = () => {
  const { values } = useFormikContext<ResourceForm['eventDate']>();
  const breakdownTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventDateEnumHelpers.setupBreakdownType), []);
  const autoBreakdownTime = getEventDateAutoBreakdownTime(values.breakdownType, values.staffNeeded);

  return (
    <Box sx={formSx.formGroup}>
      <Box display="flex" gap={3}>
        <Select
          name="breakdownType"
          label="Breakdown type"
          disabled={values.type === 'travel'}
          fullWidth
          options={breakdownTypeOptions}
          sx={{ flex: 1 }}
        />
        <Box flex={1}>
          <TextInputWithAuto
            type="number"
            name="breakdownInMinutes"
            label="Breakdown (minutes)"
            fullWidth
            autoValue={autoBreakdownTime}
          />
        </Box>
      </Box>
    </Box>
  );
};
