import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import { Box, ClickAwayListener, Fade, IconButton, Popper, keyframes } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';

export type InfoPopperProps = {
  children: React.ReactNode;
};

const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.2)
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    background-color: initial;
  }
`;

const StyledIconButton = styled(IconButton)({
  animation: `${ripple} 1.5s 2`
});

export const InfoPopper: React.FC<InfoPopperProps> = props => {
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if(anchorEl) {
      timeout = setTimeout(() => setAnchorEl(null), 15000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [ anchorEl, open ]);

  return (
    <>
      <StyledIconButton aria-describedby={id} type="button" onClick={handleOpen}>
        <InfoOutlined />
      </StyledIconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} transition sx={{ zIndex: FULL_SCREEN_Z_INDEX + 1 }} placement="bottom-end">
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Box sx={theme => ({ px: 3, py: 2, bgcolor: theme.palette.background.paper, border: 1, borderRadius: theme.shape.borderRadius, borderColor: theme.palette.grey[400], maxWidth: 400 })}>
                {props.children}
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};