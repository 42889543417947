import { BarChartOutlined, CloseFullscreen, OpenInFull, PieChartOutlined } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useMemo } from 'react';
import { AnalyticsChartWrapper } from './AnalyticsChartWrapper.component';
import { DateRange, GetAnalyticsChartDataProps, getAnalyticsChartData } from 'components';
import { AnalyticsChartDateRangeChip } from './AnalyticsChartDateRangeChip.component';
import { AnalyticsChartTypeEnum, UseAnalyticsChartProps, useAnalyticsChart } from './useAnalyticsChart.hook';
import { DefaultAnalyticsChartTooltipContent } from './DefaultAnalyticsChartTooltipContent.component';

export type AnalyticsChartConfig<Data> = GetAnalyticsChartDataProps<Data>;
export type AnalyticsChartProps<Data> = Omit<UseAnalyticsChartProps, 'data' | 'stackedBar'> & {
  header: string;
  actions?: React.ReactNode;
  config: AnalyticsChartConfig<Data>;
  loading?: boolean;
  allowedViewTypes?: Exclude<AnalyticsChartTypeEnum, 'line'>[];
  getNavigateTo?: (id: string) => string;
};

const defaultAllowedViewTypes: Exclude<AnalyticsChartTypeEnum, 'line'>[] = [ AnalyticsChartTypeEnum.bar ];

export const AnalyticsChart = <Data extends unknown>({
  header,
  config,
  loading,
  allowedViewTypes = defaultAllowedViewTypes,
  tooltipContent = DefaultAnalyticsChartTooltipContent,
  actions,
  ...props
}: AnalyticsChartProps<Data>) => {
  const data = useMemo(() => getAnalyticsChartData(config), [ config ]);
  const {
    chartType,
    setChartType,
    chartDisplay,
    tooltipDisplay,
    fullScreen,
    onFullScreenToggle
  } = useAnalyticsChart({
    ...props,
    tooltipContent,
    data,
    initialChartType: props.initialChartType ?? allowedViewTypes?.[0],
    getDisplayLegend: ({ chartType }) => chartType === AnalyticsChartTypeEnum.pie,
    stackedBar: config.stacked,
  });

  const dateRanges = useMemo(() => {
    return data.datasets
      .map(({ dateRange }) => dateRange)
      .reduce((r, dateRange) => {
        if (!r.find(rDateRange => rDateRange.start.isSame(dateRange.start, 'day') && rDateRange.end.isSame(dateRange.end, 'day'))) {
          return [ ...r, dateRange ];
        }

        return r;
      }, [] as NonNullable<DateRange>[]);
  }, [ data.datasets ]);

  return (
    <AnalyticsChartWrapper loading={loading} fullScreen={fullScreen}>
      {tooltipDisplay}
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={6}>
          <Typography variant="subtitle1" sx={{ pb: 0, borderBottomColor: (theme) => theme.palette.grey[500], borderBottomStyle: 'dotted', borderBottomWidth: 3 }} >{header}</Typography>
          <Box display="flex" alignItems="flex-start" gap={1}>
            {actions}
            {allowedViewTypes?.length === 2 && (
              <ToggleButtonGroup value={chartType} onChange={(_, value) => value && setChartType(value) } exclusive size="small">
                <ToggleButton value={AnalyticsChartTypeEnum.bar}><BarChartOutlined fontSize="small" /></ToggleButton>
                <ToggleButton value={AnalyticsChartTypeEnum.pie}><PieChartOutlined fontSize="small" /></ToggleButton>
              </ToggleButtonGroup>
            )}
            <ToggleButton onClick={onFullScreenToggle} value="" size="small">
              {fullScreen ? <CloseFullscreen titleAccess="Exit Full Screen" fontSize="small" /> : <OpenInFull titleAccess="Enter Full Screen" fontSize="small" />}
            </ToggleButton>
          </Box>
        </Box>
        {chartDisplay}
        <Box display="flex" justifyContent="flex-end" mt={1} gap={1} overflow="auto" flexWrap="wrap">
          {dateRanges.map((dateRange, index) => <AnalyticsChartDateRangeChip key={index} dateRange={dateRange} />)}
        </Box>
      </Box>
    </AnalyticsChartWrapper>
  );
};