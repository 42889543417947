import { ListAltOutlined, AppsOutlined, OpenInNewOutlined, LockOutlined, CancelOutlined, AccessTimeOutlined, NotInterested, PendingActionsOutlined, HelpOutlineOutlined, ControlPointDuplicate, AccountCircleOutlined, DateRangeOutlined, EventNoteOutlined, HotelOutlined, PaymentOutlined, PolicyOutlined, WarningAmberOutlined, DynamicFeedOutlined } from '@mui/icons-material';
import { EnumHelpersWithIcon } from 'helpers/enums/types';

export const enum EventsManagementTreeViewNodeIdEnum {
  events = 'events',
  eventsProSearch = 'eventsProSearch',
  eventsCalendar = 'eventsCalendar',
  eventsDuplicate = 'eventsDuplicate',
  eventsMissingDates = 'eventsMissingDates',

  applications = 'applications',

  applicationsMissingData = 'applicationsMissingData',
  applicationsComingSoon = 'applicationsComingSoon',
  applicationsOpen = 'applicationsOpen',
  applicationsClosed = 'applicationsClosed',
  applicationsPending = 'applicationsPending',
  applicationsWaitListed = 'applicationsWaitListed',
  applicationsDenied = 'applicationsDenied',
  applicationsCommunicationLog = 'applicationsCommunicationLog',

  logistics = 'logistics',
  logisticsMissingData = 'logisticsMissingData',
  logisticsInsurance = 'logisticsInsurance',
  logisticsPaymentsDue = 'logisticsPaymentsDue',
  logisticsEventDates = 'logisticsEventDates',
  logisticStaffSchedule = 'logisticsStaffSchedule',
  logisticsStaffScheduleUserCards = 'logisticsStaffScheduleUserCards',
  logisticsStaffScheduleCalendar = 'logisticsStaffScheduleCalendar',
  logisticStaffScheduleList = 'logisticStaffScheduleList',

  logisticsTeamAssignment = 'logisticsTeamAssignment',
  logisticsTeamAssignmentList = 'logisticsTeamAssignmentList',
  logisticsTeamAssignmentCalendar = 'logisticsTeamAssignmentCalendar',

  logisticsLodging = 'logisticsLodging',
}

export const eventsManagementTreeViewNodeIdEnumHelpers: EnumHelpersWithIcon<EventsManagementTreeViewNodeIdEnum> & { getFullLabel: (value: EventsManagementTreeViewNodeIdEnum) => string } = {
  enumValues: [
    EventsManagementTreeViewNodeIdEnum.events,
    EventsManagementTreeViewNodeIdEnum.eventsProSearch,
    EventsManagementTreeViewNodeIdEnum.eventsCalendar,
    EventsManagementTreeViewNodeIdEnum.eventsDuplicate,
    EventsManagementTreeViewNodeIdEnum.eventsMissingDates,
    EventsManagementTreeViewNodeIdEnum.applications,
    EventsManagementTreeViewNodeIdEnum.applications,
    EventsManagementTreeViewNodeIdEnum.applicationsComingSoon,
    EventsManagementTreeViewNodeIdEnum.applicationsOpen,
    EventsManagementTreeViewNodeIdEnum.applicationsClosed,
    EventsManagementTreeViewNodeIdEnum.applicationsPending,
    EventsManagementTreeViewNodeIdEnum.applicationsWaitListed,
    EventsManagementTreeViewNodeIdEnum.applicationsDenied,
    EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog,
    EventsManagementTreeViewNodeIdEnum.logisticsInsurance,
    EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue,
    EventsManagementTreeViewNodeIdEnum.logistics,
    EventsManagementTreeViewNodeIdEnum.logisticsMissingData,
    EventsManagementTreeViewNodeIdEnum.logisticsEventDates,
    EventsManagementTreeViewNodeIdEnum.logisticStaffSchedule,
    EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards,
    EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar,
    EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList,
    EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignment,
    EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList,
    EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar,
    EventsManagementTreeViewNodeIdEnum.logisticsLodging,
  ],
  getLabel(value: EventsManagementTreeViewNodeIdEnum): string {
    switch (value) {
      case EventsManagementTreeViewNodeIdEnum.events:
        return 'Events';
      case EventsManagementTreeViewNodeIdEnum.eventsProSearch:
        return 'Pro Search';
      case EventsManagementTreeViewNodeIdEnum.eventsCalendar:
        return 'Calendar';
      case EventsManagementTreeViewNodeIdEnum.eventsDuplicate:
        return 'Duplicate';
      case EventsManagementTreeViewNodeIdEnum.eventsMissingDates:
        return 'Missing Dates';
      case EventsManagementTreeViewNodeIdEnum.applications:
        return 'Applications';
      case EventsManagementTreeViewNodeIdEnum.applicationsMissingData:
        return 'Missing Data';
      case EventsManagementTreeViewNodeIdEnum.applicationsComingSoon:
        return 'Coming Soon';
      case EventsManagementTreeViewNodeIdEnum.applicationsOpen:
        return 'Open';
      case EventsManagementTreeViewNodeIdEnum.applicationsClosed:
        return 'Closed';
      case EventsManagementTreeViewNodeIdEnum.applicationsPending:
        return 'Pending';
      case EventsManagementTreeViewNodeIdEnum.applicationsWaitListed:
        return 'Waitlisted';
      case EventsManagementTreeViewNodeIdEnum.applicationsDenied:
        return 'Denied';
      case EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog:
        return 'Comm. log';
      case EventsManagementTreeViewNodeIdEnum.logistics:
        return 'Logistics';
      case EventsManagementTreeViewNodeIdEnum.logisticsMissingData:
        return 'Missing Data';
      case EventsManagementTreeViewNodeIdEnum.logisticsInsurance:
        return 'Insurance';
      case EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue:
        return 'Payments Due';
      case EventsManagementTreeViewNodeIdEnum.logisticsEventDates:
        return 'Event Dates';
      case EventsManagementTreeViewNodeIdEnum.logisticStaffSchedule:
        return 'Staff Schedule';
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards:
        return 'User Cards';
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar:
        return 'Calendar';
      case EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList:
        return 'Events List';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignment:
        return 'Team Assignment';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList:
        return 'Events List';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar:
        return 'Calendar';
      case EventsManagementTreeViewNodeIdEnum.logisticsLodging:
        return 'Lodging';
      default:
        return 'Other';
    }
  },
  getFullLabel(value: EventsManagementTreeViewNodeIdEnum): string {
    switch (value) {
      case EventsManagementTreeViewNodeIdEnum.events:
        return 'Events';
      case EventsManagementTreeViewNodeIdEnum.eventsProSearch:
        return 'Events Pro Search';
      case EventsManagementTreeViewNodeIdEnum.eventsCalendar:
        return 'Events Calendar';
      case EventsManagementTreeViewNodeIdEnum.eventsDuplicate:
        return 'Events Duplicate';
      case EventsManagementTreeViewNodeIdEnum.eventsMissingDates:
        return 'Events Missing Dates';
      case EventsManagementTreeViewNodeIdEnum.applications:
        return 'Applications';
      case EventsManagementTreeViewNodeIdEnum.applicationsMissingData:
        return 'Applications: Missing Data';
      case EventsManagementTreeViewNodeIdEnum.applicationsComingSoon:
        return 'Applications: Coming Soon';
      case EventsManagementTreeViewNodeIdEnum.applicationsOpen:
        return 'Applications: Open';
      case EventsManagementTreeViewNodeIdEnum.applicationsClosed:
        return 'Applications: Closed';
      case EventsManagementTreeViewNodeIdEnum.applicationsPending:
        return 'Applications: Pending';
      case EventsManagementTreeViewNodeIdEnum.applicationsWaitListed:
        return 'Applications: Waitlisted';
      case EventsManagementTreeViewNodeIdEnum.applicationsDenied:
        return 'Applications: Denied';
      case EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog:
        return 'Applications: Communication Log';
      case EventsManagementTreeViewNodeIdEnum.logistics:
        return 'Logistics';
      case EventsManagementTreeViewNodeIdEnum.logisticsInsurance:
        return 'Logistics: Insurance';
      case EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue:
        return 'Logistics: Payments Due';
      case EventsManagementTreeViewNodeIdEnum.logisticsMissingData:
        return 'Logistics: Missing Data';
      case EventsManagementTreeViewNodeIdEnum.logisticsEventDates:
        return 'Logistics: Event Dates';
      case EventsManagementTreeViewNodeIdEnum.logisticStaffSchedule:
        return 'Logistics: Staff Schedule';
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards:
        return 'Logistics: Staff Schedule User Cards';
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar:
        return 'Logistics: Staff Schedule Calendar';
      case EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList:
        return 'Logistics: Staff Schedule Events List';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignment:
        return 'Logistics: Team Assignment';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList:
        return 'Logistics: Team Assignment Events List';
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar:
        return 'Logistics: Team Assignment Calendar';
      case EventsManagementTreeViewNodeIdEnum.logisticsLodging:
        return 'Logistics: Lodging';
      default:
        return 'Other';
    }
  },
  getColor: () => 'unknown',
  getIcon: (value) => {
    switch (value) {
      case EventsManagementTreeViewNodeIdEnum.eventsProSearch:
        return ListAltOutlined;
      case EventsManagementTreeViewNodeIdEnum.eventsCalendar:
        return EventNoteOutlined;
      case EventsManagementTreeViewNodeIdEnum.eventsDuplicate:
        return ControlPointDuplicate;
      case EventsManagementTreeViewNodeIdEnum.eventsMissingDates:
        return WarningAmberOutlined;
      case EventsManagementTreeViewNodeIdEnum.applications:
        return AppsOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsMissingData:
        return WarningAmberOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsComingSoon:
        return AccessTimeOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsOpen:
        return OpenInNewOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsClosed:
        return LockOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsPending:
        return HelpOutlineOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsWaitListed:
        return PendingActionsOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsDenied:
        return CancelOutlined;
      case EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog:
        return DynamicFeedOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsMissingData:
        return WarningAmberOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsInsurance:
        return PolicyOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue:
        return PaymentOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsEventDates:
        return EventNoteOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards:
        return AccountCircleOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar:
        return DateRangeOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList:
        return ListAltOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList:
        return ListAltOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar:
        return EventNoteOutlined;
      case EventsManagementTreeViewNodeIdEnum.logisticsLodging:
        return HotelOutlined;
      default:
        return NotInterested;
    }
  }
};

export type EventsManagementTreeItemConfig = {
  nodeId: EventsManagementTreeViewNodeIdEnum;
  children?: EventsManagementTreeItemConfig[];
};

export const eventsManagementTreeViewTerminalNodeIds = [
  EventsManagementTreeViewNodeIdEnum.eventsProSearch,
  EventsManagementTreeViewNodeIdEnum.eventsCalendar,
  EventsManagementTreeViewNodeIdEnum.eventsDuplicate,
  EventsManagementTreeViewNodeIdEnum.eventsMissingDates,

  EventsManagementTreeViewNodeIdEnum.applicationsMissingData,
  EventsManagementTreeViewNodeIdEnum.applicationsComingSoon,
  EventsManagementTreeViewNodeIdEnum.applicationsOpen,
  EventsManagementTreeViewNodeIdEnum.applicationsClosed,
  EventsManagementTreeViewNodeIdEnum.applicationsPending,
  EventsManagementTreeViewNodeIdEnum.applicationsWaitListed,
  EventsManagementTreeViewNodeIdEnum.applicationsDenied,
  EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog,

  EventsManagementTreeViewNodeIdEnum.logisticsMissingData,
  EventsManagementTreeViewNodeIdEnum.logisticsInsurance,
  EventsManagementTreeViewNodeIdEnum.logisticsPaymentsDue,
  EventsManagementTreeViewNodeIdEnum.logisticsEventDates,
  EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList,
  EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar,
  EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards,
  EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList,
  EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar,
  EventsManagementTreeViewNodeIdEnum.logisticsLodging,
] as const;

export type EventsManagementTreeViewTerminalNodeId = typeof eventsManagementTreeViewTerminalNodeIds[number];

export const eventsManagementTreeViewNoBadgeNodeIds = [
  EventsManagementTreeViewNodeIdEnum.eventsProSearch,
  EventsManagementTreeViewNodeIdEnum.eventsCalendar,
  EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog,
  EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleCalendar,
  EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards,
  EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar,
];