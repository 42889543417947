import { Map } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

export type EmbeddedMapProps = {
  placeId: string | undefined;
  emptyMessage?: string;
};

export const EmbeddedMap: React.FC<EmbeddedMapProps> = ({
  placeId,
  emptyMessage = 'Update location for Google Maps display'
}) => {

  if (placeId) {
    return (
      <iframe
        title="home"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB-0fyN08n13Ed5fK78hf9k-j8RjIvZcLI&q=place_id:${placeId}`}
        width="100%"
        height="300"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        style={{ border: 'none' }}
      />
    );
  }

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        width: '100%',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        gap: theme.spacing(2)
      })}
    >
      <Map fontSize="large" color="primary" />
      <Typography color="text.secondary">{emptyMessage}</Typography>
    </Box>
  );
};
