import { useMemo, useState } from 'react';
import { useLocalStorage } from 'hooks';
import { ShowTheseFieldsModal } from './ShowTheseFieldsModal.modal';
import { ShowTheseFieldsActionContent } from './ShowTheseFieldsActionContent.component';
import { ShowTheseFieldsConfig } from './types';
import { EnumHelpers } from 'helpers/enums/types';

export type useShowTheseFieldsProps<Field extends string, Group extends string, Resource, Extra = undefined> = {
  id: string;
  config: ShowTheseFieldsConfig<Field, Group, Resource, Extra>[];
  groupEnumHelpers?: EnumHelpers<Group>;
  defaultShowTheseFields?: Field[];
};

export const useShowTheseFields = <Field extends string, Group extends string, Resource extends unknown, Extra extends unknown = undefined>({ id, defaultShowTheseFields, config, groupEnumHelpers }: useShowTheseFieldsProps<Field, Group, Resource, Extra>) => {
  const showTheseFieldsLocalStorageService = useLocalStorage<Field[]>(`${id}ShowTheseFields`);
  const [ showTheseFields, setShowTheseFields ] = useState<Field[]>(showTheseFieldsLocalStorageService.getResource(defaultShowTheseFields ?? []));
  const [ showModal, setShowModal ] = useState<boolean>(false);

  const modal = useMemo(() => {
    if (!showModal) {
      return null;
    }

    const onShowTheseFieldsChange = (fields: Field[]) => {
      showTheseFieldsLocalStorageService.setResource(fields);
      setShowTheseFields(fields);
    };

    return (
      <ShowTheseFieldsModal
        config={config}
        groupEnumHelpers={groupEnumHelpers}
        showTheseFields={showTheseFields}
        onShowTheseFieldsChange={onShowTheseFieldsChange}
        onClose={() => setShowModal(false)}
      />
    );
  }, [ config, groupEnumHelpers, showModal, showTheseFields, showTheseFieldsLocalStorageService ]);

  const sidebarActionConfig = useMemo(() => ({
    label: 'Show These Fields',
    content: <ShowTheseFieldsActionContent showTheseFields={showTheseFields} config={config} />,
    onClickMore: () => setShowModal(true),
  }), [ config, showTheseFields ]);

  const onResetShowTheseFields = () => {
    setShowTheseFields(defaultShowTheseFields ?? []);
  };

  return {
    showTheseFields,
    modal,
    sidebarActionConfig,
    onResetShowTheseFields
  };
};