import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventResponse, createEventDateImage } from 'api/actions';
import { EventDateImageTypeEnum } from 'api/resources';
import { EventDateAddPhotoForm, UpdateResourceModal, useAlertSnackbar, useEventContext } from 'components';
import { Formik } from 'formik';
import { eventDateEnumHelpers } from 'helpers';
import React from 'react';
import { FirebaseService } from 'services';
import { SchemaOf, mixed, object } from 'yup';

export type EventDateImageUploadModalProps = {
  onClose: () => void;
  eventDate: GetEventResponse['data']['dates'][number];
  title?: string;
};

export type EventDateImageForm = {
  image: File | null;
  type: EventDateImageTypeEnum | null;
};

const validationSchema = (): SchemaOf<EventDateImageForm> => {
  return object({
    image: mixed<File>().required('File is required'),
    type: mixed<EventDateImageTypeEnum>().required('Photo type is requred').oneOf(eventDateEnumHelpers.imageType.enumValues),
  });
};

export const EventDateImageUploadModal: React.FC<EventDateImageUploadModalProps> = ({ eventDate, onClose, title }) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const { event, invalidateQueriesHandler } = useEventContext();
  const uploadMutation = useMutation({
    mutationFn: async (form: EventDateImageForm) => {
      if (!form.image) {
        throw new Error('File is required');
      }

      const path = `${event._id}/${eventDate._id}/${form.type}/${FirebaseService.convertFileName('')}`;
      const url = await FirebaseService.uploadFile(form.image, path);

      return createEventDateImage(eventDate._id, { url, type: form.type || EventDateImageTypeEnum.other  });
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      snackbar.success('Image uploaded');

      onClose();
    },
    onError: () => {
      snackbar.error('Image could not be uploaded');
    }
  });

  const initialValues: EventDateImageForm = {
    image: null,
    type: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => uploadMutation.mutateAsync(values)}
      validationSchema={validationSchema}
    >
      {formik => {
        return (
          <UpdateResourceModal
            open
            onClose={onClose}
            onSave={formik.handleSubmit}
            title={title ?? 'Add Photo'}
            loading={formik.isSubmitting}
          >
            <EventDateAddPhotoForm eventDate={eventDate} />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};
