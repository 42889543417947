import { DeleteResourceModal, ForbiddenModal, useAlertSnackbar, useEventContext } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { deleteEvent } from 'api/actions';

export type DeleteEventModalProps = {
  onClose: () => void;
};

export const DeleteEventModal: React.FC<DeleteEventModalProps> = props => {
  const { event } = useEventContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const deleteMutation = useMutation({
    mutationFn: () => deleteEvent(event._id),
    onSuccess: async () => {
      snackbar.success('Event deleted');
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);
      navigate(`/${ROUTING_CONFIG.events}`, { replace: true });
    },
    onError: () => {
      snackbar.error('Unable to delete event');
    }
  });

  if (event.salesCount) {
    return (
      <ForbiddenModal
        onClose={props.onClose}
        title="Cannot Delete Event"
        description="You cannot delete an event that has logged sales"
      />
    );
  }

  return (
    <DeleteResourceModal
      onClose={props.onClose}
      onDelete={deleteMutation.mutate}
      deleteButtonText="Delete Event"
      description="Deleting this event will remove all event dates and their associated staff"
      loading={deleteMutation.isLoading}
    />
  );
};
