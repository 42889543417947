import { TreeItem, TreeView } from '@mui/x-tree-view';
import { EventsManagementTreeItemConfig, EventsManagementTreeViewNodeIdEnum, eventsManagementTreeViewNoBadgeNodeIds, eventsManagementTreeViewNodeIdEnumHelpers } from '../helpers';
import { Box, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type EventsManagementTreeViewProps = {
  config: EventsManagementTreeItemConfig[];
  selected: EventsManagementTreeViewNodeIdEnum;
  onNodeSelect: (selected: EventsManagementTreeViewNodeIdEnum) => void;
  badgeNumbersHash: Record<EventsManagementTreeViewNodeIdEnum, number>;
};

const getParents = (currentParents: EventsManagementTreeViewNodeIdEnum[], nodeId: EventsManagementTreeViewNodeIdEnum, config: EventsManagementTreeItemConfig): EventsManagementTreeViewNodeIdEnum[] | null => {
  if (nodeId === config.nodeId) {
    return currentParents;
  }

  if (!config.children) {
    return null;
  }

  const res = config.children.reduce((r, child) => {
    const res = getParents([ ...currentParents, config.nodeId ], nodeId, child);

    if (res) {
      return res;
    }

    return r;
  }, null);

  return res;
};

export const EventsManagementTreeView: React.FC<EventsManagementTreeViewProps> = props => {
  const getNodeParents = useCallback((nodeId: EventsManagementTreeViewNodeIdEnum): EventsManagementTreeViewNodeIdEnum[] => {
    const res = props.config.reduce((r, config) => {
      const res = getParents([], nodeId, config);

      if (res) {
        return res;
      }

      return r;
    }, []);

    return res ?? [];
  }, [ props.config ]);

  const selectedNodeParents = useMemo(() => getNodeParents(props.selected), [ getNodeParents, props.selected ]);
  const [ expanded, setExpanded ] = useState<EventsManagementTreeViewNodeIdEnum[]>(selectedNodeParents);

  useEffect(() => {
    setExpanded(p => {
      const selectedNodeParentsNotExpanded = selectedNodeParents.filter(nodeId => !p.includes(nodeId));

      return [ ...p, ...selectedNodeParentsNotExpanded ];
    });
  }, [ selectedNodeParents ]);

  const onNodeToggle = (_: React.SyntheticEvent<Element, Event>, nodeIds: string[]) => {
    const expanded = nodeIds as EventsManagementTreeViewNodeIdEnum[];
    const selectedNodeParents = getNodeParents(props.selected);
    const selectedNodeParentsNotExpanded = selectedNodeParents.filter(nodeId => !expanded.includes(nodeId));

    expanded.push(...selectedNodeParentsNotExpanded);

    setExpanded(expanded);
  };

  return (
    <TreeView
      selected={props.selected}
      onNodeSelect={(_, nodeId) => {
        props.onNodeSelect(nodeId as EventsManagementTreeViewNodeIdEnum);
      }}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeToggle={onNodeToggle}
    >
      {props.config.map(config => <EventsManagementTreeItem {...config} key={config.nodeId} disabled={selectedNodeParents} expanded={expanded} badgeNumbersHash={props.badgeNumbersHash}/>)}
    </TreeView>
  );
};

export type EventsManagementTreeItemProps = EventsManagementTreeItemConfig & {
  expanded: EventsManagementTreeViewNodeIdEnum[];
  disabled: EventsManagementTreeViewNodeIdEnum[];
  badgeNumbersHash: Record<EventsManagementTreeViewNodeIdEnum, number>;
};

const EventsManagementTreeItem: React.FC<EventsManagementTreeItemProps> = props => {
  const { expanded, disabled, badgeNumbersHash } = props;
  const isDisabled = disabled.includes(props.nodeId);
  const isExpanded = expanded.includes(props.nodeId);
  const Icon = props.children ? null : eventsManagementTreeViewNodeIdEnumHelpers.getIcon(props.nodeId);
  const label = eventsManagementTreeViewNodeIdEnumHelpers.getLabel(props.nodeId);

  const badgeNumber = badgeNumbersHash[props.nodeId];
  let badgeTitle: string | null = null;

  if (badgeNumber && !eventsManagementTreeViewNoBadgeNodeIds.includes(props.nodeId)) {
    if (badgeNumber > 99) {
      badgeTitle = '99+';
    } else {
      badgeTitle = badgeNumber.toString();
    }
  };

  return (
    <TreeItem
      nodeId={props.nodeId}
      label={(
        <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
          <Box display="flex" alignItems="center" gap={1}>
            {Icon && <Icon fontSize="small" />}
            <Typography>{label}</Typography>
          </Box>
          <Typography
            variant="caption"
            fontWeight={500}
            sx={theme => ({
              display: 'flex',
              justifyContent: 'center',
              opacity: isExpanded ? 0 : 1,
              transition: 'opacity 0.5s ease-in-out',
              pointerEvents: isExpanded ? 'none' : 'auto',
              visibility: isExpanded ? 'hidden' : 'visible',
              color: theme.palette.common.white,
              bgcolor: theme.palette.primary.main,
              borderRadius: 2,
              width: 28,
              mr: -1,
            })}
          >
            {badgeTitle}
          </Typography>
        </Box>
      )}
      collapseIcon={isDisabled && <ExpandMore color="disabled"/>}
    >
      {props.children?.map(config => <EventsManagementTreeItem {...config} key={config.nodeId} expanded={expanded} disabled={disabled} badgeNumbersHash={badgeNumbersHash} />)}
    </TreeItem>
  );
};