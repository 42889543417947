import { GridColDef } from '@mui/x-data-grid';
import { GetEventOrganizersResponse } from 'api/actions';
import { ActionCell, Table } from 'components';
import { useTableLocalStorage } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export type TEventOrganizersTableRow = GetEventOrganizersResponse['data'][number];

export type EventOrganizersTableProps = {
  eventOrganizers: GetEventOrganizersResponse['data'];
  loading: boolean;
};

const getEventOrganizersTableColumns = (navigate: (id: string | number) => void): GridColDef<TEventOrganizersTableRow>[] => [
  {
    field: 'action',
    headerName: '',
    disableExport: true,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    hideable: false,
    width: 30,
    renderCell: ({ id }) => <ActionCell onActionClick={() => navigate(id)} />,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 300,
  },
  {
    field: 'mailingAddress',
    headerName: 'Mailing Address',
    width: 400,
  },
];

export const EventOrganizersTable: React.FC<EventOrganizersTableProps> = ({
  eventOrganizers,
  loading,
}) => {

  const navigate = useNavigate();
  const columns = getEventOrganizersTableColumns(navigate);

  const { initialTableState, tableStateChange } = useTableLocalStorage({
    id: 'event-organizer-table',
    initialVisible: [ 'name', 'website', 'mailingAddress' ],
    columns,
  });

  return (
    <Table
      {...tableStateChange}
      initialState={{
        columns: {
          columnVisibilityModel: initialTableState.columnVisibility,
        },
        filter: {
          filterModel: initialTableState.filter,
        },
        sorting: {
          sortModel: initialTableState.sort,
        },
      }}
      getRowId={(x) => x._id}
      columns={columns}
      rows={eventOrganizers}
      loading={loading}
      disableRowSelectionOnClick
    />
  );
};
