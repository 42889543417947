import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Box } from '@mui/material';
import { EventDateSetupBreakdownTypeEnum } from 'api/resources';
import { FormTimePicker, Select, TextInputWithAuto } from 'components';
import { useFormikContext } from 'formik';
import { eventDateEnumHelpers, getEventDateAutoSetupTime, getSelectOptionsFromEnumHelper } from 'helpers';
import React, { useEffect, useMemo } from 'react';
import { ResourceForm } from 'types';

export type EventDateSetupFormProps = {
  staffNeeded?: number;
};

export const EventDateSetupForm: React.FC<EventDateSetupFormProps> = ({ staffNeeded }) => {
  const { values, setFieldValue } = useFormikContext<ResourceForm['eventDate']>();
  const autoSetupTime = getEventDateAutoSetupTime(values.setupType, staffNeeded);
  const setupTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventDateEnumHelpers.setupBreakdownType), []);

  useEffect(() => {
    if (values.setupType === EventDateSetupBreakdownTypeEnum.soft) {
      setFieldValue('arriveAsEarlyAs', '');
      setFieldValue('noVehiclesAllowedAfter', '');
      setFieldValue('allVehiclesMustBeRemovedBy', '');
    }
  }, [ setFieldValue, values.setupType ]);

  return (
    <Box mb={-5}>
      <Select
        name="setupType"
        label="Setup Type"
        fullWidth
        options={setupTypeOptions}
      />
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
            marginBlockEnd: 0
          },
        }}
      >
        {values.setupType === EventDateSetupBreakdownTypeEnum.hard && (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <FormTimePicker
                  label="Arrive as early as"
                  name="arriveAsEarlyAs"
                  sx={{ mt: -2 }}
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <FormTimePicker
                  label="No vehicles allowed after"
                  name="noVehiclesAllowedAfter"
                  sx={{ mt: -2 }}
                />
              </TimelineContent>
            </TimelineItem>
          </>
        )}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ mb: 3 }}>
            <TextInputWithAuto
              type="number"
              name="displaySetupInMinutes"
              label="Display Setup (minutes)"
              fullWidth
              autoValue={autoSetupTime}
              sx={{ mt: -2 }}
            />
          </TimelineContent>
        </TimelineItem>
        {values.setupType === EventDateSetupBreakdownTypeEnum.hard && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <FormTimePicker
                label="All vehiles must be removed by"
                name="allVehiclesMustBeRemovedBy"
                sx={{ mt: -2 }}
              />
            </TimelineContent>
          </TimelineItem>
        )}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" />
          </TimelineSeparator>
          <TimelineContent>
            <FormTimePicker
              name="setupEndTime"
              label="Complete setup by"
              sx={{ mt: -2 }}
            />
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};
