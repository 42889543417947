import { InventoryBatchStatusEnum } from 'api/resources';
import { InventoryBatchStatusChip } from 'components';

export type InventoryBatchStatusCellProps = {
  value?: InventoryBatchStatusEnum;
};

export const InventoryBatchStatusCell: React.FC<InventoryBatchStatusCellProps> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <InventoryBatchStatusChip size="small" value={value} />;
};