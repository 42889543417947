import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'types';

export type ColorCodeCellProps = {
  color: ThemePalette;
  label: string;
  caption?: string;
  solid?: boolean;
};

export const ColorCodeCell: React.FC<ColorCodeCellProps> = ({ color, label, caption, solid }) => {
  return (
    <Box
      p={1}
      position="relative"
      width="100%"
      height="100%"
      display="flex"
      alignItems={!!caption ? 'flex-start' : 'center'}
      justifyContent="center"
      bgcolor={theme => solid ? theme.palette[color].main : theme.palette[color].background}
      color={theme => solid ? theme.palette[color].contrastText : theme.palette[color].main}
      fontWeight={500}
    >
      {label}
      <Typography
        component="div"
        variant="caption"
        position="absolute"
        bottom={0}
        left="50%"
        sx={{ transform: 'translateX(-50%)' }} // Adjust for the centering
      >
        {caption}
      </Typography>
    </Box>
  );
};