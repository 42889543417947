import { InventoryEdits, InventoryRow, InventoryTableSettingsModal, InventoryTableShowStockMetaInput, Table, useAlertSnackbar, useInventoryTable } from 'components';
import { useProductsPageContext } from 'contexts';
import { useMemo, useState } from 'react';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { useNavigate } from 'react-router-dom';
import { getInitialTransferConfig, getTeamsStockMetaConfig, prepareInitialInventoryEdits } from '../helpers';
import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { GridRowClassNameParams } from '@mui/x-data-grid';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum } from 'api/resources';
import { GetInventoryBatchResponse, UpdateInventoryBatchInput, updateInventoryBatch } from 'api/actions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { AxiosError } from 'axios';

const enum ApproveOrReviseEnum  {
  approve = 'approve',
  revise = 'revise',
}

type InventoryReviews = {
  [productId: string]: ApproveOrReviseEnum;
};

const getEditReviewQuantity = (batch: GetInventoryBatchResponse['data'], update: GetInventoryBatchResponse['data']['updates'][number], warehouseTeamId: string, inventoryEdits: InventoryEdits) => {
  const edit = inventoryEdits[update.product][batch.type === InventoryBatchTypeEnum.overstockPicking ? warehouseTeamId : batch.team._id];
  const reviewQuantity = edit.value !== update.quantity ? edit.value : undefined;

  return reviewQuantity;
};

const prepareEditsToSave = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string, inventoryEdits: InventoryEdits) => {
  return batch.updates
    .filter(update => {
      const reviewQuantity = getEditReviewQuantity(batch, update, warehouseTeamId, inventoryEdits);

      return reviewQuantity !== 0;
    })
    .map((update) => {
      const reviewQuantity = getEditReviewQuantity(batch, update, warehouseTeamId, inventoryEdits);

      return {
        ...update,
        reviewQuantity: reviewQuantity,
      };
    });
};

export const Review: React.FC = () => {
  const navigate = useNavigate();
  const { teams, getProductsSortedAlphabetically, loading } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const filteredProducts = useMemo(() => products.filter(product => inventoryBatch.updates.some(update => update.product === product._id)), [ inventoryBatch.updates, products ]);
  const [ inventoryReviews, setInventoryReviews ] = useState<InventoryReviews>({});
  const getProductReview = (productId: string): string | undefined => inventoryReviews[productId];
  const getInitialInventoryUpdate = (productId: string) => inventoryBatch.updates.find(update => update.product === productId);
  const reviewsCount = Object.values(inventoryReviews).length;
  const snackbar = useAlertSnackbar();

  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);
  const teamsStockMetaConfig = useMemo(() => getTeamsStockMetaConfig(initialTransferConfig, inventoryBatch.type), [ initialTransferConfig, inventoryBatch.type ]);

  const {
    inventoryTableProps,
    editModal,
    setSettingsModalOpen,
    settingsModalOpen,
    showStockMeta,
    setShowStockMeta,
    hasErrors,
    inventoryEdits,
    setSaveLoading,
    getInputErrorsForProduct
  } = useInventoryTable({
    products: filteredProducts,
    teams,
    initialTransferConfig,
    initialTeamIds: [ inventoryBatch.team._id ],
    initialInventoryEdits: prepareInitialInventoryEdits(inventoryBatch, warehouseTeamId),
    teamsStockMetaConfig,
    getRowInputsDisabled: (row) => {
      const review = getProductReview(row._id);

      return review !== ApproveOrReviseEnum.revise;
    },
    hideReset: true,
    hideSaveColumn: true,
    overrideToolbarActions: (
      <Box mr={1}>
        <Typography><Typography component="span" fontSize="small">Updates Reviewed: </Typography>{reviewsCount} / {filteredProducts.length}</Typography>
      </Box>
    ),
    getCellInputError: ({ stock, updateQty }) => {
      if (updateQty === undefined) {
        return 'Please enter a value';
      }

      const isRevise = getProductReview(stock.product) === ApproveOrReviseEnum.revise;

      if (isRevise && updateQty === getInitialInventoryUpdate(stock.product)?.quantity) {
        return 'Please revise this value';
      }
    },
    allowZeroInTransferMode: true,
  });
  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
    },
    onError: (e: AxiosError) => {
      setSaveLoading(false);
      snackbar.error(e.response?.data?.error || 'Failed to update batch');
    }
  });

  const onInventoryReview = (productId: string, review: ApproveOrReviseEnum | null) => {
    setInventoryReviews(p => {
      if (!review) {
        const { [productId]: _removed, ...rest } = p;

        return rest;
      }

      return { ...p, [productId]: review };
    });
  };

  const notFinished = reviewsCount !== filteredProducts.length;

  const onComplete = () => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareEditsToSave(inventoryBatch, warehouseTeamId, inventoryEdits),
      status: InventoryBatchStatusEnum.closed,
    });
  };

  return (
    <InventoryBatchLayout
      actions={(
        <>
          <Button variant="outlined" onClick={() => navigate(`/${ROUTING_CONFIG.inventoryBatches}`)} disabled={updateMutation.isLoading}>Cancel Review</Button>
          <Button variant="contained" disabled={hasErrors || notFinished || updateMutation.isLoading} onClick={onComplete}>Complete and Update Stock</Button>
        </>
      )}
    >
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          showStockMetaInput={<InventoryTableShowStockMetaInput showStockMeta={showStockMeta} setShowStockMeta={setShowStockMeta} />}
        />
      )}
      {editModal}
      <Table
        {...inventoryTableProps}
        columns={[
          ...inventoryTableProps.columns,
          {
            field: 'review',
            headerName: '',
            minWidth: 300,
            width: 300,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => {
              const review = getProductReview(row._id);
              const edit = getInitialInventoryUpdate(row._id)?.quantity;
              const currentEdit = inventoryEdits[row._id]?.[inventoryBatch.team._id]?.value;
              const revised = currentEdit !== edit && currentEdit ? `(${currentEdit})` : null;

              return (
                <ToggleButtonGroup disabled={updateMutation.isLoading} value={review} exclusive onChange={(_e, review: ApproveOrReviseEnum | null) => !revised && onInventoryReview(row._id, review)} fullWidth size="large">
                  <ToggleButton disableRipple value="approve" color="success" disabled={!!revised}>Approve ({edit})</ToggleButton>
                  <ToggleButton disableRipple value="revise" color="warning">Revise {revised}</ToggleButton>
                </ToggleButtonGroup>
              );
            }
          }
        ]}
        pinnedColumns={{
          ...inventoryTableProps.pinnedColumns,
          right: [ 'review' ],
        }}
        getRowClassName={({ row }: GridRowClassNameParams<InventoryRow>) => {
          if (getInputErrorsForProduct(row)) {
            return 'rowHighlight rowHasErrors';
          }

          if (getProductReview(row._id)) {
            return 'rowHighlight';
          }

          return '';
        }}
        loading={loading}
      />
    </InventoryBatchLayout>
  );
};