import { Box, Toolbar, Typography } from '@mui/material';
import { AppBar } from './Appbar.component';
import { AppbarProfileContent } from './AppbarProfileContent.component';
import { logoImg } from 'assets';
import { REACT_APP_ENVIRONMENT } from 'config/environment.config';

export type LightAppbarProps = {
  leftAction?: React.ReactNode;
  header?: string;
};

export const LightAppbar: React.FC<LightAppbarProps> = (props) => {
  return (
    <AppBar variant="elevation">
      <Toolbar>
        <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" flex={1}>
            {props.leftAction && props.leftAction}
            {REACT_APP_ENVIRONMENT === 'staging' && (
              <Typography color="primary.contrastText" fontWeight={700} variant="h5" ml={2}>
                STAGING ENVIRONMENT
              </Typography>
            )}
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Box
              component="img"
              sx={{ height: (theme) => theme.spacing(5.5) }}
              src={logoImg}
            />
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <AppbarProfileContent />
          </Box>
        </Box>
        {props.header && (
          <Typography variant="h5" fontWeight={500} ml={2}>
            Log Sale
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};
