import { Grid } from '@mui/material';
import { useEventSalesAnalyticsData } from 'queries';
import { EventSalesYearsChart } from './EventSalesYearsChart.component';
import { EventsSalesYearsTimelineChart } from './EventsSalesYearsTimelineChart.component';
import { EventsSalesDateCompareChart } from './EventsSalesDateCompareChart.component';
import { useEventContext } from 'components';

export const EventSalesCharts = () => {
  const { event } = useEventContext();
  const { data = { sales: [], events: [] }, isLoading } = useEventSalesAnalyticsData(event._id);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6}>
        <EventSalesYearsChart data={data} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <EventsSalesYearsTimelineChart data={data} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12}>
        <EventsSalesDateCompareChart data={data} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};
