import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventResponse, deleteEventDate } from 'api/actions';
import { DeleteResourceModal } from 'components';
import { QUERY_KEY } from 'queries/query-keys';
import { useNavigate } from 'react-router-dom';

export type DeleteEventDateModalType = {
  eventId: string;
  eventDate: GetEventResponse['data']['dates'][number];
  eventDateSalesCount: number;
  onClose: () => void;
};

export const DeleteEventDateModal: React.FC<DeleteEventDateModalType> = ({
  eventId,
  eventDate,
  onClose,
  eventDateSalesCount
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: deleteEventDate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(eventId) });
      navigate(`/events/${eventDate.event}`);
    }
  });
  const eventDateId = eventDate._id;

  if (eventDateSalesCount) {
    return (
      <DeleteResourceModal
        onClose={onClose}
        title="Cannot Delete Event Date"
        description="You cannot delete an event date that has logged sales"
      />
    );
  }

  return (
    <DeleteResourceModal
      onClose={onClose}
      onDelete={() => deleteMutation.mutate(eventDateId)}
      deleteButtonText="Delete Event Date"
      description="Deleting this event date will also remove all associated staff"
      loading={deleteMutation.isLoading}
    />
  );
};
