import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog, DialogProps, IconButton, Slide, SlideProps,
} from '@mui/material';
import { useMemo } from 'react';

export type ModalType = DialogProps & {
  autoHeight?: boolean;
  topLeftIcon?: 'close' | React.ReactNode;
  onClose: () => void;
  onCloseIcon?: () => void;
};

const Transition: React.FC<SlideProps> = (props) => {
  return <Slide direction="up" {...props} />;
};

export const Modal: React.FC<ModalType> = ({
  children, autoHeight = false, topLeftIcon, ...props
}) => {
  let height: string | undefined = autoHeight ? 'auto' : '550px';

  if (props.fullScreen) height = undefined;

  const icon = useMemo(() => {
    if (!topLeftIcon) return null;

    if (topLeftIcon === 'close') {
      return (
        <IconButton
          onClick={() => {
            props.onClose();
            props.onCloseIcon?.();
          }}
        >
          <Close />
        </IconButton>
      );
    }
    return topLeftIcon;
  }, [ props, topLeftIcon ]);

  return (
    <Dialog
      TransitionComponent={Transition}
      {...props}
      PaperProps={{
        sx: {
          maxWidth: props.fullScreen ? undefined : undefined,
          width: '100%',
          height,
          position: 'relative',
          ...props.PaperProps?.sx,
        },
      }}
    >
      {icon && (
        <Box
          sx={(theme) => ({
            top: theme.spacing(1.5),
            right: theme.spacing(2),
            position: 'absolute',
          })}
        >
          {icon}
        </Box>
      )}
      {children}
    </Dialog>
  );
};
