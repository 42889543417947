import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { ReactNode } from 'react';

export type AutocompleteCellProps = {options: ReactNode[]} & GridRenderEditCellParams;

export const AutocompleteCell: React.FC<AutocompleteCellProps> = ({ options, ...props }) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (newValue: string) => {

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      filterOptions={(options, _) => options}
      options={options}
      value={value}
      onChange={(e, newValue) => {
        handleValueChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
        />
      )}
      fullWidth
    />
  );
};
