import { GetTeamResponse } from 'api/actions';
import { Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useTeam } from 'queries';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

type UseTeamOutletContext = {
  team: GetTeamResponse['data'];
  loading: boolean;
};

export const useTeamOutletContext = () => useOutletContext<UseTeamOutletContext>();

const TeamBaseRaw: React.FC<{teamId: string}> = ({ teamId }) => {
  const { data: team, isInitialLoading: loading } = useTeam(teamId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!team) {
    return <Navigate to={`/${ROUTING_CONFIG.teams}`} replace />;
  }

  return <Outlet context={{ team, loading }} />;
};

export const TeamBase = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={`/${ROUTING_CONFIG.teams}`} replace />;
  }

  return <TeamBaseRaw teamId={id} />;
};