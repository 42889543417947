import React from 'react';
import { Badge, Box, Divider, ListItemButton, Stack, Tooltip, alpha } from '@mui/material';
import { EventsManagementTreeItemConfig, EventsManagementTreeViewNodeIdEnum, eventsManagementTreeViewNoBadgeNodeIds, eventsManagementTreeViewNodeIdEnumHelpers } from '../helpers';

export type EventsManagementTreeViewContractSidebarProps = {
  config: EventsManagementTreeItemConfig[];
  selected: EventsManagementTreeViewNodeIdEnum;
  onSelect: (selected: EventsManagementTreeViewNodeIdEnum) => void;
  badgeNumbersHash: Record<EventsManagementTreeViewNodeIdEnum, number>;
};

export const EventsManagementTreeViewContractSidebar: React.FC<EventsManagementTreeViewContractSidebarProps> = props => {
  return (
    <Stack>
      {props.config.map((config, i) => {
        const isLast = i === props.config.length - 1 || props.config[i + 1].children;

        return (
          <React.Fragment key={config.nodeId}>
            <EventsManagementTreeViewContractSidebarItem {...props} config={config}/>
            {isLast && <Divider />}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

type EventsManagementTreeViewContractSidebarItemProps = Omit<EventsManagementTreeViewContractSidebarProps, 'config'> & {
  config: EventsManagementTreeItemConfig;
};

const EventsManagementTreeViewContractSidebarItem: React.FC<EventsManagementTreeViewContractSidebarItemProps> = ({ config, ...props }) => {
  const { selected, onSelect, badgeNumbersHash } = props;

  if (!config.children) {
    const Icon = eventsManagementTreeViewNodeIdEnumHelpers.getIcon(config.nodeId);
    const badgeNumber = badgeNumbersHash[config.nodeId];

    let badgeTitle = '';

    if (badgeNumber && !eventsManagementTreeViewNoBadgeNodeIds.includes(config.nodeId)) {
      if (badgeNumber > 99) {
        badgeTitle = '99+';
      }

      badgeTitle = badgeNumber.toString();
    };

    return Icon && (
      <Tooltip title={eventsManagementTreeViewNodeIdEnumHelpers.getFullLabel(config.nodeId)} placement="right">
        <ListItemButton
          sx={theme => ({
            px: -4,
            background: selected === config.nodeId ? alpha(theme.palette.primary.main, 0.1) : undefined,
            color: selected === config.nodeId ? undefined : theme.palette.common.black,
            '&:hover': {
              background: theme.palette.background.default,
            },
          })}
          onClick={() => onSelect(config.nodeId)}
        >
          <Box display="flex" gap={1} alignItems="flex-start">
            <Icon fontSize="small" />
            {badgeTitle && <Badge badgeContent={badgeTitle} color="primary">
              {null}
            </Badge>}
          </Box>
        </ListItemButton>
      </Tooltip>
    );
  }

  return (
    <>
      {config.children.map(config => <EventsManagementTreeViewContractSidebarItem {...props} config={config} key={config.nodeId} />)}
    </>
  );
};