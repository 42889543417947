import { Box, BoxProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EventDateListItem, EventDateTimePeriodOptionType, useEventContext } from 'components';
import { EventDateTimePeriodType } from 'types';
import { getEventDatesFilteredByTimePeriod } from 'helpers';

export type EventSalesDatesListType = {
  onEventDateClick: (dateId: string) => void;
  selectedDateId: string | null;
  datesFilter?: EventDateTimePeriodOptionType | EventDateTimePeriodType[];
  boxProps?: BoxProps;
  emptyMessage?: string;
};

export const EventSalesDatesList: React.FC<EventSalesDatesListType> = ({
  onEventDateClick,
  selectedDateId,
  datesFilter = 'past',
  emptyMessage = 'No Dates',
  boxProps,
}) => {
  const { event } = useEventContext();
  const filteredDates = getEventDatesFilteredByTimePeriod(event.dates, datesFilter);

  const hasDates = !!filteredDates.length;

  if (!hasDates) {
    return <Typography component="i">{emptyMessage}</Typography>;
  }

  return (
    <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap="1rem" alignItems="start" {...boxProps}>
      {filteredDates.map((eventDate) => {
        const isSelected = eventDate._id === selectedDateId;

        return (
          <EventDateListItem
            key={eventDate._id}
            data={{
              event,
              eventDate: eventDate,
              eventDates: event.dates,
            }}
            onClick={onEventDateClick}
            listItemButtonSx={isSelected ? {
              boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.primary.main}`,
            } : {
              boxShadow: `inset 0 0 0 1px ${grey[300]}`,
            }}
          />
        );
      })}
    </Box>
  );
};
