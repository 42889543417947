
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { AddStaffToEventDateInput, addStaffToEventDate } from 'api/actions';
import { EventDateStaffForm, UpdateResourceModal, useAlertSnackbar, useEventContext } from 'components';
import { EventDateStaffStatusEnum } from 'api/resources';
import { Formik } from 'formik';
import { DateService } from 'services';
import { FORMAT_DATE } from 'constants/format-date';
import { QUERY_KEY } from 'queries/query-keys';
import { getWorkdayTotal } from 'helpers/event-date-times';

export type AddStaffModalProps = {
  userId?: string;
  onSuccess: (userId: string) => void;
  onClose: () => void;
  invalidateQueriesHandler: (queryClient: QueryClient) => Promise<void>;
  isTeamLead?: boolean;
  eventDateId: string;
};

export const AddStaffModal: React.FC<AddStaffModalProps> = ({ onSuccess, onClose, eventDateId, isTeamLead, invalidateQueriesHandler, userId }) => {
  const { event } = useEventContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();

  const eventDate = event.dates.find(e => e._id === eventDateId);
  const workdayTotalInMinutes = eventDate ? getWorkdayTotal({ event, eventDate }).workdayTotalInMinutes : 0;
  const fullFormattedDate = eventDate ? DateService.dayjsTz(eventDate.dateAsUtc).format(FORMAT_DATE.standardFull) : 'Event Date';
  const addStaffMutation = useMutation({
    mutationFn: (input: AddStaffToEventDateInput) => addStaffToEventDate(eventDateId, { ...input, isTeamLead }),
    onSuccess: async (_, input: AddStaffToEventDateInput) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_DATE_ADD_STAFF_USER_OPTIONS(eventDateId) });
      await invalidateQueriesHandler(queryClient);
      snackbar.success(`Staff added to ${fullFormattedDate}`);
      onSuccess(input.user);
      onClose();
    },
    onError: () => {
      snackbar.error(`Unable to add staff to ${fullFormattedDate}`);
    }
  });


  const initialValues: AddStaffToEventDateInput = {
    user: userId ?? '',
    status: EventDateStaffStatusEnum.initial,
    isTeamLead: false,
  };

  return (
    <Formik
      onSubmit={(values) => addStaffMutation.mutateAsync(values)}
      initialValues={initialValues}
    >
      {(formik) => (
        <UpdateResourceModal
          open
          title="New Staff"
          onClose={onClose}
          onSave={formik.handleSubmit}
          loading={formik.isSubmitting}
        >
          <EventDateStaffForm eventDateId={eventDateId} workdayTotalInMinutes={workdayTotalInMinutes} />
        </UpdateResourceModal>
      )}
    </Formik>
  );
};
