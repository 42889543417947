import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Card, Box, Typography, Avatar, Divider, Collapse, ListItem, Stack, ListItemButton } from '@mui/material';
import { GetEventResponse } from 'api/actions';
import { UserAvailabilityEnum, UserEmployeeRoleEnum } from 'api/resources';
import { EventDateStaffStatusChangeButton } from 'components';
import { useCurrentUser } from 'contexts';
import { userEnumHelpers } from 'helpers';
import { useState, useMemo } from 'react';
import { DateService, getUserInitialsForAvatar } from 'services';

export type StaffCardProps = {
  eventDateId: string;
  workdayTotalInMinutes: number | null;
  staff: GetEventResponse['data']['dates'][number]['staff'][number];
  availability: UserAvailabilityEnum | null;
  disabled?: boolean;
};

export const STAFF_CARD_DIMENSIONS = { width: 300, height: 169 };

export const StaffCard: React.FC<StaffCardProps> = ({ eventDateId, workdayTotalInMinutes, staff, availability, disabled }) => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const [ viewingMore, setViewingMore ] = useState(false);

  const showPayRate = workdayTotalInMinutes && staff.user.payRate && isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  const employeeRoleLabel = useMemo(() => {
    if (staff.user.employeeRoles.includes(UserEmployeeRoleEnum.teamLead)) {
      return userEnumHelpers.employeeRole.getLabel(UserEmployeeRoleEnum.teamLead);
    }
    if (staff.user.employeeRoles.includes(UserEmployeeRoleEnum.teamMember)) {
      return userEnumHelpers.employeeRole.getLabel(UserEmployeeRoleEnum.teamMember);
    }
    if (staff.user.employeeRoles.includes(UserEmployeeRoleEnum.juniorTeamMember)) {
      return userEnumHelpers.employeeRole.getLabel(UserEmployeeRoleEnum.juniorTeamMember);
    }

    return userEnumHelpers.employeeRole.getLabel(staff.user.employeeRoles[0]);
  }, [ staff.user.employeeRoles ]);

  return (
    <Card variant="outlined" sx={{ borderRadius: 0, width: STAFF_CARD_DIMENSIONS.width }}>
      <Box height={21} bgcolor={theme => theme.palette[userEnumHelpers.staffStatus.getColor(staff.status)].main}>
        <Typography variant="body2" color={theme => theme.palette.common.white} fontWeight={500} textAlign="end" mr={1}>
          {userEnumHelpers.staffStatus.getLabel(staff.status)}
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', mb: 7 }}>
        <Avatar
          src={staff.user.profileImageUrl}
          sx={{
            width: 60,
            height: 60,
            border: '3px solid white',
            position: 'absolute',
            top: -11,
          }}
        >
          {getUserInitialsForAvatar(staff.user.name)}
        </Avatar>
        <Box position="absolute" top={1} left={70}>
          <Typography fontWeight={500}>{staff.user.name}</Typography>
          <Typography variant="body2" color="text.secondary">{employeeRoleLabel}&nbsp;{showPayRate && (<>(~ {workdayTotalInMinutes / 60 * (staff.user.payRate ?? 0)}$)</>)}</Typography>
        </Box>
      </Box>
      <Divider />
      <Collapse in={viewingMore} timeout="auto" unmountOnExit>
        <ListItem divider sx={{ position: 'static', backgroundColor: theme => theme.palette.primary.background }} dense>
          <Stack gap={1}>
            <Typography variant="body2" color="text.secondary" display="flex" alignItems="center">
              <Box width={80}>Availability</Box>
              <Typography variant="body2" color={theme => theme.palette[userEnumHelpers.availability.getColor(availability)].main} component="span" fontWeight={500}>{userEnumHelpers.availability.getLabel(availability)}</Typography>
            </Typography>
            <Typography variant="body2" color="text.secondary" display="flex" alignItems="center">
              <Box width={80}>Email</Box>
              <Typography variant="body2" color="initial" component="span" fontWeight={500}>{staff.user.email}</Typography>
            </Typography>
            {staff.user.phoneNumber && (
              <Typography variant="body2" color="text.secondary" display="flex" alignItems="center">
                <Box width={80}>Phone</Box>
                <Typography variant="body2" color="initial" component="span" fontWeight={500}>{staff.user.phoneNumber}</Typography>
              </Typography>
            )}
          </Stack>
        </ListItem>
      </Collapse>
      <ListItemButton dense sx={{ position: 'static', display: 'flex', justifyContent: 'center' }} onClick={() => setViewingMore(p => !p)} disableRipple divider>
        {viewingMore ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        <Typography fontSize="small">View {viewingMore ? 'Less' : 'More'} Info</Typography>
      </ListItemButton>
      <Box display="flex" mt={1} mx={1} gap={0.5}>
        <EventDateStaffStatusChangeButton eventDateId={eventDateId} staff={staff} disabled={disabled} />
      </Box>
      <Box display="flex" justifyContent="flex-end" mx={1}>
        <Typography variant="caption" color="text.secondary">Status updated: {DateService.dayjsTz(staff.updatedAt).format('MMM Do, YYYY h:mma')}</Typography>
      </Box>
    </Card>
  );
};