import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

type UseJumpNavConfigProps<T = string> = {
  initialValue: T;
  sectionIds: T[];
};

export const elementOnScreenPercentage = (element: HTMLElement | null) => {
  if (!element) {
    return 0;
  }
  const headerOffset = 0;
  const screenheight = window.innerHeight || 115;
  const rect = element.getBoundingClientRect();

  const visibleTop = Math.max(rect.top - headerOffset, 0);
  const visibleBottom = Math.min(rect.bottom - headerOffset, screenheight);
  const visibleHeight = visibleBottom - visibleTop;

  const percentageOnScreen = Math.round((visibleHeight / rect.height) * 100);

  return Math.max(percentageOnScreen, 0);
};


export const useJumpNavConfig = <T extends string>({ initialValue, sectionIds }: UseJumpNavConfigProps<T>) => {
  const [ navValue, setNavValue ] = useState<T>(initialValue);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const items = sectionIds.map(id => ({
        id,
        percentageOnScreen: elementOnScreenPercentage(document.getElementById(id))
      })).sort((a, b) => b.percentageOnScreen - a.percentageOnScreen);

      if(items.length) {
        setNavValue(items[0].id);
      }
    });

    document.addEventListener('scroll', handleScroll, true);
    document.dispatchEvent(new Event('scroll'));

    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return { navValue };
};