import { Box } from '@mui/material';
import { TextInput } from 'components';
import { FileUpload as FileUploadInput } from 'components/Inputs/FileUpload';
import { formSx } from 'styles';

export type FileUploadFormProps = {
  hideFileUpload?: boolean;
  disableTypeField?: boolean;
};

export const FileUploadForm: React.FC<FileUploadFormProps> = props => {
  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        label="File Name *"
        name="name"
        fullWidth
        trim
      />
      {!props.hideFileUpload && <FileUploadInput name="file" />}
    </Box>
  );
};
