import { SectionCard } from 'components';
import React, { useMemo } from 'react';
import { ProductPageSection, ProductPageSectionRowId } from '../types';
import { useProductOutletContext } from '../Product.base';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { genreEnumHelpers } from 'helpers/enums/genre-enums.helpers';
import { ProductSectionCardRowEditable } from '../components/ProductSectionCardRow.component';
import { updateProduct } from 'api/actions';
import { ProductGenresForm } from '../forms/ProductGenres.form';
import { array, object, string } from 'yup';

export const GenresSection = () => {
  const { product } = useProductOutletContext();
  const productGenres = product.genres.map(({ genre }) => genre._id);

  const content = useMemo(() => {
    if(product.genres.length) {
      return (
        <List sx={{
          counterReset: 'list-counter',
          '& li:before': {
            content: '"#" counter(list-counter)',
            counterIncrement: 'list-counter',
            marginRight: '0.5rem'
          },
        }}
        >
          {product.genres.map((genre, index) => {
            const GenreGroupIcon = genreEnumHelpers.group.getIcon(genre.genre.group);

            return (
              <ListItem key={index} disablePadding>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" borderLeft="2px solid" borderColor="primary.main" pl={1}>
                      <GenreGroupIcon fontSize="small" />
                      <Typography component="span" fontWeight={500}>
                        {genre.genre.title}
                      </Typography>
                      &nbsp;
                      <Typography component="span" fontSize="inherit">
                        ({genreEnumHelpers.group.getLabel(genre.genre.group)})
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}

        </List>
      );
    }

    return <Typography fontStyle="italic" color="text.secondary">No genres yet</Typography>;
  }, [ product.genres ]);

  return (
    <SectionCard title="Genres" id={ProductPageSection.GENRES}>
      <ProductSectionCardRowEditable
        title=""
        rowId={ProductPageSectionRowId.GENRES}
        formikProps={{
          initialValues: { genres: productGenres },
          onSubmit: (values) => updateProduct(product._id, { genres: values.genres.filter(value => value).map((genre, index) => ({ genre, order: index })) }),
          validationSchema: object({ genres: array(string().default('')).required() })
        }}
        form={<ProductGenresForm />}
      >
        {content}
      </ProductSectionCardRowEditable>
    </SectionCard>
  );
};
