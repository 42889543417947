import SimpleMDE, { SimpleMDEReactProps } from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import { ErrorMessage, useField } from 'formik';
import { Box, GlobalStyles } from '@mui/material';
import { useMemo, useState } from 'react';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';

export type MarkdownInputProps = Omit<SimpleMDEReactProps, 'value' | 'onChange' | 'options' | 'placeholder'> & {
  label?: string;
  name: string;
};

export const MarkdownInput: React.FC<MarkdownInputProps> = props => {
  const [ focus, setFocus ] = useState(false);
  const [ field, _, helpers ] = useField(props.name);
  const options = useMemo((): SimpleMDEReactProps['options'] => ({
    toolbar: [
      'bold',
      'italic',
      '|',
      'unordered-list',
      'ordered-list',
      'link',
      '|',
      'preview',
      'side-by-side',
      'fullscreen',
      '|',
      'guide'
    ],
    spellChecker: false,
  }), []);

  return (
    <>
      <GlobalStyles
        styles={{
          '.EasyMDEContainer': {
            '.editor-toolbar': {
              borderTop: 'none',
              borderRight: 'none',
              borderLeft: 'none',
              margin: 0,
              padding: 0,
            },
            '.editor-toolbar.fullscreen': {
              zIndex: FULL_SCREEN_Z_INDEX + 1
            },
            '.CodeMirror': {
              border: 'none',
              margin: 0,
              padding: 0,
            },
            '.CodeMirror-fullscreen': {
              zIndex: FULL_SCREEN_Z_INDEX + 1
            },
            '.editor-preview-side': {
              zIndex: FULL_SCREEN_Z_INDEX + 1
            }
          }
        }}
      />
      <Box
        sx={theme => ({
          borderRadius: 1,
          border: `${focus ? 2 : 1}px solid ${focus ? theme.palette.primary.main : theme.palette.grey[400]}`,
          p: 1,
          ':hover': focus ? {} : {
            border: `1px solid ${theme.palette.common.black}`,
          },
        })}
      >
        <SimpleMDE
          {...props}
          placeholder={props.label}
          value={field.value}
          onChange={(value) => helpers.setValue(value)}
          options={options}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Box>
      <ErrorMessage name={props.name} />
    </>
  );
};