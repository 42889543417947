import { Box, ToggleButton, TextField, FormHelperText } from '@mui/material';
import { TextInput, ToggleButtonGroup } from 'components';
import { Form, useFormikContext } from 'formik';
import { getSelectOptionsFromEnumHelper } from 'helpers';
import { productEnumHelpers } from 'helpers/enums/product-enums.helpers';
import React, { useMemo } from 'react';
import { formSx } from 'styles';
import { ProductPageSectionRowId, ProductSectionRowForm } from '../types';
import { Done } from '@mui/icons-material';
import { useProductOutletContext } from '../Product.base';

export const ProductBaselineForm = () => {
  const { product } = useProductOutletContext();
  const { values } = useFormikContext<ProductSectionRowForm[ProductPageSectionRowId.BASELINE]>();
  const options = useMemo(() => getSelectOptionsFromEnumHelper(productEnumHelpers.activeBaselineType), []);

  return (
    <Form>
      <Box sx={formSx.formGroup}>
        <ToggleButtonGroup label="Baseline Type" name="activeBaselineType" color="primary" exclusive fullWidth>
          {options.map(({ value, label }) => {
            const isSelected = values.activeBaselineType === value;

            return (
              <ToggleButton value={value} key={value}>
                <Done fontSize="small" color="primary" sx={{ visibility: isSelected ? 'initial' : 'hidden' }} />
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <Box display="flex" gap={1}>
          <Box flex={1}>
            <TextField
              fullWidth
              value={product.autoBaseline}
              label="Automatic Baseline"
              disabled
            />
            <FormHelperText>Calculated automatically based off product sales.</FormHelperText>
          </Box>
          <Box flex={1}>
            <TextInput
              fullWidth
              id="manualBaseline"
              name="manualBaseline"
              label="Manual Baseline"
              type="number"
            />
          </Box>
        </Box>
      </Box>
    </Form>
  );
};
