import { Box, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Checkbox, FormDateRangePicker } from 'components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { formSx } from 'styles';
import { EventResourceForm } from 'types';

export const ApplicationDateRangeForm: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<EventResourceForm['applicationDateRange']>();

  useEffect(() => {
    if (values.applicationOpenUntilFull) {
      setFieldValue('applicationDeadlineDate', null);
    }
    if (values.applicationIsOpen) {
      setFieldValue('applicationOpenDate', null);
    }
  }, [ setFieldValue, values.applicationIsOpen, values.applicationOpenUntilFull ]);

  return (
    <Box sx={formSx.formGroup}>
      <FormDateRangePicker
        name={{ start: 'applicationOpenDate', end: 'applicationDeadlineDate' }}
        localeText={{ start: 'Application Open Date', end: 'Application Deadline Date' }}
        isDisabled={{ start: values.applicationIsOpen, end: values.applicationOpenUntilFull }}
      />
      <FormControl>
        <FormControlLabel
          control={<Checkbox name="applicationIsOpen" />}
          label="Application is open"
        />
        <FormHelperText>Check if app. open date is unknown but it is already open</FormHelperText>
      </FormControl>
      <FormControlLabel
        control={<Checkbox name="applicationOpenUntilFull" />}
        label="Application is open until full"
      />
    </Box>
  );
};