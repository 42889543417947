import ReactDOM from 'react-dom/client';
import { Main } from './main';
import { registerChartPlugins } from 'services';
import { LicenseInfo } from '@mui/x-license-pro';

registerChartPlugins();
LicenseInfo.setLicenseKey('e124482c3449c9ce8539cdff01ecfe0fTz04MDQ0MixFPTE3MzQwMjA5ODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Main />);
