import { AirportShuttle, AttachMoneyOutlined, ColorLensOutlined, DirectionsRunOutlined, EventRepeat, FastfoodOutlined, FestivalOutlined, Group, Storefront, Thunderstorm, TimeToLeaveOutlined } from '@mui/icons-material';
import { GetEventFeedbackResponse } from 'api/actions/eventFeedback';
import { EventFeedback } from 'api/resources/eventFeedback';

type EventFeedbackIconField = keyof Omit<EventFeedback, '_id' | 'createdAt' | 'updatedAt' | 'user' | 'event' | 'nextYearRecommendations' | 'note'>;

export const getEventFeedbackIcon = (field: EventFeedbackIconField) => {
  switch (field) {
    case 'overall':
      return FestivalOutlined;
    case 'busyness':
      return DirectionsRunOutlined;
    case 'sales':
      return AttachMoneyOutlined;
    case 'food':
      return FastfoodOutlined;
    case 'artists':
      return ColorLensOutlined;
    case 'parking':
      return TimeToLeaveOutlined;
    case 'unloadPullUp':
      return AirportShuttle;
    case 'packUpPullUp':
      return AirportShuttle;
    case 'weatherAffectedSales':
      return Thunderstorm;
    case 'needMoreStaff':
      return Group;
    case 'doAgain':
      return EventRepeat;
    case 'twoSpaces':
      return Storefront;
  }
};

type SummativeEventFeedbackField = keyof Pick<EventFeedback, 'overall' | 'busyness' | 'sales' | 'food' | 'artists' | 'parking'>;
type SummativeEventFeedback = Record<SummativeEventFeedbackField, number>;
const summativeEventFeedbackFields: SummativeEventFeedbackField[] = [ 'overall', 'busyness', 'sales', 'food', 'artists', 'parking' ];

export const getSummativeEventFeedback = (feedback: GetEventFeedbackResponse['data']): SummativeEventFeedback => {
  return feedback.reduce((r1, feedbackItem, index) => {
    return summativeEventFeedbackFields.reduce((r2, field) => {
      return {
        ...r2,
        [field]: ((r1[field] ?? 0) * index + (feedbackItem[field] as number)) / (index + 1),
      };
    }, {} as SummativeEventFeedback);
  }, {} as SummativeEventFeedback);
};