import { Box, Card, Typography } from '@mui/material';
import { GetSalesReportDataInput } from 'api/actions';
import { SalePaymentEnum } from 'api/resources';
import { SalesAnalyticsTimelineChart } from 'components/AnalyticsChart';
import { saleEnumHelpers } from 'helpers';
import { useSalesReportData } from 'queries';
import React, { useState } from 'react';
import { DateService, currencyFormatter } from 'services';

export type SalesReportProps = Omit<GetSalesReportDataInput, 'timezone'> & {
  header?: string;
  endToday?: boolean;
};

const salesReportDataPlaceholder = {
  labels: [],
  data: [],
  paymentHash: saleEnumHelpers.salePayment.enumValues.reduce((prev, payment) => ({ ...prev, [payment]: { count: 0, revenue: 0 } }), {} as Record<SalePaymentEnum, { count: number; revenue: number }>),
  totalRevenue: 0,
  salesCount: 0,
  dateRange: { start: DateService.dayjs().toISOString(), end: DateService.dayjs().toISOString() },
};

export const SalesReport: React.FC<SalesReportProps> = props => {
  const [ timePeriod, setTimePeriod ] = useState(props.timePeriod);
  const timezone = DateService.dayjs.tz.guess();
  const { data: salesReportData = salesReportDataPlaceholder, isInitialLoading: loading } = useSalesReportData({ ...props, timePeriod, timezone });
  const { paymentHash, totalRevenue, salesCount } = salesReportData;

  return (
    <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box flexGrow={1}>
        <SalesAnalyticsTimelineChart
          {...salesReportData}
          loading={loading}
          endToday={props.endToday}
          header={props.header}
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1} minWidth="232px">
        <ReportCard
          label="Total"
          primary={salesCount}
          secondary={currencyFormatter.format(totalRevenue)}
        />
        <Box
          display={{ xs: 'grid', md: 'flex' }}
          gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: 'unset' }}
          flexDirection={{ md: 'column' }}
          gap={1}
          flex={Object.keys(paymentHash).length}
        >
          {Object.keys(paymentHash).map((payment: SalePaymentEnum) => {
            const label = saleEnumHelpers.salePayment.getLabel(payment);

            return <ReportCard key={payment} label={label} primary={paymentHash[payment].count} secondary={currencyFormatter.format(paymentHash[payment].revenue)} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

type ReportCardProps = {
  label: string;
  primary: string | number;
  secondary: string;
};

export const ReportCard: React.FC<ReportCardProps> = ({ label, primary, secondary }) => {

  return (
    <Box flex={1} display="flex">
      <Card
        variant="outlined"
        sx={{
          flex: 1,
          p: 2,
          py: 1.5,
          gap: 2,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '8px'
        }}
      >
        <Box bgcolor="primary.background" width="60px" height="60px" borderRadius="8px" display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight={500}>{primary}</Typography>
        </Box>
        <Box>
          <Typography color="text.secondary">{label}</Typography>
          <Typography fontSize="large">{secondary}</Typography>
        </Box>
      </Card>
    </Box>
  );
};
