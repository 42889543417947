import { MoreVert } from '@mui/icons-material';
import { Box, BoxProps, Container, ContainerProps, Divider, IconButton, Typography } from '@mui/material';
import { missingImg } from 'assets';
import { AttentionWarningIconProps } from 'components/AttentionWarningContentStack';
import { EditableImageProps } from 'components/EditableImage';
import { PopperWrap, TPopperWrapOption } from 'components/PopperWrap';
import { useMemo } from 'react';
import { StickyStackItem } from './StickyStack';

export type PageContentHeaderProps = {
  title: React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  tabs?: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  sticky?: boolean;
};

export const PageContentHeader: React.FC<PageContentHeaderProps> = ({
  maxWidth = false,
  ...props
}) => {

  const content = (
    <Box bgcolor="background.defaultDarker" zIndex={1000}>
      <Container maxWidth={maxWidth}>
        <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={2}>
          <Box display="flex" alignItems="center" gap={2} pb={1} pt={2} flex={1}>
            <Typography component="h1" variant="h5" fontWeight={700}>{props.title}</Typography>
            {props.leftActions}
          </Box>
          {props.tabs}
          <Box display="flex" alignItems="center" pb={1}>
            {props.rightActions}
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );

  if (props.sticky) {
    return (
      <StickyStackItem order={0} placement="top">
        {content}
      </StickyStackItem>
    );
  }

  return content;
};

export type PageContentResourceHeaderRenderTitleParams = {
  inputTitle: React.ReactNode;
  title: React.ReactNode;
};
export type PageContentResourceHeaderProps = {
  title?: React.ReactNode;
  subHeaderContent?: React.ReactNode;
  pageTitle: string;
  sx?: BoxProps['sx'];
  maxWidth?: ContainerProps['maxWidth'];

  imageSrc?: string;
  displayEmptyImage?: boolean;
  renderEditableImage?: (props: Pick<EditableImageProps, 'width' | 'height'>) => React.ReactElement<EditableImageProps>;

  options?: TPopperWrapOption[];
  renderTitle?: (params: PageContentResourceHeaderRenderTitleParams) => React.ReactNode;
  actions?: React.ReactNode;
  attentionWarningIcon?: React.ReactElement<AttentionWarningIconProps<string, string>>;
  tabs?: React.ReactNode;
  stickyTabs?: boolean;
  sticky?: boolean;
};

const IMAGE_DIMENSIONS = { xs: '148px', md: '112px' };
const imageDimensionProps = {
  width: IMAGE_DIMENSIONS,
  height: IMAGE_DIMENSIONS
};

export const PageContentResourceHeader: React.FC<PageContentResourceHeaderProps> = ({
  maxWidth = false,
  ...props
}) => {

  const optionsPopper = useMemo(() => {
    if (props.options) {
      return (
        <PopperWrap
          anchor={toggle => (
            <IconButton onClick={toggle}>
              <MoreVert />
            </IconButton>
          )}
          options={props.options}
        />
      );
    }

    return null;
  }, [ props.options ]);

  const title = <Typography component="span" variant="h5" lineHeight={1} fontWeight={700}>{props.title}</Typography>;

  const image = useMemo(() => {
    if (props.renderEditableImage) {
      return props.renderEditableImage(imageDimensionProps);
    }

    if (props.imageSrc || props.displayEmptyImage) {
      return (
        <Box minWidth="112px" {...imageDimensionProps} display="flex" alignItems="center" justifyContent="center">
          <img style={{ maxWidth: '100%', height: 'auto', maxHeight: '100%' }} src={props.imageSrc || missingImg} alt="header" />
        </Box>
      );
    }

    return null;
  }, [ props ]);

  const tabs = useMemo(() => {
    if (props.tabs) {
      const tabs = (
        <Box bgcolor="background.paper" zIndex={1000}>
          <Container maxWidth={maxWidth} sx={{ px: 0 }}>
            <Divider />
            {props.tabs}
          </Container>
          <Divider />
        </Box>
      );

      if (props.stickyTabs && !props.sticky) {
        return (
          <StickyStackItem order={0} placement="top">
            {tabs}
          </StickyStackItem>
        );
      }

      return tabs;
    }

    return null;
  }, [ maxWidth, props.sticky, props.stickyTabs, props.tabs ]);

  const content = (
    <>
      <Box bgcolor="background.paper">
        <Container maxWidth={maxWidth}>
          <Box pt={{ xs: 1, md: 2 }} pb={1} display="flex" gap={2} flexWrap="wrap" flexDirection={{ xs: 'column-reverse', md: 'row' }} sx={props.sx}>

            <Box display="flex" alignItems="center" gap={2} flex={1} flexDirection={{ xs: 'column', sm: 'unset' }} justifyContent={{ xs: 'center', sm: 'unset' }} pb={{ xs: 2, md: image ? 1 : 2 }}>
              {image}
              <Box maxWidth={{ xs: '400px', sm: '400px', md: 'unset' }} textAlign={{ xs: 'center', sm: 'unset' }} position="relative" flexGrow={1}>
                <Typography variant="overline" color="text.secondary">{props.pageTitle}</Typography>
                <Box>
                  {props.renderTitle ? props.renderTitle({ title, inputTitle: props.title }) : title}
                </Box>
                <Box mt={{ xs: 1, sm: 0 }}>
                  {props.subHeaderContent}
                </Box>
              </Box>
            </Box>

            <Box alignSelf={{ sm: 'flex-end' }} display="flex" alignItems={{ xs: 'flex-start', sm: 'flex-end' }} gap={2}>
              <Box flex={1} display="flex" rowGap={1} columnGap={2} flexWrap="wrap">
                {props.actions}
              </Box>
              {(props.attentionWarningIcon || optionsPopper) && (
                <Box display="flex" gap={1}>
                  {props.attentionWarningIcon}
                  {optionsPopper}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      {tabs ? tabs : <Divider />}
    </>
  );

  if (props.sticky) {
    return (
      <StickyStackItem order={0} placement="top">
        <Box zIndex={1000}>
          {content}
        </Box>
      </StickyStackItem>
    );
  }

  return content;
};