import { OpenInNewOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography,
} from '@mui/material';
import { CardContentContainer, CardContentGrid, CardContentLink, EditableImage, PageContentContainer, PageContentHeader } from 'components';
import { CreateVehicleModal } from 'pages/Vehicle/components/CreateVehicle.modal';
import { useUpcomingEventsForVehiclesHash } from 'queries';
import { useVehicles } from 'queries/vehicle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Vehicles = () => {
  const navigate = useNavigate();
  const { data: vehicles = [], isInitialLoading: loading } = useVehicles();
  const { data: upcomingEventsForVehiclesHash = {}, isInitialLoading: upcomingEventsForVehiclesHashLoading } = useUpcomingEventsForVehiclesHash({ limit: 1 });
  const [ createModalOpen, setCreateModalOpen ] = useState(false);

  return (
    <PageContentContainer
      loading={loading}
      header={(
        <PageContentHeader
          title="Vehicles"
          rightActions={<Button variant="contained" size="small" onClick={() => setCreateModalOpen(true)}>New Vehicle</Button>}
        />
      )}
    >
      <CreateVehicleModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} />
      <Grid container spacing={2}>
        {vehicles.map((vehicle) => {
          const currentEvent = upcomingEventsForVehiclesHash[vehicle._id]?.[0];

          return (
            <Grid key={vehicle._id} item xs={12} md={6} lg={4} minHeight={200}>
              <CardContentContainer
                showEditOption
                editOptionIcon={<OpenInNewOutlined />}
                onEdit={() => navigate(vehicle._id)}
                disableAutoHeight
                clickable
              >
                <Box display="flex" alignItems="flex-start" gap={2}>
                  <EditableImage
                    width="165px"
                    height="165px"
                    onUpload={()=>{}}
                    imageUrl={vehicle.imageUrl}
                    modalTitle="Update User Image"
                    disabledEditable
                  />
                  <Stack gap={1}>
                    <Typography variant="h6">{vehicle.name}</Typography>
                    <CardContentGrid
                      items={[ {
                        title: `${currentEvent?.type === 'current' ? 'Current' : 'Upcoming'} Event`,
                        type: 'full',
                        loading: upcomingEventsForVehiclesHashLoading,
                        value: currentEvent ? <CardContentLink href={`/events/${currentEvent.event._id}`} title={currentEvent.event.name} onClick={e => e.stopPropagation()} /> : <Box>––</Box>
                      } ]}
                    />
                  </Stack>
                </Box>
              </CardContentContainer>
            </Grid>
          );
        })}
      </Grid>
    </PageContentContainer>
  );
};
