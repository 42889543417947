import { useSelectionChipGroup } from 'components/SelectionChipGroup';
import { useProductsStockUpdateLogs } from 'queries';
import { StockUpdateLogTable, StockUpdateLogTableProps } from './StockUpdateLogTable.component';

export type UseStockUpdateLogTableProps = {
  teamId?: string;
  productId?: string;
  initialLimit?: '100' | '500' | 'all';
};

export const useStockUpdateLogTable = (props?: UseStockUpdateLogTableProps) => {
  const { SelectionChipGroup, value: limit } = useSelectionChipGroup({ initialValue: props?.initialLimit ?? '100' });
  const { data: updateLogs = [], isInitialLoading: isLoading } = useProductsStockUpdateLogs({ teamId: props?.teamId, productId: props?.productId, limit: limit === 'all' ? undefined : Number(limit) });

  const StockUpdateLogTableComponent: React.FC<Pick<StockUpdateLogTableProps, 'sx'>> = props => {

    return <StockUpdateLogTable {...props} rows={updateLogs} loading={isLoading} />;
  };

  const SelectionChipGroupComponent: React.FC = () => {

    return <SelectionChipGroup options={[ { value: '100', label: 'Last 100' }, { value: '500', label: 'Last 500' }, { value: 'all', label: 'Show All' } ]} disabled={isLoading} />;
  };

  return { StockUpdateLogTable: StockUpdateLogTableComponent, SelectionChipGroup: SelectionChipGroupComponent };
};