import { DeleteOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEventDateImage } from 'api/actions';
import { EventDate } from 'api/resources';
import { ImagesCarousel, useAlertSnackbar, useEventContext } from 'components';
import { eventDateEnumHelpers } from 'helpers';
import { QUERY_KEY } from 'queries/query-keys';
import { useMemo } from 'react';
import { DateService, FirebaseService } from 'services';

export type EventDateImagesCarouselProps = {
  initialIndex?: number;
  eventDateId: string;
  images: EventDate['images'];
  onClose: () => void;
  renderInfo?: (image: EventDate['images'][number], index: number, images: EventDate['images']) => React.ReactNode;
};

export const EventDateImagesCarousel: React.FC<EventDateImagesCarouselProps> = ({ eventDateId, initialIndex, images, onClose, renderInfo }) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const { event } = useEventContext();
  const deleteMutation = useMutation({
    mutationFn: async (index: number) => {
      const image = images[index];
      const fileName = FirebaseService.getFileNameFromUrl(image.url);
      const path = `${event._id}/${eventDateId}/${image.type}/${fileName}`;

      await FirebaseService.deleteFile(path);

      await deleteEventDateImage(eventDateId, image._id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      snackbar.success('Image deleted');
    },
    onError: () => {
      snackbar.error('Image could not be deleted');
    }
  });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.stopPropagation();
    deleteMutation.mutate(index);
  };

  const imageSrcs = useMemo(() => images.map(image => image.url), [ images ]);

  return (
    <ImagesCarousel
      initialIndex={initialIndex}
      imageSrcs={imageSrcs}
      onClose={onClose}
      renderInfo={
        renderInfo
          ? (index) => renderInfo(images[index], index, images)
          : (index) => {
            const image = images[index];

            return (
              <>
                <strong>{eventDateEnumHelpers.imageType.getLabel(image.type)}</strong>
                &nbsp;
                ({DateService.getFormattedDate(image.createdAt, 'MMM Do, YYYY h:mma')}),
                &nbsp;{index + 1} of {images.length}
              </>
            );
          }
      }
      renderActions={(index) => (
        <IconButton onClick={(e) => handleDelete(e, index)} sx={{ color: 'common.white' }}>
          <DeleteOutlined />
        </IconButton>
      )}
    />
  );
};