import { DatesStatusChip, SectionCardRow, StaffStatusChip } from 'components';
import { Box, Button, Card, Chip, Divider, Typography } from '@mui/material';
import { getEventDatesStaffList, getEventDatesStatus, getEventStaffStatus, userEnumHelpers } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { EventDatesList, EventDatesPreviewCalendars, EventDatesTimelines } from '../components';
import { useEventContext } from '../event.context';
import { EventPageSection } from '../types';
import { ROUTING_CONFIG } from 'constants/routing-config';

export const DatesSection: React.FC = () => {
  const navigate = useNavigate();
  const { event } = useEventContext();

  return (
    <Box id={EventPageSection.DATES} mt={5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h6" fontWeight={400}>Dates / Staff</Typography>
        <Box display="flex" gap={1}>
          <Button size="small" variant="outlined" onClick={() => navigate(`/${ROUTING_CONFIG.events}/${event._id}/scheduling`)}>Manage Scheduling</Button>
          <Button size="small" variant="outlined" onClick={() => navigate(`/${ROUTING_CONFIG.events}/${event._id}/dates`)}>Manage Dates</Button>
        </Box>
      </Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        <Box flex={1} maxWidth="800px">
          <Card variant="outlined" sx={{ borderRadius: 0, '& > [role="row"]:not(:last-of-type)': { borderBottom: theme => `1px solid ${theme.palette.divider}` } }}>
            <Box display="flex">
              <Box flex={1} borderRight={theme => `1px solid ${theme.palette.divider}`}>
                <SectionCardRow title="Dates Status">
                  <DatesStatusChip value={getEventDatesStatus(event.dates)} />
                </SectionCardRow>
                <Divider />
                <SectionCardRow title="Staff Status">
                  <StaffStatusChip value={getEventStaffStatus(event.dates)} />
                </SectionCardRow>
                <Divider />
                <EventDatesList
                  event={event}
                  onEventDateClick={(eventDateId) => navigate(`/${ROUTING_CONFIG.events}/${event._id}/dates/${eventDateId}`)}
                />
                <Divider />
              </Box>
              {!!event.dates.length && (
                <Box p={2} display={{ xs: 'none', md: 'flex' }} flexDirection="column" gap={2} flex={0}>
                  <EventDatesPreviewCalendars />
                  {event.dates.some(d => !!d.staff.length) && (
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
                      <Typography variant="body2">All Scheduled Staff</Typography>
                      <Box display="flex" gap={0.5} flexWrap="wrap" justifyContent="center">
                        {getEventDatesStaffList(event.dates).map((staff) => {
                          return (
                            <Box key={staff._id} display="flex">
                              <Chip
                                size="small"
                                color={userEnumHelpers.staffStatus.getColor(staff.status)}
                                variant="outlined"
                                label={staff.user.name}
                                sx={{ fontWeight: 500, borderRadius: '4px' }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Box minHeight={300} display={{ xs: 'none', md: 'block' }}>
          <EventDatesTimelines />
        </Box>
      </Box>
    </Box>
  );
};