import React, { FunctionComponent, SVGProps, useMemo, useState } from 'react';
import { Typography, Box, useTheme, Theme, useMediaQuery, IconButton, Popper, ClickAwayListener, Fade } from '@mui/material';
import { DateService } from 'services';
import { EventDateTimelineConfig, EventDateTimelineTimePointPrimary, EventDateTimelineTimePointSecondary, eventDateTimePeriodTypeEnumHelpers, getDurationInMinutes, getDurationLabel, getTotalDuration } from 'helpers/event-date-times';

type CondensedTimesLine = {
  type: 'secondary';
  label?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  times: EventDateTimelineTimePointSecondary[];
};

type CondensedTimes = (EventDateTimelineTimePointPrimary | CondensedTimesLine)[];

export type EventDateTimelineProps = EventDateTimelineConfig;

export const EventDateTimeline: React.FC<EventDateTimelineProps> = ({ label, LabelIcon, times, showLineAfter, showLineBefore }) => {
  const totalDurationInMinutes = getTotalDuration(times);
  const totalDurationLabel = getDurationLabel(totalDurationInMinutes);

  const condensedTimes = useMemo(() => {
    return times.reduce<CondensedTimes>((r, time) => {
      const rCopy = [ ...r ];
      const last = rCopy.pop();

      if (time.type === 'primary') {
        if (last && last.type === 'secondary') {
          const secondToLast = r[r.length - 2];

          if (secondToLast && secondToLast.type === 'primary') {
            const label = getDurationLabel(getDurationInMinutes(secondToLast.time, time.time));
            const Icon = eventDateTimePeriodTypeEnumHelpers.getIcon(secondToLast.followingTimePeriodType);

            return [ ...rCopy, { ...last, label, Icon }, time ];
          }
        }
        if(last && last.type === 'primary') {
          const label = getDurationLabel(getDurationInMinutes(last.time, time.time));
          const Icon = eventDateTimePeriodTypeEnumHelpers.getIcon(last.followingTimePeriodType);

          return [ ...r, { type: 'secondary', times: [], label, Icon }, time ];
        }
        return [ ...r, time ];
      }

      if (!last || last.type === 'primary') {
        return [ ...r, { type: 'secondary', times: [ time ] } ];
      }

      return [ ...rCopy, { ...last, times: [ ...last.times, time ] } ];
    }, []);
  }, [ times ]);

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography display="flex" alignItems="center" gap={1} my={1}><LabelIcon /> {label}</Typography>
        <Typography variant="body2" textAlign="left">
        (<Typography fontWeight={500} variant="body2" component="span">{totalDurationLabel}</Typography> total)
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent={times.length > 1 ? 'space-between' : 'center'} width="100%" bgcolor="primary.background" p={1}>
        {condensedTimes.map((element, index) => {
          if (element.type === 'secondary') {
            return <EventDateTimelineLine key={index} times={element.times} label={element.label} Icon={element.Icon} />;
          }

          return (
            <React.Fragment key={index}>
              {index === 0 && showLineBefore &&  <EventDateTimelineLine dashed />}
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={60}>
                <Typography variant="subtitle2" textAlign="center">
                  {DateService.dayjs(element.time, 'HH:mm').format('h:mma')}
                </Typography>
                <Box
                  width={15}
                  height={15}
                  borderRadius="50%"
                  border="4px solid"
                  borderColor="primary.main"
                  my={1}
                />
                <Typography variant="body2" textAlign="center">
                  {element.label}
                </Typography>
              </Box>
              {index === condensedTimes.length - 1 && showLineAfter && <EventDateTimelineLine dashed />}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};

type EventDateTimelineLineProps = {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  label?: string;
  dashed?: boolean;
  times?: EventDateTimelineTimePointSecondary[];
};

type PopperConfig = {
  index: number;
  label: string;
  time: string;
};

const EventDateTimelineLine: React.FC<EventDateTimelineLineProps> = props => {
  const theme = useTheme();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const lineDisplay = useMemo(() => {
    return (
      <Box
        flexGrow={1}
        height="2px"
        sx={theme => props.dashed ? {
          backgroundImage:  `linear-gradient(to right, ${theme.palette.grey[400]} 0%, ${theme.palette.grey[400]} 40%, transparent 40%, transparent 100%)`,
          backgroundSize: '20px 2px',
          backgroundRepeat: 'repeat-x'
        } : {
          borderTop: `2px solid ${theme.palette.grey[400]}`,
        }}
      />
    );
  }, [ props.dashed ]);

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  const [ popperConfig, setPopperConfig ] = useState<PopperConfig | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLElement>, newPopperConfig: PopperConfig) => {
    if (!popperConfig || popperConfig.index !== newPopperConfig.index) {
      setAnchorEl(e.currentTarget);
      setPopperConfig(newPopperConfig);
    } else {
      setAnchorEl(null);
      setPopperConfig(null);
    }
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
    setPopperConfig(null);
  };

  return (
    <Box flexGrow={1} display="flex" alignItems="center" position="relative">
      <Popper open={!!popperConfig} anchorEl={anchorEl} transition id="event_dates_timelines_popper">
        {({ TransitionProps }) => {
          if (!popperConfig) {
            return null;
          }

          return (
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Box
                    p={1}
                    bgcolor={theme => theme.palette.background.paper}
                    border={theme => `1px solid ${theme.palette.grey[400]}`}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="body2" borderBottom="1px solid">{DateService.dayjs(popperConfig.time, 'HH:mm').format('h:mma')}</Typography>
                    <Typography variant="caption" color="text.secondary">{popperConfig.label}</Typography>
                  </Box>
                </Fade>
              </ClickAwayListener>
            </Box>
          );
        }}
      </Popper>
      {lineDisplay}
      {props.times?.map((time, index) => {
        return (
          <React.Fragment key={index}>
            <IconButton onClick={e => handleClick(e, { index, ...time })} sx={{ height: '22px' }}>
              <Box
                width={5}
                height={5}
                borderRadius="50%"
                border="2px solid"
                borderColor="primary.main"
                my={1}
              />
            </IconButton>
            {lineDisplay}
          </React.Fragment>
        );
      })}
      {!isSmall && (
        <>
          <Typography
            variant="caption"
            component="div"
            position="absolute"
            px={0.5}
            sx={{
              top: '0',
              left: '50%',
              whiteSpace: 'nowrap',
              transform: props.times?.length ? 'translate(-50%, -100%)' : 'translate(-50%, -150%)',
            }}
          >
            {props.label}
          </Typography>
          {props.Icon && (
            <Typography
              variant="caption"
              component="div"
              position="absolute"
              px={0.5}
              sx={{
                top: '0',
                left: '50%',
                whiteSpace: 'nowrap',
                transform: props.times?.length ? 'translate(-50%, 110%)' : 'translate(-50%, 50%)',
              }}
            >
              <props.Icon style={{ fontSize: '17px', color: theme.palette.grey[600] }} />
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};