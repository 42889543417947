import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean } from 'components/RadioGroup/RadioGroupYesNo.component';
import { Form } from 'formik';
import { formSx } from 'styles';
import { ResourceForm } from 'types';

export const ProductPreperationForm: React.FC = () => {
  const createName = (name: keyof ResourceForm['product']['preperation']) => `preperation.${name}`;

  return (
    <Form>
      <Box sx={formSx.formGroup}>
        <RadioGroupYesNoBoolean
          name={createName('rasterLayer')}
          label="AI File"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('centerLabel')}
          label="Label File"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('png')}
          label="Png"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('dmc')}
          label="Dmc"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('dwg')}
          label="Dwg"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('divider')}
          label="Divider Created"
          row
          includeUnknown
        />
      </Box>
    </Form>
  );
};
