import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const LogisticsEventDates = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticsEventDates}
      getNavigateTo={(id) => `${id}/dates`}
      localStorageArgs={{
        initialVisible: [ 'name', 'eventTimeframe', 'datesStatus', 'acceptanceStatus' ],
        initialSortModel: [ { field: 'eventTimeframe', sort: 'asc' } ],
      }}
      groupByWeek
    />
  );
};
