import { SchemaOf, array, object, string } from 'yup';
import { VehiclePageSectionRowId, VehicleSectionRowForm } from '../types';

export const vehicleMaintenanceLogValidationSchema = (): SchemaOf<VehicleSectionRowForm[VehiclePageSectionRowId.MAINTENANCE_LOGS]> => {
  return object({
    title: string().required('Title Required'),
    date: string().nullable().default(null),
    description: string().default(''),
    assignees: array().of(string().required('Assignee is required')).min(1, 'At least one assignee must be selected'),
  });
};