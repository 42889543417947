import { Box, Checkbox, ListItem, ListItemText, Typography } from '@mui/material';
import { createEventToDo, deleteEventToDo, updateEventToDo } from 'api/actions';
import { SectionCard, useAlertSnackbar, useEventContext } from 'components';
import { processFormValueUpdate, removeUnchanged } from 'helpers';
import React from 'react';
import { DateService } from 'services';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'contexts';
import { EventSectionCardRowEditableList } from '../components';
import { EventToDoForm, eventToDoValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';

export const ToDosSection = () => {
  const { event, invalidateQueriesHandler } = useEventContext();
  const { _id } = useCurrentUser();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateToDoMutation = useMutation({
    mutationFn: async (toDoId: string) => {
      if (!event.toDos) {
        return;
      }

      const completed = event.toDos.filter(({ _id }) => _id === toDoId)[0].completed;

      return updateEventToDo(event._id, toDoId, { completed: !completed });
    },
    onSuccess: async (_, toDoId) => {
      const wasCompleted = event.toDos.filter(({ _id }) => _id === toDoId)[0].completed;

      await invalidateQueriesHandler(queryClient);

      snackbar.success(`Todo ${wasCompleted ? 'unchecked' : 'checked'}`);
    },
    onError: async (_, toDoId) => {
      const wasCompleted = event.toDos.filter(({ _id }) => _id === toDoId)[0].completed;

      snackbar.success(`Could not ${wasCompleted ? 'check' : 'uncheck'} todo`);
    }
  });

  return (
    <SectionCard title="To Dos" id={EventPageSection.TO_DOS}>
      <EventSectionCardRowEditableList
        title="To Dos"
        listItems={event.toDos ?? []}
        rowId={EventPageSectionRowId.TO_DO}
        renderItem={(toDo) => {
          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <Box display="flex" gap={1} alignItems="center">
                <Checkbox
                  checked={toDo.completed}
                  onChange={() => updateToDoMutation.mutate(toDo._id)}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircle />}
                  disabled={updateToDoMutation.isLoading}
                />
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography sx={{ textDecoration: toDo.completed ? 'line-through' : 'none' }} width="fit-content">
                      {toDo.title}
                    </Typography>
                  }
                  secondary={(
                    <Typography variant="body2" sx={{ textDecoration: toDo.completed ? 'line-through' : 'none' }} width="fit-content">
                      {toDo.description && toDo.description + '.\n'}
                      {toDo.description && <br />}
                      {toDo.dateAsUtc && 'Reminder on '}
                      <strong>{toDo.dateAsUtc && DateService.getFormattedDate(DateService.dayjs(toDo.dateAsUtc), 'MMM Do, YYYY h:mma') + '. '}</strong>
                      {toDo.dateAsUtc && <br />}
                      Assignees:&nbsp;
                      <strong>{toDo.assignees.map(assignee => assignee.name).join(', ')}</strong>
                    </Typography>
                  )}
                />
              </Box>
            </ListItem>
          );
        }}
        form={<EventToDoForm />}
        createButtonLabel="To Do"
        orderBy={{
          getField: (listItem) => listItem.dateAsUtc ? listItem.dateAsUtc : '',
          direction: 'desc',
        }}
        getFormikProps={(listItem) => ({
          initialValues: {
            title: listItem.title,
            date: listItem.dateAsUtc ? DateService.dayjs(listItem.dateAsUtc).toISOString() :  null,
            description: listItem.description ?? '',
            assignees: listItem.assignees.map(({ _id }) => _id) ?? [],
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEventToDo(event._id, listItem._id, {
              title: updates.title,
              date: updates.date,
              description: processFormValueUpdate.string(updates.description),
              assignees: updates.assignees as string[] | undefined,
            });
          },
          validationSchema: eventToDoValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            title: '',
            date: null,
            description: '',
            assignees: [ _id ],
          },
          onSubmit: (values) => createEventToDo(event._id, {
            title: values.title,
            date: values.date ?? undefined,
            description: processFormValueUpdate.string(values.description) || undefined,
            assignees: values.assignees,
          }),
          validationSchema: eventToDoValidationSchema,
        }}
        deleteMutationFn={(todo) => deleteEventToDo(event._id, todo._id)}
      />
    </SectionCard>
  );
};
