import { useMutation } from '@tanstack/react-query';
import { createVehicle } from 'api/actions';
import { TextInput, UpdateResourceModal, useAlertSnackbar } from 'components';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

export type CreateVehicleModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateVehicleModal: React.FC<CreateVehicleModalProps> = props => {
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const createVehicleMutation = useMutation({
    mutationFn: async (form: {name: string}) => {
      return await createVehicle(form);
    },
    onSuccess: async (response) => {
      navigate(`/${ROUTING_CONFIG.vehicles}/${response.data._id}`);
      snackbar.success('Vehicle created');
    }
  });

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values) => createVehicleMutation.mutateAsync(values)}
      validationSchema={() => object({ name: string().required('Vehicle name required') })}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open={props.open}
            onClose={props.onClose}
            onSave={() => formik.handleSubmit()}
            title="Create new vehicle"
            loading={formik.isSubmitting}
            sx={{ zIndex: FULL_SCREEN_Z_INDEX + 1 }}
            saveButtonText="Create"
          >
            <TextInput name="name" label="Vehicle name" fullWidth />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};
