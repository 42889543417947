import { ApiResource } from './resources.types';

export type Vehicle = ApiResource<{
  name: string;
  order: string;
  drivable?: boolean;
  imageUrl?: string;
  year?: number;
  make?: string;
  model?: string;
  length?: number;
  cargoLength?: number;
  grossWeight?: number;
  files?: VehicleFile[];
  maintenanceLog?: VehicleMaintenanceLog[];

  towCapabilities?: string;
  towCapacity?: number;

  fuelType?: VehicleFuelTypeEnum;
  mpg?: number;
  mileage?: number;
}>;

export const enum VehicleFuelTypeEnum {
  gasoline = 'gasoline',
  diesel = 'diesel',
  electric = 'electric',
  hybrid = 'hybrid',
  naturalGas = 'naturalGas',
  biofuel = 'biofuel',
  hydrogen = 'hydrogen'
}

// Vehicle File

export type VehicleFile = {
  _id: string;
  name: string;
  url: string;
  type: VehicleFileTypeEnum;
};

export const enum VehicleFileTypeEnum {
  insurance = 'insurance',
  registration = 'registration',
  other = 'other',
}

// Vehicle Maintenance Log

export type VehicleMaintenanceLog = {
  _id: string;
  title: string;
  completed: boolean;
  description?: string;
  dateAsUtc?: string;
  assignees: string[];
};