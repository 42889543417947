import { Stack, Typography } from '@mui/material';
import { UserEmployeeRoleEnum } from 'api/resources';
import { useUpcomingEventDates } from 'queries/eventDate';
import { useUsersAvailabilitySetHash } from 'queries/user';
import React, { useContext } from 'react';
import { EventsManagementLogisticsStaffScheduleUserCards } from './components';
import { TableActionsBar } from 'components';
import { EventsManagementInfoPopper } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { EventsManagementPageTitle } from 'pages/EventsManagement/components/EventsManagementPageTitle.component';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';

export const LogisticsStaffScheduleUserCard = () => {
  const { users } = useContext(EventsManagementPageContext);
  const { data: eventDates = [], isInitialLoading: eventDatesLoading } = useUpcomingEventDates();
  const { data: usersAvailabilitySetHash = {}, isInitialLoading: userAvailabilitySetHashIsLoading } = useUsersAvailabilitySetHash();

  const teamLeads = users.filter(user => user.employeeRoles.find(role => role === UserEmployeeRoleEnum.teamLead) && user.active);
  const teamMembers = users.filter(user => user.employeeRoles.find(role => role === UserEmployeeRoleEnum.teamMember) && !user.employeeRoles.find(role => role === UserEmployeeRoleEnum.teamLead) && user.active);

  return (
    <>
      <TableActionsBar leftActions={<EventsManagementPageTitle nodeId={EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards} />} rightActions={<EventsManagementInfoPopper tab={EventsManagementTreeViewNodeIdEnum.logisticsStaffScheduleUserCards} />} />
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h6" fontWeight={400}>Team Leads</Typography>
          <EventsManagementLogisticsStaffScheduleUserCards users={teamLeads} eventDates={eventDates} eventDatesLoading={eventDatesLoading} usersAvailabilitySetHash={usersAvailabilitySetHash} usersAvailabilitySetHashLoading={userAvailabilitySetHashIsLoading} />
        </Stack>
        <Stack gap={1}>
          <Typography variant="h6" fontWeight={400}>Team Members</Typography>
          <EventsManagementLogisticsStaffScheduleUserCards users={teamMembers} eventDates={eventDates} eventDatesLoading={eventDatesLoading} usersAvailabilitySetHash={usersAvailabilitySetHash} usersAvailabilitySetHashLoading={userAvailabilitySetHashIsLoading}  />
        </Stack>
      </Stack>
    </>
  );
};
