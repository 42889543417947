import { useAppDispatch } from 'app/hooks';
import { logoutUserThunk } from './auth.slice';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const logoutUserHandler = () => {
    dispatch(logoutUserThunk());
  };

  return { logoutUserHandler };
};