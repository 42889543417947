import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetLoggingPortalDataResponse, LoggingPortalResourceTypeEnum, updateSale } from 'api/actions';
import { SalePaymentEnum } from 'api/resources';
import { ProductsAutocomplete, TextInput, ToggleButtonGroup, UpdateResourceModal, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import { frameEnumToValueHash, getSelectOptionsFromEnumHelper, removeUnchanged, saleEnumHelpers } from 'helpers';
import { FrameEnum } from 'types';
import { saleValidationSchema } from './validation';
import { Box, ToggleButton } from '@mui/material';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { formSx } from 'styles';
import { useMemo } from 'react';
import { QUERY_KEY } from 'queries/query-keys';

export type EditSaleModalProps = {
  sale: GetLoggingPortalDataResponse['data']['recentSales'][number];
  onClose: () => void;
};

export type EditSaleForm = {
  product: string;
  salePrice: number;
  frame: FrameEnum;
  payment: SalePaymentEnum;
};

export const EditSaleModal: React.FC<EditSaleModalProps> = ({ sale, onClose }) => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const { resourceType, resourceId, teamId } = useLoggingPortalContext();

  const updateMutation = useMutation({
    mutationFn: (form: EditSaleForm) => {
      const update = removeUnchanged(form, initialValues);

      return updateSale(sale._id, {
        product: update.product,
        salePrice: update.salePrice,
        frame: update.frame && frameEnumToValueHash[update.frame],
        payment: update.payment
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        resourceType === LoggingPortalResourceTypeEnum.eventDate
          ? QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(resourceId)
          : QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(resourceId)
      );
      snackbar.success('Sale updated');

      onClose();
    },
    onError: () => {
      snackbar.error('Could not updated sale');
    },
  });

  const frameOptions = useMemo(() => getSelectOptionsFromEnumHelper(saleEnumHelpers.frame), []);
  const paymentOptions = useMemo(() => getSelectOptionsFromEnumHelper(saleEnumHelpers.salePayment), []);

  const initialValues: EditSaleForm = {
    product: sale.product,
    salePrice: sale.salePrice,
    frame: sale.frame ? FrameEnum.frame : FrameEnum.noFrame,
    payment: sale.payment,
  };

  return (
    <Formik
      onSubmit={(values) => updateMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={saleValidationSchema}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open
            onClose={onClose}
            title="Edit sale"
            onSave={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
          >
            <Box sx={formSx.formGroup}>
              {/* teamId is should not be undefined when component renders */}
              {teamId && <ProductsAutocomplete name="product" label="Product" teamId={teamId} />}
              <TextInput name="salePrice" type="number" label="Sale Price" fullWidth />
              <ToggleButtonGroup label="Frame" name="frame" color="primary" exclusive fullWidth>
                {frameOptions.map(({ value, label }) => (
                  <ToggleButton value={value} key={value}>{label}</ToggleButton>
                ))}
              </ToggleButtonGroup>
              <ToggleButtonGroup label="Payment" name="payment" color="primary" exclusive fullWidth>
                {paymentOptions.map(({ value, label }) => (
                  <ToggleButton value={value} key={value}>{label}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};