import { EditOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardContentProps, CardProps, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

export type CardContentContainerType = {
  children: React.ReactNode;
  title?: React.ReactNode;
  onEdit?: () => void;
  showEditOption?: boolean;
  editOptionIcon?: React.ReactNode;
  disableAutoHeight?: boolean;
  sx?: CardProps['sx'];
  cardContentSx?: CardContentProps['sx'];
  action?: React.ReactNode;
  clickable?: boolean;
};

export const CardContentContainer: React.FC<CardContentContainerType> = ({
  title,
  children,
  onEdit,
  disableAutoHeight = false,
  sx,
  cardContentSx,
  action,
  ...props
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [ showEditOption, setShowEditOption ] = useState(false);

  const showAction = ((isSmall || showEditOption) || props.showEditOption) && !action;

  return (
    <Card
      variant="outlined"
      sx={{
        height: disableAutoHeight ? '100%' : 'auto',
        position: 'relative',
        overflow: 'visible',
        cursor: props.clickable ? 'pointer' : undefined,
        ...sx
      }}
      onMouseOver={() => setShowEditOption(true)}
      onMouseOut={() => setShowEditOption(false)}
      onClick={props.clickable ? onEdit : undefined}
    >
      <Box sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        display: !!onEdit && showAction ? 'initial' : 'none',
        zIndex: 1,
      }}
      >
        <IconButton onClick={props.clickable ? undefined : onEdit}>
          {props.editOptionIcon ?? <EditOutlined />}
        </IconButton>
      </Box>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', ...cardContentSx }}>
        {(title || action) && (
          <Box mb={2} display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" whiteSpace="nowrap" gap={2}>
            <Box>
              {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
              {action}
            </Box>
          </Box>
        )}
        <Box flex={1}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};
