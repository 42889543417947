import { Skeleton, Card, Box, Typography, Divider, ListItemButton } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { GetEventsResponse, removeStaffFromEventDate, updateEventDateStaff } from 'api/actions';
import { EventDate, EventDateStaffStatusEnum, User } from 'api/resources';
import { ResourceWithPopulated, PickFromResource } from 'api/resources/resources.types';
import { useAlertSnackbar, ScrollableView, EventContent, useEventPreviewDrawerContext } from 'components';
import { EventDateContentCondensed } from 'components/EventDate/EventDateContentCondensed.component';
import { useCurrentUser } from 'contexts';
import { QUERY_KEY } from 'queries/query-keys';
import { EVENTS_OVERVIEW_CARD_HEIGHT, EVENTS_OVERVIEW_CARD_WIDTH } from './EventsOverview.component';

export type EventsOverviewStaffStatusCardProps = {
  title: ((totalRequests: number) => string) | string;
  events: (GetEventsResponse['data'][number] & {
    statusDates: ResourceWithPopulated<EventDate, {
      staff: ResourceWithPopulated<EventDate['staff'][number], {
        user: PickFromResource<User, 'name'>;
      }>[];
    }>[];
  })[];
  loading?: boolean;
  actions?: (updateStaffStatus: (status: EventDateStaffStatusEnum | null) => void, loading: boolean) => React.ReactNode;
};

export const EventsOverviewStaffStatusCard: React.FC<EventsOverviewStaffStatusCardProps> = ({ events, loading, actions, title }) => {
  const currentUser = useCurrentUser();
  const { setOpenEvent } = useEventPreviewDrawerContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationFn: ({ eventDateId, staffId, status }: {
      eventDateId: string;
      staffId: string;
      status: EventDateStaffStatusEnum;
    }) => updateEventDateStaff(eventDateId, staffId, { status }),
    onSuccess: async (_, updates) => {
      if (updates.status === EventDateStaffStatusEnum.confirmed) {
        snackbar.success('Request accepted');
      } else {
        snackbar.success('Request denied');
      }

      await queryClient.invalidateQueries(QUERY_KEY.EVENTS_FOR_DASHBOARD);
    },
    onError: () => {
      snackbar.error('Staff status could not be updated');
    }
  });
  const deleteMutation = useMutation({
    mutationFn: ({ eventDateId, staffId }: {eventDateId: string; staffId: string}) => removeStaffFromEventDate(eventDateId, staffId),
    onSuccess: async () => {
      snackbar.success('Request withdrawn');

      await queryClient.invalidateQueries(QUERY_KEY.EVENTS_FOR_DASHBOARD);
    },
    onError: () => {
      snackbar.error('Request could not be withdrawn');
    }
  });

  const updateStaffStatus = (status: EventDateStaffStatusEnum | null, eventDateId: string, staffId: string) => {
    if (status === null) {
      deleteMutation.mutate({ eventDateId, staffId });
    } else {
      updateMutation.mutate({ eventDateId, staffId, status });
    }
  };

  if (loading) {
    return <Skeleton variant="rectangular" height={EVENTS_OVERVIEW_CARD_HEIGHT} sx={{ flex: 1 }} />;
  }

  const totalRequests = events.reduce((r, event) => r + event.statusDates.length, 0);

  return (
    <Card variant="outlined" sx={{ flex: 1, borderRadius: 0, minWidth: EVENTS_OVERVIEW_CARD_WIDTH }}>
      <Box m={2}>
        <Typography fontWeight={500} variant="h5">{totalRequests}</Typography>
        <Typography variant="body2" color="text.secondary" mb={3}>{typeof title === 'function' ? title(totalRequests) : title}</Typography>
        <Divider />
        <ScrollableView height="410px" enableScrollShadow>
          {events.map((event, index) => {
            return (
              <ListItemButton key={event._id} onClick={() => setOpenEvent(event._id, 'main')} disableTouchRipple>
                <Box key={event._id} mb={3}>
                  {!!index && <Divider />}
                  <EventContent
                    data={{ event, startDate: event.startDate, endDate: event.endDate }}
                    showPlaceDistanceAndDurationContent
                  >
                    <Box display="flex" flexDirection="column" gap={1.5}>
                      {event.statusDates.map(eventDate => {
                        const staff = eventDate.staff.filter(({ user }) => user._id === currentUser._id)[0];

                        return (
                          <EventDateContentCondensed
                            event={event}
                            eventDate={eventDate}
                            actions={actions?.((status) => updateStaffStatus(status, eventDate._id, staff._id), updateMutation.isLoading || deleteMutation.isLoading)}
                            key={eventDate._id}
                          />
                        );
                      })}
                    </Box>
                  </EventContent>
                </Box>
              </ListItemButton>
            );
          })}
        </ScrollableView>
        <Divider />
      </Box>
    </Card>
  );
};