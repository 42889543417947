import { EditableImage, PageContentResourceHeader, PageContentResourceHeaderProps, WrapWithStatusIndicator, useAlertSnackbar, useEventContext } from 'components';
import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEvent } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { FirebaseService } from 'services';

export type EventPageHeaderProps = Omit<PageContentResourceHeaderProps, 'header' | 'imageSrc' | 'renderHeader' | 'displayEmptyImage'> & { editableImage?: boolean };

/**
 * Built ontop of MainLayoutContentContainerHeader component
 *
 * Use for Event pages
 */
export const EventPageHeader: React.FC<EventPageHeaderProps> = props => {
  const { event } = useEventContext();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const uploadMutation = useMutation({
    mutationFn: async (imageBlobUrl: Blob) => {
      if (event.iconUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(event.iconUrl);
        const path = `${event._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      const iconUrl = await FirebaseService.uploadFile(imageBlobUrl, `${event?._id}/${FirebaseService.convertFileName('_event_icon')}`);

      return updateEvent(event._id, { iconUrl });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      snackbar.success('Image uploaded');
    },
    onError: () => {
      snackbar.error('Image could not be uploaded');
    }
  });
  const deleteMutation = useMutation({
    mutationFn: async () => {
      if (event.iconUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(event.iconUrl);
        const path = `${event._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      return updateEvent(event._id, { iconUrl: null });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      snackbar.success('Image deleted');
    },
    onError: () => {
      snackbar.error('Image could not be deleted');
    }
  });

  return (
    <PageContentResourceHeader
      {...props}
      title={event.name}
      renderTitle={({ title }) => (
        <span>
          {title}&nbsp;<Typography component="span" fontSize="large">{event.year}</Typography>
          &nbsp;<Typography component="span">({event.dates.length} days)</Typography>
        </span>
      )}
      subHeaderContent={(
        <Box>
          <Typography component="span" fontSize="large">
            <WrapWithStatusIndicator status={event.team?.color}>{event.team?.name}
              {event.teamManager && ', '}
              {event.teamManager && <Typography mt={0.3} component="span" variant="body2" color="text.secondary">managed by</Typography>}
              {event.teamManager?.name}
            </WrapWithStatusIndicator>
          </Typography>
          {props.subHeaderContent}
        </Box>
      )}
      renderEditableImage={(editableImageProps) => (
        <EditableImage
          {...editableImageProps}
          imageUrl={event.iconUrl}
          disabledEditable={!props.editableImage}
          onUpload={uploadMutation.mutate}
          loading={uploadMutation.isLoading || deleteMutation.isLoading}
          deleteConfig={{
            onDelete: deleteMutation.mutate,
          }}
        />
      )}
    />
  );
};