import { Box, Typography } from '@mui/material';
import { ColorCodeKeys } from './ColorCodeKeys.component';
import { ColorCodeKeyConfig } from './colorCode';

export type ColorCodeOptionProps = {
  label: string;
  colorKeys: ColorCodeKeyConfig[];
};

export const ColorCodeOption: React.FC<ColorCodeOptionProps> = ({ label, colorKeys }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Typography whiteSpace="nowrap">{label}</Typography>
      <ColorCodeKeys colorKeys={colorKeys} justifyContent="flex-end" />
    </Box>
  );
};
