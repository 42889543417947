import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getEventFeedback } from 'api/actions/eventFeedback';

export const useEventFeedback = (eventId: string) => {
  return useQuery(QUERY_KEY.EVENT_FEEDBACK(eventId), async () => {
    const response = await getEventFeedback(eventId);

    return response.data;
  });
};