import { useQuery } from '@tanstack/react-query';
import { getVehicle, getVehicles } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useVehicles = () => {
  return useQuery({
    queryKey: QUERY_KEY.VEHICLES,
    queryFn: async () => {
      const response = await getVehicles();

      return response.data;
    },
  });
};

export const useVehicle = (id: string) => {
  return useQuery(QUERY_KEY.VEHICLE(id), async () => {
    const response = await getVehicle(id);

    return response.data;
  });
};