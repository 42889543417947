import { AreYouSureModal, AreYouSureModalProps } from './AreYouSureModal.component';

export type ForbiddenModalProps = Pick<AreYouSureModalProps, 'onClose' | 'title' | 'description'>;

export const ForbiddenModal: React.FC<ForbiddenModalProps> = ({
  title = 'Forbidden',
  description = 'You do not have permission to perform this action',
  ...props
}) => {

  return (
    <AreYouSureModal
      {...props}
      title={title}
      description={description}
      confirmButtonText="Ok"
      cancelButtonText="Close"
      maxWidth="sm"
    />
  );
};