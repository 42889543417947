import { eventsShowTheseFieldsGroupEnumHelpers, useColorCode, EventWithUserColorCodeConfigArg, eventDatesStaffPreperationStatusColorCodeConfig, eventStaffPreperationStatusColorCodeConfig, ShowTheseFieldsEventContent, ColorCodeAction } from 'components/FullCalendar/CalendarSidebarAction';
import { eventShowTheseFieldsDatesStatusConfig, eventShowTheseFieldsStaffStatusConfig, eventShowTheseFieldsStaffListConfig } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/custom/show-these-fields-presets';
import { useShowTheseFields } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/useShowTheseFields.hook';
import { useCallback, useMemo, useState } from 'react';
import { DragAndDropEventsCalendarEventExtendedProps } from '../helpers';
import { EventClickArg, EventContentArg } from 'types';
import { FullCalendarEventContentContainer } from 'components/FullCalendar/EventContent';
import { GetTeamsResponse } from 'api/actions';
import { ResetAllActionContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/ResetAllActionContent.component';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useLocalStorage } from 'hooks';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';

const showTheseFieldsConfig = [
  eventShowTheseFieldsDatesStatusConfig,
  eventShowTheseFieldsStaffStatusConfig,
  eventShowTheseFieldsStaffListConfig,
];

type ColorCodeOptionType = 'staffStatus' | 'datesStaffStatus';

const colorCodeConfigHash = {
  staffStatus: eventStaffPreperationStatusColorCodeConfig,
  datesStaffStatus: eventDatesStaffPreperationStatusColorCodeConfig,
};

export type UseTeamSchedulingCalendarConfigProps = {
  teams: GetTeamsResponse['data'];
  localStorageId: string;
};

export const useTeamSchedulingCalendarConfig = ({ localStorageId, teams }: UseTeamSchedulingCalendarConfigProps) => {
  const allTeamIds = teams.map(t => t._id);
  const selectedTeamsLocalStorage = useLocalStorage<string[]>(`${localStorageId}_selected_teams`);
  const [ selectedTeamIds, setSelectedTeamIds ] = useState(selectedTeamsLocalStorage.getResource(allTeamIds));

  const { modal: showTheseFieldsModal, sidebarActionConfig: showTheseFieldsSidebarActionConfig, showTheseFields, onResetShowTheseFields } = useShowTheseFields({
    id: localStorageId,
    config: showTheseFieldsConfig,
    defaultShowTheseFields: [ 'userStaffStatus', 'typeSetupBreakdownProcedure' ],
    groupEnumHelpers: eventsShowTheseFieldsGroupEnumHelpers,
  });

  const {
    colorCodeOption,
  } = useColorCode<ColorCodeOptionType, EventWithUserColorCodeConfigArg>({
    id: localStorageId,
    defaultValue: 'staffStatus',
    colorCodeConfigHash: colorCodeConfigHash,
  });

  const getEventContent = useCallback((arg: EventContentArg<DragAndDropEventsCalendarEventExtendedProps>) => {
    const { event, eventDate, group } = arg.event.extendedProps;
    const backgroundFunc = colorCodeOption ? colorCodeConfigHash[colorCodeOption].getColorKeyConfig({ event, eventDate }).background : undefined;

    return (
      <FullCalendarEventContentContainer
        isStart={arg.isStart}
        isEnd={arg.isEnd}
        backgroundFunc={backgroundFunc ?? (theme => theme.palette.primary.main)}
      >
        <ShowTheseFieldsEventContent
          showTheseFieldsConfig={showTheseFieldsConfig}
          showTheseFields={showTheseFields}
          title={event.name}
          extra={{ event, user: null }}
          eventDate={eventDate}
          group={group}
        />
      </FullCalendarEventContentContainer>
    );
  }, [ colorCodeOption, showTheseFields ]);

  const handleEventClick = (onEventClick?: (eventId: string) => void) => (clickInfo: EventClickArg<DragAndDropEventsCalendarEventExtendedProps>) => {
    const event = clickInfo.event.extendedProps.event;

    onEventClick?.(event._id);
  };

  const handleSelectedTeamsChange = useCallback((teamIds: string[], remove: boolean = false) => {
    teamIds.map(teamId => setSelectedTeamIds(p => {
      const newP = [ ...p ];

      if (remove) {
        if (p.includes(teamId)) {
          const teamIdIndex = p.findIndex(id => id === teamId);

          newP.splice(teamIdIndex, 1);
        }
      } else {
        if (!p.includes(teamId)) {
          newP.push(teamId);
        }
      }

      selectedTeamsLocalStorage.setResource(newP);

      return newP;
    }));
  }, [ selectedTeamsLocalStorage ]);

  const onResetAll = () => {
    onResetShowTheseFields();
    handleSelectedTeamsChange(allTeamIds);
  };

  const selectedTeams = teams.filter(t => selectedTeamIds.includes(t._id));

  const teamSelectActionContent = useMemo(() => {
    const nonWarehouseTeams = teams.filter(t => !t.isWarehouse);

    return (
      <ToggleButtonGroup
        value={selectedTeamIds}
        color="primary"
        onChange={(_, value: string) => value && handleSelectedTeamsChange([ value ], selectedTeamIds.includes(value))}
        exclusive
        size="small"
        orientation="vertical"
        fullWidth
      >
        {nonWarehouseTeams.map(team => (
          <ToggleButton value={team._id} key={team._id}>
            <WrapWithStatusIndicator status={team.color}>
              {team.name}
            </WrapWithStatusIndicator>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }, [ handleSelectedTeamsChange, selectedTeamIds, teams ]);

  return {
    modals: showTheseFieldsModal,
    sidebarActionsConfig: [
      { content: <ResetAllActionContent onResetAll={onResetAll} /> },
      { label: 'Display teams', content: teamSelectActionContent },
      { label: 'Color Code', content: <ColorCodeAction colorCodeOption={colorCodeOption} colorCodeConfigHash={colorCodeConfigHash} /> },
      showTheseFieldsSidebarActionConfig,
    ],
    getEventContent,
    handleEventClick,
    selectedTeams,
  };
};