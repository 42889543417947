import { EventInsuranceStatusEnum } from 'api/resources';
import { InsuranceStatusChip } from 'components/Chips/custom/InsuranceStatusChip.component';

export type InsuranceStatusCellType = {
  value?: EventInsuranceStatusEnum;
};

export const InsuranceStatusCell: React.FC<InsuranceStatusCellType> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <InsuranceStatusChip size="small" value={value} />;
};
