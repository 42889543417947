export const enum ApplicationStatusEnum {
  needApply = 'needApply',
  inProccess = 'inProccess',
  applied = 'applied',
  unknown = 'unknown',
  research = 'research'
}

export const enum AcceptanceStatusEnum {
  accepted = 'accepted',
  denied = 'denied',
  followUp = 'followUp',
  waitList = 'waitList',
  canceled = 'canceled',
  notInterested ='notInterested',
  unable = 'unable',
  unknown ='unknown'
}

export const enum ApplicationPlatformEnum {
  mail = 'mail',
  hostsWebsite = 'hostsWebsite',
  zapplication = 'zapplication',
  entryThingy = 'entryThingy',
  submittable = 'submittable',
  eventeny = 'eventeny',
  unknown ='unknown'
}

export const enum PaymentTypeEnum {
  credit = 'credit',
  check = 'check',
  other = 'other',
  unknown = 'unknown',
}

export const enum InsuranceStatusEnum {
  obtained = 'obtained',
  unknown = 'unknown',
}

export const enum InsuranceSharedEnum {
  shared = 'shared',
  unknown = 'unknown',
}

export const enum CommunicationLogTypeEnum {
  call = 'call',
  voicemail = 'voicemail',
  email = 'email',
  other = 'other',
}
export const enum ApplicationDateEnum {
  specified = 'specified',
  notSpecified = 'notSpecified'
}

export const enum ApplicationPaymentEnum {
  paid = 'paid',
  balance = 'balance',
  unassigned = 'unassigned',
}
