import { Typography, Box } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { getMatrixDisplayText } from 'components/PlaceDistanceAndDurationContent';
import { eventDateEnumHelpers, eventEnumHelpers, getEventDatesStaffList, getEventDatesStatus, getEventStaffStatus, userEnumHelpers } from 'helpers';
import { StatusChipDisplay } from './ShowTheseFieldsStatusChipDisplay.component';
import { EventDateStaffStatusEnum, User } from 'api/resources';
import { EventsShowTheseFieldsGroup, ShowTheseFieldsConfig } from '../types';
import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import { StaffList } from 'components/StaffList';
import _ from 'lodash';
import { getDurationInMinutes, getDurationLabel } from 'helpers/event-date-times';

type ShowTheseFieldsConfigExtraProps = {
  event: GetEventsResponse['data'][number];
  user: Pick<User, '_id'> | null;
};

type EventShowTheseFieldsConfig = ShowTheseFieldsConfig<string, EventsShowTheseFieldsGroup, GetEventsResponse['data'][number]['dates'][number], ShowTheseFieldsConfigExtraProps>;

export const eventShowTheseFieldsUserStaffStatusConfig: EventShowTheseFieldsConfig = {
  field: 'userStaffStatus',
  group: EventsShowTheseFieldsGroup.team,
  label: 'User Staff Status',
  getContent: (eventDate, group, { user }) => {
    if (!user) {
      return <i>No user selected</i>;
    }

    const userStatuses = _.uniq(group
      .filter(eventDate => eventDate.staff.some(staff => staff.user._id === user._id))
      .map(eventDate => eventDate.staff.find(staff => staff.user._id === user._id)?.status));

    if (userStatuses.length === 0) {
      return <i>Not assigned</i>;
    }

    return (
      <Box display="flex">
        <Typography fontSize="small" color="text.secondary">
            User staff st.:&nbsp;
        </Typography>
        {userStatuses.map(status => <StatusChipDisplay helpers={userEnumHelpers.staffStatus} value={status} key={status} />)}
      </Box>
    );
  }
};

export const eventShowTheseFieldsTypeSetupBreakdownProcedureConfig: EventShowTheseFieldsConfig = {
  field: 'typeSetupBreakdownProcedure',
  group: EventsShowTheseFieldsGroup.eventDate,
  label: 'Event Date info: type & breakdown procedure',
  getContent: (eventDate, group) => {
    if (group.length > 1) {
      return <i>Unspan to view.</i>;
    }

    const TypeIcon = eventDateEnumHelpers.type.getIcon(eventDate.type);
    const SetupIcon = eventDateEnumHelpers.setupBreakdownType.getIcon(eventDate.setupType);
    const BreakdownIcon = eventDateEnumHelpers.setupBreakdownType.getIcon(eventDate.breakdownType);

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="body2" display="flex" alignItems="center">Type: <TypeIcon fontSize="inherit" /></Typography>
        <Typography variant="body2" display="flex" alignItems="center">Stp. proc.: <SetupIcon fontSize="inherit" /></Typography>
        <Typography variant="body2" display="flex" alignItems="center">Br. proc.: <BreakdownIcon fontSize="inherit" /></Typography>
      </Box>
    );
  }
};

export const eventShowTheseFieldsTeamConfig: EventShowTheseFieldsConfig = {
  field: 'team',
  group: EventsShowTheseFieldsGroup.team,
  label: 'Team',
  getContent: (_, __, { event }) => {
    if (event.team) {
      return `${event.team.name} (${event.teamManager?.name ?? 'No team lead'})`;
    }

    return <Typography variant="body2" fontSize="small" component="i" color="warning.main">Team Unassigned</Typography>;
  }
};

export const eventShowTheseFieldsEventManagerConfig: EventShowTheseFieldsConfig = {
  field: 'eventManager',
  group: EventsShowTheseFieldsGroup.team,
  label: 'Event Manager',
  getContent: (_, __, { event }) => {
    if (event.eventManager) {
      return `Ev. mng.: ${event.eventManager.name}`;
    }

    return <Typography variant="body2" fontSize="small" component="i" color="warning.main">Event Manager Unassigned</Typography>;
  }
};

export const eventShowTheseFieldsTeamManagerConfig: EventShowTheseFieldsConfig = {
  field: 'teamManager',
  group: EventsShowTheseFieldsGroup.team,
  label: 'Team Manager',
  getContent: (_, __, { event }) => {
    if (event.teamManager) {
      return `Team mng.: ${event.teamManager.name}`;
    }

    return <Typography variant="body2" fontSize="small" component="i" color="warning.main">Team Manager Unassigned</Typography>;
  }
};

export const eventShowTheseFieldsSpaceSizeConfig: EventShowTheseFieldsConfig = {
  field: 'spaceSize',
  group: EventsShowTheseFieldsGroup.event,
  label: 'Space Size',
  getContent: (_, __, { event }) => event.spaceSize
};

export const eventShowTheseFieldsVehicleConfig: EventShowTheseFieldsConfig = {
  field: 'vehicle',
  group: EventsShowTheseFieldsGroup.event,
  label: 'Vehicle',
  getContent: (_, __, { event }) => {
    if (event.vehicle) {
      return event.vehicle.name;
    }

    return <Typography variant="body2" fontSize="small" component="i" color="warning.main">Vehicle Unassigned</Typography>;
  }
};

export const eventShowTheseFieldsDatesStatusConfig: EventShowTheseFieldsConfig = {
  field: 'datesStatus',
  group: EventsShowTheseFieldsGroup.logistics,
  label: 'Dates Status',
  getContent: (eventDate) => <StatusChipDisplay title="Dates st." helpers={eventEnumHelpers.datesStatus} value={getEventDatesStatus([ eventDate ])} />,
  disableTypography: true
};

export const eventShowTheseFieldsStaffStatusConfig: EventShowTheseFieldsConfig = {
  field: 'staffStatus',
  group: EventsShowTheseFieldsGroup.team,
  label: 'Staff Status',
  getContent: (eventDate) => <StatusChipDisplay title="Staff st." helpers={eventEnumHelpers.staffStatus} value={getEventStaffStatus([ eventDate ])} />,
  disableTypography: true
};

export const eventShowTheseFieldsStaffNeededConfig: EventShowTheseFieldsConfig = {
  field: 'staffNeeded',
  group: EventsShowTheseFieldsGroup.eventDate,
  label: 'Staff Needed',
  getContent: (eventDate, group) => {
    if (group.length > 1) {
      return <i>Unspan to view.</i>;
    }

    const staffConfirmed = eventDate.staff.filter(staff => staff.status === EventDateStaffStatusEnum.confirmed).length;

    return `${staffConfirmed} confirmed / ${eventDate.staffNeeded} needed`;
  }
};

export const eventShowTheseFieldsStaffListConfig: EventShowTheseFieldsConfig = {
  field: 'staff',
  label: 'Staff List',
  group: EventsShowTheseFieldsGroup.team,
  getContent: (eventDate, group) => {
    const staffList = group ? getEventDatesStaffList(group) : eventDate.staff;

    return <StaffList staffList={staffList} />;
  },
  disableTypography: true
};

export const eventShowTheseFieldsLodgingRequiredConfig: EventShowTheseFieldsConfig = {
  field: 'lodgingRequired',
  group: EventsShowTheseFieldsGroup.logistics,
  label: 'Lodging required',
  getContent: (_, __, { event }) => <Box display="flex" alignItems="center" gap={1}><YesNoUnknownIconSwitch value={event.lodging?.isRequired ?? null} fontSize="small" />Lodging</Box>
};

export const eventShowTheseFieldsDistanceFromStudioConfig: EventShowTheseFieldsConfig = {
  field: 'distanceFromStudio',
  group: EventsShowTheseFieldsGroup.logistics,
  label: 'From studio',
  getContent: (_, __, { event }) => getMatrixDisplayText(event.place.fromStudioMatrix)
};

export const eventShowTheseFieldsWorkDayLengthConfig: EventShowTheseFieldsConfig = {
  field: 'workDayLength',
  group: EventsShowTheseFieldsGroup.logistics,
  label: 'Workday length',
  getContent: (eventDate) => {
    const workdayTotalInMinutes = getDurationInMinutes(eventDate.startTime, eventDate.endTime);

    return `Workday: ${getDurationLabel(workdayTotalInMinutes)}`;
  }
};