import React, { useCallback } from 'react';

import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  AutocompleteRenderInputParams,
  IconProps,
  InputAdornment,
} from '@mui/material';
import { useField } from 'formik';
import { BaseTextField } from '../BaseTextField';

/*
  This one is weird!
  The point of this component is to extend the mui autocomplete component with our styling/defaults
  1. So we start by extending their props (which have been imported as MUIAutocompleteProps)
  2. These props are generic, so our extended interface has to be generic as well
  3. We'd like to supply a default `renderInput` prop, but it's required on their interface. We mark it option by
    first removing it from their interface (the Omit<> type), and adding it again in our additional properties
*/
export type AutocompleteProps<
  T,
  TMultiple extends boolean | undefined = undefined,
  TDisableClearable extends boolean | undefined = boolean,
  TFreeSolo extends boolean | undefined = undefined,
> = Omit<MUIAutocompleteProps<T, TMultiple, TDisableClearable, TFreeSolo>, 'renderInput'> & {
  name: string;
  label?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  groupBy?: (option: any) => string;
  helperText?: string;
  autoFocus?: boolean;
  transformValue?: (value: T | null) => unknown;
  icon?: React.ComponentType<IconProps>;

  inputValue: string;
  setInputValue: (val: string) => void;
  value?: any;
  setValue?: (value?: any) => void;
  touched?: boolean;
  error?: string;

  openWithNoTerm?: boolean;
};

const defaultTransformValue = function <T>(v: T) {
  return v;
};

export const AutocompleteRaw = <T extends { id: string } | { _id: string }>({
  name,
  helperText: helperTextProps,
  label,
  transformValue = defaultTransformValue,
  icon: Icon,
  renderInput,

  inputValue,
  setInputValue,
  value: _value,
  setValue,
  touched: metaTouched,
  error: metaError,

  ...props
}: AutocompleteProps<T>) => {
  const [ isOpen, setIsOpen ] = React.useState<boolean>(false);
  const valueAsIs = transformValue === defaultTransformValue;
  const value = React.useMemo(() => {
    if (valueAsIs) {
      return _value;
    }
    return props.options.find((o) => transformValue(o) === _value) ?? null;
  }, [ valueAsIs, props.options, _value, transformValue ]);

  const handleValueChange = (e: React.ChangeEvent<unknown>, newValue: T | null) => {
    setValue?.(transformValue(newValue) || null);
  };

  const handleInputValueChange = (e: React.ChangeEvent<unknown>, inputValue: string) => {
    setInputValue(inputValue);
    if (!inputValue) setValue?.(null);
  };

  const handleOpenRequest: AutocompleteProps<T>['onOpen'] = (e) => {
    if (
      props.openWithNoTerm === false
      && e.type === 'mousedown'
      && !inputValue
    ) {
      // should not be opened if user not enter anything
      // but open if user enter anything -> blur -> focus again
      return;
    }
    if (e.type === 'change' && e.target !== document.activeElement) {
      return;
    }

    setIsOpen(true);
  };

  const handleClose: AutocompleteProps<T>['onClose'] = () => {
    setIsOpen(false);
  };

  const defaultRenderInput = (params: AutocompleteRenderInputParams) => {
    const error = Boolean(metaError && helperTextProps) || Boolean(metaTouched && metaError);
    const helperText = helperTextProps || (metaTouched && metaError);

    return (
      <BaseTextField
        name={name}
        label={label}
        {...params}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        error={error}
        helperText={helperText}
        InputProps={{
          ...params.InputProps,
          startAdornment: Icon && (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <MUIAutocomplete
      {...props}
      clearOnBlur={false}
      renderInput={renderInput ?? defaultRenderInput}
      value={value}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={handleInputValueChange}
      onOpen={handleOpenRequest}
      onClose={handleClose}
      open={isOpen}
      onBlur={(event) => {
        setIsOpen(false);
        props?.onBlur?.(event);
      }}
    />
  );
};

export const Autocomplete = <T extends { id: string } | { _id: string }>(props: Omit<AutocompleteProps<T>, 'value' | 'touched' | 'error' | 'inputValue' | 'setInputValue'>) => {
  const [ inputValue, setInputValue ] = React.useState<string>('');
  const [ field, meta, helpers ] = useField(props.name);

  // hooks into the autocomplete's onChange incase a side effect is needed
  // ex: resetting another formik field before this ones value changes
  const handleSetValue = useCallback((value?: any) => {
    if (props.setValue) props.setValue(value);
    helpers.setValue(value);
  }, [ helpers, props ]);

  return (
    <AutocompleteRaw
      {...props}
      inputValue={inputValue}
      setInputValue={setInputValue}
      value={field.value}
      setValue={handleSetValue}
      touched={meta.touched}
      error={meta.error}
      onBlur={() => helpers.setTouched(true, true)}
    />
  );
};
