import { TextField, TextFieldProps } from '@mui/material';
import { useMemo } from 'react';

export type BaseTextFieldType = Omit<TextFieldProps, 'variant'>;

export const BaseTextField:
React.FC<BaseTextFieldType> = ({ helperText: _helperText, ...props }) => {
  const helperText = useMemo(() => {
    if (typeof _helperText === 'string') {
      return <span dangerouslySetInnerHTML={{ __html: _helperText }} />;
    }
    return _helperText;
  }, [ _helperText ]);

  return (
    <TextField
      {...props}
      helperText={helperText}
      variant="outlined"
      InputLabelProps={{ ...props.InputLabelProps }}
      InputProps={{ ...props.InputProps }}
      FormHelperTextProps={{ ...props.FormHelperTextProps }}
    />
  );
};
