import { CardContentLink, CardContentMarkdown, MarkdownInput, RadioGroupYesNoBoolean, SectionCard, SectionCardRow, Select, TextInput } from 'components';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper, getYesNoUnknownFromValue, getYesNoUnknownLabelFromValue, processFormValueUpdate } from 'helpers';
import { updateEvent } from 'api/actions';
import { mixed, number, object, string } from 'yup';
import { UnknownEnum, yesNoUnknownSchema } from 'types';
import { Box, Typography } from '@mui/material';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { EventIndoorOurdoorEnum } from 'api/resources';
import { useMemo } from 'react';
import { EventSectionCardRowEditable } from '../components';
import { EventOrganizerForm, TeamForm, EventManagerForm, SpaceSizeForm, ElectricityForm } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { useEventContext } from '../event.context';

export const OverviewSection: React.FC = () => {
  const { event } = useEventContext();
  const indoorOutdoorOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.indoorOutdoor), []);

  return (
    <SectionCard title="Overview" id={EventPageSection.OVERVIEW}>
      <EventSectionCardRowEditable
        title="Name"
        rowId={EventPageSectionRowId.NAME}
        formikProps={{
          initialValues: { name: event.name },
          onSubmit: (values) => updateEvent(event._id, { name: values.name?.trim() }),
          validationSchema: object({ name: string().required('Event Name Required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="name"
            name="name"
            label="Event Name *"
            helperText={event ? 'Updates will apply to all related events across different years' : undefined}
            autoFocus
          />
        )}
      >
        {event.name}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Event Organizer"
        rowId={EventPageSectionRowId.EVENT_ORGANIZER}
        formikProps={{
          initialValues: { eventOrganizer: event.eventOrganizer?._id ?? null },
          onSubmit: values => updateEvent(event._id, { eventOrganizer: values.eventOrganizer }),
          validationSchema: object({ eventOrganizer: string().nullable().default(null) })
        }}
        form={<EventOrganizerForm />}
      >
        {event.eventOrganizer && <CardContentLink title={event.eventOrganizer.name} href={`/${ROUTING_CONFIG.eventOrganizers}/${event.eventOrganizer._id}`} />}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Team"
        rowId={EventPageSectionRowId.TEAM}
        formikProps={{
          initialValues: { team: event.team?._id ?? null, teamManager: event.teamManager?._id ?? null },
          onSubmit: values => updateEvent(event._id, { team: values.team, teamManager: values.teamManager }),
          validationSchema: object({
            team: string().nullable().default(null).when({
              is: () => Boolean(event?.salesCount),
              then: schema => schema.required('Team Required'),
            }),
            teamManager: string().nullable().default(null),
          })
        }}
        form={<TeamForm />}
      >
        <Box display="flex" alignItems="center">
          {event.team && <CardContentLink title={event.team.name} href={`/${ROUTING_CONFIG.teams}/${event.team._id}`} />}
          {event.teamManager && <><Typography variant="body2" color="text.secondary">{event.team && ', '}managed by&nbsp;</Typography><CardContentLink title={event.teamManager.name} href={`/${ROUTING_CONFIG.users}/${event.teamManager._id}`} /></>}
        </Box>
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Event Manager"
        rowId={EventPageSectionRowId.EVENT_MANAGER}
        formikProps={{
          initialValues: { eventManager: event.eventManager?._id ?? null },
          onSubmit: values => updateEvent(event._id, { eventManager: values.eventManager }),
          validationSchema: object({ eventManager: string().nullable().default(null) })
        }}
        form={<EventManagerForm />}
      >
        {event.eventManager?.name}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Attendance"
        rowId={EventPageSectionRowId.ATTENDANCE}
        formikProps={{
          initialValues: { attendance: event.attendance ?? 0 },
          onSubmit: values => updateEvent(event._id, { attendance: processFormValueUpdate.number(values.attendance) }),
          validationSchema: object({ attendance: number().default(0) })
        }}
        form={(
          <TextInput
            fullWidth
            id="attendance"
            name="attendance"
            label="Attendance"
            type="number"
          />
        )}
      >
        {event.attendance}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Space Size"
        rowId={EventPageSectionRowId.SPACE_SIZE}
        formikProps={{
          initialValues: { spaceSize: event.spaceSize ?? '' },
          onSubmit: values => updateEvent(event._id, { spaceSize: processFormValueUpdate.string(values.spaceSize) }),
          validationSchema: object({ spaceSize: string().default('') })
        }}
        form={<SpaceSizeForm />}
      >
        {event.spaceSize}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Space Number"
        rowId={EventPageSectionRowId.SPACE_NUMBER}
        formikProps={{
          initialValues: { spaceNumber: event.spaceNumber ?? '' },
          onSubmit: values => updateEvent(event._id, { spaceNumber: processFormValueUpdate.string(values.spaceNumber) }),
          validationSchema: object({ spaceNumber: string().default('') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="spaceNumber"
            name="spaceNumber"
            label="Space Number"
            autoFocus
          />
        )}
      >
        {event.spaceNumber}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Amount of Vendors"
        rowId={EventPageSectionRowId.VENDORS}
        formikProps={{
          initialValues: { vendors: event.vendors ?? 0 },
          onSubmit: values => updateEvent(event._id, { vendors: processFormValueUpdate.number(values.vendors) }),
          validationSchema: object({ vendors: number().default(0) })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="vendors"
            name="vendors"
            label="Amount of Vendors"
            autoFocus
          />
        )}
      >
        {event.vendors}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Indoor or Outdoor"
        rowId={EventPageSectionRowId.INDOOR_OUTDOOR}
        formikProps={{
          initialValues: { indoorOutdoor: event.indoorOutdoor ?? UnknownEnum.unknown },
          onSubmit: values => updateEvent(event._id, { indoorOutdoor: processFormValueUpdate.enumWithUnknown(values.indoorOutdoor) }),
          validationSchema: object({ indoorOutdoor: mixed<EventIndoorOurdoorEnum | UnknownEnum>().oneOf(eventEnumHelpers.indoorOutdoor.enumValues).required() })
        }}
        form={(
          <Select
            name="indoorOutdoor"
            label="Indoor or Outdoor?"
            options={indoorOutdoorOptions}
            autoFocus
            SelectProps={{ defaultOpen: true }}
          />
        )}
      >
        {eventEnumHelpers.indoorOutdoor.getLabel(event.indoorOutdoor)}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Rain or Shine"
        rowId={EventPageSectionRowId.RAIN_OR_SHINE}
        formikProps={{
          initialValues: { rainOrShine: getYesNoUnknownFromValue(event.rainOrShine) },
          onSubmit: values => updateEvent(event._id, { rainOrShine: processFormValueUpdate.yesNoUnknown(values.rainOrShine) }),
          validationSchema: object({ rainOrShine: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="rainOrShine"
            label='Is Event "Rain Or Shine"?'
            includeUnknown
            row
          />
        )}
      >
        {getYesNoUnknownLabelFromValue(event.rainOrShine)}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="On Website"
        rowId={EventPageSectionRowId.ON_WEBSITE}
        formikProps={{
          initialValues: { onWebsite: getYesNoUnknownFromValue(event.onWebsite) },
          onSubmit: values => updateEvent(event._id, { onWebsite: processFormValueUpdate.yesNoUnknown(values.onWebsite) }),
          validationSchema: object({ onWebsite: yesNoUnknownSchema })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="onWebsite"
            label="Is Event added to the website?"
            includeUnknown
            row
          />
        )}
      >
        {getYesNoUnknownLabelFromValue(event.onWebsite)}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Electricity"
        rowId={EventPageSectionRowId.ELECTRICITY}
        formikProps={{
          initialValues: {
            electricityWanted: getYesNoUnknownFromValue(event.preperation.electricityWanted),
            electricityAvailable: getYesNoUnknownFromValue(event.preperation.electricityAvailable),
            electricGeneratorAllowed: getYesNoUnknownFromValue(event.preperation.electricGeneratorAllowed),
          },
          onSubmit: values => updateEvent(event._id, {
            preperation: {
              electricityWanted: processFormValueUpdate.yesNoUnknown(values.electricityWanted),
              electricityAvailable: processFormValueUpdate.yesNoUnknown(values.electricityAvailable),
              electricGeneratorAllowed: processFormValueUpdate.yesNoUnknown(values.electricGeneratorAllowed),
            }
          }),
          validationSchema: object({
            electricityWanted: yesNoUnknownSchema,
            electricityAvailable: yesNoUnknownSchema,
            electricGeneratorAllowed: yesNoUnknownSchema,
          })
        }}
        form={<ElectricityForm />}
      >
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" rowGap={2}>
          <Box>
            <Typography fontSize="small" color="text.secondary">Wanted</Typography>
            <Typography>{getYesNoUnknownLabelFromValue(event.preperation.electricityWanted)}</Typography>
          </Box>
          <Box>
            <Typography fontSize="small" color="text.secondary">Available</Typography>
            <Typography>{getYesNoUnknownLabelFromValue(event.preperation.electricityAvailable)}</Typography>
          </Box>
          <Box>
            <Typography fontSize="small" color="text.secondary">Generator</Typography>
            <Typography>{getYesNoUnknownLabelFromValue(event.preperation.electricGeneratorAllowed)}</Typography>
          </Box>
        </Box>
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Notes"
        rowId={EventPageSectionRowId.NOTES}
        formikProps={{
          initialValues: { notes: event.notes ?? '' },
          onSubmit: values => updateEvent(event._id, { notes: processFormValueUpdate.string(values.notes) }),
          validationSchema: object({ notes: string().default('') })
        }}
        form={<MarkdownInput name="notes" />}
        disableTypography
        formFullWidth
      >
        <CardContentMarkdown markdown={event.notes} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Description"
        rowId={EventPageSectionRowId.DESCRIPTION}
        formikProps={{
          initialValues: { description: event.description ?? '' },
          onSubmit: values => updateEvent(event._id, { description: processFormValueUpdate.string(values.description) }),
          validationSchema: object({ description: string().default('') })
        }}
        form={<MarkdownInput name="description" />}
        formFullWidth
        disableTypography
      >
        <CardContentMarkdown markdown={event.description} />
      </EventSectionCardRowEditable>
      {event.createdBy && <SectionCardRow title="Created by">{event.createdBy.name}</SectionCardRow>}
    </SectionCard>
  );
};