import _ from 'lodash';
import { DeepPartial } from 'types';

export function removeUnchanged<T extends { [key: string]: any }>(values: T, initialValues: T): DeepPartial<T> {
  return _.reduce(values, (acc: Partial<T>, value: T[keyof T], key: string) => {
    if (_.isObject(value) && _.isObject(initialValues[key]) && !_.isArray(value)) {
      const cleanedObject = removeUnchanged(value, initialValues[key]);

      if (!_.isEmpty(cleanedObject)) {
        return { ...acc, [key]: cleanedObject };
      }
    } else if (!_.isEqual(value, initialValues[key])) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
}