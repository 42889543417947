import { Box } from '@mui/material';
import { MarkdownInput, TextInput } from 'components';
import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { formSx } from 'styles';
import _ from 'lodash';
import { ResourceForm } from 'types';

export const ProductBasicInfoForm: React.FC = () => {
  const formik = useFormikContext<ResourceForm['product']>();

  const { name } = formik.values;
  const { setFieldValue } = formik;

  useEffect(() => {
    const kebabCaseName = _.kebabCase(name.toLowerCase());

    setFieldValue('sku', kebabCaseName);
  }, [ name, setFieldValue ]);

  return (
    <Form>
      <Box sx={formSx.formGroup}>
        <TextInput
          trim
          fullWidth
          id="name"
          name="name"
          label="Product Name *"
        />
        <TextInput
          trim
          fullWidth
          defaultValue=""
          id="sku"
          name="sku"
          label="Sku *"
          disabled
          helperText="Based on Product Name"
        />
        <Box display="flex" gap={2}>
          <TextInput
            id="price"
            name="price"
            label="Price *"
            type="number"
            sx={{ flex: 1 }}
          />
          <TextInput
            id="category"
            name="category"
            label="Category *"
            sx={{ flex: 1 }}
          />
        </Box>
        <MarkdownInput name="notes" label="Notes" />
      </Box>
    </Form>
  );
};
