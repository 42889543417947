import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const ApplicationsWaitlisted = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.applicationsWaitListed}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'daysUntilEvent', 'daysCount', 'daysUntilEvent', 'participationStatus', 'applicationStatus', 'staffStatus', 'datesStatus', 'acceptanceStatus', 'owed', 'teamName', 'applicationOpenTimeframe', 'applicationPlatform' ],
      }}
    />
  );
};
