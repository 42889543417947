import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

export type EnumFilterProps<T> = {
  value: T[];
  onChange: (value: T[]) => void;
  options: {
    label: string;
    value: T;
  }[];
  label: string;
  readOnly?: boolean;
  strict?: boolean;
};

export const EnumFilter = <T extends string>(props: EnumFilterProps<T>) => {

  const onChange = (value: T) => {
    if (props.value.includes(value)) {
      props.onChange(props.value.filter((v) => v !== value));
    } else {
      props.onChange([ ...props.value, value ]);
    }
  };

  const { readOnly, strict } = props;
  const strictOrReadOnly = strict || readOnly;
  const strictAndReadOnly = strict && readOnly;
  const filterLabel = strictOrReadOnly ? `(${readOnly ? 'Read Only' : ''}${strictAndReadOnly ? ', ' : ''}${strict ? 'Strict' : ''})` : null;
  const allSelected = props.options.length === props.value.length;

  const onSelectAll = () => {
    const allValues = props.options.map(option => option.value);

    props.onChange(allSelected ? [] : allValues);
  };

  return (
    <FormControl component="fieldset" variant="standard" >
      <FormLabel>
        {props.label} <i>{filterLabel}</i>
        {!props.readOnly && (
          <Button size="small" onClick={onSelectAll}>
            {allSelected ? 'Deselect' : 'Select'} all
          </Button>
        )}
      </FormLabel>
      <FormGroup row>
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={(
              <Checkbox
                checked={props.value.includes(option.value)}
                onChange={() => onChange(option.value)}
                disabled={props.readOnly}
              />
            )}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};