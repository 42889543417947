import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateInventoryBatch } from 'api/actions';
import { InventoryBatchStatusEnum } from 'api/resources';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { QUERY_KEY } from 'queries/query-keys';
import { Button } from '@mui/material';
import { DeleteResourceModal } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';

export const CancelBatchButton: React.FC = () => {
  const navigate = useNavigate();
  const [ open, setOpen ] = useState(false);
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: () => updateInventoryBatch(inventoryBatch._id, { status: InventoryBatchStatusEnum.canceled }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
      navigate(`/${ROUTING_CONFIG.inventoryBatches}`);
    },
  });

  if ([ InventoryBatchStatusEnum.canceled, InventoryBatchStatusEnum.closed ].includes(inventoryBatch.status)) {
    return null;
  }

  return (
    <>
      {open && (
        <DeleteResourceModal
          onClose={() => setOpen(false)}
          onDelete={deleteMutation.mutate}
          title="Cancel Batch"
          deleteButtonText="Cancel Batch"
          cancelButtonText="Go Back"
          description="Are you sure you want to cancel this batch? This action cannot be undone."
        />
      )}

      <Button color="error" variant="outlined" onClick={() => setOpen(true)}>Cancel Batch</Button>
    </>
  );
};