/* eslint-disable react/no-array-index-key */
import {
  Checkbox, FormControl, FormControlLabel, FormControlLabelProps, FormGroup, FormHelperText, FormLabel, Typography,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';

export type CheckboxItemValueType =
    | string
    | {
      label: React.ReactNode;
      caption?: React.ReactNode;
      value: string;
      disabled?: boolean;
      alwaysChecked?: true;
    } & Omit<FormControlLabelProps, 'control'>;

export type CheckboxGroupProps = {
  name: string;
  label?: string;
  values: CheckboxItemValueType[];
  helperText?: string;
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
};

type CheckboxItemProps = {
  value: CheckboxItemValueType;
  color?: CheckboxGroupProps['color'];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  fieldValue: string[];
};

const CheckboxItem: React.FC<CheckboxItemProps> = ({
  value,
  color,
  onChange,
  name,
  fieldValue,
}) => {
  if (typeof value === 'string') {
    const checked = fieldValue.includes(value);

    return (
      <FormControlLabel
        label={value}
        control={
          <Checkbox color={color} name={name} onChange={onChange} value={value} checked={checked} />
        }
      />
    );
  }

  const checked = fieldValue.includes(value.value);
  const { value: checkboxValue, caption, alwaysChecked, ...formControlProps } = value;

  return (
    <>
      <FormControlLabel
        disabled={value.disabled}
        control={
          <Checkbox color={color} name={name} onChange={onChange} value={checkboxValue} checked={alwaysChecked ?? checked} />
        }
        {...formControlProps}
      />
      <FormHelperText sx={{ ml: 4, mt: -1 }}>
        <Typography variant="body2">{caption}</Typography>
      </FormHelperText>
    </>
  );
};

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  name,
  label,
  values,
  fullWidth = false,
  ...props
}) => {
  const [ field, meta ] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);
  const helperText = (meta.touched && meta.error) || props.helperText;

  return (
    <FormControl
      component="fieldset"
      error={hasError}
      fullWidth={fullWidth}
    >
      {label && <FormLabel component="legend" color={props.color}>{label}</FormLabel>}
      <FormGroup>
        {values.map((value, i) => {
          return (
            <CheckboxItem
              value={value}
              key={i}
              color={props.color}
              onChange={field.onChange}
              name={name}
              fieldValue={field.value}
            />
          );
        })}
      </FormGroup>
      {helperText && (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
