import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const ApplicationsPending = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.applicationsPending}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'daysCount', 'daysUntilEvent', 'staffStatus', 'datesStatus', 'acceptanceStatus', 'owed', 'teamName', 'applicationOpenTimeframe', 'applicationPlatform' ],
        initialSortModel: [ { field: 'daysUntilEvent', sort: 'asc' } ]
      }}
      editable={{ acceptanceStatus: true }}
      groupByWeek
    />
  );
};
