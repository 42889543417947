import { EventPreperationAcceptanceStatusEnum } from 'api/resources';
import { StatusChipProps } from '../types';
import { eventEnumHelpers } from 'helpers';
import { StandardChip } from '../StandardChip.component';

export type AcceptanceStatusChipType = StatusChipProps<EventPreperationAcceptanceStatusEnum>;

export const AcceptanceStatusChip: React.FC<AcceptanceStatusChipType> = props => {
  return <StandardChip {...eventEnumHelpers.acceptanceStatus} {...props} />;
};
