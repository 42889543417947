import { DateSelectArg } from '@fullcalendar/react';
import { UserScheduleFullCalendar } from 'components';
import { useMemo, useState } from 'react';
import { UpdateAvailabilityModal } from './UpdateAvailabilityModal.modal';
import { User } from 'api/resources';
import { GetEventDatesForUserResponse } from 'api/actions';

export type ProfileScheduleCalendarProps = {
  user: User;
  eventDates: GetEventDatesForUserResponse['data'];
};

export const ProfileScheduleCalendar: React.FC<ProfileScheduleCalendarProps> = props => {
  const [ selectInfo, setSelectInfo ] = useState<DateSelectArg | null>(null);

  const modalView = useMemo(() => {
    if (!selectInfo) {
      return null;
    }

    return (
      <UpdateAvailabilityModal
        info={selectInfo}
        user={props.user}
        onClose={() => setSelectInfo(null)}
      />
    );
  }, [ selectInfo, props.user ]);

  return (
    <>
      {modalView}
      <UserScheduleFullCalendar id="profileUserScheduleCalendar" user={props.user} eventDates={props.eventDates} disableAutoHeight selectable setSelectInfo={setSelectInfo} />
    </>
  );
};