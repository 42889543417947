import React, { useState } from 'react';
import { EventsListTable } from './components/EventsListTable.component';
import { EventsListTableEditable } from './components/EventsListTableEditable.component';

export const EventsList = () => {
  const [ editMode, setEditMode ] = useState(false);

  if (editMode) {
    return <EventsListTableEditable setEditMode={setEditMode} />;
  }

  return (
    <EventsListTable setEditMode={setEditMode} />
  );
};
