import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetSaleResponse, GetSalesAsResourceAnalyticsDataInput, GetSalesAsResourceAnalyticsDataResponse, GetSalesInput, getSale, getSales, getSalesAsResourceAnalyticsData, getSalesForEvent, getEventSalesAnalyticsData, getEventsSales, getStorefrontsSales, GetSalesReportDataInput, getSalesReportData, getSalesAnalyticsDataWithCompare, GetSalesAnalyticsDataWithCompareInput } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useEventsSales = (input?: GetSalesInput, enabled = true) => {
  return useQuery(QUERY_KEY.EVENTS_SALES(input), async () => {
    const response = await getEventsSales(input);

    return response.data;
  }, { enabled });
};

export const useStorefrontsSales = (input?: GetSalesInput) => {
  return useQuery(QUERY_KEY.STORE_FRONTS_SALES(input), async () => {
    const response = await getStorefrontsSales(input);

    return response.data;
  });
};

export const useSales = (input?: GetSalesInput, enabled = true) => {
  return useQuery(QUERY_KEY.SALES(input), async () => {
    const response = await getSales(input);

    return response.data;
  }, { enabled });
};

export const useSalesForEvent = (eventId: string, enabled?: boolean) => {
  return useQuery(QUERY_KEY.SALES_FOR_EVENT(eventId), async () => {
    const response = await getSalesForEvent(eventId);

    return response.data;
  }, { enabled });
};

export const useSalesAsResourceAnalyticsData = (input: GetSalesAsResourceAnalyticsDataInput, opts?: UseQueryOptions<GetSalesAsResourceAnalyticsDataResponse['data']>) => {
  return useQuery(QUERY_KEY.EVENTS_SALES_AS_RESOURCE_ANALYTICS_DATA(input), async () => {
    const response = await getSalesAsResourceAnalyticsData(input);

    return response.data;
  }, opts);
};

export const useSale = (id: string, opts?: UseQueryOptions<GetSaleResponse['data']>) => {
  return useQuery(QUERY_KEY.SALE(id), async () => {
    const response = await getSale(id);

    return response.data;
  }, opts);
};

// Event Analytics

export const useEventSalesAnalyticsData = (eventId: string) => {
  return useQuery(QUERY_KEY.EVENT_SALE_ANALYTICS_DATA(eventId), async () => {
    const response = await getEventSalesAnalyticsData(eventId);

    return response.data;
  });
};

// Sales Analytics with compare

export const useSalesAnalyticsWithCompare = (input: GetSalesAnalyticsDataWithCompareInput) => {
  return useQuery(QUERY_KEY.SALES_ANALYTICS_WITH_COMPARE(input), async () => {
    const response = await getSalesAnalyticsDataWithCompare(input);

    return response.data;
  });
};

// Sales Report

export const useSalesReportData = (input: GetSalesReportDataInput) => {
  return useQuery(QUERY_KEY.SALES_REPORT_DATA(input), async () => {
    const response = await getSalesReportData(input);

    return response.data;
  });
};