import { Box } from '@mui/material';
import { AddressVerificationInput, Select, TextInput } from 'components';
import { useMemo } from 'react';
import { DateService } from 'services';
import { formSx } from 'styles';

export const CreateEventForm: React.FC = () => {
  const yearOptions = useMemo(() => {
    const thisYear = DateService.dayjs().year();
    const startYear = thisYear - 4;
    const endYear = thisYear + 4;
    const yearDiff = endYear - startYear;
    const years = Array.from({ length: yearDiff + 1 }, (_, i) => startYear + i);

    return years.map((year) => {
      const value = String(year);

      return {
        value: value,
        label: value,
      };
    });
  }, []);

  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        name="name"
        label="Event Name"
      />
      <Select
        name="year"
        label="Year"
        options={yearOptions}
      />
      <TextInput
        name="cost"
        label="Vending Cost"
        type="number"
      />
      <AddressVerificationInput
        name="location"
        label="Location"
        placeIdName="placeId"
      />
    </Box>
  );
};