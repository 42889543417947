import { Box, Typography } from '@mui/material';
import { Select } from 'components';
import { eventDateEnumHelpers } from 'helpers';
import React, { useMemo } from 'react';
import { formSx } from 'styles';
import { EventDateConfirmedTimesForm } from './EventDateConfirmedTimes.form';

export const EventDateGeneralForm = () => {
  const typeOptions = useMemo(() => {
    return eventDateEnumHelpers.type.enumValues.map(value => {
      const Icon = eventDateEnumHelpers.type.getIcon(value);

      return {
        value,
        label: (
          <Typography display="flex" alignItems="center" gap={1}>
            <Icon fontSize="small" />
            {eventDateEnumHelpers.type.getLabel(value)}
          </Typography>
        )
      };
    });
  }, []);

  return (
    <Box sx={formSx.formGroup}>
      <Select
        name="type"
        fullWidth
        options={typeOptions}
      />
      <EventDateConfirmedTimesForm />
    </Box>
  );
};
