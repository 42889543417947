import { Box } from '@mui/material';
import { SalesAnalytics } from './components/Sales';
import { ProductsAnalytics } from './components/Products/ProductsAnalytics.component';
import { AnalyticsPageContextProvider } from './analytics.context';
import { useState } from 'react';
import { DateRange, DateRangePickerPopover, JumpNav, JumpNavProps, PageContentContainer, PageContentHeader } from 'components';
import { AnalyticsSection } from './components/AnalyticsSection.component';
import { AnalyticsCompareToPopover } from './components/AnalyticsCompareToSelect.component';
import { analyticsCompareToEnumHelpers } from './helpers';
import { DateService } from 'services';
import { useCurrentUser } from 'contexts';

const enum AnalyticsTab {
  sales = 'sales',
  production = 'production',
}

export const Analytics = () => {
  const { isAdmin } = useCurrentUser();
  const [ dateRange, setDateRange ] = useState<NonNullable<DateRange>>({ start: DateService.dayjs(), end: DateService.dayjs() });
  const [ compareDateRange, setCompareDateRange ] = useState<DateRange>(null);

  const tabs: JumpNavProps['tabs'] = [
    { label: 'Sales', sectionId: AnalyticsTab.sales, hidden: !isAdmin },
    { label: 'Production', sectionId: AnalyticsTab.production },
  ];

  const onDateRangeChange = (newDateRange: NonNullable<DateRange>) => {
    const compareShortcut = analyticsCompareToEnumHelpers.getValueForDateRange(dateRange, compareDateRange);

    if (compareShortcut) {
      setCompareDateRange(analyticsCompareToEnumHelpers.getDateRange(newDateRange, compareShortcut));
    }

    setDateRange(newDateRange);
  };

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          sticky
          title="Analytics"
          leftActions={(
            <Box display="flex" gap={1}>
              <DateRangePickerPopover dateRange={dateRange} onChange={onDateRangeChange}/>
              <AnalyticsCompareToPopover inputDateRange={dateRange} dateRange={compareDateRange} onChange={setCompareDateRange} />
            </Box>
          )}
          tabs={<JumpNav offsetTop={80} tabs={tabs} initialValue={AnalyticsTab.sales} />}
        />
      )}
    >
      <Box pb={12} display="flex" flexDirection="column" gap={4}>
        <AnalyticsPageContextProvider dateRange={dateRange} compareDateRange={compareDateRange}>
          {isAdmin && <AnalyticsSection id={AnalyticsTab.sales} title="Sales">
            <SalesAnalytics />
          </AnalyticsSection>}
          <AnalyticsSection id={AnalyticsTab.production} title="Products">
            <ProductsAnalytics />
          </AnalyticsSection>
        </AnalyticsPageContextProvider>
      </Box>
    </PageContentContainer>
  );
};