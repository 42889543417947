import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { eventMissingFieldEnumHelpers } from 'components/Filter/custom/helpers';
import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const ApplicationsMissingData = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.applicationsMissingData}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'daysUntilEvent', 'participationStatus', 'applicationOpenDate', 'applicationDeadlineDate' ],
        initialSortModel: [ { field: 'daysUntilEvent', sort: 'asc' } ]
      }}
      editable={{ participationStatus: true }}
      renderAdditionalFilterActions={({ setFilter, filter }) => {
        return (
          <ToggleButtonGroup
            value={filter.showMissingData}
            onChange={(_, value) => setFilter({ ...filter, showMissingData: value })}            color="primary"
          >
            {eventMissingFieldEnumHelpers.enumValues.map(value => {
              return (
                <ToggleButton
                  value={value}
                  key={value}
                  selected={filter.showMissingData.includes(value)}
                  sx={{ display: 'flex' }}
                >
                  {eventMissingFieldEnumHelpers.getShortLabel(value)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        );
      }}
      groupByWeek
    />
  );
};
