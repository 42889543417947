import { EventPreperationApplicationPlatformEnum } from 'api/resources';
import { ApplicationPlatformChip } from 'components';

export type ApplicationPlatformCellType = {
  value?: EventPreperationApplicationPlatformEnum;
};

export const ApplicationPlatformCell: React.FC<ApplicationPlatformCellType> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <ApplicationPlatformChip size="small" value={value} />;
};
