import { Typography, lighten } from '@mui/material';
import { EnumHelpers, EnumHelpersWithIcon } from 'helpers/enums/types';

export type StatusChipDisplayProps<T extends string> = {
  title?: string;
  value?: T;
  helpers: EnumHelpers<T> | EnumHelpersWithIcon<T>;
};

export const StatusChipDisplay = <T extends string>({ title, value, helpers }: StatusChipDisplayProps<T>) => {
  const color = helpers.getColor(value);
  const label = helpers.getLabel(value);
  const Icon = 'getIcon' in helpers ? helpers.getIcon(value) : null;

  return (
    <Typography fontSize="small" color="text.secondary" display="flex">
      {title && <>{title}:&nbsp;</>}
      <Typography
        fontSize="small"
        px={0.5}
        sx={theme => ({
          background: lighten(theme.palette[color].main, 0.65),
          border: `0.5px solid ${theme.palette[color].main}`,
          width: 'fit-content',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        })}
      >
        {Icon && <Icon fontSize="small" />}{label}
      </Typography>
    </Typography>
  );
};