import { Box,Table,TableBody,TableContainer } from '@mui/material';
import { BaseTextField } from 'components';
import { useMemo, useState } from 'react';
import { filterResourceBySearch } from 'services';
import { OnAddProductHandler } from '../EventSalesLog.main';
import { EventSalesLogProductListRow } from './EventSalesLogProductListRow.component';
import { useProducts } from 'queries/product';

export type EventSalesLogProductListProps = {
  onAddProduct: OnAddProductHandler;
};

export const EventSalesLogProductList: React.FC<EventSalesLogProductListProps> = ({
  onAddProduct,
}) => {
  const { data: products = [] } = useProducts();
  const [ search, setSearch ] = useState<string>('');

  const filteredProducts = useMemo(() => {
    if (!search) return products;

    return filterResourceBySearch(products, 'name', search);
  }, [ search, products ]);

  return (
    <>
      <Box ml={2} mb={1}>
        <BaseTextField
          name="search"
          value={search}
          placeholder="beatles, kiss, etc."
          size="small"
          label="Search Products"
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {filteredProducts.map((product) => {
              return (
                <EventSalesLogProductListRow
                  key={product._id}
                  product={product}
                  onAddProduct={onAddProduct}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
