import { DateService } from 'services';
import { EventDateTimelineTimePoint } from './get-event-date-timeline-configs';

export const getDurationInMinutes = (startTime: string, endTime: string) => {
  let start = DateService.dayjs(startTime, 'HH:mm');
  const end = DateService.dayjs(endTime, 'HH:mm');

  if (start.isAfter(end)) {
    start = start.subtract(1, 'day');
  }

  return end.diff(start, 'minute');
};

export const getDurationLabel = (durationInMinutes: number) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  return `${hours > 0 ? `${hours} h` : ''}${minutes > 0 ? `${hours > 0 ? ' ' : ''}${minutes} min` : ''}`.trim();
};

export const getTotalDuration = (times: EventDateTimelineTimePoint[]) => {
  return times.reduce((r, point, index) => {
    if (index < times.length - 1) {
      return r + getDurationInMinutes(point.time, times[index + 1].time);
    }

    return r;
  }, 0);
};
