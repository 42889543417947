import { mixed } from 'yup';

export const enum YesNoEnum {
  yes = 'yes',
  no = 'no',
}

export type YesNoValueType = boolean;

export const yesNoSchema = mixed<YesNoEnum>()
  .oneOf([ YesNoEnum.yes, YesNoEnum.no ])
  .required('Please choose an option');

