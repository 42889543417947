import { CardContentMarkdown, MarkdownInput, ProgressBar, SectionCard, YesNoUnknownIconSwitchWithLabel, getFormattedPaymentDue } from 'components';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { DateService, currencyFormatter } from 'services';
import { eventEnumHelpers, getSortedPaymentsDue, getYesNoUnknownFromValue, processFormValueUpdate, removeUnchanged } from 'helpers';
import { EventPreperationElectricityPaymentTypeEnum, EventPreperationJuryFeePaymentTypeEnum, EventPreperationPaymentTypeEnum } from 'api/resources';
import { createEventApplicationPayment, createEventPaymentDue, deleteEventApplicationPayment, deleteEventPaymentDue, updateEvent, updateEventApplicationPayment, updateEventPaymentDue } from 'api/actions';
import { UnknownEnum, YesNoUnknownEnum, yesNoUnknownSchema } from 'types';
import { mixed, number, object, string } from 'yup';
import { useCallback } from 'react';
import { EventSectionCardRowEditable, EventSectionCardRowEditableList } from '../components';
import { eventApplicationPaymentValidationSchema, EventApplicationPaymentForm, JuryFeeForm, ElectricityFeeForm, eventPaymentDueValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { useEventContext } from '../event.context';
import { EventPaymentDueForm } from '../forms/EventPaymentDue.form';

type FeeContentArgs = {
  required?: boolean;
  amount?: number;
  paid?: boolean;
  paymentTypeLabel?: string | UnknownEnum;
};

export const PaymentsSection: React.FC= () => {
  const { event } = useEventContext();

  const stillOwed = event.preperation.cost - event.preperation.paid;
  const youOwe = stillOwed ? `You Owe: ${currencyFormatter.format(stillOwed)}` : 'Paid in full!';

  const getFeeContent = useCallback(({ required, amount, paid, paymentTypeLabel }: FeeContentArgs) => {
    if (required) {
      const amountLabel = amount ? currencyFormatter.format(amount) : '';

      if (paid) {

        return (
          <YesNoUnknownIconSwitchWithLabel value>
            <Typography color="success.main" variant="body2">Paid</Typography>
            <Typography>
              {[ amountLabel, paymentTypeLabel ].filter(x => x).join(' ')}
            </Typography>
          </YesNoUnknownIconSwitchWithLabel>
        );
      }

      if (amount) {
        return (
          <YesNoUnknownIconSwitchWithLabel value={false}>
            <Typography color="error.main" variant="body2">Unpaid</Typography>
            <Typography>
              {amountLabel} Due
            </Typography>
          </YesNoUnknownIconSwitchWithLabel>
        );
      }

      return 'Required';

    }

    if (required === false) {
      return 'Not Required';
    }

    return null;
  }, []);

  return (
    <SectionCard title="Payments" id={EventPageSection.PAYMENTS}>
      <EventSectionCardRowEditableList
        title="Deadlines"
        listItems={getSortedPaymentsDue(event.preperation.paymentsDue)}
        rowId={EventPageSectionRowId.PAYMENTS_DUE}
        renderItem={(paymentDue) => {
          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <ListItemText
                primary={(
                  <Typography sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                    {currencyFormatter.format(paymentDue.amountDue)}
                  </Typography>
                )}
                secondary={(
                  <Typography variant="body2" color="text.secondary" sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                    {getFormattedPaymentDue(paymentDue)}
                  </Typography>
                )}
              />
            </ListItem>
          );
        }}
        createButtonLabel="Payment Deadline"
        getFormikProps={(payment) => ({
          initialValues: {
            amountDue: payment.amountDue,
            dueAsSoonAsPossible: payment.dueAsSoonAsPossible,
            dueWithApplication: payment.dueWithApplication,
            dueDate: payment.dueDateAsUtc,
          },
          onSubmit: async (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            await updateEventPaymentDue(event._id, payment._id, {
              amountDue: updates.amountDue,
              dueDate: updates.dueDate ? DateService.dayjs(updates.dueDate).format('YYYY-MM-DD') : null,
              dueAsSoonAsPossible: updates.dueAsSoonAsPossible,
              dueWithApplication: updates.dueWithApplication,
            });
          },
          validationSchema: eventPaymentDueValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            amountDue: 0,
            dueDate: '',
          },
          onSubmit: (form) => createEventPaymentDue(event._id, {
            amountDue: form.amountDue,
            dueDate: form.dueDate ? DateService.dayjs(form.dueDate).format('YYYY-MM-DD') : null,
            dueAsSoonAsPossible: form.dueAsSoonAsPossible,
            dueWithApplication: form.dueWithApplication,
          }),
          validationSchema: eventPaymentDueValidationSchema,
        }}
        form={<EventPaymentDueForm />}
        deleteMutationFn={(payment) => deleteEventPaymentDue(event._id, payment._id)}
      />
      <EventSectionCardRowEditableList
        title="Payments"
        listItems={event.preperation.applicationPayments}
        rowId={EventPageSectionRowId.PAYMENTS}
        renderItem={(payment) => {
          const secondary = [
            DateService.getFormattedDate(payment.createdAt, 'MMM Do, YYYY h:mma'),
            payment.receiptNumber ? `Receipt: ${payment.receiptNumber}` : null,
          ].filter(x => x).join(' • ');

          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <ListItemText
                primary={`${currencyFormatter.format(payment.amount)} ${eventEnumHelpers.paymentType.getLabel(payment.type)}`}
                secondary={secondary}
              />
            </ListItem>
          );
        }}
        orderBy={{
          getField: (payment) => payment.createdAt,
          direction: 'desc',
        }}
        createButtonLabel="Payment"
        createButtonDisabled={stillOwed <= 0}
        getFormikProps={(payment) => ({
          initialValues: {
            receiptNumber: payment.receiptNumber ?? '',
            amount: String(payment.amount) ?? '',
            type: payment.type,
            paymentDue: payment.paymentDue ?? '',
          },
          onSubmit: async (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            await updateEventApplicationPayment(event._id, payment._id, {
              receiptNumber: processFormValueUpdate.string(updates.receiptNumber),
              amount: updates.amount ? Number(updates.amount) : undefined,
              type: updates.type,
              paymentDue: updates.paymentDue,
            });
          },
          validationSchema: eventApplicationPaymentValidationSchema(event.preperation.applicationPayments.filter(p => p._id !== payment._id), event.preperation.cost)
        })}
        createFormikProps={{
          initialValues: {
            receiptNumber: '',
            amount: '',
            type: EventPreperationPaymentTypeEnum.unknown,
            paymentDue: '',
          },
          onSubmit: (form) => createEventApplicationPayment(event._id, {
            receiptNumber: form.receiptNumber || undefined,
            amount: Number(form.amount),
            type: form.type,
            paymentDue: form.paymentDue || undefined,
          }),
          validationSchema: eventApplicationPaymentValidationSchema(event.preperation.applicationPayments ?? [], event.preperation.cost)
        }}
        form={<EventApplicationPaymentForm />}
        deleteMutationFn={(payment) => deleteEventApplicationPayment(event._id, payment._id)}
      >
        <Box display="flex" gap={2} alignItems="center" whiteSpace="nowrap">
          {!!event.preperation.cost && (
            <>
              <ProgressBar currentAmount={event.preperation.paid} expectedAmount={event.preperation.cost} isCurrency />
              <Typography>{youOwe}</Typography>
            </>
          )}
        </Box>
      </EventSectionCardRowEditableList>
      <EventSectionCardRowEditable
        title="Payments Note"
        rowId={EventPageSectionRowId.PAYMENT_NOTE}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { preperation: { paymentNote: processFormValueUpdate.string(values.paymentNote) } }),
          initialValues: { paymentNote: event.preperation.paymentNote ?? '' },
          validationSchema: object({ paymentNote: string().default('') })
        }}
        form={<MarkdownInput name="paymentNote" />}
        formFullWidth
      >
        <CardContentMarkdown markdown={event.preperation.paymentNote} />
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Jury Fee"
        rowId={EventPageSectionRowId.JURY_FEE}
        formikProps={{
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              preperation: {
                juryFee: processFormValueUpdate.yesNoUnknown(updates.juryFeeRequired),
                juryFeeAmount: processFormValueUpdate.number(updates.juryFeeAmount),
                juryFeePaid: processFormValueUpdate.yesNoUnknown(updates.juryFeePaid),
                juryFeePaymentType: processFormValueUpdate.enumWithUnknown(updates.juryFeePaymentType)
              }
            });
          },
          initialValues: {
            juryFeeRequired: getYesNoUnknownFromValue(event.preperation.juryFee),
            juryFeeAmount: event.preperation.juryFeeAmount ?? 0,
            juryFeePaid: getYesNoUnknownFromValue(event.preperation.juryFeePaid),
            juryFeePaymentType: event.preperation.juryFeePaymentType ?? UnknownEnum.unknown,
          },
          validationSchema: object({
            juryFeeRequired: yesNoUnknownSchema,
            juryFeeAmount: number().required().when('juryFee', {
              is: (juryFee: YesNoUnknownEnum) => juryFee === YesNoUnknownEnum.yes,
              then: schema => schema.required('Jury Fee Amount Required').min(1, 'Jury Fee Amount cannot be this low'),
            }),
            juryFeePaid: yesNoUnknownSchema,
            juryFeePaymentType: mixed<EventPreperationJuryFeePaymentTypeEnum | UnknownEnum>().oneOf(eventEnumHelpers.juryFeePaymentType.enumValues).required(),
          })
        }}
        form={<JuryFeeForm />}
      >
        {getFeeContent({
          required: event.preperation.juryFee,
          amount: event.preperation.juryFeeAmount,
          paid: event.preperation.juryFeePaid,
          paymentTypeLabel: event.preperation.juryFeePaymentType ? eventEnumHelpers.juryFeePaymentType.getLabel(event.preperation.juryFeePaymentType) : ''
        })}
      </EventSectionCardRowEditable>
      <EventSectionCardRowEditable
        title="Electricity Fee"
        rowId={EventPageSectionRowId.ELECTRICITY_FEE}
        formikProps={{
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              preperation: {
                electricityFee: processFormValueUpdate.yesNoUnknown(updates.electricityFeeRequired),
                electricityFeeAmount: processFormValueUpdate.number(updates.electricityFeeAmount),
                electricityFeePaid: processFormValueUpdate.yesNoUnknown(updates.electricityFeePaid),
                electricityFeePaymentType: processFormValueUpdate.enumWithUnknown(updates.electricityFeePaymentType),
              }
            });
          },
          initialValues: {
            electricityFeeRequired: getYesNoUnknownFromValue(event.preperation.electricityFee),
            electricityFeeAmount: event.preperation.electricityFeeAmount ?? 0,
            electricityFeePaid: getYesNoUnknownFromValue(event.preperation.electricityFeePaid),
            electricityFeePaymentType: event.preperation.electricityFeePaymentType ?? UnknownEnum.unknown,
          },
          validationSchema: object({
            electricityFeeRequired: yesNoUnknownSchema,
            electricityFeeAmount: number().required().when('electricityFee', {
              is: (electricityFee: YesNoUnknownEnum) => electricityFee === YesNoUnknownEnum.yes,
              then: schema => schema.required('Electricity Fee Amount Required').min(1, 'Electricity Fee Amount cannot be this low'),
            }),
            electricityFeePaid: yesNoUnknownSchema,
            electricityFeePaymentType: mixed<EventPreperationElectricityPaymentTypeEnum | UnknownEnum>().oneOf(eventEnumHelpers.electricityPaymentType.enumValues).required(),
          })
        }}
        form={<ElectricityFeeForm />}
      >
        {getFeeContent({
          required: event.preperation.electricityFee,
          amount: event.preperation.electricityFeeAmount,
          paid: event.preperation.electricityFeePaid,
          paymentTypeLabel: event.preperation.electricityFeePaymentType ? eventEnumHelpers.electricityPaymentType.getLabel(event.preperation.electricityFeePaymentType) : ''
        })}
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};