import { Box, Container, Divider } from '@mui/material';
import { JumpNav, PageContentContainer, PageContentResourceHeader, SalesReport, SectionCardsEditContextProvider, getInitialAnalyticsTimelineChartTimePeriodForDateRange } from 'components';
import { useMemo, useState } from 'react';
import { useProductOutletContext } from './Product.base';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { AvailabilitySection, GenresSection, InventorySection, OverviewSection, PreparationSection, ShopifySection } from './sections';
import { ProductPageSection } from './types';
import { ProductDeleteModal } from './components/ProductDeleteModal.modal';
import { DateService } from 'services';

export const Product = () => {
  const { isAdmin } = useCurrentUser();
  const [ deleteProductModalVisible, setDeleteProductModalVisible ] = useState(false);
  const { product } = useProductOutletContext();

  const modalView = useMemo(() => {
    if (!deleteProductModalVisible) {
      return null;
    }

    return <ProductDeleteModal onClose={() => setDeleteProductModalVisible(false)} />;
  }, [ deleteProductModalVisible ]);

  return (
    <PageContentContainer
      preventContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.productList, text: 'Product List' } ],
        current: product.name,
      }}
      header={(
        <PageContentResourceHeader
          title={product.name}
          pageTitle="Product Page"
          imageSrc={product.imageUrl}
          displayEmptyImage
          options={isAdmin ? [
            {
              label: 'Delete Product',
              menuItemProps: { onClick: () => setDeleteProductModalVisible(true) },
              labelTypographyProps: { sx: { color: theme => theme.palette.error.dark } },
              preventCloseOnClick: true
            },
          ] : undefined}
          stickyTabs
          tabs={(
            <JumpNav
              initialValue={ProductPageSection.OVERVIEW}
              offsetTop={64}
              tabs={[
                { label: 'Overview', sectionId: ProductPageSection.OVERVIEW },
                { label: 'Shopify', sectionId: ProductPageSection.SHOPIFY },
                { label: 'Preparation', sectionId: ProductPageSection.PREPARATION },
                { label: 'Availability', sectionId: ProductPageSection.AVAILABILITY },
                { label: 'Genres', sectionId: ProductPageSection.GENRES },
                { label: 'Sales', sectionId: ProductPageSection.SALES, hidden: !isAdmin },
                { label: 'Inventory', sectionId: ProductPageSection.INVENTORY },
              ]}
            />
          )}
        />
      )}
    >
      {modalView}

      <Box display="flex" flexDirection="column" gap={5} pt={5} pb={10}>
        <SectionCardsEditContextProvider>
          <Container maxWidth="lg">
            <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
              <OverviewSection />
              <ShopifySection />
              <PreparationSection />
              <AvailabilitySection />
              <GenresSection />
            </Box>
          </Container>
          {isAdmin && (
            <Box bgcolor="background.paper" id={ProductPageSection.SALES}>
              <Box bgcolor="primary.background">
                <Divider />
                <Container maxWidth="lg">
                  <Box py={5}>
                    <SalesReport productId={product._id} timePeriod={getInitialAnalyticsTimelineChartTimePeriodForDateRange({ start: DateService.dayjsTz(product.createdAt), end: DateService.dayjsTz() })} />
                  </Box>
                </Container>
                <Divider />
              </Box>
            </Box>
          )}
          <Container maxWidth="lg">
            <InventorySection />
          </Container>
        </SectionCardsEditContextProvider>
      </Box>
    </PageContentContainer>
  );
};
