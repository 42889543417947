import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { removeStaffFromEventDate, updateEventDateStaff } from 'api/actions';
import { EventDateStaff, EventDateStaffStatusEnum } from 'api/resources';
import { ButtonGroup, ButtonGroupType } from 'components';

export type EditStaffActionButtonGroupProps = {
  staff: Pick<EventDateStaff, 'status'> & { _id: string } ;
  eventDateId: string;
  onMutation?: () => void;
  invalidateQueriesHandler: (queryClient: QueryClient) => Promise<void>;
} & Omit<ButtonGroupType, 'options'>;

export const EditStaffActionButtonGroup: React.FC<EditStaffActionButtonGroupProps> = ({ staff, eventDateId, onMutation, invalidateQueriesHandler, ...props }) => {
  const queryClient = useQueryClient();
  const updateStaffMutation = useMutation({
    mutationFn: async (status: EventDateStaffStatusEnum) => {
      return updateEventDateStaff(eventDateId, staff._id, { status });
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      onMutation?.();
    }
  });
  const deleteStaffMutation = useMutation({
    mutationFn: async () => {
      return removeStaffFromEventDate(eventDateId, staff._id);
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      onMutation?.();
    }
  });

  const loading = updateStaffMutation.isLoading || deleteStaffMutation.isLoading;
  const status = staff.status;

  const options: ButtonGroupType['options'] = [
    {
      label: 'Confirm',
      disabled: status === EventDateStaffStatusEnum.confirmed,
      onClick: () => updateStaffMutation.mutate(EventDateStaffStatusEnum.confirmed),
    },
    {
      label: 'Request',
      disabled: status === EventDateStaffStatusEnum.requested || status === EventDateStaffStatusEnum.confirmed,
      onClick: () => updateStaffMutation.mutate(EventDateStaffStatusEnum.requested),
    },
    {
      label: 'Resend Request',
      disabled: status !== EventDateStaffStatusEnum.deniedRequest,
      onClick: () => {},
    },
    {
      label: 'Remove',
      onClick: () => deleteStaffMutation.mutate(),
    }
  ];

  return <ButtonGroup {...props} options={options} loading={loading} />;
};
