import { createContext, useContext, useEffect, useState } from 'react';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { frameEnumToPriceHash } from 'helpers';
import { ListProps, SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useLocalStorage } from 'hooks';
import { GetLoggingPortalDataResponse, LoggingPortalResourceTypeEnum } from 'api/actions';

export const getLoggingPortalTypeRoute = (value: LoggingPortalResourceTypeEnum) => {
  switch (value) {
    case LoggingPortalResourceTypeEnum.eventDate:
      return 'event-date';
    case LoggingPortalResourceTypeEnum.storefront:
    default:
      return 'store-front';
  }
};

export const enum LogSaleStepEnum {
  products = 'products',
  discount = 'discount',
  payment = 'payment',
};

export const enum DiscountEnum {
  threeOrMore = '3_or_more',
  fiveOff = '5_off',
  tenOff = '10_off',
  tenPercentOff = '10_percent_off',
}

type SaleProduct = {
  product: string;
  frame: boolean;
  discount: number;
  id: number;
};

type SaleProductTransformed = {
  product: GetLoggingPortalDataResponse['data']['products'][number];
  globalDiscount: number;
  totalDiscount: number;
  total: number;
  price: number;
} & Omit<SaleProduct, 'product'>;

export type LogSaleState = {
  step: LogSaleStepEnum;
  saleProducts: SaleProduct[];
  validatedUser: string | null;
  discounts: DiscountEnum[];
};

const getLocalStorageKey = (eventDateId: string) => `log-sale_${eventDateId}`;

export type OnSetStateFunc = (newState: Partial<LogSaleState>) => void;
export type OnAddProductFunc = (productId: string) => void;
export type OnRemoveProductFunc = (id: number) => void;
export type OnSetStepFunc = (step: LogSaleStepEnum) => void;
export type OnUpdateSaleProductFunc = (id: number, updates: Partial<Pick<SaleProduct, 'frame' | 'discount'>>) => void;
export type OnToggleDiscountFunc = (discounts: DiscountEnum) => void;
export type OnClearAndExitFunc = () => void;

type LogSaleContextType = {
  state: LogSaleState;
  onSetState: OnSetStateFunc;
  onAddProduct: OnAddProductFunc;
  onRemoveProduct: OnRemoveProductFunc;
  onSetStep: OnSetStepFunc;
  onUpdateSaleProduct: OnUpdateSaleProductFunc;
  onToggleDiscount: OnToggleDiscountFunc;
  onClearAndExit: OnClearAndExitFunc;

  saleProductsTransformed: SaleProductTransformed[];
  subtotal: number;
  discountTotal: number;
  total: number;
};

export const LogSaleContext = createContext<LogSaleContextType>({
  state: {
    step: LogSaleStepEnum.products,
    saleProducts: [],
    validatedUser: null,
    discounts: [],
  },
  onSetState: () => {},
  onAddProduct: () => {},
  onRemoveProduct: () => {},
  onSetStep: () => {},
  onUpdateSaleProduct: () => {},
  onToggleDiscount: () => {},
  onClearAndExit: () => {},

  saleProductsTransformed: [],
  subtotal: 0,
  discountTotal: 0,
  total: 0,
});

export const useLogSaleContext = () => useContext(LogSaleContext);

export const LogSaleContextProvider: React.FC<{children: React.ReactNode }> = props => {
  const navigate = useNavigate();
  const { products, resourceId, resourceType } = useLoggingPortalContext();
  const localStorage = useLocalStorage<LogSaleState>(getLocalStorageKey(resourceId));

  const [ state, setState ] = useState<LogSaleState>(localStorage.getResource({
    step: LogSaleStepEnum.products,
    saleProducts: [],
    validatedUser: null,
    discounts: [],
  }));

  const onClearAndExit: OnClearAndExitFunc = () => {
    localStorage.removeResource();
    navigate(`/${ROUTING_CONFIG.loggingPortal}/${getLoggingPortalTypeRoute(resourceType)}/${resourceId}`, { replace: true });
  };

  const onSetState: OnSetStateFunc = (newState) => {
    localStorage.setResource({
      ...state,
      ...newState,
    });
    setState({
      ...state,
      ...newState,
    });
  };

  const onAddProduct: OnAddProductFunc = (productId) => {
    const saleProducts = state.saleProducts;

    const nextId = saleProducts.reduce((acc, { id }) => id > acc ? id : acc, 0) + 1;
    const newSaleProducts = [ ...state.saleProducts, { product: productId, frame: true, discount: 0, id: nextId } ];

    onSetState({
      ...state,
      saleProducts: newSaleProducts,
    });
  };

  const onRemoveProduct: OnRemoveProductFunc = (id) => {
    const newSaleProducts = state.saleProducts.filter(product => product.id !== id);

    onSetState({
      ...state,
      saleProducts: newSaleProducts,
    });
  };

  const onUpdateSaleProduct: OnUpdateSaleProductFunc = (id, updates) => {
    onSetState({
      ...state,
      saleProducts: state.saleProducts.map(product => {
        if (product.id !== id) {
          return product;
        }

        return {
          ...product,
          ...updates,
        };
      }),
    });
  };

  const onSetStep: OnSetStepFunc = (step) => {
    onSetState({
      ...state,
      step,
    });
  };

  useEffect(() => {
    const discounts = state.discounts.filter(discount => discount !== DiscountEnum.threeOrMore);

    if (state.saleProducts.length < 3) {
      onSetState({
        ...state,
        discounts: discounts,
      });
    } else {
      onSetState({
        ...state,
        discounts: [ ...discounts, DiscountEnum.threeOrMore ],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state.saleProducts.length ]);

  const onToggleDiscount: OnToggleDiscountFunc = (discount) => {
    const isPresent = state.discounts.includes(discount);

    const newDiscounts = isPresent
      ? state.discounts.filter(selectedDiscount => selectedDiscount !== discount)
      : [ ...state.discounts, discount ];

    onSetState({
      ...state,
      discounts: newDiscounts,
    });
  };

  const saleProductsTransformed = state.saleProducts.map(({ product: productId, ...saleProduct }): SaleProductTransformed => {
    const product = products.find(product => product._id === productId) as GetLoggingPortalDataResponse['data']['products'][number];

    const threeOrMoreApplied = state.discounts.includes(DiscountEnum.threeOrMore);
    const globalDiscount = threeOrMoreApplied ? -5 : 0;
    const totalDiscount = saleProduct.discount + globalDiscount;

    return {
      ...saleProduct,
      product,
      globalDiscount,
      totalDiscount,
      total: getSaleProductPrice(saleProduct.frame, totalDiscount),
      price: getSaleProductPrice(saleProduct.frame),
    };
  });

  const subtotal = saleProductsTransformed.reduce((acc, { price }) => acc + price, 0);
  const discountTotal = state.discounts.reduce((acc, discount) => {
    switch (discount) {
      case DiscountEnum.fiveOff:
        return acc - 5;
      case DiscountEnum.tenOff:
        return acc - 10;
      case DiscountEnum.tenPercentOff:
        return acc - (saleProductsTransformed.reduce((acc, { total }) => acc + total, 0) * 0.1);
      default:
        return acc;
    }
  }, 0) + saleProductsTransformed.reduce((acc, { totalDiscount }) => acc + totalDiscount, 0);
  const total = subtotal + discountTotal;

  return (
    <LogSaleContext.Provider
      value={{
        state,
        onAddProduct,
        onRemoveProduct,
        onSetStep,
        onUpdateSaleProduct,
        onSetState,
        saleProductsTransformed,
        subtotal,
        discountTotal,
        total,
        onToggleDiscount,
        onClearAndExit,
      }}
    >
      {props.children}
    </LogSaleContext.Provider>
  );
};


export const getSaleProductPrice = (frame: boolean, discount = 0) => {
  return (frame ? frameEnumToPriceHash['frame'] : frameEnumToPriceHash['noFrame']) + discount;
};

type ListDividersSxArgs = {
  divideBottom?: boolean;
  divideTop?: boolean;
};

export const listDividersSx = (arg?: ListDividersSxArgs): ListProps['sx'] => {
  const { divideBottom = false, divideTop = false } = arg || {};
  const borderBottomSx: SxProps<Theme> = { borderBottom: theme => `1px solid ${theme.palette.divider}` };

  let divideTopSx: SxProps<Theme> = {};

  if (divideTop) {
    divideTopSx = { '& li:first-of-type': { borderTop: theme => `1px solid ${theme.palette.divider}` } };
  }

  if (divideBottom) {
    return { '& li': borderBottomSx, ...divideTopSx };
  } else {
    return { '& li:not(:last-of-type)': borderBottomSx, ...divideTopSx };
  }
};

export const getPriceAmountChar = (amount: number) => {
  if (amount > 0) {
    return '+';
  } else if (amount < 0) {
    return '-';
  } else {
    return '';
  }
};

export const getPriceAmountColor = (amount: number) => {
  if (amount > 0) {
    return 'success.light';
  } else if (amount < 0) {
    return 'error';
  } else {
    return 'default';
  }
};

export const discountsMeta = [
  {
    label: '3 or more (-$5.00 each)',
    chipLabel: '3 or more',
    value: DiscountEnum.threeOrMore,
    getDisabled: (saleProducts: LogSaleState['saleProducts']) => saleProducts.length < 3,
  },
  {
    label: '$5.00 off entire order',
    chipLabel: '$5.00 off',
    value: DiscountEnum.fiveOff,
  },
  {
    label: '$10.00 off entire order',
    chipLabel: '$10.00 off',
    value: DiscountEnum.tenOff,
  },
  {
    label: '10% off entire order (Military Discount)',
    chipLabel: '10% off',
    value: DiscountEnum.tenPercentOff,
  },
];