import React from 'react';
import { CardContentLink, SectionCard } from 'components';
import { Typography } from '@mui/material';
import { removeUnchanged } from 'helpers';
import { createEventExternalLink, deleteEventExternalLink, updateEventExternalLink } from 'api/actions';
import { useEventContext } from '../event.context';
import { EventSectionCardRowEditableList } from '../components';
import { EventExternalLinkForm, eventExternalLinkValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';

export const LinksSection = () => {
  const { event } = useEventContext();

  return (
    <SectionCard title="Links" id={EventPageSection.LINKS}>
      <EventSectionCardRowEditableList
        title="Links"
        listItems={event.externalLinks ?? []}
        rowId={EventPageSectionRowId.EXTERNAL_LINKS}
        renderItem={(link) => {
          return (
            <>
              <Typography component="span" minWidth="fit-content">{link.title}</Typography>
              <CardContentLink href={link.url} title={link.url} openInNew />
            </>
          );
        }}
        form={<EventExternalLinkForm />}
        createButtonLabel="Link"
        getFormikProps={(listItem) => ({
          initialValues: {
            title: listItem.title,
            url: listItem.url,
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEventExternalLink(event._id, listItem._id, updates);
          },
          validationSchema: eventExternalLinkValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            title: '',
            url: '',
          },
          onSubmit: (values) => createEventExternalLink(event._id, values),
          validationSchema: eventExternalLinkValidationSchema,
        }}
        deleteMutationFn={(link) => deleteEventExternalLink(event._id, link._id)}
      />
    </SectionCard>
  );
};
