import { useField } from 'formik';
import { HexColorPicker as ColorPicker } from 'react-colorful';

export type HexColorPickerProps = {
  name: string;
};

export const HexColorPicker: React.FC<HexColorPickerProps> = props => {
  const [ field, _, helpers ] = useField(props.name);

  return <ColorPicker color={field.value} onChange={color => helpers.setValue(color)} style={{ width: '100%' }}/>;
};