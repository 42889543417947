import { ApiResource } from './resources.types';

export type EventDate = ApiResource<{
  type: EventDateTypeEnum;

  dateAsUtc: string;

  confirmedTimes: boolean;

  startTime: string;
  endTime: string;

  setupType: EventDateSetupBreakdownTypeEnum;
  breakdownType: EventDateSetupBreakdownTypeEnum;

  setupEndTime: string | undefined;
  arriveAsEarlyAs: string | undefined;
  noVehiclesAllowedAfter: string | undefined;
  allVehiclesMustBeRemovedBy: string | undefined;

  bufferTimeInMinutes: number;
  displaySetupInMinutes: number;
  breakdownInMinutes: number;

  images: Omit<ApiResource<{
    url: string;
    type: EventDateImageTypeEnum;
  }>, 'updatedAt'>[];

  event: string;
  notes: string | undefined;
  staffNeeded: number | undefined;
  teamLeadNeeded: boolean;
  staff: EventDateStaff[];
  createdBy?: string;
}>;

export const enum EventDateTypeEnum {
  regular = 'regular',
  setup = 'setup',
  breakdown = 'breakdown',
  travel = 'travel',
}

export type EventDateStaff = ApiResource<{
  user: string;
  status: EventDateStaffStatusEnum;
  isTeamLead?: boolean;
}>;

export const enum EventDateStaffStatusEnum {
  initial = 'initial',
  confirmed = 'confirmed',
  requested = 'requested',
  deniedRequest = 'deniedRequest',
  selfRequested = 'selfRequested',
  selfRequestDenied = 'selfRequestDenied',
}

export const enum EventDateImageTypeEnum {
  arrival = 'arrival',
  setupComplete = 'setupComplete',
  boothStraightOut = 'boothStraightOut',
  boothStreetLeft = 'boothStreetLeft',
  boothStreetRight = 'boothStreetRight',
  insideVehicle = 'insideVehicle',
  other = 'other',
}

export const enum EventDateSetupBreakdownTypeEnum {
  soft = 'soft',
  hard = 'hard',
}