import {
  MenuItem, MenuProps,
} from '@mui/material';
import { useField } from 'formik';
import { BaseTextField, type BaseTextFieldType } from '../BaseTextField';
import { SelectOptionType } from 'helpers';

export type SelectType = BaseTextFieldType & {
  name: string;
  MenuProps?: Partial<MenuProps>;
  autoWidth?: boolean;
  options?: SelectOptionType[];
  includeUnassignedItem?: boolean;
};

export const SelectRaw: React.FC<SelectType> = ({
  name,
  helperText,
  error,
  label,
  disabled,
  autoFocus,
  MenuProps,
  autoWidth,
  onChange,
  onBlur,
  value,
  sx,
  includeUnassignedItem = false,
  ...props
}) => {
  return (
    <BaseTextField
      select
      fullWidth
      name={name}
      label={label}
      disabled={disabled}
      autoFocus={autoFocus}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
      helperText={helperText}
      sx={{ position: 'relative', ...sx }}
      SelectProps={{
        autoWidth,
        MenuProps,
      }}
      {...props}
    >
      {props.children}
      {props.options?.map(({ value, label, disabled = false, sx = undefined }) => {
        return (
          <MenuItem
            key={value + label}
            value={value}
            disabled={disabled}
            sx={sx}
          >
            {label}
          </MenuItem>
        );
      })}
      {includeUnassignedItem && (
        <MenuItem value="" color="warning">
          Unassigned
        </MenuItem>
      )}
    </BaseTextField>
  );
};

export const Select:
React.FC<Omit<SelectType, 'onChange' | 'onBlur' | 'value' | 'error' | 'setValue'>> = ({
  helperText,
  ...props
}) => {
  const [ field, meta ] = useField(props.name);
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <SelectRaw
      error={hasError}
      helperText={hasError ? meta.error : helperText}
      {...props}
      {...field}
    />
  );
};
