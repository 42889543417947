import { GetProductsAsResourceOptionsResponse } from 'api/actions';
import { Autocomplete } from './Autocomplete.component';
import { getWarehouseTeamIds } from 'helpers';
import { useProductsAsResourceOptions, useTeams } from 'queries';

export type ProductsAutocompleteProps = {
  label?: string;
  name: string;
  teamId: string;
};

export const ProductsAutocomplete: React.FC<ProductsAutocompleteProps> = ({ label = 'Product', ...props }) => {
  const { data: products = [], isInitialLoading: loading } = useProductsAsResourceOptions();
  const { data: teams = [] } = useTeams();
  const warehouseTeamIds = getWarehouseTeamIds(teams);

  const getOptionDisabled = (opt: GetProductsAsResourceOptionsResponse['data'][number]) => !opt.availability?.events && !warehouseTeamIds.includes(props.teamId);

  return (
    <Autocomplete
      loading={loading}
      label={label}
      name={props.name}
      options={products}
      getOptionLabel={(opt) => {
        if (getOptionDisabled(opt)) {
          return `(Not At Events) ${opt?.name}`;
        }
        return opt?.name;
      }}
      transformValue={(opt) => opt?._id}
      getOptionDisabled={getOptionDisabled}
    />
  );
};
