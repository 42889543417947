import { authAxiosRequest } from 'api/axios';
import { ProductStockUpdateLogTransferTypeEnum, ProductStockUpdateLogTypeEnum } from 'api/resources';

export type UpdateProductStocksQuantityInput = {
  stocks: {
    _id: string;
    updateAmount: number;
    updatedQuantity: number;
    type: ProductStockUpdateLogTypeEnum;
    transferType?: ProductStockUpdateLogTransferTypeEnum;
    transferStock?: string;
  }[];
  userId: string;
};

export const updateProductStocksQuantity = (data: UpdateProductStocksQuantityInput) => {
  return authAxiosRequest('/api/stock/quantity', { method: 'PUT', data });
};

export type UpdateProductStocksBaselineInput = {
  stocks: {
    _id: string;
    baseline: number;
  }[];
};

export const updateProductStocksBaseline = (data: UpdateProductStocksBaselineInput) => {
  return authAxiosRequest('/api/stock', { method: 'PUT', data });
};