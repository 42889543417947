import { InventoryTableSettingsModal, InventoryTableModeInput, InventoryTableShowStockMetaInput, InventoryTableStandardFiltersInput, Table, InventoryTableTeamColumnsInput, useInventoryTable, prepareInventoryTableEditsToSave, SaveInventoryEditsParams, InventoryTableEditableMode, StandardInventoryTableFilters } from 'components';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useProductStocksQuantityMutation } from 'pages/Products/mutations';
import { Fragment, useMemo } from 'react';
import { UnknownEnum } from 'types';

export const InventoryTableEditable: React.FC = () => {
  const { getProductsSortedAlphabetically, teams } = useProductsPageContext();
  const me = useCurrentUser();
  const saveMutation = useProductStocksQuantityMutation();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const onSave = async (params: SaveInventoryEditsParams) => {
    await saveMutation.mutateAsync({ stocks: prepareInventoryTableEditsToSave(params), userId: me._id });
  };
  const getDisplayStandardFilters = (mode: InventoryTableEditableMode | UnknownEnum.unknown): StandardInventoryTableFilters[] => {
    if (mode === UnknownEnum.unknown) {
      return [];
    }

    if (mode === InventoryTableEditableMode.transfer) {
      return [ 'showNotAvailableAtEvents', 'showNotAvailableAtEvents', 'showInNotNeeded', 'showNoOutStock', 'showOutNotOverstocked' ];
    }

    return [ 'showNotAvailableAtEvents', 'showNotAvailableAtEvents' ];
  };
  const {
    inventoryTableProps,
    editModal,
    setSettingsModalOpen,
    settingsModalOpen,
    teamIds,
    setTeamIds,
    transferConfig,
    setTransferConfig,
    showStockMeta,
    setShowStockMeta,
    mode,
    setMode,
    standardFilters,
    setStandardFilters,
    hasEdits,
  } = useInventoryTable({
    products,
    teams,
    onSaveAll: onSave,
    onSaveRow: onSave,
    getDisplayStandardFilters,
    initialMode: me.isAdmin ? undefined : InventoryTableEditableMode.set,
    initialSettingsModalOpen: true,
  });

  return (
    <Fragment>
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          teamSelectionInput={<InventoryTableTeamColumnsInput mode={mode} disabled={hasEdits} teams={teams} selectedTeamIds={teamIds} setSelectedTeamIds={setTeamIds} transferConfig={transferConfig} setTransferConfig={setTransferConfig} />}
          showStockMetaInput={<InventoryTableShowStockMetaInput showStockMeta={showStockMeta} setShowStockMeta={setShowStockMeta} />}
          modeInput={<InventoryTableModeInput disabled={hasEdits || !me.isAdmin} mode={mode} setMode={setMode} />}
          filterInputs={mode === UnknownEnum.unknown ? undefined : (
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters(mode)}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      {editModal}
      <Table {...inventoryTableProps} />
    </Fragment>
  );
};