import { Box } from '@mui/material';
import { GetTeamsResponse } from 'api/actions';
import { TeamCell } from 'components/Table';

export type TransferLabelProps = {
  team: GetTeamsResponse['data'][number] | undefined;
} & ({
  isIn: true;
} | {
  isOut: true;
});

export const TransferLabel: React.FC<TransferLabelProps> = props => {
  const isIn = 'isIn' in props;

  return (
    <Box display="inline-flex" alignItems="center" gap={0.5} fontWeight={500} ml={1}>
      {props.team ? <TeamCell team={props.team} /> : ''}
      <Box component="span" color={`${isIn ? 'success' : 'error'}.main`}> ({isIn ? 'in' : 'out'})</Box>
    </Box>
  );
};