export type ThemePalette =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'threat'
    | 'unknown';

export const themePalette = [
  'primary',
  'secondary',
  'success',
  'error',
  'info',
  'warning',
  'threat',
  'unknown'
];