import React from 'react';
import { StandardChip } from '../StandardChip.component';
import { StatusChipProps } from '../types';
import { EventDateStaffStatusEnum } from 'api/resources';
import { userEnumHelpers } from 'helpers';

export type UserStaffStatusChipProps = StatusChipProps<EventDateStaffStatusEnum>;

export const UserStaffStatusChip: React.FC<UserStaffStatusChipProps> = props => {
  return <StandardChip {...userEnumHelpers.staffStatus} {...props} />;
};