import { Chart, ChartData, ChartOptions } from 'chart.js';
import { useMemo, useRef, useState } from 'react';
import { DateRange } from 'components';
import { ThemePalette } from 'types';
import { Box, useTheme } from '@mui/material';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { CHART_HEIGHT } from './AnalyticsChartWrapper.component';
import { AnalyticsChartTooltip } from './AnalyticsChartTooltip.component';
import { useNavigate } from 'react-router-dom';

export const enum AnalyticsChartTypeEnum {
  bar = 'bar',
  line = 'line',
  pie = 'pie'
}

type LineBarPieChartData = ChartData<'bar'> & ChartData<'pie'> & ChartData<'line'>;
export type AnalyticsChartData = Omit<LineBarPieChartData, 'datasets' | 'labels'> & {
  datasets: (LineBarPieChartData['datasets'][number] & {
    ids?: string[];
    dateRange: NonNullable<DateRange>;
    // this property establishes a relationship between two datasets
    // the dataset that contains reference index is secondary (e.g. dataset we compare to)
    // otherwise, the dataset is primary (e.g. dataset we compare)
    referenceIndex?: number;
  })[];
  labels: string[];
};

type DisplayLegendArg = {
  chartType: AnalyticsChartTypeEnum;
  datasets: AnalyticsChartData['datasets'];
};

export type RenderTooltipArg = {
  dataIndex: number;
  data: AnalyticsChartData;
};

export type UseAnalyticsChartProps = {
  data: AnalyticsChartData;
  stackedBar?: boolean;
  tooltipContent?: (arg: RenderTooltipArg) => React.ReactNode;
  initialChartType?: AnalyticsChartTypeEnum;
  getDisplayLegend?: (params: DisplayLegendArg) => boolean;
  strColors?: ThemePalette[];
  colorMode?: 'element' | 'dataset';
  getNavigateTo?: (id: string) => string;
};

const defaultStrColors: ThemePalette[] = [ 'primary', 'info', 'success', 'error', 'warning' ];
const defaultColorMode = 'dataset';

export const useAnalyticsChart = ({
  data: rawData,
  initialChartType,
  getDisplayLegend,
  tooltipContent,
  strColors = defaultStrColors,
  colorMode = defaultColorMode,
  stackedBar,
  getNavigateTo,
}: UseAnalyticsChartProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = useMemo(() => strColors.map(color => theme.palette[color]), [ strColors, theme.palette ]);

  const [ chartType, setChartType ] = useState(initialChartType ?? AnalyticsChartTypeEnum.bar);
  const [ fullScreen, setFullScreen ] = useState(false);
  const [ tooltipPosition, setTooltipPosition ] = useState<{left: number; top: number} | null>(null);
  const [ tooltipDataIndex, setTooltipDataIndex ] = useState<number | null>(null);

  const onFullScreenToggle = () => {
    setFullScreen(p => !p);
  };

  const barChartRef = useRef<Chart<'bar'>>(null);
  const lineChartRef = useRef<Chart<'line'>>(null);
  const pieChartRef = useRef<Chart<'pie'>>(null);

  const data = useMemo(() => {
    const referenceCount: Record<number, number> = {};

    return {
      ...rawData,
      datasets: rawData.datasets.map((dataset: AnalyticsChartData['datasets'][number], index) => {
        const referenceIndexCount = dataset.referenceIndex !== undefined ? (referenceCount[dataset.referenceIndex] ?? 0) : 0;
        const color = colors[Math.max(0, dataset.referenceIndex !== undefined ? dataset.referenceIndex + referenceIndexCount : index) % colors.length];
        const backgroundColor = colorMode === 'element' ? colors.map(color => color.background) : color.background;

        if (dataset.referenceIndex !== undefined) {
          if (referenceCount[dataset.referenceIndex]) {
            referenceCount[dataset.referenceIndex] += 1;
          } else {
            referenceCount[dataset.referenceIndex] = 1;
          }
        }

        return ({
          ...dataset,
          pointBackgroundColor: color.main,
          backgroundColor: (chartType === AnalyticsChartTypeEnum.pie || dataset.referenceIndex === undefined) ? backgroundColor : 'transparent',
          borderColor: colorMode === 'element'
            ? colors.map(color => color.main)
            : color.main,
          borderWidth: 1,
          pointRadius: 2,
          borderDash: dataset.referenceIndex !== undefined ? [ 5, 5 ] : undefined,
          fill: 'origin',
        });
      })
    };
  }, [ chartType, colorMode, colors, rawData ]);

  const displayLegend = getDisplayLegend ? getDisplayLegend({ chartType, datasets: data.datasets }) : true;
  const options: ChartOptions<'pie'> & ChartOptions<'bar'> & ChartOptions<'line'> = useMemo(() => {
    const displayScales = chartType !== AnalyticsChartTypeEnum.pie ;

    return {
      onClick: (event, _, chart) => {
        if (getNavigateTo && event.native) {
          const element = chart.getElementsAtEventForMode(event.native, 'index', { intersect: false }, true)?.[0];

          if (element) {
            const { datasetIndex, index } = element;
            const id = data.datasets[datasetIndex].ids?.[index];

            if (id) {
              const to = getNavigateTo(id);

              navigate(to);
            }
          }
        }
      },
      animations: {
        x: { duration: 0 },
        y: { duration: 0 },
        width: { duration: 0 },
      },
      plugins: {
        legend: { display: displayLegend, labels: { boxWidth: 12 } },
        tooltip: {
          enabled: !tooltipContent,
          external: async ({ tooltip }) => {
            setTooltipPosition({ left: tooltip.caretX, top: tooltip.caretY });

            if (tooltip.opacity === 0) {
              setTooltipDataIndex(null);
            } else {
              setTooltipDataIndex(tooltip.dataPoints[0].dataIndex);
            }
          },
          mode: 'index',
          intersect: false,
        },
      },
      hover: {
        mode: chartType === AnalyticsChartTypeEnum.bar ? 'index' : 'nearest',
        intersect: false,
      },
      onHover: (_, __, chart) => {
        if (getNavigateTo) {
          // eslint-disable-next-line no-param-reassign
          chart.canvas.style.cursor = 'pointer';
        }
      },
      maintainAspectRatio: false,
      scales: {
        x: {
          display: displayScales,
          grid: { display: false },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            font: { size: 11 },
            callback: (tickValue) => {
              const label = data.labels[Number(tickValue)];

              if(label && label.length < 13) {
                return label;
              }

              return label?.substring(0, 13).trim() + '...';
            },
          },
        },
        y: { display: displayScales }
      },
    };
  }, [ chartType, data.datasets, data.labels, displayLegend, getNavigateTo, navigate, tooltipContent ]);

  const chartDisplay = useMemo(() => {
    let chart: React.ReactNode;

    if (chartType === AnalyticsChartTypeEnum.bar) {
      const barOptions: ChartOptions<'bar'> = options;

      if (stackedBar && barOptions.scales) {
        barOptions.scales.y = { ...barOptions.scales.y, stacked: true };
        barOptions.scales.x = { ...barOptions.scales.x, stacked: true };
      }

      chart = <Bar data={data} options={barOptions} ref={barChartRef} />;
    } else if (chartType === AnalyticsChartTypeEnum.line) {
      chart = <Line data={data} options={options} ref={lineChartRef} />;
    } else if (chartType === AnalyticsChartTypeEnum.pie) {
      chart = <Pie data={data} options={options} ref={pieChartRef} />;
    };

    return (
      <Box height={fullScreen ? 'calc(100vh - 200px)' : CHART_HEIGHT + 50}>
        {chart}
      </Box>
    );
  }, [ chartType, data, fullScreen, options, stackedBar ]);

  const tooltipDisplay = useMemo(() => {
    const chartRef= {
      [AnalyticsChartTypeEnum.bar]: barChartRef,
      [AnalyticsChartTypeEnum.line]: lineChartRef,
      [AnalyticsChartTypeEnum.pie]: pieChartRef,
    }[chartType];
    const tooltipVisible = chartRef.current && tooltipDataIndex !== null && tooltipPosition && tooltipContent;

    if(tooltipVisible) {
      return (
        <AnalyticsChartTooltip
          chart={chartRef.current}
          tooltipPosition={tooltipPosition}
          tooltip={tooltipContent({ dataIndex: tooltipDataIndex, data })}
        />
      );
    }

    return null;
  }, [ chartType, data, tooltipContent, tooltipDataIndex, tooltipPosition ]);

  return {
    dataWithStyles: data,
    chartType,
    setChartType,
    chartDisplay,
    tooltipDisplay,
    colors,
    fullScreen,
    onFullScreenToggle,
  };
};