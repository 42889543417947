import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton,TableCell,TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { ProductValues } from './ProductValues.component';
import { OnAddProductHandler } from '../EventSalesLog.main';
import { GetProductsResponse } from 'api/actions';
import { EventSalesLogProductListProps } from './EventSalesLogProductList.component';

export type EventSalesLogProductListRowType = {
  product: GetProductsResponse['data'][number];
} & Pick<EventSalesLogProductListProps, 'onAddProduct'>;

export const EventSalesLogProductListRow: React.FC<EventSalesLogProductListRowType> = ({
  product,
  onAddProduct,
}) => {
  const [ open, setOpen ] = useState(false);

  const addProductHandler: OnAddProductHandler = async (values) => {
    await onAddProduct(values);
    setOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell width="1px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{product.name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0, background: grey[200] }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && <ProductValues onAddProduct={addProductHandler} productId={product._id} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
