import { ComputerOutlined, PhoneIphoneOutlined, QuestionMarkOutlined, TabletOutlined } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { Session, User } from 'api/resources';
import { CardContentContainer } from 'components';
import { useSessionsForUser } from 'queries';
import { DateService } from 'services';

export type UserSessionsCardProps = {
  user: User;
};

const getUserAgentData = (userAgent?: string) => {
  let agent: string | undefined, device: string | undefined;

  if (!userAgent) {
    return { agent, device };
  }

  if (userAgent.includes('Firefox')) {
    agent = 'Firefox';
  } else if (userAgent.includes('Chrome')) {
    agent = 'Chrome';
  } else if (userAgent.includes('Safari')) {
    agent = 'Safari';
  } else if (userAgent.includes('PostmanRuntime')) {
    agent = 'Postman';
  }

  if (userAgent.includes('Android')) {
    device = 'Android';
  } else if (userAgent.includes('iPhone')) {
    device = 'iPhone';
  } else if (userAgent.includes('iPad')) {
    device = 'iPad';
  } else if (userAgent.includes('Macintosh')) {
    device = 'MacBook';
  } else if (userAgent.includes('Windows')) {
    device = 'Windows';
  }

  return { agent, device };
};

const enum DeviceType {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

const getIcon = (deviceType: DeviceType | null) => {
  switch (deviceType) {
    case DeviceType.mobile:
      return PhoneIphoneOutlined;
    case DeviceType.tablet:
      return TabletOutlined;
    case DeviceType.desktop:
      return ComputerOutlined;
    default:
      return QuestionMarkOutlined;
  }
};

const getDeviceType = (device?: string) => {
  switch (device) {
    case 'Android':
    case 'iPhone':
      return DeviceType.mobile;
    case 'iPad':
      return DeviceType.tablet;
    case 'MacBook':
    case 'Windows':
      return DeviceType.desktop;
    default:
      return null;
  }
};

const getSessionsData = (sessions: Session[]) => {
  const deviceCount: Record<DeviceType, number> = {
    [DeviceType.mobile]: 0,
    [DeviceType.tablet]: 0,
    [DeviceType.desktop]: 0,
  };

  const rows = sessions.map((session) => {
    const { agent, device } = getUserAgentData(session.userAgent);
    const deviceType = getDeviceType(device);

    if (deviceType) {
      deviceCount[deviceType] += 1;
    }

    return {
      ...session,
      agent,
      device,
      deviceType,
    };
  });

  return { rows, deviceCount };
};

export const UserSessionsCard: React.FC<UserSessionsCardProps> = props => {
  const { data: sessions = [], isInitialLoading: loading } = useSessionsForUser(props.user._id);

  const { rows, deviceCount } = getSessionsData(sessions);

  let content = (
    <List disablePadding>
      {rows.map((session) => {
        const Icon = getIcon(session.deviceType);

        const secondary = [ session.agent, session.device ].filter(Boolean).join(' on ');

        return (
          <ListItem key={session._id} disableGutters divider>
            <ListItemAvatar>
              <ListItemAvatar>
                <Avatar component="div" sx={{ background: theme => theme.palette.common.white }}>
                  <Icon color="primary" />
                </Avatar>
              </ListItemAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={DateService.dayjs(session.createdAt).format('MMM Do, YYYY [at] h:mma')}
              secondary={secondary}
            />
          </ListItem>
        );
      })}
    </List>
  );

  if (!sessions.length || loading) {
    content = (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        {loading ? <CircularProgress /> : <Typography>No Sessions</Typography>}
      </Box>
    );
  }

  return (
    <CardContentContainer disableAutoHeight>
      <Box display="flex" flexDirection="column" height="100%">
        <Box overflow="auto" flex="1 1 auto" height="220px" component="div" className="overscroll" >
          {content}
        </Box>
        <Box display="flex" pt={1} justifyContent="space-around" mb={-1}>
          {Object.entries({ 'total': rows.length, ...deviceCount }).map(([ deviceType, count ]) => {
            const Icon = getIcon(deviceType as any);

            return (
              <Box key={deviceType} display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" alignItems="center" gap={deviceType === DeviceType.mobile ? 0.5 : 1}>
                  {deviceType !== 'total' && <Icon color="primary" fontSize="small" />}
                  <Typography variant="body2" textTransform="capitalize" fontSize="16px">{deviceType}</Typography>
                </Box>
                <Typography fontWeight={500}>{count}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CardContentContainer>
  );
};