import { CloudUploadOutlined, PhotoSizeSelectActualTwoTone, CloseOutlined } from '@mui/icons-material';
import { Box, Typography, IconButton, Alert } from '@mui/material';
import { ImageInput } from 'components/EditableImage/ImageInput.component';
import { useFormikContext } from 'formik';
import { getSelectOptionsFromEnumHelper, eventDateEnumHelpers, getEventDateImagesWarnings } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { formSx } from 'styles';
import imageCompression from 'browser-image-compression';
import { GetEventResponse } from 'api/actions';
import { EventDateImageTypeEnum } from 'api/resources';
import { EventDateImageForm } from '../components';
import { useEventContext } from '../event.context';
import { RadioGroup } from 'components/RadioGroup';

export type EventDateAddPhotoFormProps = {
  eventDate: GetEventResponse['data']['dates'][number];
};

export const EventDateAddPhotoForm: React.FC<EventDateAddPhotoFormProps> = ({ eventDate }) => {
  const { values, setFieldValue } = useFormikContext<EventDateImageForm>();
  const { event } = useEventContext();
  const eventDateImagesWarningsHash = getEventDateImagesWarnings(eventDate, event.dates);
  const [ inputKey, setInputKey ] = useState(0);
  const imageTypeOptions = useMemo(() => {
    return getSelectOptionsFromEnumHelper(eventDateEnumHelpers.imageType)
      .map(option => {
        const moreRequired = !!eventDateImagesWarningsHash[option.value as EventDateImageTypeEnum];

        return {
          ...option,
          label: (
            <Typography display="flex">
              {option.label}&nbsp;
              {moreRequired && <Typography color="text.secondary" fontStyle="italic">
                (1 more required for this date)
              </Typography>}
            </Typography>
          ),
          disabled: !values.image
        };
      });
  }, [ eventDateImagesWarningsHash, values.image ]);

  useEffect(() => {
    if (!values.image) {
      setFieldValue('type', null);
    };
  }, [ setFieldValue, values.image ]);

  const onImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files) {
      const imageFile = e.target.files[0];
      const compressedFile = await imageCompression(imageFile, { maxSizeMB: 1 });

      setFieldValue('image', compressedFile);
    }
  };

  return (
    <Box sx={formSx.formGroup}>
      <ImageInput
        id="photo"
        onChange={onImageChange}
        disabled={false}
        key={inputKey}
      />
      <Box htmlFor="photo" component="label" sx={{ cursor: 'pointer' }} py={4} flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={3} border={theme => `1px dashed ${theme.palette.primary.main}`}>
        <Box display="flex" gap={1}>
          <CloudUploadOutlined color="primary" />
          <Typography color="primary">Choose Photo</Typography>
        </Box>
        <Typography textAlign="center" variant="body2" color="text.secondary">
          .jpg, .jpeg or .png<br /> minimum width 250px
        </Typography>
      </Box>
      {values.image ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          p={1}
          borderRadius={1}
          border={theme => `1px solid ${theme.palette.primary.main}`}
        >
          <PhotoSizeSelectActualTwoTone fontSize="large" color="disabled" />
          <Typography color="text.secondary">
            {values.image.name}
          </Typography>
          <IconButton
            onClick={() => {
              setFieldValue('image', null);
              setInputKey(p => p + 1);
            }}
          >
            <CloseOutlined color="primary" />
          </IconButton>
        </Box>
      ) : (
        <Alert severity="info" sx={{ py: 2, m: 0, mt: -1 }}>Please, upload a photo to select a type</Alert>
      )}
      <RadioGroup
        values={imageTypeOptions}
        name="type"
        label="Photo Type *"
      />
    </Box>
  );
};
