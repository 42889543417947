import { Box, Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Modal, ModalActions, ModalType } from 'components/Modal';
import { ImageCrop } from './ImageCrop.component';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Area } from 'react-easy-crop';
import { ImageInput } from './ImageInput.component';
import { CloudUploadOutlined } from '@mui/icons-material';
import { ACCEPTABLE_IMAGE_FORMATS_LIST, MAX_IMAGE_UPLOAD_SIZE } from './editable-image.constants';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { createImage, fileToUrl, getCroppedImg } from './editable-image.utils';
import { missingImg } from 'assets';

export type ImageAsset = {
  imageUrl: string;
  croppedImageUrl: string;
};

export type EditImageModalProps = {
  onClose: () => void;
  imageUrl?: string;
  onSaveNewImage: (imageAsset: ImageAsset) => void;
  onDeleteImage?: () => void;
  modalTitle?: string;
  modalSx?: ModalType['sx'];
};

export const EditImageModal: React.FC<EditImageModalProps> = props => {
  const [ loading, setLoading ] = useState(false);
  const [ image, setImage ] = useState(props.imageUrl);
  const [ croppedImage, setCroppedImage ] = useState(props.imageUrl);
  const [ cropArea, setCropArea ] = useState<Area>();
  const [ isCropping, setIsCropping ] = useState(false);
  const snackbar = useAlertSnackbar();

  const onConfirmCrop = useCallback(async () => {
    setLoading(true);
    try {
      if (cropArea && image) {
        const croppedImage = await getCroppedImg(image, cropArea);

        if (croppedImage) {
          setCroppedImage(croppedImage);
        }
      }
    } catch (e) {
      setImage(props.imageUrl);
      snackbar.error('There was an error');
    } finally {
      setIsCropping(false);
      setCropArea(undefined);
      setLoading(false);
    }
  }, [ cropArea, image, props.imageUrl, snackbar ]);

  const onChooseImage = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = event.target.files?.[0];

    if (!file) {
      snackbar.error('There was an error');
      return;
    }

    if (file.size > MAX_IMAGE_UPLOAD_SIZE) {
      snackbar.error('Image size is too large');
      return;
    }

    if (!ACCEPTABLE_IMAGE_FORMATS_LIST.includes(file.type)) {
      snackbar.error('Image format is not supported');
      return;
    }

    const fileUrl = fileToUrl(file);
    const img = await createImage(fileUrl);

    if (img.width < 250) {
      snackbar.error('Image width is too small');
      return;
    }

    setImage(fileUrl);
    setIsCropping(true);
    setLoading(false);

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const isNewImage = props.imageUrl !== image || props.imageUrl !== croppedImage;
  const actions = useMemo(() => {
    if (isCropping) {
      return (
        <ModalActions>
          <Button variant="outlined" color="inherit" onClick={() => setIsCropping(false)} disableRipple disabled={isNewImage || loading}>Cancel Crop</Button>
          <Button variant="contained" onClick={() => onConfirmCrop()} disableRipple disabled={loading}>Confirm Crop</Button>
        </ModalActions>
      );
    }

    if (isNewImage && image && croppedImage) {
      return (
        <ModalActions>
          <Button variant="outlined" color="inherit" onClick={() => props.onClose()} disableRipple disabled={loading}>Cancel</Button>
          <Button variant="contained" onClick={() => props.onSaveNewImage({ imageUrl: image, croppedImageUrl: croppedImage })} disableRipple disabled={loading}>Save New Image</Button>
        </ModalActions>
      );
    }

    return null;
  }, [ croppedImage, image, isCropping, isNewImage, loading, onConfirmCrop, props ]);


  return (
    <Modal
      open
      topLeftIcon="close"
      onClose={props.onClose}
      maxWidth="md"
      sx={props.modalSx}
    >
      <DialogTitle>{props.modalTitle ?? 'Update Image'}</DialogTitle>
      <DialogContent dividers sx={theme => ({ background: theme.palette.grey[300], padding: 0  })}>
        {isCropping && <ImageCrop image={image} onCropComplete={(_, area) => setCropArea(area)} />}
        {!isCropping && (
          <Box display="flex" height="100%">
            <Box flex={1} p={2} display="flex" alignItems="center" justifyContent="center">
              <img style={{ maxWidth: '100%', height: 'auto', maxHeight: '100%' }} src={croppedImage || missingImg} alt="header" />
            </Box>
            <Box width="300px" p={2} display="flex" flexDirection="column" gap={2} bgcolor="white">
              <Box flex={1} display="flex" flexDirection="column">
                <ImageInput
                  id="photo"
                  onChange={onChooseImage}
                  disabled={loading}
                />
                <Box htmlFor="photo" component="label" sx={{ cursor: 'pointer' }} flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={3} border={theme => `1px dashed ${theme.palette.primary.main}`}>
                  <Box display="flex" gap={1}>
                    <CloudUploadOutlined color="primary" />
                    <Typography color="primary">Choose Photo</Typography>
                  </Box>
                  <Typography textAlign="center" variant="body2" color="text.secondary">
                    .jpg or .png less than 10 MB<br /> minimum width 250px
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={1}>
                <Button fullWidth variant="outlined" onClick={() => setIsCropping(true)} disabled={loading || !image}>Crop Current Image</Button>
                <Button fullWidth variant="outlined" color="error" disabled={isNewImage || loading || !image || !props.onDeleteImage} onClick={() => props.onDeleteImage?.()}>Delete Current Image</Button>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      {actions}
    </Modal>
  );
};