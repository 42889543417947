import { authAxiosRequest } from 'api/axios';
import { Team, User, Vehicle } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetTeamsResponse = {
  data: ResourceWithPopulated<Team, {
    teamLead?: PickFromResource<User, 'name'>;
  }>[];
};

export const getTeams = () => {
  return authAxiosRequest<GetTeamsResponse>('/api/teams');
};

export type GetTeamResponse = {
  data: ResourceWithPopulated<Team, {
    vehicle: PickFromResource<Vehicle, 'name' | 'mpg'>;
    teamLead: PickFromResource<User, 'name'>;
  }>;
};

export const getTeam = (id: string) => {
  return authAxiosRequest<GetTeamResponse>(`/api/teams/${id}`);
};

export type CreateTeamInput = {

};

export const createTeam = (data: CreateTeamInput) => {
  return authAxiosRequest<GetTeamResponse>('/api/teams', { method: 'POST', data });
};

export type UpdateTeamInput = {
  color?: string;
  vehicle?: string;
  teamLead?: string;
};

export const updateTeam = (id: string, data: UpdateTeamInput) => {
  return authAxiosRequest<GetTeamResponse>(`/api/teams/${id}`, { method: 'PUT', data });
};