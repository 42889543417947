import { RatingProps as MuiRatingProps, Rating as MuiRating, FormHelperText, FormControl, FormLabel, Box } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

export type RatingProps = MuiRatingProps & {
  label?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
};

export const Rating: React.FC<RatingProps> = ({ name, label, Icon, ...props }) => {
  const [ field, meta, helpers ] = useField(name);
  const error = (meta.touched && meta.error);

  return (
    <FormControl
      component="fieldset"
      error={Boolean(error)}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {Icon && <Icon color="disabled" />}{label && <FormLabel component="legend">{label}</FormLabel>}
      </Box>
      <MuiRating
        precision={0.5}
        value={field.value}
        onChange={(e, value) => {
          helpers.setValue(value);
        }}
        {...props}
      />
      {error && (
        <FormHelperText>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
