import React from 'react';
import { useEventOrganizerOutletContext } from '../EventOrganizer.base';
import { CardContentGrid, CardContentLink, CardContentMarkdown, MarkdownInput, SectionCard, SectionCardRow, TextInput } from 'components';
import { EventOrganizerPageSection, EventOrganizerPageSectionRowId } from '../types';
import { EventOrganizerSectionCardRowEditable, EventOrganizerSectionCardRowEditableList } from '../components/EventOrganizerSectionCardRowEditable.component';
import { createEventOrganizerContact, deleteEventOrganizerContact, updateEventOrganizer, updateEventOrganizerContact } from 'api/actions';
import { object, string } from 'yup';
import { getExternalLinkHref, processFormValueUpdate, removeUnchanged } from 'helpers';
import { ContactPhoneOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { EventOraganizerContactForm } from '../forms/EventOraganizerContact.form';
import { eventOrganizerContactValidationSchema } from '../validation';

export const OverviewSection: React.FC = () => {
  const { eventOrganizer } = useEventOrganizerOutletContext();

  return (
    <SectionCard title="Overview" id={EventOrganizerPageSection.OVERVIEW}>
      <EventOrganizerSectionCardRowEditable
        title="Name"
        rowId={EventOrganizerPageSectionRowId.NAME}
        formikProps={{
          initialValues: { name: eventOrganizer.name },
          onSubmit: (values) => updateEventOrganizer(eventOrganizer._id, { name: values.name?.trim() }),
          validationSchema: object({ name: string().required('Event Organizer name required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="name"
            name="name"
            label="Event Organizer Name *"
            autoFocus
          />
        )}
      >
        {eventOrganizer.name}
      </EventOrganizerSectionCardRowEditable>
      <EventOrganizerSectionCardRowEditable
        title="Website"
        rowId={EventOrganizerPageSectionRowId.WEBSITE}
        formikProps={{
          initialValues: { website: eventOrganizer.website },
          onSubmit: (values) => updateEventOrganizer(eventOrganizer._id, { website: values.website }),
          validationSchema: object({ website: string().required('Event Organizer website required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="website"
            name="website"
            label="Website *"
          />
        )}
      >
        <CardContentLink href={getExternalLinkHref(eventOrganizer.website)} title={eventOrganizer.website} openInNew />
      </EventOrganizerSectionCardRowEditable>
      <EventOrganizerSectionCardRowEditable
        title="Mailing address"
        rowId={EventOrganizerPageSectionRowId.MAILING_ADDRESS}
        formikProps={{
          initialValues: { mailingAddress: eventOrganizer.mailingAddress },
          onSubmit: (values) => updateEventOrganizer(eventOrganizer._id, { mailingAddress: values.mailingAddress }),
          validationSchema: object({ mailingAddress: string().required('Event Organizer mailing address required') })
        }}
        form={(
          <TextInput
            trim
            fullWidth
            id="mailingAddress"
            name="mailingAddress"
            label="Mailing Address *"
            multiline
            minRows={2}
          />
        )}
      >
        {eventOrganizer.mailingAddress}
      </EventOrganizerSectionCardRowEditable>
      <EventOrganizerSectionCardRowEditableList
        title="Contacts"
        listItems={eventOrganizer.contacts}
        rowId={EventOrganizerPageSectionRowId.CONTACTS}
        renderItem={(contact) => {
          return (
            <Box width="100%">
              <Box display="flex"  alignItems="center" justifyContent="space-between">
                <Box display="flex" gap={2} alignItems="center">
                  <ContactPhoneOutlined color="primary" />
                  <Typography variant="subtitle1" fontWeight={500}>{contact.name}<Box component="span" color="text.secondary" fontWeight={400}>&nbsp;&nbsp;&#40;{contact.title}&#41;</Box></Typography>
                </Box>
              </Box>
              <Box my={1} mr={4}>
                <CardContentGrid
                  items={[
                    {
                      title: 'Phone Number',
                      value: contact.phone
                    },
                    {
                      title: 'Extension',
                      value: contact.extension,
                    },
                    {
                      title: 'Email',
                      value: contact.email,
                      type: 'full',
                    },
                    {
                      title: 'Notes',
                      value: <CardContentMarkdown markdown={contact.note} />,
                      isUnassigned: !contact.note,
                      type: 'full',
                    }
                  ]}
                />
              </Box>
            </Box>
          );
        }}
        form={<EventOraganizerContactForm />}
        createButtonLabel="Contact"
        getFormikProps={(listItem) => ({
          initialValues: {
            name: listItem.name,
            title: listItem.title,
            phone: listItem.phone ?? '',
            email: listItem.email ?? '',
            extension: listItem.extension ?? '',
            note: listItem.note ?? '',
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEventOrganizerContact(eventOrganizer._id, listItem._id, {
              name: updates.name,
              title: updates.title,
              phone: processFormValueUpdate.string(updates.phone),
              email: processFormValueUpdate.string(updates.email),
              extension: processFormValueUpdate.string(updates.extension),
              note: processFormValueUpdate.string(updates.note)
            });
          },
          validationSchema: eventOrganizerContactValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            name: '',
            title: '',
            phone: '',
            email: '',
            extension: '',
            note: '',
          },
          onSubmit: (values) => createEventOrganizerContact(eventOrganizer._id, {
            name: values.name.trim(),
            title: values.title.trim(),
            phone: values.phone.trim() || undefined,
            email: values.email.trim() || undefined,
            extension: values.extension.trim() || undefined,
            note: values.note.trim() || undefined
          }),
          validationSchema: eventOrganizerContactValidationSchema,
        }}
        deleteMutationFn={(contact) => deleteEventOrganizerContact(eventOrganizer._id, contact._id)}
      />
      <EventOrganizerSectionCardRowEditable
        title="Notes"
        rowId={EventOrganizerPageSectionRowId.NOTES}
        formikProps={{
          initialValues: { notes: eventOrganizer.notes ?? '' },
          onSubmit: (values) => updateEventOrganizer(eventOrganizer._id, { notes: processFormValueUpdate.string(values.notes) }),
          validationSchema: object({ notes: string().default('') })
        }}
        form={<MarkdownInput name="notes" label="Notes" />}
      >
        <CardContentMarkdown markdown={eventOrganizer.notes} />
      </EventOrganizerSectionCardRowEditable>
      {eventOrganizer.createdBy && <SectionCardRow title="Created by">{eventOrganizer.createdBy.name}</SectionCardRow>}
    </SectionCard>
  );
};
