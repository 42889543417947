import React, { useContext } from 'react';
import { EventColorCodeConfigArg, EventsFullCalendar, FullCalendarEventContentContainer, UseFilterOutput, eventAcceptanceStatusColorCodeConfig, eventApplicationStatusColorCodeConfig, eventDatesStaffPreperationStatusColorCodeConfig, eventParticipationStatusColorCodeConfig, eventStaffPreperationStatusColorCodeConfig, useColorCode, useEventPreviewDrawerContext, useSpanEvents } from 'components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { GetEventsResponse } from 'api/actions';
import { ShowTheseFieldsEventContent, eventsShowTheseFieldsGroupEnumHelpers } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction';
import { useShowTheseFields } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/useShowTheseFields.hook';
import { showTheseFieldsConfig } from './showTheseFields';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { ResetAllActionContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/ResetAllActionContent.component';

export type EventsCalendarModalViewType = 'colorCode' | 'showTheseFields';
export type ApplicationsCalendarProps = UseFilterOutput<GetEventsResponse['data'][number]>;

type EventColorCodeOptionType =
  | 'participationStatus'
  | 'acceptanceStatus'
  | 'applicationStatus'
  | 'eventStaffPreperation'
  | 'datesStaffPreperation';

const eventColorCodeConfigHash = {
  participationStatus: eventParticipationStatusColorCodeConfig,
  acceptanceStatus: eventAcceptanceStatusColorCodeConfig,
  applicationStatus: eventApplicationStatusColorCodeConfig,
  eventStaffPreperation: eventStaffPreperationStatusColorCodeConfig,
  datesStaffPreperation: eventDatesStaffPreperationStatusColorCodeConfig,
};

export const EventsCalendar: React.FC<ApplicationsCalendarProps> = props => {
  const nodeId = EventsManagementTreeViewNodeIdEnum.eventsCalendar;
  const { events } = useContext(EventsManagementPageContext);
  const { setOpenEvent } = useEventPreviewDrawerContext();
  const {
    colorCodeOption,
    setColorCodeOption,
    modal: colorCodeModal,
    sidebarActionConfig: colorCodeSidebarActionConfig,
  } = useColorCode<EventColorCodeOptionType, EventColorCodeConfigArg>({
    id: nodeId,
    defaultValue: 'acceptanceStatus',
    colorCodeConfigHash: eventColorCodeConfigHash,
  });
  const { sidebarActionConfig: showTheseFieldsSidebarActionConfig, modal: showTheseFieldsModal, showTheseFields, onResetShowTheseFields } = useShowTheseFields({
    id: nodeId,
    config: showTheseFieldsConfig,
    defaultShowTheseFields: [ 'team', 'staff' ],
    groupEnumHelpers: eventsShowTheseFieldsGroupEnumHelpers,
  });
  const {
    spanEvents,
    setSpanEvents,
    sidebarActionConfig: spanEventsSidebarActionConfig
  } = useSpanEvents({ id: nodeId });

  const { filterFunc, modal: filtersModal, onResetFilters, sidebarActionConfig: filterSidebarActionConfig } = props;

  const onResetAll = () => {
    setSpanEvents(true);
    onResetFilters();
    setColorCodeOption('acceptanceStatus');
    onResetShowTheseFields();
  };

  return (
    <>
      {colorCodeModal}
      {showTheseFieldsModal}
      {filtersModal}

      <EventsFullCalendar
        id={nodeId}
        events={events}
        filter={event => filterFunc(event.extendedProps?.event._id || '')}
        spanEvents={spanEvents}
        onEventClick={(eventId) => setOpenEvent(eventId, 'main')}
        getEventContent={arg => {
          const { event, eventDate, group } = arg.event.extendedProps;

          const backgroundFunc = colorCodeOption ? eventColorCodeConfigHash[colorCodeOption].getColorKeyConfig({ event, eventDate }).background : undefined;

          return (
            <FullCalendarEventContentContainer
              isStart={arg.isStart}
              isEnd={arg.isEnd}
              backgroundFunc={backgroundFunc ?? (theme => theme.palette.primary.main)}
            >
              <ShowTheseFieldsEventContent
                showTheseFieldsConfig={showTheseFieldsConfig}
                showTheseFields={showTheseFields}
                title={event.name}
                extra={{ event }}
                eventDate={eventDate}
                group={group}
              />
            </FullCalendarEventContentContainer>
          );
        }}
        sidebarActionsConfig={[
          { content: <ResetAllActionContent onResetAll={onResetAll} /> },
          spanEventsSidebarActionConfig,
          filterSidebarActionConfig,
          colorCodeSidebarActionConfig,
          showTheseFieldsSidebarActionConfig
        ]}
      />
    </>
  );
};