import { authAxiosRequest, axiosInstance } from 'api/axios';
import { User } from 'api/resources';
import { ApiAxiosError } from 'api/resources/resources.types';

export type LoginUserInput = {
  email: string;
  password: string;
};

export type LoginUserResponse = {
  data: {
    accessToken: string;
    sessionId: string;
    me: User;
  };
};

export type LoginUserErrorResponse = ApiAxiosError<'deactivated' | 'invalidCredentials'>;

export const loginUser = async (data: LoginUserInput) => {
  const response = await axiosInstance.post<LoginUserResponse>('/api/sessions', data);

  return response.data;
};

export const logoutUser = () => {
  return authAxiosRequest('/api/sessions', { method: 'DELETE' });
};

export type GetMeResponse = {
  data: {
    me: User;
    sessionId: string;
  };
};

export type GetMeErrorResponse = ApiAxiosError<'deactivated'>;

export const getMe = () => {
  return authAxiosRequest<GetMeResponse>('/api/users/me');
};

export type ValidateCredentialsInput = {
  userId: string;
} & ({
  password: string;
} | {
  pin: string;
});

export const validateCredentials = (data: ValidateCredentialsInput) => {
  return authAxiosRequest<ValidateCredentialsInput>('/api/users/validate-credentials', { method: 'POST', data });
};