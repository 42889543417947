import { PageContentContainer, PageContentHeader } from 'components';
import { ProductionTable } from './components';
import { ProductsPageContextProvider } from 'contexts';

export const ProductionSchedule: React.FC = () => {

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Production Schedule"
        />
      )}
    >
      <ProductsPageContextProvider waitForTeams>
        <ProductionTable />
      </ProductsPageContextProvider>
    </PageContentContainer>
  );
};