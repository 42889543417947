import { yesNoToValueHash, yesNoUnknownToValueHash } from './yesNoUnknown/yesNoUnknown';
import { YesNoUnknownEnum, YesNoEnum, UnknownEnum } from 'types';


export const processFormValueUpdate = {
  string(value?: string) {
    if (value === undefined) {
      return undefined;
    }

    if (value.trim() === '') {
      return null;
    }

    return value.trim();
  },
  yesNoUnknown(value?: YesNoUnknownEnum | (YesNoEnum | UnknownEnum)) {
    if (value === undefined) {
      return undefined;
    }

    return yesNoUnknownToValueHash[value];
  },
  yesNo(value?: YesNoEnum | null){
    if (value === undefined) {
      return undefined;
    }

    return processFormValue.yesNoOrNull(value);
  },
  number: (value?: number | string) => {
    if (value === undefined) {
      return undefined;
    }

    if (value === 0 || (typeof value === 'string' && value.trim() === '')) {
      return null;
    }

    return Number(value);
  },
  enumWithUnknown<T extends string>(value?: T | UnknownEnum.unknown) {
    if (value === undefined) {
      return undefined;
    }

    if (value === UnknownEnum.unknown) {
      return null;
    }

    return value;
  }
} as const;

export const processFormValue = {
  yesNoOrNull(value: YesNoEnum | null) {
    if (value === null) {
      return false;
    }

    return yesNoToValueHash[value];
  }
} as const;