import { GenreGroupEnum } from 'api/resources/genre';
import { EnumHelpersWithIcon } from './types';
import { AlbumOutlined, BoltOutlined, CategoryOutlined, EmojiPeopleOutlined, LocalFireDepartmentOutlined, MemoryOutlined, MicExternalOnOutlined, MusicNoteOutlined, NaturePeopleOutlined, PianoOutlined, StarOutlined } from '@mui/icons-material';

const group: EnumHelpersWithIcon<GenreGroupEnum> = {
  enumValues: [
    GenreGroupEnum.electronic,
    GenreGroupEnum.folk,
    GenreGroupEnum.hiphop,
    GenreGroupEnum.jazz,
    GenreGroupEnum.latin,
    GenreGroupEnum.metal,
    GenreGroupEnum.other,
    GenreGroupEnum.pop,
    GenreGroupEnum.punk,
    GenreGroupEnum.rb,
    GenreGroupEnum.rock
  ],
  getLabel: (value) => {
    switch (value) {
      case GenreGroupEnum.electronic:
        return 'Electronic';
      case GenreGroupEnum.folk:
        return 'Folk';
      case GenreGroupEnum.hiphop:
        return 'Hip-Hop';
      case GenreGroupEnum.jazz:
        return 'Jazz';
      case GenreGroupEnum.latin:
        return 'Latin';
      case GenreGroupEnum.metal:
        return 'Metal';
      case GenreGroupEnum.pop:
        return 'Pop';
      case GenreGroupEnum.punk:
        return 'Punk';
      case GenreGroupEnum.rb:
        return 'R&B';
      case GenreGroupEnum.rock:
        return 'Rock';
      default:
        return 'Other';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case GenreGroupEnum.electronic:
        return MemoryOutlined;
      case GenreGroupEnum.folk:
        return NaturePeopleOutlined;
      case GenreGroupEnum.hiphop:
        return MicExternalOnOutlined;
      case GenreGroupEnum.jazz:
        return PianoOutlined;
      case GenreGroupEnum.latin:
        return EmojiPeopleOutlined;
      case GenreGroupEnum.metal:
        return BoltOutlined;
      case GenreGroupEnum.pop:
        return StarOutlined;
      case GenreGroupEnum.punk:
        return LocalFireDepartmentOutlined;
      case GenreGroupEnum.rb:
        return AlbumOutlined;
      case GenreGroupEnum.rock:
        return MusicNoteOutlined;
      default:
        return CategoryOutlined;
    }
  },
  getColor: () => 'unknown',
};

export const genreEnumHelpers = {
  group,
};