import { SVGProps } from 'react';

export type IconProps = SVGProps<SVGSVGElement> & {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const Icon: React.FC<IconProps> = props => {
  if(props.className?.toLowerCase().includes('small') || props.fontSize === 'small') {
    return <props.Icon {...props} width="20px" height="20px" />;
  }

  if(props.className?.toLowerCase().includes('large') || props.fontSize === 'large') {
    return <props.Icon {...props} width="35px" height="35px" />;
  }

  return <props.Icon {...props} width="24px" height="24px" />;
};