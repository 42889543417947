import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { GetInventoryBatchResponse, getInventoryBatch, getInventoryBatches } from 'api/actions';

export const useInventoryBatches = () => {
  return useQuery(QUERY_KEY.INVENTORY_BATCHES, async () => {
    const response = await getInventoryBatches();

    return response.data;
  });
};

export const useInventoryBatch = (id: string, opts?: UseQueryOptions<GetInventoryBatchResponse['data']>) => {
  return useQuery(QUERY_KEY.INVENTORY_BATCH(id), async () => {
    const response = await getInventoryBatch(id);

    return response.data;
  }, opts);
};