import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { TextInput, FormDateTimePicker } from 'components';
import { useFormikContext } from 'formik';
import { formSx } from 'styles';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';
import { useUsersAsResourceOptions } from 'queries/user';

export const EventToDoForm = () => {
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const { values, setFieldValue, touched, errors }= useFormikContext<EventSectionRowForm[EventPageSectionRowId.TO_DO]>();

  const handleAssigneesChange = (event: SelectChangeEvent<string[]>) => {
    setFieldValue('assignees', event.target.value);
  };

  const renderValue = (selected: string[]) => (
    <Box display="flex" flexWrap="wrap" gap={0.5}>
      {selected.map(value => {
        const user = users.find(user => user._id === value);

        return <Chip key={value} label={user ? user.name : value} size="small" />;
      })}
    </Box>
  );

  return (
    <Box sx={formSx.formGroup}>
      <TextInput name="title" label="Title" trim />
      <TextInput name="description" label="Description" multiline minRows={2} trim />
      <FormDateTimePicker name="date" label="Reminder" helperText="All assignees will get a reminder email"/>
      <FormControl fullWidth error={touched.assignees && Boolean(errors.assignees)}>
        <InputLabel id="assignees-label">Assignees</InputLabel>
        <Select
          labelId="assignees-label"
          multiple
          value={values.assignees}
          onChange={handleAssigneesChange}
          input={<OutlinedInput id="assignees-chip" label="Assignees" />}
          renderValue={renderValue}
          disabled={usersLoading}
        >
          {users.map(user => (
            <MenuItem key={user._id} value={user._id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
