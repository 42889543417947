import { RadioGroupYesNoBoolean, SectionCard } from 'components';
import React from 'react';
import { ProductPageSection, ProductPageSectionRowId } from '../types';
import { ProductSectionCardRowEditable } from '../components/ProductSectionCardRow.component';
import { useProductOutletContext } from '../Product.base';
import { getYesNoUnknownFromValue, processFormValueUpdate } from 'helpers';
import { updateProduct } from 'api/actions';
import { object } from 'yup';
import { yesNoUnknownSchema } from 'types';
import { ProductPageSectionCardRowYesNoUnknownContent } from '../components/ProductPageSectionCardRowYesNoUnknownContent.component';

export const PreparationSection = () => {
  const { product } = useProductOutletContext();

  return (
    <SectionCard title="Preparation" id={ProductPageSection.PREPARATION}>
      <ProductSectionCardRowEditable
        title="AI File"
        rowId={ProductPageSectionRowId.RASTER_LAYER}
        formikProps={{
          initialValues: { rasterLayer: getYesNoUnknownFromValue(product.preperation?.rasterLayer)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { rasterLayer: processFormValueUpdate.yesNoUnknown(values.rasterLayer) } }),
          validationSchema: object({ rasterLayer: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="rasterLayer" label="AI File" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.rasterLayer} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Label File"
        rowId={ProductPageSectionRowId.CENTER_LABEL}
        formikProps={{
          initialValues: { centerLabel: getYesNoUnknownFromValue(product.preperation?.centerLabel)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { centerLabel: processFormValueUpdate.yesNoUnknown(values.centerLabel) } }),
          validationSchema: object({ centerLabel: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="centerLabel" label="Label File" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.centerLabel} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="PNG"
        rowId={ProductPageSectionRowId.PNG}
        formikProps={{
          initialValues: { png: getYesNoUnknownFromValue(product.preperation?.png)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { png: processFormValueUpdate.yesNoUnknown(values.png) } }),
          validationSchema: object({ png: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="png" label="PNG" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.png} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="DMC"
        rowId={ProductPageSectionRowId.DMC}
        formikProps={{
          initialValues: { dmc: getYesNoUnknownFromValue(product.preperation?.dmc)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { dmc: processFormValueUpdate.yesNoUnknown(values.dmc) } }),
          validationSchema: object({ dmc: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="dmc" label="DMC" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.dmc} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="DWG"
        rowId={ProductPageSectionRowId.DWG}
        formikProps={{
          initialValues: { dwg: getYesNoUnknownFromValue(product.preperation?.dwg)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { dwg: processFormValueUpdate.yesNoUnknown(values.dwg) } }),
          validationSchema: object({ dwg: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="dwg" label="DWG" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.dwg} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Divider"
        rowId={ProductPageSectionRowId.DIVIDER}
        formikProps={{
          initialValues: { divider: getYesNoUnknownFromValue(product.preperation?.divider)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { divider: processFormValueUpdate.yesNoUnknown(values.divider) } }),
          validationSchema: object({ divider: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="divider" label="Divider" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.divider} text="Created" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Website PNGs"
        rowId={ProductPageSectionRowId.WEBSITE_PNGS}
        formikProps={{
          initialValues: { websitePngs: getYesNoUnknownFromValue(product.preperation?.websitePngs)  },
          onSubmit: (values) => updateProduct(product._id, { preperation: { websitePngs: processFormValueUpdate.yesNoUnknown(values.websitePngs) } }),
          validationSchema: object({ websitePngs: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="websitePngs" label="Website PNGs" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.preperation?.websitePngs} text="Created" />
      </ProductSectionCardRowEditable>
    </SectionCard>
  );
};