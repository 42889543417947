import { FilterAltOutlined, SwapHorizOutlined, ToggleOnOutlined, TuneOutlined } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Modal } from 'components';
import React from 'react';
import { EditTypeTooltip } from './EditType.component';

export type EditSettingsModalProps = {
  open: boolean;
  onClose: () => void;
  teamSelectionInput?: React.ReactNode;
  showNotAtEventsInput?: React.ReactNode;
  editTypeInput?: React.ReactNode;
  transferModeInput?: React.ReactNode;

  otherFilters?: React.ReactNode;
  title?: string;
};

export const EditSettingsModal: React.FC<EditSettingsModalProps> = ({ title = 'Edit Mode Settings', ...props }) => {

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      topLeftIcon="close"
      autoHeight
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap={3} pb={3} pt={1}>
          <EditSettingsModalSection
            icon={<ToggleOnOutlined />}
            title="Team Selection"
          >
            {props.teamSelectionInput}
          </EditSettingsModalSection>
          <EditSettingsModalSection
            icon={<FilterAltOutlined />}
            title="Filters"
          >
            {props.showNotAtEventsInput}
            {props.otherFilters}
          </EditSettingsModalSection>
          <EditSettingsModalSection
            icon={<TuneOutlined />}
            title="Edit Type"
            tooltip={<EditTypeTooltip />}
          >
            {props.editTypeInput}
          </EditSettingsModalSection>
          <EditSettingsModalSection
            icon={<SwapHorizOutlined />}
            title="Transfer Mode (Admin-only)"
          >
            {props.transferModeInput}
          </EditSettingsModalSection>
        </Box>
      </DialogContent>
    </Modal>
  );
};


type EditSettingsModalSectionProps = {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
};

const EditSettingsModalSection: React.FC<EditSettingsModalSectionProps> = props => {

  if (!props.children) {
    return null;
  }

  return (
    <Box display="flex" ml={{ xs: 0, sm: -1 }}>
      <Box mr={3} sx={{ display: { xs: 'none', sm: 'unset' } }}>
        {props.icon}
      </Box>
      <Box flex={1}>
        <Box display="flex" alignItems="center" gap={2} mb={1}>
          <Typography fontWeight={500}>{props.title}</Typography>
          {props.tooltip}
        </Box>
        <Box display="flex" flexDirection="column">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};