import { RestartAltOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

export type ResetAllActionContentProps = {
  onResetAll: () => void;
};

export const ResetAllActionContent: React.FC<ResetAllActionContentProps> = props => {
  return (
    <Button
      onClick={() => props.onResetAll()}
      color="primary"
      variant="outlined"
      startIcon={<RestartAltOutlined />}
      size="small"
    >
      Reset All
    </Button>
  );
};