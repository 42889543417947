import { Box, Container, ContainerProps, LinearProgress } from '@mui/material';
import { PageContentBreadcrumbs } from './PageContentBreadcrumbs.component';
import React, { useMemo } from 'react';
import { PageContentHeaderProps, PageContentResourceHeaderProps } from './PageContentHeader.component';

export type PageContentContainerBreadcrumb = {
  link: string;
  text: string;
};

export type PageContentContainerProps = {
  maxWidth?: ContainerProps['maxWidth'];
  header?: React.ReactElement<PageContentResourceHeaderProps | PageContentHeaderProps>;
  breadcrumbs?: {
    prev: PageContentContainerBreadcrumb[];
    current: string;
  };
  children?: React.ReactNode;
  loading?: boolean;
  contentSx?: ContainerProps['sx'];
  preventContainer?: boolean;
};

export const PageContentContainer: React.FC<PageContentContainerProps> = ({
  maxWidth = false,
  ...props
}) => {

  const content = useMemo(() => {
    if (props.loading) {
      return <LinearProgress />;
    }

    if (props.preventContainer) {
      return <Box flex={1}>{props.children}</Box>;
    }

    return (
      <Container maxWidth={maxWidth} sx={{ pt: 2, pb: 4, flex: 1, ...props.contentSx }}>
        {props.children}
      </Container>
    );
  }, [ maxWidth, props.children, props.contentSx, props.loading, props.preventContainer ]);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {props.breadcrumbs && <PageContentBreadcrumbs breadcrumbs={props.breadcrumbs} />}
      {props.header && (
        React.Children.map(props.header, child => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child, { maxWidth });
        })
      )}
      {content}
    </Box>
  );
};