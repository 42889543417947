import { Theme, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';

export type UseContainerQueryProps = {
  containerRef: React.RefObject<HTMLElement>;
  query: ((theme: Theme) => string) | string;
};

const parseQuery = (queryString: string) => {
  const minWidthMatch = queryString.match(/min-width:\s*(\d+\.?\d*)px/);
  const maxWidthMatch = queryString.match(/max-width:\s*(\d+\.?\d*)px/);

  return {
    minWidth: minWidthMatch ? parseFloat(minWidthMatch[1]) : undefined,
    maxWidth: maxWidthMatch ? parseFloat(maxWidthMatch[1]) : undefined
  };
};

export const useContainerQuery = ({ containerRef, query }: UseContainerQueryProps) => {
  const theme = useTheme();
  const [ matches, setMatches ] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const evaluateQuery = () => {
      const queryString = typeof query === 'function' ? query(theme) : query;
      const { minWidth, maxWidth } = parseQuery(queryString);
      const { width } = container.getBoundingClientRect();
      const match = (minWidth === undefined || width >= minWidth) && (maxWidth === undefined || width <= maxWidth);

      setMatches(match);
    };

    const observer = new ResizeObserver(() => {
      evaluateQuery();
    });

    observer.observe(container);

    evaluateQuery();

    return () => observer.disconnect();
  }, [ containerRef, query, theme ]);

  return matches;
};