import { createContext, useContext, useState } from 'react';

type SectionCardsEditContextType = {
  editRowId: string | null;
  setEditRowId: (key: string | null) => void;
};

const defaultContext: SectionCardsEditContextType = {
  editRowId: null,
  setEditRowId: () => {},
};

const SectionCardEditContext = createContext<SectionCardsEditContextType>(defaultContext);

export type SectionCardsEditContextProviderProps = {
  children: React.ReactNode;
};

export const SectionCardsEditContextProvider: React.FC<SectionCardsEditContextProviderProps> = props => {
  const [ editRowId, setEditRowId ] = useState<string | null>(null);

  return (
    <SectionCardEditContext.Provider value={{ editRowId, setEditRowId }}>
      {props.children}
    </SectionCardEditContext.Provider>
  );
};

export const useSectionCardsEditContext = () => {
  const context = useContext(SectionCardEditContext);

  return context ?? defaultContext;
};