import { mixed } from 'yup';

export const enum YesNoUnknownEnum {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

export type YesNoUnknownValueType = boolean | null;

export const yesNoUnknownSchema = mixed<YesNoUnknownEnum>()
  .oneOf([ YesNoUnknownEnum.yes, YesNoUnknownEnum.no, YesNoUnknownEnum.unknown ])
  .nullable()
  .required('Please choose an option');