import { Box, Button, FormLabel, ToggleButton, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Autocomplete, PasswordTextInput, PinInput, ToggleButtonGroup } from 'components';
import { formSx } from 'styles';
import { User } from 'api/resources';
import { ResourceForm } from 'types';
import { useUsersAsResourceOptions } from 'queries/user';
import { useCurrentUser } from 'contexts';

export type UserCredentialsFormProps = { users?: User[]; firstStepText?: string };

export const UserCredentialsForm: React.FC<UserCredentialsFormProps> = props => {
  const { getIsMe } = useCurrentUser();
  const { values, setFieldValue } = useFormikContext<ResourceForm['validateCredentials']>();
  const { data = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const users = (props.users ?? data).sort(user => getIsMe(user._id) ? -1 : 1);

  const credentialInput = useMemo(() => {
    const disabled = !values.userId;

    if (values.credentialType === 'pin') {
      return (
        <PinInput name="pin" disabled={disabled} autoFocus />
      );
    }

    return <PasswordTextInput name="password" disabled={disabled} />;
  }, [ values.credentialType, values.userId ]);

  const userInput = useMemo(() => {
    if (users.length <= 6) {
      return (
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1} maxHeight={170} overflow="auto">
          {
            users.map((user) => {
              const { name, _id, email } = user;
              const isSelected = values.userId === _id;

              return (
                <Button
                  key={_id}
                  variant={isSelected ? 'outlined' : 'text'}
                  color={isSelected ? 'primary' : 'inherit'}
                  sx={(theme) => ({
                    p: 1,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: isSelected ? undefined : theme.palette.divider,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: isSelected ? theme.palette.primary.background : 'transparent',
                  })}
                  onClick={() => setFieldValue('userId', _id)}
                >
                  <Typography textTransform="none">{name}</Typography>
                  <Typography variant="body2" textTransform="none">{email}</Typography>
                </Button>
              );
            })
          }
        </Box>
      );
    }

    return (
      <Autocomplete
        name="userId"
        label="User"
        options={users}
        getOptionLabel={(opt) => opt?.name}
        transformValue={(opt) => opt?._id}
        loading={usersLoading}
      />
    );
  }, [ setFieldValue, users, usersLoading, values.userId ]);

  return (
    <Box sx={formSx.formGroup}>

      <FormLabel component="div" sx={{ pb: 0.5 }}>1)&nbsp;{props.firstStepText ?? 'Select a user'}</FormLabel>
      {userInput}

      <ToggleButtonGroup
        name="credentialType"
        color="primary"
        exclusive
        fullWidth
        label="2) Select which crendential input to use"
      >
        <ToggleButton value="pin">Use Pin</ToggleButton>
        <ToggleButton value="password">Use Password</ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
        <FormLabel component="div" sx={{ pb: 1 }}>3) Type in your {values.credentialType}</FormLabel>
        {credentialInput}
      </Box>

    </Box>
  );
};
