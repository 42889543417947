import { ResourceForm } from 'types';
import { SchemaOf, object, string } from 'yup';

export const eventOrganizerValidationSchema = (): SchemaOf<ResourceForm['eventOrganizer']> => {
  return object({
    name: string().required('Event Organizer Name is required'),
    website: string().required('Website is required'),
    mailingAddress: string().required('Mailing Address is required'),
    notes: string().default(''),
  });
};

//EVENT ORGANIZER CONTACT
const phoneRegExp = new RegExp(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/);

export const eventOrganizerContactValidationSchema = (): SchemaOf<ResourceForm['eventOrganizerContact']> => {
  return object({
    name: string().required('Contact Name is required'),
    title: string().required('Contact Title is required'),
    phone: string().matches(phoneRegExp, { message: 'Phone number is not valid', excludeEmptyString: true }).default(''),
    email: string().email('Email is not valid').default(''),
    note: string().default(''),
  });
};