import { AnalyticsTimelineChartConfig } from 'components';
import { useAnalyticsPageContext } from '../analytics.context';
import { DateService } from 'services';
import { DataType } from './types';
import { useMemo } from 'react';

export type UseAnalyticsTimelineChartDataWithCompareProps<T extends 'sales' | 'updateLogs'> = {
  type: T;
  datasetsConfigs?: AnalyticsTimelineChartConfig<DataType<T>>['datasetsConfigs'];
} & (T extends 'updateLogs' ? { getValue: AnalyticsTimelineChartConfig<DataType<T>>['baseConfig']['getValue'] } : {});

const isSpecificType = <T extends 'sales' | 'updateLogs'>(
  obj: UseAnalyticsTimelineChartDataWithCompareProps<'sales' | 'updateLogs'>,
  type: T
): obj is UseAnalyticsTimelineChartDataWithCompareProps<T> => {
  return obj.type === type;
};

export const useAnalyticsTimelineChartConfigWithCompare = <T extends 'sales' | 'updateLogs'>(props: UseAnalyticsTimelineChartDataWithCompareProps<T>) => {
  const { sales, compareSales, updateLogs, compareUpdateLogs, dateRange, compareDateRange } = useAnalyticsPageContext();

  const config = useMemo(() => {
    if(isSpecificType(props, 'updateLogs')) {
      const config: AnalyticsTimelineChartConfig<DataType<'updateLogs'>> = {
        baseConfig: {
          data: updateLogs,
          dateRange,
          getValue: props.getValue,
          getDate: (element) => DateService.dayjs(element.createdAt)
        },
        datasetsConfigs: props.datasetsConfigs
      };

      if (compareDateRange) {
        config.compareConfigs = [ { data: compareUpdateLogs,  dateRange: compareDateRange } ];
      }

      return config;
    }

    if(isSpecificType(props, 'sales')) {
      const config: AnalyticsTimelineChartConfig<DataType<'sales'>> = {
        baseConfig: {
          data: sales,
          dateRange,
          getValue: (() => 1),
          getDate: (element) => DateService.dayjs(element.saleDate)
        },
        datasetsConfigs: props.datasetsConfigs
      };

      if (compareDateRange) {
        config.compareConfigs = [ { data: compareSales,  dateRange: compareDateRange } ];
      }

      return config;
    }

    // empty config
    return {
      config: {
        baseConfig: { data: [], dateRange: { start: DateService.dayjs(), end: DateService.dayjs() }, getValue: () => 1, getDate: () => DateService.dayjs() },
        datasetsConfigs: [],
      }
    };
  }, [ compareDateRange, compareSales, compareUpdateLogs, dateRange, props, sales, updateLogs ]);

  return { config: config as AnalyticsTimelineChartConfig<DataType<T>> };
};