import { UpdateResourceModal, UpdateResourceModalProps, useAlertSnackbar } from 'components';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';
import { Formik, FormikProps } from 'formik';
import { ProductStockUpdateLogTypeEnum } from 'api/resources';
import { DamagedOrMissingEnum, InventoryDamagedForm } from './InventoryDamagedForm.form';
import { useMemo, useRef } from 'react';
import { useProducts } from 'queries';
import { SchemaOf, object, string, number, mixed } from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProductStocksQuantity } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';

export type InventoryDamagedStateType = {
  productId: string | null;
  teamId: string | null;
  amount: number;
  damagedOrMissing: DamagedOrMissingEnum | null;
};

type InventoryDamagedValidationSchema = () => SchemaOf<Omit<InventoryDamagedStateType, 'userId'>>;
const inventoryDamagedValidationSchema: InventoryDamagedValidationSchema = () => {
  return object({
    productId: string().nullable().required('Product required'),
    teamId: string().nullable().required('Team required'),
    amount: number().required(),
    damagedOrMissing: mixed<DamagedOrMissingEnum>().oneOf([ DamagedOrMissingEnum.damaged, DamagedOrMissingEnum.missing ]).required('Damaged or Missing selection is requred'),
  });
};

export type InventoryDamagedModalProps = Pick<UpdateResourceModalProps, 'onClose' | 'open'>;

export const InventoryDamagedModal: React.FC<InventoryDamagedModalProps> = props => {
  const alert = useAlertSnackbar();
  const queryClient = useQueryClient();
  const formikRef = useRef<FormikProps<InventoryDamagedStateType> | null>();
  const updateStocksMutation = useMutation({
    mutationFn: updateProductStocksQuantity,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      alert.success('Inventory updated');
    }
  });
  const { data: products = [] } = useProducts();
  const userIdRef = useRef<string | null>(null);

  const initialInventoryDamagedState: InventoryDamagedStateType = useMemo(() => ({
    productId: null,
    teamId: null,
    amount: -1,
    damagedOrMissing: null,
  }), []);
  const onSubmit = async (values: InventoryDamagedStateType) => {
    const { productId, teamId, amount } = values;

    if (!productId || !teamId || !userIdRef.current) {
      return;
    }

    const product = products.find((product) => product._id === productId);

    if (!product) return;

    const stock = product.stocks[teamId];

    await updateStocksMutation.mutateAsync({
      stocks: [ {
        _id: stock._id,
        updateAmount: amount,
        updatedQuantity: stock.quantity + amount,
        type: values.damagedOrMissing === DamagedOrMissingEnum.damaged ? ProductStockUpdateLogTypeEnum.damaged : ProductStockUpdateLogTypeEnum.missing
      } ],
      userId: userIdRef.current
    });

    formikRef.current?.setFormikState((prevState) => ({ ...prevState, values: initialInventoryDamagedState }));
    props.onClose();
  };

  return (
    <Formik
      innerRef={(f) => formikRef.current = f}
      onSubmit={onSubmit}
      initialValues={initialInventoryDamagedState}
      validationSchema={inventoryDamagedValidationSchema}
      validateOnMount
    >
      {(formik) => {

        return (
          <UpdateResourceModal
            open={props.open}
            onClose={props.onClose}
            onSave={(userId) => {
              userIdRef.current = userId;
              formik.handleSubmit();
            }}
            title="Damaged or Missing Inventory"
            loading={formik.isSubmitting}
            sx={{ zIndex: FULL_SCREEN_Z_INDEX + 1 }}
            requireCredentials
            userCredentialsFormProps={{ firstStepText: `Select who is logging ${formik.values.damagedOrMissing} inventory` }}
          >
            <InventoryDamagedForm {...formik} products={products} />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};
