import { Avatar, Box, Hidden, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useLogout } from 'app/auth/use-logout';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInitialsForAvatar } from 'services';

export const AppbarProfileContent: React.FC = () => {
  const navigate = useNavigate();
  const { logoutUserHandler } = useLogout();
  const me = useCurrentUser();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const openMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const onProfileClick = () => {
    navigate(`/${ROUTING_CONFIG.profile}`);
    closeMenuHandler();
  };

  return (
    <Box display="flex" alignItems="center">

      <Hidden smDown>
        <Box mr={1} textAlign="right" mt={0.5}>
          <Typography sx={{ lineHeight: '20px' }}>
            {me.name}
          </Typography>
          <Typography variant="body2" color={theme => theme.palette.grey[200]} sx={{ lineHeight: '18px' }}>
            {me.email}
          </Typography>
        </Box>
      </Hidden>
      <IconButton onClick={openMenuHandler}>
        <Avatar src={me.profileImageUrl}>{me.name && getUserInitialsForAvatar(me.name)}</Avatar>
      </IconButton>
      <Menu
        sx={{
          mt: 6,
          '& .MuiMenu-paper': {
            width: '200px',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={closeMenuHandler}
      >
        <MenuItem onClick={onProfileClick} divider>
          <Typography>Profile</Typography>
        </MenuItem>
        <MenuItem onClick={logoutUserHandler}>
          <Typography color="error.dark">Log Out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};