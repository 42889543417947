import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import React from 'react';
import { AnalyticsChart, AnalyticsChartTypeEnum  } from 'components';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';

export const SalesFrameChart = () => {
  const { salesLoading } = useAnalyticsPageContext();
  const { config } = useAnalyticsChartDataWithCompare({
    type: 'sales',
    baseConfig: { getValue: () => 1, getLabel: (sale) => sale.frame ? 'With a frame' : 'Without a frame', getId: (sale) => sale.frame ? 'frame' : 'noFrame' },
  });

  return (
    <AnalyticsChart
      header="Frame"
      config={config}
      allowedViewTypes={[ AnalyticsChartTypeEnum.bar, AnalyticsChartTypeEnum.pie ]}
      colorMode="element"
      initialChartType={AnalyticsChartTypeEnum.pie}
      loading={salesLoading}
    />
  );
};
