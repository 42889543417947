import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SectionCard, useAlertSnackbar } from 'components';
import { VehiclePageSection, VehiclePageSectionProps, VehiclePageSectionRowId } from '../types';
import { createVehicleMaintenanceLog, deleteVehicleMaintenanceLog, updateVehicleMaintenanceLog } from 'api/actions';
import { VehicleSectionCardRowEditableList } from '../components/VehicleSectionCardRowEditable.component';
import { Box, Checkbox, ListItem, ListItemText, Typography } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { DateService } from 'services';
import { VehicleMaintenanceLogForm } from '../forms/VehicleMaintenanceLog.form';
import { processFormValueUpdate, removeUnchanged } from 'helpers';
import { vehicleMaintenanceLogValidationSchema } from '../forms/validation';
import { useUsersAsResourceOptions } from 'queries/user';
import { UserRoleEnum } from 'api/resources';
import { QUERY_KEY } from 'queries/query-keys';

export const MaintenanceSection: React.FC<VehiclePageSectionProps> = ({ vehicle }) => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const adminUserIds = users.filter(user => user.role === UserRoleEnum.admin).map(user => user._id);
  const updateVehicleMaintenanceLogMutation = useMutation({
    mutationFn: async (maintenanceLogId: string) => {
      if (!vehicle.maintenanceLog) {
        return;
      }

      const completed = vehicle.maintenanceLog.filter(({ _id }) => _id === maintenanceLogId)[0].completed;

      return updateVehicleMaintenanceLog(vehicle._id, maintenanceLogId, { completed: !completed });
    },
    onSuccess: async (_, maintenanceLogId) => {
      const wasCompleted = vehicle.maintenanceLog?.filter(({ _id }) => _id === maintenanceLogId)[0].completed;

      await queryClient.invalidateQueries(QUERY_KEY.VEHICLE(vehicle._id));

      snackbar.success(`Maintenance log toggled to ${wasCompleted ? '"not done"' : '"done"'}`);
    },
    onError: async (_) => {
      snackbar.success('Could not toggle maintenance log');
    }
  });

  return (
    <SectionCard title="Maintenance" id={VehiclePageSection.MAINTENANCE}>
      <VehicleSectionCardRowEditableList
        title="Maintenance Logs"
        listItems={vehicle.maintenanceLog ?? []}
        rowId={VehiclePageSectionRowId.MAINTENANCE_LOGS}
        vehicle={vehicle}
        renderItem={(maintenanceLog) => {
          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <Box display="flex" gap={1} alignItems="center">
                <Checkbox
                  checked={maintenanceLog.completed}
                  onChange={() => updateVehicleMaintenanceLogMutation.mutate(maintenanceLog._id)}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircle />}
                  disabled={updateVehicleMaintenanceLogMutation.isLoading}
                />
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography sx={{ textDecoration: maintenanceLog.completed ? 'line-through' : 'none' }} width="fit-content">
                      {maintenanceLog.title}
                    </Typography>
                  }
                  secondary={(
                    <Typography variant="body2" sx={{ textDecoration: maintenanceLog.completed ? 'line-through' : 'none' }} width="fit-content">
                      {maintenanceLog.description && maintenanceLog.description + '.\n'}
                      {maintenanceLog.description && <br />}
                      {maintenanceLog.dateAsUtc && 'Reminder on '}
                      <strong>{maintenanceLog.dateAsUtc && DateService.getFormattedDate(DateService.dayjs(maintenanceLog.dateAsUtc), 'MMM Do, YYYY h:mma') + '. '}</strong>
                      {maintenanceLog.dateAsUtc && <br />}
                      Assignees:&nbsp;
                      <strong>{maintenanceLog.assignees.map(assignee => assignee.name).join(', ')}</strong>
                    </Typography>
                  )}
                />
              </Box>
            </ListItem>
          );
        }}
        form={<VehicleMaintenanceLogForm users={users} usersLoading={usersLoading} />}
        createButtonLabel="Maintenance Log"
        orderBy={{
          getField: (listItem) => listItem.dateAsUtc ? listItem.dateAsUtc : '',
          direction: 'desc',
        }}
        getFormikProps={(listItem) => ({
          initialValues: {
            title: listItem.title,
            date: listItem.dateAsUtc ? DateService.dayjs(listItem.dateAsUtc).toISOString() :  null,
            description: listItem.description ?? '',
            assignees: listItem.assignees.map(({ _id }) => _id) ?? [],
          },
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateVehicleMaintenanceLog(vehicle._id, listItem._id, {
              title: updates.title,
              date: updates.date,
              description: processFormValueUpdate.string(updates.description),
              assignees: updates.assignees as string[] | undefined,
            });
          },
          validationSchema: vehicleMaintenanceLogValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            title: '',
            date: null,
            description: '',
            assignees: adminUserIds,
          },
          onSubmit: (values) => createVehicleMaintenanceLog(vehicle._id, {
            title: values.title,
            date: values.date ?? undefined,
            description: processFormValueUpdate.string(values.description) || undefined,
            assignees: values.assignees,
          }),
          validationSchema: vehicleMaintenanceLogValidationSchema,
        }}
        deleteMutationFn={(maintenanceLog) => deleteVehicleMaintenanceLog(vehicle._id, maintenanceLog._id)}
      />
    </SectionCard>
  );
};
