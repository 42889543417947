import { EventDate, EventDateTypeEnum } from 'api/resources';
import { DateObjectType, DateService } from 'services';
import { getEventLodgingPlaceForDate } from 'helpers';
import { AirportShuttleOutlined, HardwareOutlined, Inventory2Outlined, StorefrontOutlined } from '@mui/icons-material';
import { UnknownEnum } from 'types';
import { EventDateTimePeriodTypeEnum } from './event-date-time-period-enum';
import { GetEventResponse } from 'api/actions';
import { FunctionComponent, SVGProps } from 'react';

export type EventDateTimelineTimePointPrimary = {
  type: 'primary';
  time: string;
  label: string;
  followingTimePeriodType: EventDateTimePeriodTypeEnum | UnknownEnum;
};

export type EventDateTimelineTimePointSecondary = {
  type: 'secondary';
  time: string;
  label: string;
  followingTimePeriodType?: never;
};

export type EventDateTimelineTimePoint = EventDateTimelineTimePointPrimary | EventDateTimelineTimePointSecondary;

export type GetEventDateTimelineConfigsProps = {
  eventDate: Omit<EventDate, 'staff' | 'createdBy'>;
  event: Pick<GetEventResponse['data'], 'lodging' | 'place'>;
};

export type EventDateTimelineConfig = {
  label: string;
  LabelIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
  times: EventDateTimelineTimePoint[];
  showLineBefore?: boolean;
  showLineAfter?: boolean;
};

export const getEventDateTimelineConfigs = (props: GetEventDateTimelineConfigsProps): EventDateTimelineConfig[] => {
  const { eventDate } = props;

  switch (eventDate.type) {
    case EventDateTypeEnum.regular:
      return [
        {
          label: 'Pre-show',
          LabelIcon: Inventory2Outlined,
          times: getRegularEventDateTimelinePreShowTimes(props),
        },
        {
          label: 'Show',
          LabelIcon: StorefrontOutlined,
          times: getEventDateTimelineMainTimes(props),
        },
        {
          label: 'Post-show',
          LabelIcon: AirportShuttleOutlined,
          times: getRegularEventDateTimelinePostShowTimes(props),
        },
      ];
    case EventDateTypeEnum.setup:
    case EventDateTypeEnum.breakdown:
      return [
        {
          label: 'To show',
          LabelIcon: AirportShuttleOutlined,
          times: getToShowTravelTimes(props, DateService.combineDateAsUtcAndTime(props.eventDate.dateAsUtc, props.eventDate.startTime)).withTime
        },
        {
          label: eventDate.type === EventDateTypeEnum.setup ? 'Setup' : 'Breakdown',
          LabelIcon: HardwareOutlined,
          times: getEventDateTimelineMainTimes(props),
        },
        {
          label: 'From show',
          LabelIcon: AirportShuttleOutlined,
          times: getFromShowTravelTimes(props, DateService.combineDateAsUtcAndTime(props.eventDate.dateAsUtc, props.eventDate.endTime)).withTime
        },
      ];
    case EventDateTypeEnum.travel:
    default:
      return [];
  }
};

const getEventTravelTimeInSeconds = (props: GetEventDateTimelineConfigsProps, dateAndTime: DateObjectType) => {
  const eventLodgingPlace = getEventLodgingPlaceForDate(props.event, dateAndTime);
  const previousLodgingPlace = props.event.lodging?.places?.find(lodgingPlace => {
    const dayDiff = dateAndTime.diff(DateService.dayjsTz(lodgingPlace.checkOut), 'day');

    if (!lodgingPlace.checkIn && !lodgingPlace.checkOut) {
      return false;
    }

    return dayDiff === 0;
  });
  const lodgingPlace = (eventLodgingPlace ?? previousLodgingPlace);

  return lodgingPlace ? lodgingPlace.place.toEventMatrix.duration : props.event.place.fromStudioMatrix.duration;
};

export const getToShowTravelTimes = (props: GetEventDateTimelineConfigsProps, arriveBy: DateObjectType, followingTimePeriodType: EventDateTimePeriodTypeEnum | UnknownEnum = UnknownEnum.unknown) => {
  const travelTimeInSeconds = getEventTravelTimeInSeconds(props, DateService.combineDateAsUtcAndTime(props.eventDate.dateAsUtc, '00:00'));
  const departure = arriveBy.subtract(travelTimeInSeconds, 'seconds').subtract(props.eventDate.bufferTimeInMinutes, 'minutes');

  return {
    withDateTimeObject: [
      { dateTimeObj: departure, label: 'Departure', followingTimePeriodType: EventDateTimePeriodTypeEnum.travel },
      { dateTimeObj: arriveBy, label: 'Arrival', followingTimePeriodType },
    ],
    withTime: [
      { type: 'primary' as const, time: departure.format('HH:mm'), label: 'Departure', followingTimePeriodType: EventDateTimePeriodTypeEnum.travel },
      { type: 'primary' as const, time: arriveBy.format('HH:mm'), label: 'Arrival', followingTimePeriodType },
    ]
  };
};

export const getFromShowTravelTimes = (props: GetEventDateTimelineConfigsProps, departure: DateObjectType) => {
  const travelTimeInSeconds = getEventTravelTimeInSeconds(props, DateService.combineDateAsUtcAndTime(props.eventDate.dateAsUtc,'23:59'));
  const arrival = departure.add(travelTimeInSeconds, 'seconds').add(props.eventDate.bufferTimeInMinutes, 'minutes');

  return {
    withDateTimeObject: [
      { dateTimeObj: departure, label: 'Departure', followingTimePeriodType: EventDateTimePeriodTypeEnum.travel },
      { dateTimeObj: arrival, label: 'Arrival', followingTimePeriodType: UnknownEnum.unknown },
    ],
    withTime: [
      { type: 'primary' as const, time: departure.format('HH:mm'), label: 'Departure', followingTimePeriodType: EventDateTimePeriodTypeEnum.travel },
      { type: 'primary' as const, time: arrival.format('HH:mm'), label: 'Arrival', followingTimePeriodType: UnknownEnum.unknown },
    ]
  };
};

const getEventDateTimelineMainTimes = (props: GetEventDateTimelineConfigsProps) => {
  let timePeriodType = EventDateTimePeriodTypeEnum.sales;

  if (props.eventDate.type === EventDateTypeEnum.setup) {
    timePeriodType = EventDateTimePeriodTypeEnum.setup;
  }
  if (props.eventDate.type === EventDateTypeEnum.breakdown) {
    timePeriodType = EventDateTimePeriodTypeEnum.breakdown;
  }

  return [
    {
      type: 'primary' as const,
      label: 'Start',
      followingTimePeriodType: timePeriodType,
      time: props.eventDate.startTime,
    },
    {
      type: 'primary' as const,
      label: 'End',
      followingTimePeriodType: UnknownEnum.unknown,
      time: props.eventDate.endTime,
    }
  ];
};

const getRegularEventDateTimelinePreShowTimes = (props: GetEventDateTimelineConfigsProps) => {
  const { eventDate } = props;
  const {
    startTime,
    setupEndTime,
    arriveAsEarlyAs,
    noVehiclesAllowedAfter,
    allVehiclesMustBeRemovedBy,
    displaySetupInMinutes,
  } = eventDate;

  const setupEnd = setupEndTime ?? startTime;
  const noVehiclesAllowedAfterBasedArrival = !!noVehiclesAllowedAfter
    ? DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, noVehiclesAllowedAfter)
      .subtract(15, 'minutes')
    : undefined;
  const setupEndTimeBasedArrival = DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, setupEnd)
    .subtract(displaySetupInMinutes, 'minutes');

  const arrival = !!noVehiclesAllowedAfterBasedArrival
    ? DateService.dayjs.min(noVehiclesAllowedAfterBasedArrival, setupEndTimeBasedArrival)
    : setupEndTimeBasedArrival;

  const times = [
    ...getToShowTravelTimes(props, arrival, EventDateTimePeriodTypeEnum.setup).withDateTimeObject,
    { dateTimeObj: DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, setupEnd), label: 'Setup by', followingTimePeriodType: UnknownEnum.unknown },
    { dateTimeObj: arriveAsEarlyAs ? DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, arriveAsEarlyAs) : undefined, label: 'Arrive as early as' },
    { dateTimeObj: allVehiclesMustBeRemovedBy ? DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, allVehiclesMustBeRemovedBy) : undefined, label: 'All vehicles must be removed by' },
    { dateTimeObj: noVehiclesAllowedAfter ? DateService.combineDateAsUtcAndTime(eventDate.dateAsUtc, noVehiclesAllowedAfter) : undefined, label: 'No vehicles allowed after' },
  ];

  return times
    .filter(timePoint => !!timePoint.dateTimeObj)
    .sort((a, b) => a.dateTimeObj?.isBefore(b.dateTimeObj) ? -1 : 1)
    .map((timePoint): EventDateTimelineTimePoint => {
      const time = (timePoint.dateTimeObj as DateObjectType).format('HH:mm');

      if ('followingTimePeriodType' in timePoint) {
        return {
          type: 'primary',
          ...timePoint,
          time,
        };
      }

      return {
        type: 'secondary',
        label: timePoint.label,
        time,
      };
    });
};

const getRegularEventDateTimelinePostShowTimes = (props: GetEventDateTimelineConfigsProps) => {
  const departure = DateService.combineDateAsUtcAndTime(props.eventDate.dateAsUtc, props.eventDate.endTime)
    .add(props.eventDate.breakdownInMinutes, 'minutes');

  return [
    { type: 'primary' as const, time: props.eventDate.endTime, label: 'Breakdown', followingTimePeriodType: EventDateTimePeriodTypeEnum.breakdown },
    ...getFromShowTravelTimes(props, departure).withTime,
  ];
};