import { EventPreperationAcceptanceStatusEnum } from 'api/resources';
import { AcceptanceStatusChip } from 'components';

export type AcceptanceStatusCellProps = {
  value?: EventPreperationAcceptanceStatusEnum;
};

export const AcceptanceStatusCell: React.FC<AcceptanceStatusCellProps> = ({ value }) => {

  if (!value) {
    return null;
  }

  return <AcceptanceStatusChip size="small" value={value} />;
};
