import { Formik } from 'formik';
import { useMemo } from 'react';
import { DateRange, dateRangeShortcutEnumHelpers } from './helpers';
import { DateRangeForm } from './DateRange.form';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { PopoverButton } from 'components/Button';
import { DateService } from 'services';

export type DateRangePickerPopoverProps = {
  dateRange: NonNullable<DateRange>;
  onChange: (value: DateRange) => void;
};

export type DateRangeFormInput = { dateRange: NonNullable<DateRange>};

export const DateRangePickerPopover: React.FC<DateRangePickerPopoverProps> = props => {
  const label = useMemo(() => {
    const dateRangeShortcut = dateRangeShortcutEnumHelpers.getValueForDateRange(props.dateRange);

    if (dateRangeShortcut) {
      return dateRangeShortcutEnumHelpers.getLabel(dateRangeShortcut);
    }

    return DateService.getFormattedDateRange(props.dateRange.start, props.dateRange.end);
  }, [ props.dateRange ]);

  const initialValues: DateRangeFormInput = { dateRange: props.dateRange };

  return (
    <PopoverButton label={label} id="daterange-popover" startIcon={<CalendarTodayOutlined />}>
      {({ handleClose }) => {
        return (
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => props.onChange(values.dateRange)}
          >
            {formik => (
              <DateRangeForm
                onCancel={handleClose}
                onApply={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
              />
            )}
          </Formik>
        );
      }}
    </PopoverButton>
  );
};


