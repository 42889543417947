import { useField } from 'formik';
import React from 'react';
import { DateObjectType, DateService } from 'services';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { Stack, Typography } from '@mui/material';
import { FormDatePicker } from './FormDatePicker.component';

export type FormDateRangePickerPropsType = DateRangePickerProps<DateObjectType> & {
  name: {
    start: string;
    end: string;
  };
  isDisabled?: {
    start?: boolean;
    end?: boolean;
  };
};

export const FormDateRangePicker: React.FC<FormDateRangePickerPropsType> = ({
  name,
  isDisabled,
  ...props
}) => {
  const [ startField, startMeta, startHelpers ] = useField(name.start);
  const [ endField, endMeta, endHelpers ] = useField(name.end);

  if (isDisabled?.start || isDisabled?.end) {
    return (
      <Stack direction="row" justifyContent="stretch" alignItems="baseline" gap={2} width="100%" mb="3px">
        <FormDatePicker
          name={name.start}
          label={props.localeText?.start}
          disabled={isDisabled?.start}
          sx={{ flex: 1 }}
          disableOpenPicker={isDisabled?.start}
        />
        <Typography>—</Typography>
        <FormDatePicker
          name={name.end}
          label={props.localeText?.end}
          disabled={isDisabled?.end}
          sx={{ flex: 1 }}
          disableOpenPicker={isDisabled?.end}
        />
      </Stack>
    );
  }

  return (
    <DateRangePicker
      {...props}
      value={[ startField.value ? DateService.dayjs(startField.value) : null,
        endField.value ? DateService.dayjs(endField.value) : null ]}
      onChange={(date) => {
        startHelpers.setValue(date[0]);
        endHelpers.setValue(date[1]);
      }}
      slotProps={{
        textField: {
          error: !!startMeta.error || !!endMeta.error,
          helperText: (!!startMeta.error || !!endMeta.error) ? [ startMeta.error, endMeta.error ].join(', ') : '',
        },
      }}
    />
  );
};