import { SalesPerTableRowGetIdAndNameType, TSalesTablePerTypeRow, TSalesTableRow } from './types';

const getResourceAmountHash = <T extends TSalesTableRow>(sales: T[], getIdAndName: SalesPerTableRowGetIdAndNameType<T>): { [key: string]: TSalesTablePerTypeRow } => {
  return sales.reduce((r, sale) => {
    const { id, name } = getIdAndName(sale);

    const prev = r?.[id] ?? null;
    const salesCount = (prev?.salesCount ?? 0) + 1;
    const salesRevenue = (prev?.salesRevenue ?? 0) + sale.salePrice;

    return {
      ...r,
      [id]: {
        salesCount,
        salesRevenue,
        name,
        _id: id,
      },
    };
  }, {} as { [key: string]: TSalesTablePerTypeRow });
};

export const transformSalesForTablePerType = <T extends TSalesTableRow>(sales: T[], getIdAndName: SalesPerTableRowGetIdAndNameType<T>): TSalesTablePerTypeRow[] => {
  return Object.values(getResourceAmountHash(sales, getIdAndName));
};