import { Box } from '@mui/material';
import { TextInput } from 'components';
import React from 'react';
import { formSx } from 'styles';

export const EventExternalLinkForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <TextInput name="title" label="Title *" trim />
      <TextInput name="url" label="URL *" trim />
    </Box>
  );
};
