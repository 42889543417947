import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { useProductsPageContext } from 'contexts';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateInventoryBatchInput, updateInventoryBatch } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { useCallback, useMemo } from 'react';
import { InventoryEdits, InventoryTableEditableMode, InventoryTableSettingsModal, InventoryTableShowStockMetaInput, InventoryTableStandardFiltersInput, StandardInventoryTableFilters, Table, useInventoryTable } from 'components';
import { getInitialTransferConfig, getTeamsStockMetaConfig, prepareInitialInventoryEdits } from '../helpers';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, InventoryBatchUpdate } from 'api/resources';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { CancelBatchButton } from '../components/CancelBatchButton.component';

const prepareInventoryBatchEditsToSave = (edits: InventoryEdits) => {
  return Object.entries(edits).reduce((acc: InventoryBatchUpdate[], [ productId, productEdits ]) => {
    return Object.values(productEdits).reduce((acc, inventoryEdit) => {
      const update: InventoryBatchUpdate = {
        quantity: inventoryEdit.value,
        product: productId,
      };

      return [ ...acc, update ];
    }, acc);
  }, []);
};

export const Open: React.FC = () => {
  const navigate = useNavigate();
  const { teams, getProductsSortedAlphabetically } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);

  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);
  const teamsStockMetaConfig = useMemo(() => getTeamsStockMetaConfig(initialTransferConfig, inventoryBatch.type), [ initialTransferConfig, inventoryBatch.type ]);
  const getDisplayStandardFilters = useCallback((): StandardInventoryTableFilters[] => inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking ? [  'showNotAvailableAtEvents', 'onlyShowEdits', 'showOutNotOverstocked' ] : [  'showNotAvailableAtEvents', 'onlyShowEdits', 'showInNotNeeded', 'showNoOutStock' ], [ inventoryBatch.type ]);

  const {
    inventoryTableProps,
    editModal,
    setSettingsModalOpen,
    settingsModalOpen,
    transferConfig,
    showStockMeta,
    setShowStockMeta,
    standardFilters,
    setStandardFilters,
    hasEdits,
    hasErrors,
    inventoryEdits,
    setSaveLoading
  } = useInventoryTable({
    products,
    teams,
    initialMode: inventoryBatch.team.isWarehouse ? InventoryTableEditableMode.unary : InventoryTableEditableMode.transfer,
    initialTransferConfig,
    getDisplayStandardFilters,
    initialTeamIds: [ inventoryBatch.team._id ],
    initialInventoryEdits: prepareInitialInventoryEdits(inventoryBatch, warehouseTeamId),
    teamsStockMetaConfig,
  });
  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async (_, { status }) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
      if (status !== InventoryBatchStatusEnum.review) {
        navigate(`/${ROUTING_CONFIG.inventoryBatches}`);
      }
    },
    onError: () => {
      setSaveLoading(false);
    }
  });

  const onSave = async (moveToReview = false) => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareInventoryBatchEditsToSave(inventoryEdits),
      status: moveToReview ? InventoryBatchStatusEnum.review : undefined,
    });
  };

  return (
    <InventoryBatchLayout
      actions={(
        <>
          <CancelBatchButton />
          <Button variant="outlined" color="success" disabled={hasErrors || updateMutation.isLoading} onClick={() => onSave()}>Save and Finish Later</Button>
          <Button variant="contained" color="success" disabled={!hasEdits || hasErrors || updateMutation.isLoading} onClick={() => onSave(true)}>Complete (move to review)</Button>
        </>
      )}
    >
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          showStockMetaInput={<InventoryTableShowStockMetaInput showStockMeta={showStockMeta} setShowStockMeta={setShowStockMeta} />}
          filterInputs={(
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters()}
              disableFilters={[ 'showNotAvailableAtEvents' ]}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      {editModal}
      <Table {...inventoryTableProps} />
    </InventoryBatchLayout>
  );
};