import { UpdateResourceModal, useAlertSnackbar } from 'components';
import { Formik } from 'formik';
import React from 'react';
import { EventOrganizerBasicInfoForm } from '../forms/EventOrganizerBasicInfo.form';
import { eventOrganizerValidationSchema } from '../validation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResourceForm } from 'types';
import { useEventOrganizerOutletContext } from '../EventOrganizer.base';
import { updateEventOrganizer } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { processFormValueUpdate, removeUnchanged } from 'helpers';

export type EditEventOrganizerModalProps = {
  onClose: () => void;
};

export const EditEventOrganizerModal: React.FC<EditEventOrganizerModalProps> = props => {
  const snackbar = useAlertSnackbar();
  const { eventOrganizer } = useEventOrganizerOutletContext();
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationFn: (form: ResourceForm['eventOrganizer']) => {
      const updates = removeUnchanged(form, initialValues);

      return updateEventOrganizer(eventOrganizer._id, {
        name: updates.name,
        website: updates.website,
        mailingAddress: updates.mailingAddress,
        notes: processFormValueUpdate.string(updates.notes),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT_ORGANIZER(eventOrganizer._id));
      props.onClose();
      snackbar.success('Event organizer updated');
    }
  });

  const initialValues: ResourceForm['eventOrganizer'] = {
    name: eventOrganizer.name,
    website: eventOrganizer.website,
    mailingAddress: eventOrganizer.mailingAddress,
    notes: eventOrganizer.notes || '',
  };

  return (
    <Formik
      onSubmit={(values) => updateMutation.mutateAsync(values)}
      initialValues={initialValues}
      validationSchema={eventOrganizerValidationSchema}
    >
      {(formik) => {
        return (
          <UpdateResourceModal
            open
            onClose={props.onClose}
            title="Basic Information"
            onSave={() => formik.handleSubmit()}
            loading={formik.isSubmitting}
          >
            <EventOrganizerBasicInfoForm />
          </UpdateResourceModal>
        );
      }}
    </Formik>
  );
};
