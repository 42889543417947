import { SectionCardRowEditable, SectionCardRowEditableProps } from 'components';
import { SalePageSectionRowId, SaleSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useSaleOutletContext } from '../Sale.base';

export type SaleSectionCardRowEditableProps<RowId extends SalePageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<SaleSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const SaleSectionCardRowEditable = <RowId extends SalePageSectionRowId>(props: SaleSectionCardRowEditableProps<RowId>) => {
  const { sale } = useSaleOutletContext();

  return <SectionCardRowEditable {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.SALE(sale._id) })} />;
};
