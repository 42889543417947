import { ApiResource } from './resources.types';

export const enum UserAvailabilityEnum {
  available = 'available',
  notAvailable = 'notAvailable',
  undetermined = 'undetermined',
}

export type User = ApiResource<{
  name: string;
  email: string;
  active: boolean;
  enabled: boolean;
  phoneNumber?: string;
  birthday?: string;
  payRate?: number;
  role: UserRoleEnum;
  employeeRoles: UserEmployeeRoleEnum[];
  pinNeedsUpdate?: boolean;
  profileImageUrl?: string;
  driverLicence?: boolean;
  personalVehicle?: boolean;
  coveredByBussinessInsuranceAsDriver?: boolean;
  canDriveBoxTruck?: boolean;
  canPullTrailer?: boolean;
  drivingNotes?: string;
  createdBy?: string;
}>;

export const enum UserRoleEnum {
  user = 'USER',
  admin = 'ADMIN',
  superadmin = 'SUPERADMIN',
}

export const enum UserEmployeeRoleEnum {
  juniorTeamMember = 'juniorTeamMember',
  teamMember = 'teamMember',
  teamLead = 'teamLead',
  productionManager = 'productionManager',
  productionMember = 'productionMember',
  eventManager = 'eventManager',
  wholesaleManager = 'wholesaleManager',
}