import { Box, Container, Divider, Typography } from '@mui/material';
import { ConfirmActionModal, EditableImage, JumpNav, PageContentContainer, PageContentResourceHeader, SalesReport, SectionCard, SectionCardsEditContextProvider, getInitialAnalyticsTimelineChartTimePeriodForDateRange, useAlertSnackbar } from 'components';
import { useMemo, useState } from 'react';
import { ProfileSchedule } from './components/ProfileSchedule/ProfileSchedule.main';
import { User, UserRoleEnum } from 'api/resources';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { DateService, FirebaseService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { getMeThunk } from 'app/auth/auth.slice';
import { useAppDispatch } from 'app/hooks';
import { OverviewSection, SecurityCredentialsSection, DrivingSection } from './sections';
import { ProfilePageSection } from './types';
import { UserSessionsCard } from './components/UserSessionsCard.component';

export type ProfileContentProps = {
  user: User;
};

export const ProfileContent: React.FC<ProfileContentProps> = ({ user }) => {
  const currentUser = useCurrentUser();
  const isMe = currentUser.getIsMe(user);
  const isAdmin = currentUser.role === UserRoleEnum.admin;
  const isMeOrAdmin = isMe || isAdmin;
  const [ activationModalOpen, setActivationModalOpen ] = useState(false);
  const snackbar = useAlertSnackbar();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const userActiveMutation = useMutation({
    mutationFn: async () => {
      return updateUser(user._id, { active: !user.active });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.USER(user._id));
      snackbar.success(`User ${user.active ? 'de' : ''}activated`);

      setActivationModalOpen(false);
    },
    onError: () => {
      snackbar.error(`User could not be ${user.active ? 'de' : ''}activated`);
    }
  });
  const uploadImageMutation = useMutation({
    mutationFn: async (imageBlobUrl: Blob) => {
      if (user.profileImageUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(user.profileImageUrl);
        const path = `${user._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      const profileImageUrl = await FirebaseService.uploadFile(imageBlobUrl, `${user._id}/${FirebaseService.convertFileName('_user_profile_image')}`);

      return updateUser(user._id, { profileImageUrl });
    },
    onSuccess: async () => {
      if (isMe) {
        await dispatch(getMeThunk());
      } else {
        await queryClient.invalidateQueries(QUERY_KEY.USER(user._id));
      }
      snackbar.success('Image uploaded');
    },
    onError: () => {
      snackbar.error('Image could not be uploaded');
    }
  });
  const deleteImageMutation = useMutation({
    mutationFn: async () => {
      if (user.profileImageUrl) {
        const fileName = FirebaseService.getFileNameFromUrl(user.profileImageUrl);
        const path = `${user._id}/${fileName}`;

        await FirebaseService.deleteFile(path);
      }

      return updateUser(user._id, { profileImageUrl: null });
    },
    onSuccess: async () => {
      if (isMe) {
        await dispatch(getMeThunk());
      } else {
        await queryClient.invalidateQueries(QUERY_KEY.USER(user._id));
      }
      snackbar.success('Image deleted');
    },
    onError: () => {
      snackbar.error('Image could not be deleted');
    }
  });

  const activationModal = useMemo(() => {
    if(!activationModalOpen) {
      return null;
    }

    const deactivateText = `Please confirm the deactivation of the user account. This action will prevent ${user.name} from accessing the logistics application and will remove their name from the scheduling dropdown menus.`;
    const reactivateText = `Please confirm the reactivation of the user account. This action will restore ${user.name}'s access to the logistics application and reinstate their name in the scheduling dropdown menus.`;

    return (
      <ConfirmActionModal
        open
        loading={userActiveMutation.isLoading}
        text={user.active ? deactivateText : reactivateText}
        onConfirm={userActiveMutation.mutate}
        onClose={() => setActivationModalOpen(false)}
      />
    );
  }, [ activationModalOpen, user.active, user.name, userActiveMutation.isLoading, userActiveMutation.mutate ]);

  const subHeaderContent = useMemo(() => {
    if (!user.active) {
      return <Typography color="error.main">DEACTIVATED</Typography>;
    }

    if (user.role === UserRoleEnum.admin) {
      return <Typography color="primary.main">{user.role}</Typography>;
    }

    return null;
  }, [ user.active, user.role ]);

  return (
    <PageContentContainer
      preventContainer
      maxWidth="lg"
      breadcrumbs={!isMe ? {
        prev: [ { link: ROUTING_CONFIG.users, text: 'Users' } ],
        current: user.name,
      } : undefined}
      header={(
        <PageContentResourceHeader
          pageTitle={isMe ? 'Profile Page' : 'User Page'}
          title={user.name}
          renderTitle={({ inputTitle }) => (
            <Box display="flex" gap={1} alignItems="flex-end">
              {isMe && <Typography variant="h4" lineHeight={1} fontWeight={400}>Welcome back, </Typography>}
              <Typography variant="h4" lineHeight={1} fontWeight={700}>{inputTitle}</Typography>
            </Box>
          )}
          subHeaderContent={subHeaderContent}
          renderEditableImage={props => (
            <EditableImage
              {...props}
              imageUrl={user.profileImageUrl}
              onUpload={uploadImageMutation.mutate}
              deleteConfig={{ onDelete: deleteImageMutation.mutate }}
              loading={uploadImageMutation.isLoading || deleteImageMutation.isLoading}
              modalTitle="Update User Image"
              variant="rounded"
              disabledEditable={!isMe}
            />
          )}
          options={(currentUser.isAdmin && !isMe) ? [
            {
              label: `${user.active ? 'Deactivate' : 'Activate'} User`,
              menuItemProps: { onClick: () => setActivationModalOpen(true) },
              labelTypographyProps: { sx: { color: theme => user.active ? theme.palette.error.dark : theme.palette.success.dark  } },
            }
          ] : undefined}
          stickyTabs
          tabs={(
            <JumpNav
              initialValue={ProfilePageSection.OVERVIEW}
              offsetTop={50}
              tabs={[
                { label: 'Schedule', sectionId: ProfilePageSection.SCHEDULE },
                { label: 'Overview', sectionId: ProfilePageSection.OVERVIEW },
                { label: 'Driving', sectionId: ProfilePageSection.DRIVING },
                { label: 'Credentials', sectionId: ProfilePageSection.CREDENTIALS, hidden: !isMe },
                { label: 'Sales', sectionId: ProfilePageSection.SALES, hidden: !isAdmin },
                { label: 'Sessions', sectionId: ProfilePageSection.SESSIONS, hidden: !isMeOrAdmin },
              ]}
            />
          )}
        />
      )}
    >
      {activationModal}

      <SectionCardsEditContextProvider>
        <Container maxWidth="lg">
          <Box pt={5}>
            <SectionCard id={ProfilePageSection.SCHEDULE} title="Schedule">
              <ProfileSchedule user={user} />
            </SectionCard>
          </Box>
          <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5} mb={5} pt={5}>
            <OverviewSection user={user} />
            <DrivingSection user={user} />
            {isMe && <SecurityCredentialsSection user={user} />}

          </Box>
        </Container>
        {isAdmin && (
          <Box bgcolor="background.paper" id={ProfilePageSection.SALES}>
            <Box bgcolor="primary.background">
              <Divider />
              <Container maxWidth="lg">
                <Box py={5}>
                  <SalesReport userId={user._id} timePeriod={getInitialAnalyticsTimelineChartTimePeriodForDateRange({ start: DateService.dayjsTz(user.createdAt), end: DateService.dayjsTz() })} />
                </Box>
              </Container>
              <Divider />
            </Box>
          </Box>
        )}
        <Container maxWidth="lg">
          <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5} mb={5} pt={5}>
            {isMeOrAdmin && (
              <Box id={ProfilePageSection.SESSIONS}>
                <Typography variant="h6" fontWeight={400} mb={1}>Sessions</Typography>
                <UserSessionsCard user={user} />
              </Box>
            )}
          </Box>
        </Container>
      </SectionCardsEditContextProvider>


    </PageContentContainer>
  );
};