import { Typography } from '@mui/material';
import { ShowTheseFieldsConfig } from './types';

export type ShowTheseFieldsActionContentProps<Field extends string, Group extends string, Resource, Extra = undefined> = {
  showTheseFields: Field[];
  config: ShowTheseFieldsConfig<Field, Group, Resource, Extra>[];
};

export const ShowTheseFieldsActionContent = <Field extends string, Group extends string, Resource extends unknown, Extra extends unknown = undefined>({ showTheseFields, config }: ShowTheseFieldsActionContentProps<Field, Group, Resource, Extra>) => {
  const showTheseFieldsLabels = config
    .filter(config => showTheseFields.includes(config.field))
    .map(config => config.label);

  return (
    <>
      {showTheseFieldsLabels.map((label) => (
        <Typography key={label} fontSize="small" color="text.secondary">{'<'}{label}{'>'}</Typography>
      ))}
    </>
  );
};