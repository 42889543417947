import { EditModeTogglerProps, FilterButton, Table, TableActionsBar, TableProps, UseFilterOutput, useEventPreviewDrawerContext } from 'components';
import { UseTableActionColumnArgs, UseTableLocalStorage, useTableLocalStorage } from 'hooks';
import { useEventsManagementFilterConfigurations, useEventsManagementProcessRowUpdate, useEventsManagementTableColumns } from 'pages/EventsManagement/hooks';
import { EventsManagementInfoPopper } from './EventsManagementInfoPopper.component';
import { useEventsManagementFilter } from 'pages/EventsManagement/hooks/useEventsManagementFilter.hook';
import { EventsManagementTreeViewTerminalNodeId } from 'pages/EventsManagement/helpers';
import React, { useCallback, useContext, useMemo } from 'react';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { GridColDef, GridRowClassNameParams } from '@mui/x-data-grid';
import { GetEventsResponse } from 'api/actions';
import { EventsManagementPageTitle } from './EventsManagementPageTitle.component';
import { DateService } from 'services';
import { Box } from '@mui/material';

export type EventsManagementTableProps = Pick<TableProps, 'processRowUpdate' | 'getDetailPanelContent' | 'getDetailPanelHeight'> & {
  nodeId: EventsManagementTreeViewTerminalNodeId;
  localStorageArgs: Omit<UseTableLocalStorage, 'columns' | 'id'>;
  getNavigateTo?: UseTableActionColumnArgs['getNavigateTo'];
  editable?: Record<string, boolean>;
  extraColumns?: GridColDef[];
  editModeToggler?: React.ReactElement<EditModeTogglerProps>;
  renderTopContent?: (events: GetEventsResponse['data']) => React.ReactNode;
  renderAdditionalFilterActions?: (arg: Pick<UseFilterOutput<GetEventsResponse['data']>, 'filter' | 'setFilter'>) => React.ReactNode;
  groupByWeek?: boolean;
};

export const EventsManagementTable: React.FC<EventsManagementTableProps> = props => {
  const processRowUpdate = useEventsManagementProcessRowUpdate();
  const { events, eventsLoading: loading } = useContext(EventsManagementPageContext);
  const { setOpenEvent, openEventId } = useEventPreviewDrawerContext();
  const eventsManagementColumns = useEventsManagementTableColumns({ getNavigateTo: props.getNavigateTo, editable: props.editable });
  const columns  = [ ...eventsManagementColumns, ...(props.extraColumns ?? []) ];
  const { initialTableState, tableStateChange } = useTableLocalStorage({ ...props.localStorageArgs, id: props.nodeId, columns });
  const filterConfigurations = useEventsManagementFilterConfigurations();
  const filterConfiguration = filterConfigurations[props.nodeId];

  const eventsWithYearWeeks = useMemo(() => {
    return events.map(event => ({ ...event, formattedWeek: DateService.dayjsTz(event.startDate?.dateAsUtc).format('YYYY/ww') }));
  }, [ events ]);
  const { modal, setShowModal, filteredRows, filter, setFilter } = useEventsManagementFilter({ events: eventsWithYearWeeks, nodeId: props.nodeId });

  const sortedRowsWithFormattedWeek = useMemo(() => {
    return eventsWithYearWeeks.sort((a, b) => {
      if(!a.startDate?.dateAsUtc) return 1;
      if(!b.startDate?.dateAsUtc) return -1;

      return DateService.dayjs(a.startDate.dateAsUtc).isBefore(b.startDate.dateAsUtc) ? -1 : 1;
    });
  }, [ eventsWithYearWeeks ]);
  const yearWeeks = useMemo(() => {
    const filteredRowIds = filteredRows.map(row => row._id);

    return sortedRowsWithFormattedWeek
      .filter(row => filteredRowIds.includes(row._id))
      .map(row => row.formattedWeek);
  }, [ filteredRows, sortedRowsWithFormattedWeek ]);

  const getRowClassName = useCallback(({ row }: GridRowClassNameParams<GetEventsResponse['data'][number] & { formattedWeek: string }>) => {
    if (row._id === openEventId) {
      return 'selected-darker';
    }

    return yearWeeks.indexOf(row.formattedWeek) % 2 ? 'selected' : '';
  }, [ openEventId, yearWeeks ]);

  return (
    <>
      {modal}
      <EventsManagementPageTitle nodeId={props.nodeId} />
      <TableActionsBar
        editModeToggler={props.editModeToggler}
        leftActions={modal && (
          <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
            {props.renderAdditionalFilterActions?.({ filter, setFilter })}
            <FilterButton onClick={() => setShowModal(true)} />
          </Box>
        )}
        rightActions={filterConfiguration
          ? <EventsManagementInfoPopper tab={props.nodeId} filterConfigurations={filterConfiguration} filter={filter} />
          : <EventsManagementInfoPopper tab={props.nodeId} />
        }
      />
      {props.renderTopContent && <Box mt={2}>
        {props.renderTopContent(filteredRows)}
      </Box>}
      <Table
        {...tableStateChange}
        getDetailPanelContent={props.getDetailPanelContent}
        getDetailPanelHeight={props.getDetailPanelHeight}
        getRowId={(x) => x._id}
        rows={filteredRows}
        columns={columns}
        loading={loading}
        pagination
        initialState={{
          columns: { columnVisibilityModel: initialTableState.columnVisibility },
          filter: { filterModel: initialTableState.filter },
          sorting: { sortModel: initialTableState.sort },
        }}
        onCellClick={(params, e) => {
          if(params.isEditable) {
            e.stopPropagation();
          }
        }}
        onRowClick={(params) => setOpenEvent(params.row._id, 'main')}
        processRowUpdate={props.processRowUpdate ?? processRowUpdate}
        getRowClassName={getRowClassName}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer'
          }
        }}
      />
    </>
  );
};