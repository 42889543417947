import { EnumHelpers } from 'helpers/enums/types';
import { UseCurrentUserOutput } from 'contexts';
import { EventDatePageSection, EventDatePageSectionRowId } from 'components';

export const eventDatePageSectionHelpers: EnumHelpers<EventDatePageSection> & {
  getIsHidden: (value: EventDatePageSection, currentUser: UseCurrentUserOutput) => boolean;
} = {
  enumValues: [
    EventDatePageSection.OVERVIEW,
    EventDatePageSection.STAFF,
    EventDatePageSection.PHOTOS,
    EventDatePageSection.SALES,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventDatePageSection.OVERVIEW:
        return 'Overview';
      case EventDatePageSection.STAFF:
        return 'Staff';
      case EventDatePageSection.PHOTOS:
        return 'Photos';
      case EventDatePageSection.SALES:
      default:
        return 'Sales';
    }
  },
  getIsHidden: (value, currentUser) => {
    if (value === EventDatePageSection.SALES) {
      return !currentUser.isAdmin;
    }

    return false;
  },
  getColor: () => 'unknown',
};

export const eventDatePageSectionRowHelpers: EnumHelpers<EventDatePageSectionRowId> = {
  enumValues: [
    EventDatePageSectionRowId.STAFF_NEEDED,
    EventDatePageSectionRowId.TYPE,
    EventDatePageSectionRowId.LODGING,
    EventDatePageSectionRowId.NOTES,
    EventDatePageSectionRowId.TIMES,
    EventDatePageSectionRowId.STAFF,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventDatePageSectionRowId.STAFF_NEEDED:
        return 'Staff Needed';
      case EventDatePageSectionRowId.TYPE:
        return 'Type';
      case EventDatePageSectionRowId.LODGING:
        return 'Lodging';
      case EventDatePageSectionRowId.NOTES:
        return 'Notes';
      case EventDatePageSectionRowId.TIMES:
        return 'Times';
      case EventDatePageSectionRowId.STAFF:
      default:
        return 'Staff';
    }
  },
  getColor: () => 'unknown',
};