import { RadioGroup, RadioGroupProps, RadioItemValueType } from './RadioGroup.component';

export type RadioGroupYesNoBooleanProps = Omit<RadioGroupProps, 'values'> & {
  includeUnknown?: true;
  disabled?: boolean;
};

const yesNoBooleanValues: RadioItemValueType[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const RadioGroupYesNoBoolean: React.FC<RadioGroupYesNoBooleanProps> = ({ includeUnknown, disabled, ...props }) => {
  let values = yesNoBooleanValues;

  if (includeUnknown) {
    values = [ ...yesNoBooleanValues, { label: 'Unknown', value: 'unknown' } ];
  }

  values = values.map((el) => {
    if (typeof el === 'object') {
      return { ...el, disabled };
    } else {
      return el;
    }
  });

  return <RadioGroup {...props} values={values} />;
};
