import { ProductStockUpdateLogTypeEnum } from 'api/resources';
import { AnalyticsChart } from 'components';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import { useAnalyticsChartDataWithCompare } from 'pages/Analytics/hooks';

export const ProductsSetsChart: React.FC = () => {
  const { updateLogsLoading } = useAnalyticsPageContext();
  const { config } = useAnalyticsChartDataWithCompare({
    type: 'updateLogs',
    baseConfig: {
      getValue:  (updateLog) => updateLog.newQuantity - updateLog.previousQuantity,
      getLabel: (updateLog) => updateLog.stock.team.name,
      getId: (updateLog) => updateLog.stock.team._id,
    },
    datasetsConfigs: [
      { filter: (updateLog) => updateLog.type === ProductStockUpdateLogTypeEnum.set && updateLog.newQuantity < updateLog.previousQuantity },
      { filter: (updateLog) => updateLog.type === ProductStockUpdateLogTypeEnum.set && updateLog.newQuantity > updateLog.previousQuantity }
    ],
  });

  return (
    <AnalyticsChart
      header='Team "sets"'
      config={{ ...config, stacked: true }}
      loading={updateLogsLoading}
      strColors={[ 'error', 'success', 'success' ]}
    />
  );
};