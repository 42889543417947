import { TableActionsBar, useFilter } from 'components';
import { EventsManagementInfoPopper } from 'pages/EventsManagement/components';
import { EventsCalendar as EventsCalendarDisplay } from './components/EventsCalendar/EventsCalendar.component';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { useEventsManagementFilterConfigurations } from 'pages/EventsManagement/hooks';
import { useContext } from 'react';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { EventsManagementPageTitle } from 'pages/EventsManagement/components/EventsManagementPageTitle.component';

export const EventsCalendar = () => {
  const { events } = useContext(EventsManagementPageContext);
  const eventsManagementFilterConfigurations = useEventsManagementFilterConfigurations();

  const filterProps = useFilter({
    rows: events,
    localStorageId: EventsManagementTreeViewNodeIdEnum.eventsCalendar,
    filterConfigurations: eventsManagementFilterConfigurations.eventsCalendar ?? [],
  });

  return (
    <>
      <TableActionsBar leftActions={<EventsManagementPageTitle nodeId={EventsManagementTreeViewNodeIdEnum.eventsCalendar} />} rightActions={<EventsManagementInfoPopper tab={EventsManagementTreeViewNodeIdEnum.eventsCalendar} filterConfigurations={eventsManagementFilterConfigurations.eventsCalendar ?? []} filter={filterProps.filter}/>} />
      <EventsCalendarDisplay {...filterProps} />
    </>
  );
};
