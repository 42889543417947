import { GetEventsResponse } from 'api/actions';
import { DateService } from 'services';
import { TEventsTableEditableRow, UnknownEnum } from 'types';

export const transformEventsForTableEditable = (events: GetEventsResponse['data']) => {
  return events.map(({
    preperation, ...event
  }): TEventsTableEditableRow => {

    return {
      _id: event._id,
      name: event.name,
      eventTimeframe: DateService.getFormattedDateRange(DateService.dayjsTz(event.startDate?.dateAsUtc), DateService.dayjsTz(event.endDate?.dateAsUtc), false),
      applicationOpenTimeframe: [
        DateService.dayjs(preperation.applicationOpenDate).isValid() ? DateService.dayjs(preperation.applicationOpenDate).toISOString() : null,
        DateService.dayjs(preperation.applicationDeadlineDate).isValid() ? DateService.dayjs(preperation.applicationDeadlineDate).toISOString() : null
      ],

      spaceSize: event.spaceSize ?? '',
      attendance: event.attendance ?? 0,

      participationStatus: preperation.participationStatus ?? UnknownEnum.unknown,
      applicationPlatform: preperation.applicationPlatform ?? UnknownEnum.unknown,
      applicationStatus: preperation.applicationStatus ?? UnknownEnum.unknown,
      acceptanceStatus: preperation.acceptanceStatus ?? UnknownEnum.unknown,
    };
  });
};
