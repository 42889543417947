import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getSessionsForUser } from 'api/actions';

export const useSessionsForUser = (userId: string) => {
  return useQuery(QUERY_KEY.SESSIONS_FOR_USER(userId), async () => {
    const response = await getSessionsForUser(userId);

    return response.data;
  }, {});
};