import { Box } from '@mui/material';
import { UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { CheckboxGroup, CheckboxItemValueType, FormDatePicker, Select, TextInput } from 'components';
import { getSelectOptionsFromEnumHelper, userEnumHelpers } from 'helpers';
import { useMemo } from 'react';
import { formSx } from 'styles';

export type UserBasicInfoFormProps = {
  showRole?: boolean;
  showEmployeeRoles?: boolean;
};

export const UserBasicInfoForm: React.FC<UserBasicInfoFormProps> = (props) => {
  const employeeRoleValues: CheckboxItemValueType[] = useMemo(() => getSelectOptionsFromEnumHelper(userEnumHelpers.employeeRole).map(value => ({ ...value, caption: userEnumHelpers.employeeRole.getDescription(value.value as UserEmployeeRoleEnum) })), []);

  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        name="name"
        label="Name"
        trim
      />
      <TextInput
        name="email"
        label="Email"
        trim
      />
      <TextInput
        name="phoneNumber"
        label="Phone Number"
        trim
      />
      {props.showRole && (
        <Select
          name="role"
          label="Role"
          options={[
            { value: UserRoleEnum.user, label: 'User' },
            { value: UserRoleEnum.admin, label: 'Admin' },
          ]}
        />
      )}
      <FormDatePicker name="birthday" />
      {props.showEmployeeRoles && (
        <CheckboxGroup
          label="Employee Roles"
          name="employeeRoles"
          values={employeeRoleValues}
        />
      )}
    </Box>
  );
};