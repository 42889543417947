import React from 'react';
import { VehiclePageSection, VehiclePageSectionProps } from '../types';
import { SectionCard, VehicleEventList } from 'components';
import { Box, Typography } from '@mui/material';
import { EventForResourceTypeEnum } from 'api/resources';
import { useUpcomingEventsForVehicle } from 'queries';

export const EventsSection: React.FC<VehiclePageSectionProps> = ({ vehicle }) => {
  const { data: upcomingEventsForVehicle = [] } = useUpcomingEventsForVehicle({ vehicleId: vehicle._id });

  return (
    <SectionCard title="Events" id={VehiclePageSection.EVENTS}>
      {!!upcomingEventsForVehicle.length && (
        <Box display="flex" flexDirection="column" gap={3} m={2}>
          <VehicleEventList
            events={upcomingEventsForVehicle.filter(e => e.type === EventForResourceTypeEnum.current)}
            title="Current:"
            vehicle={vehicle}
          />
          <VehicleEventList
            events={upcomingEventsForVehicle.filter(e => e.type === EventForResourceTypeEnum.upcoming)}
            title="Upcoming:"
            vehicle={vehicle}
          />
        </Box>
      )}
      {!upcomingEventsForVehicle.length && (
        <Box m={2}>
          <Typography component="i" color="text.secondary">No current or upcoming events</Typography>
        </Box>
      )}
    </SectionCard>
  );
};
