import { ProductStockUpdateLogTypeEnum } from 'api/resources';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import React from 'react';
import { AnalyticsTimelineChart } from 'components';
import { useAnalyticsTimelineChartConfigWithCompare } from 'pages/Analytics/hooks';

export const TotalProducedChart = () => {
  const { dateRange, updateLogs, updateLogsLoading } = useAnalyticsPageContext();
  const totalProduced = updateLogs
    .filter(updateLog => updateLog.type === ProductStockUpdateLogTypeEnum.unary)
    .reduce((r, updateLog) => r + updateLog.newQuantity - updateLog.previousQuantity, 0);

  const { config } = useAnalyticsTimelineChartConfigWithCompare({
    type: 'updateLogs',
    getValue: (updateLog) => updateLog.newQuantity - updateLog.previousQuantity,
    datasetsConfigs: [ { filter: (updateLog) => updateLog.type === ProductStockUpdateLogTypeEnum.unary } ]
  });

  return (
    <AnalyticsTimelineChart
      header="Total Produced"
      subheader={totalProduced.toString()}
      dateRange={dateRange}
      config={config}
      loading={updateLogsLoading}
    />
  );
};
