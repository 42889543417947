import React from 'react';
import { useLoggingPortalContext } from '../LoggingPortal.base';
import { LoggingPortalResourceTypeEnum } from 'api/actions';
import { Box, Card, Typography } from '@mui/material';
import { PlaceOutlined } from '@mui/icons-material';

export const LoggingPortalStorefront = () => {
  const { storefront } = useLoggingPortalContext(LoggingPortalResourceTypeEnum.storefront);

  return (
    <Card variant="outlined">
      <Box mx={2} my={1}>
        <Typography component="span" fontSize="1.15rem" lineHeight={1} fontWeight={500}>{storefront.name}</Typography>
        <Typography mt={0.5} display="flex" alignItems="center"><PlaceOutlined color="error" fontSize="small" />{storefront.place.address}</Typography>
      </Box>
    </Card>
  );
};
