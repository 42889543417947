import { SectionCardRowEditable, SectionCardRowEditableList, SectionCardRowEditableListProps, SectionCardRowEditableProps } from 'components';
import { VehiclePageSectionRowId, VehicleSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { GetVehicleResponse } from 'api/actions';

export type VehicleSectionCardRowEditableProps<RowId extends VehiclePageSectionRowId> = {
  vehicle: GetVehicleResponse['data'];
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<VehicleSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const VehicleSectionCardRowEditable = <RowId extends VehiclePageSectionRowId>(props: VehicleSectionCardRowEditableProps<RowId>) => {
  return <SectionCardRowEditable {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.VEHICLE(props.vehicle._id) })} />;
};

export type VehicleSectionCardRowEditableListProps<RowId extends keyof VehicleSectionRowForm, ListItem extends {_id: string}> = {
  vehicle: GetVehicleResponse['data'];
  rowId: RowId;
} & Omit<SectionCardRowEditableListProps<VehicleSectionRowForm[RowId], ListItem>, 'rowId' | 'invalidateQueryKeys'>;

export const VehicleSectionCardRowEditableList = <RowId extends keyof VehicleSectionRowForm, ListItem extends {_id: string}>(props: VehicleSectionCardRowEditableListProps<RowId, ListItem>) => {
  return <SectionCardRowEditableList {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.VEHICLE(props.vehicle._id) })} />;
};