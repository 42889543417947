export const ROUTING_CONFIG = {
  dashboard: '',
  analytics: 'analytics',
  categories: 'categories',
  genres: 'genres',
  productList: 'product-list',
  productionSchedule: 'production-schedule',
  inventoryBatches: 'inventory-batches',
  inventoryBatch: 'inventory-batches/:id',
  inventoryAdjustments: 'inventory-adjustments',
  baselines: 'baselines',
  updateLogs: 'product-update-logs',
  product: 'product-list/:id',
  productNew: 'product-list/new',
  events: 'events',
  event: 'events/:id',
  eventNew: 'events/new',
  eventOrganizers: 'event-organizers',
  eventOrganizer: 'event-organizers/:id',
  eventOrganizerNew: 'event-organizers/new',
  scheduling: 'scheduling',
  dates: 'dates',
  feedback: 'feedback',
  sales: 'sales',
  date: 'dates/:dateId',
  salesLog: 'sales-log',
  storefronts: 'storefronts',
  logLiveSale: 'point-of-sale',
  loggingPortal: 'logging-portal',
  loggingPortalForResource: 'logging-portal/:resourceType/:resourceId',
  logSale: 'log-sale',
  sale: 'sales-log/:id',
  teams: 'teams',
  team: 'teams/:id',
  vehicles: 'vehicles',
  vehicle: 'vehicles/:id',
  retail: 'retail',
  picking: 'picking',

  profile: 'profile',
  users: 'users',
  user: 'users/:id',
  userNew: 'users/new',
  settings: 'settings',
  login: 'login',
} as const;
