import { Box, Card, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, List, Typography } from '@mui/material';
import { DiscountEnum, LogSaleStepEnum, discountsMeta, getPriceAmountChar, getPriceAmountColor, listDividersSx, useLogSaleContext } from '../utils';
import { SaleProductListItem } from '../components/SaleProductListItem.component';
import { NavigateButtons } from '../components/NavigateButtons.component';
import { AddCircleOutline, DeleteOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { currencyFormatter, currencyFormatterWithoutDecimals } from 'services';
import { TotalsOverview } from '../components/TotalsOverview.component';
import { DiscountChip } from '../components/DiscountChip.component';

export const DiscountStep: React.FC = () => {
  const { state, onUpdateSaleProduct, onSetStep, onRemoveProduct, saleProductsTransformed, onToggleDiscount } = useLogSaleContext();

  return (
    <Container sx={{ flex: 1, pt: { xs: 3, lg: 4 }, pb: 10 }} maxWidth="md">
      <NavigateButtons
        onNext={() => onSetStep(LogSaleStepEnum.payment)}
        onBack={() => onSetStep(LogSaleStepEnum.products)}
      />
      <Box display="flex" flexDirection="column" gap={1} mx={{ xs: -2, sm: 0 }}>
        <Card variant="outlined">
          <List disablePadding sx={listDividersSx()}>
            {saleProductsTransformed.map(({ product, frame, id, discount, globalDiscount, total }) => {
              const discountDisplay = `${getPriceAmountChar(discount)}${currencyFormatterWithoutDecimals.format(Math.abs(discount))}`;

              return (
                <SaleProductListItem
                  key={id}
                  wrapActionsOnMobile
                  name={product.name}
                  imageUrl={product.imageUrl}
                  otherAction={(
                    <IconButton onClick={() => onRemoveProduct(id)}>
                      <DeleteOutlined color="error" />
                    </IconButton>
                  )}
                  actions={(
                    <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" flex={1}>
                      <FormControlLabel
                        control={<Checkbox checked={frame} onChange={() => onUpdateSaleProduct(id, { frame: !frame })} />}
                        label="Frame"
                      />
                      <Box display="flex" alignItems="center">
                        <IconButton size="small" onClick={() => onUpdateSaleProduct(id, { discount: discount - 5 })}>
                          <RemoveCircleOutline fontSize="small" />
                        </IconButton>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize="large"
                          lineHeight={1}
                          color={getPriceAmountColor(discount)}
                          width="42px"
                          textAlign="center"
                        >
                          {discountDisplay}
                        </Typography>
                        <IconButton size="small" onClick={() => onUpdateSaleProduct(id, { discount: discount + 5 })}>
                          <AddCircleOutline fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                  bottomContent={(
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      flex={1}
                      px={2}
                      pb={0.5}
                      pt={{ xs: 1, sm: 0.5 }}
                      rowGap={1}
                      columnGap={2}
                      flexWrap="wrap"
                      bgcolor={theme => theme.palette.primary.background}
                    >
                      <DiscountChip discount={DiscountEnum.threeOrMore} />
                      <Box display="flex" gap={{ xs: 1, sm: 2 }} alignItems="center">
                        <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                          <Typography variant="body2" color="text.secondary">Discounts:</Typography>
                          <Typography
                            fontWeight={500}
                            color={getPriceAmountColor(globalDiscount)}
                          >
                            {currencyFormatterWithoutDecimals.format(globalDiscount)}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                          <Typography variant="body2" color="text.secondary">Adjustment:</Typography>
                          <Typography
                            fontWeight={500}
                            color={getPriceAmountColor(discount)}
                          >
                            {discountDisplay}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={{ xs: 0.5, sm: 1 }} alignItems="center">
                          <Typography variant="body2">Total:</Typography>
                          <Typography fontWeight={500}>{currencyFormatter.format(total)}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                />
              );
            })}
          </List>
        </Card>
        <Card variant="outlined">
          <Box display="flex" pl={3} pr={2} py={2} flexDirection={{ xs: 'column', sm: 'row' }} gap={3}>
            <Box flex={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Discounts</FormLabel>
                <FormGroup>
                  {discountsMeta.map(({ label, value, getDisabled }) => {
                    const disabled = getDisabled?.(state.saleProducts) ?? false;

                    return (
                      <FormControlLabel
                        key={value}
                        control={<Checkbox checked={state.discounts.includes(value)} onChange={() => onToggleDiscount(value)} />}
                        label={label}
                        disabled={disabled}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Box>
            <TotalsOverview />
          </Box>
        </Card>
      </Box>
    </Container>
  );
};