import { SchemaOf, mixed, number, object, string } from 'yup';
import { EditSaleForm } from './EditSale.modal';
import { SalePaymentEnum } from 'api/resources';
import { saleEnumHelpers } from 'helpers';
import { FrameEnum } from 'types';

export const saleValidationSchema = (): SchemaOf<EditSaleForm> => {
  return object({
    product: string().required('Product Required'),
    salePrice: number().required('Price Required'),
    frame: mixed<FrameEnum>().oneOf([ FrameEnum.frame, FrameEnum.noFrame ]).required(),
    payment: mixed<SalePaymentEnum>().oneOf(saleEnumHelpers.salePayment.enumValues).required(),
  });
};