import { PaletteMode, responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { makeComponents } from './components';
import { getPalette } from './palette';


export const getTheme = (mode: PaletteMode) => {
  let mainTheme = createTheme({
    spacing: 8,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: getPalette(mode),
    typography: {
      allVariants: {
        fontFamily: "'Roboto', sans-serif",
      },
    }
  });

  mainTheme = responsiveFontSizes(mainTheme);
  mainTheme.components = makeComponents(mainTheme);

  return mainTheme;
};
