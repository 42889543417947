import React from 'react';
import { CalendarMonth, ListOutlined } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export type TableOrCalendarViewType = 'table' | 'calendar';

export type TableOrCalendarViewToggleProps = {
  view: TableOrCalendarViewType;
  setView: (view: TableOrCalendarViewType) => void;
  disabled?: boolean;
};

export const TableOrCalendarViewToggle: React.FC<TableOrCalendarViewToggleProps> = props => {

  return (
    <ToggleButtonGroup
      value={props.view}
      color="primary"
      onChange={(_, value: TableOrCalendarViewType) => props.setView(value)}
      exclusive
      disabled={props.disabled}
    >
      <ToggleButton value="table">
        Table <ListOutlined fontSize="small" sx={{ ml: 1 }} />
      </ToggleButton>
      <ToggleButton value="calendar">
        Calendar <CalendarMonth fontSize="small" sx={{ ml: 1 }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
