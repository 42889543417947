import { DiscountOutlined } from '@mui/icons-material';
import { DiscountEnum, discountsMeta, useLogSaleContext } from '../utils';
import { Box, Chip } from '@mui/material';

export type DiscountChipProps = {
  discount: DiscountEnum;
};

export const DiscountChip: React.FC<DiscountChipProps> = props => {
  const { state } = useLogSaleContext();

  if (state.discounts.includes(props.discount)) {
    const discountMeta = discountsMeta.find(discountMeta => discountMeta.value === props.discount);

    if (discountMeta) {
      const label = discountMeta.chipLabel ?? discountMeta.label;

      return (
        <Box display="flex">
          <Chip variant="outlined" size="small" label={`"${label}" discount`} color="primary" icon={<DiscountOutlined />} />
        </Box>
      );
    }

  }

  return null;
};