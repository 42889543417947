import { Autocomplete, Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TextInput, FormDateTimePicker } from 'components';
import { useFormikContext } from 'formik';
import { formSx } from 'styles';
import { VehiclePageSectionRowId, VehicleSectionRowForm } from '../types';
import { GetUsersAsResourceResponse } from 'api/actions';
import React from 'react';

export type VehicleMaintenanceLogFormProps = {
  users: GetUsersAsResourceResponse['data'];
  usersLoading: boolean;
};

const maintenanceTitleOptions = [ 'Registration', 'Inspection', 'Emissions', 'Oil change' ];

export const VehicleMaintenanceLogForm: React.FC<VehicleMaintenanceLogFormProps> = ({ users, usersLoading }) => {
  const { values, setFieldValue, touched, errors }= useFormikContext<VehicleSectionRowForm[VehiclePageSectionRowId.MAINTENANCE_LOGS]>();

  const handleAssigneesChange = (event: SelectChangeEvent<string[]>) => {
    setFieldValue('assignees', event.target.value);
  };

  const titleError = touched.title && errors.title;

  const renderValue = (selected: string[]) => (
    <Box display="flex" flexWrap="wrap" gap={0.5}>
      {selected.map(value => {
        const user = users.find(user => user._id === value);

        return <Chip key={value} label={user ? user.name : value} size="small" />;
      })}
    </Box>
  );

  return (
    <Box sx={formSx.formGroup}>
      <Autocomplete
        freeSolo
        disableClearable
        options={maintenanceTitleOptions}
        value={values.title}
        onChange={(_, newValue) => {
          setFieldValue('title', newValue);
        }}
        openOnFocus={!values.title}
        renderInput={(params) => (
          <TextField
            {...params}
            name="title"
            label="Title"
            value={values.title}
            onChange={(e) => {
              setFieldValue('title', e.target.value);
            }}
            error={Boolean(titleError)}
            helperText={titleError}
            autoFocus
          />
        )}
      />
      <TextInput name="description" label="Description" multiline minRows={2} trim />
      <FormDateTimePicker name="date" label="Reminder" helperText="All assignees will get a reminder email"/>
      <FormControl fullWidth error={touched.assignees && Boolean(errors.assignees)}>
        <InputLabel id="assignees-label">Assignees</InputLabel>
        <Select
          labelId="assignees-label"
          multiple
          value={values.assignees}
          onChange={handleAssigneesChange}
          input={<OutlinedInput id="assignees-chip" label="Assignees" />}
          renderValue={renderValue}
          disabled={usersLoading}
        >
          {users.map(user => (
            <MenuItem key={user._id} value={user._id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
