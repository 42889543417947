import {
  LinkProps as MuiLinkProps, Link as MuiLink, Theme,
} from '@mui/material';
import { forwardRef } from 'react';
import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom';

export type BaseLinkProps = Pick<RouterLinkProps, 'to' | 'replace'>;
export type LinkProps = {
  onClick?: () => void;
  getColor?: (theme: Theme) => string;
} & BaseLinkProps & Omit<MuiLinkProps, 'color'>;

const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>((props, ref) => {
  return (
    <RouterLink
      ref={ref}
      {...props}
    />
  );
});

export const Link: React.FC<LinkProps> = ({ children, onClick, underline = 'always', sx, getColor, ...props }) => {

  const getLinkColor = (theme: Theme) => {
    return getColor ? getColor(theme) : theme.palette.text.primary;
  };

  return (
    <MuiLink
      onClick={onClick}
      underline={underline}
      component={BaseLink}
      sx={({
        ...sx,
        color: theme => `${getLinkColor(theme)} !important`,
        textDecorationColor: theme => `${getLinkColor(theme)} !important`,
      })}
      {...props}
    >
      {children}
    </MuiLink>
  );
};
