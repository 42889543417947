import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useRef, useState } from 'react';

export type TPopperWrapOption = {
  label: string;
  menuItemProps?: MenuItemProps;
  labelTypographyProps?: TypographyProps;
  preventCloseOnClick?: boolean;
};

export type PopperWrapProps = {
  anchor: (onToggle: () => void) => React.ReactNode;
  options: TPopperWrapOption[];
};

export const PopperWrap: React.FC<PopperWrapProps> = ({ anchor, options }) => {
  const [ open, setOpen ] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current) {
      if (anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
    }

    setOpen(false);
  };

  return (
    <div ref={anchorRef}>
      {anchor(handleToggle)}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 1001 }}
        placement="bottom-end"
      >
        {({ TransitionProps }) => {
          return (
            <Grow {...TransitionProps}>
              <Paper variant="outlined" sx={{ borderRadius: 0 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map(({ label, menuItemProps, labelTypographyProps, preventCloseOnClick }) => {
                      return (
                        <MenuItem
                          key={label}
                          {...menuItemProps}
                          onClick={(event) => {
                            if (!preventCloseOnClick) {
                              handleToggle();
                            }
                            menuItemProps?.onClick?.(event);
                          }}
                        >
                          <Typography {...labelTypographyProps}>{label}</Typography>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
};
