import { Box, Button, Input, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { SalePaymentEnum } from 'api/resources';
import { EventSalesLogProductListProps } from './EventSalesLogProductList.component';

export type ProductValuesType = {
  productId: string;
} & Pick<EventSalesLogProductListProps, 'onAddProduct'>;

export type ProductValuesStateType = {
  frame: string;
  payment: SalePaymentEnum;
  price: string;
};

export const ProductValues: React.FC<ProductValuesType> = ({ onAddProduct, productId }) => {
  const [ loading, setLoading ] = useState(false);
  const initialValues = { frame: 'yes', payment: SalePaymentEnum.cash, price: '50' };
  const [ values, setValues ] = useState<ProductValuesStateType>(initialValues);

  const { frame, payment, price } = values;

  const onFrameChange = (val: string) => {
    if (!val) return;
    setValues((p) => ({ ...p, frame: val }));
  };
  const onPaymentChange = (val: SalePaymentEnum) => {
    if (!val) return;
    setValues((p) => ({ ...p, payment: val }));
  };
  const onPriceChange = (val: string) => setValues((p) => ({ ...p, price: val }));

  const addProductHandler = async () => {
    setLoading(true);
    await onAddProduct({
      frame: frame === 'yes',
      payment: payment,
      salePrice: Number(price),
      product: productId
    });
  };

  return (
    <Box
      display="flex"
      alignItems={{ xs: 'flex-start', lg: 'center' }}
      justifyContent="space-between"
      mt={2}
      mb={3}
      ml={1}
      flexDirection={{ xs: 'column', lg: 'initial' }}
    >
      <Box
        display="flex"
        gap="2rem"
        alignItems="flex-start"
        flexDirection={{ xs: 'column', lg: 'initial' }}
        mb={{ xs: 3, md: 0 }}
      >
        <Box>
          <Typography variant="subtitle2" display="block">Frame</Typography>
          <ToggleButtonGroup
            size="small"
            value={frame}
            color="primary"
            exclusive
            onChange={(_e, val) => onFrameChange(val)}
            disabled={loading}
          >
            <ToggleButton value="yes">Yes</ToggleButton>
            <ToggleButton value="no">No</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography variant="subtitle2" display="block">Payment</Typography>
          <ToggleButtonGroup
            size="small"
            value={payment}
            color="primary"
            exclusive
            onChange={(_e, val: SalePaymentEnum) => onPaymentChange(val)}
            disabled={loading}
          >
            <ToggleButton value="cash">Cash</ToggleButton>
            <ToggleButton value="card">Card</ToggleButton>
            <ToggleButton value="venmo">Venmo</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography variant="subtitle2" display="block">Price</Typography>
          <Input
            disabled={loading}
            value={price}
            size="small"
            name="price"
            type="number"
            onChange={(e) => onPriceChange(e.target.value)}
            sx={{ width: 64 }}
          />
        </Box>
      </Box>
      <Box width={{ sx: '100%', lg: 'initial' }}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={addProductHandler}
          disabled={!price || loading}
          fullWidth
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};
