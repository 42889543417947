import { Alert, AlertTitle } from '@mui/material';
import { GetUsersAvailabilitySetHashResponse } from 'api/actions';

export type AvailabilitySetAlertProps = Omit<GetUsersAvailabilitySetHashResponse['data'][string], 'availabilitySlots'>;

export const AvailabilitySetAlert: React.FC<AvailabilitySetAlertProps> = props => {
  return (
    <Alert severity={props.severity}>
      <AlertTitle>{props.title}</AlertTitle>
      {props.label}
    </Alert>
  );
};