import { EnumHelpers } from 'helpers/enums/types';
import { DateObjectType, DateService } from 'services';

export type DateRange = {
  start: DateObjectType;
  end: DateObjectType;
} | null;

export const enum DateRangeShortcut {
  today = 'today',
  yesterday = 'yesterday',
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  last_90_days = 'last_90_days',
  last_365_days = 'last_365_days',
  last_month = 'last_month',
  last_6_month = 'last_6_month',
  last_12_month = 'last_12_month',
  last_year = 'last_year'
}

const getDateRangeShortcutValueToDateRangeHash = (): Record<DateRangeShortcut, NonNullable<DateRange>> => {
  const today = DateService.dayjs();

  return {
    [DateRangeShortcut.today]: { start: today, end: today },
    [DateRangeShortcut.yesterday]: { start: today.subtract(1, 'day'), end: today.subtract(1, 'day') },
    [DateRangeShortcut.last_7_days]: { start: today.subtract(7, 'day'), end: today },
    [DateRangeShortcut.last_30_days]: { start: today.subtract(30, 'day'), end: today },
    [DateRangeShortcut.last_90_days]: { start: today.subtract(90, 'day'), end: today },
    [DateRangeShortcut.last_365_days]: { start: today.subtract(365, 'day'), end: today },
    [DateRangeShortcut.last_month]: { start: today.subtract(1, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') },
    [DateRangeShortcut.last_6_month]: { start: today.subtract(6, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') },
    [DateRangeShortcut.last_12_month]: { start: today.subtract(12, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') },
    [DateRangeShortcut.last_year]: { start: today.subtract(1, 'year').startOf('year'), end: today.subtract(1, 'year').endOf('year') },
  };
};

const enumValues: DateRangeShortcut[] = [
  DateRangeShortcut.today,
  DateRangeShortcut.yesterday,
  DateRangeShortcut.last_7_days,
  DateRangeShortcut.last_30_days,
  DateRangeShortcut.last_90_days,
  DateRangeShortcut.last_365_days,
  DateRangeShortcut.last_month,
  DateRangeShortcut.last_6_month,
  DateRangeShortcut.last_12_month,
  DateRangeShortcut.last_year,
];

export const dateRangeShortcutEnumHelpers: EnumHelpers<DateRangeShortcut> & {
  getDateRange: (value: DateRangeShortcut) => DateRange;
  getValueForDateRange: (dateRange: NonNullable<DateRange>) => DateRangeShortcut | undefined;
} = {
  enumValues: enumValues,
  getLabel: (value) => {
    switch(value) {
      case DateRangeShortcut.today:
        return 'Today';
      case DateRangeShortcut.yesterday:
        return 'Yesterday';
      case DateRangeShortcut.last_7_days:
        return 'Last 7 days';
      case DateRangeShortcut.last_30_days:
        return 'Last 30 days';
      case DateRangeShortcut.last_90_days:
        return 'Last 90 days';
      case DateRangeShortcut.last_365_days:
        return 'Last 365 days';
      case DateRangeShortcut.last_month:
        return 'Last month';
      case DateRangeShortcut.last_6_month:
        return 'Last 6 month';
      case DateRangeShortcut.last_12_month:
        return 'Last 12 month';
      case DateRangeShortcut.last_year:
        return 'Last year';
      default:
        return '';
    }
  },
  getColor: () => 'unknown',
  getDateRange: (value) => getDateRangeShortcutValueToDateRangeHash()[value],
  getValueForDateRange: (dateRange) => {
    const dateRangeShortcutValueToDateRangeHash = getDateRangeShortcutValueToDateRangeHash();

    return enumValues.find((key: DateRangeShortcut) => {
      const keyDateRange = dateRangeShortcutValueToDateRangeHash[key];

      return DateService.dayjs(keyDateRange.start).isSame(dateRange.start, 'day') && DateService.dayjs(keyDateRange.end).isSame(dateRange.end, 'day');
    });
  }
};

