import { Edit } from '@mui/icons-material';
import { Box, BoxProps, CircularProgress, IconButton } from '@mui/material';
import { missingImg } from 'assets';
import { useMemo, useState } from 'react';
import { EditImageModal, EditImageModalProps, ImageAsset } from './EditImageModal.component';
import { downloadBlob } from './editable-image.utils';
import { DeleteResourceModal, DeleteResourceModalType } from 'components/Modal';

export type EditableImageProps = {
  imageUrl?: string;
  width: BoxProps['width'];
  height: BoxProps['height'];
  disabledEditable?: boolean;
  loading?: boolean;
  onUpload: (imageBlob: Blob) => void;
  deleteConfig?: {
    onDelete: () => void;
  } & Partial<Omit<DeleteResourceModalType, 'onClose' | 'onDelete'>>;
  variant?: 'rounded' | 'square';
} & Pick<EditImageModalProps, 'modalTitle'>;

export const EditableImage: React.FC<EditableImageProps> = props => {
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isDelete, setIsDelete ] = useState(false);

  const onSaveNewImage = async (asset: ImageAsset) => {
    setIsEdit(false);

    const file = await downloadBlob(asset.croppedImageUrl);

    props.onUpload(file);
  };

  const deleteModal = useMemo(() => {
    const deleteConfig = props.deleteConfig;

    if (deleteConfig && isDelete) {

      const onDeleteImage = () => {
        setIsEdit(false);
        setIsDelete(false);
        deleteConfig.onDelete();
      };

      return (
        <DeleteResourceModal
          onClose={() => setIsDelete(false)}
          deleteButtonText="Delete Image"
          description="Are you sure you want to delete this image?"
          {...deleteConfig}
          onDelete={onDeleteImage}
          TransitionProps={{ timeout: 0 }}
          transitionDuration={0}
        />
      );
    }

    return null;
  }, [ isDelete, props.deleteConfig ]);

  const borderRadius = props.variant === 'rounded' ? '50%' : undefined;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={props.width}
      height={props.height}
      minWidth={props.width}
      component="div"
      sx={{ '&:hover > .pop': { display: props.disabledEditable || props.loading ? 'none' : 'flex' } }}
      position="relative"
    >
      {deleteModal}
      {isEdit && (
        <EditImageModal
          onClose={() => setIsEdit(false)}
          imageUrl={props.imageUrl}
          onSaveNewImage={onSaveNewImage}
          onDeleteImage={props.deleteConfig ? () => setIsDelete(true) : undefined}
          modalTitle={props.modalTitle}
          modalSx={{ visibility: isDelete ? 'hidden' : undefined }}
        />
      )}
      <img style={{ maxWidth: '100%', height: 'auto', maxHeight: '100%', borderRadius: borderRadius }} src={props.imageUrl || missingImg} alt="header" />
      <Box display="none" component="div" className="pop" position="absolute" width="100%" height="100%" sx={{ bgcolor: 'rgba(0, 0, 0, 0.3)' }} justifyContent="center" alignItems="center" borderRadius={borderRadius}>
        <IconButton onClick={() => setIsEdit(true)} sx={{ color: theme => theme.palette.common.white }}>
          <Edit />
        </IconButton>
      </Box>
      {props.loading && (
        <Box position="absolute" display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" sx={{ bgcolor: 'rgba(0, 0, 0, 0.3)' }} borderRadius={borderRadius}>
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      )}
    </Box>
  );
};