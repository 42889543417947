import React, { useMemo, useState } from 'react';
import { CardContentMarkdown, DatesStatusChip, EventDatePageSection, EventDatePageSectionRowId, EventDateSectionProps, MarkdownInput, SectionCard, SectionCardRow, Select, StaffStatusChip, useEventContext } from 'components';
import { eventDateEnumHelpers, getEventDateStaffStatus, getEventDatesStatus, getSelectOptionsFromEnumHelper, processFormValueUpdate, removeUnchanged } from 'helpers';
import { updateEventDate } from 'api/actions';
import { boolean, mixed, number, object, string } from 'yup';
import { EventDateSetupBreakdownTypeEnum, EventDateTypeEnum } from 'api/resources';
import { DateService } from 'services';
import { FORMAT_DATE } from 'constants/format-date';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { EventDateSectionCardRowEditable } from '../components/EventDateSectionCardRowEditable.component';
import { EventDateTimelines } from '../components/EventDateTimelines.component';
import { formSx } from 'styles';
import { EventDateConfirmedTimesForm } from '../forms/EventDateConfirmedTimes.form';
import { EventDateSetupForm } from '../forms/EventDateSetup.form';
import { EventDateStartEndTimesForm } from '../forms/EventDateMarket.form';
import { EventDateCloseProcedureForm } from '../forms/EventDateCloseProcedure.form';
import { EventDateTimesList } from '../components/EventDateTimesList.component';
import { ListOutlined, TimelineOutlined } from '@mui/icons-material';
import { EventDateTravelForm } from '../forms/EventDateTravel.form';

type View = 'list' | 'timelines';

export const OverviewSection: React.FC<EventDateSectionProps> = ({ eventDate }) => {
  const { event } = useEventContext();

  const isTravel = eventDate.type === EventDateTypeEnum.travel;
  const fullFormattedDate = DateService.dayjsTz(eventDate.dateAsUtc).format(FORMAT_DATE.standardFull);
  const typeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventDateEnumHelpers.type), []);

  const TypeIcon = eventDateEnumHelpers.type.getIcon(eventDate.type);

  const [ timesView, setTimesView ] = useState<View>('timelines');

  return (
    <SectionCard title="Overview" id={EventDatePageSection.OVERVIEW}>
      <SectionCardRow title="Event name">{event.name}</SectionCardRow>
      <SectionCardRow title="Date">{fullFormattedDate}</SectionCardRow>
      <SectionCardRow title="Date Status"><DatesStatusChip value={getEventDatesStatus([ eventDate ])} /></SectionCardRow>
      <SectionCardRow title="Staff Status"><StaffStatusChip value={getEventDateStaffStatus(eventDate)} /></SectionCardRow>
      <EventDateSectionCardRowEditable
        title="Type"
        rowId={EventDatePageSectionRowId.TYPE}
        formikProps={{
          initialValues: { type: eventDate.type },
          onSubmit: (values) => {
            const updates = values.type === EventDateTypeEnum.regular
              ? {}
              : {
                arriveAsEarlyAs: null,
                noVehiclesAllowedAfter: null,
                allVehiclesMustBeRemovedBy: null,
                setupEndTime: null,
              };

            updateEventDate(eventDate._id, { ...updates, type: values.type });
          },
          validationSchema: object({ type: mixed<EventDateTypeEnum>().oneOf(eventDateEnumHelpers.type.enumValues).required() })
        }}
        form={(
          <Select
            label="Type"
            name="type"
            fullWidth
            options={typeOptions}
          />
        )}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {TypeIcon && <TypeIcon />} {eventDateEnumHelpers.type.getLabel(eventDate.type)}
        </Box>
      </EventDateSectionCardRowEditable>

      {!isTravel && (
        <EventDateSectionCardRowEditable
          title="Times"
          rowId={EventDatePageSectionRowId.TIMES}
          formikProps={{
            initialValues: {
              confirmedTimes: eventDate.confirmedTimes,
              startTime: eventDate.startTime,
              endTime: eventDate.endTime,
              setupType: eventDate.setupType,
              breakdownType: eventDate.breakdownType,
              arriveAsEarlyAs: eventDate.arriveAsEarlyAs ?? '',
              noVehiclesAllowedAfter: eventDate.noVehiclesAllowedAfter ?? '',
              allVehiclesMustBeRemovedBy: eventDate.allVehiclesMustBeRemovedBy ?? '',
              setupEndTime: eventDate.setupEndTime ?? '',
              bufferTimeInMinutes: eventDate.bufferTimeInMinutes ?? 20,
              displaySetupInMinutes: eventDate.displaySetupInMinutes ?? 90,
              breakdownInMinutes: eventDate.breakdownInMinutes ?? 90,
            },
            onSubmit: (values, { initialValues }) => {
              const updates = removeUnchanged(values, initialValues);

              return updateEventDate(eventDate._id, {
                confirmedTimes: updates.confirmedTimes,
                startTime: updates.startTime,
                endTime: updates.endTime,
                setupType: updates.setupType,
                breakdownType: updates.breakdownType,
                arriveAsEarlyAs: processFormValueUpdate.string(updates.arriveAsEarlyAs),
                noVehiclesAllowedAfter: processFormValueUpdate.string(updates.noVehiclesAllowedAfter),
                allVehiclesMustBeRemovedBy: processFormValueUpdate.string(updates.allVehiclesMustBeRemovedBy),
                setupEndTime: processFormValueUpdate.string(updates.setupEndTime),
                bufferTimeInMinutes: updates.bufferTimeInMinutes,
                displaySetupInMinutes: updates.displaySetupInMinutes,
                breakdownInMinutes: updates.breakdownInMinutes,
              });
            },
            validationSchema: object({
              setupType: mixed<EventDateSetupBreakdownTypeEnum>().oneOf(eventDateEnumHelpers.setupBreakdownType.enumValues).required(),
              breakdownType: mixed<EventDateSetupBreakdownTypeEnum>().oneOf(eventDateEnumHelpers.setupBreakdownType.enumValues).required(),
              confirmedTimes: boolean().required(),
              bufferTimeInMinutes: number().min(0).max(59).required(),
              displaySetupInMinutes: number().min(0).max(180).required(),
              breakdownInMinutes: number().min(0).max(180).required(),
              startTime: string().required(),
              endTime: string().required(),
              arriveAsEarlyAs: string().default(''),
              noVehiclesAllowedAfter: string().default(''),
              allVehiclesMustBeRemovedBy: string().default(''),
              setupEndTime: string().default(''),
            })
          }}
          form={(
            <Box sx={formSx.formGroup}>
              <EventDateConfirmedTimesForm />
              <EventDateTravelForm date={DateService.dayjsTz(eventDate.dateAsUtc)} />
              {eventDate.type === EventDateTypeEnum.regular && <EventDateSetupForm />}
              <EventDateStartEndTimesForm />
              {eventDate.type === EventDateTypeEnum.regular && <EventDateCloseProcedureForm />}
            </Box>
          )}
        >
          <Box display="flex" justifyContent="flex-end" mb={1}>
            <ToggleButtonGroup
              value={timesView}
              color="primary"
              onChange={(_, value: View) => value && setTimesView(value)}
              exclusive
              size="small"
            >
              <ToggleButton value="timelines">
                Timelines <TimelineOutlined fontSize="small" sx={{ ml: 1 }} />
              </ToggleButton>
              <ToggleButton value="list">
                List <ListOutlined fontSize="small" sx={{ ml: 1 }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {timesView === 'list'
            ? <EventDateTimesList eventDate={eventDate} />
            : <EventDateTimelines eventDate={eventDate} />
          }
        </EventDateSectionCardRowEditable>
      )}

      <EventDateSectionCardRowEditable
        title="Notes"
        rowId={EventDatePageSectionRowId.NOTES}
        formikProps={{
          initialValues: { notes: eventDate.notes ?? '' },
          onSubmit: (values) => updateEventDate(eventDate._id, { notes: processFormValueUpdate.string(values.notes) }),
          validationSchema: object({ notes: string().default('') })
        }}
        form={<MarkdownInput name="notes" label="Notes" />}
        formFullWidth
      >
        <CardContentMarkdown markdown={eventDate.notes} />
      </EventDateSectionCardRowEditable>
      {eventDate.createdBy && <SectionCardRow title="Created by">{eventDate.createdBy.name}</SectionCardRow>}
    </SectionCard>
  );
};
