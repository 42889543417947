import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getGenres } from 'api/actions/genre';

export const useGenres = () => {
  return useQuery(QUERY_KEY.GENRES, async () => {
    const response = await getGenres();

    return response.data;
  });
};
