import React from 'react';
import { UserSessionsCardProps } from '../components/UserSessionsCard.component';
import { updateUserPassword, updateUserPin } from 'api/actions';
import { SectionCard } from 'components';
import { UserSectionCardRowEditable } from '../components/UserSectionCardRowEditable.component';
import { ProfilePageSection, UserPageSectionRowId } from '../types';
import { passwordValidationSchema, pinValidationSchema } from '../validation';
import { ChangePasswordForm, ChangePinForm } from '../forms';

export const SecurityCredentialsSection: React.FC<UserSessionsCardProps> = ({ user }) => {
  return (
    <SectionCard title="Security Credentials" id={ProfilePageSection.CREDENTIALS}>
      <UserSectionCardRowEditable
        title="Password"
        rowId={UserPageSectionRowId.PASSWORD}
        formikProps={{
          initialValues: { newPassword: '', currentPassword: '' },
          onSubmit: updateUserPassword,
          validationSchema: passwordValidationSchema
        }}
        form={<ChangePasswordForm />}
        user={user}
      >
        ●●●●●●●●
      </UserSectionCardRowEditable>
      <UserSectionCardRowEditable
        title="Pin"
        rowId={UserPageSectionRowId.PIN}
        formikProps={{
          initialValues: { password: '', newPin: '' },
          onSubmit: updateUserPin,
          validationSchema: pinValidationSchema
        }}
        form={<ChangePinForm />}
        user={user}
      >
        ●●●●
      </UserSectionCardRowEditable>
    </SectionCard>
  );
};
