import { Box, Grid } from '@mui/material';
import { formSx } from 'styles';

export type FormGroupLayoutProps = {
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  maintainLeftIconGutter?: boolean;
  children: React.ReactNode;
};

export const FormGroupLayout: React.FC<FormGroupLayoutProps> = props => {

  const formContent = <Box sx={formSx.formGroup}>{props.children}</Box>;

  if (!props.leftIcon && !props.maintainLeftIconGutter) {
    return formContent;
  }

  return (
    <Grid container alignItems="start" spacing={2}>
      <Grid item xs={12} md={1}>
        {props.leftIcon && <props.leftIcon />}
      </Grid>
      <Grid item xs={12} md={11}>
        {formContent}
      </Grid>
    </Grid>
  );
};