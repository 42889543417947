import { Typography } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { eventDateEnumHelpers } from 'helpers';

export type EventDaysSummaryProps = {
  event: GetEventsResponse['data'][number];
};

export const EventDaysSummary: React.FC<EventDaysSummaryProps> = ({ event }) => {
  return (
    <Typography display="flex" alignItems="center" color="text.secondary">
      Event is<b>&nbsp;{event.dates.length}&nbsp;</b> day{!!event.dates.length && 's'} long&nbsp;
      {!!event.dates.length && <>
      ({eventDateEnumHelpers.type.enumValues
          .filter(type => event.dates.filter(d => d.type === type).length)
          .map((type, index) => {
            const dates = event.dates.filter(d => d.type === type);
            const Icon = eventDateEnumHelpers.type.getIcon(type);

            return <>{!!index && ', '}{dates.length} <Icon fontSize="small" /></>;
          })})
      </>}.
    </Typography>
  );
};