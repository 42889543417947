import { Brightness4, MenuOpen } from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
  IconButton,
  Divider,
  Drawer as MuiDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMainLayoutContext } from 'contexts';
import { useIsMobile } from 'hooks';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useThemeModeContext } from 'services/theme/withTheme';
import { SideBarNavItem, MainLayoutConfType, SideBarNavItemWithoutNested } from 'types';

export type SidebarProps = {
  useLayoutConf: () => MainLayoutConfType;
};

type ListSubheaderProps = {
  open: boolean;
} & MuiListSubheaderProps;

export const sideBarDrawerWidth: number = 235;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => {
  return prop !== 'open';
} })(({ theme, open }) => {
  return {
    '& .MuiDrawer-paper': {
      '& *': {
        transition: theme.transitions.create([ 'padding' ], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      background: theme.palette.background.defaultDarker,
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: sideBarDrawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      overflowX: 'hidden',
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(5),
        },
      }),
    },
  };
});

const ListSubheader = styled(MuiListSubheader, {
  shouldForwardProp: (prop) => {
    return prop !== 'open';
  },
})<ListSubheaderProps>(({ theme, open }) => {
  return {
    lineHeight: '100%',
    color: theme.palette.text.primary,
    background: 'transparent',
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.4),
    fontSize: 'small',
    opacity: 1,
    transition: theme.transitions.create([ 'opacity', 'padding', 'lineheight' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(!open && {
      lineHeight: 0,
      padding: 0,
      opacity: 0,
      transition: theme.transitions.create([ 'opacity', 'padding', 'lineheight' ], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  };
});

export const Sidebar: React.FC<SidebarProps> = ({ useLayoutConf }) => {
  const { isDrawerOpen, onToggleDrawer } = useMainLayoutContext();
  const isMobile = useIsMobile();
  const mainNavConfig = useLayoutConf();
  const { nextMode, onToggleThemeMode } = useThemeModeContext();

  const getLink = useMemo(
    () => {
      return (item: SideBarNavItemWithoutNested) => {
        return forwardRef<HTMLAnchorElement>((linkProps, ref) => {
          const onClick = isMobile ? onToggleDrawer : undefined;

          return (
            <NavLink
              to={item.to}
              {...linkProps}
              ref={ref}
              onClick={onClick}
            />
          );
        });
      };
    },
    [ isMobile, onToggleDrawer ],
  );

  const mapNavItems = useCallback((items: SideBarNavItem[]) => {
    return items.map((item: SideBarNavItem, idx): JSX.Element => {
      if ('nested' in item) {
        return (
          <React.Fragment key={idx}>
            <ListSubheader open={isDrawerOpen}>{item.title}</ListSubheader>
            <Box pl={isDrawerOpen ? 2 : 0}>{mapNavItems(item.nested)}</Box>
          </React.Fragment>
        );
      }

      return (
        <ListItemButton
          key={idx}
          component={getLink(item)}
          sx={(theme) => {
            return {
              py: 0,
              px: 1,
              borderRadius: isDrawerOpen ? theme.spacing(1) : 0,
              height: { xs: '32px', sm: '28px' },

              '&:hover': {
                background: theme.palette.background.default,
              },

              '& .MuiListItemIcon-root': {
                fontSize: '14px',
                minWidth: 'auto',
                mr: 1.5,
              },

              '& .MuiListItemText-root': {
                width: 0,
              },

              '&.active': {
                background: theme.palette.background.paper,

                '& .MuiTypography-root': {
                  fontWeight: 600,
                }
              },
            };
          }}
        >
          <ListItemIcon><item.icon fontSize="small" /></ListItemIcon>
          <ListItemText primary={item.title} primaryTypographyProps={{ fontWeight: 500, fontSize: 'small' }} />
        </ListItemButton>
      );
    });
  }, [ getLink, isDrawerOpen ]);


  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <Toolbar />
      <Box display={{ xs: 'none', sm: 'initial' }}>
        <Box display="flex" p={0.5}>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              onToggleDrawer();
            }}
          >
            <MenuOpen sx={{ transform: isDrawerOpen ? 'none' : 'rotate(180deg)' }} fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
      </Box>
      <Box role="presentation" flex={1} px={isDrawerOpen ? '12px' : 0} py={1}>
        <List component="nav" disablePadding>
          {mapNavItems(mainNavConfig)}
        </List>
      </Box>
      <Box display="flex" alignItems="flex-end" px={0.5} pb={1}>

        <Box>
          <Typography fontSize="small" color="text.secondary" pl={4.5} component="i">This is not finished</Typography>
          <Box display="flex" gap={1} alignItems="center">
            <IconButton size="small" onClick={onToggleThemeMode}>
              <Brightness4 fontSize="small" />
            </IconButton>
            <Typography fontSize="small" fontWeight={500}>Change to {nextMode} mode</Typography>
          </Box>
        </Box>

      </Box>
    </Drawer>
  );
};
