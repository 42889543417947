import { Box } from '@mui/material';
import { PasswordTextInput, PinInput } from 'components';
import { formSx } from 'styles';

export const UserPasswordPinForm: React.FC = () => {

  return (
    <Box sx={formSx.formGroup}>
      <PasswordTextInput
        name="password"
        label="Password"
        autoComplete="new-password"
        helperText="Enter a password"
      />
      <PasswordTextInput
        name="passwordConfirmation"
        label="Confirm Password"
        autoComplete="new-password"
        helperText="Confirm password"
      />
      <PinInput
        name="pin"
        label="Pin"
        helperText="Enter a 4 digit pin"
      />
      <PinInput
        name="pinConfirmation"
        label="Pin Confirmation"
        helperText="Confirm 4 digit pin"
      />
    </Box>
  );
};