import { User, UserEmployeeRoleEnum, UserRoleEnum } from 'api/resources';
import { useAppSelector } from 'app/hooks';

export type UseCurrentUserOutput = User & {
  sessionId: string;
  getIsMe: (user: User | string) => boolean;
  isAdmin: boolean;
  isAuthorizedEmployee: (requiredEmployeeRoles: UserEmployeeRoleEnum[], allowAdmin?: boolean) => boolean;
};

export const useCurrentUser = (): UseCurrentUserOutput => {
  const me = useAppSelector(state => state.auth.me) as User;
  const sessionId = useAppSelector(state => state.auth.sessionId) as string;

  const getIsMe = (user: User | string) => {
    if (typeof user === 'string') {
      return me._id === user;
    }

    return me._id === user._id;
  };

  const isAdmin = me.role === UserRoleEnum.admin;
  const isAuthorizedEmployee = (requiredEmployeeRoles: UserEmployeeRoleEnum[], allowAdmin: boolean = true) => {
    return (allowAdmin && isAdmin) || requiredEmployeeRoles.some(role => me.employeeRoles.includes(role));
  };

  return { ...me, sessionId, getIsMe, isAdmin, isAuthorizedEmployee };
};