import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean } from 'components/RadioGroup/RadioGroupYesNo.component';
import { Form, useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { formSx } from 'styles';
import { ResourceForm, YesNoUnknownEnum } from 'types';

export const ProductAvailabilityForm: React.FC = () => {
  const createName = (name: keyof ResourceForm['product']['availability']) => `availability.${name}`;

  const [ websiteField ] = useField<ResourceForm['product']['availability']['website']>(createName('website'));
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (websiteField.value !== 'yes') {
      setFieldValue(createName('uploaded'), 'unknown');
    }
  }, [ websiteField.value, setFieldValue ]);

  return (
    <Form>
      <Box sx={formSx.formGroup}>
        <RadioGroupYesNoBoolean
          name={createName('events')}
          label="Available At Events"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('website')}
          label="Available On Website"
          row
          includeUnknown
        />
        <RadioGroupYesNoBoolean
          name={createName('uploaded')}
          label="Uploaded To Website"
          row
          includeUnknown
          disabled={websiteField.value !== YesNoUnknownEnum.yes}
          helperText={websiteField.value !== YesNoUnknownEnum.yes ? 'Must be available on website' : undefined}
        />
      </Box>
    </Form>
  );
};
