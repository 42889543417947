import { Box } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react';

export type AnalyticsChartTooltipProps = {
  tooltipPosition: {left: number; top: number};
  chart: Chart<'line'> | Chart<'bar'> | Chart<'pie'>;
  tooltip: React.ReactNode;
};

export const AnalyticsChartTooltip: React.FC<AnalyticsChartTooltipProps> = (props) => {
  const [ _, setState ] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [ boxWidth, setBoxWidth ] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;

      setBoxWidth(width);
    }
  }, [ props.tooltip ]);

  useEffect(() => {
    const handleScroll = () => {
      setState(p => p + 1);
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const position = { left: 0, top: 0 };

  if(props.chart) {
    position.left = props.tooltipPosition.left + props.chart.canvas.getBoundingClientRect().left;
    position.top = props.tooltipPosition.top + props.chart.canvas.getBoundingClientRect().top;
  }

  const positionRight =  window.innerWidth - position.left <= 250;

  return (
    <Box
      ref={ref}
      sx={theme => ({
        position: 'fixed',
        pointerEvents: 'none',
        top: position.top,
        left: positionRight ? position.left - boxWidth :  position.left,
        transition: 'top 0.1s ease, left 0.1s ease',

        background: theme.palette.background.paper,
        border: '1px solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[300],
        py: 2,
        px: 1,
        ml: positionRight ? -1 : 1,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
      })}
    >
      {props.tooltip}
    </Box>
  );
};