import { ArrowDropDownOutlined } from '@mui/icons-material';
import {
  Button, ButtonGroup as MuiButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Portal,
} from '@mui/material';
import { useRef, useState } from 'react';

export type ButtonGroupType = {
  options: {
    label: string;
    disabled?: boolean;
    onClick: () => void | Promise<void>;
  }[];
  loading?: boolean;
  fitContainer?: boolean;
};

export const ButtonGroup: React.FC<ButtonGroupType> = ({ options, loading = false, fitContainer }) => {
  const [ open, setOpen ] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const handleClick = () => {
    options[selectedIndex].onClick();
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => {
      return !prevOpen;
    });
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current
            && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const buttonText = options[selectedIndex].label;
  const buttonDisabled = options[selectedIndex].disabled;

  return (
    <>
      <MuiButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={fitContainer ? { height: '100%', display: 'flex' } : undefined} fullWidth={fitContainer}>
        <Button
          onClick={handleClick}
          disabled={buttonDisabled || loading}
          sx={fitContainer ? { flex: 1 } : undefined}
        >
          {buttonText}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={fitContainer ? { flex: 0 } : undefined}
          disabled={loading}
        >
          <ArrowDropDownOutlined />
        </Button>
      </MuiButtonGroup>
      <Portal>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          sx={{ zIndex: 10000 }}
        >
          {({ TransitionProps, placement }) => {
            return (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map(({ label, disabled = false }, index) => {
                        return (
                          <MenuItem
                            key={label}
                            disabled={disabled}
                            selected={index === selectedIndex}
                            onClick={(event) => {
                              return handleMenuItemClick(event, index);
                            }}
                          >
                            {label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </Popper>
      </Portal>
    </>
  );
};
