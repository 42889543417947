import React, { Component, ErrorInfo } from 'react';
import { ErrorPageContent } from './ErrorPageContent.component';


type ErrorBoundaryProps = {
  children: React.ReactNode;
  pathname: string;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    if (this.props.pathname !== prevProps.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPageContent
          errorCode={500}
          message="There has been a critical error. Try refreshing or clearing site data. If the problem persists, contact management and only return to this page once issue is resolved."
        />
      );
    }

    return this.props.children;
  }
}