import { Box } from '@mui/material';
import { MarkdownInput, TextInput } from 'components';
import { formSx } from 'styles';

export const EventOraganizerContactForm: React.FC = () => {
  return (
    <Box sx={formSx.formGroup}>
      <TextInput
        trim
        fullWidth
        id="name"
        name="name"
        label="Event Organizer Name *"
      />
      <TextInput
        trim
        fullWidth
        id="title"
        name="title"
        label="Event Organizer Title *"
      />
      <TextInput
        trim
        fullWidth
        id="phone"
        name="phone"
        label="Phone"
      />
      <TextInput
        trim
        fullWidth
        id="email"
        name="email"
        label="Email"
      />
      <TextInput
        trim
        fullWidth
        id="extension"
        name="extension"
        label="Extension"
      />
      <MarkdownInput name="note" label="Note" />
    </Box>
  );
};
