import { useMemo } from 'react';
import { InventoryTableEditableMode } from '../useInventoryTable.hook';
import { Alert, Box, Checkbox, FormControlLabel } from '@mui/material';
import { UnknownEnum } from 'types';

export type InventoryTableModeInputProps = {
  mode: InventoryTableEditableMode | UnknownEnum;
  setMode: (mode: InventoryTableEditableMode) => void;
  disabled?: boolean;
};

export const InventoryTableModeInput: React.FC<InventoryTableModeInputProps> = props => {
  const options = useMemo(() => {
    return [
      { label: 'Set', value: InventoryTableEditableMode.set },
      { label: 'Add', value: InventoryTableEditableMode.unary },
      { label: 'Transfer', value: InventoryTableEditableMode.transfer },
    ];
  }, []);

  return (
    <>
      {props.mode === UnknownEnum.unknown && <Alert severity="error">Edit mode not selected</Alert>}
      <Box display="flex" gap={1}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={(
              <Checkbox
                onChange={e => props.setMode(e.target.value as InventoryTableEditableMode)}
                value={option.value}
                checked={props.mode === option.value}
              />
            )}
          />
        ))}
      </Box>
    </>
  );
};