import { Box } from '@mui/material';
import { RadioGroup, RadioItemValueType, UpdateResourceModal } from 'components';
import { Formik } from 'formik';
import { ColorCodeOption } from './ColorCodeOption.component';
import { CALENDAR_FULL_SCREEN_MODAL_ZINDEX } from 'constants/full-screen';
import { ColorCodeConfig } from './colorCode';

export type ColorCodeModalProps<Option extends string, Arg extends unknown> = {
  colorCodeOption: Option | null;
  disabledColorCodeOptions?: Option[];
  colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>;
  onClose: () => void;
  onColorCodeOptionChange: (colorCodeOption: Option | null) => void;
};

type ColorCodeFormType<Option extends string> = {
  colorCodeOption: Option | 'none';
};

const getColorCodeOptions = <Option extends string, Arg extends unknown>(disabledColorCodeOptions: Option[] | undefined, colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>): RadioItemValueType[] =>  [
  {
    label: 'No Color Code',
    value: 'none',
  },
  ...Object.keys(colorCodeConfigHash).map((key: Option) => {
    const colorCodeConfig = colorCodeConfigHash[key];

    return ({
      label: <ColorCodeOption label={colorCodeConfig.label} colorKeys={colorCodeConfig.colorKeyConfigs} />,
      value: key,
    });
  }),
].map((colorCodeOption) => {
  const disabled = disabledColorCodeOptions?.includes(colorCodeOption.value as Option);

  return ({
    ...colorCodeOption,
    disableTypography: true,
    disabled: disabled,
    sx: {
      mr: 0,
      color: disabled ? 'text.secondary' : undefined
    },
  });
});

export const ColorCodeModal = <Option extends string, Arg extends unknown>({ onClose, colorCodeOption, colorCodeConfigHash, onColorCodeOptionChange, disabledColorCodeOptions }: ColorCodeModalProps<Option, Arg>) => {
  const onSubmit = ({ colorCodeOption }: ColorCodeFormType<Option>) => {
    onColorCodeOptionChange(colorCodeOption === 'none' ? null : colorCodeOption);
    onClose();
  };

  const initialValues: ColorCodeFormType<Option> = {
    colorCodeOption: colorCodeOption ?? 'none',
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formik) => (
        <UpdateResourceModal
          open
          onClose={onClose}
          onSave={formik.handleSubmit}
          title="Color Code"
          sx={{ zIndex: CALENDAR_FULL_SCREEN_MODAL_ZINDEX }}
        >
          <Box>
            <RadioGroup
              fullWidth
              name="colorCodeOption"
              values={getColorCodeOptions(disabledColorCodeOptions, colorCodeConfigHash)}
              sx={{ gap: 2 }}
            />
          </Box>
        </UpdateResourceModal>
      )}
    </Formik>
  );
};
