import { AddStaffActionButtonGroupProps, AddStaffActionButtonGroup } from './AddStaffActionButtonGroup.component';
import { EditStaffActionButtonGroupProps, EditStaffActionButtonGroup } from './EditStaffActionButtonGroup.component';

type AddStaffActionCellProps = AddStaffActionButtonGroupProps & { variant: 'add' };
type EditStaffActionCellProps = EditStaffActionButtonGroupProps & { variant: 'edit' };
export type StaffActionCellProps = AddStaffActionCellProps | EditStaffActionCellProps;

export const StaffActionCell: React.FC<StaffActionCellProps> = props => {
  if(props.variant === 'add') {
    return <AddStaffActionButtonGroup {...props} fitContainer />;
  }

  return <EditStaffActionButtonGroup {...props} fitContainer />;
};