import { authAxiosRequest } from 'api/axios';
import { User } from 'api/resources';
import { EventFeedback } from 'api/resources/eventFeedback';
import { DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { DeepPartial } from 'types';

export type GetEventFeedbackResponse = {
  data: ResourceWithPopulated<EventFeedback, {
    user: PickFromResource<User, 'name'>;
  }>[];
};

export const getEventFeedback = (eventId: string) => {
  return authAxiosRequest<GetEventFeedbackResponse>(`/api/events/${eventId}/event-feedback`);
};

export type CreateEventFeedbackInput = DeepOptionalToNull<{
  overall: number;
  busyness: number;
  sales: number;
  food: number;
  artists: number;
  parking: number;

  unloadPullUp: boolean;
  packUpPullUp: boolean;
  doAgain: boolean;
  twoSpaces: boolean;
  needMoreStaff: boolean;
  weatherAffectedSales: boolean;

  nextYearRecommendations?: string;
  note?: string;
}>;

export const createEventFeedback = (eventId: string, data: CreateEventFeedbackInput) => {
  return authAxiosRequest(`/api/events/${eventId}/event-feedback`, { method: 'POST', data });
};

export type UpdateEventFeedbackInput = DeepPartial<CreateEventFeedbackInput>;

export const updateEventFeedback = (eventId: string, eventFeedbackItemId: string, data: UpdateEventFeedbackInput) => {
  return authAxiosRequest(`/api/event-feedback/${eventFeedbackItemId}`, { method: 'PUT', data });
};

export const deleteEventFeedback = (eventId: string, eventFeedbackItemId: string) => {
  return authAxiosRequest(`/api/event-feedback/${eventFeedbackItemId}`, { method: 'DELETE' });
};