import React from 'react';
import { EventDatesStatusEnum } from 'types';
import { StandardChip } from '../StandardChip.component';
import { StatusChipProps } from '../types';
import { eventEnumHelpers } from 'helpers';

export type DatesStatusChipProps = StatusChipProps<EventDatesStatusEnum>;

export const DatesStatusChip: React.FC<DatesStatusChipProps> = props => {
  return <StandardChip {...eventEnumHelpers.datesStatus} {...props} />;
};