import { ErrorPageContent } from 'components';
import React from 'react';

export const AccessDenied403: React.FC = () => {
  return (
    <ErrorPageContent
      errorCode={403}
      message="We're sorry, but you do not have permission to access this page. If you believe this is an error, please contact management."
    />
  );
};
