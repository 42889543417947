import { AnalyticsChartConfig } from 'components';
import { useAnalyticsPageContext } from '../analytics.context';
import { DataType } from './types';
import { DateService } from 'services';
import { useMemo } from 'react';

export type UseAnalyticsChartDataWithCompareProps<T extends 'sales' | 'eventsSales' | 'updateLogs'> = {
  type: T;
  baseConfig: Omit<AnalyticsChartConfig<DataType<T>>['baseConfig'], 'data' | 'dateRange'>;
  datasetsConfigs?: AnalyticsChartConfig<DataType<T>>['datasetsConfigs'];
};

const isSpecificType = <T extends 'sales' | 'eventsSales' | 'updateLogs'>(
  obj: UseAnalyticsChartDataWithCompareProps<'sales' | 'eventsSales' | 'updateLogs'>,
  type: T
): obj is UseAnalyticsChartDataWithCompareProps<T> => {
  return obj.type === type;
};

export const useAnalyticsChartDataWithCompare = <T extends 'sales' | 'eventsSales' | 'updateLogs'>(props: UseAnalyticsChartDataWithCompareProps<T>) => {
  const { sales, eventsSales, compareEventsSales, compareSales, updateLogs, compareUpdateLogs, dateRange, compareDateRange } = useAnalyticsPageContext();

  const config = useMemo(() => {
    if(isSpecificType(props, 'updateLogs')) {
      const config: AnalyticsChartConfig<DataType<'updateLogs'>> = {
        baseConfig: { ...props.baseConfig, data: updateLogs, dateRange },
        datasetsConfigs: props.datasetsConfigs
      };

      if (compareDateRange) {
        config.compareConfig = { data: compareUpdateLogs,  dateRange: compareDateRange };
      }

      return config;
    }

    if(isSpecificType(props, 'sales')) {
      const config: AnalyticsChartConfig<DataType<'sales'>> = {
        baseConfig: { ...props.baseConfig, data: sales, dateRange },
        datasetsConfigs: props.datasetsConfigs
      };

      if (compareDateRange) {
        config.compareConfig = { data: compareSales,  dateRange: compareDateRange };
      }

      return config;
    }

    if(isSpecificType(props, 'eventsSales')) {
      const config: AnalyticsChartConfig<DataType<'eventsSales'>> = {
        baseConfig: { ...props.baseConfig, data: eventsSales, dateRange },
        datasetsConfigs: props.datasetsConfigs
      };

      if (compareDateRange) {
        config.compareConfig = { data: compareEventsSales,  dateRange: compareDateRange };
      }

      return config;
    }

    // empty config
    return {
      config: {
        baseConfig: { data: [], dateRange: { start: DateService.dayjs(), end: DateService.dayjs() }, getValue: () => 1, getId: () => '', getLabel: () => '' },
        datasetsConfigs: [],
      }
    };
  }, [ compareDateRange, compareEventsSales, compareSales, compareUpdateLogs, dateRange, eventsSales, props, sales, updateLogs ]);

  return { config: config as AnalyticsChartConfig<DataType<T>> };
};