import { Box, Button } from '@mui/material';

export type NavigateButtonsProps = {
  onNext: () => void;
  nextText?: string;
  nextDisabled?: boolean;
  onBack?: () => void;
};

export const NavigateButtons: React.FC<NavigateButtonsProps> = ({ nextText = 'Next', nextDisabled = false, ...props }) => {

  return (
    <Box
      position="fixed"
      bottom={theme => ({ xs: theme.spacing(2), sm: theme.spacing(4) })}
      left={0}
      width="100%"
      px={{ xs: 2, sm: 4 }}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gap={2}
      zIndex={1000}
    >
      {props.onBack && (
        <Box display="flex" bgcolor={theme => theme.palette.background.paper}>
          <Button size="large" variant="outlined" color="inherit" onClick={props.onBack} disableTouchRipple>Back</Button>
        </Box>
      )}
      <Button size="large" variant="contained" onClick={props.onNext} disabled={nextDisabled} disableTouchRipple>{nextText}</Button>
    </Box>
  );
};