import { Box, Button, Collapse, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DateService } from 'services';
import { eventDateEnumHelpers } from 'helpers';
import { SectionCard } from 'components';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useEventContext } from '../event.context';
import { EventDateImagesCarouselConfig, UploadEventDateImageModalConfig, EventDatesPhotosProps, EventDatesPhotos, EventDateImageUploadModal, EventDateImagesCarousel } from '../components';
import { EventPageSection } from '../types';

export const PhotosSection = () => {
  const { event } = useEventContext();
  const [ carouselConfig, setCarouselConfig ] = useState<EventDateImagesCarouselConfig | null>(null);
  const [ uploadImageModalConfig, setUploadImageModalConfig ] = useState<UploadEventDateImageModalConfig | null>(null);
  const [ popperEventDateId, setPopperEventDateId ] = useState<string | null>(null);
  const [ popperAnchorEl, setPopperAnchorEl ] = useState<HTMLButtonElement | null>(null);
  const [ showAll, setShowAll ] = useState(false);

  const carouselConfigEventDate = useMemo(() => carouselConfig && event.dates[carouselConfig.eventDateIndex], [ carouselConfig, event.dates ]);
  const uploadImageModalConfigEventDate = useMemo(() => uploadImageModalConfig && event.dates[uploadImageModalConfig.eventDateIndex], [ event.dates, uploadImageModalConfig ]);

  const pastEventDates = event.dates.filter(eventDate => !DateService.dayjs().isBefore(DateService.dayjsTz(eventDate.dateAsUtc))).reverse();

  const handleWarningAmberClick = (eventDateId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopperAnchorEl(event.currentTarget);
    setPopperEventDateId(eventDateId);
  };
  const handlePopperClose = () => {
    setPopperAnchorEl(null);
    setPopperEventDateId(null);
  };

  const eventDatePhotosConfig = useMemo((): Omit<EventDatesPhotosProps, 'eventDates'> => ({
    popperEventDateId,
    popperAnchorEl,
    handlePopperClose,
    handleWarningAmberClick,
    setUploadImageModalConfig,
    setCarouselConfig,
  }), [ popperAnchorEl, popperEventDateId ]);

  if (!pastEventDates.length) {
    return null;
  }

  const getEventDateIndex = (eventDateId: string) => event.dates.findIndex(eventDate => eventDate._id === eventDateId);

  return (
    <SectionCard id={EventPageSection.PHOTOS} title="Photos">
      <Stack m={2}>
        {(carouselConfig && carouselConfigEventDate) && (
          <EventDateImagesCarousel
            eventDateId={carouselConfigEventDate._id}
            images={carouselConfigEventDate.images}
            initialIndex={carouselConfig.initialIndex}
            onClose={() => setCarouselConfig(null)}
            renderInfo={(image, index, images) => (
              <>
                <strong>Day {getEventDateIndex(carouselConfigEventDate._id) + 1} - {eventDateEnumHelpers.imageType.getLabel(image.type)}</strong>
              &nbsp;
              ({DateService.getFormattedDate(image.createdAt, 'MMM Do, YYYY h:mma')}),
              &nbsp;{index + 1} of {images.length}
              </>
            )}
          />
        )}
        {(uploadImageModalConfig && uploadImageModalConfigEventDate) && (
          <EventDateImageUploadModal
            eventDate={uploadImageModalConfigEventDate}
            onClose={() => setUploadImageModalConfig(null)}
            title={`Upload photo for Day ${uploadImageModalConfig.eventDateIndex + 1} - ${DateService.getFormattedDate(uploadImageModalConfigEventDate.dateAsUtc)}`}
          />
        )}

        <EventDatesPhotos eventDates={pastEventDates.slice(0, 2)} {...eventDatePhotosConfig} />

        {pastEventDates.length > 2 && (
          <>
            <Collapse orientation="vertical" in={showAll}>
              <EventDatesPhotos eventDates={pastEventDates.slice(2)} {...eventDatePhotosConfig} />
            </Collapse>
            <Box display="flex" justifyContent="center">
              <Button onClick={() => setShowAll(p => !p)}>
                <Box display="flex" alignItems="center" gap={1}>
                  {showAll ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                  {showAll ? 'Show less' : `Show more (${pastEventDates.length - 2})`}
                </Box>
              </Button>
            </Box>
          </>
        )}

      </Stack>
    </SectionCard>
  );
};
