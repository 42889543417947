import { Table } from 'components';
import { useTableActionColumn, useTableLocalStorage } from 'hooks';
import { salesTableColumns } from './columns';
import { SalesTableProps } from './types';
import { ROUTING_CONFIG } from 'constants/routing-config';

export const AllSalesTable: React.FC<SalesTableProps> = ({ sales, loading }) => {
  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.salesLog });
  const columns = withActionColumn(salesTableColumns);
  const { initialTableState, tableStateChange } = useTableLocalStorage({
    id: 'sales-table',
    initialVisible: [ 'order', 'product', 'frame', 'salePrice', 'payment', 'event', 'date' ],
    columns,
  });

  return (
    <Table
      {...tableStateChange}
      initialState={{
        columns: {
          columnVisibilityModel: initialTableState.columnVisibility,
        },
        filter: {
          filterModel: initialTableState.filter,
        },
        sorting: {
          sortModel: initialTableState.sort,
        },
      }}
      getRowId={(x) => x._id}
      rows={sales}
      columns={columns}
      loading={loading}
      disableRowSelectionOnClick
    />
  );
};
