import { Box } from '@mui/material';
import { TextInput, RadioGroupYesNoBoolean } from 'components';
import React from 'react';
import { formSx } from 'styles';

export const EventDateStaffForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <Box display="flex" gap={3}>
        <TextInput
          label="Staff Needed"
          name="staffNeeded"
          type="number"
          fullWidth
          sx={{ flex: 1 }}
        />
        <Box flex={1}>
          <RadioGroupYesNoBoolean
            label="Is Team Lead needed?"
            name="teamLeadNeeded"
            row
          />
        </Box>
      </Box>
    </Box>
  );
};
