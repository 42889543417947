import { GetVehicleResponse } from 'api/actions';
import { VehicleFuelTypeEnum } from 'api/resources';
import { FileFormType } from 'components';
import { StrictForm, UnknownEnum, YesNoUnknownEnum } from 'types';

export type VehiclePageSectionProps = {
  vehicle: GetVehicleResponse['data'];
};

export const enum VehiclePageSection {
  OVERVIEW = 'overview',
  EVENTS = 'events',
  MAINTENANCE = 'maintenance',
  FILES = 'files',
}

export const enum VehiclePageSectionRowId {
  NAME = 'name',
  YEAR = 'year',
  MAKE = 'make',
  MODEL = 'model',
  DRIVABLE = 'drivable',
  LENGTH = 'length',
  CARGO_LENGTH = 'cargo_length',
  GROSS_WEIGHT = 'gross_weight',
  TOW_CAPABILITIES = 'tow_capabilities',
  TOW_CAPACITY = 'tow_capacity',
  FUEL_TYPE = 'fuel_type',
  MPG = 'mpg',
  MILEAGE = 'mileage',

  INSURANCE_FILES = 'insurance_files',
  REGISTRATION_FILES = 'registration_files',
  OTHER_FILES = 'other_files',

  MAINTENANCE_LOGS = 'maintenance_logs',
}

export type VehicleSectionRowForm = {
  [VehiclePageSectionRowId.NAME]: StrictForm<{
    name: string;
  }>;
  [VehiclePageSectionRowId.YEAR]: StrictForm<{
    year: number;
  }>;
  [VehiclePageSectionRowId.MAKE]: StrictForm<{
    make: string;
  }>;
  [VehiclePageSectionRowId.MODEL]: StrictForm<{
    model: string;
  }>;
  [VehiclePageSectionRowId.DRIVABLE]: StrictForm<{
    drivable: YesNoUnknownEnum;
  }>;
  [VehiclePageSectionRowId.LENGTH]: StrictForm<{
    length: number;
  }>;
  [VehiclePageSectionRowId.CARGO_LENGTH]: StrictForm<{
    cargoLength: number;
  }>;
  [VehiclePageSectionRowId.GROSS_WEIGHT]: StrictForm<{
    grossWeight: number;
  }>;
  [VehiclePageSectionRowId.TOW_CAPABILITIES]: StrictForm<{
    towCapabilities: string;
  }>;
  [VehiclePageSectionRowId.TOW_CAPACITY]: StrictForm<{
    towCapacity: number;
  }>;
  [VehiclePageSectionRowId.FUEL_TYPE]: StrictForm<{
    fuelType: VehicleFuelTypeEnum | UnknownEnum;
  }>;
  [VehiclePageSectionRowId.MPG]: StrictForm<{
    mpg: number;
  }>;
  [VehiclePageSectionRowId.MILEAGE]: StrictForm<{
    mileage: number;
  }>;
  [VehiclePageSectionRowId.INSURANCE_FILES]: FileFormType;
  [VehiclePageSectionRowId.REGISTRATION_FILES]: FileFormType;
  [VehiclePageSectionRowId.OTHER_FILES]: FileFormType;
  [VehiclePageSectionRowId.MAINTENANCE_LOGS]: StrictForm<{
    title: string;
    description: string;
    date: string | null;
    assignees: string[];
  }>;
};

export type CreateVehicleFormType = StrictForm<{
  name: string;
}>;