import { GetInventoryBatchResponse } from 'api/actions';
import { InventoryBatchTypeEnum } from 'api/resources';
import { InventoryEdits, UseInventoryTableArgs } from 'components';

export const getInitialTransferConfig = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string): UseInventoryTableArgs['initialTransferConfig'] => {
  if (batch.team.isWarehouse) {
    return undefined;
  }

  if(batch.type === InventoryBatchTypeEnum.picking) {
    return {
      out: warehouseTeamId,
      in: batch.team._id,
    };
  }

  return {
    out: batch.team._id,
    in: warehouseTeamId,
    autoFillBase: 'out',
  };
};

export const getTeamsStockMetaConfig = (initialTransferConfig: UseInventoryTableArgs['initialTransferConfig'], inventoryBatchType: InventoryBatchTypeEnum): UseInventoryTableArgs['teamsStockMetaConfig'] => {
  if(!initialTransferConfig) {
    return undefined;
  }

  if(inventoryBatchType === InventoryBatchTypeEnum.picking) {
    return {
      [initialTransferConfig.in]: { overstocked: 'secondary' },
      [initialTransferConfig.out]: { baseline: 'secondary', needed: 'secondary', overstocked: 'secondary' },
    };
  }

  if(inventoryBatchType === InventoryBatchTypeEnum.overstockPicking) {
    return {
      [initialTransferConfig.in]: { baseline: 'secondary', needed: 'secondary', overstocked: 'secondary' },
      [initialTransferConfig.out]: { needed: 'secondary' },
    };
  }

  return undefined;
};

export const prepareInitialInventoryEdits = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string) => {
  return batch.updates.reduce((acc: InventoryEdits, update) => {
    return {
      ...acc,
      [update.product]: {
        [batch.type === InventoryBatchTypeEnum.overstockPicking ? warehouseTeamId : batch.team._id]: { value: update.quantity }
      },
    };
  }, {});
};