import { Theme, useMediaQuery } from '@mui/material';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage.hook';
import React, {
  useState, createContext, useMemo, useContext, useCallback,
} from 'react';

export type MainLayoutContextType = {
  isDrawerOpen: boolean;
  onToggleDrawer: () => void;
};

export const MainLayoutContext = createContext<MainLayoutContextType>({} as MainLayoutContextType);
export const useMainLayoutContext = () => useContext(MainLayoutContext);

export const MainLayoutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isSmallOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const drawerLocalStorage = useLocalStorage<boolean>('main-layout-drawer');
  const [ isDrawerOpen, setIsDrawerOpen ] = useState<boolean>(drawerLocalStorage.getResource(isSmallOrTablet ? false : true));

  const onToggleDrawer = useCallback(() => {
    const newValue = !isDrawerOpen;

    setIsDrawerOpen(newValue);
    drawerLocalStorage.setResource(newValue);
  }, [ drawerLocalStorage, isDrawerOpen ]);

  const value = useMemo((): MainLayoutContextType => {
    return {
      isDrawerOpen,
      onToggleDrawer
    };
  }, [ isDrawerOpen, onToggleDrawer ]);

  return (
    <MainLayoutContext.Provider value={value}>
      {children}
    </MainLayoutContext.Provider>
  );
};
