import { Box, Button, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { authAxiosInstance } from 'api/axios';
import { useField, useFormikContext } from 'formik';
import { useCallback, useRef, useState } from 'react';
import { TextInput, TextInputRaw } from '../TextInput';

type Substring = {
  length: number;
  offset: number;
};

export type AddressSuggestion = {
  description: string;
  matched_substrings: Substring[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: Substring[];
    secondary_text: string;
    secondary_text_matched_substrings: Substring[];
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
};

export type AddressSuggestionsResponse = {
  predictions: AddressSuggestion[];
};

export type AddressVerificationInputProps = {
  name: string;
  label: string;
  placeIdName?: string;
  disabled?: boolean;
  onSelect?: () => void;
};

export const AddressVerificationInput: React.FC<AddressVerificationInputProps> = ({ name, label, placeIdName, onSelect, disabled=false }) => {
  const [ field, meta, helpers ] = useField<string>(name);
  const formik = useFormikContext();
  const ref = useRef<HTMLInputElement>(null);
  const [ value, setValue ] = useState<string>('');
  const [ loading, setLoading ] = useState(false);
  const [ suggestions, setSuggestions ] = useState<AddressSuggestion[]>([]);
  const [ suggestionsError, setSuggestionsError ] = useState<string | null>(null);

  const selectedAddress = field.value;

  const onSelectAddress = (suggestion: google.maps.places.AutocompletePrediction) => {
    helpers.setValue(suggestion.description, true);
    if (placeIdName) {
      formik.setFieldValue(placeIdName, suggestion.place_id, true);
    }
  };

  const search = useCallback(async (text: string) => {
    if (suggestionsError) {
      setSuggestionsError(null);
    }

    try {
      const urlEncodedText = new URLSearchParams({ text }).toString();
      const { data } = await authAxiosInstance.get<AddressSuggestionsResponse>(`/api/google-api/addressSuggestions?${urlEncodedText}`);

      if (!data?.predictions?.length) throw new Error();

      setSuggestions(data.predictions);
    } catch (error) {
      setSuggestionsError('No results found. Please try again with a different address.');
      setSuggestions([]);
    }

    setLoading(false);
  }, [ suggestionsError ]);

  const onInputChange = (value: string) => {
    if (!value.length || suggestions.length) {
      setSuggestions([]);
    }
    if (suggestionsError) {
      setSuggestionsError(null);
    }

    setValue(value);
  };

  const onChangeAddress = () => {
    helpers.setValue('');
    onInputChange('');
    setTimeout(() => {
      ref.current?.focus?.();
    }, 100);
  };

  if (selectedAddress || disabled) {
    return (
      <Box display="flex" flexDirection="column" gap={1} alignItems="flex-start">
        <TextInput
          label={label}
          name={name}
          autoComplete="off"
          disabled
          fullWidth
        />
        <Button component="button" size="small" onClick={onChangeAddress} disabled={disabled}>Change Address</Button>
      </Box>
    );
  }

  const onSelectAddressHandler = (suggestion: AddressSuggestion) => {
    onSelectAddress(suggestion);
    if (onSelect) onSelect();
  };

  return (
    <div>
      <Box display="flex" alignItems="flex-start" gap={2} mb={2}>
        <TextInputRaw
          inputRef={ref}
          name="address"
          label="Address"
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
          setValue={(val) => onInputChange(val)}
          autoComplete="off"
          endAdornment={loading ? <CircularProgress /> : null}
          fullWidth
          error={!!suggestionsError || Boolean(meta.error && meta.touched)}
          helperText={suggestionsError ?? 'Type in an address and click search to see Google Maps search results'}
        />
        <Button
          variant="contained"
          onClick={() => search(value)}
          sx={{ mt: 1.25 }}
        >
          search
        </Button>
      </Box>
      {!!suggestions.length && (
        <Box>
          <List disablePadding>
            {suggestions.map((suggestion) => {
              return (
                <ListItem key={suggestion.place_id} divider>
                  <ListItemText disableTypography>
                    <Typography>{suggestion.structured_formatting.main_text}</Typography>
                    <Typography variant="body2">{suggestion.structured_formatting.secondary_text}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Button
                      size="small" onClick={() =>  onSelectAddressHandler(suggestion)}
                    >Select Address</Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
          <Typography variant="body2" mt={1}>Dont see the correct address? Try searching again with a different address.</Typography>
        </Box>
      )}
    </div>
  );
};