import { ProductActiveBaselineTypeEnum } from 'api/resources';
import { StrictForm, YesNoUnknownEnum } from 'types';

export const enum ProductPageSection {
  OVERVIEW = 'overview',
  SHOPIFY = 'shopify',
  PREPARATION = 'preparation',
  AVAILABILITY = 'availability',
  INVENTORY = 'inventory',
  GENRES = 'genres',
  SALES = 'sales',
}

export const enum ProductPageSectionRowId {
  NAME = 'name',
  PRICE = 'price',
  CATEGORY = 'category',
  NOTES = 'notes',
  RASTER_LAYER = 'raster_layer',
  CENTER_LABEL = 'center_label',
  PNG = 'png',
  DMC = 'dmc',
  DWG = 'dwg',
  DIVIDER = 'divider',
  WEBSITE_PNGS = 'website_pngs',
  EVENTS_AVAILABILITY = 'events_availability',
  WEBSITE_AVAILABILITY = 'website_availability',
  GENRES = 'genres',
  BASELINE = 'baseline',
}

export type ProductSectionRowForm = {
  [ProductPageSectionRowId.NAME]: StrictForm<{
    name: string;
    sku: string;
  }>;
  [ProductPageSectionRowId.PRICE]: StrictForm<{
    price: number;
  }>;
  [ProductPageSectionRowId.CATEGORY]: StrictForm<{
    category: string;
  }>;
  [ProductPageSectionRowId.NOTES]: StrictForm<{
    notes: string;
  }>;
  [ProductPageSectionRowId.RASTER_LAYER]: StrictForm<{
    rasterLayer: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.CENTER_LABEL]: StrictForm<{
    centerLabel: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.PNG]: StrictForm<{
    png: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.DMC]: StrictForm<{
    dmc: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.DWG]: StrictForm<{
    dwg: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.DIVIDER]: StrictForm<{
    divider: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.WEBSITE_PNGS]: StrictForm<{
    websitePngs: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.EVENTS_AVAILABILITY]: StrictForm<{
    events: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.WEBSITE_AVAILABILITY]: StrictForm<{
    website: YesNoUnknownEnum;
    uploaded: YesNoUnknownEnum;
  }>;
  [ProductPageSectionRowId.GENRES]: StrictForm<{
    genres: string[];
  }>;
  [ProductPageSectionRowId.BASELINE]: StrictForm<{
    manualBaseline: number;
    activeBaselineType: ProductActiveBaselineTypeEnum;
  }>;
};