import { VehicleFileTypeEnum } from 'api/resources';
import { VehiclePageSection, VehiclePageSectionProps, VehiclePageSectionRowId } from '../types';
import { FileUploadForm, SectionCard, fileValidationSchema } from 'components';
import { removeUnchanged, vehicleEnumHelpers } from 'helpers';
import { VehicleSectionCardRowEditableList } from '../components/VehicleSectionCardRowEditable.component';
import { Box, Link } from '@mui/material';
import { DescriptionOutlined } from '@mui/icons-material';
import { createVehicleFile, deleteVehicleFile, updateVehicleFile } from 'api/actions';
import { object, string } from 'yup';
import { FirebaseService } from 'services';

export const FilesSection: React.FC<VehiclePageSectionProps> = ({ vehicle }) => {
  const fileRows = [
    {
      type: VehicleFileTypeEnum.insurance,
      rowId: VehiclePageSectionRowId.INSURANCE_FILES as const,
    },
    {
      type: VehicleFileTypeEnum.registration,
      rowId: VehiclePageSectionRowId.REGISTRATION_FILES as const,
    },
    {
      type: VehicleFileTypeEnum.other,
      rowId: VehiclePageSectionRowId.OTHER_FILES as const,
    }
  ];

  return (
    <SectionCard title="Files" id={VehiclePageSection.FILES}>
      {fileRows.map(({ type, rowId }) => {
        const label = vehicleEnumHelpers.file.getLabel(type);

        return (
          <VehicleSectionCardRowEditableList
            key={rowId}
            rowId={rowId}
            vehicle={vehicle}
            title={`${label} Files`}
            listItems={vehicle.files?.filter(file => file.type === type) ?? []}
            renderItem={(file) => (
              <Box display="flex" gap={2}>
                <DescriptionOutlined fontSize="small" />
                <Link
                  href={file.url}
                  target="_blank"
                  rel="noopener"
                >
                  {file.name}
                </Link>
              </Box>
            )}
            getFormikProps={(item) => ({
              onSubmit: (form, { initialValues }) => {
                const updates = removeUnchanged(form, initialValues);

                return updateVehicleFile(vehicle._id, item._id, {
                  name: updates.name,
                });
              },
              initialValues: { name: item.name, file: null },
              validationSchema: object({ name: string().required('File name required') }),
            })}
            createFormikProps={{
              initialValues: { name: '', file: null },
              onSubmit: async (form) => {
                if (!form.file) {
                  throw new Error('File is required');
                }

                const name = form.name.trim();

                const path = `${vehicle._id}/${type ? type + '/' : ''}${FirebaseService.convertFileName(name)}`;
                const url = await FirebaseService.uploadFile(form.file, path);

                return createVehicleFile(vehicle._id, {
                  name: name,
                  type: type,
                  url: url,
                });
              },
              validationSchema: fileValidationSchema,
            }}
            form={<FileUploadForm hideFileUpload />}
            createForm={<FileUploadForm disableTypeField />}
            createButtonLabel="File"
            deleteMutationFn={async (file) => {
              const fileName = FirebaseService.getFileNameFromUrl(file.url);
              const path = `${vehicle._id}/${file.type ? file.type + '/' : ''}${fileName}`;

              await FirebaseService.deleteFile(path);

              await deleteVehicleFile(vehicle._id, file._id);
            }}
          />
        );
      })}
    </SectionCard>
  );
};