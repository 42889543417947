import { ApiResource } from './resources.types';

export type Genre = ApiResource<{
  group: GenreGroupEnum;
  title: string;
}>;

export const enum GenreGroupEnum {
  rock = 'rock',
  hiphop = 'hiphop',
  pop = 'pop',
  metal = 'metal',
  rb = 'rb',
  punk = 'punk',
  folk = 'folk',
  electronic = 'electronic',
  jazz = 'jazz',
  latin = 'latin',
  other = 'other'
}