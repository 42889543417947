import { ReportProblemOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Box, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { AttentionWarningContentStack, Modal, eventPageSectionEnumHelpers, eventPageSectionRowEnumHelpers } from 'components';
import { getWarningsForEvent } from 'helpers';
import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React, { useState } from 'react';

export const LogisticsMissingData = () => {
  const [ modalViewEvent, setModalViewEvent ] = useState<GetEventsResponse['data'][number] | null>(null);

  return (
    <>
      {modalViewEvent && (
        <Modal topLeftIcon="close" open onClose={() => setModalViewEvent(null)}>
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={2}>
              <ReportProblemOutlined color="warning" />
              Warnings for {modalViewEvent.name}
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <AttentionWarningContentStack warningItems={getWarningsForEvent(modalViewEvent).warningItems} rowEnumHelpers={eventPageSectionRowEnumHelpers} sectionEnumHelpers={eventPageSectionEnumHelpers} />
          </DialogContent>
        </Modal>
      )}
      <EventsManagementTable
        nodeId={EventsManagementTreeViewNodeIdEnum.logisticsMissingData}
        extraColumns={[
          {
            field: 'warnings',
            headerName: 'Warnings',
            width: 90,
            renderCell: ({ row }) => {
              const warningsCount = getWarningsForEvent(row).warningItems.reduce((r, item) => r + item.reasonItems.length, 0);

              return (
                <Box display="flex" alignItems="center">
                  <Tooltip title="See all warnings">
                    <IconButton color="warning" onClick={() => setModalViewEvent(row)}>
                      <WarningAmberOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  ({warningsCount})
                </Box>
              );
            },
          },
        ]}
        localStorageArgs={{
          initialVisible: [ 'name', 'eventTimeframe', 'warnings' ],
          initialSortModel: [ { field: 'eventTimeframe', sort: 'asc' } ]
        }}
        groupByWeek
      />
    </>
  );
};
