import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import React, { useState } from 'react';

export type CheckboxActionContentProps = {
  defaultValue?: boolean;
  label: string;
  onToggle: (newValue: boolean) => void;
};

export const CheckboxActionContent: React.FC<CheckboxActionContentProps> = props => {
  const [ checked, setChecked ] = useState(props.defaultValue ?? false);

  const handleToggle = () => {
    setChecked(!checked);
    props.onToggle(!checked);
  };

  return (
    <FormControl>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'body2', sx: { userSelect: 'none' } } }}
        label={props.label}
        control={<Checkbox onChange={handleToggle} checked={checked} size="small" />}
      />
    </FormControl>
  );
};
