import {
  Button, Grid, Typography,
} from '@mui/material';
import { FormFooter, CardContentContainer, useAlertSnackbar, PageContentContainer, PageContentResourceHeader,
} from 'components';
import { Formik } from 'formik';
import { ResourceForm, YesNoUnknownEnum } from 'types';
import { productNewValidationSchema } from './validation';
import { ProductBasicInfoForm } from './forms/ProductBasicInfo.form';
import { ProductAvailabilityForm } from './forms/ProductAvailability.form';
import { ProductPreperationForm } from './forms/ProductPreperation.form';
import { useMutation } from '@tanstack/react-query';
import { createProduct } from 'api/actions';
import { yesNoUnknownToValueHash } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';

const initialValues: ResourceForm['product'] = {
  name: '',
  sku: '',
  price: 40,
  category: '12x12',
  notes: '',
  availability: {
    events: YesNoUnknownEnum.unknown,
    website: YesNoUnknownEnum.unknown,
    uploaded: YesNoUnknownEnum.unknown,
  },
  preperation: {
    rasterLayer: YesNoUnknownEnum.unknown,
    centerLabel: YesNoUnknownEnum.unknown,
    png: YesNoUnknownEnum.unknown,
    dmc: YesNoUnknownEnum.unknown,
    dwg: YesNoUnknownEnum.unknown,
    divider: YesNoUnknownEnum.unknown,
    websitePngs: YesNoUnknownEnum.unknown,
  },
  genres: [],
};

export const ProductNew = () => {
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const createMutation = useMutation({
    mutationFn: async (form: ResourceForm['product']) => {
      return createProduct({
        name: form.name.trim(),
        sku: form.sku.trim(),
        price: form.price,
        category: form.category.trim(),
        notes: form.notes.trim() || undefined,
        needsAttention: false,
        availability: {
          events: yesNoUnknownToValueHash[form.availability.events],
          website: yesNoUnknownToValueHash[form.availability.website],
          uploaded: yesNoUnknownToValueHash[form.availability.uploaded],
        },
        preperation: {
          rasterLayer: yesNoUnknownToValueHash[form.preperation.rasterLayer],
          centerLabel: yesNoUnknownToValueHash[form.preperation.centerLabel],
          png: yesNoUnknownToValueHash[form.preperation.png],
          dmc: yesNoUnknownToValueHash[form.preperation.dmc],
          dwg: yesNoUnknownToValueHash[form.preperation.dwg],
          divider: yesNoUnknownToValueHash[form.preperation.divider],
          websitePngs: yesNoUnknownToValueHash[form.preperation.websitePngs],
        },
        genres: [],
      });
    },
    onSuccess: async (response) => {
      navigate(`/${ROUTING_CONFIG.productList}/${response.data._id}`);
      snackbar.success('Product created');
    }
  });

  const handleCancel = () => {
    navigate(`/${ROUTING_CONFIG.productList}`);
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.productList, text: 'Product List' } ],
        current: 'New Product',
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="New Product Page"
          title="Create a new product"
        />
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => createMutation.mutateAsync(values)}
        validationSchema={productNewValidationSchema}
        validateOnMount
      >
        {(formik) => {
          return (
            <>
              <CardContentContainer>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Basic Information</Typography>
                    <ProductBasicInfoForm />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Availability</Typography>
                    <ProductAvailabilityForm />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h6" mb={2}>Preperation</Typography>
                    <ProductPreperationForm />
                  </Grid>
                </Grid>
              </CardContentContainer>
              <FormFooter>
                <Button
                  onClick={handleCancel}
                  size="large"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={formik.handleSubmit as () => void}
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Create Product
                </Button>
              </FormFooter>
            </>
          );
        }}
      </Formik>
    </PageContentContainer>
  );
};
