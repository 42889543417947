import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const ApplicationsOpen = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.applicationsOpen}
      localStorageArgs={{
        initialVisible: [ 'name', 'daysUntilEvent', 'juryFee', 'juryFeeAmount' , 'cost', 'applicationOpenDate', 'applicationHasBeenOpen', 'applicationDeadlineDate', 'untilApplicationDeadline', 'applicationStatus', 'participationStatus' ],
        initialSortModel: [ { field: 'applicationOpenDate', sort: 'asc' }, { field: 'applicationDeadlineDate', sort: 'asc' } ]
      }}
      editable={{ participationStatus: true }}
    />
  );
};
