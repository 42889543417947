import { EventDateStaffStatusEnum, UserAvailabilityEnum, UserEmployeeRoleEnum } from 'api/resources';
import { EnumHelpers, EnumHelpersWithIcon } from './types';
import { EventAvailableOutlined, EventBusyOutlined, HelpCenterOutlined, CalendarTodayOutlined } from '@mui/icons-material';

const staffStatus: EnumHelpers<EventDateStaffStatusEnum> = {
  enumValues: [
    EventDateStaffStatusEnum.confirmed,
    EventDateStaffStatusEnum.requested,
    EventDateStaffStatusEnum.deniedRequest,
    EventDateStaffStatusEnum.selfRequested,
    EventDateStaffStatusEnum.selfRequestDenied,
    EventDateStaffStatusEnum.initial,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventDateStaffStatusEnum.confirmed:
        return 'Confirmed';
      case EventDateStaffStatusEnum.requested:
        return 'Requested';
      case EventDateStaffStatusEnum.deniedRequest:
        return 'Denied Request';
      case EventDateStaffStatusEnum.selfRequested:
        return 'Self Requested';
      case EventDateStaffStatusEnum.selfRequestDenied:
        return 'Self Request Denied';
      default:
        return 'Not Confirmed';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventDateStaffStatusEnum.confirmed:
        return 'success';
      case EventDateStaffStatusEnum.requested:
      case EventDateStaffStatusEnum.selfRequested:
        return 'warning';
      case EventDateStaffStatusEnum.deniedRequest:
      case EventDateStaffStatusEnum.selfRequestDenied:
        return 'error';
      case EventDateStaffStatusEnum.initial:
      default:
        return 'unknown';
    }
  },
};

const availability: EnumHelpersWithIcon<UserAvailabilityEnum> = {
  enumValues: [
    UserAvailabilityEnum.available,
    UserAvailabilityEnum.notAvailable,
    UserAvailabilityEnum.undetermined
  ],
  getLabel: (value) => {
    switch (value) {
      case UserAvailabilityEnum.available:
        return 'Available';
      case UserAvailabilityEnum.notAvailable:
        return 'Not Available';
      case UserAvailabilityEnum.undetermined:
        return 'Undetermined';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case UserAvailabilityEnum.available:
        return 'success';
      case UserAvailabilityEnum.notAvailable:
        return 'error';
      case UserAvailabilityEnum.undetermined:
        return 'warning';
      default:
        return 'unknown';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case UserAvailabilityEnum.available:
        return EventAvailableOutlined;
      case UserAvailabilityEnum.notAvailable:
        return EventBusyOutlined;
      case UserAvailabilityEnum.undetermined:
        return HelpCenterOutlined;
      default:
        return CalendarTodayOutlined;
    }
  }
};

const employeeRole: EnumHelpers<UserEmployeeRoleEnum> & { getDescription: (value: UserEmployeeRoleEnum) => React.ReactNode } = {
  enumValues: [
    UserEmployeeRoleEnum.juniorTeamMember,
    UserEmployeeRoleEnum.teamMember,
    UserEmployeeRoleEnum.teamLead,
    UserEmployeeRoleEnum.productionManager,
    UserEmployeeRoleEnum.productionMember,
    UserEmployeeRoleEnum.eventManager,
    UserEmployeeRoleEnum.wholesaleManager,
  ],
  getLabel: (value) => {
    switch(value) {
      case UserEmployeeRoleEnum.juniorTeamMember:
        return 'Junior Team Member';
      case UserEmployeeRoleEnum.teamMember:
        return 'Team Member';
      case UserEmployeeRoleEnum.teamLead:
        return 'Team Lead';
      case UserEmployeeRoleEnum.productionManager:
        return 'Production Manager';
      case UserEmployeeRoleEnum.productionMember:
        return 'Production Member';
      case UserEmployeeRoleEnum.wholesaleManager:
        return 'Wholesale Manager';
      default:
        return 'Event Manager';
    }
  },
  getDescription: (value) => {
    switch(value) {
      case UserEmployeeRoleEnum.juniorTeamMember:
        return (
          <>
            New in Sales. Flexible schedule. Engages with customers at events, assists in showcasing the vinyl collection, and supports sales.  A developing face of the company, contributing to memorable experiences while building skills and knowledge. Assists with event setup & breakdown.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View company calendar</li>
                <li>Request schedule - Send requests to be scheduled for events, with manager confirmation</li>
                <li>Withdraw event request</li>
                <li>Accept / Deny Requests</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.teamMember:
        return (
          <>
            Experienced in Sales. Reliable and open availability. Engages with customers at events, showcases the vinyl collection, and makes sales. A trusted and experienced face of the company, interacting directly with customers to create a memorable experience. Assists with team preparation, event setup & breakdown.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View company calendar</li>
                <li>Self Schedule - confirm yourself at events</li>
                <li>Request schedule - Send requests to be scheduled for events, with manager confirmation</li>
                <li>Withdraw event request</li>
                <li>Accept / Deny Requests</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.teamLead:
        return (
          <>
            Experienced & Knowledgeable. Responsible for team preparation, event logistics and team member assignment. Able setup and work shows solo. Capable of driving company vans, skilled in event setup & breakdown. Knowledgeable in sales and astro logistics app. Leads by example to ensure smooth operations at events. Responsible for product & displays safety and security.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View company calendar</li>
                <li>Self Schedule - confirm yourself at events</li>
                <li>Request schedule - Send requests to be scheduled for events, with manager confirmation</li>
                <li>Withdraw event request</li>
                <li>Accept / Deny Requests</li>
                <li>Requests other users</li>
                <li>Remove other users from event dates</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.productionMember:
        return (
          <>
          The backbone of the inventory and production team, responsible for producing and managing inventory, picking orders, and preparing batches. Meticulous attention to detail ensures that products meet top quality standards. Integral to the smooth operation of production processes.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View production calendar</li>
                <li>Create batches and edit batches they have initiated. Review batches prepared by other users.</li>
                <li>Update inventory levels under supervision</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.productionManager:
        return (
          <>
            A seasoned and trusted leader within the production team. Manages all aspects of production including overseeing production members, ensuring the quality and timely completion of batches, and maintaining inventory accuracy. Has the authority to edit, review, and close any user's batch, ensuring high standards are met consistently across all operations.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View and manage production calendar</li>
                <li>Edit, review, and close any batches</li>
                <li>Monitor inventory levels and make adjustments as necessary</li>
                <li>Assign tasks and manage schedules of production members</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.eventManager:
        return (
          <>
            Strategic event planner and communicator. Researches and plans for events, submits applications, and communicates with event organizers. Assists with team & user assignment and event logistics. The mastermind behind event preparation for a successful presence            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View company calendar and Events Management page</li>
                <li>Use Event Scheduling page</li>
                <li>Requests and Confirm other users</li>
                <li>Accept / Deny user requests</li>
                <li>Remove other users from event dates</li>
              </ul>
            </div>
          </>
        );
      case UserEmployeeRoleEnum.wholesaleManager:
      default:
        return (
          <>
            Manages all aspects of the wholesale store. Responsible for ordering inventory, managing wholesale accounts, and overseeing sales. Ensures timely fulfillment of wholesale orders while maintaining stock levels. Builds and nurtures relationships with wholesale customers, ensuring smooth transactions and ongoing business growth. Works closely with the production team to ensure inventory meets demand. A key figure in driving wholesale strategy and maintaining strong business relationships.
            <br />
            <div style={{ marginTop: '8px' }}>
              <b>Default Permissions:</b>
              <ul style={{ margin: 0 }}>
                <li>View wholesale inventory and sales data</li>
                <li>Create and manage wholesale orders</li>
                <li>Adjust pricing for wholesale accounts</li>
                <li>Approve discounts and promotions</li>
                <li>Collaborate with production team for inventory planning</li>
                <li>Update inventory levels for wholesale stock</li>
              </ul>
            </div>
          </>
        );
    }
  },
  getColor: () => 'unknown'
};

export const userEnumHelpers = {
  employeeRole,
  staffStatus,
  availability
};