import { FormControlLabel, Switch } from '@mui/material';

export type EditModeTogglerProps = {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  disabled?: boolean;
};

export const EditModeToggler: React.FC<EditModeTogglerProps> = ({ isEditMode, setIsEditMode, disabled }) => {

  const toggleEditModeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setIsEditMode(isChecked);
  };

  return (
    <FormControlLabel
      control={<Switch checked={isEditMode} onChange={toggleEditModeHandler} />}
      label="Edit Mode"
      sx={{ userSelect: 'none', transition: 'none', whiteSpace: 'nowrap' }}
      disabled={disabled}
    />
  );
};