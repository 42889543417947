import { FormControlLabel, Box, Typography } from '@mui/material';
import { Checkbox } from 'components';
import React from 'react';

export const EventDateConfirmedTimesForm = () => {
  return (
    <FormControlLabel
      control={<Checkbox name="confirmedTimes" />}
      label={
        <Box>
          <Typography variant="body1">Confirmed Times</Typography>
          <Typography variant="caption" color="text.secondary">
            Checking this box will confirm all event date times, including setup, market and breakdown procedure.
          </Typography>
        </Box>
      }
      style={{ alignItems: 'flex-start' }}
    />
  );
};
