import { InventoryTableEditableMode, TransferConfig } from '../useInventoryTable.hook';
import { useCallback, useMemo } from 'react';
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import { SwapHorizOutlined } from '@mui/icons-material';
import { GetTeamsResponse } from 'api/actions';
import { UnknownEnum } from 'types';

export type InventoryTableTeamColumnsInputProps = {
  mode: InventoryTableEditableMode | UnknownEnum;
  teams:  GetTeamsResponse['data'];
  setSelectedTeamIds: (teamIds: string[]) => void;
  selectedTeamIds: string[];
  setTransferConfig: (transferConfig: TransferConfig) => void;
  transferConfig: TransferConfig;
  disabled?: boolean;
};

export const InventoryTableTeamColumnsInput: React.FC<InventoryTableTeamColumnsInputProps> = props => {
  const onSelectTeam = useCallback((teamId: string) => {
    const prev = props.selectedTeamIds;

    const teamExists = prev.some(_id => _id === teamId);

    if (teamExists) {
      props.setSelectedTeamIds(prev.filter(_id => _id !== teamId));
      return;
    }

    props.setSelectedTeamIds(props.teams.filter(team => [ ...prev, teamId ].includes(team._id)).sort((a, b) => a.order - b.order).map(team => team._id));
  }, [ props ]);

  const content = useMemo(() => {
    const transferConfig = props.transferConfig;

    if (props.mode === InventoryTableEditableMode.transfer) {
      const onSwapTransfer = () => {
        props.setTransferConfig({
          in: transferConfig.out,
          out: transferConfig.in,
        });
      };

      return (
        <Box display="flex" gap={2} alignItems="center">
          <Box flex={1} textAlign="center" border={theme => `1px solid ${theme.palette.error.dark}`} py={1}>
            <Select
              size="small"
              value={transferConfig.in}
              onChange={(e) => props.setTransferConfig({ in: e.target.value, out: transferConfig.out })}
              sx={{ background: theme => theme.palette.background.paper, width: { xs: '100px', sm: '150px' } }}
            >
              {props.teams.map((team) => {
                return (
                  <MenuItem key={team._id} value={team._id} disabled={transferConfig.out === team._id}>
                    {team.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography>{'>>>'}</Typography>
          <Box flex={1} textAlign="center" border={theme => `1px solid ${theme.palette.success.dark}`} py={1}>
            <Select
              size="small"
              value={transferConfig.out}
              onChange={(e) => props.setTransferConfig({ out: e.target.value, in: transferConfig.in })}
              sx={{ background: theme => theme.palette.background.paper, width: { xs: '100px', sm: '150px' } }}
            >
              {props.teams.map((team) => {
                return (
                  <MenuItem key={team._id} value={team._id} disabled={transferConfig.in === team._id}>
                    {team.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Button startIcon={<SwapHorizOutlined />} onClick={onSwapTransfer} disabled={props.disabled}>Swap</Button>
        </Box>
      );
    }

    return (
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))">
        {props.teams.map((team) => {
          const isSelected = props.selectedTeamIds.some((_id) => _id === team._id);

          return (
            <FormControlLabel
              key={team._id}
              control={<Checkbox checked={isSelected} onClick={() => onSelectTeam(team._id)} />}
              label={team.name}
              disabled={props.mode === UnknownEnum.unknown || props.disabled}
            />
          );
        })}
      </Box>
    );
  }, [ onSelectTeam, props ]);

  return content;
};