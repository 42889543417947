import { inventoryBatchEnumHelpers } from 'helpers';
import { useInventoryBatchOutletContext } from './InventoryBatch.base';
import { InventoryBatchLayout } from './components/InventoryBatchLayout.component';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { CardContentContainer, CardContentGrid, InventoryBatchStatusChip, LoggedAtDateCell, StockUpdateLogTable, Table } from 'components';
import { LabeledChipBox } from 'components/Chips/LabeledChipBox.component';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, InventoryBatchUpdate } from 'api/resources';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { GridColDef } from '@mui/x-data-grid';
import { useProductsStockUpdateLogs } from 'queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateInventoryBatch } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { CancelBatchButton } from './components/CancelBatchButton.component';

const useColumns = (): GridColDef<InventoryBatchUpdate>[] => {
  const { products } = useProductsPageContext();
  const { inventoryBatch } = useInventoryBatchOutletContext();

  const productNameHash = products.reduce((acc: Record<string, string>, product) => ({ ...acc, [product._id]: product.name }), {});



  return [
    {
      field: 'product',
      headerName: 'Product',
      valueGetter: ({ row }) => productNameHash[row.product],
      width: 200,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: ({ row }) => {
        const value = row.quantity;
        const isPositive = inventoryBatch.type !== InventoryBatchTypeEnum.overstockPicking && value > 0;

        return (
          <Typography
            sx={{
              color: (theme) => row.reviewQuantity ? theme.palette.text.disabled : theme.palette[isPositive ? 'success' : 'error'].main,
            }}
          >
            {isPositive ? '+' : '-'}{Math.abs(value)}
          </Typography>
        );
      }
    },
    {
      field: 'reviewQuantity',
      headerName: 'Review Quantity',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: ({ row }) => {
        const value = row.reviewQuantity;

        if (!value) {
          return null;
        }

        const isPositive = inventoryBatch.type !== InventoryBatchTypeEnum.overstockPicking && value > 0;

        return (
          <Typography
            sx={{ color: (theme) => theme.palette[isPositive ? 'success' : 'error'].main }}
          >
            {isPositive ? '+' : '-'}{Math.abs(value)}
          </Typography>
        );
      }
    }
  ];
};

export const BatchOverview: React.FC = () => {
  const columns = useColumns();
  const { getIsMe } = useCurrentUser();
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const updateStatusMutation = useMutation({
    mutationFn: (status: InventoryBatchStatusEnum) => updateInventoryBatch(inventoryBatch._id, { status }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });
    }
  });
  const isClosed = inventoryBatch.status === InventoryBatchStatusEnum.closed;
  const { data: updateLogs = [], isInitialLoading: updateLogsLoading } = useProductsStockUpdateLogs({ batchId: inventoryBatch._id }, isClosed);

  const TypeIcon = inventoryBatchEnumHelpers.type.getIcon(inventoryBatch.type);

  const isMyBatch = getIsMe(inventoryBatch.user._id);

  return (
    <InventoryBatchLayout
      preventReducedMarginTop
      actions={(
        <>
          {isMyBatch && <CancelBatchButton />}
          {inventoryBatch.status === InventoryBatchStatusEnum.review && isMyBatch && <Button variant="outlined" disabled={updateStatusMutation.isLoading} onClick={() => updateStatusMutation.mutate(InventoryBatchStatusEnum.open)}>Make More Updates</Button>}
        </>
      )}
    >
      <Box display="grid" gridTemplateColumns="minmax(420px, 1fr) 3fr" gap={2}>
        <Box>
          <CardContentContainer title="Batch Information">
            <CardContentGrid
              items={[
                {
                  title: 'Batch ID',
                  value: inventoryBatch.name,
                },
                {
                  title: 'Type',
                  value: (
                    <Box display="flex" gap={1} mt={0.25}>
                      <TypeIcon />
                      <Typography fontWeight={500}>{inventoryBatchEnumHelpers.type.getLabel(inventoryBatch.type)}</Typography>
                    </Box>
                  ),
                },
                {
                  title: 'Team',
                  value: inventoryBatch.team.name
                },
                {
                  title: 'User',
                  value: inventoryBatch.user.name
                },
                {
                  value: <LabeledChipBox title="Batch Status" value={inventoryBatch.status} ChipComponent={InventoryBatchStatusChip} />,
                  type: 'full',
                }
              ]}
            />
            <Divider sx={{ my: 2 }} />
            <CardContentGrid
              items={[
                {
                  title: 'Created At',
                  value: <LoggedAtDateCell value={inventoryBatch.createdAt} fontSize="16px" />,
                },
                {
                  title: 'Updated At',
                  value: <LoggedAtDateCell value={inventoryBatch.updatedAt} fontSize="16px" />,
                },
              ]}
            />
            <Divider sx={{ my: 2 }} />
            <CardContentGrid
              items={[
                {
                  title: 'Reviewer',
                  value: isClosed ? inventoryBatch.reviewUser?.name : '–',
                },
                {
                  title: 'Revisions',
                  value: isClosed ? inventoryBatch.updates.filter(update => update.reviewQuantity !== undefined).length : '–',
                }
              ]}
            />
          </CardContentContainer>
        </Box>
        <Box>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box p={2}>
                <Typography variant="h6" fontWeight={500}>Batch Updates</Typography>
              </Box>
              <Box display="flex" flex={1}>
                <Table
                  getRowId={(row) => row._id}
                  rows={inventoryBatch.updates}
                  columns={columns}
                  slotProps={{
                    toolbar: { searchOnly: true }
                  }}
                  emptyMessage="No updates yet"
                  sx={{ border: 'none' }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      {isClosed && (
        <Card sx={{ mt: 2 }} variant="outlined">
          <Box p={2}>
            <Typography variant="h6" fontWeight={500}>Stock Update Logs</Typography>
            <Typography>Once a batch is closed, the inventory is updated. Here are the update logs from this batch:</Typography>
          </Box>
          <StockUpdateLogTable
            loading={updateLogsLoading}
            rows={updateLogs}
            sx={{ border: 'none' }}
          />
        </Card>
      )}
    </InventoryBatchLayout>
  );
};