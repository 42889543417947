import { Chip, ChipProps, useTheme } from '@mui/material';
import { ThemePalette } from 'types';

export type StandardChipProps<T extends string> = ChipProps & {
  value: T | undefined | null;
  getIcon?: (value: T | undefined | null) => React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  getLabel: (value: T | undefined | null) => string;
  getColor: (value: T | undefined | null) => ThemePalette;
};

export const StandardChip = <T extends string>(props: StandardChipProps<T>) => {
  const theme = useTheme();
  const color = props.getColor(props.value);
  const Icon = props.getIcon ? props.getIcon(props.value) : null;

  return (
    <Chip
      label={props.getLabel(props.value)}
      size="small"
      color={color}
      icon={Icon ? <Icon /> : undefined}
      variant="outlined"
      sx={{ fontWeight: 400, bgcolor: theme.palette[color].background, borderRadius: 0, cursor: 'inherit' }}
      {...props}
    />
  );
};