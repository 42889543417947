import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';

export const LogisticsTeamAssignmentList = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentList}
      localStorageArgs={{
        initialVisible: [ 'name', 'eventTimeframe', 'teamName', 'vehicleName' ],
        initialSortModel: [ { field: 'eventTimeframe', sort: 'asc' } ],
      }}
      groupByWeek
    />
  );
};
