import { OpenInNewOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Card, Chip, Divider, List, ListItem, Typography } from '@mui/material';
import { Loading, useEventPreviewDrawerContext, useSelectionChipGroup } from 'components';
import { eventEnumHelpers } from 'helpers';
import { EventsManagementPageTitle } from 'pages/EventsManagement/components/EventsManagementPageTitle.component';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { useEventsCommunocationLog } from 'queries';
import React, { useMemo } from 'react';
import { DateService, getUserInitialsForAvatar } from 'services';

export const ApplicationsCommunicationLog = () => {
  const { SelectionChipGroup, value: limit } = useSelectionChipGroup({ initialValue: '50' });
  const { data: eventsCommunicationLog = [], isInitialLoading: eventsCommunicationLogLoading } = useEventsCommunocationLog({ limit: limit === 'all' ? undefined : Number(limit) });
  const { setOpenEvent } = useEventPreviewDrawerContext();

  const content = useMemo(() => {
    if (eventsCommunicationLogLoading) {
      return <Loading />;
    }

    return (
      <List>
        {eventsCommunicationLog.map(communicationLog => {
          const Icon = eventEnumHelpers.communicationLog.getIcon(communicationLog.type);

          return (
            <ListItem key={communicationLog._id} components={{ Root: 'div' }} disableGutters disablePadding>
              <Card variant="outlined" sx={{ my: 0.5, maxWidth: 700 }}>
                <Box mx={2} my={1} display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    <Box display="flex" alignItems="center" gap={1} mb={-1} ml={-1}>
                      <Button onClick={() => setOpenEvent(communicationLog.event._id, 'main')}>
                        <Typography display="flex" alignItems="center">
                          <OpenInNewOutlined fontSize="small" />&nbsp;{communicationLog.event.name}
                        </Typography>
                      </Button>
                      <Typography>|</Typography>
                      <Icon fontSize="small" color="primary" />
                      {eventEnumHelpers.communicationLog.getLabel(communicationLog.type)}
                    </Box>
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="small"
                      label={DateService.dayjs(communicationLog.date).fromNow()}
                      sx={{ bgcolor: theme => theme.palette.primary.background }}
                    />
                  </Box>
                  <Box mx={-2}><Divider /></Box>
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {communicationLog.user && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ width: 20, height: 20 }} src={communicationLog.user.profileImageUrl}>
                          {getUserInitialsForAvatar(communicationLog.user.name)}
                        </Avatar>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <Box component="span" fontWeight={500}>{communicationLog.user.name}</Box>
                          {' on '}
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                      fontWeight={500}
                    >
                      {DateService.getFormattedDate(communicationLog.date, 'MMM Do, YYYY h:mma')}
                    </Typography>
                  </Box>

                  {communicationLog.note && (
                    <Typography sx={{ display: 'inline', bgcolor: theme => theme.palette.grey[100], mx: -2, mb: -1, px: 2, pt: 1, pb: 2 }} component="span" variant="body2" color="text.secondary">
                      {communicationLog.note}
                    </Typography>
                  )}
                </Box>
              </Card>
            </ListItem>
          );
        })}
      </List>
    );
  }, [ eventsCommunicationLog, eventsCommunicationLogLoading, setOpenEvent ]);

  return (
    <>
      <EventsManagementPageTitle nodeId={EventsManagementTreeViewNodeIdEnum.applicationsCommunicationLog} />
      <Box display="flex" justifyContent="flex-end">
        <SelectionChipGroup options={[ { value: '50', label: 'Last 50' }, { value: '100', label: 'Last 100' }, { value: 'all', label: 'Show All' } ]} />
      </Box>
      {content}
    </>
  );
};
