import React from 'react';
import { EventStaffStatusEnum } from 'types';
import { StandardChip } from '../StandardChip.component';
import { StatusChipProps } from '../types';
import { eventEnumHelpers } from 'helpers';

export type StaffStatusChipProps = StatusChipProps<EventStaffStatusEnum>;

export const StaffStatusChip: React.FC<StaffStatusChipProps> = props => {
  return <StandardChip {...eventEnumHelpers.staffStatus} {...props} />;
};