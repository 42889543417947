import { ClearOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, ListSubheader, Stack, Typography, lighten } from '@mui/material';
import { GenreGroupEnum } from 'api/resources/genre';
import { Autocomplete } from 'components';
import { FieldArray, useFormikContext } from 'formik';
import { genreEnumHelpers } from 'helpers/enums/genre-enums.helpers';
import { useGenres } from 'queries/genre';
import { ResourceForm } from 'types';

export const ProductGenresForm = () => {
  const { data: genres = [] } = useGenres();
  const { values, setFieldValue } = useFormikContext<ResourceForm['product']>();

  return (
    <FieldArray
      name="genres"
      render={arrayHelpers => (
        <Box sx={{
          ml: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1.5
        }}
        >
          {Array.from({ length: 3 }).map((_, i) => {
            const value = values.genres[i];

            if(value === null) {
              setFieldValue(`genres.${i}`, '');
            }

            return (
              <Box width="100%" key={value}>
                <Grid container spacing={1} display="flex" alignItems="center">
                  <Grid item xs={1}>
                    #{i + 1}
                  </Grid>
                  <Grid item xs={1} height="100%">
                    <Stack borderLeft="2px solid" borderColor="primary.main" pl={1}>
                      <IconButton
                        onClick={() => arrayHelpers.swap(i, i - 1)}
                        disabled={!i}
                        color="primary"
                      >
                        <KeyboardArrowUpOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => arrayHelpers.swap(i, i + 1)}
                        disabled={i === 2}
                        color="primary"
                      >
                        <KeyboardArrowDownOutlined />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={9}>
                    <Autocomplete
                      name={`genres.${i}`}
                      color="primary.main"
                      disableClearable
                      label={`TOP-${i + 1} Genre`}
                      options={genres}
                      groupBy={(opt) => opt?.group}
                      getOptionLabel={(opt) => opt?.title}
                      transformValue={(opt) => opt?._id}
                      renderGroup={(params) => {
                        const group = params.group as GenreGroupEnum;
                        const GroupIcon = genreEnumHelpers.group.getIcon(group);

                        return (
                          <li key={params.key}>
                            <ListSubheader
                              sx={{
                                top: -10,
                                display: 'flex',
                                alignItems: 'center',
                                background: theme => lighten(theme.palette.primary.light, 0.7),
                                color: theme => theme.palette.primary.dark,
                                userSelect: 'none',
                              }}
                            >
                              <GroupIcon />&nbsp;
                              <Typography fontWeight={500}>
                                {genreEnumHelpers.group.getLabel(group)}
                              </Typography>
                            </ListSubheader>
                            {params.children}
                          </li>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} height="100%">
                    <IconButton onClick={() => setFieldValue(`genres.${i}`, undefined)} color="primary">
                      <ClearOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      )}
    />
  );
};