import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ProfileScheduleTable } from './ProfileScheduleTable.component';
import { TableOrCalendarViewToggle, TableOrCalendarViewType } from 'components';
import { User } from 'api/resources';
import { useEventDatesForUser } from 'queries/eventDate';
import { ProfileScheduleCalendar } from './ProfileScheduleCalendar.component';

export type ProfileScheduleProps = {
  user: User;
};

export const ProfileSchedule: React.FC<ProfileScheduleProps> = ({ user }) => {
  const [ view, setView ] = useState<TableOrCalendarViewType>('calendar');
  const { data: eventDates = [], isInitialLoading: loading } = useEventDatesForUser(user._id);

  const viewChangeHandler = (nextView: TableOrCalendarViewType) => {
    if (!nextView) {
      return;
    }

    setView(nextView);
  };

  return (
    <Box m={2}>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <Box display="flex" gap={2} alignItems="center" flexWrap="wrap-reverse" justifyContent="space-between">
          <TableOrCalendarViewToggle view={view} setView={viewChangeHandler} />
        </Box>
      </Box>
      <Box height="700px">
        {
          {
            table: <ProfileScheduleTable eventDates={eventDates} user={user} loading={loading} />,
            calendar: <ProfileScheduleCalendar eventDates={eventDates} user={user} />,
          }[view]
        }
      </Box>
    </Box>
  );
};