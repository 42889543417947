import { ClickAwayListener, Box, Divider, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { eventsManagementTreeViewConfig } from '../EventsManagement.page';
import { EventsManagementTreeView } from './EventsManagementTreeView.component';
import { EventsManagementTreeViewContractSidebar } from './EventsManagementTreeViewContractSidebar.component';
import { MenuOpen } from '@mui/icons-material';
import { EventsManagementTreeViewNodeIdEnum } from '../helpers';
import { useEventManagementNodeIdsBadgeNumbersHash } from '../hooks';

export type EventsManagementSidebarProps = {
  nodeId: EventsManagementTreeViewNodeIdEnum;
  onNodeSelect: (nodeId: EventsManagementTreeViewNodeIdEnum) => void;
};

export const EventsManagementSidebar: React.FC<EventsManagementSidebarProps> = ({ nodeId, onNodeSelect }) => {
  const [ treeViewOpen, setTreeViewOpen ] = useState(true);
  const badgeNumbersHash = useEventManagementNodeIdsBadgeNumbersHash();

  const contractSidebarDisplay = useMemo(() => {
    return <EventsManagementTreeViewContractSidebar badgeNumbersHash={badgeNumbersHash} config={eventsManagementTreeViewConfig} selected={nodeId} onSelect={onNodeSelect} />;
  }, [ nodeId, onNodeSelect, badgeNumbersHash ]);
  const sidebarDisplay = useMemo(() => {
    return (
      <ClickAwayListener onClickAway={() => setTreeViewOpen(false)}>
        <Box width={240} flexWrap="nowrap" overflow="hidden">
          <EventsManagementTreeView badgeNumbersHash={badgeNumbersHash} config={eventsManagementTreeViewConfig} selected={nodeId} onNodeSelect={onNodeSelect} />
        </Box>
      </ClickAwayListener>
    );
  }, [ nodeId, onNodeSelect, badgeNumbersHash ]);

  const toggleTreeViewOpen = () => {
    setTreeViewOpen(p => !p);
  };

  return (
    <Box mr={2} minWidth={60} bgcolor={theme => theme.palette.background.defaultDarker} borderRight={theme => `1px solid ${theme.palette.divider}`}>
      <Box ml={1}>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            toggleTreeViewOpen();
          }}
        >
          <MenuOpen sx={{ transform: treeViewOpen ? 'none' : 'rotate(180deg)' }} fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box display="flex" height="85vh">
        {treeViewOpen ? sidebarDisplay : contractSidebarDisplay}
      </Box>
    </Box>
  );
};
