import { Box } from '@mui/material';
import { EditModeTogglerProps } from 'components/EditMode';

export type TableActionsBarProps = {
  editModeToggler?: React.ReactElement<EditModeTogglerProps>;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
};

export const TableActionsBar: React.FC<TableActionsBarProps> = props => {

  return (
    <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={2} mb={1} flexWrap="wrap">
      <Box flex={1} display="flex" alignItems="center" gap={2} flexWrap="wrap">
        {props.editModeToggler && (
          <Box>
            {props.editModeToggler}
          </Box>
        )}
        <Box>
          {props.leftActions}
        </Box>
      </Box>
      {props.rightActions && (
        <Box>
          {props.rightActions}
        </Box>
      )}
    </Box>
  );
};