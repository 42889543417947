import { Grid } from '@mui/material';
import React from 'react';
import { TotalProducedChart } from './TotalProducedChart.component';
import { DamagedMissingProductsChart } from './DamagedMissingProductsChart.component';
import { TeamProductionChart } from './TeamProductionChart.component';
import { ProductProductionChart } from './ProductProductionChart.component';
import { ProductsSetsChart } from './ProductsSetsChart.compoenent';

export const ProductsAnalytics = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TotalProducedChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <DamagedMissingProductsChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductProductionChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <TeamProductionChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProductsSetsChart />
      </Grid>
    </Grid>
  );
};
