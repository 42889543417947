import { LoggingPortalDataForEventDate, GetLoggingPortalDataResponse, LoggingPortalDataForStorefront, LoggingPortalResourceTypeEnum } from 'api/actions';
import { LightLayout, Loading, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useLoggingPortalDataForEventDateQuery, useLoggingPortalDataForStorefrontQuery } from 'queries/log-live-sale';
import { Navigate, Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { LogSaleLayout } from './LogSale/components/LogSaleLayout.component';
import { useTeams } from 'queries';
import { useMemo } from 'react';

type LoggingPortalParams = { resourceType: LoggingPortalResourceTypeEnum; resourceId: string };
type LoggingPortalBaseType = LoggingPortalParams & { teamId?: string };
type LoggingPortalBaseContextType = GetLoggingPortalDataResponse['data'] & LoggingPortalBaseType;
type LoggingPortalForEventDateContextType = LoggingPortalDataForEventDate & LoggingPortalBaseType;
type LoggingPortalForStorefrontContextType = LoggingPortalDataForStorefront & LoggingPortalBaseType;

export function useLoggingPortalContext<T extends LoggingPortalResourceTypeEnum>(portalType?: T):
T extends undefined ? GetLoggingPortalDataResponse['data']
  : T extends LoggingPortalResourceTypeEnum.eventDate ? LoggingPortalForEventDateContextType
    : LoggingPortalForStorefrontContextType;
export function useLoggingPortalContext(portalType?: LoggingPortalResourceTypeEnum): LoggingPortalForEventDateContextType | LoggingPortalForStorefrontContextType | LoggingPortalBaseContextType {
  const data = useOutletContext();

  if (portalType === LoggingPortalResourceTypeEnum.eventDate) {
    return data as LoggingPortalForEventDateContextType;
  }
  if (portalType === LoggingPortalResourceTypeEnum.storefront) {
    return data as LoggingPortalForStorefrontContextType;
  }

  return data as LoggingPortalBaseContextType;
}

type LoggingPortalBaseRawProps = LoggingPortalParams;

const LoggingPortalBaseRaw: React.FC<LoggingPortalBaseRawProps> = ({ resourceType, resourceId }) => {
  const snackbar = useAlertSnackbar();
  const { pathname } = useLocation();
  const { data: dataForEventDate, isInitialLoading: loggingPortalDataForEventDateQueryLoading } = useLoggingPortalDataForEventDateQuery(resourceId, resourceType === LoggingPortalResourceTypeEnum.eventDate);
  const { data: dataForStorefront, isInitialLoading: loggingPortalDataForStorefrontQueryLoading } = useLoggingPortalDataForStorefrontQuery(resourceId,  resourceType === LoggingPortalResourceTypeEnum.storefront);
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const data = resourceType === LoggingPortalResourceTypeEnum.eventDate ?  dataForEventDate : dataForStorefront;

  if (loggingPortalDataForEventDateQueryLoading || loggingPortalDataForStorefrontQueryLoading || teamsLoading) {
    if (pathname.includes(ROUTING_CONFIG.logSale)) {
      return (
        <LogSaleLayout>
          <Loading />
        </LogSaleLayout>
      );
    }

    return (
      <LightLayout>
        <Loading />
      </LightLayout>
    );
  }

  if (!data) {
    snackbar.error('There was an issue loading the logging portal');
    return <Navigate to={`/${ROUTING_CONFIG.logLiveSale}`} replace />;
  }

  const warehouseTeamId = teams.find(team => team.isWarehouse)?._id;
  const teamId = 'event' in data ? data.event.team?._id : warehouseTeamId;

  return <Outlet context={{ ...data, resourceId, resourceType, teamId }} />;
};

export const LoggingPortalBase: React.FC = () => {
  const { resourceType, resourceId } = useParams();
  const portalResourceType = useMemo(() => resourceType === 'event-date' ? LoggingPortalResourceTypeEnum.eventDate : LoggingPortalResourceTypeEnum.storefront, [ resourceType ]);

  if (!resourceId || !resourceType) {
    return <Navigate to={`/${ROUTING_CONFIG.logLiveSale}`} replace />;
  }

  return (
    <LoggingPortalBaseRaw
      resourceType={portalResourceType}
      resourceId={resourceId}
    />
  );
};