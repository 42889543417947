import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { useField } from 'formik';

export type CheckboxProps = MuiCheckboxProps & {
  name: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({ name, ...props }) => {
  const [ field ] = useField(name);

  return (
    <MuiCheckbox
      checked={field.value}
      {...props}
      {...field}
    />
  );
};
