import { DialogTitle, DialogContent, DialogContentText, Button } from '@mui/material';
import { Modal } from '../Modal.component';
import { ModalActions } from '../ModalActions.component';

export type ConfirmActionModalProps = {
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  title?: string;
  text?: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
};

export const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  open,
  title = 'Are you sure?',
  text = 'Please, confirm your action',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  onClose,
  loading
}) => {

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      topLeftIcon="close"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      autoHeight
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <ModalActions>
        <Button onClick={handleClose} color="inherit" disabled={loading}>
          {cancelButtonText}
        </Button>
        <Button
          onClick={() => onConfirm()}
          variant="contained"
          disabled={loading}
        >
          {!loading ? confirmButtonText : 'Loading...'}
        </Button>
      </ModalActions>
    </Modal>
  );
};