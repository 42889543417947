import { DateRange } from 'components';
import { EnumHelpers } from 'helpers/enums/types';

export type GetSortedDataProps<T> = {
  data: T[];
  getNameAndId: (item: T) => {name: string; id: string} | null;
};

export const getSortedData = <T extends unknown>({ data, getNameAndId }: GetSortedDataProps<T>) => {
  const hash = data.reduce((r, item) => {
    const nameAndId = getNameAndId(item);

    if (!nameAndId) {
      return r;
    }

    const { id, name } = nameAndId;

    return {
      ...r,
      [id]: { _id: id, name, amount: (r[id]?.amount ?? 0) + 1 },
    };
  }, {} as { [id: string]: { _id: string; name: string; amount: number } });

  const sortedData = Object.values(hash).sort((a, b) => b.amount - a.amount);

  return sortedData;
};

export const enum AnalyticsCompareToEnum {
  previousPeriod = 'previousPeriod',
  lastYear = 'lastYear'
}

const dateRangesAreSame = (dateRange1: DateRange, dateRange2: DateRange) => {
  if(!dateRange1 || !dateRange2) {
    return false;
  }

  return dateRange1.start.isSame(dateRange2.start, 'day') && dateRange1.end.isSame(dateRange2.end, 'day');
};

export const analyticsCompareToEnumHelpers: EnumHelpers<AnalyticsCompareToEnum> & {
  getDateRange: (inputDateRange: NonNullable<DateRange>, value: AnalyticsCompareToEnum) => DateRange;
  getValueForDateRange: (inputDateRange: NonNullable<DateRange>, DateRange: DateRange) => AnalyticsCompareToEnum | undefined;
} = {
  enumValues: [
    AnalyticsCompareToEnum.previousPeriod,
    AnalyticsCompareToEnum.lastYear
  ],
  getLabel: (value) => {
    switch (value) {
      case AnalyticsCompareToEnum.lastYear:
        return 'Last Year';
      case AnalyticsCompareToEnum.previousPeriod:
        return 'Previous Period';
      default:
        return 'No Comparison';
    }
  },
  getDateRange: (inputDateRange, value) => {
    switch (value) {
      case AnalyticsCompareToEnum.lastYear:
        return {
          start: inputDateRange.start.subtract(1, 'year'),
          end: inputDateRange.end.subtract(1, 'year'),
        };
      case AnalyticsCompareToEnum.previousPeriod:
        const periodDuration = inputDateRange.end.diff(inputDateRange.start, 'days') + 1;

        return { start: inputDateRange.start.subtract(periodDuration, 'days'), end: inputDateRange.end.subtract(periodDuration, 'days') };
      default:
        return null;
    }
  },
  getValueForDateRange: (inputDateRange, dateRange) => {
    if(!dateRange) {
      return undefined;
    }
    const lastYearDateRange = {
      start: inputDateRange.start.subtract(1, 'year'),
      end: inputDateRange.end.subtract(1, 'year'),
    };

    if(dateRangesAreSame(dateRange, lastYearDateRange)) {
      return AnalyticsCompareToEnum.lastYear;
    }

    const periodDuration = inputDateRange.end.diff(inputDateRange.start, 'days') + 1;
    const prevPeriodDateRange = { start: inputDateRange.start.subtract(periodDuration, 'days'), end: inputDateRange.end.subtract(periodDuration, 'days') };

    if(dateRangesAreSame(dateRange, prevPeriodDateRange)) {
      return AnalyticsCompareToEnum.previousPeriod;
    }

    return undefined;
  },
  getColor: () => 'unknown'
};

export const maxCountOptions = [ 3, 5, 7, 10, 15, 20 ];