import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { Loading } from 'components';
import { useProducts, useTeams } from 'queries';
import React, { useContext } from 'react';

type ProductsPageContextType = {
  products: GetProductsResponse['data'];
  getProductsSortedAlphabetically: () => GetProductsResponse['data'];
  loading: boolean;
  teams: GetTeamsResponse['data'];
  teamsLoading: boolean;
  productsLoading: boolean;
};

export const ProductsPageContext = React.createContext<ProductsPageContextType>({
  products: [],
  getProductsSortedAlphabetically: () => [],
  loading: false,
  teams: [],
  teamsLoading: false,
  productsLoading: false,
});

export type ProductsPageContextProviderProps = {
  children: React.ReactNode | ((context: ProductsPageContextType) => React.ReactNode);
  waitForTeams?: boolean;
  waitForProducts?: boolean;
};

export const ProductsPageContextProvider: React.FC<ProductsPageContextProviderProps> = props => {
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: products = [], isInitialLoading: productsLoading } = useProducts();

  const contextValue: ProductsPageContextType = {
    products,
    getProductsSortedAlphabetically: () => [ ...products ].sort((a, b) => a.name.localeCompare(b.name)),
    loading: productsLoading,
    teams,
    teamsLoading,
    productsLoading,
  };

  const children = typeof props.children === 'function' ? props.children(contextValue) : props.children;

  return (
    <ProductsPageContext.Provider value={contextValue}>
      {(props.waitForTeams && teamsLoading) || (props.waitForProducts && productsLoading) ? <Loading /> : children}
    </ProductsPageContext.Provider>
  );
};

export const useProductsPageContext = () => useContext(ProductsPageContext);