import { CalendarApi } from '@fullcalendar/react';
import {
  ArrowForwardIos, CloseFullscreen, MenuOpen, OpenInFull,
} from '@mui/icons-material';
import {
  Box, Button, ButtonGroup, IconButton, Typography,
} from '@mui/material';

export type CalendarToolbarProps = {
  calendarApis?: CalendarApi[];
  fullScreen?: boolean;
  onFullScreenToggle?: () => void;
  isSidebarOpen?: boolean;
  onSidebarToggle?: () => void;
  onStateChange: () => void;
  onDateChange?: (date: Date) => void;
};

export const CalendarToolbar: React.FC<CalendarToolbarProps> = ({
  calendarApis,
  fullScreen = false,
  onFullScreenToggle,
  isSidebarOpen,
  onSidebarToggle,
  onStateChange,
  onDateChange,
}) => {
  if (!calendarApis) {
    return null;
  }

  const onTodayClickHandler = () => {
    calendarApis.forEach(c => c.today());
    onDateChange?.(calendarApis[0].getDate());
    onStateChange();
  };
  const onPrevClickHandler = () => {
    calendarApis.forEach(c => c.prev());
    onDateChange?.(calendarApis[0].getDate());
    onStateChange();
  };
  const onNextClickHandler = () => {
    calendarApis.forEach(c => c.next());
    onDateChange?.(calendarApis[0].getDate());
    onStateChange();
  };

  return (
    <Box mb={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box display="flex" gap={1} alignItems="center">
          {onSidebarToggle && (
            <IconButton onClick={onSidebarToggle}>
              <MenuOpen sx={{ transform: isSidebarOpen ? 'none' : 'rotate(180deg)' }} />
            </IconButton>
          )}
          <Button onClick={onTodayClickHandler} variant="outlined">Today</Button>
          <ButtonGroup>
            <Button onClick={onPrevClickHandler} variant="outlined">
              <ArrowForwardIos sx={{ transform: 'rotate(180deg)' }} />
            </Button>
            <Button onClick={onNextClickHandler} variant="outlined">
              <ArrowForwardIos />
            </Button>
          </ButtonGroup>
          <Typography alignSelf="center" ml={2} variant="h5">{Object.values(calendarApis)[0]?.view.title}</Typography>
        </Box>
        <Box display="flex" gap={1}>
          {onFullScreenToggle && (
            <Button variant="outlined" onClick={onFullScreenToggle}>
              {fullScreen ? <CloseFullscreen titleAccess="Exit Full Screen" /> : <OpenInFull titleAccess="Enter Full Screen" />}
            </Button>
          )}
        </Box>
      </Box>

    </Box>
  );
};