import { PageContentContainer, PageContentHeader } from 'components';
import { ProductsTable } from './components';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ProductsPageContextProvider, useCurrentUser } from 'contexts';

export const ProductList = () => {
  const { isAdmin } = useCurrentUser();
  const navigate = useNavigate();

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Product List"
          rightActions={<Button variant="contained" onClick={() => navigate('new')} size="small" disabled={!isAdmin}>New Product</Button>}
        />
      )}
    >
      <ProductsPageContextProvider>
        <ProductsTable />
      </ProductsPageContextProvider>
    </PageContentContainer>
  );
};