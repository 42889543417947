import { SectionCardRowEditable, SectionCardRowEditableProps } from 'components';
import { UserPageSectionRowId, UserSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { User } from 'api/resources';
import { QueryClient } from '@tanstack/react-query';
import { useAppDispatch } from 'app/hooks';
import { useCurrentUser } from 'contexts';
import { getMeThunk } from 'app/auth/auth.slice';

export type UserSectionCardRowEditableProps<RowId extends UserPageSectionRowId> = {
  rowId: RowId;
  user: User;
} & Omit<SectionCardRowEditableProps<UserSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const UserSectionCardRowEditable = <RowId extends UserPageSectionRowId>(props: UserSectionCardRowEditableProps<RowId>) => {
  const dispatch = useAppDispatch();
  const { getIsMe } = useCurrentUser();
  const isMe = getIsMe(props.user);
  const invalidateQueriesHandler = async (queryClient: QueryClient) => {
    if (isMe) {
      await dispatch(getMeThunk());
    }

    await queryClient.invalidateQueries(QUERY_KEY.USER(props.user._id));
  };

  return <SectionCardRowEditable {...props} invalidateQueriesHandler={invalidateQueriesHandler} />;
};