import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { ActionCell } from 'components';
import { useNavigate } from 'react-router-dom';

export type UseTableActionColumnArgs= {
  routeTo: string;
  getNavigateTo?: (id: string, row: any) => string;
  openInNew?: boolean;
};

// TODO: update this hook to take the columns directly in the args and improve its generic types
export const useTableActionColumn = (args: UseTableActionColumnArgs) => {
  const navigate = useNavigate();

  const onActionClick = (id: string, row: any) => {
    if(args.getNavigateTo) {
      navigate(args.getNavigateTo(id, row));
    } else {
      navigate(`/${args.routeTo}/${id}`);
    }
  };

  const actionColumn: GridColDef = {
    field: 'action',
    headerName: '',
    disableExport: true,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    hideable: false,
    width: 30,
    renderCell: ({ id, row }) => <ActionCell onActionClick={() => onActionClick(String(id), row)} />,
  };

  const withActionColumn = <T extends GridValidRowModel>(columns: GridColDef<T>[]): GridColDef<T>[] => {
    return [ actionColumn, ...columns ];
  };

  return { withActionColumn };
};
