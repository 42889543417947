import { OpenInNewOutlined } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';

export type ActionCellType = {
  onActionClick: () => void;
};

export const ActionCell: React.FC<ActionCellType> = ({ onActionClick }) => {
  return (
    <GridActionsCellItem
      label="Open"
      icon={<OpenInNewOutlined />}
      onClick={onActionClick}
    />
  );
};
