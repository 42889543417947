import { Table } from 'components';
import { useTableActionColumn, useTableLocalStorage } from 'hooks';
import { NeedsAttentionCell } from './NeedsAttentionCell.component';
import { GetProductsResponse } from 'api/actions';
import { productSalesCountColumns } from 'helpers';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { Product } from 'api/resources';
import { GridColDef } from '@mui/x-data-grid';
import { ROUTING_CONFIG } from 'constants/routing-config';

const useProductsTableColumns = () => {
  const { isAdmin } = useCurrentUser();
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 100,
    },
    {
      field: 'website',
      headerName: 'Available On Website',
      width: 150,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'events',
      headerName: 'Available At Events',
      width: 150,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'uploaded',
      headerName: 'Uploaded To Website',
      width: 150,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'needsAttention',
      headerName: 'Needs Attention',
      width: 150,
      sortable: false,
      renderCell: NeedsAttentionCell,
      align: 'center',
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'rasterLayer',
      headerName: 'AI FIle',
      width: 100,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'centerLabel',
      headerName: 'Label File',
      width: 100,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'png',
      headerName: 'PNG',
      width: 70,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'dmc',
      headerName: 'DMC',
      width: 70,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'dwg',
      headerName: 'DWG',
      width: 70,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'divider',
      headerName: 'Divider Created',
      width: 120,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'dwgCreator',
      headerName: 'DWG Creator',
      width: 120,
      sortable: false,
    },
    {
      field: 'websitePngs',
      headerName: 'Website PNGs',
      width: 150,
      type: 'boolean',
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: ({ value }) => new Date(value),
      type: 'date',
      width: 100,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 100,
    },
  ];

  if(isAdmin) {
    columns.push(...productSalesCountColumns);
  }

  return columns;
};

export type TProductTableRow = {
  salesCount: number;
  prevSixMonthSalesCount: number;
} & Omit<Product, 'availability' | 'preperation' | 'genres'> & Product['preperation'] & Partial<Product['availability']>;

export const transformProductsForTable = (products: GetProductsResponse['data']) => {
  return products.map((product): TProductTableRow => {
    return {
      ...product.preperation,
      ...product.availability,
      ...product.stocks,
      ...product,
      createdBy: product.createdBy?.name,
    };
  });
};

export const ProductsTable: React.FC = () => {
  const { products, loading } = useProductsPageContext();
  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });
  const productsTableColumns = useProductsTableColumns();
  const columns = withActionColumn(productsTableColumns);
  const { initialTableState, tableStateChange } = useTableLocalStorage({
    id: 'products-table',
    initialVisible: [ 'name', 'sku', 'salesCount', 'prevSixMonthSalesCount' ],
    columns,
  });

  const rows = transformProductsForTable(products);

  return (
    <Table
      {...tableStateChange}
      initialState={{
        columns: {
          columnVisibilityModel: initialTableState.columnVisibility,
        },
        filter: {
          filterModel: initialTableState.filter,
        },
        sorting: {
          sortModel: initialTableState.sort,
        },
      }}
      getRowId={(x) => x._id}
      columns={columns}
      rows={rows}
      loading={loading}
      emptyMessage="No Products Were Found"
      disableRowSelectionOnClick
    />
  );
};