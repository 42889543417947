import { BackspaceOutlined, IsoOutlined } from '@mui/icons-material';
import { Box, ButtonBase, Divider, Typography } from '@mui/material';
import React from 'react';

export type NumberPadProps = {
  value: string;
  onChange: (value: string) => void;
  preventNegative?: boolean;
  error?: string;
};

export const NumberPad: React.FC<NumberPadProps> = props => {
  const { value, onChange } = props;

  const onNumberKeyClick = (number: number) => {
    const newValue = value + number;

    onChange(newValue);
  };

  const onBackspaceClick = () => {
    if (value === '') {
      return;
    }

    const newValue = value.slice(0, -1);

    if (newValue === '-') {
      onChange('');
      return;
    }

    onChange(newValue);
  };

  const onIsoClick = () => {
    if (value === '') {
      return;
    }

    const newValue = value.includes('-') ? value.slice(1) : '-' + value;

    onChange(newValue);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" px={3} height="104px">
        <Box>
          <Typography fontSize="48px" fontWeight={500}>{value}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="1px" bgcolor={theme => theme.palette.divider}>

        {Array.from({ length: 9 }).map((_, i) => <NumberKey key={i} content={i + 1} onClick={() => onNumberKeyClick(i + 1)} />)}

        {props.preventNegative ? <div /> : <NumberKey content={<IsoOutlined fontSize="large" />} onClick={onIsoClick} />}
        <NumberKey content={0} onClick={() => onNumberKeyClick(0)} />
        <NumberKey content={<BackspaceOutlined fontSize="large" />} onClick={onBackspaceClick} />
      </Box>
    </Box>
  );
};

type NumberKeyProps = {
  content: React.ReactNode;
  onClick: () => void;
};

const NumberKey: React.FC<NumberKeyProps> = props => {

  return (
    <ButtonBase sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '111px', bgcolor: theme => theme.palette.background.paper }} onClick={props.onClick}>
      {typeof props.content === 'number' ? <Typography fontSize="42px">{props.content}</Typography> : props.content}
    </ButtonBase>
  );
};