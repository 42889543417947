import { Box, Card, Divider, List, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { eventDateEnumHelpers, getEventLodgingPlaceForDate } from 'helpers';
import { useMemo, useState } from 'react';
import { EventBusyOutlined } from '@mui/icons-material';
import { EventDateListItem, ScrollableView } from 'components';
import { getWarningsForEventDate } from 'helpers/resource-warnings/warning-config/event-date-warning-config';
import { EventDateTypeEnum } from 'api/resources';
import { DateService } from 'services';

export type EventDatesListProps = {
  event: GetEventsResponse['data'][number];
  isCalendar?: boolean;
  onEventDateClick: (eventDateId: string) => void;
};

type EventDateTypeOption = EventDateTypeEnum | 'all';

const getEventDatesFilteredByType = (event: GetEventsResponse['data'][number], typeOption: EventDateTypeOption) => {
  return event.dates.filter(eventDate => typeOption === 'all' ? true : eventDate.type === typeOption);
};

export const EventDatesList: React.FC<EventDatesListProps> = ({ event, isCalendar = false, onEventDateClick }) => {
  const [ typeOption, setTypeOption ] = useState<EventDateTypeOption>('all');
  const filterdDates = getEventDatesFilteredByType(event, typeOption);

  const datesList = useMemo(() => {
    if (filterdDates.length) {
      return (
        <List
          disablePadding
          component="div"
        >
          {filterdDates.map((eventDate, index, { length }) => (
            <Card sx={{ m: 2 }} key={eventDate._id} variant="outlined">
              <EventDateListItem
                key={eventDate._id}
                data={{
                  event: event,
                  eventDate: eventDate,
                  eventDates: event.dates,
                  travelData: {
                    studioToEventMatrix: event.place.fromStudioMatrix,
                    lodgingToEventMatrix: getEventLodgingPlaceForDate(event, DateService.dayjsTz(eventDate.dateAsUtc))?.place?.toEventMatrix,
                  }
                }}
                onClick={onEventDateClick}
                hasWarning={!isCalendar && Boolean(getWarningsForEventDate(eventDate).warningItems.length)}
                divider={index !== length - 1}
              />
            </Card>
          ))}
        </List>
      );
    }

    return (
      <Box height="300px" display="flex" alignItems="center" justifyContent="center">
        <Box maxWidth="200px" textAlign="center" display="flex" flexDirection="column" alignItems="center" gap={1}>
          <EventBusyOutlined fontSize="large" />
          <Typography>
            {!event.dates.length ? 'No event dates' : 'No event dates match the selected filter'}
          </Typography>
        </Box>
      </Box>
    );
  }, [ event, filterdDates, isCalendar, onEventDateClick ]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={typeOption}
        exclusive
        onChange={(_, value) => value && setTypeOption(value)}
        disabled={!event.dates.length}
        sx={{ py: 1.5, px: 2 }}
      >
        { Array<EventDateTypeOption>(...eventDateEnumHelpers.type.enumValues, 'all').map((value) => {
          const length = getEventDatesFilteredByType(event, value).length;
          const label = value === 'all' ? 'All' : eventDateEnumHelpers.type.getShortLabel(value);

          return <ToggleButton disabled={!length} value={value} size="small" key={value}>{label} ({length})</ToggleButton>;
        })}
      </ToggleButtonGroup>
      <Divider />
      <ScrollableView height="480px" enableScrollShadow>
        {datesList}
      </ScrollableView>
    </>
  );
};
