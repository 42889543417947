export const FORMAT_DATE = {
  standardShort: 'MMMM Do, YYYY' as const,
  standard: 'ddd, MMMM Do, YYYY' as const,
  standardFull: 'dddd, MMMM Do, YYYY' as const,
  forThisEventDate: 'YYYY-MM-DD' as const,
  'MM-D-YY': 'MM-D-YY' as const,
  'ddd, MMM Do, YYYY': 'ddd, MMM Do, YYYY' as const,
  standardTableCell: 'MM/DD/YY (ddd)' as const,
  'MMM Do, YYYY h:mma': 'MMM Do, YYYY h:mma' as const,
};
