import { AttentionWarningItemType } from 'components';
import { ResouceWarningConfig } from './types';

export type GetWarningsForResourceOutput<SectionEnum extends string, RowEnum extends string> = {
  warningItems: AttentionWarningItemType<SectionEnum, RowEnum>[];
  getRowWarningDescription: (row: RowEnum) => string[] | undefined;
};

export const getWarningsForResource = <Resource = unknown, SectionEnum extends string = string, RowEnum extends string = string>(resource: Resource, warningConfigs: ResouceWarningConfig<Resource, SectionEnum, RowEnum>[]): GetWarningsForResourceOutput<SectionEnum, RowEnum> => {
  const warningConfigsHash = warningConfigs.reduce((prev, warningConfig) => {
    return {
      ...prev,
      [warningConfig.section]: prev[warningConfig.section] ?
        [
          ...prev[warningConfig.section],
          warningConfig,
        ] : [
          warningConfig
        ]
    };
  }, {} as { [id: string]: ResouceWarningConfig<Resource, SectionEnum, RowEnum>[] });

  const warningItems = Object.keys(warningConfigsHash).reduce((prev, section: SectionEnum) => {
    const reasonItems = [ ...warningConfigsHash[section].reduce((prev, warningConfig): AttentionWarningItemType<SectionEnum, RowEnum>['reasonItems'] => {
      const needsAttention = warningConfig.needsAttention(resource);

      if (needsAttention) {
        return [ ...prev,  {
          row: warningConfig.row,
          description: Array.isArray(needsAttention) ? needsAttention : [ needsAttention ]
        } ];
      }

      return prev;
    }, []) ];

    if (!reasonItems.length) return prev;

    return [ ...prev, { section, reasonItems } ];
  }, []);
  const rowWarningDescriptionHash = warningItems.reduce((r, warningItem) => {
    const newR = warningItem.reasonItems.reduce((_r, reasonItem) => ({
      ..._r,
      [reasonItem.row]: reasonItem.description,
    }), {});

    return { ...r, ...newR };
  }, {} as Record<RowEnum, string[] | undefined>);

  return { warningItems, getRowWarningDescription: (row: RowEnum) => rowWarningDescriptionHash[row] };
};