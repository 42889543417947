import { GridCellParams, GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventResponse, GetStaffSchedulingForEventResponse, deleteEventStaff, updateEventDate } from 'api/actions';
import { AreYouSureModal, Link, Table, UserStaffStatusChip, useAlertSnackbar, useEventContext } from 'components';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useCallback, useMemo, useState } from 'react';
import { eventDateEnumHelpers, getEventDateStaffStatus, processFormValueUpdate, userEnumHelpers } from 'helpers';
import { EventStaffStatusEnum } from 'types';
import { Avatar, Box, FormControlLabel, IconButton, ListItemButton, Switch, ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup, Tooltip, Typography, styled } from '@mui/material';
import { DateService, getUserInitialsForAvatar } from 'services';
import { AddOutlined, OpenInNewOutlined, PersonRemove } from '@mui/icons-material';
import _ from 'lodash';
import { useStaffSchedulingForEvent } from 'queries';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { AddStaffModalProps, AddStaffModal } from './AddStaffModal.modal';
import { StaffActionCell } from './StaffActionCell.component';
import { UseContainerQueryProps, useContainerQuery, useTableActionColumn } from 'hooks';
import { EventDaysSummary } from 'components/Event/EventDaysSummary.component';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export type EventSchedulingTableProps = Pick<AddStaffModalProps, 'invalidateQueriesHandler'> & {
  containerRef: UseContainerQueryProps['containerRef'];
  userId?: string;
};
type TEventSchedulingTableRow = GetEventResponse['data']['dates'][number];
type TEventSchedulingStaff = TEventSchedulingTableRow['staff'][number];

const staffFieldName = {
  getFieldName: (userId: string) => `staff_col-${userId}`,
  getUserId: (fieldName: string) => {
    const contains = fieldName.includes('staff_col-');

    if (contains) {
      return fieldName.replace('staff_col-', '');
    };

    return null;
  },
};

const getEventSchedulingTableColumns = ({ event, scheduling, onAddStaffToEventDate, navigate, invalidateQueriesHandler, isContainerSmall, editable, allUsers, displayUsersAvailbaility }: EventSchedulingTableProps & {
  event: GetEventResponse['data'];
  scheduling: GetStaffSchedulingForEventResponse['data'];
  onAddStaffToEventDate: (eventDateId: string) => void;
  navigate: NavigateFunction;
  isContainerSmall: boolean;
  editable: boolean;
  allUsers: GetEventResponse['data']['dates'][number]['staff'][number]['user'][];
  displayUsersAvailbaility: boolean;
}): GridColDef<TEventSchedulingTableRow>[] => {
  let STAFF_COLUMN_WIDTH = isContainerSmall ? 150 : 250;

  if (!displayUsersAvailbaility) {
    STAFF_COLUMN_WIDTH = 110;
  }

  const staffColumns = allUsers.map((user): GridColDef<TEventSchedulingTableRow> => {
    const field = staffFieldName.getFieldName(user._id);

    return {
      field: field,
      editable,
      sortable: false,
      hideable: false,
      minWidth: STAFF_COLUMN_WIDTH + 20,
      flex: allUsers.length >= 4 ? 1 : undefined,
      type: 'string',
      headerClassName: 'flex-header',
      cellClassName: 'staff-cell',
      renderHeader: () => {
        return (
          <Box minWidth={STAFF_COLUMN_WIDTH} width="100%" display="flex" flex={1} justifyContent="space-between" px={isContainerSmall ? 0 : 2}>
            <Typography variant="subtitle2">{user.name}</Typography>
            {displayUsersAvailbaility && <Typography variant="subtitle2" fontWeight={400}>{isContainerSmall ? 'Avl.' : 'Availability'}</Typography>}
          </Box>
        );
      },
      valueGetter: ({ row }) => row.staff.find(s => s.user._id === user._id),
      valueFormatter: ({ value }: GridValueFormatterParams<TEventSchedulingStaff>) => {
        return value ? userEnumHelpers.staffStatus.getLabel(value.status) : null;
      },
      renderCell: ({ row, value }: GridRenderCellParams<TEventSchedulingTableRow, TEventSchedulingStaff>) => {
        const userStatus = value?.status;

        const userScheduling = scheduling[row._id]?.[user._id];
        const availability = userScheduling?.availability;
        const conflictEvent = userScheduling?.conflictEvent;
        const showConflict = conflictEvent && conflictEvent._id !== event._id;

        const Icon = userEnumHelpers.availability.getIcon(availability);

        return (
          <Box minWidth={STAFF_COLUMN_WIDTH} width="100%" height="100%">
            <Box mx="-10px" display="flex" flex={1} height="100%">
              <Box bgcolor={theme => theme.palette.background.default} width="10px" />
              <Box
                display="flex"
                flex={1}
                justifyContent="space-between"
                alignItems="center"
                pr={isContainerSmall ? 0 : 2}
                pl={isContainerSmall ? 0 : 1}
                overflow="hidden"
                bgcolor={theme => showConflict ? theme.palette.threat.background : undefined}
              >
                <Box display="flex" alignItems="center" flex={1} gap={1}>
                  {userStatus && <UserStaffStatusChip value={userStatus} size="small" />}
                  {showConflict && (
                    <Tooltip
                      title={(
                        <Box display="flex" alignItems="center" gap={1}>
                          <Typography fontSize="small">{user.name} is scheduled at {conflictEvent.name} on this date</Typography>
                          <IconButton size="small" color="inherit" onClick={() => navigate(`/${ROUTING_CONFIG.events}/${conflictEvent._id}/${ROUTING_CONFIG.scheduling}`)}>
                            <OpenInNewOutlined fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    >
                      <Typography fontSize="14px" color="threat.main">Sched. at <Link getColor={theme => theme.palette.text.secondary} to={`/${ROUTING_CONFIG.events}/${conflictEvent._id}/${ROUTING_CONFIG.scheduling}`}>{conflictEvent.name}</Link> </Typography>
                    </Tooltip>
                  )}
                </Box>
                {displayUsersAvailbaility && !showConflict && availability && (
                  <Typography fontSize="14px" color={(theme) => theme.palette[userEnumHelpers.availability.getColor(availability)].main}>
                    {isContainerSmall ? (
                      <Icon color={userEnumHelpers.availability.getColor(availability) as any} />
                    ) : userEnumHelpers.availability.getLabel(availability)}
                  </Typography>
                )}
              </Box>
              <Box bgcolor={theme => theme.palette.background.default} width="10px" />
            </Box>
          </Box>
        );
      },
      renderEditCell: ({ value, row, api }: GridRenderEditCellParams<TEventSchedulingTableRow, TEventSchedulingStaff>) => {
        const stopEditMode = () => api.stopCellEditMode({ id: row._id, field: field });

        if (value) {
          return <StaffActionCell variant="edit" eventDateId={row._id} staff={value} onMutation={stopEditMode} invalidateQueriesHandler={invalidateQueriesHandler} />;
        }

        return <StaffActionCell variant="add" eventDateId={row._id} userId={user._id} onMutation={stopEditMode} invalidateQueriesHandler={invalidateQueriesHandler} />;
      },
    };
  });

  return [
    {
      field: 'date',
      headerName: 'Event Date',
      minWidth: isContainerSmall ? 120 : 170,
      hideable: false,
      valueFormatter: ({ value }: GridValueFormatterParams<string>) => DateService.dayjs(value).format('ddd, MMM Do'),
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams<TEventSchedulingTableRow>) => {
        const date = DateService.dayjsTz(row.dateAsUtc);
        const today = DateService.dayjs();
        const isInPast = date.isBefore(today, 'day');
        const isToday = date.isSame(today, 'day');
        const Icon = eventDateEnumHelpers.type.getIcon(row.type);

        return (
          <Box display="flex" alignItems="center" gap={1} width="100%" justifyContent="space-between" mx={isContainerSmall ? -1 : 0}>
            <Box display="flex" alignItems="center" gap={isContainerSmall ? 0 : 0.5}>
              <Icon fontSize="small" color="disabled" />
              <Typography fontSize="14px" flex={1} width="68px">{date.format('MMM Do')}</Typography>
              <Typography fontSize="12px" fontWeight={500}>{date.format('ddd')}</Typography>
            </Box>
            {!isContainerSmall && (
              <>
                {isInPast && (
                  <Typography color="text.disabled" fontSize="12px">Past</Typography>
                )}
                {isToday && (
                  <Typography color="text.disabled" fontSize="12px">Today</Typography>
                )}
              </>
            )}
          </Box>
        );
      }
    },
    {
      field: 'staffNeeded',
      headerName: 'Need',
      headerAlign: 'left',
      align: 'center',
      width: 64,
      hideable: false,
      editable,
      type: 'number',
      disableColumnMenu: true,
    },
    {
      field: '_addDate',
      headerName: ' ',
      hideable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      width: 32,
      renderCell: ({ row }) => {
        return (
          <IconButton size="small" onClick={() => onAddStaffToEventDate(row._id)} disabled={!editable}>
            <AddOutlined fontSize="small" />
          </IconButton>
        );
      }
    },
    ...staffColumns,
  ];
};

export const EventSchedulingTable: React.FC<EventSchedulingTableProps> = props => {
  const isContainerSmall = useContainerQuery({ containerRef: props.containerRef, query: theme => theme.breakpoints.down('md') });

  const { event, invalidateQueriesHandler } = useEventContext();
  const { isAuthorizedEmployee } = useCurrentUser();
  const allUsers = _.uniqBy(_.flatten(event.dates.map(d => d.staff)).map(s => s.user), s => s._id).sort((a, b) => a.name.localeCompare(b.name));

  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const navigate = useNavigate();
  const [ addStaffToEventDate, setAddStaffToEventDate ] = useState<string | null>(null);
  const [ deleteEventStaffUserId, setDeleteEventStaffUserId ] = useState<string | null>(null);
  const deleteEventStaffMutation = useMutation({
    mutationFn: async (userId: string) => deleteEventStaff(event._id, userId),
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      snackbar.success('User removed');
    },
    onError: () => {
      snackbar.error('User could not be removed');
    }
  });

  const deleteEventStaffModal = useMemo(() => {
    const user = _.flatten(event.dates.map(d => d.staff)).find(staff => staff.user._id === deleteEventStaffUserId)?.user;

    if (!user) {
      return null;
    }

    return (
      <AreYouSureModal
        onClose={() => setDeleteEventStaffUserId(null)}
        onConfirm={() => deleteEventStaffMutation.mutate(user._id)}
        description={`Are you sure you want to remove ${user.name} from all ${event.name} dates?`}
        title="Remove user from event"
        loading={deleteEventStaffMutation.isLoading}
      />
    );
  }, [ deleteEventStaffMutation, deleteEventStaffUserId, event.dates, event.name ]);

  const { data: scheduling = {} } = useStaffSchedulingForEvent(event._id);
  const updateEventDateMutation = useMutation({
    mutationFn: async ({ eventDateId, staffNeeded }: { eventDateId: string; staffNeeded?: number }) => {
      return await updateEventDate(eventDateId, { staffNeeded: processFormValueUpdate.number(staffNeeded) });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) });
      snackbar.success('Event date updated');
    },
    onError: () => {
      snackbar.error('Unable to update event date');
    }
  });

  const processRowUpdate = useCallback(async (newRow: TEventSchedulingTableRow, oldRow: TEventSchedulingTableRow) => {
    if(newRow.staffNeeded !== oldRow.staffNeeded) {
      await updateEventDateMutation.mutateAsync({ eventDateId: newRow._id, staffNeeded: newRow.staffNeeded });
    }

    return newRow;
  }, [ updateEventDateMutation ]);

  const [ displayUsersAvailbaility, setDisplayUsersAvailability ] = useState(!isContainerSmall);
  const [ displayUserIds, setDisplayUserIds ] = useState(allUsers.map(u => u._id));
  const { withActionColumn } = useTableActionColumn({ routeTo: 'events', getNavigateTo: (id, row) => `/${ROUTING_CONFIG.events}/${row.event}/dates/${id}` });
  const columns = useMemo(() => {
    const columns = getEventSchedulingTableColumns({
      event,
      scheduling,
      onAddStaffToEventDate: setAddStaffToEventDate,
      navigate,
      isContainerSmall,
      editable: isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]),
      allUsers: allUsers.filter(u => displayUserIds.includes(u._id)),
      displayUsersAvailbaility,
      ...props,
    });

    if (isContainerSmall) {
      return columns;
    }

    return withActionColumn(columns);
  }, [ event, scheduling, navigate, isContainerSmall, isAuthorizedEmployee, allUsers, displayUsersAvailbaility, props, withActionColumn, displayUserIds ]);

  const getCellClassName = ({ colDef, row }: GridCellParams<TEventSchedulingTableRow>) => {
    if(colDef.field === 'date') {
      const status = getEventDateStaffStatus(row);

      if(status === EventStaffStatusEnum.fulfilled) {
        return 'color-2-1';
      }
      return 'color-3-1';
    }

    return '';
  };

  const daysUntilEvent = event.startDate && -DateService.dayjs().startOf('day').diff(event.startDate.dateAsUtc, 'days');

  const columnMenuDisplay = useCallback((props: any) => {
    const userId = props.colDef.field && staffFieldName.getUserId(props.colDef.field);

    if (!userId) {
      return null;
    }

    return (
      <ListItemButton onClick={() => setDeleteEventStaffUserId(userId)}>
        <Box display="flex" gap={1}>
          <PersonRemove fontSize="small" color="error" />
          <Typography variant="body2" color="error">Remove from all dates</Typography>
        </Box>
      </ListItemButton>
    );
  }, []);

  return (
    <Box>
      <Box mb={3} display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" mb={0.5} mt={isContainerSmall ? 2 : 0} mx={isContainerSmall ? 0.5 : 0}>
          {(!!daysUntilEvent && daysUntilEvent > 0) && <Typography color="text.secondary">It's <b>{daysUntilEvent}</b> days until event.&nbsp;</Typography>}
          <EventDaysSummary event={event} />
        </Box>
        <Box>
          <Typography color="text.secondary">Display Staff:</Typography>
          <ToggleButtonGroup
            value={displayUserIds}
            onChange={(_, value) => setDisplayUserIds(value)}
            color="primary"
          >
            {allUsers.map(user => (
              <ToggleButton key={user._id} value={user._id}>
                <Box display="flex" gap={1}>
                  <Avatar src={user.profileImageUrl} sx={{ width: 25, height: 25 }}>{user.name && getUserInitialsForAvatar(user.name)}</Avatar>
                  {user.name}
                </Box>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <FormControlLabel
          control={<Switch checked={displayUsersAvailbaility} onChange={() => setDisplayUsersAvailability(p => !p)} />}
          label="Show users availability"
        />
      </Box>
      {addStaffToEventDate && (
        <AddStaffModal
          userId={props.userId}
          eventDateId={addStaffToEventDate}
          onSuccess={(userId) => setDisplayUserIds(ids => [ ...ids, userId ])}
          onClose={() => setAddStaffToEventDate(null)}
          invalidateQueriesHandler={props.invalidateQueriesHandler}
        />
      )}
      {deleteEventStaffModal}
      <Table
        rows={event.dates}
        columns={columns}
        initialState={{ pinnedColumns: { left: [ 'action', 'date', 'staffNeeded', '_addDate' ] }, }}
        getRowId={(x) => x._id}
        processRowUpdate={processRowUpdate}
        isCellEditable={(params: GridCellParams<TEventSchedulingTableRow>) => {
          const { field, row } = params;

          const userId = staffFieldName.getUserId(field);

          if (userId) {
            const hasConflictEvent = scheduling[row._id]?.[userId]?.conflictEvent;
            const isStaff = row.staff.some(s => s.user._id === userId);

            if (hasConflictEvent && !isStaff) {
              return false;
            }
          }

          return true;
        }}
        disableRowSelectionOnClick
        slots={{ toolbar: null, columnMenu: columnMenuDisplay }}
        getCellClassName={getCellClassName}
        showCellVerticalBorder
        density="compact"
        sx={{
          maxHeight: 'unset',
          minHeight: 600,
          '& .staff-cell': {
            cursor: 'pointer'
          }
        }}
      />
    </Box>
  );
};