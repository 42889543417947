import { DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, InputLabel, Theme, Tooltip, Typography } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import React, { useMemo } from 'react';

export type FileUploadProps = {
  name: string;
};

export const FileUpload: React.FC<FileUploadProps> = ({ name }) => {

  const [ field, meta, helpers ] = useField(name);

  const fileName = useMemo(() => {
    if (!field.value) {
      return null;
    }

    return field.value.name;
  }, [ field.value ]);


  const backgoundColorFunction = (theme: Theme) => field.value ?
    theme.palette.success.background : theme.palette.background.default;

  const borderColorFunction = (theme: Theme) => {
    if (field.value) return theme.palette.success.main;
    if (meta.touched && meta.error) return theme.palette.error.main;
    return  theme.palette.grey[400];
  };

  const colorFunction = (theme: Theme) => {
    if (field.value) return theme.palette.success.main;
    if (meta.touched && meta.error) return theme.palette.error.main;
    return undefined;
  };

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      helpers.setValue(e.target.files[0]);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
      >
        <Box
          width="100%"
          padding={1.8}
          border={1}
          borderRadius={1}
          textAlign="center"
          overflow="-moz-hidden-unscrollable"
          borderColor={borderColorFunction}
          bgcolor={backgoundColorFunction}
          color={colorFunction}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              borderColor: (theme) => (meta.touched && meta.error) ?  undefined : theme.palette.grey[900]
            },
          }}

        >
          <InputLabel
            htmlFor="file-upload"
            sx={{
              width: '100%'
            }}
          >
            { fileName ?? 'Choose a file...' }
          </InputLabel>
          <input
            id="file-upload"
            style={{
              display: 'none'
            }}
            type="file"
            onChange={fileChangeHandler}
          />
        </Box>
        <Box ml={1}>
          <Tooltip title="Remove file">
            <IconButton
              disabled={!field.value}
              onClick={() => helpers.setValue(null)}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Typography
        mt={1}
        sx={{
          marginTop: -0.6,
          color: (theme) => theme.palette.error.main,
          fontSize: 12.5,
        }}
      >
        <ErrorMessage name={name} />
      </Typography>
    </Box>
  );
};
