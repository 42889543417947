import { EditOutlined, OpenInNewOutlined } from '@mui/icons-material';
import {
  Box, Divider, Grid, GridProps, IconButton, Link, LinkProps, Skeleton, SkeletonProps, Typography, TypographyProps,
} from '@mui/material';
import { YesNoUnknownIconSwitch } from 'components/IconSwitch';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import { YesNoUnknownValueType } from 'types';

type EditableConfig = {
  onClick: () => void;
  renderEditAction?: (params: { onClick: () => void }) => React.ReactNode;
};

export type CardContentGridItemProps = {
  title?: string;
  value?: React.ReactNode | number;
  type?: 'full' | 'half';
  dividerTop?: true;
  content?: React.ReactNode;
  hide?: boolean;
  typographyProps?: TypographyProps;
  loading?: boolean;
  skeletonProps?: SkeletonProps;
  isUnassigned?: boolean;
  unassignedText?: string;
  editableConfig?: EditableConfig;
} | {
  title: string;
  value: YesNoUnknownValueType | undefined;
  type?: 'yesNoUnknown';
  dividerTop?: true;
  content?: null;
  hide?: boolean;
  typographyProps?: TypographyProps;
  loading?: boolean;
  skeletonProps?: SkeletonProps;
  isUnassigned?: boolean;
  unassignedText?: never;
  editableConfig?: EditableConfig;
};

// export type CardContentGridItemProps = {
//     title: string;
//     dividerTop?: true;
//     hide?: boolean;
//     loading?: boolean;
//     skeletonProps?: SkeletonProps;
// } & ({
//     type?: 'full' | 'half';
//     value?: React.ReactNode | number;
//     content?: React.ReactNode;
//     typographyProps?: TypographyProps;
// } | {
//     type?: 'yesNoUnknown';
//     value: YesNoUnknownValueType | undefined;
// })

export type CardContentGridProps = {
  items: CardContentGridItemProps[];
  gridProps?: GridProps;
};

const ellipsisSx: TypographyProps['sx'] = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export type CardContentGridItemTitleProps = {
  children?: string;
};

export const CardContentGridItemTitle: React.FC<CardContentGridItemTitleProps> = props => {

  return <Typography variant="subtitle2" fontWeight={400} component="p">{props.children}</Typography>;
};

const CardContentGridItem: React.FC<CardContentGridItemProps> = ({
  title,
  value,
  type,
  dividerTop,
  content,
  hide = false,
  typographyProps,
  loading,
  skeletonProps,
  isUnassigned,
  unassignedText = 'Unassigned',
  editableConfig
}) => {
  const isFull = type === 'full' || type === 'yesNoUnknown';

  const displayContent = useMemo(() => {
    if (content) {
      return content;
    }

    const titleDisplay = <CardContentGridItemTitle>{title}</CardContentGridItemTitle>;

    if (loading) {
      <div>
        {titleDisplay}
        <Skeleton width={40} {...skeletonProps} />
      </div>;
    }

    if (type === 'yesNoUnknown') {
      return (
        <Box display="flex" alignItems="center" gap={2}>
          <YesNoUnknownIconSwitch value={value ?? null} />
          {titleDisplay}
        </Box>
      );
    }

    if (value === undefined || value === null || value === '' || isUnassigned) {
      return (
        <div>
          {titleDisplay}
          <Typography color="warning.main" fontWeight={500}>{unassignedText}</Typography>
        </div>
      );
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return (
        <Box>
          {titleDisplay}
          <Typography
            {...typographyProps}
            sx={{
              ...(!isFull ? ellipsisSx : {}),
              ...typographyProps?.sx,
            }}
            fontWeight={500}
          >
            {value}
          </Typography>
        </Box>
      );
    }


    return (
      <div>
        {titleDisplay}
        {value}
      </div>
    );
  }, [ content, isFull, isUnassigned, loading, skeletonProps, title, type, typographyProps, unassignedText, value ]);

  const editAction = useMemo(() => {
    if (editableConfig) {
      if (editableConfig.renderEditAction) {
        return editableConfig.renderEditAction({ onClick: editableConfig.onClick });
      }

      return (
        <IconButton onClick={() => editableConfig.onClick()}>
          <EditOutlined />
        </IconButton>
      );
    }

    return null;
  }, [ editableConfig ]);

  if (hide) {
    return null;
  }

  return (
    <Grid item xs={isFull ? 12 : 6} md={isFull ? 12 : 6} key={title}>
      {dividerTop && <Box mb={2}><Divider /></Box>}
      <Box display="flex" alignItems="center">
        <Box flex={1} minWidth={0}>
          {displayContent}
        </Box>
        {editAction}
      </Box>
    </Grid>
  );
};

export const CardContentGrid: React.FC<CardContentGridProps> = ({ items, gridProps }) => {

  return (
    <Grid container spacing={2} {...gridProps}>
      {items.map((props, idx) => <CardContentGridItem key={idx} {...props} />)}
    </Grid>
  );
};

export type CardContentContainerProps = {
  maxHeight?: number;
  children: React.ReactNode;
};

export const CardContentScrollContainer: React.FC<CardContentContainerProps> = props => {
  return (
    <Box maxHeight={props.maxHeight ?? 300} className="overscroll" pr={1} overflow="auto" sx={{ '& > p': { margin: 0 } }}>
      {typeof props.children === 'string' ? <Typography whiteSpace="pre-line">{props.children}</Typography> : props.children}
    </Box>
  );
};

export type CardContentMarkdownProps = Omit<CardContentContainerProps, 'children'> & {markdown?: string};

export const CardContentMarkdown: React.FC<CardContentMarkdownProps> = ({ markdown, ...props }) => {
  return (
    <CardContentScrollContainer {...props}>
      <Markdown>
        {markdown}
      </Markdown>
    </CardContentScrollContainer>
  );
};

export type CardContentLinkProps = LinkProps & {
  title: string;
  openInNew?: boolean;
};

export const CardContentLink: React.FC<CardContentLinkProps> = ({  title, openInNew = false, ...props }) => {
  return (
    <Link
      {...props}
      target={openInNew ? '_blank' : undefined}
      rel={openInNew ? 'noopener' : undefined}
      underline="hover"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <OpenInNewOutlined fontSize="small" />
        <Typography
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
          variant={props.variant}
        >
          {title}
        </Typography>
      </Box>
    </Link>
  );
};
