import { Box, Typography } from '@mui/material';
import { Fragment } from 'react';
import { ShowTheseFieldsConfig } from './types';

export type ShowTheseFieldsEventContentProps<Field extends string, Group extends string, Resource, Extra = undefined> = {
  title?: string;
  showTheseFields: Field[];
  showTheseFieldsConfig: ShowTheseFieldsConfig<Field, Group, Resource, Extra>[];
  eventDate: Resource;
  extra: Extra;
  group: Resource[];
};

export const ShowTheseFieldsEventContent = <Field extends string, Group extends string, Resource extends unknown, Extra extends unknown = undefined>(props: ShowTheseFieldsEventContentProps<Field, Group, Resource, Extra>) => {
  return (
    <Box whiteSpace="nowrap" color="text.primary">
      {props.title && <Typography fontWeight={500} fontSize="small" color="text.primary">{props.title}</Typography>}
      {props.showTheseFieldsConfig.map((config) => {
        if (!props.showTheseFields.includes(config.field)) {
          return null;
        }

        const { disableTypography, field, getContent } = config;
        const value = getContent(props.eventDate, props.group, props.extra);

        if (disableTypography) {
          return <Fragment key={field}>{value}</Fragment>;
        }
        return <Typography key={field} fontSize="small" color="text.secondary">{value}</Typography>;
      })}
    </Box>
  );
};