
import { getMeThunk } from './auth.slice';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';

export const useMe = (initialFetch: boolean = true) => {
  const dispatch = useAppDispatch();
  const { loading, me } = useAppSelector((state) => state.auth);

  const refetchGetMe = useCallback(async () => {
    await dispatch(getMeThunk());
  }, [ dispatch ]);

  useEffect(() => {
    if (!initialFetch) return;
    refetchGetMe();
  }, [ refetchGetMe, initialFetch ]);

  return {
    me,
    loading: loading === 'pending',
    authLoadingState: loading,
    refetchGetMe,
  };
};
