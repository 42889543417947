import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resizeEventDate } from 'api/actions';
import { AreYouSureModal, useAlertSnackbar, useEventContext } from 'components';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useMemo } from 'react';
import { DateService } from 'services';

export type ConfirmResizeModalProps = {
  onClose: () => void;
  resizeObj: EventResizeDoneArg;
};

export const ConfirmResizeModal: React.FC<ConfirmResizeModalProps> = ({ resizeObj, ...props }) => {
  const { event } = useEventContext();
  const { endDelta, oldEvent, startDelta } = resizeObj;
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const resizeMutation = useMutation({
    mutationFn: async () => {

      if (endDelta.days) {
        const baseDate = DateService.dayjs(resizeObj.oldEvent.end).subtract(1, 'day');
        const eventDate = event.dates.find(d => baseDate.isSame(DateService.dayjsTz(d.dateAsUtc), 'day'));

        if (eventDate) {
          const interval = endDelta.days;

          await resizeEventDate(eventDate._id, { interval });
        }
      }

      if (startDelta.days) {
        const baseDate = DateService.dayjs(resizeObj.oldEvent.start);
        const eventDate = event.dates.find(d => baseDate.isSame(d.dateAsUtc, 'day'));


        if (eventDate) {
          const interval = startDelta.days;

          await resizeEventDate(eventDate._id, { interval, reverse: true });
        }
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);
      snackbar.success('Event dates updated');
      props.onClose();
    },
    onError: () => {
      snackbar.error('Unable to update event dates');
    },
  });


  const confirmationText = useMemo(() => {
    if (endDelta.days) {
      const baseDate = DateService.dayjs(oldEvent.end).subtract(1, 'day');

      if (endDelta.days > 0) {
        if (endDelta.days === 1) return (
          <Typography>
            This will add a date on&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.add(1, 'day').format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
        return (
          <Typography>
            This will add dates from&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.add(1, 'day').format('MM/DD/YYYY')}
            </span>
            &nbsp;to&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.add(endDelta.days, 'day').format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
      } else {
        if (endDelta.days === -1) return (
          <Typography>
            This will remove a date on&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
        return (
          <Typography>
            This will remove dates from&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.subtract(-(endDelta.days + 1), 'day').format('MM/DD/YYYY')}
            </span>
            &nbsp;to&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
      }
    }
    if (startDelta.days) {

      const baseDate = DateService.dayjs(oldEvent.start);

      if (startDelta.days > 0) {
        if (startDelta.days === 1) return (
          <Typography>
            This will remove a date on&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
        return (
          <Typography>
            This will remove dates from&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.format('MM/DD/YYYY')}
            </span>
            &nbsp;to&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.add(startDelta.days - 1, 'day').format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
      } else {
        if (startDelta.days === -1) return (
          <Typography>
            This will add a date on&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.subtract(1, 'day').format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
        return (
          <Typography>
            This will add dates from&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.subtract(-startDelta.days, 'day').format('MM/DD/YYYY')}
            </span>
            &nbsp;to&nbsp;
            <span style={{ fontWeight: 700 }}>
              {baseDate.subtract(1, 'day').format('MM/DD/YYYY')}.
            </span>
          </Typography>
        );
      }
    }
  }, [ endDelta.days, oldEvent.end, oldEvent.start, startDelta.days ]);

  return (
    <AreYouSureModal
      {...props}
      onConfirm={resizeMutation.mutate}
      description={confirmationText}
      loading={resizeMutation.isLoading}
    />
  );
};
