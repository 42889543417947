import React, { useEffect, useMemo, useState } from 'react';
import { useAnalyticsPageContext } from 'pages/Analytics/analytics.context';
import { AnalyticsTimelineChart, AnalyticsTimelineChartWithDataProps, getInitialAnalyticsTimelineChartTimePeriodForDateRange } from 'components';
import { DateService, currencyFormatter } from 'services';
import { useSalesAnalyticsWithCompare } from 'queries';
import { GetSalesAnalyticsDataWithCompareResponse } from 'api/actions';

const salesAnalyticsWithComparePlaceholder: GetSalesAnalyticsDataWithCompareResponse['data'] = {
  labels: [],
  data: [],
  compareData: [],
};

export const TotalSalesChart = () => {
  const { sales, dateRange, compareDateRange } = useAnalyticsPageContext();
  const [ timePeriod, setTimePeriod ] = useState(getInitialAnalyticsTimelineChartTimePeriodForDateRange(dateRange));
  const timezone = DateService.dayjs.tz.guess();
  const { data = salesAnalyticsWithComparePlaceholder, isLoading } = useSalesAnalyticsWithCompare({
    dateRange: { start: dateRange.start.format('YYYY-MM-DD'), end: dateRange.end.format('YYYY-MM-DD') },
    compareDateRange: compareDateRange ? { start: compareDateRange.start.format('YYYY-MM-DD'), end: compareDateRange.end.format('YYYY-MM-DD') } : null,
    timePeriod,
    timezone,
  });
  const salesRevenue = currencyFormatter.format(sales.reduce((r, sale) => sale.salePrice + r, 0));

  useEffect(() => {
    setTimePeriod(getInitialAnalyticsTimelineChartTimePeriodForDateRange(dateRange));
  }, [ dateRange ]);

  const datasets = useMemo(() => {
    const datasets: AnalyticsTimelineChartWithDataProps['data']['datasets'] = [ { data: data.data, dateRange } ];

    if (data.compareData && compareDateRange) {
      datasets.push({ data: data.compareData, dateRange: compareDateRange, referenceIndex: 0 });
    }

    return datasets;
  }, [ compareDateRange, data.compareData, data.data, dateRange ]);

  return (
    <AnalyticsTimelineChart
      header="Total Sales"
      dateRange={dateRange}
      subheader={salesRevenue}
      data={{ labels: data.labels, datasets }}
      loading={isLoading}
      timePeriod={timePeriod}
      setTimePeriod={setTimePeriod}
    />
  );
};