import { EventSchedulingTable, PageContentContainer, useEventContext } from 'components';
import React, { useMemo, useRef } from 'react';
import { EventPageHeader } from 'pages/Event/components';
import { useLocation } from 'react-router-dom';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { Box } from '@mui/material';

export const EventScheduling = () => {
  const location = useLocation();
  const { event } = useEventContext();
  const ref = useRef<HTMLElement>(null);

  const prevBreadcrumbs = useMemo(() => {
    const prevBreadcrumbs = [
      { link: ROUTING_CONFIG.events, text: 'Event' },
      { link: `${ROUTING_CONFIG.events}/${event._id}`, text: event.name }
    ];

    if (location.pathname.includes('dates')) {
      prevBreadcrumbs.push({ link: `${ROUTING_CONFIG.events}/${event._id}/${ROUTING_CONFIG.dates}`, text: 'Event Dates' });
    }

    return prevBreadcrumbs;
  }, [ event._id, event.name, location.pathname ]);


  const invalidateQueriesHandler = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) });
  };

  return (
    <PageContentContainer
      breadcrumbs={{
        prev: prevBreadcrumbs,
        current: 'Scheduling'
      }}
      header={<EventPageHeader pageTitle="Scheduling Page" />}
      maxWidth="lg"
    >
      <Box ref={ref}>
        <EventSchedulingTable invalidateQueriesHandler={invalidateQueriesHandler} containerRef={ref} />
      </Box>
    </PageContentContainer>
  );
};