export const enum EventDateTypeEnum {
  regular = 'regular',
  setup = 'setup',
  breakdown = 'breakdown',
  travel = 'travel',
}

export const enum EventDateLodgingEnum {
  lodging = 'lodging',
  departure = 'departure',
  unknown = 'unknown',
}

export const enum EventDateSetupEnum {
  lockUp = 'lockUp',
  breakDown = 'breakDown',
  unknown = 'unknown',
}