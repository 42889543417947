import { InventoryBatchStatusEnum, InventoryBatchTypeEnum } from 'api/resources';
import { EnumHelpers, EnumHelpersWithIcon } from './types';
import { PrecisionManufacturingOutlined, QuestionMarkOutlined, SyncAltOutlined } from '@mui/icons-material';

const status: EnumHelpers<InventoryBatchStatusEnum> = {
  enumValues: [
    InventoryBatchStatusEnum.open,
    InventoryBatchStatusEnum.review,
    InventoryBatchStatusEnum.canceled,
    InventoryBatchStatusEnum.closed,
  ],
  getLabel: value => {
    switch (value) {
      case InventoryBatchStatusEnum.open:
        return 'Open';
      case InventoryBatchStatusEnum.review:
        return 'Review';
      case InventoryBatchStatusEnum.canceled:
        return 'Canceled';
      case InventoryBatchStatusEnum.closed:
        return 'Closed';
      default:
        return 'Unknown';
    }
  },
  getColor: value => {
    switch (value) {
      case InventoryBatchStatusEnum.open:
        return 'success';
      case InventoryBatchStatusEnum.review:
        return 'info';
      case InventoryBatchStatusEnum.canceled:
        return 'error';
      case InventoryBatchStatusEnum.closed:
        return 'primary';
      default:
        return 'unknown';
    }
  }
};

const type: EnumHelpersWithIcon<InventoryBatchTypeEnum> = {
  enumValues: [
    InventoryBatchTypeEnum.picking,
    InventoryBatchTypeEnum.production,
  ],
  getLabel: value => {
    switch (value) {
      case InventoryBatchTypeEnum.picking:
        return 'Picking';
      case InventoryBatchTypeEnum.overstockPicking:
        return 'Overstock picking';
      case InventoryBatchTypeEnum.production:
        return 'Production';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'primary',
  getIcon: value => {
    switch (value) {
      case InventoryBatchTypeEnum.picking:
        return SyncAltOutlined;
      case InventoryBatchTypeEnum.overstockPicking:
        return SyncAltOutlined;
      case InventoryBatchTypeEnum.production:
        return PrecisionManufacturingOutlined;
      default:
        return QuestionMarkOutlined;
    }
  }
};

export const inventoryBatchEnumHelpers = {
  status,
  type,
};