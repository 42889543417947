import { GetEventResponse, GetEventsResponse } from 'api/actions';
import { FullCalendar } from 'components/FullCalendar/FullCalendar.component';
import { EventApi, EventContentArg } from 'types';
import { eventOrder, getEventDateGroups, getEventDatesStartAndEnd } from '../helpers';
import { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import { DateObjectType, DateService } from 'services';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { FullCalendarEventContentContainer } from 'components/FullCalendar/EventContent';

export type EventDatesFullCalendarProps = {
  events: GetEventsResponse['data'];
  event: GetEventResponse['data'];

  renderAddDateModal: (date: DateObjectType, onClose: () => void) => React.ReactNode;
  renderResizeModal: (resizeObj: EventResizeDoneArg, onClose: () => void) => React.ReactNode;
};

const checkForTeamConflict = (event1: GetEventsResponse['data'][number], event2: GetEventsResponse['data'][number]) => {
  if (event1.team && event2.team && event1.team._id === event2.team._id) {
    return true;
  }

  return false;
};

type ExtendedProps = {
  event: GetEventsResponse['data'][number];
  eventDate: GetEventsResponse['data'][number]['dates'][number];
  group: GetEventsResponse['data'][number]['dates'];
};

const convertEventsToCalendarEvents = (events: GetEventsResponse['data'], currentEventId: string) => {
  return events.reduce((allEventDates, event) => {
    if (event.dates.length) {
      const groups = getEventDateGroups(event.dates);

      const events = groups.map((group): EventInput => {
        const { start, end } = getEventDatesStartAndEnd(group);

        return {
          title: event.name,
          start: start.toDate(),
          end: end.toDate(),
          allDay: true,
          id: group[0]._id,
          extendedProps: {
            eventDate: group[0],
            event,
            group: group,
          },
          editable: event._id === currentEventId,
        };
      });

      return [ ...allEventDates, ...events ];
    }
    return allEventDates;
  }, [] as EventInput[]);
};

export const EventDatesFullCalendar: React.FC<EventDatesFullCalendarProps> = props => {
  const snackbar = useAlertSnackbar();
  const [ resizeObj, setResizeObj ] = useState<EventResizeDoneArg | null>(null);
  const [ selected, setSelected ] = useState<DateObjectType | null>(null);

  const onEventOverlap = (stillEvent: EventApi<ExtendedProps>, movingEvent: EventApi<ExtendedProps>) => {
    if (!movingEvent) {
      return true;
    }

    const teamConflict = checkForTeamConflict(movingEvent.extendedProps.event, stillEvent.extendedProps.event);

    if (teamConflict) {
      return false;
    }

    return true;
  };

  const onDateClick = (arg: DateClickArg) => {
    const conflictEvent = props.events.find((externalEvent) => {
      const teamConflict = checkForTeamConflict(props.event, externalEvent);

      return teamConflict && externalEvent.dates.some((eventDate) => {
        return DateService.dayjsTz(eventDate.dateAsUtc).isSame(arg.date, 'day');
      });
    });

    if (conflictEvent) {
      if (conflictEvent._id === props.event._id) {
        snackbar.error('This date is already added to this event');
      } else {
        snackbar.error('This date has a team conflict with ' + conflictEvent.name);
      }
      return;
    }

    setSelected(DateService.dayjs(arg.date));
  };

  const getEventContent = (arg: EventContentArg<ExtendedProps>) => {
    const { event } = arg.event.extendedProps;
    const isEvent = event._id === props.event._id;
    const isConflict = props.event.team && props.event.team._id === event.team?._id;

    return (
      <FullCalendarEventContentContainer
        isStart={arg.isStart}
        isEnd={arg.isEnd}
        backgroundFunc={theme => {
          if (isEvent) {
            return theme.palette.primary.main;
          }
          if (isConflict) {
            return theme.palette.error.main;
          }
          return theme.palette.grey[600];
        }}
      >
        <Typography fontWeight={500} fontSize="small" whiteSpace="nowrap" color="text.primary">{arg.event.title}</Typography>
        <Typography fontSize="small" color="text.secondary">{event.team?.name}</Typography>
      </FullCalendarEventContentContainer>
    );
  };

  return (
    <>
      {selected && props.renderAddDateModal(selected, () => setSelected(null))}
      {resizeObj && props.renderResizeModal(resizeObj, () => setResizeObj(null))}
      <FullCalendar
        calendarsConfig={[
          {
            id: 'eventDatesCalendar',
            plugins: [ dayGridPlugin, interactionPlugin ],
            initialView: 'dayGridMonth',
            headerToolbar: false,
            events: convertEventsToCalendarEvents(props.events, props.event._id),
            eventDrop: ({ revert }) => revert(),
            dayCellClassNames: 'cursor-crosshair',
            dateClick: (info) => onDateClick(info),
            eventOverlap: onEventOverlap,
            eventResize: (info) => setResizeObj(info),
            eventContent: getEventContent,
            eventOrder: (a: EventContentArg<ExtendedProps>['event'], b: EventContentArg<ExtendedProps>['event']) => eventOrder(a, b),
            eventResizableFromStart: true,
            height: 850,
          }
        ]}
      />
    </>
  );
};