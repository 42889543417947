import { Box, Popover, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { YearInputValue, YearInputValueOption } from 'components';
import React, { useRef, useState } from 'react';
import { DateObjectType, DateService } from 'services';

export type YearFilterProps<V extends string>  = {
  value: YearInputValue<V>;
  label: string;
  onChange: (value: YearInputValue<V>) => void;
  options: Omit<YearInputValueOption<any, V>, 'predicate'>[];
  modalZIndex?: number;
  readOnly?: boolean;
};

export const YearFilter = <V extends string>(props: YearFilterProps<V>) => {
  const [ fallback, setFallback ] = useState(props.value);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLElement | null>(null);
  const isYear = typeof props.value === 'number';

  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;

  const onYearChange = (year: number | null) => {
    if (year) {
      props.onChange(year);
      setFallback(year);
      setAnchorEl(null);
    } else {
      props.onChange(fallback);
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        sx={{ zIndex: props.modalZIndex }}
      >
        <Box my={2}>
          <StaticDatePicker
            views={[ 'year' ]}
            view="year"
            onChange={(date: DateObjectType | null) => onYearChange(date ? DateService.dayjs(date).year() : null)}
            value={typeof props.value === 'number' ? DateService.dayjs().year(props.value) : undefined}
            slots={{ toolbar: undefined }}
            sx={{ '& .MuiPickersLayout-actionBar': { display: 'none' } }}
          />
        </Box>
      </Popover>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography color={(theme) => theme.palette.grey[700]}>
          {props.label} {props.readOnly && <i>(Read Only)</i>}
        </Typography>
        <Box maxWidth={300}>
          <ToggleButtonGroup value={props.value} color="primary" disabled={props.readOnly}>
            {props.options.map(option => (
              <ToggleButton
                key={option.value}
                value={option.value}
                onClick={() => props.onChange(option.value)}
              >
                {option.label}
              </ToggleButton>
            ))}
            <ToggleButton
              value=""
              ref={(r) => ref.current = r}
              selected={isYear}
              onClick={() => {
                setAnchorEl(ref.current);
              }}
            >
              Year{isYear ? ` (${props.value})` : ''}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </>
  );
};
