import { ApiResource } from './resources.types';

export type Product = ApiResource<{
  name: string;
  sku: string;
  shopifyProductId?: number;
  price: number;
  category: string;
  notes?: string;
  imageUrl?: string;
  availability?: ProductAvailability;
  needsAttention: boolean;
  preperation?: ProductPreperation;
  genres: {
    genre: string;
    order: number;
  }[];
  salesPerEventDate: number;
  autoBaseline: number;
  manualBaseline?: number;
  activeBaselineType: ProductActiveBaselineTypeEnum;
  createdBy?: string;
}>;

export type ProductAvailability = {
  website?: boolean;
  events?: boolean;
  uploaded?: boolean;
};

export type ProductPreperation = {
  rasterLayer?: boolean;
  centerLabel?: boolean;
  png?: boolean;
  dmc?: boolean;
  dwg?: boolean;
  dwgCreator?: string; // TODO: user type
  websitePngs?: boolean;
  divider?: boolean;
};

export const enum ProductActiveBaselineTypeEnum {
  manual = 'manual',
  auto = 'auto',
}