import { authAxiosRequest } from 'api/axios';
import { User, Vehicle, VehicleFile, VehicleFuelTypeEnum, VehicleMaintenanceLog } from 'api/resources';
import { DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetVehiclesResponse = {
  data: ResourceWithPopulated<Vehicle, {
    maintenanceLog: ResourceWithPopulated<VehicleMaintenanceLog, {
      assignees: PickFromResource<User, 'name'>[];
    }>[];
  }>[];
};

export const getVehicles = () => {
  return authAxiosRequest<GetVehiclesResponse>('/api/vehicles');
};

export type GetVehicleResponse = {
  data: GetVehiclesResponse['data'][number];
};

export const getVehicle = (id: string) => {
  return authAxiosRequest<GetVehicleResponse>(`/api/vehicles/${id}`);
};

export type CreateVehicleInput = {
  name: string;
};

export const createVehicle = (data: CreateVehicleInput) => {
  return authAxiosRequest<GetVehicleResponse>('/api/vehicles', { method: 'POST', data });
};

export type UpdateVehicleInput = Partial<DeepOptionalToNull<{
  name: string;
  imageUrl?: string;
  drivable?: boolean;
  year?: number;
  make?: string;
  model?: string;
  length?: number;
  cargoLength?: number;
  grossWeight?: number;
  towCapabilities?: string;
  towCapacity?: number;
  fuelType?: VehicleFuelTypeEnum;
  mpg?: number;
  mileage?: number;
}>>;

export const updateVehicle = (id: string, data: UpdateVehicleInput) => {
  return authAxiosRequest(`/api/vehicles/${id}`, { method: 'PUT', data });
};

export type CreateVehicleFileInput = {
  name: string;
  type: VehicleFile['type'];
  url: string;
};

export const createVehicleFile = (vehicleId: string, data: CreateVehicleFileInput) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/file`, { method: 'POST', data });
};

export type UpdateVehicleFileInput = Partial<Omit<CreateVehicleFileInput, 'url'>>;

export const updateVehicleFile = (vehicleId: string, id: string, data: UpdateVehicleFileInput) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/file/${id}`, { method: 'PUT', data });
};

export const deleteVehicleFile = (vehicleId: string, id: string) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/file/${id}`, { method: 'DELETE' });
};

export type CreateVehicleMaintenanceLogInput = {
  title: string;
  description?: string;
  date?: string;
  assignees: string[];
};

export const createVehicleMaintenanceLog = (vehicleId: string, data: CreateVehicleMaintenanceLogInput) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/maintenance_log`, { method: 'POST', data });
};

export type UpdateVehicleMaintenanceLogInput = Partial<DeepOptionalToNull<CreateVehicleMaintenanceLogInput> & { completed: boolean }>;

export const updateVehicleMaintenanceLog = (vehicleId: string, id: string, data: UpdateVehicleMaintenanceLogInput) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/maintenance_log/${id}`, { method: 'PUT', data });
};

export const deleteVehicleMaintenanceLog = (vehicleId: string, id: string) => {
  return authAxiosRequest(`/api/vehicles/${vehicleId}/maintenance_log/${id}`, { method: 'DELETE' });
};
