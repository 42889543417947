import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEventFeedback, GetEventFeedbackResponse } from 'api/actions/eventFeedback';
import { DeleteResourceModal, useEventContext } from 'components';
import { QUERY_KEY } from 'queries/query-keys';

export type DeleteEventFeedbackProps = {
  eventFeedback: GetEventFeedbackResponse['data'][number];
  onClose: () => void;
};

export const DeleteEventFeedbackModal: React.FC<DeleteEventFeedbackProps> = ({ eventFeedback, onClose }) => {
  const { event } = useEventContext();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: () => deleteEventFeedback(event._id, eventFeedback._id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_FEEDBACK(event._id) });
    }
  });

  return (
    <DeleteResourceModal
      onClose={onClose}
      onDelete={deleteMutation.mutate}
      deleteButtonText="Delete Event Feedback"
      description="Please, confirm deleting the Event Feedback form."
      loading={deleteMutation.isLoading}
    />
  );
};