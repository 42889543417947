import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Table, TeamCell } from 'components';
import { useMemo } from 'react';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { useProductsPageContext } from 'contexts';

const createInventoryTableColumns = (teams:  GetTeamsResponse['data']): GridColDef<GetProductsResponse['data'][number]>[] => {
  const inventoryColumns: GridColDef<GetProductsResponse['data'][number]>[] = teams.map((team) => {
    return {
      field: team._id,
      headerName: team.name,
      width: 100,
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderHeader: () => <TeamCell team={team} />,
      valueGetter: (params) => {
        const { row, field: teamId } = params;

        if (!row.availability?.events && !team.isWarehouse) {
          return undefined;
        }

        return row.stocks[teamId].quantity;
      },
      renderCell: (params: GridRenderCellParams<GetProductsResponse['data'][number], number | undefined>) => {
        if (params.value === undefined) {
          return (
            <Typography
              variant="body2"
              color="text.secondary"
              component="i"
            >
              Not at events
            </Typography>
          );
        }

        return params.value;
      },
    };
  });

  return [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      hideable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: ({ value }) => new Date(value),
      type: 'date',
      width: 150,
    },
    ...inventoryColumns,
  ];
};

export const InventoryTable: React.FC = () => {
  const { teams, getProductsSortedAlphabetically, loading } = useProductsPageContext();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const inventoryTableColumns = useMemo(() => createInventoryTableColumns(teams), [ teams ]);

  return (
    <Table
      rows={products}
      columns={inventoryTableColumns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            createdAt: false,
          }
        }
      }}
      loading={loading}
      getRowId={(x) => x._id}
      disableRowSelectionOnClick
    />
  );
};
