import { Box, Typography } from '@mui/material';
import React from 'react';
import { ColorCodeKeys } from './ColorCodeKeys.component';
import { ColorCodeConfig } from './colorCode';

export type ColorCodeActionProps<Option extends string, Arg extends unknown> = {
  colorCodeOption: Option | null;
  colorCodeConfigHash: Record<Option, ColorCodeConfig<string, Arg>>;
};

export const ColorCodeAction  = <Option extends string, Arg extends unknown>({ colorCodeOption, colorCodeConfigHash }: ColorCodeActionProps<Option, Arg>) => {
  const colorCodeLabel = colorCodeOption ? colorCodeConfigHash[colorCodeOption].label : null;
  const colorCodeKeys = colorCodeOption ? colorCodeConfigHash[colorCodeOption].colorKeyConfigs : null;

  return (
    <>
      <Typography variant="body2">{colorCodeLabel ?? 'No Color Code'}</Typography>
      {!!colorCodeKeys && (
        <Box pt={1}>
          <ColorCodeKeys colorKeys={colorCodeKeys} />
        </Box>
      )}
    </>
  );
};
