import { AlertProps, Button } from '@mui/material';
import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';

export type AlertSnackbarProviderProps = {
  children: React.ReactNode;
};

export type AlertSnackbarAlert = {
  severity: AlertProps['severity'];
  message: string;
};

export const AlertSnackbarProvider: React.FC<AlertSnackbarProviderProps> = ({ children }) => {

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      {children}
    </SnackbarProvider>
  );
};

export const useAlertSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setNewAlertHandler = (arg: AlertSnackbarAlert) => {
    enqueueSnackbar(arg.message, {
      variant: arg.severity,
      action: (key) => (
        <Button size="small" onClick={() => closeSnackbar(key)} color="inherit" variant="outlined">
            Dismiss
        </Button>
      ),

    //   autoHideDuration: 3000,
    });
  };

  const alert = {
    success: (message: string) => setNewAlertHandler({ message, severity: 'success' }),
    error: (message: string) => setNewAlertHandler({ message, severity: 'error' }),
    warning: (message: string) => setNewAlertHandler({ message, severity: 'warning' }),
    info: (message: string) => setNewAlertHandler({ message, severity: 'info' }),
  };

  return alert;
};