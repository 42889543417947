import React from 'react';
import { StickyStackSetValueContext, useStickyStackContext } from './StickyStackProvider';


export type StickyStackItemProps = {
  order: number;
  placement: 'bottom' | 'top';
  children: React.ReactElement;
};


export const StickyStackItem: React.FC<StickyStackItemProps> = (props) => {
  const stickyStack = useStickyStackContext();
  const setStickyStack = React.useContext(StickyStackSetValueContext);
  const [ elementRef, setElementRef ] = React.useState<HTMLElement | null>();

  React.useLayoutEffect(() => {
    if (!elementRef) return;

    setStickyStack?.(sticky => {
      const newStickyArray = [ ...sticky[props.placement] ];

      newStickyArray[props.order] = elementRef.offsetHeight;

      return {
        ...sticky,
        [props.placement]: newStickyArray,
      };
    });

    return () => {
      setStickyStack?.(sticky => {
        const newStickyArray = [ ...sticky[props.placement] ];

        newStickyArray[props.order] = 0;

        return {
          ...sticky,
          [props.placement]: newStickyArray,
        };
      });
    };
  }, [ props.placement, props.order, elementRef ]); // eslint-disable-line react-hooks/exhaustive-deps

  const elementStickyStyles: React.CSSProperties = React.useMemo(() => {
    if (!stickyStack) {
      return {};
    }

    if (props.placement === 'top') {
      return {
        position: 'sticky',
        [props.placement]: stickyStack[props.placement]
          .slice(0, props.order)
          .reduce((prev, curr) => prev + curr, 0),
      };
    }

    if (props.placement === 'bottom') {
      return {
        position: 'sticky',
        [props.placement]: stickyStack[props.placement]
          .slice(0, props.order)
          .reduce((prev, curr) => prev + curr, 0),
      };
    }

    return {};
  }, [ props.placement, props.order, stickyStack ]);

  return React.cloneElement(props.children, {
    ref: setElementRef,
    style: {
      ...props.children.props.style,
      ...elementStickyStyles,
    }
  });
};