/* eslint-disable max-len */
import { authActions } from 'app/auth/auth.slice';
import { store } from 'app/store';
import {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const configCopy = { ...config };
  const auth = store.getState().auth;

  if (auth.accessToken) {
    configCopy.headers = { ...configCopy.headers };
    configCopy.headers.Authorization = `Bearer ${auth.accessToken}`;
  }

  return configCopy;
};

const onResponseError = async (error: AxiosError, _axiosInstance: AxiosInstance): Promise<AxiosError> => {
  if (error.response?.status === 403) {
    store.dispatch(authActions.replaceAccessToken(null));
  }

  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => {
  const xAccessToken = response.headers['x-access-token'];

  if (xAccessToken) {
    const accessToken = store.getState().auth.accessToken;

    if (!accessToken) {
      store.dispatch(authActions.replaceAccessToken(xAccessToken));
    }
  }

  return response;
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, undefined);
  axiosInstance.interceptors.response.use(onResponse, (error: any) => onResponseError(error, axiosInstance));
  return axiosInstance;
};
