import { SchemaOf, mixed, object, string } from 'yup';
import { FileFormType } from './types';

const validFileExtensions = [ 'jpg', 'jpeg', 'gif', 'png', 'svg', 'webp', 'doc', 'docx', 'pdf', 'txt' ];

function isValidFileType(fileName?: string) {
  if (!fileName) {
    return false;
  }

  const fileExtension = fileName.split('.').pop();

  if (!fileExtension) {
    return false;
  }

  return validFileExtensions.indexOf(fileExtension) !== -1;
}

const MAX_FILE_SIZE = 7168000; // 7MB

export const fileValidationSchema = (): SchemaOf<FileFormType> => {
  return object({
    name: string().required('File name required'),
    file: mixed<File>().required('File is required').test('isValidType', 'Not a valid file type', (value: File) => {
      return isValidFileType(value?.name?.toLowerCase?.());
    })
      .test('isValidSize', 'Max allowed size is 7 MB', (value: File) => {
        return value && value.size <= MAX_FILE_SIZE;
      }),
  });
};