import { Box, Typography, TypographyProps } from '@mui/material';
import { EnumHelpers } from 'helpers/enums/types';

export type ReasonItem<RowEnum extends string> = {
  row: RowEnum;
  description: string[];
};

export type AttentionWarningItemType<SectionEnum extends string, RowEnum extends string> = {
  section: SectionEnum;
  reasonItems: ReasonItem<RowEnum>[];
};

export type AttentionWarningContentStackProps<SectionEnum extends string, RowEnum extends string> = {
  warningItems: AttentionWarningItemType<SectionEnum, RowEnum>[];
  sectionEnumHelpers: Pick<EnumHelpers<SectionEnum>, 'getLabel'>;
  rowEnumHelpers: Pick<EnumHelpers<RowEnum>, 'getLabel'>;
};

export type AttentionWarningContentStackReasonItemDescriptionProps = {
  description: string[];
  variant?: TypographyProps['variant'];
};

export const AttentionWarningContentStackReasonItemDescription: React.FC<AttentionWarningContentStackReasonItemDescriptionProps> = ({ description, variant }) => {
  if (!description.length) {
    return null;
  }

  if (description.length === 1) {
    return <Typography component="div" variant={variant}>{description[0]}</Typography>;
  }

  return (
    <>
      {description.map((item, index) =>
        <Typography ml={1} key={index} component="div" variant={variant}>-&nbsp;{item}</Typography>
      )}
    </>
  );
};

export const AttentionWarningContentStack = <SectionEnum extends string, RowEnum extends string>({ warningItems, sectionEnumHelpers, rowEnumHelpers }: AttentionWarningContentStackProps<SectionEnum, RowEnum>) => {
  if (!warningItems.length) return null;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {warningItems.map((warningItem) => {
        return (
          <Box key={warningItem.section}>
            <Typography component="div" fontWeight={500}>{sectionEnumHelpers.getLabel(warningItem.section)}</Typography>
            {warningItem.reasonItems.map(reasonItem => {
              const subtitle = rowEnumHelpers.getLabel(reasonItem.row);

              return (
                <Typography component="div" key={reasonItem.row}>
                  &#8226;<span style={{ fontStyle: 'italic' }}>{subtitle}:</span>&nbsp;
                  <AttentionWarningContentStackReasonItemDescription description={reasonItem.description} />
                </Typography>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
