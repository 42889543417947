import { AirportShuttleOutlined, HardwareOutlined, AttachMoneyOutlined, HelpOutlined } from '@mui/icons-material';
import { EnumHelpersWithIcon } from 'helpers/enums/types';
import { UnknownEnum } from 'types';

export const enum EventDateTimePeriodTypeEnum {
  travel = 'travel',
  setup = 'setup',
  sales = 'sales',
  breakdown = 'breakdown',
}

export const eventDateTimePeriodTypeEnumHelpers: EnumHelpersWithIcon<EventDateTimePeriodTypeEnum | UnknownEnum.unknown> = {
  enumValues: [
    EventDateTimePeriodTypeEnum.travel,
    EventDateTimePeriodTypeEnum.setup,
    EventDateTimePeriodTypeEnum.sales,
    EventDateTimePeriodTypeEnum.breakdown,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventDateTimePeriodTypeEnum.travel:
        return 'Travel';
      case EventDateTimePeriodTypeEnum.setup:
        return 'Setup';
      case EventDateTimePeriodTypeEnum.sales:
        return 'Sales';
      case EventDateTimePeriodTypeEnum.breakdown:
        return 'Breakdown';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventDateTimePeriodTypeEnum.travel:
        return 'info';
      case EventDateTimePeriodTypeEnum.setup:
        return 'warning';
      case EventDateTimePeriodTypeEnum.sales:
        return 'success';
      case EventDateTimePeriodTypeEnum.breakdown:
        return 'threat';
      default:
        return 'unknown';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case EventDateTimePeriodTypeEnum.travel:
        return AirportShuttleOutlined;
      case EventDateTimePeriodTypeEnum.setup:
        return HardwareOutlined;
      case EventDateTimePeriodTypeEnum.sales:
        return AttachMoneyOutlined;
      case EventDateTimePeriodTypeEnum.breakdown:
        return HardwareOutlined;
      default:
        return HelpOutlined;
    }
  }
};