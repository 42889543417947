import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventsResponse, UpdateEventInput, updateEvent } from 'api/actions';
import { EventPreperationAcceptanceStatusEnum, EventPreperationParticipationStatusEnum } from 'api/resources';
import { eventsTableColumns, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { processFormValueUpdate } from 'helpers';
import { UseTableActionColumnArgs, useTableActionColumn } from 'hooks';
import { QUERY_KEY } from 'queries/query-keys';
import { UnknownEnum } from 'types';

export const useEventsManagementTableColumns = (params: {getNavigateTo?: UseTableActionColumnArgs['getNavigateTo']; editable?: Record<string, boolean>}) => {
  const { getNavigateTo, editable } = params;
  const { withActionColumn } = useTableActionColumn({
    routeTo: 'events',
    getNavigateTo: getNavigateTo ? ((id, row) => `/${ROUTING_CONFIG.events}/${getNavigateTo(id, row)}`) : ((id) => `/${ROUTING_CONFIG.events}/${id}`),
    openInNew: true,
  });
  const columns = eventsTableColumns.map(column => ({ ...column, editable: editable?.[column.field] }));

  return withActionColumn(columns);
};

const useEventsManagementTableUpdateMutation = () => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();

  return useMutation({
    mutationFn: ({ eventId, update }: {eventId: string; update: UpdateEventInput }) => {
      return updateEvent(eventId, update);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENTS);

      snackbar.success('Event updated');
    }
  });
};

export const useEventsManagementProcessRowUpdate = () => {
  const updateMutation = useEventsManagementTableUpdateMutation();

  return async (
    newRow: GetEventsResponse['data'][number] & {
      participationStatus: EventPreperationParticipationStatusEnum | UnknownEnum;
      acceptanceStatus: EventPreperationAcceptanceStatusEnum | UnknownEnum;
    },
    oldRow: GetEventsResponse['data'][number]
  ) => {
    const update = {
      preperation: {
        participationStatus: processFormValueUpdate.enumWithUnknown(newRow.participationStatus),
        acceptanceStatus: processFormValueUpdate.enumWithUnknown(newRow.acceptanceStatus),
      },
    };

    if((update.preperation.participationStatus || undefined) !== oldRow.preperation.participationStatus
      || (update.preperation.acceptanceStatus || undefined) !== oldRow.preperation.acceptanceStatus) {
      await updateMutation.mutateAsync({ eventId: newRow._id, update });
    }

    return oldRow;
  };
};