import { HorizontalRule } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DateRange } from 'components';
import { useMemo } from 'react';
import { DateService } from 'services';

export type AnalyticsChartDateRangeChipProps = {
  dateRange: DateRange;
  indicatorColor?: string;
  indicatorDashed?: boolean;
};

export const formatAnalyticsChartDateRange = (dateRange: DateRange) => {
  if(!dateRange) {
    return 'Unknown';
  }
  if(dateRange.start.year() === dateRange.end.year()) {
    if(dateRange.start.isSame(dateRange.end, 'day')) {
      return `${dateRange.end.format('MMM D')}, ${dateRange.start.year()}`;
    }
    return `${dateRange.start.format('MMM D')}—${dateRange.end.format('MMM D')}, ${dateRange.start.year()}`;
  }
  return DateService.getFormattedDateRange(dateRange.start, dateRange.end, false);
};

export const AnalyticsChartDateRangeChip: React.FC<AnalyticsChartDateRangeChipProps> = props => {
  const indicatorDisplay = useMemo(() => {
    if(!props.indicatorColor) {
      return null;
    }
    if(props.indicatorDashed) {
      return <Typography color={props.indicatorColor} fontWeight={900}>---</Typography>;
    }
    // I have to use style there because icon expects color's title, like 'primary', which I can't retrieve
    return <HorizontalRule style={{ color: props.indicatorColor }} />;
  }, [ props.indicatorColor, props.indicatorDashed ]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        px: 1,
        py: 0.5,
        gap: 1,
        borderWidth: 1,
        borderRadius: 2,
        borderborderColor: theme.palette.background.defaultDarker,
        backgroundColor: theme.palette.background.default,
        minWidth: 'fit-content',
      })}
    >
      {indicatorDisplay}
      <Typography variant="body2" color="text.secondary" >{formatAnalyticsChartDateRange(props.dateRange)}</Typography>
    </Box>
  );
};