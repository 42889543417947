import { EventDateSetupBreakdownTypeEnum, EventDateTypeEnum } from 'api/resources';
import { eventDateEnumHelpers } from 'helpers';
import { ResourceForm, yesNoSchema } from 'types';
import { SchemaOf, boolean, mixed, number, object, string } from 'yup';

export const eventDateValidationSchema = (): SchemaOf<ResourceForm['eventDate']> => {
  return object({
    type: mixed<EventDateTypeEnum>().oneOf(eventDateEnumHelpers.type.enumValues).required(),
    setupType: mixed<EventDateSetupBreakdownTypeEnum>().oneOf(eventDateEnumHelpers.setupBreakdownType.enumValues).required(),
    breakdownType: mixed<EventDateSetupBreakdownTypeEnum>().oneOf(eventDateEnumHelpers.setupBreakdownType.enumValues).required(),
    confirmedTimes: boolean().required(),
    bufferTimeInMinutes: number().min(0).max(59).required(),
    displaySetupInMinutes: number().min(0).max(180).required(),
    breakdownInMinutes: number().min(0).max(180).required(),
    startTime: string().required(),
    endTime: string().required(),
    arriveAsEarlyAs: string().default(''),
    noVehiclesAllowedAfter: string().default(''),
    allVehiclesMustBeRemovedBy: string().default(''),
    setupEndTime: string().default(''),
    notes: string().default(''),
    teamLeadNeeded: yesNoSchema,
    staffNeeded: number().default(0),
  });
};