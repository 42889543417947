import { Stack, Box, Alert, Typography } from '@mui/material';
import { GetEventsResponse } from 'api/actions';
import { EventDateTypeEnum } from 'api/resources';
import { useEventContext, EventDateTimeline } from 'components';
import { getEventDateTimelineConfigs, getWorkdayTotal } from 'helpers/event-date-times';

export type EventDateTimelinesProps = {
  eventDate: GetEventsResponse['data'][number]['dates'][number];
};

export const EventDateTimelines: React.FC<EventDateTimelinesProps> = ({ eventDate }) => {
  const { event } = useEventContext();

  if (eventDate.type === EventDateTypeEnum.travel) {
    return null;
  }

  const timelineConfigs = getEventDateTimelineConfigs({ event, eventDate });
  const { workdayTotalLabel } = getWorkdayTotal({ event, eventDate });

  return (
    <Stack gap={1}>
      <Box mb={1} display="flex" flexDirection="column" gap={1}>
        {eventDate.confirmedTimes ? (
          <Alert severity="success">Times are confirmed</Alert>
        ) : (
          <Alert severity="warning">Times are not confirmed</Alert>
        )}
      </Box>
      {timelineConfigs.map((config, index) => <EventDateTimeline key={index} {...config} />)}
      <Typography mt={2}>Total workday: <Typography component="span" fontWeight={500}>{workdayTotalLabel}</Typography></Typography>
    </Stack>
  );
};