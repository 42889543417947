import { Box } from '@mui/material';
import { MarkdownInput, RadioGroupYesNoBoolean } from 'components';
import React from 'react';
import { formSx } from 'styles';

export const UserDrivingInfoForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="driverLicence"
        label="Driving Licence"
        row
        includeUnknown
      />
      <RadioGroupYesNoBoolean
        name="personalVehicle"
        label="Personal Vehicle"
        row
        includeUnknown
      />
      <RadioGroupYesNoBoolean
        name="coveredByBussinessInsuranceAsDriver"
        label="Covered by bussiness insurance as driver"
        row
        includeUnknown
      />
      <RadioGroupYesNoBoolean
        name="canDriveBoxTruck"
        label="Can drive box truck"
        row
        includeUnknown
      />
      <RadioGroupYesNoBoolean
        name="canPullTrailer"
        label="Can pull trailer"
        row
        includeUnknown
      />
      <MarkdownInput label="Driving notes" name="drivingNotes" />
    </Box>
  );
};
