/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { PaletteMode, PaletteOptions, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
import { REACT_APP_ENVIRONMENT } from 'config/environment.config';
import React from 'react';

declare module '@mui/material/styles/createPalette' {
  interface SimplePaletteColorOptions {
    background: React.CSSProperties['color'];
  }

  interface PaletteColor {
    background: React.CSSProperties['color'];
  }

  interface TypeBackground {
    defaultDarker: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    threat: Palette['primary'];
    unknown: Palette['primary'];
  }

  interface PaletteOptions {
    unknown: PaletteOptions['primary'];
    threat: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    threat: true;
    unknown: true;
  }
}

declare module '@mui/material/Button' {
  export interface ButtonPropsColorOverrides {
    threat: true;
    unknown: true;
  }
}


export const getPalette = (mode: PaletteMode): PaletteOptions => ({
  mode,
  background: {
    default: mode === 'light' ? grey[100] : grey[900],
    defaultDarker: mode === 'light' ? grey[200] : grey[900],
  },
  primary: {
    main: REACT_APP_ENVIRONMENT === 'staging' ? 'rgb(238, 52, 0)' : 'rgb(117, 123, 200)',
    background: REACT_APP_ENVIRONMENT === 'staging' ? 'rgba(238, 52, 0, 0.08)' : 'rgba(117, 123, 200, 0.08)',
  },
  secondary: {
    main: 'rgb(222, 205, 111)',
    background: 'rgba(222, 205, 111, 0.08)',
  },
  success: {
    main: '#2e7d32',
    background: 'rgba(46, 125, 50, 0.08)',
  },
  error: {
    main: '#d32f2f',
    background: 'rgba(211, 47, 47, 0.08)',
  },
  warning: {
    main: '#cfa604',
    background: 'rgba(207, 166, 4, 0.08)',
  },
  info: {
    main: '#42a5f5',
    background: 'rgba(66, 165, 245, 0.08)',
  },
  threat: {
    main: '#ed4a0e',
    light: '#f07243',
    dark: '#c7400e',
    background: alpha('#ed4a0e', 0.08),
    contrastText: '#000'
  },
  unknown: {
    main: grey[600],
    light: grey[400],
    dark: grey[800],
    background: alpha(grey[600], 0.08),
    contrastText: '#000'
  }
});
