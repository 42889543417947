import { Box } from '@mui/material';
import { Select, TextInput, FormDateTimePicker } from 'components';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper } from 'helpers';
import { useMemo } from 'react';
import { formSx } from 'styles';

export const EventCommunicationLogForm = () => {
  const communicationLogTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.communicationLog), []);

  return (
    <Box sx={formSx.formGroup}>
      <FormDateTimePicker name="date" />
      <Select name="type" label="Type" options={communicationLogTypeOptions} />
      <TextInput name="note" label="Note" multiline minRows={2} trim />
    </Box>
  );
};
