import { EditModeToggler } from 'components';
import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';

export type EventsListTableProps = {
  setEditMode: (isInEditMode: boolean) => void;
};

export const EventsListTable: React.FC<EventsListTableProps> = props => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.eventsProSearch}
      localStorageArgs={{
        initialVisible: [ 'name', 'year', 'eventTimeframe', 'daysCount', 'participationStatus', 'applicationStatus', 'staffStatus', 'datesStatus', 'acceptanceStatus', 'owed', 'teamName', 'applicationOpenTimeframe', 'applicationPlatform' ],
      }}
      editModeToggler={<EditModeToggler isEditMode={false} setIsEditMode={props.setEditMode} />}
    />
  );
};