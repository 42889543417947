import { Box, BoxProps } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { ACCEPTABLE_IMAGE_EXTENSIONS } from './editable-image.constants';

export type ImageInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  id: string;
  sx?: BoxProps['sx'];
  disabled?: boolean;
};

export const ImageInput: React.FC<ImageInputProps> = ({ onChange, id, sx, disabled }) => (
  <Box
    component="input"
    accept={ACCEPTABLE_IMAGE_EXTENSIONS.join(', ')}
    onChange={onChange}
    id={id}
    type="file"
    disabled={disabled}
    sx={{
      ...sx,
      width: '250px',
      height: '250px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'none',
    }}
  />
);