import { DialogActions, SxProps } from '@mui/material';

export const ModalActions: React.FC<{ children: React.ReactNode; sx?: SxProps }> = ({ children, sx = {} }) => {
  return (
    <DialogActions
      sx={{
        padding: 2,
        display: 'flex',
        gap: 1,
        '& > button': {
          flex: 1,
          margin: 0,
        },
        '& > :not(:first-of-type)': {
          marginLeft: 0,
        },
        ...sx,
      }}
    >
      {children}
    </DialogActions>
  );
};
