import { Box, Button, Typography } from '@mui/material';
import { logoImg } from 'assets';
import { PageContentContainer } from 'components/MainLayout';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';

export type ErrorPageContentProps = {
  errorCode: 403 | 404 | 500;
  message: string;
};

export const ErrorPageContent: React.FC<ErrorPageContentProps> = props => {
  const navigate = useNavigate();

  return (
    <PageContentContainer>
      <Box flex={1} display="flex" justifyContent="center" pt={5}>
        <Box textAlign="center">
          <Box
            component="img"
            height={theme => theme.spacing(10)}
            src={logoImg}
          />
          <Typography fontSize="64px" fontWeight={600}>{props.errorCode.toString().split('').join(' ')}</Typography>
          <Typography maxWidth="350px">{props.message}</Typography>
          <Box mt={3} display="flex" gap={2} justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Go To Previous Page</Button>
            <Button variant="outlined" color="primary" onClick={() => navigate(`/${ROUTING_CONFIG.dashboard}`)}>Go To Dashboard</Button>
          </Box>
        </Box>
      </Box>
    </PageContentContainer>
  );
};