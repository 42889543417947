import { AddGenreModal, GenresTable } from './components';
import { Button } from '@mui/material';
import { useState } from 'react';
import { PageContentContainer, PageContentHeader } from 'components';
import { useCurrentUser } from 'contexts';

export const Genres = () => {
  const { isAdmin } = useCurrentUser();
  const [ addGenreModalOpen, setAddGenreModalOpen ] = useState(false);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Product Genres"
          rightActions={<Button variant="contained" onClick={() => setAddGenreModalOpen(true)} size="small" disabled={!isAdmin}>New Genre</Button>}
        />
      )}
    >
      {addGenreModalOpen && <AddGenreModal onClose={() => setAddGenreModalOpen(false)}/>}
      <GenresTable />
    </PageContentContainer>
  );
};