import {
  Button, ButtonProps, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { Modal, ModalType } from '../Modal.component';
import { ModalActions } from '../ModalActions.component';

export type AreYouSureModalProps = {
  confirmButtonColor?: ButtonProps['color'];
  confirmButtonText?: string;
  confirmButtonLoadingText?: string;
  confirmButtonDisabled?: boolean;
  onConfirm?: (...params: any) => void;
  title?: string;
  onClose: () => void;
  description?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  cancelButtonText?: string;
} & Omit<ModalType, 'onClose' | 'open'>;

export const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
  confirmButtonText = 'Confirm',
  confirmButtonLoadingText = 'Saving...',
  onConfirm,
  title = 'Are you sure?',
  onClose,
  description,
  children,
  loading = false,
  cancelButtonText = 'Cancel',
  confirmButtonColor = 'primary',
  confirmButtonDisabled = false,
  ...modalProps
}) => {

  const handleClose = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  return (
    <Modal
      open
      onClose={handleClose}
      autoHeight
      {...modalProps}
    >
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {typeof description === 'string' ? <Typography>{description}</Typography> : description}
          {children}
        </DialogContent>
        <ModalActions>
          <Button
            color="inherit"
            onClick={handleClose}
            disabled={loading}
          >
            {cancelButtonText}
          </Button>
          {onConfirm && (
            <Button
              color={confirmButtonColor}
              variant="contained"
              onClick={() => onConfirm()}
              disabled={loading || confirmButtonDisabled}
            >
              {!loading ? confirmButtonText : confirmButtonLoadingText}
            </Button>
          )}
        </ModalActions>
      </>
    </Modal>
  );
};