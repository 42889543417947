import { Theme } from '@mui/material';

export const paletteHelperHash: { [key in 'success' | 'successDark' | 'error' | 'warning' | 'unknown' | 'unknownDarker' | 'threat' | 'info']: Pick<ColorCodeKeyConfig, 'background' | 'color'> } = {
  success: {
    background: (theme) => theme.palette.success.main,
    color: (theme) => theme.palette.success.contrastText,
  },
  successDark: {
    background: (theme) => theme.palette.success.dark,
    color: (theme) => theme.palette.success.contrastText,
  },
  error: {
    background: (theme) => theme.palette.error.main,
    color: (theme) => theme.palette.error.contrastText,
  },
  warning: {
    background: (theme) => theme.palette.warning.main,
    color: (theme) => theme.palette.warning.contrastText,
  },
  unknownDarker: {
    background: (theme) => theme.palette.grey[900],
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[900]),
  },
  unknown: {
    background: (theme) => theme.palette.grey[600],
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[600]),
  },
  threat: {
    background: (theme) => theme.palette.threat.main,
    color: (theme) => theme.palette.threat.contrastText,
  },
  info: {
    background: (theme) => theme.palette.info.main,
    color: (theme) => theme.palette.info.contrastText,
  },
};

export type ColorCodeKeyConfig = {
  keyLabel: string;
  background: (theme: Theme) => string;
  color: (theme: Theme) => string;
};

type ColorCodeKeyFuncType<Key, Arg> = (arg: Arg) => Key;

export class ColorCodeConfig <Option extends string, Arg extends unknown> {
  private colorKeyConfigHash: Record<Option, ColorCodeKeyConfig>;

  private keyFunc: ColorCodeKeyFuncType<Option, Arg>;

  label: string;

  constructor(label: string, colorKeyConfigHash: Record<Option, ColorCodeKeyConfig>, keyFunc: ColorCodeKeyFuncType<Option, Arg>) {
    this.colorKeyConfigHash = colorKeyConfigHash;
    this.keyFunc = keyFunc;
    this.label = label;
  }

  get colorKeyConfigs() {
    return Object.values(this.colorKeyConfigHash) as ColorCodeKeyConfig[];
  }

  getColorKeyConfig(arg: Arg) {
    const key = this.keyFunc(arg);

    return this.colorKeyConfigHash[key] ?? null;
  }
}
