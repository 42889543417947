import React from 'react';
import { FullCalendarSidebarAction, FullCalendarSidebarActionProps } from './CalendarSidebarAction';
import { Box, Collapse } from '@mui/material';
import { SIDEBAR_TIMEOUT } from './FullCalendar.component';

export type FullCalendarSidebarProps = {
  open: boolean;
  actionsConfig: FullCalendarSidebarActionProps[];
};

export const FullCalendarSidebar: React.FC<FullCalendarSidebarProps> = props => {
  return (
    <Collapse orientation="horizontal" in={props.open} timeout={SIDEBAR_TIMEOUT}>
      <Box width={200} pr={1}>
        {props.actionsConfig.map((config, index) => <FullCalendarSidebarAction {...config} key={index} />)}
      </Box>
    </Collapse>
  );
};
