import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getLoggingPortalDataForEventDate, getLoggingPortalDataForStorefront } from 'api/actions';

export const useLoggingPortalDataForEventDateQuery = (eventDateId: string, enabled: boolean = true) => {
  return useQuery(QUERY_KEY.LOGGING_PORTAL_DATA_FOR_EVENT_DATE(eventDateId), async () => {
    const response = await getLoggingPortalDataForEventDate(eventDateId);

    return response.data;
  }, { retry: false, enabled });
};

export const useLoggingPortalDataForStorefrontQuery = (storefrontId: string, enabled: boolean = true) => {
  return useQuery(QUERY_KEY.LOGGING_PORTAL_DATA_FOR_STOREFRONT(storefrontId), async () => {
    const response = await getLoggingPortalDataForStorefront(storefrontId);

    return response.data;
  }, { retry: false, enabled });
};