import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FilterResourceFieldConfiguration } from './useFilter.hook';
import { useMemo } from 'react';

export type ResourceFilterProps = Pick<FilterResourceFieldConfiguration<any>, 'options' | 'includeAny' | 'includeNotSelected' | 'loading'> & {
  label: string;
  value: NonNullable<FilterResourceFieldConfiguration<any>['defaultValue']>;
  onChange: (value: 'any' | string[]) => void;
  readOnly?: boolean;
};

export const ResourceFilter: React.FC<ResourceFilterProps> = props => {
  const selectDisplay = useMemo(() => {
    if (props.loading) {
      return <Typography fontStyle="italic" color="text.secondary">Loading...</Typography>;
    }

    const onChange = (e: SelectChangeEvent<'any' | 'notSelected' | string | string[]>) => {
      const value = e.target.value;

      if(!props.value.includes('any') && value.includes('any')) {
        // it means that 'any' option was just selected
        // selecting 'any' unselects all previously selected options
        return props.onChange('any');
      }

      if(!Array.isArray(value)) {
        return props.onChange([ value ]);
      }

      if (!value.length) {
        return props.onChange('any');
      }

      // filter out 'any' as selecting an option overrides 'any'
      const values = value.filter(value => value !== 'any');

      return props.onChange(values);
    };

    return (
      <Select
        multiple
        size="small"
        defaultValue={props.value}
        value={props.value === 'any' ? [ 'any' ] : props.value}
        onChange={onChange}
        MenuProps={{
          style: { zIndex: 1500 },
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        }}
        disabled={props.readOnly}
      >
        {props.includeAny && (
          <MenuItem value="any" divider>
            <Typography component="span" fontStyle="italic">Any</Typography>
          </MenuItem>
        )}
        {props.options.map((option) => <MenuItem value={option._id} key={option._id}>{option.label}</MenuItem>)}
        {props.includeNotSelected && (
          <MenuItem value="notSelected">
            <Typography component="span" fontStyle="italic">Not Selected</Typography>
          </MenuItem>
        )}
      </Select>
    );
  }, [ props ]);

  return (
    <>
      <Typography color={(theme) => theme.palette.grey[700]}>
        {props.label} {props.readOnly && <i>(Read Only)</i>}
      </Typography>
      {selectDisplay}
    </>
  );
};