import { authAxiosRequest } from 'api/axios';

export type GetProductShopifyInfo = {
  data: {
    title: string;
    handle: string;
    status: string;
    tags: string;
    storeUrl: string;
  };
};

export const getProductShopifyInfo = (shopifyProductId: number) => {
  return authAxiosRequest<GetProductShopifyInfo>(`/api/shopify/products/${shopifyProductId}`);
};