import { List, ListItem, Typography, TypographyProps } from '@mui/material';
import { GetEventResponse } from 'api/actions';
import { getEventDateImagesWarnings } from 'helpers';

export type EventDateImageWarningsProps = {
  variant?: TypographyProps['variant'];
  event: GetEventResponse['data'];
  eventDate: GetEventResponse['data']['dates'][number];
};

export const EventDateImageWarnings: React.FC<EventDateImageWarningsProps> = ({ event, eventDate, variant }) => {
  const eventDateImagesWarningsHash = getEventDateImagesWarnings(eventDate, event.dates);
  const eventDateImagesWarnings = Object.values(eventDateImagesWarningsHash);

  return (
    <List
      sx={{
        listStyleType: 'disc',
        pl: 3,
        pt: 0,
        pb: 0,
        '& .MuiListItem-root': {
          display: 'list-item',
          pt: 0,
        },
      }}
    >
      {eventDateImagesWarnings.filter(x => x).map((warning, index) => (
        <ListItem key={index} disablePadding>
          <Typography variant={variant}>{warning}</Typography>
        </ListItem>
      ))}
    </List>
  );
};