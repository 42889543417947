import { FormControl, FormHelperText, FormLabel, useTheme } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import ReactPinInput from 'react-pin-input';


export type PinInputProps = {
  disabled?: boolean;
  name: string;
  helperText?: string;
  label?: string;
  autoFocus?: boolean;
  onComplete?: (value: string) => void;
  clearOnComplete?: boolean;
};

export const PinInput: React.FC<PinInputProps> = props => {
  const pinRef = useRef<ReactPinInput | null>(null);
  const theme = useTheme();

  const [ _field, meta, helpers ] = useField(props.name);
  const hasError = Boolean(meta.error && meta.touched);
  const helperText = (meta.touched && meta.error) || props.helperText;

  const onChange = (value: string) => {
    helpers.setValue(value, false);
  };

  const onComplete = (value: string) => {
    if (props.onComplete) {
      props.onComplete(value);
    }

    if (props.clearOnComplete && pinRef.current) {
      pinRef.current.clear();
    }
  };

  useEffect(() => {
    if (props.autoFocus && pinRef.current) {
      pinRef.current.focus();
    }
  }, [ props.autoFocus ]);

  const inputStyle = useMemo(() => {
    const style: React.CSSProperties = {
      borderColor: theme.palette.grey[400],
      borderRadius: 4,
      borderWidth: 1,
      font: 'inherit',
      height: 56,
    };

    if (hasError) {
      style.borderColor = theme.palette.error.main;
    }

    return style;
  }, [ hasError, theme.palette.error.main, theme.palette.grey ]);

  return (
    <FormControl error={hasError}>
      <FormLabel>{props.label}</FormLabel>
      <ReactPinInput
        ref={pinRef}
        length={4}
        secret
        secretDelay={400}
        type="numeric"
        inputMode="numeric"
        disabled={props.disabled}
        onComplete={onComplete}
        onChange={onChange}
        inputFocusStyle={{
          borderColor: !hasError ? theme.palette.primary.main : theme.palette.error.main,
          borderWidth: 2,
        }}
        inputStyle={inputStyle}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};