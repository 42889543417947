import { Box, Stack, Typography } from '@mui/material';
import { RenderTooltipArg } from './useAnalyticsChart.hook';
import { CropFree, Square } from '@mui/icons-material';

export type DefaultAnalyticsChartTooltipContentProps = RenderTooltipArg;

export const DefaultAnalyticsChartTooltipContent: React.FC<DefaultAnalyticsChartTooltipContentProps> = props => {
  return (
    <>
      {props.data.datasets.map((dataset, index) => {
        const color = Array.isArray(dataset.borderColor) ? dataset.borderColor[props.dataIndex] : dataset.borderColor;

        return (
          <Stack key={index}>
            <Box display="flex" alignItems="center">
              {dataset.borderDash
                ? <CropFree style={{ color }} fontSize="small" />
                : <Square style={{ color }} fontSize="small" />}
              &nbsp;
              <Typography variant="body2">{props.data.labels[props.dataIndex]}</Typography>
              <Typography variant="body2" fontWeight={700} ml={1}>{dataset.data[props.dataIndex] ?? 0}</Typography>
            </Box>
          </Stack>
        );
      })}
    </>
  );
};