import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

import { AllSalesTable } from './AllSales.table';
import { SalesPerEventTable } from './SalesPerEvent.table';
import { SalesPerProductTable } from './SalesPerProduct.table';
import { SalesPerTeamTable } from './SalesPerTeam.table';
import { SalesTableProps } from './types';
import { TableActionsBar } from 'components';

const enum SalesTableTypeEnum {
  all = 'all',
  product = 'product',
  team = 'team',
  event = 'event',
}

const tableTypeOptions: { label: string; value: SalesTableTypeEnum }[] = [
  { label: 'All', value: SalesTableTypeEnum.all },
  { label: 'Per Product', value: SalesTableTypeEnum.product },
  { label: 'Per Team', value: SalesTableTypeEnum.team },
  { label: 'Per Event', value: SalesTableTypeEnum.event },
];

export const SalesTable: React.FC<SalesTableProps> = props => {
  const [ tableType, setTableType ] = useState<SalesTableTypeEnum>(SalesTableTypeEnum.all);
  const tableHash: { [TableType in SalesTableTypeEnum]: React.ReactNode } = {
    all: <AllSalesTable {...props} />,
    product: <SalesPerProductTable {...props} />,
    event: <SalesPerEventTable {...props} />,
    team: <SalesPerTeamTable {...props} />,
  };

  return (
    <>
      <TableActionsBar
        leftActions={(
          <Box width={200}>
            <FormControl fullWidth>
              <InputLabel id="table-view-type">View Type</InputLabel>
              <Select
                labelId="table-view-type"
                label="View Type"
                size="small"
                value={tableType}
                onChange={(e) => setTableType(e.target.value as SalesTableTypeEnum)}
                fullWidth
              >
                {tableTypeOptions.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        )}
      />
      {tableHash[tableType]}
    </>
  );
};
