import React from 'react';
import { PageContentContainer, PageContentHeader } from 'components';
import { ProductsPageContextProvider } from 'contexts';
import { BaselinesTable } from './components';

export const Baselines = () => {

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Baselines"
        />
      )}
    >
      <ProductsPageContextProvider>
        <BaselinesTable />
      </ProductsPageContextProvider>
    </PageContentContainer>
  );
};