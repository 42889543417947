import { ApiResource } from './resources.types';

export type Place = ApiResource<{
  name?: string;
  address: string;
  googlePlaceId: string;
  lat: number;
  lng: number;
}>;

export type PlaceMatrix = {
  distance: number;
  duration: number;
};

export const enum PlaceDefaultEnum {
  studio = 'studio',
}