import React from 'react';
import { StatusChipProps } from './types';
import { Box } from '@mui/material';
import { CardContentGridItemTitle } from 'components/CardContent';

type BaseProps<T extends string | number> = {
  title: string;
  ChipComponent: React.ComponentType<StatusChipProps<T>>;
};
type ValueDefinedProps<T extends string | number> = BaseProps<T> & {
  value: T;
  defaultValue?: never;
};
type ValueUndefinedProps<T extends string | number> = BaseProps<T> & {
  value?: T;
  defaultValue: T;
};
export type LabeledChipBoxProps<T extends string | number> = ValueDefinedProps<T> | ValueUndefinedProps<T>;

export const LabeledChipBox = <T extends string | number>({ title, value, defaultValue, ChipComponent }: LabeledChipBoxProps<T>) => (
  <Box display="flex" flexDirection="column" gap={0.5}>
    <CardContentGridItemTitle>{title}</CardContentGridItemTitle>
    <ChipComponent size="medium" value={(value ?? defaultValue) as T} sx={{ fontWeight: 500 }} />
  </Box>
);
