import { Box } from '@mui/material';
import { JumpNav, PageContentContainer, PageContentHeader } from 'components';
import { EventsManagementOverview } from './components/EventsManagementOverview.component';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';
import { ProductionOverview } from './components/ProductionOverview.component';
import { EventsOverview } from './components/EventsOverview.component';
import { DashboardPageSection } from './types';
import { useMemo } from 'react';

export const Dashboard = () => {
  const { isAuthorizedEmployee } = useCurrentUser();

  const initialTab = useMemo(() => {
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false)) {
      return DashboardPageSection.EVENTS_MANAGEMENT;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false)) {
      return DashboardPageSection.PRODUCTION;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false)) {
      return DashboardPageSection.EVENTS;
    }

    return null;
  }, [ isAuthorizedEmployee ]);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Dashboard"
          sticky
          tabs={initialTab && (
            <JumpNav
              initialValue={initialTab}
              offsetTop={64}
              tabs={[
                { label: 'Events Management', sectionId: DashboardPageSection.EVENTS_MANAGEMENT, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) },
                { label: 'Production', sectionId: DashboardPageSection.PRODUCTION, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) },
                { label: 'Events', sectionId: DashboardPageSection.EVENTS, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) },
              ]}
            />
          )}
        />
      )}
    >
      <Box display="flex" flexDirection="column" gap={5}>
        {isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) && <EventsManagementOverview />}
        {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) && <ProductionOverview />}
        {isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) && <EventsOverview />}
      </Box>
    </PageContentContainer>
  );
};
