import { CreateEventFormType } from '../types';
import { SchemaOf, object, string, number } from 'yup';

export const createEventValidationSchema = (): SchemaOf<CreateEventFormType> => {
  return object({
    name: string().required('Event Name Required'),
    year: string().required('Year Required'),
    location: string().required('Location Required'),
    placeId: string().default(''),
    cost: number().required('Cost Required').min(0, 'Cost cannot be this low'),
  });
};