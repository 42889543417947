import { InfoOutlined } from '@mui/icons-material';
import { Divider, FormControl, FormHelperText, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useCurrentUser } from 'contexts';
import React from 'react';

export const enum EditType {
  unary = 'unary',
  set = 'set',
}

export type EditTypeInputProps = {
  editType: EditType | null;
  setEditType: React.Dispatch<React.SetStateAction<EditType | null>>;
  disabled?: boolean;
};

export const EditTypeInput: React.FC<EditTypeInputProps> = props => {
  const { isAdmin } = useCurrentUser();
  const onSetEditType = (value: EditType | null) => {
    if (!value) {
      return;
    }

    props.setEditType(value);
  };

  return (
    <FormControl disabled={props.disabled} fullWidth>
      <ToggleButtonGroup
        onChange={(_e, value: EditType | null) => onSetEditType(value)}
        value={props.editType}
        exclusive
        color="primary"
        fullWidth
        disabled={props.disabled || !isAdmin}
      >
        <ToggleButton value={EditType.unary}>Add</ToggleButton>
        <ToggleButton value={EditType.set}>Set (Admin-only)</ToggleButton>
      </ToggleButtonGroup>
      {!props.editType && <FormHelperText>Please select before editing</FormHelperText>}
    </FormControl>
  );
};


export const EditTypeTooltip: React.FC = () => {

  return (
    <Tooltip
      title={(
        <div>
          <Typography variant="overline">ADD</Typography>
          <Typography variant="body2">The number you input will be added to the current inventory number</Typography>
          <Divider sx={{ mt: 1 }} />
          <Typography variant="overline">SET</Typography>
          <Typography variant="body2">The number you input will override and set the current inventory number</Typography>
        </div>
      )}
      color="text.secondary"
      placement="right"
      arrow
    >
      <InfoOutlined fontSize="small" color="inherit" />
    </Tooltip>
  );
};