import React from 'react';
import { useField } from 'formik';
import {
  ToggleButtonGroup as MUIToggleButtonGroup,
  ToggleButtonGroupProps as MUIToggleButtonGroupProps,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@mui/material';

export type ToggleButtonGroupProps = MUIToggleButtonGroupProps & {
  name: string;
  nullable?: boolean;
  label?: string;
};

export const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  name, nullable, label, ...props
}) => {
  const [ field, meta ] = useField(name);
  const { onChange } = field;
  const helperText = meta.error;
  const hasError = Boolean(helperText);

  const handleChange = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    if (!nullable && !value) {
      return;
    }
    onChange({
      target: {
        name,
        value,
      },
    });
  }, [ onChange, name, nullable ]);

  return (
    <FormControl error={hasError} component="div">
      {label && <FormLabel sx={{ pb: 0.5 }}>{label}</FormLabel>}
      <MUIToggleButtonGroup
        onChange={handleChange}
        value={field.value}
        id={name}
        {...props}
      />
      {helperText && (
        <FormHelperText id={`${name}-helper`} component="div" dangerouslySetInnerHTML={{ __html: helperText }} />
      )}
    </FormControl>
  );
};
