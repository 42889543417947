import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getStaffSchedulingForEvent } from 'api/actions';

export const useStaffSchedulingForEvent = (eventId: string) => {
  return useQuery(QUERY_KEY.EVENT_SCHEDULING(eventId), async () => {
    const response = await getStaffSchedulingForEvent({ eventId });

    return response.data;
  });
};