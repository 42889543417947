import { Typography } from '@mui/material';
import { Autocomplete, AutocompleteProps } from './Autocomplete.component';
import { GetEventResourceChangeOptionsData } from 'api/actions';
import { DateService } from 'services';

export type EventResourceOptionsAutocompleteProps<T extends GetEventResourceChangeOptionsData> = Omit<AutocompleteProps<T>, 'value' | 'touched' | 'error' | 'inputValue' | 'setInputValue' | 'renderOption'> & {
  loading: boolean;
  renderOptionTitle?: (option: T) => React.ReactNode;
};

export const EventResourceOptionsAutocomplete = <T extends GetEventResourceChangeOptionsData>({ name, renderOptionTitle, ...props }: EventResourceOptionsAutocompleteProps<T>) => {
  return (
    <Autocomplete
      {...props}
      id={name}
      name={name}
      renderOption={(props, option: T) => {
        const eventDateConflicts = option.eventDateConflicts;
        const titleDisplay = renderOptionTitle ? renderOptionTitle(option) : <Typography>{option.name}</Typography>;

        if (eventDateConflicts) {
          return (
            <li {...props}>
              <div>
                {titleDisplay}
                {eventDateConflicts.map((eventDate) => {
                  return <Typography key={eventDate.eventDateId} variant="body2"><Typography component="span" color="error" variant="inherit">CONFLICT:</Typography> {eventDate.eventName} - {DateService.getFormattedDate(DateService.dayjsTz(eventDate.eventDate))}</Typography>;
                })}
              </div>
            </li>
          );
        }

        return <li {...props}>{titleDisplay}</li>;
      }}
    />
  );
};