import { updateUser } from 'api/actions';
import { SectionCardRow, YesNoUnknownIconSwitch, CardContentMarkdown, RadioGroupYesNoBoolean, MarkdownInput, SectionCard } from 'components';
import { useCurrentUser } from 'contexts';
import { getYesNoUnknownFromValue, processFormValueUpdate } from 'helpers';
import React, { useMemo } from 'react';
import { object, string } from 'yup';
import { UserSectionCardRowEditable } from '../components/UserSectionCardRowEditable.component';
import { ProfilePageSection, UserPageSectionRowId } from '../types';
import { UserSessionsCardProps } from '../components/UserSessionsCard.component';
import { yesNoUnknownSchema } from 'types';

export const DrivingSection: React.FC<UserSessionsCardProps> = ({ user }) => {
  const { getIsMe, isAdmin } = useCurrentUser();
  const canEdit = getIsMe(user) || isAdmin;

  const content = useMemo(() => {
    if(canEdit) {
      return (
        <>
          <UserSectionCardRowEditable
            title="Driver Licence"
            rowId={UserPageSectionRowId.DRIVER_LICENSE}
            formikProps={{
              initialValues: { driverLicence: getYesNoUnknownFromValue(user.driverLicence) },
              onSubmit: (values) => updateUser(user._id, { driverLicence: processFormValueUpdate.yesNoUnknown(values.driverLicence) }),
              validationSchema: object({ driverLicence: yesNoUnknownSchema })
            }}
            form={<RadioGroupYesNoBoolean name="driverLicence" label="Driving Licence" row includeUnknown />}
            user={user}
          >
            <YesNoUnknownIconSwitch value={user.driverLicence ?? null} />
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Personal Vehicle"
            rowId={UserPageSectionRowId.PERSONAL_VEHICLE}
            formikProps={{
              initialValues: { personalVehicle: getYesNoUnknownFromValue(user.personalVehicle) },
              onSubmit: (values) => updateUser(user._id, { personalVehicle: processFormValueUpdate.yesNoUnknown(values.personalVehicle) }),
              validationSchema: object({ personalVehicle: yesNoUnknownSchema })
            }}
            form={<RadioGroupYesNoBoolean name="personalVehicle" label="Personal Vehicle" row includeUnknown />}
            user={user}
          >
            <YesNoUnknownIconSwitch value={user.personalVehicle ?? null} />
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Covered by Bussiness Insurance as a Driver"
            rowId={UserPageSectionRowId.COVERED_BY_BUSSINESS_INSURANCE_AS_DRIVER}
            formikProps={{
              initialValues: { coveredByBussinessInsuranceAsDriver: getYesNoUnknownFromValue(user.coveredByBussinessInsuranceAsDriver) },
              onSubmit: (values) => updateUser(user._id, { coveredByBussinessInsuranceAsDriver: processFormValueUpdate.yesNoUnknown(values.coveredByBussinessInsuranceAsDriver) }),
              validationSchema: object({ coveredByBussinessInsuranceAsDriver: yesNoUnknownSchema })
            }}
            form={<RadioGroupYesNoBoolean name="coveredByBussinessInsuranceAsDriver" label="Covered by Bussiness Insurance as a Driver" row includeUnknown />}
            user={user}
          >
            <YesNoUnknownIconSwitch value={user.coveredByBussinessInsuranceAsDriver ?? null} />
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Can Drive Box Truck"
            rowId={UserPageSectionRowId.CAN_DRIVE_BOX_TRUCK}
            formikProps={{
              initialValues: { canDriveBoxTruck: getYesNoUnknownFromValue(user.canDriveBoxTruck) },
              onSubmit: (values) => updateUser(user._id, { canDriveBoxTruck: processFormValueUpdate.yesNoUnknown(values.canDriveBoxTruck) }),
              validationSchema: object({ canDriveBoxTruck: yesNoUnknownSchema })
            }}
            form={<RadioGroupYesNoBoolean name="canDriveBoxTruck" label="Can Drive Box Truck" row includeUnknown />}
            user={user}
          >
            <YesNoUnknownIconSwitch value={user.canDriveBoxTruck ?? null} />
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Can Pull Trailer"
            rowId={UserPageSectionRowId.CAN_PULL_TRAILER}
            formikProps={{
              initialValues: { canPullTrailer: getYesNoUnknownFromValue(user.canPullTrailer) },
              onSubmit: (values) => updateUser(user._id, { canPullTrailer: processFormValueUpdate.yesNoUnknown(values.canPullTrailer) }),
              validationSchema: object({ canPullTrailer: yesNoUnknownSchema })
            }}
            form={<RadioGroupYesNoBoolean name="canPullTrailer" label="Can Pull Trailer" row includeUnknown />}
            user={user}
          >
            <YesNoUnknownIconSwitch value={user.canPullTrailer ?? null} />
          </UserSectionCardRowEditable>
          <UserSectionCardRowEditable
            title="Notes"
            rowId={UserPageSectionRowId.DRIVING_NOTES}
            formikProps={{
              initialValues: { drivingNotes: user.drivingNotes ?? '' },
              onSubmit: (values) => updateUser(user._id, { drivingNotes: processFormValueUpdate.string(values.drivingNotes) }),
              validationSchema: object({ drivingNotes: string().default('') })
            }}
            form={<MarkdownInput label="Driving notes" name="drivingNotes" />}
            user={user}
          >
            <CardContentMarkdown markdown={user.drivingNotes} />
          </UserSectionCardRowEditable>
        </>
      );
    }

    return (
      <>
        <SectionCardRow title="Driver Licence"><YesNoUnknownIconSwitch value={user.driverLicence ?? null} /></SectionCardRow>
        <SectionCardRow title="Personal Vehicle"><YesNoUnknownIconSwitch value={user.personalVehicle ?? null} /></SectionCardRow>
        <SectionCardRow title="Covered by Bussiness Insurance as a Driver"><YesNoUnknownIconSwitch value={user.coveredByBussinessInsuranceAsDriver ?? null} /></SectionCardRow>
        <SectionCardRow title="Can Drive Box Truck"><YesNoUnknownIconSwitch value={user.canDriveBoxTruck ?? null} /></SectionCardRow>
        <SectionCardRow title="Can Pull Trailer"><YesNoUnknownIconSwitch value={user.canPullTrailer ?? null} /></SectionCardRow>
        <SectionCardRow title="Driving Notes"><CardContentMarkdown markdown={user.drivingNotes} /></SectionCardRow>
      </>
    );
  }, [ canEdit, user ]);

  return (
    <SectionCard title="Driving Information" id={ProfilePageSection.DRIVING}>
      {content}
    </SectionCard>
  );
};
