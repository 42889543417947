import { Box } from '@mui/material';
import { RadioGroupYesNoBoolean } from 'components';
import { formSx } from 'styles';

export const ElectricityForm: React.FC = () => {

  return (
    <Box sx={formSx.formGroup}>
      <RadioGroupYesNoBoolean
        name="electricityWanted"
        label="Is Electricity Wanted?"
        includeUnknown
        row
      />
      <RadioGroupYesNoBoolean
        name="electricityAvailable"
        label="Is Electricity Available?"
        includeUnknown
        row
      />
      <RadioGroupYesNoBoolean
        name="electricGeneratorAllowed"
        label="Is Electric Generator Allowed?"
        includeUnknown
        row
      />
    </Box>
  );
};