import { Done } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';

export type SelectionChipGroupProps = {
  options: SelectionChipOption[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export type SelectionChipOption = {
  value: string;
  label: string;
};

export const SelectionChipGroup: React.FC<SelectionChipGroupProps> = props => {

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {props.options.map((option, idx) => {
        const isSelected = option.value === props.value;

        return (
          <Chip
            key={idx}
            disabled={props.disabled}
            label={(
              <Box display="flex" alignItems="center" gap={1} pl={3}>
                <Typography variant="body2">{option.label}</Typography>
                <Done fontSize="small" sx={{ visibility: isSelected ? 'initial' : 'hidden' }} />
              </Box>
            )}
            onClick={() => !props.disabled && props.onChange(option.value)}
            variant={isSelected ? 'outlined' : 'outlined'}
            color={isSelected ? 'primary' : 'default'}
            sx={{ borderRadius: theme => theme.spacing(0.5), minWidth: 100, flex: '1 1 auto' }}
          />
        );
      })}
    </Box>
  );
};