import { EnumHelpers } from 'helpers/enums/types';

export const enum EventMissingField {
  participationStatus = 'participationStatus',
  applicationOpenDate = 'applicationOpenDate',
  applicationDeadlineDate = 'applicationDeadlineDate',
}

export const eventMissingFieldEnumHelpers: EnumHelpers<EventMissingField> & { getShortLabel: (value: EventMissingField) => string } = {
  enumValues: [ EventMissingField.participationStatus, EventMissingField.applicationOpenDate, EventMissingField.applicationDeadlineDate ],
  getLabel: (value) => {
    switch (value) {
      case EventMissingField.participationStatus:
        return 'Participation Status';
      case EventMissingField.applicationOpenDate:
        return 'Application Open Date';
      case EventMissingField.applicationDeadlineDate:
      default:
        return 'Application Deadline Date';
    }
  },
  getShortLabel: (value) => {
    switch (value) {
      case EventMissingField.participationStatus:
        return 'Part. St.';
      case EventMissingField.applicationOpenDate:
        return 'App. Open Date';
      case EventMissingField.applicationDeadlineDate:
      default:
        return 'App. Deadline Date';
    }
  },
  getColor: () => 'unknown',
};