import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FullCalendarWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  // gap: theme.spacing(1),

  // header
  '& .fc-col-header-cell-cushion': {
    fontWeight: 400,
  },

  '& .fc-bg-event': {
    opacity: 1,
  },

  '& .fc .fc-daygrid-day.fc-day-today': {
    backgroundColor: theme.palette.primary.background
  },

  // event
  // daygrid event
  '& .fc-daygrid-event': {
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer',
  },
  '& .fc-daygrid-event:hover': {
    background: 'transparent'
  },
  '& .fc-daygrid-event.fc-event:focus': {
    boxShadow: 'none',
  },
  '& .fc-daygrid-event.fc-event:focus:before': {
    all: 'initial',
  },
  '& .fc-daygrid-event.fc-event:focus:after': {
    all: 'initial',
  },
  // timegrid event
  '& .fc-timegrid-event': {
    padding: 0,
    borderRadius: 0,
    cursor: 'pointer',
    margin: 0,
  },
  '& .fc-timegrid-event .fc-event-main': {
    padding: 0,
  },
  '& .fc-v-event': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '& .fc-h-event': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
}));