import { useEffect, useMemo, useState } from 'react';
import { CheckboxActionContent } from '../CheckboxActionContent.conponent';
import { useLocalStorage } from 'hooks';

export type UseSpanEventsProps = {
  id: string;
  defaultValue?: boolean;
};

export const useSpanEvents = ({ id, defaultValue = true }: UseSpanEventsProps) => {
  const spanEventsLocalStorageService = useLocalStorage<boolean>(`${id}SpanEvents`);
  const [ spanEvents, setSpanEvents ] = useState(spanEventsLocalStorageService.getResource(defaultValue));

  useEffect(() => {
    return () => spanEventsLocalStorageService.setResource(spanEvents);
  }, [ spanEvents, spanEventsLocalStorageService ]);

  const sidebarActionConfig = useMemo(() => ({
    label: 'Span events',
    content: <CheckboxActionContent label="Span events" defaultValue={spanEventsLocalStorageService.getResource(defaultValue)} onToggle={() => setSpanEvents(p => !p)} />,
  }), [ defaultValue, spanEventsLocalStorageService ]);

  return {
    spanEvents,
    setSpanEvents,
    sidebarActionConfig,
  };
};