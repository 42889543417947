import { Box } from '@mui/material';
import { PlaceDefaultEnum } from 'api/resources';
import { PlaceDistanceAndDurationContent, TextInput, useEventContext } from 'components';
import { getEventLodgingPlaceForDate } from 'helpers';
import React, { useMemo } from 'react';
import { DateObjectType } from 'services';

export type EventDateTravelFormProps = {
  date: DateObjectType;
};

export const EventDateTravelForm: React.FC<EventDateTravelFormProps> = ({ date }) => {
  const { event } = useEventContext();
  const lodgingPlace = getEventLodgingPlaceForDate(event, date);

  const placeDistanceAndDurationDisplay = useMemo(() => {
    if (lodgingPlace) {
      return (
        <PlaceDistanceAndDurationContent
          fromLabel="Lodging"
          toLabel="Event"
          placeMatrix={lodgingPlace.place.toEventMatrix}
          routeDetails={{
            fromAddress: lodgingPlace.place.address,
            toAddress: event.place.address,
          }}
        />
      );
    }

    return (
      <PlaceDistanceAndDurationContent
        fromLabel="Studio"
        toLabel="Event"
        placeMatrix={event.place.fromStudioMatrix}
        routeDetails={{
          fromAddress: PlaceDefaultEnum.studio,
          toAddress: event.place.address,
        }}
      />
    );
  }, [ event.place.address, event.place.fromStudioMatrix, lodgingPlace ]);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <TextInput
        type="number"
        name="bufferTimeInMinutes"
        label="Buffer (minutes)"
        fullWidth
        sx={{ flex: 1 }}
      />
      <Box flex={1}>
        {placeDistanceAndDurationDisplay}
      </Box>
    </Box>
  );
};
