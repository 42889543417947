import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Portal, Typography, useTheme } from '@mui/material';
import { Loading } from 'components';
import { useEffect, useState } from 'react';

export type ImagesCarouselProps = {
  initialIndex?: number;
  imageSrcs: string[];
  onClose: () => void;
  renderInfo?: (index: number) => React.ReactNode;
  isLoading?: boolean;
  renderActions?: (index: number) => React.ReactNode;
  isProductImagesDisplay?: boolean;
};

export const ImagesCarousel: React.FC<ImagesCarouselProps> = ({ initialIndex, imageSrcs, onClose, renderInfo, isLoading, renderActions, isProductImagesDisplay }) => {
  const [ current, setCurrent ] = useState(initialIndex ?? 0);
  const theme = useTheme();

  const handlePrev = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setCurrent(current => (current - 1 + imageSrcs.length) % imageSrcs.length);
  };

  const handleNext = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setCurrent(current => (current + 1) % imageSrcs.length);
  };

  useEffect(() => {
    if (!imageSrcs.length) {
      onClose();
    }
    if (current < 0 || current > imageSrcs.length - 1) {
      setCurrent(0);
    }
  }, [ current, imageSrcs.length, onClose ]);

  return (
    <Portal>
      <Box
        component="div"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 1300,
          py: theme.spacing(2)
        }}
        onClick={onClose}
      >
        {isLoading && <Loading />}
        <Box display="flex" justifyContent="space-between" alignItems="center" height="100%" zIndex={1301}>
          <Box
            component="div"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            onClick={handlePrev}
            pl={1}
            pr={2}
            color="common.white"
            visibility={imageSrcs.length > 1 ? 'initial' : 'hidden'}
            sx={{
              transition: 'background 0.3s ease',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            <ArrowBackIosNewOutlined />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" flex={1}>
            {imageSrcs[current] && (
              <>
                <Box display="flex" justifyContent="space-between">
                  <IconButton onClick={onClose} sx={{ color: 'common.white' }}>
                    <CloseOutlined />
                  </IconButton>
                  <Typography color="common.white">
                    {renderInfo?.(current)}
                  </Typography>
                  <Box>{renderActions?.(current)}</Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
                  <img
                    key={current}
                    src={imageSrcs[current]}
                    alt={`Slide ${current}`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '90vh',
                      display: 'block',
                      userSelect: 'none',
                      backgroundColor: isProductImagesDisplay ? theme.palette.common.white : 'transparent',
                      borderRadius: isProductImagesDisplay ? '50%' : 0,
                      boxShadow: isProductImagesDisplay ? 'inset 0 0 0 1px black' : 'none',
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box
            component="div"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            onClick={handleNext}
            pl={2}
            pr={1}
            color="common.white"
            visibility={imageSrcs.length > 1 ? 'initial' : 'hidden'}
            sx={{
              transition: 'background 0.3s ease',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            <ArrowForwardIosOutlined />
          </Box>
        </Box>
      </Box>
    </Portal>
  );
};