import { Box, Divider, Typography } from '@mui/material';
import { PageContentContainer, PageContentHeader } from 'components';
import { CreateBatchCards } from './components/CreateBatchCards.component';
import { useInventoryBatches } from 'queries';
import { useProductsPageContext } from 'contexts';
import { InventoryBatchesTable } from 'components/Table/custom';

export type InventoryBatchesProps = {

};

export const InventoryBatches: React.FC<InventoryBatchesProps> = () => {
  const { teams } = useProductsPageContext();
  const { data: inventoryBatches = [], isInitialLoading: loading } = useInventoryBatches();

  return (
    <PageContentContainer header={<PageContentHeader title="Inventory Batches" />} >
      <Box display="flex" flexDirection="column" gap={3}>
        <Box minHeight="420px">
          <InventoryBatchesTable teams={teams} inventoryBatches={inventoryBatches} loading={loading} sx={{ maxHeight: 'unset' }} />
        </Box>
        <Box flex={1}>
          <Box mb={2}>
            <Box>
              <Typography variant="h6">Create a New Batch</Typography>
            </Box>
            <Divider />
          </Box>
          <CreateBatchCards inventoryBatches={inventoryBatches} />
        </Box>
      </Box>
    </PageContentContainer>
  );
};