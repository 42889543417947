import React from 'react';


export type StickyStackContextValue = {
  top: number[];
  bottom: number[];
};


export const StickyStackContext = React.createContext<StickyStackContextValue | null>(null);
export const StickyStackSetValueContext = React.createContext<React.Dispatch<React.SetStateAction<StickyStackContextValue>> | null>(null);


export const useStickyStackContext = () => {
  const value = React.useContext(StickyStackContext);

  if (value === null) {
    console.warn('Sticky Stack will be not working. Need to use `StickyStackProvider`');
  }

  return value;
};

export type StickyStackProviderProps = {
  children: React.ReactNode;
};

export const StickyStackProvider: React.FC<StickyStackProviderProps> = ({ children }) => {
  const [ state, setState ] = React.useState<StickyStackContextValue>({ top: [], bottom: [] });

  return (
    <StickyStackContext.Provider value={state}>
      <StickyStackSetValueContext.Provider value={setState}>
        {children}
      </StickyStackSetValueContext.Provider>
    </StickyStackContext.Provider>
  );
};
