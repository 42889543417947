import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { eventsManagementInfoConfigurations, eventsManagementTreeViewNodeIdEnumHelpers } from '../helpers';
import { FilterFieldConfiguration, FilterValidFilterObject, InfoPopper } from 'components';
import { getNonDefaultFilterDisplay } from 'components/Filter/helpers';

export type EventsManagementInfoPopperProps =
  | {
    tab: keyof typeof eventsManagementInfoConfigurations;
    filter: FilterValidFilterObject;
    filterConfigurations: FilterFieldConfiguration[];
  }
  | {
    tab: keyof typeof eventsManagementInfoConfigurations;
    filter?: never;
    filterConfigurations?: never;
  };

export const EventsManagementInfoPopper: React.FC<EventsManagementInfoPopperProps> = props => {
  const config = eventsManagementInfoConfigurations[props.tab];

  const nonDefaultFiltersContent = useMemo(() => {
    if('filterConfigurations' in props && props.filterConfigurations && props.filter) {
      return getNonDefaultFilterDisplay(props.filter, props.filterConfigurations);
    }

    return null;
  }, [ props ]);

  if (!config) {
    return null;
  }

  return (
    <InfoPopper>
      <Typography variant="subtitle2" mb={1} fontWeight={600}>{eventsManagementTreeViewNodeIdEnumHelpers.getFullLabel(props.tab)}</Typography>
      <Typography variant="body2" mb={1}>
        {config.description}
      </Typography>
      <Typography variant="body2">
        {nonDefaultFiltersContent && (
          <>
            <Typography variant="subtitle2" fontWeight={600}>Non-default filters applied:</Typography>
            <Box display="flex" flexDirection="column">
              {nonDefaultFiltersContent}
            </Box>
          </>
        )}
      </Typography>
    </InfoPopper>
  );
};