import { EventDateContent, EventDateContentData, EventDateContentProps } from '../EventDate/EventDateContent.component';
import { Box } from '@mui/material';
import { EventContent, EventContentData, EventContentProps } from './EventContent.component';

type EventWithEventDateContentData = EventContentData & EventDateContentData;

export type EventWithEventDateContentProps = {
  data: EventWithEventDateContentData;
  eventDateProps?: Omit<EventDateContentProps, 'data'>;
} & Pick<EventContentProps, 'collapsable' | 'actions' | 'disableActionsDivider'>;

export const EventWithEventDateContent: React.FC<EventWithEventDateContentProps> = (props) => {

  return (
    <EventContent {...props}>
      {(isExpanded) => (
        <Box maxWidth="500px">
          <EventDateContent isExpanded={isExpanded} data={props.data} {...props.eventDateProps} />
        </Box>
      )}
    </EventContent>
  );
};