import { userInfoValidationSchema } from 'pages/Profile/validation';
import { UserResourceForm } from 'types';
import { SchemaOf, object, string } from 'yup';


export const userNewValidationSchema = (): SchemaOf<UserResourceForm['new']> => {
  return object({
    password: string().min(6, 'Too short').required('Password is required'),
    passwordConfirmation: string().min(6, 'Too short').required('Password confirmation is required'),
    pin: string().length(4, 'Pin must be 4 digits long').required('Pin is required'),
    pinConfirmation: string().length(4, 'Pin must be 4 digits long').required('Pin confirmation is required'),
  }).concat(userInfoValidationSchema());
};