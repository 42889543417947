import { VehicleFileTypeEnum, VehicleFuelTypeEnum } from 'api/resources';
import { UnknownEnum } from 'types';
import { EnumHelpers } from './types';

const fuelType: EnumHelpers<VehicleFuelTypeEnum | UnknownEnum> = {
  enumValues: [
    VehicleFuelTypeEnum.gasoline,
    VehicleFuelTypeEnum.diesel,
    VehicleFuelTypeEnum.electric,
    VehicleFuelTypeEnum.hybrid,
    VehicleFuelTypeEnum.biofuel,
    VehicleFuelTypeEnum.hydrogen,
    VehicleFuelTypeEnum.naturalGas,
    UnknownEnum.unknown,
  ],
  getLabel: value => {
    switch (value) {
      case VehicleFuelTypeEnum.gasoline:
        return 'Gasoline';
      case VehicleFuelTypeEnum.diesel:
        return 'Diesel';
      case VehicleFuelTypeEnum.electric:
        return 'Electric';
      case VehicleFuelTypeEnum.hybrid:
        return 'Hybrid';
      case VehicleFuelTypeEnum.biofuel:
        return 'Biofuel';
      case VehicleFuelTypeEnum.hydrogen:
        return 'Hydrogen';
      case VehicleFuelTypeEnum.naturalGas:
        return 'Natural Gas';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

const file: EnumHelpers<VehicleFileTypeEnum> = {
  enumValues: [
    VehicleFileTypeEnum.insurance,
    VehicleFileTypeEnum.registration,
    VehicleFileTypeEnum.other
  ],
  getLabel: (value) => {
    switch (value) {
      case VehicleFileTypeEnum.registration:
        return 'Registration';
      case VehicleFileTypeEnum.insurance:
        return 'Insurance';
      default:
        return 'Other';
    }
  },
  getColor: () => 'unknown'
};

export const vehicleEnumHelpers = {
  fuelType,
  file,
};