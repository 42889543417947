import { GetEventResponse } from 'api/actions';
import { getEventDateImagesWarnings } from 'helpers';
import React from 'react';
import { useEventContext } from '../event.context';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Stack, Popper, ClickAwayListener, Grow, Box, IconButton, Typography, Button } from '@mui/material';
import { DateService } from 'services';
import { EventDateImageWarnings } from 'components';
import { EventDateImagesList } from './EventDateImagesList.component';

export type EventDateImagesCarouselConfig = {
  eventDateIndex: number;
  initialIndex: number;
};
export type UploadEventDateImageModalConfig = {
  eventDateIndex: number;
};
export type EventDatesPhotosProps = {
  eventDates: GetEventResponse['data']['dates'];
  popperEventDateId: string | null;
  popperAnchorEl: HTMLButtonElement | null;
  handlePopperClose: () => void;
  handleWarningAmberClick: (eventDateId: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  setUploadImageModalConfig: React.Dispatch<React.SetStateAction<UploadEventDateImageModalConfig | null>>;
  setCarouselConfig: React.Dispatch<React.SetStateAction<EventDateImagesCarouselConfig | null>>;
};

export const EventDatesPhotos: React.FC<EventDatesPhotosProps> = ({
  eventDates,
  popperEventDateId,
  popperAnchorEl,
  handlePopperClose,
  handleWarningAmberClick,
  setUploadImageModalConfig,
  setCarouselConfig
}) => {
  const { event } = useEventContext();
  const getEventDateIndex = (eventDateId: string) => event.dates.findIndex(eventDate => eventDate._id === eventDateId);

  return (
    <>
      {eventDates.map((eventDate, idx) => {
        const eventDateIndex = getEventDateIndex(eventDate._id);
        const hasWarnings = !!Object.values(getEventDateImagesWarnings(eventDate, event.dates)).length;
        const open = popperEventDateId === eventDate._id;
        const popperId = open ? `event_date_photos_popper_${eventDate._id}` : undefined;

        return (
          <Stack gap={1} key={idx}>
            <Popper id={popperId} open={open} anchorEl={popperAnchorEl} transition placement="bottom-start">
              {({ TransitionProps }) => {
                return (
                  <ClickAwayListener onClickAway={handlePopperClose}>
                    <Grow {...TransitionProps}>
                      <Box py={1} pr={1} bgcolor="background.paper" border={theme =>`1px solid ${theme.palette.grey[300]}`}>
                        <EventDateImageWarnings event={event} eventDate={eventDate} variant="body2" />
                      </Box>
                    </Grow>
                  </ClickAwayListener>
                );
              }}
            </Popper>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex" alignItems="center">
                {hasWarnings && (
                  <IconButton onClick={handleWarningAmberClick(eventDate._id)}>
                    <WarningAmberOutlined color="warning" fontSize="small" />
                  </IconButton>
                )}
                <Typography fontWeight={500}>{DateService.getFormattedDate(DateService.dayjsTz(eventDate.dateAsUtc))}</Typography>
                <Typography variant="body2" color="text.secondary" display="flex" alignItems="center" ml={1}>(Day {eventDateIndex + 1} of {event.dates.length})</Typography>
              </Box>
              <Button onClick={() => setUploadImageModalConfig({ eventDateIndex: eventDateIndex })} size="small" variant="outlined">+ Add</Button>
            </Box>
            <Box>
              <EventDateImagesList images={eventDate.images} onClick={(imageIndex) => setCarouselConfig({ eventDateIndex: eventDateIndex, initialIndex: imageIndex })} size={170} />
            </Box>
          </Stack>
        );
      })}
    </>
  );
};
