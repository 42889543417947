import { SectionCardRowEditable, SectionCardRowEditableList, SectionCardRowEditableListProps, SectionCardRowEditableProps } from 'components';
import { EventPageSectionRowId, EventSectionRowForm } from '../types';
import { useEventContext } from '../event.context';
import { useMemo } from 'react';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';

export type EventSectionCardRowEditableProps<RowId extends EventPageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<EventSectionRowForm[RowId]>, 'rowId' | 'invalidateQueriesHandler' | 'warningDescription' | 'disableEditable'>;

export const EventSectionCardRowEditable = <RowId extends EventPageSectionRowId>(props: EventSectionCardRowEditableProps<RowId>) => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const { getRowWarningDescription, invalidateQueriesHandler } = useEventContext();
  const description = getRowWarningDescription(props.rowId);

  const disableEditable = useMemo(() => {
    return !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  }, [ isAuthorizedEmployee ]);

  return (
    <SectionCardRowEditable
      {...props}
      invalidateQueriesHandler={invalidateQueriesHandler}
      warningDescription={description}
      disableEditable={disableEditable}
    />
  );
};

export type EventSectionCardRowEditableListProps<RowId extends keyof EventSectionRowForm, ListItem extends {_id: string}> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableListProps<EventSectionRowForm[RowId], ListItem>, 'rowId' | 'invalidateQueriesHandler' | 'warningDescription' | 'disableEditable'>;

export const EventSectionCardRowEditableList = <RowId extends keyof EventSectionRowForm, ListItem extends {_id: string}>(props: EventSectionCardRowEditableListProps<RowId, ListItem>) => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const { getRowWarningDescription, invalidateQueriesHandler } = useEventContext();
  const description = getRowWarningDescription(props.rowId);

  const disableEditable = useMemo(() => {
    return !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]);
  }, [ isAuthorizedEmployee ]);

  return (
    <SectionCardRowEditableList
      {...props}
      invalidateQueriesHandler={invalidateQueriesHandler}
      warningDescription={description}
      disableEditable={disableEditable}
    />
  );
};