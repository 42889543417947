import { authAxiosRequest } from 'api/axios';
import { Genre, Place, Product, ProductStock, Sale, SalePaymentEnum, Storefront, User } from 'api/resources';
import { GetEventsResponse } from './event';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { GetUsersAsResourceResponse } from './user';

export type CreateLiveSaleBaseInput = {
  user: string;
  payment: SalePaymentEnum;
  sales: {
    product: string;
    salePrice: number;
    frame: boolean;
  }[];
};
export type CreateLiveSaleForEventDateInput = CreateLiveSaleBaseInput & {
  eventDate: string;
};
export type CreateLiveSaleForStorefrontInput = CreateLiveSaleBaseInput & {
  storefront: string;
};

export const createLiveSaleForEventDate = (data: CreateLiveSaleForEventDateInput) => {
  return authAxiosRequest('/api/log-live-sale/createLogLiveSale/event-date', { method: 'POST', data });
};
export const createLiveSaleForStorefront = (data: CreateLiveSaleForStorefrontInput) => {
  return authAxiosRequest('/api/log-live-sale/createLogLiveSale/storefront', { method: 'POST', data });
};

type BaseLoggingPortalData = {
  userOptions: GetUsersAsResourceResponse['data'];
  products: ResourceWithPopulated<PickFromResource<Product, 'name' | 'availability' | 'imageUrl'>, {
    stocks: ProductStock[];
    genres: ResourceWithPopulated<Product['genres'][number], { genre: Genre }>[];
    salesCount: number;
  }>[];
  recentSales: ResourceWithPopulated<Sale, {
    user: PickFromResource<User, 'name'>;
  }>[];
};
export type LoggingPortalDataForEventDate = BaseLoggingPortalData & {
  event: GetEventsResponse['data'][number];
  eventDate: GetEventsResponse['data'][number]['dates'][number];
};
export type LoggingPortalDataForStorefront = BaseLoggingPortalData & {
  storefront: ResourceWithPopulated<Storefront, {
    place: Place;
  }>;
};

export type GetLoggingPortalDataResponse = {
  data: LoggingPortalDataForEventDate | LoggingPortalDataForStorefront;
};

export const enum LoggingPortalResourceTypeEnum {
  eventDate = 'eventDate',
  storefront = 'storefront',
}

export const getLoggingPortalDataForEventDate = (eventDateId: string) => {
  return authAxiosRequest<{ data: LoggingPortalDataForEventDate }>(`/api/log-live-sale/logging-portal/event-date/${eventDateId}`);
};

export const getLoggingPortalDataForStorefront = (storefrontId: string) => {
  return authAxiosRequest<{ data: LoggingPortalDataForStorefront }>(`/api/log-live-sale/logging-portal/storefront/${storefrontId}`);
};