import { authAxiosRequest } from 'api/axios';
import { Product, ProductActiveBaselineTypeEnum, ProductStock, User } from 'api/resources';
import { Genre } from 'api/resources/genre';
import { CreateResourceResponse, DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { DeepPartial } from 'types';

export type GetProductsResponse = {
  data: ResourceWithPopulated<Product, {
    stocks: {
      [teamId: string]: ProductStock;
    };

    salesCount: number;
    prevSixMonthSalesCount: number;
    activeBaseline: number;
    activeWarehouseBaseline: number;
    autoWarehouseBaseline: number;
    manualWarehouseBaseline?: number;
    salesRank: number;
    genres: ResourceWithPopulated<Product['genres'][number], { genre: Genre }>[];
    createdBy?: PickFromResource<User, 'name'>;
  }>[];
};

export const getProducts = () => {
  return authAxiosRequest<GetProductsResponse>('/api/products');
};


export type GetProductsAsResourceOptionsResponse = {
  data: PickFromResource<Product, 'name' | 'availability' | 'imageUrl'>[];
};

export const getProductsAsResourceOptions = () => {
  return authAxiosRequest<GetProductsAsResourceOptionsResponse>('/api/products', { params: { asResource: 'options' } });
};

export type GetProductResponse = {
  data: GetProductsResponse['data'][number];
};

export const getProduct = (id: string) => {
  return authAxiosRequest<GetProductResponse>(`/api/products/${id}`);
};

export type CreateProductInput = DeepOptionalToNull<{
  name: string;
  sku: string;
  price: number;
  category: string;
  notes?: string;
  imageUrl?: string;
  availability: {
    events?: boolean;
    website?: boolean;
    uploaded?: boolean;
  };
  needsAttention: boolean;
  preperation: {
    rasterLayer?: boolean;
    centerLabel?: boolean;
    png?: boolean;
    dmc?: boolean;
    dwg?: boolean;
    divider?: boolean;
    websitePngs?: boolean;
  };
  genres: {
    genre: string;
    order: number;
  }[];
}>;

export const createProduct = (data: CreateProductInput) => {
  return authAxiosRequest<CreateResourceResponse>('/api/products', { method: 'POST', data });
};

export type UpdateProductInput = DeepPartial<CreateProductInput & DeepOptionalToNull<{
  manualBaseline: number;
  activeBaselineType: ProductActiveBaselineTypeEnum;
}>>;

export const updateProduct = (id: string, data: UpdateProductInput) => {
  return authAxiosRequest<GetProductResponse>(`/api/products/${id}`, { method: 'PUT', data });
};

export const deleteProduct = (id: string) => {
  return authAxiosRequest(`/api/products/${id}`, { method: 'DELETE' });
};