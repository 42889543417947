import { Box, Button, Grid } from '@mui/material';
import { PageContentContainer, useEventContext } from 'components';
import { EventDatesCard } from './components';
import { EventPageHeader } from 'pages/Event/components';
import { EventDatesCalendar } from './components/EventDatesCalendar/EventDatesCalendar.component';
import { EventSchedulingCard } from '../../components/EventSchedulingCard.component';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';

export const EventDates = () => {
  const navigate = useNavigate();
  const { event } = useEventContext();

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [
          { link: ROUTING_CONFIG.events, text: 'Events' },
          { link: `${ROUTING_CONFIG.events}/${event._id}`, text: event.name }
        ],
        current: 'Event Dates'
      }}
      header={
        <EventPageHeader
          pageTitle="Event Dates Page"
          actions={<Button onClick={() => navigate(ROUTING_CONFIG.scheduling)}>Scheduling</Button>}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={7.7} alignSelf="start">
          <EventDatesCalendar />
        </Grid>
        <Grid item xs={12} md={4.3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <EventSchedulingCard />
            <EventDatesCard
              showButton={false}
              isCalendar
            />
          </Box>
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};
