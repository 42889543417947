import { Box } from '@mui/material';
import { PasswordTextInput, PinInput } from 'components';
import { formSx } from 'styles';

export const ChangePinForm = () => {

  return (
    <Box sx={formSx.formGroup}>
      <PasswordTextInput
        name="password"
        label="Password"
        helperText="Enter your current password"
      />
      <PinInput
        name="newPin"
        helperText="Enter a new 4 digit pin"
        label="Pin"
      />
    </Box>
  );
};