import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import { DateObjectType, DateService } from 'services';

export type FormDateTimePickerProps = {
  name: string;
  helperText?: string;
} & DateTimePickerProps<DateObjectType>;

export const FormDateTimePicker: React.FC<FormDateTimePickerProps> = props => {
  const [ field, meta, helpers ] = useField<string | null>(props.name);

  return (
    <DateTimePicker
      {...props}
      value={field.value ? DateService.dayjs(field.value) : null}
      onChange={(date) => helpers.setValue(date ? date.toISOString() : null, true)}
      slotProps={{
        textField: {
          error: !!meta.error,
          helperText: meta.error ?? props.helperText,
        },
      }}
    />
  );
};