import { EventsManagementTable } from 'pages/EventsManagement/components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import React from 'react';

export const LogisticsStaffScheduleList = () => {
  return (
    <EventsManagementTable
      nodeId={EventsManagementTreeViewNodeIdEnum.logisticStaffScheduleList}
      localStorageArgs={{
        initialVisible: [ 'name', 'eventTimeframe', 'staffStatus', 'studioToEvent', 'teamName', 'staffList', 'spaceSize' ],
        initialSortModel: [ { field: 'eventTimeframe', sort: 'asc' } ],
      }}
      groupByWeek
    />
  );
};
