import { AutoFixHighOutlined, SaveOutlined } from '@mui/icons-material';
import { Box, Input, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';

export type InputWithSaveCellProps = {
  value?: number;
  onChange: (value: number | null) => void;
  onSave: () => void;
  error?: string;
  note?: string;
  hasFocus: boolean;
  autoFillValue?: number;
  disabled?: boolean;
};

type StyledTooltipProps = TooltipProps & {variant: 'error' | 'warning'};

const StyledTooltip = styled(({ className, ...props }: StyledTooltipProps ) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, variant }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: variant === 'error' ? theme.palette.error.main : theme.palette.warning.main,
    color: variant === 'error' ? theme.palette.error.contrastText : theme.palette.warning.contrastText,
  },
}));

export const InputWithSaveCell: React.FC<InputWithSaveCellProps> = ({
  value,
  onChange,
  onSave,
  hasFocus,
  error,
  note,
  autoFillValue,
  disabled
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e.target.value;

    if (newValue === '') {
      onChange(null);
      return;
    }

    if (!(/^[0-9]*$/.test(newValue))) {
      return;
    }

    const value = Math.abs(Number(newValue));

    onChange(value);
  };

  const handleSave = () => {
    onSave();
  };

  const handleAutoFill = () => {
    if (autoFillValue) {
      onChange(autoFillValue);
    }
  };

  return (
    <StyledTooltip title={error ?? note} variant={error ? 'error' : 'warning'}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Input
          color={error ? 'error' : undefined}
          inputRef={input => input && hasFocus && input.focus()}
          value={value ?? ''}
          onChange={handleChange}
          sx={(theme) => ({ width: '100%', ':before': { borderBottomColor: error ? theme.palette.error.main : undefined } })}
          inputProps={{ inputProps: { inputMode: 'numeric' } }}
          disabled={disabled}
        />
        {autoFillValue &&
          <GridActionsCellItem
            icon={<AutoFixHighOutlined />}
            label="Cancel"
            className="textPrimary"
            onClick={handleAutoFill}
            color="primary"
          />
        }
        <GridActionsCellItem
          icon={<SaveOutlined />}
          label="Save"
          onClick={handleSave}
          disabled={!!error || !value || disabled}
          color="success"
        />
      </Box>
    </StyledTooltip>
  );
};