import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { currencyFormatter } from 'services';

export type ProgressBarProps = {
  currentAmount: number;
  expectedAmount: number;
  allowOverflow?: boolean;
  isCurrency?: boolean;
};

const getColor = (percentage: number, allowOverflow?: boolean) => {
  if (percentage === 100 || (allowOverflow && percentage > 100)) {
    return 'success';
  }
  if (percentage >= 50) {
    return 'warning';
  }
  return 'error';
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ currentAmount, expectedAmount, isCurrency, allowOverflow }) => {
  const percentage = useMemo(() => {
    if (expectedAmount === 0) {
      if (currentAmount !== 0 && !allowOverflow) {
        return 0;
      }

      return 100;
    }

    return currentAmount / expectedAmount * 100;
  }, [ allowOverflow, currentAmount, expectedAmount ]);
  const color = getColor(percentage, allowOverflow);

  const label = useMemo(() => {
    if (isCurrency) {
      return `${currencyFormatter.format(currentAmount)} / ${currencyFormatter.format(expectedAmount)}`;
    }

    return `${currentAmount} / ${expectedAmount}`;
  }, [ currentAmount, expectedAmount, isCurrency ]);

  return (
    <Box
      height={32}
      width="100%"
      sx={(theme) => {
        return {
          border: `1px solid ${theme.palette[color].light}`,
          position: 'relative',
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
        };
      }}
    >
      <Box
        width={`${percentage}%`}
        sx={(theme) => ({
          height: '100%',
          background: theme.palette[color].background,
          borderRight: (percentage > 0 && percentage < 100) ? `1px solid ${theme.palette[color].main}` : undefined,
        })}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" fontWeight={500} color={`${color}.main`}>{label}</Typography>
      </Box>
    </Box>
  );
};