import { Box, BoxProps, Typography } from '@mui/material';
import { ColorCodeKeyConfig } from './colorCode';

export type ColorCodeKeysProps = {
  colorKeys: ColorCodeKeyConfig[];
} & BoxProps;

export const ColorCodeKeys: React.FC<ColorCodeKeysProps> = ({ colorKeys, ...boxProps }) => {
  return (
    <Box display="flex" gap={1} flexWrap="wrap" {...boxProps}>
      {colorKeys.map((colorKey) => (
        <Box key={colorKey.keyLabel}>
          <Typography
            variant="body2"
            color={colorKey.color}
            bgcolor={colorKey.background}
            sx={{
              px: 1,
              borderRadius: '4px',
              lineHeight: 1,
              py: 0.5,
            }}
          >
            {colorKey.keyLabel}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
