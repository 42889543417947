import { SectionCardRowEditable, SectionCardRowEditableList, SectionCardRowEditableListProps, SectionCardRowEditableProps } from 'components';
import { EventOrganizerPageSectionRowId, EventOrganizerSectionRowForm } from '../types';
import { QUERY_KEY } from 'queries/query-keys';
import { useEventOrganizerOutletContext } from '../EventOrganizer.base';

export type EventOrganizerSectionCardRowEditableProps<RowId extends EventOrganizerPageSectionRowId> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableProps<EventOrganizerSectionRowForm[RowId]>, 'rowId' | 'invalidateQueryKeys'>;

export const EventOrganizerSectionCardRowEditable = <RowId extends EventOrganizerPageSectionRowId>(props: EventOrganizerSectionCardRowEditableProps<RowId>) => {
  const { eventOrganizer } = useEventOrganizerOutletContext();

  return <SectionCardRowEditable {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_ORGANIZER(eventOrganizer._id) })} />;
};

export type EventOrganizerSectionCardRowEditableListProps<RowId extends keyof EventOrganizerSectionRowForm, ListItem extends {_id: string}> = {
  rowId: RowId;
} & Omit<SectionCardRowEditableListProps<EventOrganizerSectionRowForm[RowId], ListItem>, 'rowId' | 'invalidateQueryKeys'>;

export const EventOrganizerSectionCardRowEditableList = <RowId extends keyof EventOrganizerSectionRowForm, ListItem extends {_id: string}>(props: EventOrganizerSectionCardRowEditableListProps<RowId, ListItem>) => {
  const { eventOrganizer } = useEventOrganizerOutletContext();

  return <SectionCardRowEditableList {...props} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_ORGANIZER(eventOrganizer._id) })} />;
};