import { Close, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { logoImg } from 'assets';
import React from 'react';
import { REACT_APP_ENVIRONMENT } from 'config/environment.config';
import { AppbarProfileContent } from './AppbarProfileContent.component';

export type AppbarType = {
  open?: boolean;
  onToggleDrawer?: () => void;
};

export const AppBar = styled(MuiAppBar)(({ theme }) => {
  return {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  };
});

export const Appbar: React.FC<AppbarType> = ({ open, onToggleDrawer }) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onToggleDrawer?.();
  };

  return (
    <AppBar variant="elevation">
      <Toolbar>
        <IconButton
          onClick={onClick}
          edge="start"
          color="inherit"
          sx={{ display: { sm: 'none' } }}
        >
          {open ? <Close /> : <MenuIcon />}
        </IconButton>
        <Box
          component="img"
          sx={{ height: (theme) => theme.spacing(5.5) }}
          src={logoImg}
          display="flex"
        />
        {REACT_APP_ENVIRONMENT === 'staging' && <Typography color="primary.contrastText" fontWeight={700} variant="h5">STAGING ENVIRONMENT</Typography>}
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <AppbarProfileContent />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
