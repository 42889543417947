import { CreateOutlined, FlakyOutlined, StarHalfOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FormGroupLayout, MarkdownInput, RadioGroupYesNoBoolean, Rating } from 'components';
import React from 'react';
import { formSx } from 'styles';
import { getEventFeedbackIcon } from '../helpers';

export const EventFeedbackForm = () => {
  return (
    <Box sx={formSx.formGroup}>
      <FormGroupLayout leftIcon={StarHalfOutlined}>
        <Rating Icon={getEventFeedbackIcon('overall')} label="How did you like the show?" name="overall" />
        <Rating Icon={getEventFeedbackIcon('busyness')} label="How busy was it?" name="busyness" />
        <Rating Icon={getEventFeedbackIcon('sales')} label="How were the sales?" name="sales" />
        <Rating Icon={getEventFeedbackIcon('food')} label="Was there good food available?" name="food" />
        <Rating Icon={getEventFeedbackIcon('artists')} label="Was there a wide range of high-quality artists?" name="artists" />
        <Rating Icon={getEventFeedbackIcon('parking')} label="Was parking easy?" name="parking" />
      </FormGroupLayout>
      <FormGroupLayout leftIcon={FlakyOutlined}>
        <RadioGroupYesNoBoolean
          label="Could you pull up to the spot to unload?"
          Icon={getEventFeedbackIcon('unloadPullUp')}
          name="unloadPullUp"
          row
        />
        <RadioGroupYesNoBoolean
          label="Could you pull up to the spot to pack up?"
          Icon={getEventFeedbackIcon('packUpPullUp')}
          name="packUpPullUp"
          row
        />
        <RadioGroupYesNoBoolean
          label="If we had more help, would we have sold more?"
          Icon={getEventFeedbackIcon('needMoreStaff')}
          name="needMoreStaff"
          row
        />
        <RadioGroupYesNoBoolean
          label="Were sales affected by weather?"
          Icon={getEventFeedbackIcon('weatherAffectedSales')}
          name="weatherAffectedSales"
          row
        />
        <RadioGroupYesNoBoolean
          label="Would you recommend we do this show again?"
          Icon={getEventFeedbackIcon('doAgain')}
          name="doAgain"
          row
        />
        <RadioGroupYesNoBoolean
          label="Was the event big enough that we could have used 2 spaces?"
          Icon={getEventFeedbackIcon('twoSpaces')}
          name="twoSpaces"
          row
        />
      </FormGroupLayout>
      <FormGroupLayout leftIcon={CreateOutlined}>
        <MarkdownInput name="nextYearRecommendations" label="Was there anything youd recommend we do differently or have for next year?" />
        <MarkdownInput name="note" label="Notes" />
      </FormGroupLayout>
    </Box>
  );
};