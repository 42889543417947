import { GetInventoryBatchResponse } from 'api/actions';
import { Loading } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { ProductsPageContextProvider } from 'contexts';
import { useInventoryBatch } from 'queries';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

type UseInventoryBatchOutletContext = {
  inventoryBatch: GetInventoryBatchResponse['data'];
};

export const useInventoryBatchOutletContext = () => useOutletContext<UseInventoryBatchOutletContext>();

const InventoryBatchBaseRaw: React.FC<{inventoryBatchId: string}> = ({ inventoryBatchId }) => {
  const { data: inventoryBatch, isInitialLoading: loading } = useInventoryBatch(inventoryBatchId, { retry: false });

  if (loading) {
    return <Loading />;
  }

  if (!inventoryBatch) {
    return <Navigate to={`/${ROUTING_CONFIG.inventoryBatches}`} replace />;
  }

  return <Outlet context={{ inventoryBatch }} />;
};

export const InventoryBatchBase = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={`/${ROUTING_CONFIG.inventoryBatches}`} replace />;
  }

  return (
    <ProductsPageContextProvider waitForTeams waitForProducts>
      <InventoryBatchBaseRaw inventoryBatchId={id} />
    </ProductsPageContextProvider>
  );
};
