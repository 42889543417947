import { ScrollableView } from 'components';
import { useEventContext } from '../event.context';
import { Box, ClickAwayListener, Divider, Fade, Popper, Theme, Typography, alpha, lighten, useTheme } from '@mui/material';
import { UnknownEnum } from 'types';
import { DateService } from 'services';
import React, { useState } from 'react';
import { GetEventResponse } from 'api/actions';
import { EventDateTimePeriodTypeEnum, EventDateTimelineTimePoint, GetEventDateTimelineConfigsProps, eventDateTimePeriodTypeEnumHelpers, getEventDateTimes } from 'helpers/event-date-times';
import { EventDateTypeEnum } from 'api/resources';

const HOUR_WIDTH = 20;
const DATE_LABEL_WIDTH = 110;

const getTimeInMinutes = (time: string) => {
  const [ hours, minutes ] = time.split(':').map(Number);

  return hours + minutes / 60;
};
const getTimePeriodLength = (startTime: string, endTime: string) => {
  const startTimeInMinutes = getTimeInMinutes(startTime);
  const endTimeInMinutes = getTimeInMinutes(endTime);
  let timePeriodLength = endTimeInMinutes - startTimeInMinutes;

  if(timePeriodLength < 0) {
    timePeriodLength += 24;
  }

  return timePeriodLength;
};

type PopperConfig = {
  eventDate: GetEventResponse['data']['dates'][number];
  index: number;
};

const getTimes = (props: GetEventDateTimelineConfigsProps): EventDateTimelineTimePoint[] => {
  if (props.eventDate.type === EventDateTypeEnum.travel) {
    return [
      { type: 'primary' as const, time: '00:00', label: 'Departure', followingTimePeriodType: EventDateTimePeriodTypeEnum.travel },
      { type: 'primary' as const, time: '23:59', label: 'Arrival', followingTimePeriodType: UnknownEnum.unknown },
    ];
  }

  return getEventDateTimes(props, (time) => time.type === 'primary');
};

export const EventDatesTimelines: React.FC = () => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  const [ popperConfig, setPopperConfig ] = useState<PopperConfig | null>(null);
  const { event } = useEventContext();
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLElement>, eventDate: GetEventResponse['data']['dates'][number], index: number) => {
    if (!popperConfig || (popperConfig.eventDate._id !== eventDate._id || popperConfig.index !== index)) {
      setAnchorEl(e.currentTarget);
      setPopperConfig({ eventDate, index });
    } else {
      setAnchorEl(null);
      setPopperConfig(null);
    }
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
    setPopperConfig(null);
  };

  const handleScrollCapture = () => {
    setPopperConfig(null);
    setAnchorEl(null);
  };

  const backgroundGradient = (theme: Theme) => `linear-gradient(
    to right,
    transparent 0px,         
    transparent ${DATE_LABEL_WIDTH}px,         
    ${theme.palette.grey[300]} ${DATE_LABEL_WIDTH}px,
    ${theme.palette.grey[300]} ${HOUR_WIDTH * 7 + DATE_LABEL_WIDTH}px, 
    transparent ${HOUR_WIDTH * 7 + DATE_LABEL_WIDTH}px,         
    transparent ${HOUR_WIDTH * 20 + DATE_LABEL_WIDTH}px,              
    ${theme.palette.grey[300]} ${HOUR_WIDTH * 20 + DATE_LABEL_WIDTH}px,       
    ${theme.palette.grey[300]} 100%          
  )`;

  return (
    <Box sx={{ background: backgroundGradient }} height="100%">
      <Box display="flex">
        <Box width={DATE_LABEL_WIDTH} />
        {Array.from({ length: 27 }).map((_, index) => {
          const value = index % 12 === 0 ? 12 : index % 12;

          return (
            <Box key={index} width={HOUR_WIDTH} textAlign="center">
              <Typography variant="body2" letterSpacing="-2px" fontSize="13px">{value}</Typography>
            </Box>
          );
        })}
      </Box>
      <Divider />
      <ScrollableView height="calc(100% - 20px)" enableScrollShadow onScrollCapture={handleScrollCapture}>
        {event.dates.map((eventDate, index) => {
          const times = getTimes({ event, eventDate });

          let width = getTimePeriodLength('00:00', times[0].time);
          let startIndex = 0;
          let bgcolor = 'transparent';
          let hoverBgcolor = 'transparent';
          let border = 'none';
          let cursor = 'default';
          let isUnknown = true;

          if (times.length >= 2 && getTimePeriodLength(times[0].time, times[1].time) > getTimePeriodLength('00:00', times[1].time)) {
            width = getTimePeriodLength('00:00', times[1].time);
            startIndex = 1;
            isUnknown = times[0].followingTimePeriodType === UnknownEnum.unknown;

            const color = eventDateTimePeriodTypeEnumHelpers.getColor(times[0].followingTimePeriodType);

            bgcolor = times[0].followingTimePeriodType === UnknownEnum.unknown ? 'transparent' : alpha(theme.palette[color].main, 0.5);
            hoverBgcolor = times[0].followingTimePeriodType === UnknownEnum.unknown ? 'transparent' : alpha(theme.palette[color].main, 0.7);
            border = isUnknown ? 'none' : `1px solid ${theme.palette[color].main}`;
            cursor = isUnknown ? 'default' : 'pointer';
          }

          const isLast = index === event.dates.length - 1;
          const isToday = DateService.dayjs().isSame(DateService.dayjsTz(eventDate.dateAsUtc), 'day');
          const percentage = (DateService.dayjs().hour() * 60 + DateService.dayjs().minute()) / 1440;
          const isCurrentBreak = index < event.dates.length - 1
            && DateService.dayjs().isAfter(DateService.dayjsTz(eventDate.dateAsUtc), 'day')
            && DateService.dayjs().isBefore(DateService.dayjsTz(event.dates[index + 1].dateAsUtc), 'day');

          const daysDiff = !isLast ? DateService.dayjsTz(event.dates[index + 1].dateAsUtc).diff(DateService.dayjsTz(eventDate.dateAsUtc), 'day') : 0;
          const nextDayIsTommorrow = !isLast && daysDiff === 1;

          const currentTimeInPixels = Math.round(HOUR_WIDTH * 24 * percentage);
          const dynamicBackground = isToday ? (theme: Theme) => `linear-gradient(
            to right,
            transparent 0px,
            transparent ${currentTimeInPixels}px,
            ${theme.palette.primary.main} ${currentTimeInPixels}px,
            ${theme.palette.primary.main} ${currentTimeInPixels + 3}px,
            transparent ${currentTimeInPixels + 3}px,
            transparent 100%
          )` : 'transparent';

          return (
            <React.Fragment key={eventDate._id}>
              <Box
                display="flex"
                alignItems="center"
                borderBottom={theme => `1px solid ${theme.palette.grey[400]}`}
              >
                <Box
                  pl={0.5}
                  width={DATE_LABEL_WIDTH}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  bgcolor={theme => isToday ? lighten(theme.palette.primary.main, 0.8) : 'transparent'}
                >
                  <Typography variant="body2">{DateService.dayjsTz(eventDate.dateAsUtc).format('ddd, MMM Do')}</Typography>
                  <Typography variant="caption" color="text.secondary">Day {index + 1} of {event.dates.length}</Typography>
                </Box>
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  sx={{ background: dynamicBackground }}
                >
                  <Box
                    component="div"
                    sx={{ width: `${width * HOUR_WIDTH}px`, height: 30, bgcolor, border, cursor, '&:hover': { bgcolor: hoverBgcolor } }}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => startIndex === 1 && !isUnknown && handleClick(e, eventDate, 0)}
                  />
                  {times.slice(startIndex).map(({ time, followingTimePeriodType }, index, times) => {
                    let width: number;

                    if (index === times.length - 1) {
                      width = 0;
                    } else {
                      const [ hours, minutes ] = time.split(':').map(n => Number(n));

                      if (DateService.dayjs().set('hours', hours).set('minutes', minutes).isBefore(DateService.dayjs().set('hours', 3).set('minutes', 0))) {
                        width = getTimePeriodLength(time, times[index + 1].time);
                      } else {
                        width = Math.min(getTimePeriodLength(time, times[index + 1].time), getTimePeriodLength(time, '3:00'));
                      }
                    }

                    const color = eventDateTimePeriodTypeEnumHelpers.getColor(followingTimePeriodType);
                    const isUnknown = followingTimePeriodType === UnknownEnum.unknown;

                    return (
                      <Box
                        component="div"
                        key={index}
                        sx={theme => ({
                          width: `${width * HOUR_WIDTH}px`,
                          height: 30,
                          border: isUnknown ? 'none' : `1px solid ${theme.palette[color].main}`,
                          bgcolor: isUnknown ? 'transparent' : alpha(theme.palette[color].main, 0.5),
                          cursor: isUnknown ? 'default' : 'pointer',
                          '&:hover': {
                            bgcolor: isUnknown ? 'transparent' : alpha(theme.palette[color].main, 0.7),
                          }
                        })}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => !isUnknown && handleClick(e, eventDate, index + startIndex)}
                      />
                    );
                  })}
                </Box>
              </Box>
              {!isLast && !nextDayIsTommorrow && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderBottom={theme => `1px solid ${theme.palette.grey[400]}`}
                  bgcolor={theme => isCurrentBreak ? lighten(theme.palette.primary.main, 0.8) : theme.palette.background.default}
                >
                  <Typography variant="body2" fontStyle="italic" color="text.secondary">{daysDiff - 1} day{daysDiff > 2 ? 's' : ''} break...</Typography>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </ScrollableView>
      <Popper open={!!popperConfig} anchorEl={anchorEl} transition id="event_dates_timelines_popper">
        {({ TransitionProps }) => {
          if (!popperConfig) {
            return null;
          }

          const times = getTimes({ event, eventDate: popperConfig.eventDate });

          if (popperConfig.index === times.length - 1) {
            return null;
          }

          const { followingTimePeriodType, time } = times[popperConfig.index];
          const endTime = times[popperConfig.index + 1].time;
          const lengthInMinutes = popperConfig.index < times.length - 1 ? getTimePeriodLength(time, endTime) * 60 : 0;
          const hours = Math.floor(lengthInMinutes / 60);
          const minutes = Math.floor(lengthInMinutes % 60);
          const Icon = eventDateTimePeriodTypeEnumHelpers.getIcon(followingTimePeriodType);

          return (
            <Box>
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Box p={1} bgcolor={theme => theme.palette.background.paper} border={theme => `1px solid ${theme.palette.grey[400]}`}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Icon fontSize="small" />
                      <Typography variant="body2">{eventDateTimePeriodTypeEnumHelpers.getLabel(followingTimePeriodType)}</Typography>
                    </Box>
                    <Typography variant="caption" color="text.secondary">{time} - {endTime} ({hours}h.{minutes}m.)</Typography>
                  </Box>
                </Fade>
              </ClickAwayListener>
            </Box>
          );
        }}
      </Popper>
    </Box>
  );
};