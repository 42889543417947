import ReactFullCalendar, { CalendarOptions } from '@fullcalendar/react';
import { Box, Dialog, DialogContent, SxProps, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CalendarToolbar } from './FullCalendarToolbar.component';
import { FullCalendarSidebar, FullCalendarSidebarProps } from './FullCalendarSidebar.component';
import { FullCalendarWrapper } from './FullCalendarWrapper.styled';
import { FULL_SCREEN_Z_INDEX } from 'constants/full-screen';
import { useLocalStorage } from 'hooks';
import { DateService } from 'services';

export const SIDEBAR_TIMEOUT = 500;

export type FullCalendarProps = {
  sidebarActionsConfig?: FullCalendarSidebarProps['actionsConfig'];
  calendarsConfig: ({
    id: string;
    initialDate?: Date;
    label?: React.ReactNode;
    labelSx?: SxProps;
    fullWidthHeight?: CalendarOptions['height'];
  } & CalendarOptions)[];
  onDateChange?: (date: Date) => void;
};

export const FullCalendar: React.FC<FullCalendarProps> = (props) => {
  const [ _state, setState ] = useState(0);
  const onStateChange = () => setState(p => p + 1);
  const { setResource, getResource } = useLocalStorage<Date>(`${props.calendarsConfig[0]?.id}_date`);
  const [ fullScreen, setFullScreen ] = useState(false);
  const [ sidebarOpen, setSidebarOpen ] = useState(false);
  const calendarRefs = useRef<ReactFullCalendar[]>([]);

  const onSidebarToggleHandler = () => {
    setSidebarOpen((p) => !p);
    setTimeout(() => {
      calendarRefs.current.forEach((ref) => ref?.getApi()?.updateSize());
    }, SIDEBAR_TIMEOUT);
  };

  useEffect(onStateChange, [ fullScreen ]);

  const content = (
    <Box display="flex" flexDirection="column" height="100%">
      <CalendarToolbar
        calendarApis={calendarRefs.current.map((ref) => ref?.getApi())}
        fullScreen={fullScreen}
        onFullScreenToggle={() => setFullScreen((p) => !p)}
        isSidebarOpen={sidebarOpen}
        onSidebarToggle={props.sidebarActionsConfig ? onSidebarToggleHandler : undefined}
        onStateChange={onStateChange}
        onDateChange={props.onDateChange}
      />

      <Box display="flex" height="100%">

        {props.sidebarActionsConfig &&
            <FullCalendarSidebar
              open={sidebarOpen}
              actionsConfig={props.sidebarActionsConfig}
            />
        }

        <FullCalendarWrapper>
          {props.calendarsConfig.map(({ id, label, labelSx, fullWidthHeight = '100%', ...config }, idx) => {
            const calendarNode = (
              <Box bgcolor={theme => theme.palette.background.paper} flex={1}>
                <ReactFullCalendar
                  ref={e => {
                    if (e) {
                      calendarRefs.current[idx] = e;
                    }
                  }}
                  initialDate={getResource(config.initialDate ?? new Date())}
                  firstDay={1}
                  {...config}
                  height={fullScreen ? fullWidthHeight : config.height}
                  datesSet={arg => idx === 0 && setResource(
                    DateService.dayjs(arg.startStr).add(
                      DateService.dayjs(arg.endStr).diff(arg.startStr, 'days') / 2,
                      'days'
                    ).toDate()
                  )}
                />
              </Box>
            );

            if (label) {
              return (
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontWeight={400} display="flex" alignItems="center" sx={labelSx}>
                    {label}
                  </Typography>
                  {calendarNode}
                </Box>
              );
            }

            return <Fragment key={id}>{calendarNode}</Fragment>;
          })}
        </FullCalendarWrapper>
      </Box>
    </Box>
  );

  if (fullScreen) {
    return (
      <Dialog fullScreen fullWidth open sx={{ zIndex: FULL_SCREEN_Z_INDEX, bgcolor: theme => theme.palette.grey[50] }} keepMounted>
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    );
  }

  return content;
};