import { EventContentArg } from '@fullcalendar/react';
import { SxProps, Box, alpha, Theme } from '@mui/material';

export type FullCalendarEventContentContainerProps = {
  backgroundFunc: (theme: Theme) => string;
  children: React.ReactNode;
} & Pick<EventContentArg, 'isStart' | 'isEnd'>;


export const FullCalendarEventContentContainer: React.FC<FullCalendarEventContentContainerProps> = ({
  backgroundFunc,
  children,
  isStart,
  isEnd,
}) => {
  const startSx: SxProps<Theme> = (theme) => ({
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      background: backgroundFunc(theme),
      width: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.25),
      borderBottomRightRadius: theme.spacing(0.25),
    },
  });

  const endSx: SxProps<Theme> = (theme) => ({
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      height: '100%',
      background: backgroundFunc(theme),
      width: theme.spacing(0.2),
    },
  });

  return (
    <Box width="100%" height="100%" bgcolor="white">
      <Box
        overflow="hidden"
        p={0.5}
        pl={1}
        sx={(theme) => ({
          background: alpha(backgroundFunc(theme), 0.2),
          height: '100%',
          width: '100%',
          position: 'relative',
          userSelect: 'none',
          ...(isStart ? startSx(theme) : {}),
          ...(isEnd ? endSx(theme) : {}),
        })}
      >
        {children}
      </Box>
    </Box>
  );
};
