import { Box, Typography } from '@mui/material';
import { getPriceAmountChar, getPriceAmountColor, useLogSaleContext } from '../utils';
import { currencyFormatter } from 'services';

const getPriceAdjustmentLabel = (priceAdjustment: number) => {
  if (priceAdjustment > 0) {
    return 'Additions';
  } else {
    return 'Discount';
  }
};

export const TotalsOverview: React.FC = () => {
  const { total, subtotal, discountTotal } = useLogSaleContext();

  const discountDisplay = `${getPriceAmountChar(discountTotal)}${currencyFormatter.format(Math.abs(discountTotal))}`;

  return (
    <Box display="flex">
      <Box display="flex" justifyContent="space-between" flex={1}>
        <Box textAlign="end">
          <Typography>Subtotal:</Typography>
          <Typography>{getPriceAdjustmentLabel(discountTotal)}:</Typography>
          <Typography mt={1}>Total:</Typography>
        </Box>
        <Box textAlign="end">
          <Typography fontWeight={500} ml={4}>{currencyFormatter.format(subtotal)}</Typography>
          <Typography fontWeight={500} ml={4} color={getPriceAmountColor(discountTotal)}>{discountDisplay}</Typography>
          <Typography fontWeight={500} ml={4} mt={1}>{currencyFormatter.format(total)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};