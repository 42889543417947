import { useFullCalendarLocalStorage } from './useFullCalendarLocalStorage.hook';

export const useFullCalendarWithEditModeDateLocalStorage = (calendarId: string, calendarEditableId: string, isEditMode: boolean) => {
  const { getResource: getCalendarDate } = useFullCalendarLocalStorage(calendarId);
  const { getResource: getEditableCalendarDate } = useFullCalendarLocalStorage(calendarEditableId);

  if (isEditMode) {
    return getCalendarDate(new Date());
  }

  return getEditableCalendarDate(new Date());
};
