import { ResouceWarningConfig } from '../types';
import { getWarningsForResource } from '../resource-warnings';
import { GetEventsResponse } from 'api/actions';
import { EventDatePageSection, EventDatePageSectionRowId } from 'components';

export const eventDateWarningConfigs: ResouceWarningConfig<GetEventsResponse['data'][number]['dates'][number], EventDatePageSection>[] = [
  {
    section: EventDatePageSection.OVERVIEW,
    row: EventDatePageSectionRowId.TIMES,
    needsAttention: (eventDate) => {
      if (!eventDate.confirmedTimes) {
        return 'Times are not confirmed';
      }

      return false;
    }
  },
  {
    section: EventDatePageSection.OVERVIEW,
    row: EventDatePageSectionRowId.TIMES,
    needsAttention: (eventDate) => {
      if (!eventDate.setupEndTime) {
        return "'Complete setup by' is unassigned";
      }

      return false;
    }
  },
  {
    section: EventDatePageSection.STAFF,
    row: EventDatePageSectionRowId.STAFF_NEEDED,
    needsAttention: (eventDate) => {
      if (!eventDate.staffNeeded) {
        return 'Number of needed staff is not set';
      }

      return false;
    }
  },
];

export const getWarningsForEventDate = (eventDate: GetEventsResponse['data'][number]['dates'][number]) => {
  return getWarningsForResource(eventDate, eventDateWarningConfigs);
};