import styled from '@emotion/styled';
import { InputBase, InputBaseProps, TextFieldProps } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { useMemo } from 'react';
import { DateObjectType, DateService } from 'services';

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0 9px',
});

const WrappedGridEditDateInput = (props: TextFieldProps) => {
  const { InputProps, ...other } = props;

  return (
    <GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)} />
  );
};

export const DateRangePickerCell: React.FC<GridRenderEditCellParams<any, DateRange<string>>> = (props) => {
  const { id, field, api } = props;

  const handleValueChange = (newValue: DateRange<DateObjectType>) => {
    const value: DateRange<string> = [
      newValue[0]?.isValid() ? newValue[0].toISOString() : null,
      newValue[1]?.isValid() ? newValue[1].toISOString() : null,
    ];

    api.setEditCellValue({ id, field, value: value });
  };

  const value = useMemo((): DateRange<DateObjectType> => {
    if (!props.value) {
      return [ null, null ];
    }

    return [
      DateService.dayjs(props.value[0]).isValid() ? DateService.dayjs(props.value[0]) : null,
      DateService.dayjs(props.value[1]).isValid() ? DateService.dayjs(props.value[1]) : null,
    ];
  }, [ props.value ]);

  return (
    <DateRangePicker
      value={value}
      onChange={handleValueChange}
      slotProps={{
        textField: {
          variant: 'standard',
          size: 'small',
        }
      }}
      slots={{ textField: WrappedGridEditDateInput }}
    />
  );
};